'use strict';

angular
  .module('routes.home.etfs', [
    'controller.home.etfs',
    'service.resolve-helpers.etfs',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', '$urlRouterProvider', configRoutes]);

function configRoutes($stateProvider, $urlRouterProvider) {
  function routeToFirstEtf($state) {
    $state.transitionTo('etfs.ishares-core-cci');
  }

  $urlRouterProvider.when('/etfs', ['$state', routeToFirstEtf]);

  function resolveChartData(etf, etfsResolveHelpers) {
    return etfsResolveHelpers.resolveChartData(etf);
  }

  $stateProvider
    .state('etfs', {
      url: 'etfs',
      abstract: true,
      parent: 'home',
      data: {
        title: 'Etfs',
      },
      resolve: {
        chartData: function () {
          return undefined;
        },
        etf: function () {
          return angular.noop;
        },
      },
      views: {
        '': {
          controller: 'EtfsCtrl',
        },
      },
    })
    // Canadian Stocks
    .state('etfs.ishares-core-cci', {
      url: '/ishares-core-capped-composite-index-cad-hedged-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.xic;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'This ETF offers broad exposure to the Canadian stock market for very low cost.  Vanguard and BMO also offer ETFs that track the Canadian stock market.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'iShares Core S&P/TSX Capped Composite Index ETF | ModernAdvisor',
            'twitter:description':
              'This ETF offers broad exposure to the Canadian stock market for very low cost.  Vanguard and BMO also offer ETFs that track the Canadian stock market.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'iShares Core Capped Composite ETF (XIC) | ModernAdvisor',
            'og:type': 'website',
            'og:url':
              'https://www.modernadvisor.ca/etfs/ishares-core-capped-composite-index-cad-hedged-etf',
            'og:description':
              'This ETF offers broad exposure to the Canadian stock market for very low cost.  Vanguard and BMO also offer ETFs that track the Canadian stock market.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'iShares Core Capped Composite ETF (XIC) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    .state('etfs.ishares-esg-advanced-msci', {
      url: '/ishares-esg-advanced-msci-index-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.xcsr;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'This ETF invests in Canadian companies. Companies are screened on environmental, social and governance factors and must receive an MSCI ESG rating of at least BBB or above. Companies in 13 industries, including fossil fuels, gambling, GMO, nuclear power, alcohol, military contracting, civilian firearms, and tobacco are ineligible.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'iShares ESG Advanced MSCI Canada Index ETF (XCSR) | ModernAdvisor',
            'twitter:description':
              'This ETF invests in Canadian companies. Companies are screened on environmental, social and governance factors and must receive an MSCI ESG rating of at least BBB or above. Companies in 13 industries, including fossil fuels, gambling, GMO, nuclear power, alcohol, military contracting, civilian firearms, and tobacco are ineligible.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'iShares ESG Advanced MSCI Canada Index ETF (XCSR) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/ishares-esg-advanced-msci-index-etf',
            'og:description':
              'This ETF invests in Canadian companies. Companies are screened on environmental, social and governance factors and must receive an MSCI ESG rating of at least BBB or above. Companies in 13 industries, including fossil fuels, gambling, GMO, nuclear power, alcohol, military contracting, civilian firearms, and tobacco are ineligible.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'iShares ESG Advanced MSCI Canada Index ETF (XCSR) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    // US Stocks
    .state('etfs.vanguard-us-tm', {
      url: '/vanguard-us-total-market-cad-hedged-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.vus;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'This ETF offers broad exposure to the US stock market and covers large-cap, mid-cap and small-cap stocks.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'Vanguard US Total Market ETF (CAD Hedged) (VUS) | ModernAdvisor',
            'twitter:description':
              'This ETF offers broad exposure to the US stock market and covers large-cap, mid-cap and small-cap stocks.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'Vanguard US Total Market ETF (CAD Hedged) (VUS) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/vanguard-us-total-market-cad-hedged-etf',
            'og:description':
              'This ETF offers broad exposure to the US stock market and covers large-cap, mid-cap and small-cap stocks.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'Vanguard US Total Market ETF (CAD Hedged) (VUS) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    .state('etfs.ishares-msci-kld-400', {
      url: '/ishares-msci-kld-400-social-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.dsi;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'A socially responsible alternative to VUS.  Companies involved in nuclear power, tobacco, alcohol, gambling, military weapons, civilian firearms, GMOs and adult entertainment are excluded.  The remaining companies are screened on environmental, social and governance factors and must maintain a minimum B rating.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'iShares MSCI KLD 400 Social ETF (DSI) (US) | ModernAdvisor',
            'twitter:description':
              'A socially responsible alternative to VUS.  Companies involved in nuclear power, tobacco, alcohol, gambling, military weapons, civilian firearms, GMOs and adult entertainment are excluded.  The remaining companies are screened on environmental, social and governance factors and must maintain a minimum B rating.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'iShares MSCI KLD 400 Social ETF (DSI) (US) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/ishares-msci-kld-400-social-etf',
            'og:description':
              'A socially responsible alternative to VUS. Companies involved in nuclear power, tobacco, alcohol, gambling, military weapons, civilian firearms, GMOs and adult entertainment are excluded.  The remaining companies are screened on environmental, social and governance factors and must maintain a minimum B rating.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'iShares MSCI KLD 400 Social ETF (DSI) (US) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    // Non-North American Developed Market Stocks
    .state('etfs.vanguard-ftse-dena', {
      url: '/vanguard-ftse-developed-ex-north-america-cad-hedged-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.vef;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'This ETF covers stocks from Asian, European and Australian stock markets at very low cost.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'Vanguard FTSE Developed ex US ETF CAD-Hedged (VEF) | ModernAdvisor',
            'twitter:description':
              'This ETF covers stocks from Asian, European and Australian stock markets at very low cost.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'Vanguard FTSE Developed ex US ETF CAD-Hedged (VEF) | ModernAdvisor',
            'og:type': 'website',
            'og:url':
              'https://www.modernadvisor.ca/etfs/vanguard-ftse-developed-ex-north-america-cad-hedged-etf',
            'og:description':
              'This ETF covers stocks from Asian, European and Australian stock markets at very low cost.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'Vanguard FTSE Developed ex US ETF CAD-Hedged (VEF) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    // Emerging Market Stocks
    .state('etfs.vanguard-ftse-em', {
      url: '/vanguard-ftse-emerging-markets-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.vee;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'This ETF provides exposure to emerging market stocks from countries such as China, India, South Africa and Brazil. VEE is the lowest cost emerging markets ETF in Canada.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'Vanguard FTSE Emerging Markets ETF (VEE) | ModernAdvisor',
            'twitter:description':
              'This ETF provides exposure to emerging market stocks from countries such as China, India, South Africa and Brazil. VEE is the lowest cost emerging markets ETF in Canada.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'Vanguard FTSE Emerging Markets ETF (VEE) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/vanguard-ftse-emerging-markets-etf',
            'og:description':
              'This ETF provides exposure to emerging market stocks from countries such as China, India, South Africa and Brazil. VEE is the lowest cost emerging markets ETF in Canada.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'Vanguard FTSE Emerging Markets ETF (VEE) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    // Canadian Bonds
    .state('etfs.vanguard-canadian-stb', {
      url: '/vanguard-canadian-short-term-bond-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.vsb;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'VSB is the lowest cost short-term bond ETF in Canada.  It holds mostly Canadian federal and provincial bonds, as well as bonds issued by Canadian companies such as banks.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'Vanguard Canadian Short-Term Index Bond ETF (VSB) | ModernAdvisor',
            'twitter:description':
              'VSB is the lowest cost short-term bond ETF in Canada.  It holds mostly Canadian federal and provincial bonds, as well as bonds issued by Canadian companies such as banks.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'Vanguard Canadian Short-Term Bond ETF (VSB) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/vanguard-canadian-short-term-bond-etf',
            'og:description':
              'VSB is the lowest cost short-term bond ETF in Canada.  It holds mostly Canadian federal and provincial bonds, as well as bonds issued by Canadian companies such as banks.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'Vanguard Canadian Short-Term Bond ETF (VSB) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    .state('etfs.ishares-laddered-etf', {
      url: '/ishares-1-5-year-laddered-government-bond-index-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.clf;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'A socially responsible alternative to VSB.  This ETF invests only in Canadian government bonds.  By avoiding corporate bonds it will not have any exposure to bonds from companies that may have a questionable environmental, social, or governance record.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title':
              'iShares 1-5 Year Laddered Government Bond Index ETF (CLF) | ModernAdvisor',
            'twitter:description':
              'This socially responsible ETF invests only in Canadian government bonds.  By avoiding corporate bonds it will not have any exposure to bonds from companies that may have a questionable environmental, social, or governance record.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'iShares 1-5 Year Laddered Government Bond Index ETF (CLF) | ModernAdvisor',
            'og:type': 'website',
            'og:url':
              'https://www.modernadvisor.ca/etfs/ishares-1-5-year-laddered-government-bond-index-etf',
            'og:description':
              'This socially responsible ETF invests only in Canadian government bonds.  By avoiding corporate bonds it will not have any exposure to bonds from companies that may have a questionable environmental, social, or governance record.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'iShares 1-5 Year Laddered Government Bond Index ETF (CLF) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    // Emerging Market Bonds
    .state('etfs.bmo-emerging-markets-bch', {
      url: '/bmo-emerging-markets-bond-cad-hedged-index-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.zef;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'This ETF invests in bonds from countries like South Korea, Russia, Mexico and Indonesia.  This is the only emerging market bond ETF available in Canada.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title':
              'BMO Emerging Markets Bond Hedged to CAD Index ETF (ZEF) | ModernAdvisor',
            'twitter:description':
              'This ETF invests in bonds from countries like South Korea, Russia, Mexico and Indonesia.  This is the only emerging market bond ETF available in Canada.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'BMO Emerging Markets Bond Hedged to CAD Index ETF (ZEF) | ModernAdvisor',
            'og:type': 'website',
            'og:url':
              'https://www.modernadvisor.ca/etfs/bmo-emerging-markets-bond-cad-hedged-index-etf',
            'og:description':
              'This ETF invests in bonds from countries like South Korea, Russia, Mexico and Indonesia.  This is the only emerging market bond ETF available in Canada.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'BMO Emerging Markets Bond Hedged to CAD Index ETF (ZEF)| ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    // 8
    .state('etfs.vanguard-capped-reits', {
      url: '/vanguard-capped-reits-index-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.vre;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description: 'VRE is the least expensive diversified REITs ETF in Canada.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'Vanguard FTSE Canadian Capped REITs Index ETF (VRE) | ModernAdvisor',
            'twitter:description': 'VRE is the least expensive diversified REITs ETF in Canada.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'Vanguard FTSE Canadian Capped REITs Index ETF (VRE) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/vanguard-capped-reits-index-etf',
            'og:description': 'VRE is the least expensive diversified REITs ETF in Canada.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'Vanguard FTSE Canadian Capped REITs Index ETF (VRE) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    .state('etfs.ishares-msci-eafe-esg-optimized', {
      url: '/ishares-msci-eafe-esg-optimized-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.esgd;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'A socially responsible alternative to VEF. Companies involved in tobacco or military weapons are excluded.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'iShares ESG Aware MSCI EAFE ETF (ESGD) (US) | ModernAdvisor',
            'twitter:description':
              'A socially responsible alternative to VEF. Companies involved in tobacco or military weapons are excluded.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'iShares ESG Aware MSCI EAFE ETF (ESGD) (US) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/ishares-msci-eafe-esg-optimized-etf',
            'og:description':
              'A socially responsible alternative to VEF. Companies involved in tobacco or military weapons are excluded.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'iShares ESG Aware MSCI EAFE ETF (ESGD) (US) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    .state('etfs.ishares-msc-em-esg-optimized', {
      url: '/ishares-msc-em-esg-optimized-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.esge;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              'A socially responsible alternative to VEE. Companies involved in tobacco or military weapons are excluded..',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'iShares ESG Aware MSCI EM ETF (ESGE) (US) | ModernAdvisor',
            'twitter:description':
              'A socially responsible alternative to VEE. Companies involved in tobacco or military weapons are excluded.',
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'iShares ESG Aware MSCI EM ETF (ESGE) (US) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/ishares-msc-em-esg-optimized-etf',
            'og:description':
              'A socially responsible alternative to VEE. Companies involved in tobacco or military weapons are excluded.',
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'iShares ESG Aware MSCI EM ETF (ESGE) (US) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    })
    .state('etfs.ishares-global-reit', {
      url: '/ishares-global-reit-etf',
      controller: 'EtfsCtrl',
      resolve: {
        etf: [
          'etfsResolveHelpers',
          function (etfsResolveHelpers) {
            return etfsResolveHelpers.etfs.reet;
          },
        ],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData],
      },
      data: {
        meta: {
          name: {
            Description:
              "A socially responsible alternative to VRE. This ETF has received a 'High' rating from Morningstar for sustainability.",
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'iShares Global REIT ETF (REET) (US) | ModernAdvisor',
            'twitter:description':
              "A socially responsible alternative to VRE. This ETF has received a 'High' rating from Morningstar for sustainability.",
            'twitter:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
          property: {
            'og:title': 'iShares Global REIT ETF (REET) (US) | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca/etfs/ishares-global-reit-etf',
            'og:description':
              "A socially responsible alternative to VRE. This ETF has received a 'High' rating from Morningstar for sustainability.",
            'og:site_name': 'ModernAdvisor',
            'og:image': 'https://www.modernadvisor.ca/assets/images/home_graphic.png',
          },
        },
        title: 'iShares Global REIT ETF (REET) (US) | ModernAdvisor',
      },
      templateUrl: 'views/home/etfs/details.html',
    });
}
