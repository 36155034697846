'use strict';

angular
  .module('model.AccountBeneficiary', ['ram', 'config', 'model.PersonalRelation', 'model.Account'])
  .factory('AccountBeneficiary', ['ram', 'config', accountBeneficiaryFactory]);

function accountBeneficiaryFactory(ram, config) {
  var AccountBeneficiary = new ram.Collection('AccountBeneficiary', {
    belongsTo: {
      personalRelation: 'PersonalRelation',
      account: 'Account',
    },
    schema: config.schemas.AccountBeneficiary,
    serialize: ['personalRelation'],
    resources: {
      default: new ram.resources.Http('/api/account_beneficiaries/:id.json'),
      cookie: new ram.resources.Cookie('account_beneficiaries'),
    },
  });

  AccountBeneficiary.prototype.initialize = function () {
    this._addressChanged = _.isUndefined(this._addressChanged) ? false : this._addressChanged;
    this._justCreated = _.isUndefined(this._justCreated) ? false : this._justCreated;
  };

  AccountBeneficiary.getAccountBeneficiaryRelationTypes = function (beneficiaryType) {
    return _.chain(config.types.PersonalRelation)
      .filter(function (relation) {
        return relation[beneficiaryType] !== -1;
      })
      .sortBy(function (relation) {
        return relation[beneficiaryType];
      })
      .value();
  };

  /**
   * Load sub-objects
   * @returns an array of promises, will be fed to $q.all()
   */
  AccountBeneficiary.prototype.loadDependantObjects = function () {
    return [
      this.personalRelation.ready(true),
      // In the future, primaryCaregiver and legalGuardian will go in this array, too.
    ];
  };

  AccountBeneficiary.prototype.addressChanged = function (val) {
    if (!_.isUndefined(val)) {
      this._addressChanged = val;
    }
    return this._addressChanged;
  };

  AccountBeneficiary.prototype.justCreated = function (val) {
    if (!_.isUndefined(val)) {
      this._justCreated = val;
    }
    return this._justCreated;
  };

  return AccountBeneficiary;
}
