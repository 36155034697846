'use strict';

var _paginationData = {};

function bulkDataLoaderFactoryCode($http, ram) {
  var _restApi = new ram.Accessor('');
  var _page = new ram.Accessor(0);
  var _perPage = new ram.Accessor(25);
  var _usePagination = new ram.Accessor(true);

  /*
   * Create the string with the REST arguments
   */
  function getRestArguments(pageNumber) {
    var data = _paginationData;

    var args = [];

    if (_usePagination()) {
      args.push('page=' + pageNumber);
      args.push('per_page=' + _perPage());
    }

    if (data.state && data.state.length > 0) {
      args.push('state=' + data.state);
    }

    if (data.order && data.order.length > 0) {
      args.push('order=' + data.order);
    }

    return args.length ? '?' + args.join('&') : '';
  }

  return {
    page: _page,
    perPage: _perPage,
    usePagination: _usePagination,
    restApi: _restApi,

    getResults: function (pageNumber) {
      var restApi = _restApi();
      if (restApi.length === 0) {
        return;
      }
      if (_usePagination() === false) {
        _page(1); // Always on page 1 if not using pagination
        _paginationData.totalPages = 1; // Always a single page if not using pagination
      }
      return $http.get(restApi + getRestArguments(pageNumber)).then(
        function (result) {
          return result;
        },
        function () {
          console.log('There was an error getting the data.  Please log in again.');
        }
      );
    },

    // The meta-data from the server needs to be stored.  This method takes the incoming
    // data and puts it in `_paginationData` and `_page`.
    setPaginationData: function (incomingData) {
      var newData = _.camelizeKeys(incomingData);
      _.extend(_paginationData, newData);
      _page(newData.page);
    },

    // A simple get method.
    getPaginationData: function () {
      return _paginationData;
    },

    defaultPaginationData: function () {
      return {
        state: '',
        order: '',
        totalPages: 1, // You always have one page, even if it is empty.
        totalCount: 0, // Number of records
        toIndex: 0, // first record on page
        fromIndex: 0, // last record on page
      };
    },

    numPages: function () {
      return _paginationData.totalPages;
    },
  };
}

angular
  .module('service.bulkDataLoader', [])
  .factory('bulkDataLoader', ['$http', 'ram', bulkDataLoaderFactoryCode]);
