'use strict';

angular.module('service.csv-table-creator', []).factory('csvTableCreator', [factory]);

function factory() {
  function csvToHtmlTable(csv) {
    var result = '<table class="table table-bordered table-hover table-condensed">';
    var rows = csv.split('\n');
    var headers = rows[0].split(',');
    result += '<thead><tr>';
    for (var i = 0; i < headers.length; i++) {
      result += '<th title="Field #' + i + '">' + headers[i] + '</th>';
    }
    result += '</tr></thead>';
    result += '<tbody>';
    for (var j = 1; j < rows.length; j++) {
      var fieldWithQuotes = rows[j].match(/".*?"/g);
      var counter = 0;
      var row = rows[j].replace(/".*?"/g, '#');
      var fields = row.split(',');
      result += '<tr>';
      for (var k = 0; k < fields.length; k++) {
        var field = fields[k];
        if (field === '#') {
          field = fieldWithQuotes[counter++].replace(/"/g, '');
        }
        result += '<td>' + field + '</td>';
      }
      result += '</tr>';
    }
    result += '</tbody>';
    result += '</table>';
    return result;
  }

  return {
    csvToHtmlTable,
  };
}
