'use strict';

angular
  .module('controller.mobile-upload', ['service.intercom', 'model.Document', 'service.modals'])
  .controller('MobileUploadCtrl', [
    '$state',
    '$scope',
    '$window',
    'modals',
    'intercom',
    'Document',
    'userName',
    'password',
    'uploadDetails',
    controller,
  ]);

function controller(
  $state,
  $scope,
  $window,
  modals,
  intercom,
  Document,
  userName,
  password,
  uploadDetails
) {
  // Don't need intercom.  It just gets in the way.
  intercom.shutdown();

  if (!uploadDetails || uploadDetails.error) {
    $scope.error = true;

    if (uploadDetails.error) {
      modals
        .errorModal(uploadDetails.error.title, uploadDetails.error.message)
        .result.then(function () {
          $window.location = $scope.$root.config.env.marketingUrl;
        });
    } else {
      $window.location = $scope.$root.config.env.marketingUrl;
    }
    return;
  }

  var isSpouseJointSubscriber = uploadDetails.isSpouseJointSubscriber === 1;
  var documentDefaults = {
    adcKey: null,
    awsKey: null,
    signedAt: null,
    approvedAt: null,
  };

  $scope.photos = {
    bankStatementImage: Document.new(
      _.defaults(
        {
          typeId: '5',
        },
        documentDefaults
      )
    ),
    idFrontImage: Document.new(
      _.defaults(
        {
          typeId: '3',
        },
        documentDefaults
      )
    ),
    idBackImage: Document.new(
      _.defaults(
        {
          typeId: '4',
        },
        documentDefaults
      )
    ),
  };

  if (uploadDetails.bankAccountId) {
    $scope.photos.bankStatementImage.relatedToType('BankAccount');
    $scope.photos.bankStatementImage.relatedToId(uploadDetails.bankAccountId);
  }

  if (isSpouseJointSubscriber) {
    var accountParams = {
      relatedToType: 'Account',
      relatedToId: uploadDetails.accountId,
    };

    _.extend($scope.photos, {
      jointIdFrontImage: Document.new(
        _.defaults(
          {
            typeId: '11',
          },
          documentDefaults,
          accountParams
        )
      ),
      jointIdBackImage: Document.new(
        _.defaults(
          {
            typeId: '12',
          },
          documentDefaults,
          accountParams
        )
      ),
    });

    $scope.spouseOrPartner = uploadDetails.spouseTypeName;
    $scope.isSpouseJointSubscriber = isSpouseJointSubscriber;
  }
  $scope.userName = userName;
  $scope.password = password;

  $scope.uploadData = {
    credentials: $scope.userName + ':' + $scope.password,
  };
}
