'use strict';

angular
  .module('directive.input.bank-account-numbers', ['service.patterns'])
  .directive('bankAccountNumbersInput', [
    'patterns',
    'accountService',
    bankAccountNumbersInputDirective,
  ]);

function bankAccountNumbersInputDirective(patterns, accountService) {
  return {
    restrict: 'E',
    scope: {
      autoSave: '=',
      bankAccount: '=',
      institutions: '=',
      optional: '=',
    },
    templateUrl: 'directives/input/bank-account-numbers.html',
    link: function ($scope) {
      $scope.patterns = patterns;
      $scope.showAutoCompleteMenu = true;
      $scope.hasFccAccount = accountService.hasFccAccount($scope.$root.currentUser.accounts());

      $scope.onBankAccountChange = function () {
        if ($scope.autoSave) {
          $scope.bankAccount.save();
        }
      };

      var onAutoCompleteSelect = function (event, ui) {
        if (ui.item) {
          $scope.$apply(function () {
            $scope.bankAccount.bankCode(ui.item.value);
          });
        }
      };

      var codesInitialized = false;

      $scope.initAutocomplete = function (event) {
        if (!codesInitialized) {
          $(event.target).autocomplete({
            appendTo: '#autocomplete-menu',
            delay: 100,
            minLength: 2,
            source: $scope.institutions,
            select: onAutoCompleteSelect,
          });
        }
        codesInitialized = true;
      };
    },
  };
}
