'use strict';

angular
  .module('controller.modal.advisor.transfer-status-modal', [])
  .controller('ModalAdvisorTransferStatusModalCtrl', [
    '$scope',
    'accountTransfer',
    transferStatusModalController,
  ]);

function transferStatusModalController($scope, accountTransfer) {
  $scope.accountTransfer = accountTransfer;

  $scope.accountNumber = accountTransfer.accountNumber();
  $scope.cancelledAt = accountTransfer.cancelledAt();
  $scope.signedAt = accountTransfer.accountTransferForm().signedAt();
  $scope.clientName = accountTransfer.clientName();
  $scope.amount = accountTransfer.amount();
  $scope.inKind = accountTransfer.inKind();
  $scope.partialTransfer = accountTransfer.partialTransfer();
  $scope.institutionName = accountTransfer.institutionName();
  $scope.externalAccountNumber = accountTransfer.externalAccountNumber();
  $scope.status = accountTransfer.status.label();

  $scope.transferType = function () {
    if ($scope.inKind && $scope.partialTransfer) {
      return 'Partial in-kind';
    } else if (!$scope.inKind && !$scope.partialTransfer) {
      return 'Full in-cash';
    } else if ($scope.inKind && !$scope.partialTransfer) {
      return 'Full in-kind';
    } else {
      return 'Partial in-cash';
    }
  };
}
