'use strict';

angular
  .module('controller.modal.change-new-account-flow-account-nickname', [])
  .controller('changeNewAccountFlowAccountNicknameCtrl', [
    '$scope',
    'ram',
    'newAccountFlow',
    changeNewAccountFlowAccountNicknameCtrl,
  ]);

function changeNewAccountFlowAccountNicknameCtrl($scope, ram, newAccountFlow) {
  $scope.newAccountFlow = newAccountFlow;
  $scope.nickname = new ram.Accessor(newAccountFlow.accountNickname());

  $scope.saveNicknameAndExit = function () {
    newAccountFlow.accountNickname($scope.nickname());
    newAccountFlow.save().then(function () {
      $scope.$dismiss();
    });
  };
}
