'use strict';

angular
  .module('model.TransferInstruction', [
    'config',
    'model.TransferRequest',
    'model.Custodian',
    'ram',
  ])
  .factory('TransferInstruction', [
    '$http',
    'config',
    'ram',
    'Custodian',
    transferInstructionFactory,
  ]);

function transferInstructionFactory($http, config, ram, Custodian) {
  var TransferInstruction = new ram.Collection('TransferInstruction', {
    bind: ['workflowClientAction'],
    enums: {
      type: config.types.TransferInstruction,
      frequency: config.types.TransferInstructionFrequency,
      status: config.types.TransferInstructionStatus,
      subStatus: config.types.TransferInstructionSubStatus,
    },
    hasMany: {
      transferRequests: 'TransferRequest',
    },
    resources: {
      default: new ram.resources.Http('/api/transfer_instructions/:id.json'),
      cookie: new ram.resources.Cookie('transfer_instructions'),
    },
    schema: config.schemas.TransferInstruction,
  });

  TransferInstruction.prototype.cancel = function () {
    var self = this;
    return $http.put('/api/transfer_instructions/' + this.id + '/cancel.json').then(function () {
      return self.reload();
    });
  };

  TransferInstruction.prototype.forceCancel = function () {
    var self = this;
    return $http
      .put('/api/transfer_instructions/' + this.id + '/force_cancel.json')
      .then(function () {
        return self.reload();
      });
  };

  TransferInstruction.prototype.hasInProgressRequests = function () {
    return this.inProgressRequest() !== undefined;
  };

  // Quick replacement for Array.prototype.at(-1) which is not supported in old versions of Safari
  function lastFor(array) {
    return array[array.length - 1];
  }

  TransferInstruction.prototype.custodianIsBbs = function () {
    return this.custodianId() === Custodian.bbsId();
  };

  TransferInstruction.prototype.inProgressRequest = function () {
    const inProgressRequests = this.transferRequests().filter((transferRequest) => {
      if (transferRequest.custodianIsBbs()) {
        return transferRequest.status.is.inProgress() && transferRequest.sentAt() !== null;
      } else {
        return transferRequest.status.is.inProgress();
      }
    });

    return lastFor(inProgressRequests);
  };

  TransferInstruction.prototype.resetCancelData = function () {
    this.continueUntil(null);
    this.maxOccurrence(null);
    this.manualCancel(null);
  };

  TransferInstruction.prototype.setFrom = function (typeString, accountId) {
    this.transferFromType(typeString);
    this.transferFromId(accountId);
  };

  TransferInstruction.prototype.setTo = function (typeString, accountId) {
    this.transferToType(typeString);
    this.transferToId(accountId);
  };

  TransferInstruction.prototype.userIsHolderOfFromOrTo = function () {
    var fromUserIsHolder = this.transferFrom && this.transferFrom.userIsHolder();
    var toUserIsHolder = this.transferTo && this.transferTo.userIsHolder();

    return fromUserIsHolder || toUserIsHolder;
  };

  TransferInstruction.prototype.amountAsMonthlyRate = function () {
    return (parseFloat(this.amount()) * this.frequency().instancesPerYear) / 12;
  };

  TransferInstruction.prototype.workflowClientAction = function (userId, action) {
    return $http.post('/api/transfer_instructions/workflow_client_actions', {
      userId,
      id: this.id,
      workflowAction: action,
    });
  };

  TransferInstruction.prototype.mostRecentTransferRequest = function () {
    return _.chain(this.transferRequests())
      .sortBy(function (transferRequest) {
        return transferRequest.dateToBeProcessed();
      })
      .last()
      .value();
  };

  return TransferInstruction;
}
