'use strict';

angular
  .module('service.promotion-redemption-cookie-creator', ['model.PortfolioManager'])
  .factory('promotionRedemptionCookieCreator', [
    '$cookies',
    'PortfolioManager',
    promotionRedemptionCookieCreatorFactory,
  ]);

function promotionRedemptionCookieCreatorFactory($cookies, PortfolioManager) {
  var PROMOTION_COOKIE_NAMES = [
    'auto_apply_promo_code',
    'group_savings_plan',
    'group_url_id',
    'leadDynoTracking',
    'referral_code',
  ];

  function existingCookie() {
    const existingCookieName = PROMOTION_COOKIE_NAMES.find((promotionCookieName) => {
      return $cookies.get(promotionCookieName);
    });

    if (existingCookieName) {
      return $cookies.get(existingCookieName);
    }
  }

  function removeOtherPromotionCookies() {
    _.each(PROMOTION_COOKIE_NAMES, function (promotionCookieName) {
      $cookies.remove(promotionCookieName);
    });
  }

  function createReferAFriend(referralCode) {
    removeOtherPromotionCookies();

    $cookies.putObject(
      'referral_code',
      {
        code: referralCode,
        promotionRedeemedAt: moment().toDate(),
      },
      {
        expires: moment().add(14, 'days').toDate(),
      }
    );
  }

  function _isNullOrUndefined(test) {
    return test === null || test === undefined;
  }

  function createAdvisorReferral(groupUrlId, portfolioManagerId) {
    portfolioManagerId = _isNullOrUndefined(portfolioManagerId)
      ? PortfolioManager.constructor.modernAdvisorId()
      : portfolioManagerId;
    removeOtherPromotionCookies();

    const matches = groupUrlId.match(/^([^,]+),?(.*)$/);
    const urlId = matches[1];
    const invitationLink = matches[2];
    $cookies.putObject(
      'group_url_id',
      {
        urlId,
        portfolioManagerId,
        invitationLink,
        promotionRedeemedAt: moment().toDate(),
      },
      {
        expires: moment().add(90, 'days').toDate(),
      }
    );
  }

  function getGroupUrlId() {
    var advisorReferral = $cookies.getObject('group_url_id');
    return advisorReferral && advisorReferral.urlId;
  }

  function createAffiliateReferral(affiliateLink, trackingCode) {
    removeOtherPromotionCookies();

    $cookies.putObject(
      'leadDynoTracking',
      {
        affiliateLink,
        trackingCode,
        promotionRedeemedAt: moment().toDate(),
      },
      {
        expires: moment().add(90, 'days').toDate(),
      }
    );
  }

  function createAutoApplyPromoCode(code) {
    removeOtherPromotionCookies();

    $cookies.putObject(
      'auto_apply_promo_code',
      {
        code,
        promotionRedeemedAt: moment().toDate(),
      },
      {
        expires: moment().add(14, 'days').toDate(),
      }
    );
  }

  return {
    createAutoApplyPromoCode,
    createReferAFriend,
    createAdvisorReferral,
    createAffiliateReferral,
    existingCookie,
    getGroupUrlId,
  };
}
