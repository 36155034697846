'use strict';

angular
  .module('service.new-client-goto-next-state-service', [])
  .factory('newClientGotoNextStateService', [NewClientGotoNextStateService]);

function NewClientGotoNextStateService() {
  function go($state, $scope, params) {
    const { source, advisorDetails } = params;

    if (source === 'service-level' && advisorDetails.multiRelationshipManagers) {
      $state.go('advise.add-client.add-advisors');
    } else if (
      source === 'new-client-button' &&
      (advisorDetails.multiServiceLevels || advisorDetails.multiRelationshipManagers)
    ) {
      $state.go('advise.add-client', {
        redirect: true,
      });
    } else {
      $scope.inviteClientModal(params);
    }
  }

  return {
    go,
  };
}
