'use strict';

angular
  .module('directive.input.email', ['templates', 'animations', 'directive.focus'])
  .directive('email', [emailDirective]);

function emailDirective() {
  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      ngChange: '=',
      form: '=',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      name: '@',
      class: '@',
      placeholder: '@',
      inputClass: '@',
      inputElementClass: '@',
      labelClass: '@',
      helpClass: '@',
      disableLabel: '=',
      disableInput: '=',
      optional: '=',
    },
    templateUrl: 'directives/input/email.html',
  };
}
