'use strict';

angular
  .module('routes.home', [
    'controller.home',
    'controller.navbar.default',
    'service.resolve-helpers.model-portfolio',
    'service.leadDynoVisitorNotifier',
    'service.referral-reward-client-greeter',
    'routes.home.etfs',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

/**
 * Initialize angular UI router for home states.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider) {
  function resolvePresetQuestion(questions) {
    return _.find(questions, function (question) {
      return question.accessor() === 'preset';
    });
  }

  function redirectFromMain(
    $state,
    $location,
    leadDynoVisitorNotifier,
    referralRewardClientGreeter,
    affiliateLink,
    referralCode
  ) {
    if (affiliateLink) {
      leadDynoVisitorNotifier.getAffiliatePromoInfo(affiliateLink).then(function (affiliateInfo) {
        if (affiliateInfo) {
          leadDynoVisitorNotifier.recordVisitor(affiliateInfo.code);
          return $state.go('landing.welcome', {
            affiliateName: affiliateInfo.name,
            affiliatePromo: affiliateInfo.description,
          });
        } else {
          return $state.go('referral-only', {
            invalidAffiliateCode: true,
          });
        }
      });
    } else if (referralCode) {
      referralRewardClientGreeter.getReferrerName(referralCode).then(function (referrerName) {
        if (referrerName) {
          referralRewardClientGreeter.createCookie(referralCode);
          return $state.go('landing.welcome', {
            referrerName,
          });
        } else {
          $location.path('404.html');
        }
      });
    } else {
      return $state.go('account.signin');
    }
  }

  $stateProvider
    .state('home', {
      url: '/',
      parent: 'site',
      abstract: true,
      data: {
        title: 'Home',
      },
      resolve: {
        presetQuestion: ['questions', resolvePresetQuestion],
      },
    })
    .state('home.main', {
      url: '?intercom&gspName&advName', // See note with text `https://www.modernadvisor.ca/?intercom=true` in the controller
      resolve: {
        redirect: [
          '$state',
          '$location',
          'leadDynoVisitorNotifier',
          'referralRewardClientGreeter',
          'affiliateLink',
          'referralCode',
          redirectFromMain,
        ],
      },
      data: {
        meta: {
          name: {
            Description:
              'ModernAdvisor is an online financial advisor. We manage personalized investment portfolios inside RRSP, TFSA, RESP, RRIF, and taxable accounts for a remarkably low fee.',
            'twitter:card': 'summary_large_image',
            'twitter:site': '@ModernAdvsr',
            'twitter:title': 'Online Financial Advisor | ModernAdvisor',
            'twitter:description':
              'ModernAdvisor is an online financial advisor. We manage personalized investment portfolios inside RRSP, TFSA, RESP, RRIF, and taxable accounts for a remarkably low fee.',
            'twitter:image':
              'https://s3-us-west-2.amazonaws.com/modernadvisor-public/header-image-ma-012017.png',
          },
          property: {
            'og:title': 'Online Financial Advisor | ModernAdvisor',
            'og:type': 'website',
            'og:url': 'https://www.modernadvisor.ca',
            'og:description':
              'ModernAdvisor is an online financial advisor. We manage personalized investment portfolios inside RRSP, TFSA, RESP, RRIF, and taxable accounts for a remarkably low fee.',
            'og:site_name': 'ModernAdvisor',
            'og:image':
              'https://s3-us-west-2.amazonaws.com/modernadvisor-public/header-image-ma-012017.png',
          },
        },
        title: 'Online Financial Advisor',
      },
      controller: 'HomeMainCtrl',
    })
    // .state('home.main-old', {
    //   url: 'main-old?intercom&gspName&advName', // See note with text `https://www.modernadvisor.ca/?intercom=true` in the controller
    //   data: {
    //     meta: {
    //       name: {
    //         'Description': 'ModernAdvisor is an online financial advisor. We manage personalized investment portfolios inside RRSP, TFSA, RESP, RRIF, and taxable accounts for a remarkably low fee.',
    //         'twitter:card': 'summary_large_image',
    //         'twitter:site': '@ModernAdvsr',
    //         'twitter:title': 'Online Financial Advisor | ModernAdvisor',
    //         'twitter:description': 'ModernAdvisor is an online financial advisor. We manage personalized investment portfolios inside RRSP, TFSA, RESP, RRIF, and taxable accounts for a remarkably low fee.',
    //         'twitter:image': 'https://s3-us-west-2.amazonaws.com/modernadvisor-public/header-image-ma-012017.png'
    //       },
    //       property: {
    //         'og:title': 'Online Financial Advisor | ModernAdvisor',
    //         'og:type': 'website',
    //         'og:url': 'https://www.modernadvisor.ca',
    //         'og:description': 'ModernAdvisor is an online financial advisor. We manage personalized investment portfolios inside RRSP, TFSA, RESP, RRIF, and taxable accounts for a remarkably low fee.',
    //         'og:site_name': 'ModernAdvisor',
    //         'og:image': 'https://s3-us-west-2.amazonaws.com/modernadvisor-public/header-image-ma-012017.png'
    //       }
    //     },
    //     title: 'Online Financial Advisor'
    //   },
    //   controller: 'HomeMainCtrl'
    // })
    // .state('home.pricing-old', {
    //   url: 'pricing-old',
    //   data: {
    //     meta: {
    //       name: {
    //         'Description': 'We use smart technology to lower the cost of high-quality investment advice and help you keep more of your own money. Our investment fees are amongst the lowest in Canada.',
    //         'twitter:card': 'summary_large_image',
    //         'twitter:site': '@ModernAdvsr',
    //         'twitter:title': 'Remarkably Low Investment Management Fees | ModernAdvisor',
    //         'twitter:description': 'We use smart technology to lower the cost of high-quality investment advice and help you keep more of your own money. Our investment fees are amongst the lowest in Canada.',
    //         'twitter:image': 'https://www.modernadvisor.ca/assets/images/pricing_graphic.png'
    //       },
    //       property: {
    //         'og:title': 'Remarkably Low Investment Fees | ModernAdvisor',
    //         'og:type': 'website',
    //         'og:url': 'https://www.modernadvisor.ca/pricing',
    //         'og:description': 'We use smart technology to lower the cost of high-quality investment advice and help you keep more of your own money. Our investment fees are amongst the lowest in Canada.',
    //         'og:site_name': 'ModernAdvisor',
    //         'og:image': 'https://www.modernadvisor.ca/assets/images/pricing_graphic.png'
    //       }
    //     },
    //     title: 'Pricing - Investment Fees'
    //   },
    //   controller: 'HomePricingCtrl'
    // })
    .state('home.signout', {
      url: 'signout',
      data: {
        title: 'Signed Out',
      },
      views: {
        'main@site': {
          templateUrl: 'views/home/signout.html',
        },
        'navbar@site': {
          templateUrl: 'navbar/minimal.html',
        },
        'footer@site': {
          templateUrl: 'partials/footer-minimal.html',
        },
      },
      hasMobileView: true,
    })
    .state('home.link-not-found', {
      url: 'link-not-found',
    });
}
