'use strict';

angular
  .module('controller.advise.create-client.email', [
    'model.AdvisorCreateNewClientFlow',
    'ram',
    'service.form-helper',
    'service.new-client-creator',
    'service.user-notifications',
  ])
  .controller('AdviseCreateClientEmailCtrl', [
    '$http',
    '$scope',
    '$state',
    '$stateParams',
    'formHelper',
    'newClientCreator',
    'ram',
    'userNotifications',
    adviseCreateClientEmailCtrl,
  ]);

function adviseCreateClientEmailCtrl(
  $http,
  $scope,
  $state,
  $stateParams,
  formHelper,
  newClientCreator,
  ram,
  userNotifications
) {
  $scope.email = new ram.Accessor();
  $scope.isSubmitting = false;

  $scope.deleteExistingClientFlow = function (existingClientApplicationId) {
    $http
      .delete('/api/advisor_create_new_client_flows/' + existingClientApplicationId + '.json')
      .then(function () {
        $scope.existingClientApplicationId = null;
      });
  };

  function doSubmit() {
    $scope.isSubmitting = true;

    newClientCreator
      .create($scope.email(), $stateParams.advisorReferralCode, $stateParams.portfolioManagerId)
      .then(function (clientFlow) {
        return $state.go('advise.client-application.setup', {
          id: clientFlow.id,
        });
      })
      .catch(function (response) {
        var errors = response.data.errors;

        if (errors && errors.alreadyCreated) {
          $scope.existingClientApplicationId = errors.alreadyCreated.existingRecordId;
        } else if (errors && errors.email) {
          userNotifications.showError(
            'The provided email has already been taken. Please provide a different email address.'
          );
        } else {
          userNotifications.showError('Something went wrong. Please try again later.');
        }
      })
      .finally(function () {
        $scope.isSubmitting = false;
      });
  }

  $scope.submit = function (formController) {
    $scope.existingClientApplicationId = null;
    userNotifications.clearNotification();

    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      doSubmit();
    }
  };

  $scope.cancelAction = function () {
    var modalTitle = 'Are you sure?';
    var modalBody = 'This action will delete the current application.';

    $scope.$root.genericConfirmationModal(modalTitle, modalBody).result.then(function () {
      $state.go('advise.overview');
    });
  };
}
