'use strict';

angular
  .module('controller.onboarding.personal.spouse', [
    'directive.input',
    'directive.luhn',
    'directive.validate-date',
    'model.JointApplicant',
  ])
  .controller('OnboardingPersonalSpouseController', [
    '$scope',
    'ram',
    'JointApplicant',
    onboardingPersonalSpouseController,
  ]);

function onboardingPersonalSpouseController($scope, ram, JointApplicant) {
  var jointApplicants = $scope.jointApplicants;
  var spouse = $scope.spouse;

  $scope.onSpouseChange = function (person) {
    if ($scope.autoSave) {
      return person.makeConsistentAndSave();
    }
  };

  $scope.onPhoneNumberChange = function (phoneNumber) {
    return phoneNumber.save();
  };

  $scope.onAddressChange = function (address) {
    return address.save();
  };

  $scope.onDateOfBirthChange = function (person, date) {
    return person.save({
      birthDate: date.toDate(),
    });
  };

  var respRdspJointController = function () {
    $scope.$watch('account.spousalJointAccount()', function (spouseIsJointApplicant) {
      if (spouseIsJointApplicant && !$scope.spouseJointApplicant) {
        $scope.spouseJointApplicant = JointApplicant.new({
          accountId: $scope.account.id,
        });
      } else if (!spouseIsJointApplicant && $scope.spouseJointApplicant) {
        $scope.spouseJointApplicant.destroy().then(function () {
          $scope.spouseJointApplicant = null;
        });
      }
      $scope.account.save();
      $scope.$emit('spouseJointSubscriber', {
        spouseJointApplicant: !!spouseIsJointApplicant,
      });
    });
  };

  var regularJointController = function () {
    $scope.$watch('account.spousalJointAccount()', function (spouseIsJointApplicant) {
      if (spouseIsJointApplicant) {
        $scope.spouseJointApplicant.personId(spouse.id); // With JointWROS, you can have someone else as joint holder.
      } else if (!spouseIsJointApplicant) {
        $scope.spouseJointApplicant.personId(null);
      }
      $scope.account.save();
      $scope.spouseJointApplicant.save();
      $scope.$emit('spouseJointSubscriber', {
        spouseJointApplicant: !!spouseIsJointApplicant,
      });
    });
  };

  if ($scope.canHaveSpouseAsJoint) {
    $scope.spouseJointApplicant = _.first(jointApplicants);

    if ($scope.isResp) {
      respRdspJointController();
    } else if ($scope.isRdsp) {
      respRdspJointController();
    } else if ($scope.isJoint) {
      regularJointController();
    }

    $scope.personalSubforms.saveSpouse = function () {
      return $scope.spouse.save().then(function () {
        if ($scope.spouseJointApplicant && $scope.account.spousalJointAccount()) {
          return $scope.spouseJointApplicant.save();
        }
      });
    };
  }
}
