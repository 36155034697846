'use strict';

function priceDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModelController) {
      var formatPrice = function (price) {
        if (price === null || !Number(price)) {
          return '';
        }
        return '$' + Number(price).toFixed(2);
      };

      element.bind('focus', function () {
        ngModelController.$setViewValue(ngModelController.$viewValue.replace('$', ''));
        ngModelController.$render();
      });

      element.bind('blur', function () {
        ngModelController.$setViewValue(formatPrice(ngModelController.$viewValue));
        ngModelController.$render();
      });

      ngModelController.$formatters.unshift(formatPrice);

      ngModelController.$parsers.push(function (viewValue) {
        var newModelValue = viewValue.replace('$', '');
        if (Number(newModelValue) >= attrs.ngMin && Number(newModelValue) <= attrs.ngMax) {
          return newModelValue;
        } else {
          return null;
        }
      });
    },
  };
}

angular.module('directive.input.price', []).directive('price', priceDirective);
