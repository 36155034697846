'use strict';

import accountBeneficiariesFormComponent from './account-beneficiaries-form.component';

const AccountBeneficiariesFormModule = angular
  .module('component.account-beneficiaries-form', [
    'model.AccountBeneficiary',
    'model.Beneficiary',
    'model.AdvisorBeneficiaryForm',
    'model.Person',
    'model.PersonalRelation',
    'model.SuccessorHolder',
    'service.account-service',
    'service.beneficiary-service',
    'service.spouse-or-partner',
    'service.beneficiary-params-generator',
  ])
  .component('accountBeneficiariesForm', accountBeneficiariesFormComponent);

export default AccountBeneficiariesFormModule;
