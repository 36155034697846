'use strict';

angular
  .module('controller.onboarding.signatures', [
    'ram',
    'directive.secured',
    'service.loading-indicator',
    'service.account-service',
  ])
  .controller('OnboardingSignaturesController', [
    '$scope',
    '$timeout',
    '$window',
    '$state',
    'currentUser',
    'completeApplication',
    'account',
    'accountService',
    onboardingSignaturesController,
  ]);

function onboardingSignaturesController(
  $scope,
  $timeout,
  $window,
  $state,
  currentUser,
  completeApplication,
  account,
  accountService
) {
  $scope.$parent.done = function () {
    return true;
  };

  if (!completeApplication.inline) {
    $scope.nextState();
  }

  $scope.inlineSignature = !!completeApplication.inline;
  $scope.noDocumentsToSign = accountService.noDocumentsToSign(currentUser, account);

  $scope.reloads = 0;

  function reloadNaaf() {
    account.newAccountApplicationForm
      .ready(true)
      .then(function (naaf) {
        $scope.widgetUrl = naaf && naaf.widgetUrl();
      }, angular.noop)
      .finally(retryLoading);
  }

  function retryLoading() {
    if (!$scope.widgetUrl) {
      if ($scope.reloads < 60) {
        $scope.reloads++;
        $timeout(reloadNaaf, 2000);
      } else {
        $scope.reloading = false;
        $scope.trouble = true;
        $state.$current.data.title = 'This is embarrassing';
        $state.$current.data.hideNavigationButtons = false;
      }
    } else {
      $scope.reloading = false;
      $window.location.href = $scope.widgetUrl;
    }
  }

  if ($scope.inlineSignature) {
    $scope.reloading = true;
    reloadNaaf();
  }
}
