'use strict';

angular
  .module('routes.wsi-optimizing', [
    'controller.optimizing',
    'templates',
    'ui.router',
    'service.adaptive-views',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', configRoutes]);

function configRoutes($stateProvider, adaptiveViewsProvider) {
  $stateProvider.state('wsi-optimizing', {
    params: {
      redirect: 'wsi-interview.preview.investments',
      after: 2000,
    },
    parent: 'site',
    data: {
      interviewStep: 1,
    },
    views: {
      main: {
        controller: 'OptimizingCtrl',
        templateUrl: 'views/optimizing.html',
      },
      navbar: {
        templateUrl: 'navbar/preview.html',
      },
      footer: {
        templateUrl: adaptiveViewsProvider.isMobile()
          ? 'mobile/partials/footer.html'
          : 'partials/footer-minimal.html',
      },
    },
  });
}
