'use strict';

angular
  .module('model.Document', ['ram', 'config'])
  .factory('Document', ['$http', 'ram', 'config', documentFactory]);

function documentFactory($http, ram, config) {
  var Document = new ram.Collection('Document', {
    belongsTo: {
      user: 'User',
    },
    bind: ['needsSignature', 'inlineSignature'],
    enums: {
      type: config.types.Document,
    },
    resources: {
      default: new ram.resources.Http('/api/documents/:id.json'),
      cookie: new ram.resources.Cookie('documents'),
    },
    schema: config.schemas.Document,
  });

  Document.prototype.needsSignature = function () {
    return !this.signedAt() && !this.widgetRedirectReceivedAt();
  };

  Document.prototype.inlineSignature = function () {
    return !_.isEmpty(this.widgetUrl());
  };

  Document.prototype.prepareForPrefill = function () {
    return $http.post('/api/documents/prepare_for_prefill', {
      document: this.toJSON(),
    });
  };

  return Document;
}
