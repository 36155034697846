'use strict';

angular
  .module('controller.modal.account-nickname', ['service.adaptive-views'])
  .controller('accountNicknameCtrl', [
    '$scope',
    'ram',
    'adaptiveViews',
    'account',
    accountNicknameController,
  ]);

function accountNicknameController($scope, ram, adaptiveViews, account) {
  $scope.account = account;
  $scope.isSafari = adaptiveViews.isSafari;
  $scope.nickname = new ram.Accessor(account.nickname());

  $scope.saveNicknameAndExit = function () {
    account.nickname($scope.nickname());
    account.save().then(function () {
      $scope.$dismiss();
    });
  };
}
