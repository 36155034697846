'use strict';

angular
  .module('controller.advisor-new-account.stale', [])
  .controller('AdvisorNewAccountStaleController', [
    '$scope',
    '$state',
    'config',
    function ($scope, $state, config) {
      $scope.accountType = config.types.Account[$scope.newAccountFlow.accountTypeId()].label;

      $scope.goToDashboard = function () {
        $state.go('dashboard.summary');
      };
    },
  ]);
