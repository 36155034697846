'use strict';

angular
  .module('controller.advise.bulk-client-upload', [
    'directive.document-upload',
    'service.user-notifications',
  ])
  .controller('AdviseBulkClientUploadCtrl', [
    '$scope',
    '$http',
    '$window',
    '$document',
    '$timeout',
    'userNotifications',
    adviseBulkClientUploadController,
  ]);

function adviseBulkClientUploadController(
  $scope,
  $http,
  $window,
  $document,
  $timeout,
  userNotifications
) {
  $scope.isProcessing = false;
  $scope.processIsDone = false;
  $scope.uploadedFileHasErrorMessage =
    'Please fix the following problems and upload the file again.';

  $scope.process = function () {
    if (!$scope.file) {
      userNotifications.showError('Please upload a CSV file.');
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText($scope.file, 'utf-8');
    fileReader.onload = function (fileLoadedEvent) {
      $scope.hasError = false;
      $scope.isValidationError = false;
      $scope.isProcessing = true;
      return $http
        .post('/api/advisor/bulk_client_uploads.json', {
          bulk_client_upload: {
            file: fileLoadedEvent.target.result,
          },
        })
        .then(function (result) {
          $scope.numberOfClients = result.data.numberOfClientApplicationsCreated;
          $scope.successMessage =
            $scope.numberOfClients + ' clients have been imported successfully.';
        })
        .catch(function (result) {
          if (result.status === 422) {
            $scope.isValidationError = true;
            $scope.errors = result.data;
          }

          $scope.hasError = true;
        })
        .finally(function () {
          $scope.processIsDone = true;
          $scope.isProcessing = false;
          $timeout(function () {
            $window.scrollTo({
              top: $document[0].getElementById('result').offsetTop - 300,
              behavior: 'smooth',
            });
          }, 500);
        });
    };
  };

  $scope.reformatTheErrors = function (data) {
    let str = String(data);
    str = str.replaceAll(',', ', ');
    return str;
  };

  $scope.downloadTemplate = function () {
    const url = 'api/advisor/bulk_client_uploads/template.csv';
    $window.location = url;
  };

  $scope.openApplications = function () {
    const url = 'advise/new-account-applications';
    $window.location = url;
  };

  $scope.$on('documentUploaded', function (event, attrs, file) {
    userNotifications.clearNotification();
    $scope.processIsDone = false;
    $scope.file = file;
  });
}
