'use strict';

function factory(marked) {
  function link(scope) {
    scope.getLabel = function (option) {
      if (scope.optionLabel) {
        return _.deepAccess(option, scope.optionLabel);
      } else if (_.has(option, 'label')) {
        return _.deepAccess(option, 'label');
      } else {
        return option;
      }
    };
    scope.getValue = function (option) {
      if (scope.optionValue) {
        return _.deepAccess(option, scope.optionValue);
      } else if (_.has(option, 'value')) {
        return _.deepAccess(option, 'value');
      } else {
        return option;
      }
    };
    scope.getDescription = function (option) {
      if (scope.descriptionValue) {
        return _.deepAccess(option, scope.descriptionValue);
      } else if (_.has(option, 'descriptionMd')) {
        var markDown = _.deepAccess(option, 'descriptionMd') || '';
        return marked(markDown);
      } else if (_.has(option, 'description')) {
        return _.deepAccess(option, 'description');
      }
      return null;
    };
    scope.getHeadline = function (option) {
      if (scope.optionHeadline) {
        return _.deepAccess(option, scope.optionHeadline);
      } else if (_.has(option, 'headline')) {
        return _.deepAccess(option, 'headline');
      } else {
        return option;
      }
    };
    scope.hasHeadline = function (option) {
      return option.headline !== undefined;
    };
    scope.hasLabel = function (option) {
      return option.label !== undefined;
    };
  }

  return {
    link,
  };
}

angular
  .module('directive.input.option-helper', ['hc.marked'])
  .factory('optionHelper', ['marked', factory]);
