'use strict';

function createDropdownOption(value, labelLeft, labelRight, callback) {
  return {
    value,
    labelLeft,
    labelRight,
    callback,
  };
}

function createCallback($state, accountId) {
  return function () {
    var newState = $state.current;
    return $state.go(newState, {
      accountId,
    });
  };
}

function getAccountRightLabel($filter, account) {
  if (account.isCombinedAccount() || (account.type() && account.status.is.open())) {
    return $filter('currency')(account.balance(), '$', 0);
  }
  return '';
}

function accountsDropdownDirective($state, $filter) {
  return {
    restrict: 'E',
    scope: {
      accounts: '=',
    },
    template:
      '<dropdown options="accountsDropdownOptions" value="{{selectedAccountId}}" disabled="{{disabled}}"></dropdown>',
    controllerAs: 'ctrl',

    link: function ($scope, element, attrs) {
      var dropdownOptions = [];
      var selectedAccountId = $state.params.accountId;

      $scope.disabled = $scope.$eval(attrs.disabled) || false;

      _.each($scope.accounts, function (account) {
        dropdownOptions.push(
          createDropdownOption(
            account.id,
            account.clientLabel,
            function () {
              return getAccountRightLabel($filter, account);
            },
            createCallback($state, account.id)
          )
        );
      });
      if (dropdownOptions.length === 1 && !selectedAccountId) {
        dropdownOptions[0].callback();
      }

      $scope.accountsDropdownOptions = dropdownOptions;
      $scope.selectedAccountId = selectedAccountId || '-';
    },
  };
}

angular
  .module('mobile.directive.accounts-dropdown', ['directive.dropdown'])
  .directive('accountsDropdown', ['$state', '$filter', accountsDropdownDirective]);
