'use strict';

function previewPerformanceController($scope, ram) {
  $scope.start = new ram.Accessor();
  $scope.end = new ram.Accessor();

  /**
   * Get the hypothetical performance of $10,000 invested at the inception
   * date of the newest fund.
   */
  $scope.$watch('portfolio', function (portfolio) {
    if (portfolio) {
      $scope.performance = portfolio.performance(10000);
    }
  });

  $scope.historicalPoint = {};
}

angular
  .module('controller.preview.performance', [
    'directive.chart.area',
    'directive.chart.timeframe',
    'ram',
  ])
  .controller('PreviewPerformanceController', ['$scope', 'ram', previewPerformanceController]);
