'use strict';

function adviseReferralPaymentsController($scope, referralPayments, referralService) {
  $scope.referralPayments = referralPayments;
  $scope.referralService = referralService;

  $scope.referralPaymentStates = ['new-locked', 'new-unlocked', 'paid-locked', 'paid-unlocked'];
}

angular
  .module('controller.advise.referral-payments', ['service.advisor.referral-service'])
  .controller('AdviseReferralPaymentsCtrl', [
    '$scope',
    'referralPayments',
    'referralService',
    adviseReferralPaymentsController,
  ]);
