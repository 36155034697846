'use strict';

angular
  .module('model.AdvisorNotificationSubscription', ['ram', 'config', 'model.Person'])
  .factory('AdvisorNotificationSubscription', ['ram', 'config', '$http', factory]);

function factory(ram, config, $http) {
  var AdvisorNotificationSubscription = new ram.Collection('AdvisorNotificationSubscription', {
    bind: ['load', 'save'],
    belongsTo: {
      advisor: 'Person',
    },
    enums: {
      advisorNotification: config.types.AdvisorNotification,
    },
    resources: {
      default: new ram.resources.Http('/api/advisor_notification_subscriptions/:id.json'),
      cookie: new ram.resources.Cookie('advisor_notification_subscriptions'),
    },
    schema: config.schemas.AdvisorNotificationSubscription,
  });

  AdvisorNotificationSubscription.load = function () {
    return $http.get('/api/advisor_notification_subscriptions/load.json');
  };

  AdvisorNotificationSubscription.save = function (valuesToSave) {
    const objectToSave = {};
    valuesToSave.forEach((tuple) => (objectToSave[tuple[0]] = tuple[1]));
    return $http.post('/api/advisor_notification_subscriptions/save.json', {
      advisorNotificationSubscription: objectToSave,
    }); // Deal with errors in calling code
  };

  return AdvisorNotificationSubscription;
}
