'use strict';

angular
  .module('controller.referral-only', [
    'ram',
    'service.form-helper',
    'service.leadDynoVisitorNotifier',
  ])
  .controller('ReferralOnlyCtrl', [
    '$scope',
    '$stateParams',
    'formHelper',
    'leadDynoVisitorNotifier',
    'ram',
    controller,
  ]);

function controller($scope, $stateParams, formHelper, leadDynoVisitorNotifier, ram) {
  $scope.feedbackMessage = {};
  $scope.promoCode = new ram.Accessor();
  $scope.validationResponse = {
    message: null,
    validCode: null,
  };

  if ($stateParams.invalidAffiliateCode) {
    $scope.feedbackMessage.error = {
      invalidAffiliateCode: true,
    };
  }

  $scope.validateCode = function (formController) {
    $scope.validationResponse.message = null;
    $scope.validationResponse.validCode = null;

    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
      return false;
    }

    const code = $scope.promoCode();

    leadDynoVisitorNotifier.getAffiliatePromoInfo(code).then(function (affiliateInfo) {
      if (affiliateInfo) {
        leadDynoVisitorNotifier.recordVisitor(affiliateInfo.code);
        $scope.validationResponse.message = 'The code has been applied';
        $scope.validationResponse.validCode = true;
      } else {
        $scope.validationResponse.message = 'The code does not exist or is expired';
        $scope.validationResponse.validCode = false;
      }
    });
  };
}
