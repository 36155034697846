'use strict';

angular
  .module('controller.dashboard.transfer-account', ['directive.account-transfer-form'])
  .controller('DashboardTransferAccountCtrl', [
    '$scope',
    'transferableAccounts',
    transferAccountController,
  ]);

function transferAccountController($scope, transferableAccounts) {
  $scope.transferableAccounts = transferableAccounts;
}
