'use strict';
import React, { useState, useEffect } from 'react';
import AccountsReport from './AccountsReport';
import ForeignAssetReport from './ForeignAssetReport';
import RealizedGainLossReport from './RealizedGainLossReport';
import RealizedIncomeReport from './RealizedIncomeReport';
import EftContributionReport from './EftContributionReport';
import KycStatusReport from './KycStatusReport';
import BeneficiaryReport from './BeneficiaryReport';
import ClientContactListReport from './ClientContactListReport';

// DataWidget allows us to multiplex the different report widgets, picking one to host at a time.
const DataWidget = ({ Widget, ...props }) => {
  return <Widget {...props} />;
};

const AdvisorReports = ({ accounts, csrfToken, userNotifications, config }) => {
  const [state, setState] = useState('');
  const [timeoutId, setTimeoutId] = useState(0);
  const [reportIsRunning, setReportIsRunning] = useState(true);
  const [existingReports, setExistingReports] = useState(null);
  const [retryAutomatically] = useState(true);
  const reports = [
    { value: 'accounts_report', text: 'Accounts Report', widget: AccountsReport },
    {
      value: 'eft_contribution_report',
      text: 'EFT Contribution Report',
      widget: EftContributionReport,
    },
    { value: 'foreign_asset_report', text: 'Foreign Asset Report', widget: ForeignAssetReport },
    {
      value: 'realized_gain_loss_report',
      text: 'Realized Gain Loss Report',
      widget: RealizedGainLossReport,
    },
    {
      value: 'realized_income_report',
      text: 'Realized Income Report',
      widget: RealizedIncomeReport,
    },
    { value: 'kyc_status_report', text: 'KYC Status Report', widget: KycStatusReport },
    { value: 'beneficiary_report', text: 'Beneficiary Report', widget: BeneficiaryReport },
    {
      value: 'client_contact_list_report',
      text: 'Client Contact List Report',
      widget: ClientContactListReport,
    },
  ];

  const checkRunning = (result) => {
    const isRunning = result.some((record) => {
      return !record.completed_at;
    });
    setReportIsRunning(isRunning);
    if (!isRunning) {
      userNotifications.clearNotification();
    }
  };

  const loadExistingReports = () => {
    if (!reports[state]) {
      return;
    }
    fetch(`api/advisor/advisor_reports?name=${reports[state].value}`, {})
      .then((res) => res.json())
      .then(
        (result) => {
          checkRunning(result);
          setExistingReports(result);
        },
        (error) => {
          setExistingReports(null);
          userNotifications.showError(error);
        }
      );
  };

  useEffect(() => {
    if (retryAutomatically) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      setTimeoutId(
        setTimeout(() => {
          loadExistingReports();
        }, 5000)
      );
    }
  }, [existingReports]);

  const downloadUrl = (report) => {
    return `api/advisor/advisor_reports/${report.id}/download_report`;
  };

  const duration = (report) => {
    const endTime = report.completed_at ? Date.parse(report.completed_at) : Date.now();
    const diffMs = Math.abs(endTime - Date.parse(report.created_at));
    const secs = Math.abs(diffMs) / 1000;
    if (secs < 60) {
      return `${secs.toFixed(2)} seconds`;
    }
    const mins = secs / 60;
    return `${mins.toFixed(2)} minutes`;
  };

  const createdAt = (report) => {
    const createdAt = new Date(Date.parse(report.created_at));
    var year = createdAt.toLocaleString('default', { year: 'numeric' });
    var month = createdAt.toLocaleString('default', { month: '2-digit' });
    var day = createdAt.toLocaleString('default', { day: '2-digit' });
    var hour = createdAt.toLocaleString('default', { hour: '2-digit', hour12: false });
    var minute = createdAt.toLocaleString('default', { minute: '2-digit' });
    var second = createdAt.toLocaleString('default', { second: '2-digit' });

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const paramsText = (params) => {
    const parsed = _.camelizeKeys(JSON.parse(params));
    if (parsed.startDate && parsed.endDate) {
      return `${parsed.startDate} - ${parsed.endDate}`;
    }
    if (parsed.endDate) {
      return `${parsed.endDate}`;
    }
    if (parsed?.options?.groupId) {
      return `Group Id: ${parsed.options.groupId}`;
    }
    return 'N/A';
  };

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    loadExistingReports();
  }, [state]);

  const handleChange = (event) => {
    userNotifications.clearNotification();
    setReportIsRunning(true);
    setState(event.target.value);
  };

  const afterGenerateReport = () => {
    setReportIsRunning(true);
  };

  return (
    <div className="container">
      <h2>Reports</h2>
      <div className="panel panel-default">
        <div style={{ justifyContent: 'left', display: 'flex' }} className="panel-body">
          <div className="col-xs-2" style={{ justifyContent: 'right', display: 'flex' }}>
            <dt className="p-tb-5 dt-center-align">Report:</dt>
          </div>
          <div className="col-xs-7">
            <select value={state} onChange={handleChange} className="form-control">
              <option value="" disabled>
                Please select...
              </option>
              {reports.map((report, index) => {
                return (
                  <option key={index} value={index}>
                    {report.text}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      {state && (
        <div className="panel panel-default">
          <div className="panel-body">
            <DataWidget
              Widget={reports[state].widget}
              csrfToken={csrfToken}
              userNotifications={userNotifications}
              accounts={accounts}
              isRunning={reportIsRunning}
              afterGenerateReport={afterGenerateReport}
              config={config}
            />
          </div>
          <div id="user-notification" className="p-15 m-b-0-alert-override" />
        </div>
      )}
      {state && (
        <div className="panel panel-default">
          <div className="panel-body">
            <h4>Previously Generated Reports</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>File</th>
                  <th>Parameters</th>
                  <th>Date Created</th>
                  <th>Status</th>
                  <th>Duration</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(!existingReports || existingReports.length === 0) && (
                  <tr>
                    <td colSpan="99" align="center">
                      <i className="text-muted"> There are no reports to display. </i>
                    </td>
                  </tr>
                )}
                {existingReports &&
                  existingReports.length > 0 &&
                  existingReports.map((report) => {
                    return (
                      <tr key={report.id}>
                        {report.completed_at && (
                          <td>
                            {
                              <a
                                className="download-link"
                                target="_blank"
                                href={downloadUrl(report)}
                                download
                                rel="noreferrer"
                              >
                                <div>
                                  <span>{report.file_name}</span>
                                </div>
                              </a>
                            }
                          </td>
                        )}
                        {!report.completed_at && <td>{report.file_name}</td>}
                        <td>{paramsText(report.params)}</td>
                        <td>{createdAt(report)}</td>
                        <td>
                          <div>{report.completed_at ? 'Completed' : 'Running'}</div>
                        </td>
                        <td>
                          <div> {duration(report)}</div>
                        </td>
                        <td>
                          {report.completed_at && (
                            <a
                              className="download-link"
                              target="_blank"
                              href={downloadUrl(report)}
                              download
                              rel="noreferrer"
                            >
                              <div>
                                <img
                                  className="h-18px m-l-5"
                                  src="/assets/images/icons/file-download.svg"
                                />
                              </div>
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvisorReports;
