'use strict';

function modalChangeEmailController($scope, $state, $modalInstance) {
  $scope.dismiss = function () {
    $modalInstance.dismiss();
    $scope.currentUser.unconfirmedEmail.reset();
    $scope.currentUser.email.reset();
  };

  $scope.changeEmail = function () {
    if ($scope.currentUser.unconfirmedEmail.changed()) {
      // $scope.currentUser.email($scope.currentUser.unconfirmedEmail());
      $scope.currentUser
        .save({
          email: $scope.currentUser.unconfirmedEmail(),
          unconfirmedEmail: $scope.currentUser.unconfirmedEmail(),
        })
        .then(
          function () {
            $modalInstance.close();
          },
          function (result) {
            $scope.errors = result.data.errors;
          }
        );
    } else {
      $modalInstance.close();
    }
  };
}

angular
  .module('controller.modal.change-email', [])
  .controller('ModalChangeEmailCtrl', [
    '$scope',
    '$state',
    '$uibModalInstance',
    modalChangeEmailController,
  ]);
