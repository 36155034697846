'use strict';

class RiskLevelBusinessRules {
  constructor() {
    this.account = undefined;
  }

  canDisplayRisk(account) {
    return !account.portfolioOption.is.savingsPortfolio();
  }

  displayRiskChangeFor(account) {
    if (!account) {
      // This gets called by AngularJS when changing pages from Preview to /onboarding/personal.  The account is undefined then.
      // There is no need to do anything in this case.  It will fail down below.
      return false;
    }
    return !account.portfolioOption.is.savingsPortfolio();
  }
}

export default RiskLevelBusinessRules;
