'use strict';

import accountRifDetailComponent from './account-rif-detail.component';

const AccountRifDetailModule = angular
  .module('component.advisor-dashboard.account-rif-detail', [
    'directive.rif-detail-field',
    'service.form-modification-tracker',
  ])
  .component('advisorDashboardAccountRifDetail', accountRifDetailComponent);

export default AccountRifDetailModule;
