'use strict';

function twoFactorAuthenticatorFactory($rootScope, $http) {
  function addSendCodeMesssage(contactMethod) {
    return {
      sentCodeViaText: contactMethod === 'text',
      sentCodeViaCall: contactMethod === 'call',
      sentCodeViaEmail: contactMethod === 'email',
    };
  }

  function successMessageObject(messageObject) {
    return {
      success: messageObject,
    };
  }

  function errorMessageObject(messageObject) {
    return {
      error: messageObject,
    };
  }

  function handleError(error, messageObject) {
    if (error.status === 401) {
      return errorMessageObject({
        server: error.data.error,
      });
    } else {
      return errorMessageObject(messageObject);
    }
  }

  var activate = function (activationCode) {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .put('/api/users/' + $rootScope.currentUser.id + '/activate_2fa.json', {
        otpAttempt: activationCode,
      })
      .catch(function (error) {
        return handleError(error, {
          activateTwoFA: true,
        });
      });
  };

  var authenticatorAppActivate = function (activationCode) {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .put('/api/users/' + $rootScope.currentUser.id + '/activate_auth_app.json', {
        otpAttempt: activationCode,
      })
      .catch(function (error) {
        return handleError(error, {
          activateAuthApp: true,
        });
      });
  };

  var authenticatorAppDeactivate = function (activationCode) {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .put('/api/users/' + $rootScope.currentUser.id + '/deactivate_auth_app.json', {
        otpAttempt: activationCode,
      })
      .catch(function (error) {
        return handleError(error, {
          deactivateAuthApp: true,
        });
      });
  };

  var authenticatorAppQrCode = function () {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .get('/api/users/' + $rootScope.currentUser.id + '/otp_qr_code.json', {})
      .catch(function (error) {
        return handleError(error, {
          activateAuthApp: true,
        });
      });
  };

  var authenticatorAppPrepare = function () {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .put('/api/users/' + $rootScope.currentUser.id + '/prepare_auth_app.json', {})
      .catch(function (error) {
        return handleError(error, {
          activateAuthApp: true,
        });
      });
  };

  var deactivate = function (code) {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .put('/api/users/' + $rootScope.currentUser.id + '/deactivate_2fa.json', {
        otpAttempt: code,
      })
      .catch(function (error) {
        return handleError(error, {
          deactivateTwoFA: true,
        });
      });
  };

  var deactivateForClient = function (userId) {
    return $http.put('/api/users/' + userId + '/deactivate_client_2fa.json').catch(function () {
      return errorMessageObject({
        deactivateTwoFA: true,
      });
    });
  };

  var getSanitizedDetailsForLogin = function (email, password) {
    return $http.get('/api/authentication_factors/sanitized_details_for_user.json', {
      params: {
        email,
        password,
      },
    });
  };

  var getSanitizedDetailsForPasswordReset = function (passwordResetToken) {
    return $http.get('/api/authentication_factors/sanitized_details_for_user.json', {
      params: {
        passwordResetToken,
      },
    });
  };

  var sendActivationCode = function (phoneNumber, contactMethod) {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .put('/api/users/' + $rootScope.currentUser.id + '/send_2fa_activation_code.json', {
        phoneNumber,
        contactMethod,
      })
      .then(function () {
        return successMessageObject(addSendCodeMesssage(contactMethod));
      })
      .catch(function () {
        return errorMessageObject(addSendCodeMesssage(contactMethod));
      });
  };

  var sendCodeForLoggedInUser = function (contactMethod) {
    if (!$rootScope.currentUser) {
      return false;
    }

    return $http
      .get('/api/users/' + $rootScope.currentUser.id + '/send_2fa_code_for_logged_in_user.json', {
        params: {
          contactMethod,
        },
      })
      .then(function () {
        return successMessageObject(addSendCodeMesssage(contactMethod));
      })
      .catch(function () {
        return errorMessageObject(addSendCodeMesssage(contactMethod));
      });
  };

  var sendCodeForLogin = function (email, password, contactMethod) {
    return $http
      .get('/api/users/send_2fa_code_for_logged_out_user.json', {
        params: {
          email,
          password,
          contactMethod,
        },
      })
      .then(function () {
        return successMessageObject(addSendCodeMesssage(contactMethod));
      })
      .catch(function () {
        return errorMessageObject(addSendCodeMesssage(contactMethod));
      });
  };

  var sendCodeForPasswordReset = function (passwordResetToken, contactMethod) {
    return $http
      .get('/api/users/send_2fa_code_for_logged_out_user.json', {
        params: {
          passwordResetToken,
          contactMethod,
        },
      })
      .then(function () {
        return successMessageObject(addSendCodeMesssage(contactMethod));
      })
      .catch(function () {
        return errorMessageObject(addSendCodeMesssage(contactMethod));
      });
  };

  return {
    activate,
    authenticatorAppActivate,
    authenticatorAppDeactivate,
    authenticatorAppQrCode,
    authenticatorAppPrepare,
    deactivate,
    deactivateForClient,
    getSanitizedDetailsForLogin,
    getSanitizedDetailsForPasswordReset,
    sendActivationCode,
    sendCodeForLoggedInUser,
    sendCodeForLogin,
    sendCodeForPasswordReset,
  };
}
angular
  .module('service.two-factor-authenticator', [])
  .factory('twoFactorAuthenticator', ['$rootScope', '$http', twoFactorAuthenticatorFactory]);
