'use strict';

angular
  .module('controller.marketing.rrsp-info', [
    'directive.open-account-lead',
    'directive.social-media-icons',
    'service.fee-calculator',
  ])
  .controller('marketingrrspInfoCtrl', [
    '$scope',
    'feeCalculator',
    'ram',
    'presetQuestion',
    'storedGoal',
    'storedUser',
    rrspInfoCtrl,
  ]);

function rrspInfoCtrl($scope, feeCalculator, ram, question, storedGoal, storedUser) {
  angular.extend($scope, {
    goal: storedGoal,
    question, // Necessary for open account lead
    user: storedUser,
  });

  $scope.goal.preset('Save for Retirement');
  $scope.goal.save();

  //= ========= Start - Savings Calculator ====================================//

  angular.extend($scope, {
    annualContribution: new ram.Accessor(5000),
    currentBalance: new ram.Accessor(25000),
    minAge: 18,
    maxAge: 150,
    saveIfValid,
    tmpAge: new ram.Accessor($scope.user.age()),
  });

  function saveIfValid(age) {
    if ($scope.tmpAge() >= 18 && $scope.tmpAge() <= 150) {
      $scope.user.age(age);
      $scope.user.save();
    }
  }

  function calculateSavings(userAge, currentBalance, annualContribution) {
    currentBalance = currentBalance || 0;
    annualContribution = annualContribution || 0;
    var age = userAge;
    var annualRoR = feeCalculator.getAnnualRoR();
    var avgMutualFundFee = feeCalculator.getAverageMutualFundRate();
    var portfolioCost = feeCalculator.getPortfolioRate();
    var retirementAge = 65;
    var term = retirementAge - age;

    if (term < 0) {
      term = 0;
    }

    function calcOtherSavings(term, currentSavings, annualContribution) {
      var effectiveRoR = annualRoR - avgMutualFundFee;
      if (term) {
        currentSavings = currentSavings + currentSavings * effectiveRoR + annualContribution;
        return calcOtherSavings(term - 1, currentSavings, annualContribution);
      } else {
        return currentSavings;
      }
    }

    function calcOurSavings(term, currentSavings, annualContribution) {
      var effectiveRoR = annualRoR - feeCalculator.getFeeRate(currentSavings) - portfolioCost;
      if (term) {
        currentSavings = currentSavings + currentSavings * effectiveRoR + annualContribution;
        return calcOurSavings(term - 1, currentSavings, annualContribution);
      } else {
        return currentSavings;
      }
    }

    $scope.annualRoR = annualRoR * 100;
    $scope.ourFeeSmall =
      Math.round((feeCalculator.getSmallAccountFeeRate() + portfolioCost) * 10000) / 100.0;
    $scope.ourFeeLarge =
      Math.round((feeCalculator.getLargeAcountFeeRate() + portfolioCost) * 10000) / 100.0;
    $scope.avgMutualFundFee = Math.round(avgMutualFundFee * 10000) / 100.0;

    return (
      calcOurSavings(term, currentBalance, annualContribution) -
      calcOtherSavings(term, currentBalance, annualContribution)
    );
  }

  function updateSavingsForm() {
    saveIfValid($scope.tmpAge());
    $scope.potentialAdditionalSavings = Math.round(
      calculateSavings($scope.user.age(), $scope.currentBalance(), $scope.annualContribution())
    );
  }

  $scope.$watchGroup(['tmpAge()', 'currentBalance()', 'annualContribution()'], updateSavingsForm);

  //= ========= End - Savings Calculator ======================================//

  //= ========= Social Media Icons ============================================//

  var urlEncode = function (url) {
    return encodeURIComponent(url);
  };

  var shareUrl = 'www.ModernAdvisor.ca/save4refugeechildren';

  $scope.emailShare = function () {
    var emailSubject = 'Support a good cause';
    var emailBodyOne =
      'In collaboration with Save the Children Canada, ModernAdvisor is supporting Syrian refugee children and their families. You can help them reach more refugees by opening a new RRSP with ModernAdvisor, or transferring your existing one to them.';
    var newLinePlusSpace = '%0A%0A';
    var emailBodyTwo = 'Learn more here: www.modernadvisor.ca/save4refugeechildren';

    return (
      'mailto:' +
      '?subject=' +
      urlEncode(emailSubject) +
      '&body=' +
      urlEncode(emailBodyOne) +
      newLinePlusSpace +
      urlEncode(emailBodyTwo)
    );
  };

  $scope.twitterShare = function () {
    var tweetContent =
      'I’m supporting refugee children in Syria by investing my #RRSP @ModernAdvsr. www.modernadvisor.ca/save4refugeechildren #Save4RefugeeChildren @SaveChildrenCan';
    var url =
      'http://twitter.com/share?text=' + urlEncode(tweetContent) + '&url=' + urlEncode(shareUrl);

    window.open(
      url,
      'Share',
      'height=400,width=500,left=200,top=200,chrome=yes,centerscreen=yes,dialog=yes,modal=yes,alwaysRaised=yes'
    );
  };

  $scope.fbShare = function () {
    window.FB.ui({
      method: 'feed',
      display: 'popup',
      picture: 'https://s3-us-west-2.amazonaws.com/modernadvisor-public/Save4Refugees.png',
      name: "Help someone else's future by saving for yours",
      caption: 'modernadvisor.ca',
      description:
        'With your help ModernAdvisor is aiming to donate $20,000 to Save the Children charity to support Syrian refugee children and their families.',
      link: shareUrl,
    });
  };

  //= ========= Social Media Icons End ========================================//
}
