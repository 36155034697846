'use strict';

angular
  .module('routes.advise.client', ['templates', 'model.AdvisorCreateNewClientFlow', 'ui.router'])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function resolveClient(User, $stateParams) {
    return User.find({
      id: $stateParams.id,
    });
  }

  function inspectAuthorizeIdentityVerification(authorizer) {
    return authorizer.inspectPermission('identity_verification');
  }

  function checkPermission(authorizer, requiredPermission) {
    return authorizer.checkPermission(requiredPermission);
  }

  function authorizeSuperAdvisor(authorizer) {
    return checkPermission(authorizer, 'superAdvisor');
  }

  function resolveBlankDocument(config, client, Document) {
    var type = config.types.Document.findByName('unspecified').id;
    return Document.find({
      typeId: type,
      userId: client.id,
    }).then(function () {
      return Document.new({
        typeId: type,
        userId: client.id,
      });
    });
  }

  function resolveFeeCreditAccount(client, FeeCreditAccount) {
    return FeeCreditAccount.where({
      personId: client.person().id,
    }).then(_.first);
  }

  function resolveClientAccounts(client) {
    return client.accounts.ready();
  }

  function resolveClientBankAccounts(client) {
    return client.bankAccounts.ready();
  }

  function resolveClientRelationshipManagerInfo(client) {
    return client.person().relationshipManagerInfo();
  }

  function resolveIdentityVerificationStatus($http, authorizeIdentityVerification, client) {
    if (!authorizeIdentityVerification) {
      return {};
    }

    return $http
      .get('api/advisor/people/' + client.person().id + '/identity_verification_status.json')
      .then(function (response) {
        return response.data;
      });
  }

  function resolveCurrentFeeSchedule($http, authorizeFeeSchedule, client) {
    if (!authorizeFeeSchedule) {
      return {};
    }

    return $http
      .get('api/super_advisor/user/' + client.id + '/current_fee_schedule.json')
      .then(function (response) {
        return response.data;
      });
  }

  function resolvePendingAdvisorInfo($http, authorizeSuperAdvisor, client) {
    if (!authorizeSuperAdvisor) {
      return {};
    }

    return $http
      .get('/api/client_advisor_assignment_flows/' + client.id + '.json')
      .then(function (response) {
        return response.data;
      });
  }

  $stateProvider
    .state('advise.client', {
      url: '/client/{id:[0-9]+}',
      abstract: true,
      controller: 'AdviseClientCtrl',
      resolve: {
        client: ['User', '$stateParams', resolveClient],
        clientRelationshipManagerInfo: ['client', resolveClientRelationshipManagerInfo],
        authorizeSuperAdvisor: ['authorizer', inspectAuthorizeIdentityVerification],
        identityVerificationStatus: [
          '$http',
          'authorizeSuperAdvisor',
          'client',
          resolveIdentityVerificationStatus,
        ],
        pendingAdvisorInfo: ['$http', 'authorizeSuperAdvisor', 'client', resolvePendingAdvisorInfo],
      },
    })
    .state('advise.client.view', {
      url: '',
      controller: 'AdviseClientViewCtrl',
      resolve: {
        clientAccounts: ['client', resolveClientAccounts],
        blankDocument: ['config', 'client', 'Document', resolveBlankDocument],
        feeCreditAccount: ['client', 'FeeCreditAccount', resolveFeeCreditAccount],
        clientBankAccounts: ['client', resolveClientBankAccounts],
        currentFeeSchedule: ['$http', 'authorizeSuperAdvisor', 'client', resolveCurrentFeeSchedule],
        clientAccountGoals: [
          '$q',
          'client',
          function ($q, client) {
            return $q
              .all(
                _.map(client.viewableAccounts(), function (account) {
                  return account && account.accountGoals.ready();
                })
              )
              .then(function (accountGoals) {
                return _.flatten(accountGoals);
              });
          },
        ],
        // Need to load the following from the perspective of the goal.  Starting with the goal.
        // Same as in app/assets/javascripts/routes/dashboard.js:572
        goals: [
          '$q',
          'clientAccountGoals',
          function ($q, accountGoals) {
            return $q
              .all(
                _.map(accountGoals, function (accountGoal) {
                  return accountGoal.goal.ready();
                })
              )
              .then(function (goals) {
                return _.flatten(goals);
              });
          },
        ],
        accountGoals: [
          '$q',
          'goals',
          function ($q, goals) {
            return $q
              .all(
                _.map(goals, function (goal) {
                  return goal.accountGoals.ready();
                })
              )
              .then(function (accountGoals) {
                return _.flatten(accountGoals);
              });
          },
        ],
        accounts: [
          '$q',
          'accountGoals',
          function ($q, accountGoals) {
            return $q.all(
              _.map(accountGoals, function (accountGoal) {
                return accountGoal.account.ready();
              })
            );
          },
        ],
        modelPortfolios: [
          '$q',
          'accountGoals',
          function ($q, accountGoals) {
            return $q.all(
              _.map(accountGoals, function (accountGoal) {
                return accountGoal.modelPortfolio.ready();
              })
            );
          },
        ],
        projections: [
          '$q',
          'modelPortfolios',
          function ($q, modelPortfolios) {
            return $q.all(
              _.map(modelPortfolios, function (modelPortfolio) {
                return modelPortfolio.projections.ready();
              })
            );
          },
        ],
        allocations: [
          '$q',
          'modelPortfolios',
          function ($q, modelPortfolios) {
            return $q
              .all(
                _.map(modelPortfolios, function (modelPortfolio) {
                  return modelPortfolio.allocations.ready();
                })
              )
              .then(function (allocations) {
                return _.flatten(allocations);
              });
          },
        ],
        funds: [
          '$q',
          'allocations',
          function ($q, allocations) {
            return $q.all(
              _.map(allocations, function (allocation) {
                return allocation.fund.ready();
              })
            );
          },
        ],
        transferInstructions: [
          'client',
          'TransferInstruction',
          function (client, TransferInstruction) {
            return TransferInstruction.where({
              userId: client.id,
            });
          },
        ],
        advisorCreateNewClientFlows: [
          'client',
          'AdvisorCreateNewClientFlow',
          function (client, AdvisorCreateNewClientFlow) {
            return AdvisorCreateNewClientFlow.where({
              clientId: client.person().id,
            });
          },
        ],
      },
      templateUrl: 'views/advise/client/view.html',
    })
    .state('advise.client.prepare-documents', {
      url: '/prepare-documents',
      controller: 'AdviseClientPrepareDocumentsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        clientAccounts: ['client', resolveClientAccounts],
      },
      templateUrl: 'views/advise/client/prepare-documents.html',
    })
    .state('advise.client.identity-verification', {
      url: '/identity-verification',
      controller: 'AdviseClientIdentityVerificationCtrl',
      resolve: {},
      templateUrl: 'views/advise/client/identity-verification.html',
    });
}
