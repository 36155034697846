'use strict';

function advisorEftDatasetFactory(ram, config) {
  /**
   * Constructor for PaginatedAccount model.
   * @class
   */
  var AdvisorEftDataset = new ram.Collection('AdvisorEftDataset', {
    schema: config.schemas.AdvisorEftDataset,
    hasMany: {
      bankAccounts: 'BankAccount',
      transferableAccounts: 'Account',
    },
    resources: {
      default: new ram.resources.Http('/api/advisor_eft_datasets/:id.json'),
      cookie: new ram.resources.Cookie('advisor_eft_datasets'),
    },
  });

  return AdvisorEftDataset;
}

angular
  .module('model.AdvisorEftDataset', ['ram', 'config', 'model.BankAccount', 'model.Account'])
  .factory('AdvisorEftDataset', ['ram', 'config', advisorEftDatasetFactory]);
