'use strict';

angular
  .module('directive.open-account-lead', [])
  .directive('openAccountLead', [openAccountLeadDirective]);

function openAccountLeadDirective() {
  return {
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/open-account-lead.html',
    link: function (scope, element, attrs) {
      scope.minAge = 18;
      scope.maxAge = 150;
      scope.buttonLabel = attrs.buttonLabel;
      scope.openAccountType = attrs.openAccountType;
      scope.titleLabel = attrs.titleLabel || 'START YOUR INVESTMENT PLAN';
      scope.titleCenter = attrs.titleCenter || false;
    },
  };
}
