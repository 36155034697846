'use strict';

angular
  .module('component.beneficiary-account-display-all', [
    'service.spouse-or-partner',
    'service.beneficiary-service',
    'filter.masked-text',
  ])
  .component('beneficiaryAccountDisplayAll', {
    templateUrl: 'components/advise/beneficiary-account-display-all.html',
    bindings: {
      advisor: '<',
      beneficiaries: '<',
      successorHolder: '<',
      account: '<',
      client: '<',
    },
    controller: [
      '$scope',
      'spouseOrPartner',
      'beneficiaryService',
      BeneficiaryAccountDisplayAllController,
    ],
    controllerAs: '$ctrl',
  });

function BeneficiaryAccountDisplayAllController($scope, spouseOrPartner, beneficiaryService) {
  $scope.person = this.client.person();
  $scope.currentUser = this.client; // This may be used in the context of superadvisor is the currentUser
  $scope.spouse = $scope.person.spouseRelation() && $scope.person.spouseRelation().relative();
  $scope.beneficiaries = this.beneficiaries;
  $scope.successorHolder = this.successorHolder;

  beneficiaryService.setScope($scope);
  beneficiaryService.setAdvisor(this.advisor);
  beneficiaryService.setAccount(this.account);
  beneficiaryService.inlineSave(false);

  $scope.$on('beneficiary-edit-complete', () => {
    $scope.displayOrganizer = beneficiaryService.advisorBeneficiarySummaryStatement();
  });

  $scope.displayOrganizer = beneficiaryService.advisorBeneficiarySummaryStatement();
}
