'use strict';

angular
  .module('animation.risk-appetite-question', [])
  .animation('.risk-appetite-question', ['$animateCss', riskAppetiteQuestionAnimation]);

function riskAppetiteQuestionAnimation($animateCss) {
  return {
    enter: function (element) {
      if (element.scope().$parent.backward) {
        return $animateCss(element, {
          applyClassesEarly: true,
          event: 'enter',
          removeClass: 'risk-appetite-question-forward',
          structural: true,
          from: {
            opacity: 0,
            left: '-90%',
          },
          to: {
            opacity: 1,
            left: 0,
          },
          duration: 0.2,
          easing: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
          cleanupStyles: true,
        })
          .start()
          .then(function () {
            element.scope().$emit('entered-backward');
          });
      } else {
        return $animateCss(element, {
          event: 'enter',
          structural: true,
        });
      }
    },
  };
}
