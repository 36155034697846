'use strict';

angular
  .module('controller.advise.client-application.joint-applicant', [
    'ram',
    'component.subform.photo-id-upload',
    'service.form-helper',
    'service.spouse-or-partner',
    'service.user-notifications',
    'service.dropdown-options',
    'model.AdvisorCreateNewClientFlow',
  ])
  .controller('AdviseClientApplicationJointApplicantCtrl', [
    '$scope',
    '$state',
    '$http',
    '$timeout',
    '$q',
    'AdvisorCreateNewClientFlow',
    'formHelper',
    'spouseOrPartner',
    'dropdownOptions',
    'client',
    'spouse',
    'jointFrontImage',
    'jointBackImage',
    'newAccountFlow',
    adviseClientApplicationDetailsCtrl,
  ]);

function adviseClientApplicationDetailsCtrl(
  $scope,
  $state,
  $http,
  $timeout,
  $q,
  AdvisorCreateNewClientFlow,
  formHelper,
  spouseOrPartner,
  dropdownOptions,
  client,
  spouse,
  jointFrontImage,
  jointBackImage,
  newAccountFlow
) {
  angular.extend($scope, {
    client,
    spouse,
    jointFrontImage,
    jointBackImage,
    newAccountFlow,
    spouseOrPartner,
  });

  $scope.prefillSpouseIsJointApplicant = true; // Or else we wouldn't be here
  $scope.account = {
    spousalJointAccount: () => true, // Force ng-if at spouse-personal.html:50 to be open.  In this path, there is no radio with the spousal widgets.
  };
  $scope.personalSubforms = {};
  $scope.residencies = dropdownOptions.residencies();

  $scope.spouseOrPartner = function () {
    return spouseOrPartner.relationshipName(client);
  };

  function doSave() {
    return $scope.spouse.save();
  }

  $scope.submit = function (formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      doSave().then(() => goNext(newAccountFlow));
    }
  };

  $scope.cancelAction = function () {
    goToReview();
  };

  function goNext(newAccountFlow) {
    if (newAccountFlow.accountType().beneficiary) {
      $state.go('advise.client-application.beneficiary', {
        newAccountFlowId: newAccountFlow.id,
      });
    } else {
      goToReview();
    }
  }

  function goToReview() {
    $state.go('advise.client-application.review');
  }
}
