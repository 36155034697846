'use strict';

angular
  .module('routes.preview', [
    'model.AdvisorCreateNewClientFlow',
    'model.PortfolioManager',
    'model.PortfolioPreferenceAnswer',
    'controller.preview',
    'templates',
    'ui.router',
    'service.adaptive-views',
    'service.branding-machine',
    'ngCookies',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', 'brandingMachineProvider', configRoutes]);

/**
 * Initialize angular UI router for advisor dashboard.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider, brandingMachineProvider) {
  function resolveAllocations(modelPortfolio) {
    return modelPortfolio && modelPortfolio.allocations.ready();
  }

  function resolveRisk($http, storedGoal, storedUser) {
    var params = {
      financialAssets: storedUser.financialAssets(),
      income: storedUser.income(),
      initial: storedGoal.initial(),
      nonFinancialAssets: storedUser.nonFinancialAssets(),
      ongoing: storedGoal.ongoing(),
      riskAppetite: storedUser.riskAppetite(),
      timeframe: storedGoal.timeframe(),
      totalLiabilities: storedUser.totalLiabilities(),
    };
    return $http.get('api/goals/new/risk.json', {
      params: _.underscoreKeys(params),
    });
  }

  function resolvePortfolioOptionId(
    PortfolioManager,
    storedAccount,
    portfolioOptions,
    defaultPortfolioManagerId
  ) {
    const defaultPortfolioOption =
      PortfolioManager.constructor.defaultPortfolioOption(portfolioOptions);
    const currentOption = portfolioOptions.filter(
      (option) => option.id === storedAccount.portfolioOptionId()
    )[0];

    // Business Logic:
    //   * It is possible that the client flow has a Portfolio Manager that is incompatible with the Portfolio Option on
    //       the account flow.  That would be a data error.  This is normally handled in the Ruby code in the
    //       `default_portfolio_manager_for_user` route on the PortfolioManagersController.  (See `client_flow_pm_id`).
    //   * The following is to prevent inconsistencies in the front end should it ever get called with the wrong parameters.
    //       The if statement below simply changes the portfolio option in the cookie to match the PM.  In this way, the
    //       code in the controller will still offer sensible defaults.
    if (
      _.isNullOrUndefined(currentOption) ||
      Object.keys(currentOption).length === 0 ||
      _.isNullOrUndefined(storedAccount.portfolioOptionId()) ||
      currentOption.portfolioManagerId !== defaultPortfolioManagerId
    ) {
      storedAccount.portfolioOptionId(defaultPortfolioOption.id);
    }
    return storedAccount.save().then(() => storedAccount.portfolioOptionId());
  }

  function resolveModelPortfolio(
    risk,
    storedAccount,
    storedGoal,
    ModelPortfolio,
    portfolioOptionId
  ) {
    storedGoal.riskRecommendation.set(risk.data, true);
    return ModelPortfolio.find({
      risk: storedGoal.risk(),
      taxable: storedAccount.type() && !storedAccount.type().registered,
      portfolioOptionId,
    }).then(function (modelPortfolio) {
      return (
        modelPortfolio &&
        modelPortfolio.projections.ready().then(function () {
          return modelPortfolio;
        })
      );
    });
  }

  function resolveGoal(storedGoal) {
    return storedGoal;
  }

  function resolveAdvisorGroupUrl() {
    var $cookies = angular.injector(['ngCookies']).get('$cookies');
    var referral = $cookies.getObject('group_url_id');
    return referral && referral.urlId;
  }

  function resolveClientFlow(AdvisorCreateNewClientFlow, currentUser) {
    if (!currentUser) {
      return null;
    }
    return AdvisorCreateNewClientFlow.where({
      clientId: currentUser.person().id,
    }).then(function (list) {
      if (list.length === 0) {
        return null;
      }
      return _.first(list);
    });
  }

  function resolveAdvisorReferralDetails(currentUser, clientFlow) {
    return brandingMachineProvider.getAdvisorDetails(
      currentUser,
      clientFlow && clientFlow.advisorReferralCode()
    );
  }

  function resolveRedirectToInterviewPlatform($location, $q, $state, config) {
    const platform = config.env.platform;

    if (platform && platform.name === 'WSI') {
      // From https://stackoverflow.com/a/30592506 Gustav's comment
      // The last `return $q.reject()` was not used as it seems to work without it.
      return $q.reject().catch(function () {
        $state.transitionTo('wsi-interview.goal', $location.search(), {
          location: 'replace',
        });
      });
    }
  }

  function resolvePortfolioManagers(PortfolioManager) {
    return PortfolioManager.where({});
  }

  function resolveDefaultPortfolioManagerId($http, $cookies, User, PortfolioManager, currentUser) {
    const fallback = PortfolioManager.constructor.modernAdvisorId();
    return $http
      .get(
        `/api/portfolio_managers/default_portfolio_manager_for_user.json?user_id=${currentUser?.id}`
      )
      .then((response) => response.data?.portfolioManagerId ?? fallback)
      .catch(() => fallback);
  }

  function resolvePortfolioOptions($http, defaultPortfolioManagerId, currentUser) {
    const userIdPortion = _.isNullOrUndefined(currentUser) ? '' : currentUser.id;
    return $http
      .get(
        `/api/portfolio_managers/${defaultPortfolioManagerId}/portfolio_options.json?user_id=${userIdPortion}`
      )
      .then((result) => result.data?.portfolioOptions);
  }

  function resolveRecommendedPortfolioOption($http, $cookies) {
    const portfolioPreferenceId =
      $cookies.getObject('portfolio_preference_answers')?.[0]?.portfolioPreferenceId ?? 0; // jshint ignore:line
    return $http
      .post('/api/portfolio_preference_answers/recommended_portfolio_option.json', {
        portfolioPreferenceId,
      })
      .then((result) => result.data?.recommendedPortfolioOptionId); // Not a function
  }

  function resolveChosenPortfolioPreferenceAnswer($cookies) {
    return $cookies.getObject('portfolio_preference_answers')?.[0]?.portfolioPreferenceId ?? 0; // jshint ignore:line
  }

  $stateProvider
    .state('preview', {
      url: '/preview',
      parent: 'site',
      abstract: true,
      hasMobileView: true,
      data: {
        title: 'Preview',
        interviewStep: 1,
      },
      resolve: {
        redirectToInterviewPlatform: [
          '$location',
          '$q',
          '$state',
          'config',
          resolveRedirectToInterviewPlatform,
        ],
        risk: ['$http', 'storedGoal', 'storedUser', resolveRisk],
        portfolioManagers: ['PortfolioManager', resolvePortfolioManagers],
        defaultPortfolioManagerId: [
          '$http',
          '$cookies',
          'User',
          'PortfolioManager',
          'currentUser',
          resolveDefaultPortfolioManagerId,
        ],
        portfolioOptions: [
          '$http',
          'defaultPortfolioManagerId',
          'currentUser',
          resolvePortfolioOptions,
        ],
        portfolioOptionId: [
          'PortfolioManager',
          'storedAccount',
          'portfolioOptions',
          'defaultPortfolioManagerId',
          resolvePortfolioOptionId,
        ],
        modelPortfolio: [
          'risk',
          'storedAccount',
          'storedGoal',
          'ModelPortfolio',
          'portfolioOptionId',
          resolveModelPortfolio,
        ],
        advisorGroupUrl: [resolveAdvisorGroupUrl],
        clientFlow: ['AdvisorCreateNewClientFlow', 'currentUser', resolveClientFlow],
        advisorReferralDetails: ['currentUser', 'clientFlow', resolveAdvisorReferralDetails],
        recommendedPortfolioOption: ['$http', '$cookies', resolveRecommendedPortfolioOption],
        chosenPortfolioPreferenceAnswer: ['$cookies', resolveChosenPortfolioPreferenceAnswer],
        ima: [
          'currentUser',
          function (currentUser) {
            if (currentUser) {
              return currentUser.investmentManagementAgreement.ready();
            }
          },
        ],
      },
      views: {
        main: {
          controller: 'PreviewCtrl',
        },
        navbar: {
          templateUrl: 'navbar/interview.html',
        },
        footer: {
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
        // these are here for A/B testing for Variation #1
        'projections@preview': {
          controller: 'PreviewProjectionsController',
          templateUrl: 'views/preview/projections.html',
          resolve: {
            goal: ['storedGoal', resolveGoal],
          },
        },
        'investments@preview': {
          controller: 'PreviewInvestmentsController',
          templateUrl: 'views/preview/investments.html',
          resolve: {
            allocations: ['modelPortfolio', resolveAllocations],
          },
        },
      },
    })
    .state('preview.investments', {
      url: '',
      controller: 'PreviewInvestmentsController',
      resolve: {
        allocations: ['modelPortfolio', resolveAllocations],
      },
    })
    .state('preview.projections', {
      controller: 'PreviewProjectionsController',
      resolve: {
        goal: ['storedGoal', resolveGoal],
      },
    })
    .state('preview.performance', {
      controller: 'PreviewPerformanceController',
    })
    .state('preview.fees', {});
}
