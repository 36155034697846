'use strict';

angular
  .module('controller.wsi-interview.risk', [
    'config',
    'model.Question',
    'directive.user.risk-appetite',
    'service.loading-indicator',
    'service.wsi.risk-calculator',
  ])
  .controller('WsiInterviewRiskController', [
    '$scope',
    '$state',
    '$cookies',
    '$http',
    'config',
    'storedAccount',
    'storedUser',
    'storedGoal',
    'questions',
    'completedRiskAppetiteQuizzes',
    'loadingIndicator',
    'wsiRiskCalculator',
    'storedTrialRiskAppetite',
    'unconstrainedRecommendedRiskLevel',
    controller,
  ]);

function prepareForTrial($scope) {
  $scope.question.values = _.map($scope.riskAppetiteTypes, function (riskAppetiteType) {
    return riskAppetiteType.id;
  });

  $scope.question.prettifyFunction = function (riskAppetiteTypeId) {
    return $scope.riskAppetiteTypes[riskAppetiteTypeId].label;
  };

  $scope.$watch('user.riskAppetite()', function (newRiskAppetiteValue) {
    if (!newRiskAppetiteValue) {
      return;
    }

    var newRiskAppetiteType = _.findWhere($scope.riskAppetiteTypes, {
      value: newRiskAppetiteValue,
    });
    $scope.trialRiskAppetite.riskAppetiteTypeId(newRiskAppetiteType.id);
    $scope.trialRiskAppetite.save();
  });

  $scope.$watch('trialRiskAppetite.riskAppetiteType()', function (newRiskAppetiteType) {
    if (!newRiskAppetiteType) {
      return;
    }

    $scope.user.riskAppetite(newRiskAppetiteType.value);
    $scope.user.save();
  });

  if (!$scope.trialRiskAppetite.riskAppetiteType()) {
    var veryConservativeRiskType = $scope.riskAppetiteTypes.findByName('very_conservative');
    $scope.trialRiskAppetite.riskAppetiteTypeId(veryConservativeRiskType.id);
    $scope.trialRiskAppetite.save();
  }
}

function controller(
  $scope,
  $state,
  $cookies,
  $http,
  config,
  account,
  user,
  goal,
  questions,
  completedRiskAppetiteQuizzes,
  loadingIndicator,
  wsiRiskCalculator,
  storedTrialRiskAppetite,
  unconstrainedRecommendedRiskLevel
) {
  $scope.trialRiskAppetite = storedTrialRiskAppetite;
  $scope.riskAppetiteTypes = config.types.RiskAppetiteType;

  $scope.questions = _.indexBy(questions, function (question) {
    return question.accessor();
  });

  $scope.riskSurveyComplete = completedRiskAppetiteQuizzes.length > 0;

  var removePreviousCompletedRiskAppetiteQuizzesFromCookies = function () {
    // TODO: Somehow removing the completed quizzes through ram does not work
    $cookies.remove('completed_risk_appetite_quizzes');
  };

  var openAndHandleAppetiteModalForLoggedInUser = function () {
    var completedRiskAppetiteQuiz;
    var riskAppetite;

    $scope
      .appetiteModal()
      .result.then(function (completedRiskAppetiteQuizFromModal) {
        loadingIndicator.show();
        completedRiskAppetiteQuiz = completedRiskAppetiteQuizFromModal;
        return completedRiskAppetiteQuiz.save();
      })
      .then(function () {
        $scope.riskSurveyComplete = true;
        var calculatedRiskAppetiteTypeId = completedRiskAppetiteQuiz
          .riskAppetiteCalculations()[0]
          .riskAppetiteTypeId();
        riskAppetite = config.types.RiskAppetiteType[calculatedRiskAppetiteTypeId].value;
        $scope.currentUser.riskAppetite(riskAppetite);
        return $scope.currentUser.save();
      })
      .then(function () {
        user.riskAppetite(riskAppetite);
        return user.save();
      })
      .then(resolveUnconstrainedRecommendedRiskLevel)
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  var openAndHandleAppetiteModalForVisitor = function () {
    var completedRiskAppetiteQuiz;

    $scope
      .appetiteModal({
        useCookie: true,
      })
      .result.then(function (completedRiskAppetiteQuizFromModal) {
        loadingIndicator.show();
        removePreviousCompletedRiskAppetiteQuizzesFromCookies();
        completedRiskAppetiteQuiz = completedRiskAppetiteQuizFromModal;
        return completedRiskAppetiteQuiz.save();
      })
      .then(function () {
        $scope.riskSurveyComplete = true;
        return completedRiskAppetiteQuiz.calculateRiskAppetiteTypeId();
      })
      .then(function (calculatedRiskAppetiteTypeId) {
        var riskAppetite = config.types.RiskAppetiteType[calculatedRiskAppetiteTypeId].value;
        user.riskAppetite(riskAppetite);
        return user.save();
      })
      .then(resolveUnconstrainedRecommendedRiskLevel)
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  $scope.openAndHandleAppetiteModal = function () {
    if ($scope.currentUser) {
      openAndHandleAppetiteModalForLoggedInUser();
    } else {
      openAndHandleAppetiteModalForVisitor();
    }
  };

  $scope.account = account;
  $scope.question = $scope.questions.riskAppetite;
  $scope.user = user;
  $scope.goal = goal;

  if ($scope.account.type.is.trial()) {
    prepareForTrial($scope);
  }

  // ---------------------------------------------------------------------------
  $scope.unconstrainedRecommendedRiskLevel = unconstrainedRecommendedRiskLevel;

  function resolveUnconstrainedRecommendedRiskLevel() {
    const params = {
      financialAssets: $scope.user.financialAssets(),
      income: $scope.user.income(),
      initial: $scope.goal.initial(),
      nonFinancialAssets: $scope.user.nonFinancialAssets(),
      ongoing: $scope.goal.ongoing(),
      riskAppetite: $scope.user.riskAppetite(),
      timeframe: $scope.goal.timeframe(),
      totalLiabilities: $scope.user.totalLiabilities(),
    };

    return $http
      .get('api/goals/new/wsi_unconstrained_recommended_risk_level.json', {
        params: _.underscoreKeys(params),
      })
      .then(function (riskResponse) {
        $scope.unconstrainedRecommendedRiskLevel = riskResponse.data;
      });
  }

  function hasReturnObjectiveConflict() {
    return wsiRiskCalculator.hasConflict(
      $scope.account.returnObjective(),
      $scope.unconstrainedRecommendedRiskLevel
    );
  }

  // ---------------------------------------------------------------------------

  $scope.$parent.nextSection = function () {
    if (
      (!$scope.user.investorType() || !$scope.riskSurveyComplete) &&
      !$scope.account.type.is.trial()
    ) {
      $scope.$parent.errorMessage = 'You have to take the Risk Appetite Quiz before continuing';
      // When on mobile next button opens modal
      if ($scope.isMobile) {
        $scope.openAndHandleAppetiteModal();
      }
    } else {
      if ($scope.account.returnObjective() && hasReturnObjectiveConflict()) {
        $state.go($state.current.data.next);
      } else {
        $state.go('wsi-optimizing');
      }
    }
  };
  $scope.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;
}
