export const rules = [
  {
    from: 'non_registered',
    to: 'non_registered',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'non_registered',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'tfsa',
    to: 'non_registered',
    ruleValue: 'warning',
    text: 'tfsa_withdrawal',
  },
  {
    from: 'individual_rrsp',
    to: 'non_registered',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'spousal_rrsp',
    to: 'non_registered',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'individual_rif',
    to: 'non_registered',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'spousal_rif',
    to: 'non_registered',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'individual_resp',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'rdsp',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'non_registered',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'non_registered',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'tfsa',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'warning',
    text: 'tfsa_withdrawal',
  },
  {
    from: 'individual_rrsp',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'spousal_rrsp',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'individual_rif',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'joint_with_right_of_survivorship',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'non_registered',
    to: 'tfsa',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'tfsa',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'tfsa',
    to: 'tfsa',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'individual_rrsp',
    to: 'tfsa',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'spousal_rrsp',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'spousal_rif',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_resp',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'rdsp',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'tfsa',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'non_registered',
    to: 'individual_rrsp',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'individual_rrsp',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'tfsa',
    to: 'individual_rrsp',
    ruleValue: 'warning',
    text: 'tfsa_withdrawal',
  },
  {
    from: 'individual_rrsp',
    to: 'individual_rrsp',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'spousal_rrsp',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'spousal_rif',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_resp',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'individual_rrsp',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'corporate',
    to: 'individual_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'non_registered',
    to: 'spousal_rrsp',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'spousal_rrsp',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'tfsa',
    to: 'spousal_rrsp',
    ruleValue: 'warning',
    text: 'tfsa_withdrawal',
  },
  {
    from: 'individual_rrsp',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_resp',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'spousal_rrsp',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'corporate',
    to: 'spousal_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'tfsa',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rrsp',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'spousal_rrsp',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rif',
    to: 'individual_rif',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'spousal_rif',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_resp',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'rdsp',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'individual_rif',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'corporate',
    to: 'individual_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'tfsa',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rrsp',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rrsp',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'spousal_rif',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'individual_resp',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'spousal_rif',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'corporate',
    to: 'spousal_rif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'individual_resp',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'tfsa',
    to: 'individual_resp',
    ruleValue: 'warning',
    text: 'tfsa_withdrawal',
  },
  {
    from: 'individual_rrsp',
    to: 'individual_resp',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'spousal_rrsp',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'family_resp',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'lira',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'individual_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'family_resp',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'tfsa',
    to: 'family_resp',
    ruleValue: 'warning',
    text: 'tfsa_withdrawal',
  },
  {
    from: 'individual_rrsp',
    to: 'family_resp',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'spousal_rrsp',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'family_resp',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'lira',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'family_resp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'tfsa',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rrsp',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rrsp',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rif',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'lira',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'locked_in_rrsp',
    to: 'lira',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'lif',
    to: 'lira',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'rdsp',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'lira',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'tfsa',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rrsp',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rrsp',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rif',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'locked_in_rrsp',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'lif',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'rdsp',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'locked_in_rrsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'tfsa',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rrsp',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rrsp',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rif',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'lif',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'locked_in_rrsp',
    to: 'lif',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'lif',
    to: 'lif',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'rdsp',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'lif',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'rdsp',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'tfsa',
    to: 'rdsp',
    ruleValue: 'warning',
    text: 'tfsa_withdrawal',
  },
  {
    from: 'individual_rrsp',
    to: 'rdsp',
    ruleValue: 'warning',
    text: 'rrsp_withdrawal',
    moreInfo: 'rrsp_withdrawal_info',
  },
  {
    from: 'spousal_rrsp',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'rdsp',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'fhsa',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'fhsa',
    ruleValue: 'warning',
    text: 'transfer_to_registered_gsub',
  },
  {
    from: 'tfsa',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rrsp',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'spousal_rrsp',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_rif',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'spousal_rif',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'individual_resp',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'fhsa',
    ruleValue: 'ok',
    text: 'permitted',
  },
  {
    from: 'corporate',
    to: 'fhsa',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'non_registered',
    to: 'corporate',
    ruleValue: 'warning',
    text: 'capital_gain_loss',
  },
  {
    from: 'joint_with_right_of_survivorship',
    to: 'corporate',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'tfsa',
    to: 'corporate',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rrsp',
    to: 'corporate',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'spousal_rrsp',
    to: 'corporate',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_rif',
    to: 'corporate',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'spousal_rif',
    to: 'corporate',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
  {
    from: 'individual_resp',
    to: 'corporate',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'family_resp',
    to: 'corporate',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lira',
    to: 'corporate',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'locked_in_rrsp',
    to: 'corporate',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'lif',
    to: 'corporate',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'rdsp',
    to: 'corporate',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'fhsa',
    to: 'corporate',
    ruleValue: 'error',
    text: 'not_permitted',
  },
  {
    from: 'corporate',
    to: 'corporate',
    ruleValue: 'error',
    text: 'please_contact_gsub',
  },
];

/* jshint ignore: start */
export const errorText = {
  not_permitted: {
    ruleValue: 'error',
    colour: 'red',
    text: 'This type of transfer is not permitted.',
  },
  please_contact_gsub: {
    ruleValue: 'error',
    colour: 'blue',
    text: 'If you want to transfer from {{an_account_type_from}} to {{an_account_type_to}}, please contact us via chat or email at <strong>support@modernadvisor.ca</strong>',
  },
  capital_gain_loss: {
    ruleValue: 'warning',
    colour: 'yellow',
    text: 'This transfer may trigger capital gains or losses.',
  },
  tfsa_withdrawal: {
    ruleValue: 'warning',
    colour: 'yellow',
    text: 'TFSA withdrawal amounts are only added back to your contribution room at the beginning of next calendar year. You may only contribute withdrawn amounts in the same calendar year if you have available TFSA contribution room.',
  },
  rrsp_withdrawal: {
    ruleValue: 'warning',
    colour: 'yellow',
    text: 'RRSP withdrawals are subject to a withdrawal fee of $25+GST/HST (or $50+GST/HST for partial and $75+GST/HST for full withdrawal at Credential).',
    moreInfo: 'rrsp_withdrawal_info',
  },
  rrsp_withdrawal_info: {
    ruleValue: 'info',
    text: 'A withdrawal from your RRSP is subject to income tax witholding. The RRSP income tax withholding rate varies depending on the amount withdrawn.',
  },
  transfer_to_registered_gsub: {
    ruleValue: 'warning',
    colour: 'yellow',
    text: 'This transfer may trigger capital gains or losses. Please ensure you have enough {{account_type_to}} contribution room before proceeding.',
  },
  custodian: {
    ruleValue: 'error',
    colour: 'red',
    text: 'The above accounts are at different custodians.  Please contact us via chat or email at <strong>support@modernadvisor.ca</strong>',
  },
  permitted: {
    ruleValue: 'ok',
  },
};
/* jshint ignore: end */
