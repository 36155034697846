'use strict';

// import React from 'react'; // jshint ignore:line
import maReact2Angular from '../maReact2Angular';
import UploadFundPricesModal from './UploadFundPricesModal';

angular
  .module('react.component.advise.upload-fund-prices', [
    'config',
    'csrf',
    'service.user-notifications',
  ])
  .component(
    'reactUploadFundPricesModal',
    maReact2Angular(
      UploadFundPricesModal,
      {
        dismiss: '<',
        close: '<',
      },
      ['csrfToken', 'userNotifications']
    )
  );
