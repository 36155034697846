'use strict';

angular
  .module('controller.modal.invite-client', [
    'service.promotion-redemption-cookie-creator',
    'service.link-copy-handler',
  ])
  .controller('ModalInviteClientCtrl', [
    '$scope',
    '$rootScope',
    '$timeout',
    'config',
    'inviteLinkInfo',
    'promotionRedemptionCookieCreator',
    'linkCopyHandler',
    inviteClientCtrl,
  ]);

function inviteClientCtrl(
  $scope,
  $rootScope,
  $timeout,
  config,
  inviteLinkInfo,
  promotionRedemptionCookieCreator,
  linkCopyHandler
) {
  $scope.togglePrefillFeature = config.env.featureToggles.advisorCreateClient;
  $scope.groupUrlAndLink = inviteLinkInfo.groupUrlAndLink;
  $scope.portfolioManagerId = inviteLinkInfo.portfolioManagerId;
  $scope.mailToLink =
    'subject=Open%20your%20investment%20account&' +
    'body=Please%20use%20this%20link%20to%20open%20your%20account%20' +
    inviteLinkInfo.url +
    '&';
  $scope.inviteLinkUrl = inviteLinkInfo.url;
  $scope.onboard = function () {
    promotionRedemptionCookieCreator.createAdvisorReferral(
      $scope.groupUrlAndLink,
      $scope.portfolioManagerId
    );
    return $rootScope.logout('/interview');
  };
  $scope.isWfmAdvisor = !!inviteLinkInfo.url.match(/\/adv\/wfm-/);

  $scope.onLinkCopyError = function (e) {
    linkCopyHandler.onLinkCopyError(e);
  };

  $scope.onLinkCopied = function (e) {
    linkCopyHandler.onLinkCopied(e, '<i class="fa fa-check-circle fc-green fs-12px"></i> Copied!');
  };
}
