import template from './advisor-disclosure-form.html';
import controller from './advisor-disclosure-form.controller';

const advisorDisclosureFormComponent = {
  template,
  bindings: {
    advisorName: '<',
    institutionName: '<',
    disclosures: '<',
    preferredPortfolioManagerId: '<',
    hasServiceFee: '<',
    showGroupUnderOneName: '<',
  },
  controller: ['$scope', 'ram', 'PortfolioManager', controller],
  controllerAs: '$ctrl',
};

export default advisorDisclosureFormComponent;
