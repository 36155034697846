'use strict';

angular
  .module('controller.onboarding.congratulations', [
    'ram',
    'directive.secured',
    'service.account-service',
    'service.securityService',
  ])
  .controller('OnboardingCongratulationsController', [
    '$scope',
    'currentUser',
    'account',
    'isFirstRealAccount',
    'accountService',
    'securityServiceFactory',
    onboardingCongratulationsController,
  ]);

function onboardingCongratulationsController(
  $scope,
  currentUser,
  account,
  isFirstRealAccount,
  accountService,
  securityServiceFactory
) {
  var currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);

  $scope.$parent.done = function () {
    return true;
  };

  $scope.noDocumentsToSign = accountService.noDocumentsToSign(currentUser, account);

  $scope.isPersonalClientOpeningFirstAccountWithoutAdvisor =
    currentUserSecurity.isPersonalClient() &&
    isFirstRealAccount &&
    !$scope.account.advisorCreateNewAccountFlow();
}
