'use strict';

angular
  .module('model.SelectedRiskAppetiteQuizChoice', [
    'model.CompletedRiskAppetiteQuiz',
    'model.RiskAppetiteQuizQuestion',
    'model.RiskAppetiteQuizChoice',
  ])
  .factory('SelectedRiskAppetiteQuizChoice', ['ram', selectedRiskAppetiteQuizChoiceFactory]);

function selectedRiskAppetiteQuizChoiceFactory(ram) {
  /**
   * Constructor for resource-aware SelectedRiskAppetiteQuizChoice model.
   * @class
   */
  var SelectedRiskAppetiteQuizChoice = new ram.Collection('SelectedRiskAppetiteQuizChoice', {
    belongsTo: {
      completedRiskAppetiteQuiz: 'CompletedRiskAppetiteQuiz',
      riskAppetiteQuizQuestion: 'RiskAppetiteQuizQuestion',
      riskAppetiteQuizChoice: 'RiskAppetiteQuizChoice',
    },
    resources: {
      default: new ram.resources.Http('/api/selected_risk_appetite_quiz_choices/:id.json'),
      cookie: new ram.resources.Cookie('selected_risk_appetite_quiz_choices'),
    },
  });

  return SelectedRiskAppetiteQuizChoice;
}
