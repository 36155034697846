'use strict';

angular
  .module('controller.advise.add-client', [
    'controller.advise.add-client.add-advisors',
    'controller.advise.add-client.service-level',
    'controller.advise.add-client.preferred-portfolio-manager',
  ])
  .controller('AdviseAddClientCtrl', [
    '$scope',
    'advisorDetails',
    'ram',
    adviseAddClientController,
  ]);

function adviseAddClientController($scope, advisorDetails, ram) {
  angular.extend($scope, {
    referralOption: {
      options: undefined,
      selection: new ram.Accessor(undefined),
    },
    primaryAdvisor: {
      advisorId: new ram.Accessor(null),
      commissionSplit: new ram.Accessor(null),
    },
  });

  $scope.getUrlId = function () {
    var serviceLevel = $scope.referralOption.selection();
    var groupUrlId = advisorDetails.urlId;

    if ($scope.primaryAdvisor.advisorId()) {
      groupUrlId = _.findWhere(advisorDetails.institutionAdvisors, {
        id: $scope.primaryAdvisor.advisorId(),
      }).groupUrlId;
    }

    if (serviceLevel) {
      return groupUrlId + serviceLevel;
    } else {
      return groupUrlId;
    }
  };
}
