'use strict';

angular
  .module('service.open-modals', ['ui.bootstrap.modal'])
  .factory('openModals', ['$uibModalStack', openModalsService]);

/**
 * Service that dismisses all modals that are currently open.
 * Makes use of `$modalStack` defined in `modal.js` in `angular-ui`
 * See https://github.com/angular-ui/bootstrap/blob/master/src/modal/modal.js
 * for more info.
 */

function openModalsService($modalStack) {
  return {
    dismissAll: function () {
      $modalStack.dismissAll();
    },
  };
}
