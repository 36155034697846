'use strict';

angular
  .module('model.InvestmentManagementAgreement', ['ram', 'config'])
  .factory('InvestmentManagementAgreement', ['ram', 'config', factory]);

function factory(ram, config) {
  const InvestmentManagementAgreement = new ram.Collection('InvestmentManagementAgreement', {
    belongsTo: {
      user: 'User',
    },
    resources: {
      default: new ram.resources.Http('/api/investment_management_agreements/:id.json'),
      cookie: new ram.resources.Cookie('investment_management_agreements'),
    },
    schema: config.schemas.InvestmentManagementAgreement,
  });

  return InvestmentManagementAgreement;
}
