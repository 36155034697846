'use strict';
// import React from 'react'; // jshint ignore:line
import maReact2Angular from '../maReact2Angular';
import CallAttentionDot from './callAttentionDot';

angular.module('react.component.icons.call-attention-dot', []).component(
  'callAttentionDot',
  maReact2Angular(
    CallAttentionDot,
    {
      size: '@',
      marginTop: '@',
      marginLeft: '@',
    },
    []
  )
);
