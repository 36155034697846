'use strict';

function factory() {
  /* jshint ignore:start */
  return {
    start: function (currentUser) {
      window.wootricSettings = {
        email: currentUser.email,
        external_id: currentUser.id,
        created_at: Math.floor(new Date(currentUser.createdAt()).getTime() / 1000),
        account_token: window.config.env.wootricToken,
      };
      window.wootric_survey_immediately = window.config.env.wootricDebug;

      if (window.wootric) {
        window.wootric('run');
      }
    },
  };
  /* jshint ignore:end */
}

angular.module('service.wootric', []).factory('wootric', [factory]);
