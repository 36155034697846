'use strict';

angular
  .module('routes.mobile-app-messages', ['templates', 'ui.router'])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  $stateProvider
    .state('mobile-app-messages', {
      url: '/mobile-app-messages',
      parent: 'site',
      abstract: true,
      views: {
        main: {
          template: '<div ui-view></div>',
        },
      },
    })
    .state('mobile-app-messages.client-onboarding', {
      url: '/client-onboarding',
    });
}
