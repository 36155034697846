'use strict';

class HoldingsPieChartController {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.positionPoint = {};
  }
}

export default HoldingsPieChartController;
