'use strict';

angular
  .module('controller.eft-verify', [
    'service.intercom',
    'service.modals',
    'service.transfer-account-data-provider',
    'service.user-notifications',
    'service.eft-validation-service',
  ])
  .controller('EftVerifyCtrl', [
    '$scope',
    '$window',
    'transferAccountDataProvider',
    'userNotifications',
    'modals',
    'intercom',
    'eftDetails',
    'eftInProgressDetails',
    'advisorEftDataset',
    'userId',
    'advisorDataArray',
    'eftValidationService',
    controller,
  ]);

function controller(
  $scope,
  $window,
  transferAccountDataProvider,
  userNotifications,
  modals,
  intercom,
  eftDetails,
  eftInProgressDetails,
  advisorEftDataset,
  userId,
  advisorDataArray,
  eftValidationService
) {
  // Don't need intercom.  It just gets in the way.
  intercom.shutdown();

  $scope.buttonState = {};
  _.each(eftDetails, function (transferInstruction) {
    $scope.buttonState[transferInstruction.id] = {
      confirming: false,
      rejecting: false,
      confirmed: false,
      rejected: false,
      hidden: false,
      doConfirm: function () {
        this.confirming = true;
      },
      doReject: function () {
        this.rejecting = true;
      },
      doReset: function () {
        this.confirming = this.rejecting = false;
      },
      doHide: function () {
        this.rejected = this.rejecting;
        this.confirmed = this.confirming;
        this.doReset();
        this.hidden = true;
      },
      rejectBtnState: function () {
        if (this.rejecting) {
          return 'rejecting';
        } else if (this.rejected) {
          return 'rejected';
        } else {
          return '';
        }
      },
      confirmBtnState: function () {
        if (this.confirming) {
          return 'confirming';
        } else if (this.confirmed) {
          return 'confirmed';
        } else {
          return '';
        }
      },
    };
  });

  transferAccountDataProvider.setInputs(
    null,
    advisorEftDataset[0].transferableAccounts(),
    advisorEftDataset[0].bankAccounts()
  );

  $scope.transferDisplayDataList = _.map(eftDetails, function (detail, index) {
    var dataObject = transferAccountDataProvider.getConfirmationData(
      'dialog',
      detail,
      _eftCheckResultFor(detail)
    );
    dataObject.advisorData = advisorDataArray[index];
    dataObject.isPendingCancel = detail.subStatus.is.pendingCancelApproval();
    dataObject.inProgressTransfer = eftInProgressDetails.find(
      (request) => request.transferInstructionId() === detail.id
    );
    return dataObject;
  });

  if (!eftDetails || eftDetails.error) {
    $scope.error = true;

    if (eftDetails.error) {
      modals.errorModal(eftDetails.error.title, eftDetails.error.message).result.then(function () {
        $window.location = $scope.$root.config.env.marketingUrl;
      });
    } else {
      $window.location = $scope.$root.config.env.marketingUrl;
    }
    return;
  }

  $scope.doReject = function (id) {
    $scope.buttonState[id].doReject();
    _.find(eftDetails, function (transferInstruction) {
      return transferInstruction.id === id;
    })
      .workflowClientAction(userId, 'reject')
      .then(
        function () {
          $scope.buttonState[id].doHide();
          showEftResultModal();
        },
        function () {
          $scope.buttonState[id].doReset();
          userNotifications.showError('Cannot reject this transfer');
        }
      );
  };

  $scope.doConfirm = function (id) {
    $scope.buttonState[id].doConfirm();
    _.find(eftDetails, function (transferInstruction) {
      return transferInstruction.id === id;
    })
      .workflowClientAction(userId, 'approve')
      .then(
        function () {
          $scope.buttonState[id].doHide();
          showEftResultModal();
        },
        function () {
          $scope.buttonState[id].doReset();
          userNotifications.showError('Cannot approve this transfer');
        }
      );
  };

  function allTransfersHandled() {
    return $scope.transferDisplayDataList.every(function (data) {
      const id = data.transferInstruction.id;
      return (
        $scope.buttonState[id].confirmBtnState() === 'confirmed' ||
        $scope.buttonState[id].rejectBtnState() === 'rejected'
      );
    });
  }

  function showEftResultModal() {
    if (allTransfersHandled()) {
      modals.eftConfirmationResultModal();
    }
  }

  function _eftCheckResultFor(transferInstruction) {
    return eftValidationService.result(
      transferInstruction,
      transferAccountDataProvider,
      transferInstruction.transferFromId(),
      transferInstruction.transferToId()
    );
  }
}
