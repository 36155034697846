'use strict';

angular
  .module('controller.modal.wsi.review', [
    'component.investment-charts.model-portfolio-pie-chart',
    'filter.a-or-an',
    'directive.charts',
    'service.investment-charts.asset-allocation-data-generator',
    'service.risk-level-business-rules',
  ])
  .controller('modalWsiReviewCtrl', [
    '$scope',
    'account',
    'goal',
    'portfolio',
    'user',
    'questions',
    'toBeStacked',
    'investmentsChartData',
    'investmentChartsAssetAllocationDataGenerator',
    'riskAbility',
    'riskLevelBusinessRules',
    controller,
  ]);

function controller(
  $scope,
  account,
  goal,
  portfolio,
  user,
  questions,
  toBeStacked,
  investmentsChartData,
  investmentChartsAssetAllocationDataGenerator,
  riskAbility,
  riskLevelBusinessRules
) {
  $scope.account = account;
  $scope.goal = goal;
  $scope.portfolio = portfolio;
  $scope.questions = questions;
  $scope.toBeStacked = toBeStacked;
  $scope.investmentsChartData = investmentsChartData;
  $scope.riskLevelBusinessRules = riskLevelBusinessRules;

  if (user) {
    $scope.currentUser = user;
  }

  var chartData = investmentChartsAssetAllocationDataGenerator.getData(
    portfolio.nonZeroAllocations()
  );

  $scope.allocationDataForPieChart = _.map(chartData, function (chartDataRow) {
    return {
      color: chartDataRow.color,
      label: chartDataRow.allocation.fund().label(),
      value: chartDataRow.allocation.weight(),
    };
  });

  $scope.allocationDataForLegend = _.map(chartData, function (chartDataRow) {
    return {
      color: chartDataRow.color,
      label: chartDataRow.allocation.fund().label(),
      weight: chartDataRow.allocation.weight(),
    };
  });

  $scope.setBigModal = function () {
    angular.element('.modal:not(.menu)').addClass('big-dialog-disable-flexbox');
  };

  $scope.riskToleranceLabel = function () {
    if (riskAbility >= 7) {
      return 'High';
    } else if (riskAbility >= 4) {
      return 'Medium';
    } else {
      return 'Low';
    }
  };
}
