'use strict';

function factory() {
  // validateForm is an optional function you can pass that will do custom validation.
  // Leave it out and it is skipped.
  function done(form, validateForm) {
    var externalValidationResult = !validateForm || (validateForm && validateForm(form));
    return form.$valid && externalValidationResult;
  }

  function notDone($scope, form) {
    var firstInvalidInput = document[form.$name].querySelector('.ng-invalid');
    var navbarHeight = document.querySelector('nav').clientHeight;

    _.each(form.$error, function (errors) {
      _.each(errors, function (control) {
        control.$setDirty();
      });
    });

    if (firstInvalidInput) {
      firstInvalidInput.focus();
      window.scrollBy(0, -(navbarHeight + 10));
    }

    $scope.$root.$broadcast('submit');
  }

  return {
    done,
    notDone,
  };
}

angular.module('service.form-helper', []).factory('formHelper', [factory]);
