'use strict';
import { rules, errorText } from './eft-validation-service.rules';

const DEBUG = false;
const CHECK_CUSTODIAN = true; // It helps to turn this off during testing

export const errorStatus = {
  ok: 'ok',
  warning: 'warning',
  error: 'error',
  unknown: 'unknown',
};

class EftValidationService {
  constructor(config) {
    this.config = config;
  }

  static get errorText() {
    return errorText;
  }

  static get rules() {
    return rules;
  }

  result(transferInstruction, transferAccountDataProvider, from, to) {
    if (!transferInstruction.type.is.transfer()) {
      return this._buildReturnCode(errorStatus.ok);
    }
    const fromAccount = transferAccountDataProvider.getAccountById('Account', from);
    const toAccount = transferAccountDataProvider.getAccountById('Account', to);
    if (!fromAccount || !toAccount) {
      return this._buildReturnCode(errorStatus.unknown);
    }
    const fromCustodian = this._custodian(fromAccount);
    const fromType = this._type(fromAccount);
    const toCustodian = this._custodian(toAccount);
    const toType = this._type(toAccount);
    const realAccounts =
      fromAccount.constructor.name === 'Account' && toAccount.constructor.name === 'Account';
    if (DEBUG) {
      console.log('from', fromCustodian, fromAccount);
      console.log('to  ', toCustodian, toAccount);
    }

    const [rule, result] = this._processRules(fromType, toType);
    if (result === errorStatus.error) {
      return this._buildReturnCode(result, this._textForMessage(rule), rule);
    } else if (CHECK_CUSTODIAN && realAccounts && fromCustodian !== toCustodian) {
      const text = EftValidationService.errorText.custodian;
      return this._buildReturnCode(errorStatus.error, text, undefined, 'blue');
    } else if (result === errorStatus.warning) {
      // Only do warning messages if the custodians match, as mismatched custodian is a hard error
      return this._buildReturnCode(result, this._textForMessage(rule), rule);
    }
    return this._buildReturnCode(result);
  }

  _colourToAlertStyle(colour) {
    switch (colour) {
      case 'blue':
        return 'alert-info';
      case 'red':
        return 'alert-danger';
      case 'yellow':
        return null; // This one isn't dynamic.  The message box is shared with other messages and is not under this service's control.
      default:
        return 'alert-info';
    }
  }

  _buildReturnCode(status, message = undefined, rule = undefined, colour = undefined) {
    if (!message) {
      return {
        status: errorStatus.ok,
        errorMessage: null,
        warningMessage: null,
        alertStyle: null,
        moreInfo: null,
        isError: false,
      };
    } else {
      const isError = status === errorStatus.error || status === errorStatus.unknown;
      return {
        status,
        isError,
        errorMessage: isError ? message : null,
        warningMessage: !isError ? message : null,
        alertStyle: this._colourToAlertStyle(
          colour ?? EftValidationService.errorText[rule.text]?.colour
        ),
        moreInfo: EftValidationService.errorText[rule.moreInfo]?.text,
      };
    }
  }

  _textForMessage(rule) {
    let messageKey;
    if (rule.text && rule.text.includes('gsub')) {
      return this._ruleGsubText(EftValidationService.errorText[rule.text].text, rule.from, rule.to);
    } else if (rule.text) {
      messageKey = rule.text;
    } else {
      return rule.ruleValue.startsWith('error') ? 'generalError' : 'generalWarning';
    }
    return EftValidationService.errorText[messageKey].text;
  }

  _processRules(fromType, toType) {
    for (const rule of EftValidationService.rules) {
      for (const ruleFromType of this._toSafeArray(rule.from)) {
        for (const ruleToType of this._toSafeArray(rule.to)) {
          if (DEBUG) {
            console.log(
              `rule: ${ruleFromType} [[${fromType}]]-> ${ruleToType} [[${toType}]] = ${rule.ruleValue}`
            );
          }
          if (rule.ruleValue === errorStatus.unknown) {
            console.error('Unknown rule value', rule);
            continue;
          }

          let match = true;
          if (ruleFromType === '*' && ruleToType === '*') {
            continue; // This edge case doesn't help, so skip it
          } else if (ruleFromType === '*') {
            match = ruleToType === toType;
          } else if (ruleToType === '*') {
            match = ruleFromType === fromType;
          } else {
            match = ruleFromType === fromType && ruleToType === toType;
          }

          if (match) {
            return [rule, rule.ruleValue];
          }
        }
      }
    }
    return [null, errorStatus.ok];
  }

  _custodian(account) {
    if (account.constructor.name === 'Account') {
      return account.custodian().code();
    }
    return null;
  }

  _type(account) {
    return account.type().name;
  }

  _toSafeArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]' ? obj : [obj];
  }

  _ruleGsubText(standardMessageText, accountTypeFrom, accountTypeTo) {
    const fromString = this._accountTypeLabel(accountTypeFrom);
    const toString = this._accountTypeLabel(accountTypeTo);
    return standardMessageText
      .replace(/{{an_account_type_from}}/, `${_aOrAn(fromString)} ${fromString}`)
      .replace(/{{an_account_type_to}}/, `${_aOrAn(toString)} ${toString}`)
      .replace(/{{account_type_to}}/, toString);
  }

  _accountTypeLabel(accountTypeName) {
    const accountType = this.config.types.Account.find((at) => at.name === accountTypeName);
    return accountType ? accountType.label : accountTypeName;
  }
}

EftValidationService.$inject = ['config'];

function _aOrAn(string) {
  return string.match(/^[aeiou]/i) ? 'an' : 'a';
}

export default EftValidationService;
