'use strict';

angular
  .module('service.pending-changes-service', [])
  .factory('pendingChangesService', ['$http', factory]);

function factory($http) {
  const vars = {
    baseScope: null,
  };

  function getPendingBeneficiaryDesignations(accountId) {
    return $http.get(`/api/pending_changes?name_id=0&account_id=${accountId}`);
  }

  function createPendingBeneficiaryDesignation(userId, accountId, data) {
    return $http.post('/api/pending_changes', {
      pending_change: {
        userId,
        accountId,
        nameId: 0,
        statusId: 0,
        data,
      },
    });
  }

  function deletePendingChange(id) {
    return $http.delete(`/api/pending_changes/${id}.json`);
  }

  function setBaseScope(scope) {
    vars.baseScope = scope;
  }

  function getBaseScope() {
    return vars.baseScope;
  }

  return {
    getPendingBeneficiaryDesignations,
    createPendingBeneficiaryDesignation,
    deletePendingChange,
    setBaseScope,
    getBaseScope,
  };
}
