'use strict';

angular
  .module('model.ContributionAmount', [])
  .factory('ContributionAmount', ['config', 'ram', contributionAmountFactory]);

function contributionAmountFactory(config, ram) {
  var ContributionAmount = new ram.Collection('ContributionAmount', {
    belongsTo: {
      account: 'Account',
    },
    schema: config.schemas.ContributionAmount,
    resources: {
      default: new ram.resources.Http('/api/contribution_amounts/:id.json'),
      cookie: new ram.resources.Cookie('contribution_amounts'),
    },
  });

  return ContributionAmount;
}
