'use strict';

angular
  .module('directive.learn-more-button', [])
  .directive('learnMoreButton', [learnMoreButtonDirective]);

function learnMoreButtonDirective() {
  return {
    restrict: 'E',
    scope: {
      class: '@',
      href: '@',
    },
    templateUrl: 'directives/learn-more-button.html',
  };
}
