'use strict';

function factory(ram, config) {
  return new ram.Collection('KnowYourClient', {
    belongsTo: {
      user: 'User',
    },
    resources: {
      default: new ram.resources.Http('/api/know_your_clients/:id.json'),
      cookie: new ram.resources.Cookie('know_your_clients'),
    },
    schema: config.schemas.KnowYourClient,
  });
}

angular
  .module('model.KnowYourClient', ['ram', 'config'])
  .factory('KnowYourClient', ['ram', 'config', factory]);
