'use strict';

angular
  .module('service.investments-bar-prep-data', [])
  .factory('invBarPrepData', [investmentsBarPrepData]);

function investmentsBarPrepData() {
  return {
    getChartDataForPositions,
    getChartDataForAllocations,
    getChartDataForTacticalAllocations,
  };

  function getChartDataForAllocations(allocations) {
    return getChartData(
      _.map(allocations, function (allocation) {
        return {
          fund: allocation.fund(),
          value: allocation.weight(),
        };
      })
    );
  }

  function getChartDataForTacticalAllocations(allocations) {
    return getChartData(
      _.map(allocations, function (allocation) {
        return {
          fund: allocation.fund(),
          value: allocation.tacticalWeight(),
        };
      })
    );
  }

  function getChartDataForPositions(positions) {
    return getChartData(
      _.map(positions, function (position) {
        return {
          fund: position.fund(),
          value: parseFloat(position.marketValue()),
        };
      })
    );
  }

  /**
   * Takes an account and its positions and returns an object that contains the data required for an
   * investments bar chart as two elements.
   *
   * @param entries Entries for the graph (array of {fund: ..., value: ...})
   * @param account The account for which the investments bar chart is to be created.
   * @return {object} The two element object containing the data in a format necessary for constructing
   * an investments bar chart.
   */
  function getChartData(entries) {
    const toBeStacked = [];
    const investmentsChartData = {};

    let i = entries.length - 1;
    while (i >= 0) {
      setData(i);
      i--;
    }

    function setData(i) {
      const entry = entries[i];
      const fund = entry.fund;
      const sym = fund.label();

      if (entry.value > 0) {
        toBeStacked.unshift(sym);
        investmentsChartData[sym] = {
          value: entry.value,
          color: fund.color,
        };
      }
    }

    return {
      toBeStacked,
      investmentsChartData,
    };
  }
}
