'use strict';

angular
  .module('controller.mobile-upload-request', ['service.intercom', 'model.Document'])
  .controller('MobileUploadRequestCtrl', [
    '$scope',
    'config',
    'intercom',
    'Document',
    'userName',
    'password',
    'uploadData',
    'invitedByAdvisor',
    controller,
  ]);

function controller(
  $scope,
  config,
  intercom,
  Document,
  userName,
  password,
  uploadData,
  invitedByAdvisor
) {
  // Don't need intercom.  It just gets in the way.
  intercom.shutdown();

  var documentTypeId = config.types.Document.findByName('unspecified').id;

  $scope.document = Document.new({
    adcKey: 'null',
    awsKey: 'null',
    typeId: '' + documentTypeId,
    signedAt: 'null',
    approvedAt: 'null',
  });
  $scope.userName = userName;
  $scope.password = password;
  $scope.uploadData = {
    notes: 'Error',
  };
  $scope.error = !uploadData || !userName || !password;
  $scope.invitedByAdvisor = invitedByAdvisor;

  if (uploadData) {
    $scope.uploadData = uploadData;
    $scope.uploadData.credentials = $scope.userName + ':' + $scope.password;
    $scope.uploadData.documentType = $scope.document.typeId();
  }
}
