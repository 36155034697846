'use strict';

angular
  .module('service.check-incomplete-accounts-service', [
    'service.modals',
    'service.loading-indicator',
  ])
  .factory('checkIncompleteAccountsService', [
    '$state',
    '$window',
    'modals',
    'loadingIndicator',
    factory,
  ]);

function factory($state, $window, modals, loadingIndicator) {
  var isActive = false;

  function checkIncompleteAccounts($scope, $state) {
    isActive = true;
    loadingIndicator.hide('stateChange');
    if ($scope.currentUser) {
      $scope.currentUser.accounts
        .ready(true)
        .then(function (accounts) {
          return _.reject(accounts, function (account) {
            return account.applicationCompleted() || account.type.is.trial();
          });
        })
        .then(function (incompleteAccounts) {
          if (incompleteAccounts && incompleteAccounts.length > 0) {
            var account = incompleteAccounts[0];
            var modal = modals.incompleteAccountConfirmation(account, $scope.currentUser);
            modal.result
              .then(function () {
                isActive = false;
              })
              .catch(function () {
                // user cancelled
                isActive = false;
                if ($state.previous && $state.previous.name) {
                  $state.go($state.previous.name);
                } else {
                  $window.location = $scope.$root.config.env.marketingUrl;
                }
              });
          }
        });
    }
  }

  return {
    call: checkIncompleteAccounts,
    active: function (arg) {
      if (arguments.length > 0) {
        isActive = arg;
      }
      return isActive;
    },
  };
}
