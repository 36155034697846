'use strict';

// TODO: This service is no longer used. Need to be removed when the home state gets removed
angular
  .module('service.external-advisor-client-greeter', [])
  .factory('externalAdvisorClientGreeter', [
    '$rootScope',
    '$cookies',
    '$http',
    function ($rootScope) {
      var knownAdvisors = {
        tundra17: {
          firstName: 'Michael',
          fullName: 'Michael Daciw',
          office: 'Tundra',
          promotion: '2 months of free service and a discount on our fees for the first year',
          referralArrangementUrl: '/referral-terms-mdaciw',
        },
        'jeppel-b': {
          firstName: 'Joshua',
          fullName: 'Joshua Eppel',
          promotion: '1 month of free service',
          referralArrangementUrl: '/referral-terms-jeppel-basic',
          modalUrl: 'modals/referral-signup-reward-client-jeppel-modal.html',
        },
        'jeppel-p': {
          firstName: 'Joshua',
          fullName: 'Joshua Eppel',
          promotion: '1 month of free service',
          referralArrangementUrl: '/referral-terms-jeppel-premium',
          modalUrl: 'modals/referral-signup-reward-client-jeppel-modal.html',
        },
      };
      return {
        greetClient: function (advisorUrl) {
          if (advisorUrl.startsWith('cp-')) {
            var referralArrangementUrl = advisorUrl.endsWith('-b')
              ? '/referral-terms-cp-basic'
              : '/referral-terms-cp-premium';
            $rootScope.advisorSignupRewardClientNotifyModal(
              'modals/referral-signup-reward-client-cp-modal.html',
              referralArrangementUrl
            );
          } else {
            var advisor = knownAdvisors[advisorUrl];
            if (advisor) {
              $rootScope.advisorSignupRewardClientNotifyModal(
                advisor.modalUrl,
                advisor.referralArrangementUrl,
                advisor.firstName,
                advisor.fullName,
                advisor.office,
                advisor.promotion
              );
            }
          }
        },
      };
    },
  ]);
