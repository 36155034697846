'use strict';

function typeWriterAnimationHandler($scope, $interval) {
  var delayBetweenLetters = 60;
  var phrases = [
    'protects your assets through diversification',
    'leaves more for you by minimizing your fees',
    'helps you grow your wealth with the market',
  ];
  var currentPhraseIndex = 0;
  var animationDirection = 'forwards';
  $scope.currentTypeWriterText = '';
  var delayBeforeBackwardsAnimation = 3000;
  var currentDelayBeforeBackwardsAnimation = 0;

  var needToPauseAnimation = function (currentLength) {
    return (
      currentLength === phrases[currentPhraseIndex].length &&
      animationDirection === 'backwards' &&
      currentDelayBeforeBackwardsAnimation < delayBeforeBackwardsAnimation
    );
  };

  var changePhraseIndex = function () {
    if (currentPhraseIndex < phrases.length - 1) {
      currentPhraseIndex++;
    } else {
      currentPhraseIndex = 0;
    }
  };

  var doForwardsAnimation = function (currentLength) {
    return currentLength < phrases[currentPhraseIndex].length && animationDirection === 'forwards';
  };

  var isEndOfForwardsAnimation = function (currentLength) {
    return (
      currentLength === phrases[currentPhraseIndex].length && animationDirection === 'forwards'
    );
  };

  var doBackwardsAnimation = function (currentLength) {
    return (
      currentLength <= phrases[currentPhraseIndex].length && animationDirection === 'backwards'
    );
  };

  var isEndOfBackwardsAnimation = function (currentLength) {
    return currentLength === 0 && animationDirection === 'backwards';
  };

  var typeWriterAnimation = function () {
    var currentLength = $scope.currentTypeWriterText.length;
    if (needToPauseAnimation(currentLength)) {
      currentDelayBeforeBackwardsAnimation += delayBetweenLetters;
      return;
    }
    currentDelayBeforeBackwardsAnimation = 0;

    if (doForwardsAnimation(currentLength)) {
      $scope.currentTypeWriterText += phrases[currentPhraseIndex][currentLength];
    } else if (isEndOfBackwardsAnimation(currentLength)) {
      changePhraseIndex();
      animationDirection = 'forwards';
    } else if (doBackwardsAnimation(currentLength)) {
      $scope.currentTypeWriterText = $scope.currentTypeWriterText.substring(0, currentLength - 1);
    } else if (isEndOfForwardsAnimation(currentLength)) {
      animationDirection = 'backwards';
    }
  };

  var typeWriterAnimationPromise = $interval(typeWriterAnimation, delayBetweenLetters);
  $scope.$on('$stateChangeStart', function () {
    $interval.cancel(typeWriterAnimationPromise);
  });
}

angular
  .module('controller.home.main', [
    'directive.charts',
    'directive.input',
    'directive.partial',
    'directive.input.currency',
    'directive.portfolio',
    'directive.plus-service-options',
    'directive.learn-more-button',
    'ram',
    'service.external-advisor-client-greeter',
    'service.fee-calculator',
  ])
  .controller('HomeMainCtrl', [
    '$scope',
    '$interval',
    '$stateParams',
    'externalAdvisorClientGreeter',
    'feeCalculator',
    'presetQuestion',
    'storedUser',
    'storedGoal',
    'ram',
    mainController,
  ]);

// controller for the homepage
function mainController(
  $scope,
  $interval,
  $stateParams,
  externalAdvisorClientGreeter,
  feeCalculator,
  question,
  user,
  goal,
  ram
) {
  $scope.user = user;
  $scope.goal = goal;
  $scope.question = question;
  $scope.minAge = 18;
  $scope.maxAge = 150;
  var defaultAge = 35; // Set to match the default age set in resolveStoredUser in routes.js

  // Setting user.age() as ng-model directly results in invalid age being saved (even without explicitly calling .save())
  function ageIsValid(age) {
    return !(age < $scope.minAge || age > $scope.maxAge);
  }

  if (!ageIsValid($scope.user.age())) {
    $scope.user.age(defaultAge);
    $scope.user.save();
  }

  $scope.tmpAge = new ram.Accessor($scope.user.age());
  $scope.saveIfValid = function () {
    if (ageIsValid($scope.tmpAge())) {
      $scope.user.age($scope.tmpAge());
      $scope.user.save();
      $scope.goal.timeframe(null);
    }
  };

  if (!goal.preset()) {
    question.choices.ready().then(function (choices) {
      goal.preset(choices[0].value());
      goal.save();
    });
  }

  // ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ; ;
  // The mobile app can launch a mobile browser with https://www.modernadvisor.ca/?intercom=true
  if ($stateParams.intercom === 'true') {
    window.Intercom('show');
  }

  if ($stateParams.advName) {
    externalAdvisorClientGreeter.greetClient($stateParams.advName);
  }

  if ($stateParams.gspName) {
    $scope.genericOkModal(
      'Group Savings Plan',
      '<p>Welcome to ModernAdvisor. We are excited to help you reach your financial goals.</p>' +
        '<p>To participate in your company\'s Group Savings Plan, click on the "Create my Portfolio" button on our homepage. Need help or have questions? Use the blue chat circle on the bottom right hand side of the page to contact one of our support staff.</p>' +
        '<p>Happy saving!</p>'
    );
  }

  //= == Savings Chart Data ===//

  var term = 20;
  var avgMutualFundFee = feeCalculator.getAverageMutualFundRate();
  var annualRoR = feeCalculator.getAnnualRoR();

  function calculateCosts(initialInvestment) {
    var ourFee = feeCalculator.getFeeRate(initialInvestment) + feeCalculator.getPortfolioRate();
    var mutualFundCost = feeCalculator.getAverageMutualFundCost(initialInvestment);
    var ourCost = initialInvestment * ourFee;

    var savings = mutualFundCost - ourCost;
    var savingsPercent =
      initialInvestment === 0 ? 0.0 : Math.round((savings / initialInvestment) * 100.0);

    var ourPerf = Math.round(Math.pow(1.0 + annualRoR - ourFee, term) * initialInvestment);
    var mutualFundPerf = Math.round(
      Math.pow(1.0 + annualRoR - avgMutualFundFee, term) * initialInvestment
    );

    var savingsOverTerm = ourPerf - mutualFundPerf;
    var savingsOverTermPercent =
      initialInvestment === 0 ? 0 : Math.round((savingsOverTerm / initialInvestment) * 100.0);

    return {
      mutualFundCost,
      ourCost,
      savings,
      savingsPercent,
      savingsOverTerm,
      savingsOverTermPercent,
      annualRoR: annualRoR * 100,
      portfolioCost: Math.round(ourFee * 10000) / 100.0,
      term,
      avgMutualFundFee: Math.round(avgMutualFundFee * 10000) / 100.0,
    };
  }

  $scope.initialInvestment = new ram.Accessor(100000);
  typeWriterAnimationHandler($scope, $interval);

  $scope.data = [
    {
      category: 'ModernAdvisor',
      value: $scope.initialInvestment() * $scope.ourCost,
    },
    {
      category: 'Traditional Mutual Fund',
      value: $scope.initialInvestment() * $scope.mutualFundCost,
    },
  ];

  $scope.$watch('initialInvestment()', function (val) {
    var costs = calculateCosts(val || 0);
    _.extendOwn($scope, costs);
  });

  $scope.$watch('initialInvestment()', function () {
    $scope.data.forEach(function (datum) {
      if (datum.category === 'ModernAdvisor') {
        datum.value = $scope.ourCost;
      } else {
        datum.value = $scope.mutualFundCost;
      }
    });
    if ($scope.datachange) {
      $scope.datachange = 0;
    } else {
      $scope.datachange = 1;
    }
  });
}
