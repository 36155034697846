'use strict';

angular
  .module('controller.modal.advisor-id-verify-confirm', ['ram', 'service.loading-indicator'])
  .controller('ModalAdvisorIdVerifyConfirm', [
    '$http',
    '$q',
    '$scope',
    '$uibModalInstance',
    'personId',
    'loadingIndicator',
    controller,
  ]);

function controller($http, $q, $scope, $modalInstance, personId, loadingIndicator) {
  $scope.informationMessageForUser = '';
  $scope.notes = '';

  $scope.confirm = function () {
    loadingIndicator.show();

    return $http
      .post('/api/advisor/manual_identity_checks.json', {
        personId,
        notes: $scope.notes,
      })
      .then(function () {
        $scope.informationMessageForUser = 'Id verified!';
        $modalInstance.close();
      })
      .catch(function (error) {
        var message = error.data.message || 'Could not verify identity';
        $scope.informationMessageForUser = 'Error: ' + message;

        return $q.reject(error);
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  };
}
