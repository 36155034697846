'use strict';

angular
  .module('controller.modal.confirm-risk-appetite-change', [
    'model.Goal',
    'service.risk-level-business-rules',
  ])
  .controller('ModalConfirmRiskAppetiteChangeCtrl', [
    '$scope',
    '$http',
    'config',
    'accounts',
    'oldInvestorType',
    'Goal',
    'riskLevelBusinessRules',
    modalConfirmRiskAppetiteChangeController,
  ]);

function modalConfirmRiskAppetiteChangeController(
  $scope,
  $http,
  config,
  accounts,
  oldInvestorType,
  Goal,
  riskLevelBusinessRules
) {
  var startsWithVowelRe = /^[aeiou]/i;

  $scope.aOrAn = function (word) {
    return startsWithVowelRe.test(word) ? 'an' : 'a';
  };

  $scope.accountRiskRecommendationChanges = [];
  $scope.savingsAccountRiskRecommendationChanges = [];
  $scope.oldInvestorType = oldInvestorType;
  $scope.updatedInvestorType = $scope.currentUser.investorType().label;

  $scope.allRiskRecommendationChanges = () => ({
    regular: $scope.accountRiskRecommendationChanges,
    savings: $scope.savingsAccountRiskRecommendationChanges,
  });

  _.each(accounts, function (account) {
    var goal = account.accountGoal().goal();

    var request = {
      financialAssets: $scope.currentUser.financialAssets(),
      income: $scope.currentUser.income(),
      initial: goal.initial(),
      nonFinancialAssets: $scope.currentUser.nonFinancialAssets(),
      ongoing: goal.ongoing(),
      riskAppetite: $scope.currentUser.riskAppetite(),
      timeframe: goal.timeframe(),
      totalLiabilities: $scope.currentUser.totalLiabilities(),
    };

    $http
      .get('api/goals/new/risk.json', {
        params: _.underscoreKeys(request),
      })
      .then(function (response) {
        var goalWithNewRisk = Goal.new();

        goalWithNewRisk.riskRecommendation(response.data);
        goalWithNewRisk.advisorRiskOffset(goal.advisorRiskOffset());
        goalWithNewRisk.riskOffset(goal.riskOffset());

        if (goal.risk() !== goalWithNewRisk.risk()) {
          const payload = {
            account,
            risk: {
              old: goal.risk(),
              new: goalWithNewRisk.risk(),
            },
          };
          if (riskLevelBusinessRules.displayRiskChangeFor(account)) {
            $scope.accountRiskRecommendationChanges.push(payload);
          } else {
            $scope.savingsAccountRiskRecommendationChanges.push(payload);
          }
        }
      });
  });
}
