'use strict';

angular
  .module('routes.advisor-new-client', [
    'controller.advisor-new-client',
    'controller.minimal-footer',
    'model.AdvisorCreateNewClientFlow',
    'service.adaptive-views',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', configRoutes]);

function configRoutes($stateProvider, adaptiveViewsProvider) {
  function resolveDetails($http, $stateParams) {
    return $http
      .get(
        '/api/advisor_create_new_client_flows/' +
          $stateParams.advisorNewClientFlowPassword +
          '/sanitized_details'
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (errorInfo) {
        return {
          errorCode: errorInfo.status,
        };
      });
  }

  function resolveClientFlow($stateParams, currentUser, AdvisorCreateNewClientFlow) {
    var password = $stateParams.advisorNewClientFlowPassword;
    var params = {
      password,
    };
    if (!password && currentUser) {
      // If coming from a re-direct
      params = {
        clientId: currentUser.person().id,
      };
    }
    return AdvisorCreateNewClientFlow.where(params).then(function (flows) {
      return _.first(flows);
    });
  }

  function resolveAccountFlow(clientFlow) {
    var accountFlowIndex = 0; // Start with the first account

    return clientFlow.accountFlowForNewClient(accountFlowIndex).then(function (response) {
      return response.data;
    });
  }

  function resolveRedirect(details, redirect) {
    if (details.errorCode) {
      redirect
        .to('advisor-new-client.invalid-link', {
          errorCode: details.errorCode,
        })
        .always();
      return;
    }

    if (details.isCompleted) {
      redirect.to('account.signin').always();
    }

    const isModernAdvisorPersonal = details.advisorReferralCode.startsWith('mapersonal');
    if (isModernAdvisorPersonal) {
      redirect.to('advisor-new-client.verify-identity').always();
    }
  }

  function saveParamsToCookie($cookies, $stateParams) {
    if ($stateParams.onboardType) {
      $cookies.put('onboard_type', $stateParams.onboardType);
    }
  }

  $stateProvider
    .state('advisor-new-client', {
      url: '/advisor-new-client/:advisorNewClientFlowPassword',
      abstract: true,
      parent: 'site',
      views: {
        main: {
          controller: 'AdvisorNewClientController',
        },
        navbar: {
          templateUrl: 'navbar/minimal.html',
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
      resolve: {
        details: ['$http', '$stateParams', resolveDetails],
      },
    })
    .state('advisor-new-client.view', {
      url: '/view?onboardType',
      controller: 'AdvisorNewClientViewController',
      resolve: {
        cookieSaved: ['$cookies', '$stateParams', saveParamsToCookie],
        resolveRedirect: ['details', 'redirect', resolveRedirect],
      },
    })
    .state('advisor-new-client.goodbye', {
      url: '/goodbye',
      controller: 'AdvisorNewClientGoodbyeController',
      params: {
        deleted: false,
      },
      resolve: {
        resolveRedirect: ['details', 'redirect', resolveRedirect],
      },
    })
    .state('advisor-new-client.verify-identity', {
      url: '/verify-identity',
      controller: 'AdvisorNewClientVerifyIdentityController',
    })
    .state('advisor-new-client.invalid-link', {
      url: '/invalid-link', // Don't need errorCode here because we arrive here via JS code.
      params: {
        errorCode: undefined,
      },
      controller: 'AdvisorNewClientInvalidLinkController',
    })
    .state('advisor-new-client.add-password', {
      url: '/add-password',
      controller: 'AdvisorNewClientAddPasswordController',
      resolve: {
        clientFlow: [
          '$stateParams',
          'currentUser',
          'AdvisorCreateNewClientFlow',
          resolveClientFlow,
        ],
        accountFlow: ['clientFlow', resolveAccountFlow],
      },
    });
}
