'use strict';

angular
  .module('controller.advise.bulk-client-upload-help', [])
  .controller('AdviseBulkClientUploadHelpCtrl', [
    '$scope',
    'advisorDetails',
    'config',
    adviseBulkClientUploadHelpController,
  ]);

function adviseBulkClientUploadHelpController($scope, advisorDetails, config) {
  $scope.serviceLevels = advisorDetails.serviceLevels;

  $scope.accountTypes = _.reject(config.types.Account, function (accountType) {
    return (
      accountType.name === 'corporate' ||
      accountType.name === 'joint_no_right_of_survivorship' ||
      accountType.name === 'group_rrsp' ||
      accountType.name === 'trial'
    );
  });

  $scope.accountPortfolioOptions = advisorDetails.allowedPortfolioOptions;
}
