'use strict';

function referralServiceFactory($modal, Referral) {
  return {
    markAsPaid: function (payment, referral) {
      var _this = this;
      return $modal
        .open({
          templateUrl: 'modals/advisor/referral-mark-as-paid.html',
          backdrop: 'static',
          keyboard: false,
          controller: [
            '$scope',
            'referral',
            function ($scope, referral) {
              $scope.payment = payment;
              $scope.referral = referral;
              $scope.referralService = _this;
            },
          ],
          resolve: {
            referral: function () {
              return (
                referral ||
                Referral.find({
                  id: payment.referralId(),
                })
              );
            },
          },
        })
        .result.then(function () {
          payment.paidAt(new Date());
          payment.save();
        });
    },
    getUnlockDateText: function (payment) {
      var unlockedAt = moment(payment.unlockedAt());
      if (moment().diff(unlockedAt) < 0) {
        return 'unlocks ' + unlockedAt.fromNow();
      }
      return 'unlocked';
    },
  };
}
angular
  .module('service.advisor.referral-service', ['ui.bootstrap', 'model.Referral'])
  .factory('referralService', ['$uibModal', 'Referral', referralServiceFactory]);
