'use strict';

var controller = [
  '$scope',
  '$rootScope',
  'fetchLastDocument',
  'onboardingMobileUploadManager',
  photoIdUploadController,
];

angular
  .module('component.subform.photo-id-upload', [
    'directive.document-upload',
    'service.fetch-last-document',
    'service.onboarding-mobile-upload-manager',
    'service.form-helper',
    'ram',
  ])
  .component('photoIdUpload', {
    templateUrl: 'components/photo-id-upload.html',
    bindings: {
      backImage: '<',
      frontImage: '<',
      jointBackImage: '<',
      jointFrontImage: '<',
      spouse: '<',
      spouseOrPartner: '<',
      advisorTemplate: '@',
      client: '<',
      hidePrimaryApplicant: '@',
    },
    controller,
    controllerAs: '$ctrl',
  });

function photoIdUploadController(
  $scope,
  $rootScope,
  fetchLastDocument,
  onboardingMobileUploadManager
) {
  var $ctrl = this; // jshint ignore:line

  // Set up variables
  $scope = $scope.$parent; // To get the proper scope
  var currentUser = $rootScope.currentUser;
  var client = $ctrl.client || currentUser.person();
  var isSpouseJointSubscriber = !!$ctrl.spouse;
  $ctrl.isOntarioAddress = function () {
    return _isOntarioAddress(client) || _isOntarioAddress($ctrl.spouse);
  };
  $ctrl.hidePrimaryApplicantSetting = $ctrl.hidePrimaryApplicant === 'true';

  $ctrl.photos = {
    idBackImage: $ctrl.backImage,
    idFrontImage: $ctrl.frontImage,
  };

  if (isSpouseJointSubscriber) {
    _.extend($ctrl.photos, {
      jointIdBackImage: $ctrl.jointBackImage,
      jointIdFrontImage: $ctrl.jointFrontImage,
    });
  }
  $ctrl.emailSentState = onboardingMobileUploadManager.getState();
  $ctrl.imagesConfirmed =
    onboardingMobileUploadManager.enoughImagesCollected(isSpouseJointSubscriber);
  $ctrl.hideMobileUploadLink = $ctrl.advisorTemplate === 'true';
  $ctrl.isRequired = $ctrl.advisorTemplate !== 'true';

  $scope.$parent.done = function () {
    return _hasIdFrontImage();
  };

  $scope.$parent.notDone = function () {
    $ctrl.form.$setDirty();
    $scope[$ctrl.form.$name] = $ctrl.form; // For `super`-controller to pick it up
    return $scope.formNotDone($ctrl.form.$name);
  };

  $rootScope.$on('doMobilePhotoRefresh', function () {
    currentUser.documents.ready().then(function () {
      $ctrl.photos.idFrontImage = fetchLastDocument.fetch(currentUser.documents(), 'id_front');
      $ctrl.photos.idBackImage = fetchLastDocument.fetch(currentUser.documents(), 'id_back');
      if (isSpouseJointSubscriber) {
        $ctrl.photos.jointIdFrontImage = fetchLastDocument.fetch(
          currentUser.documents(),
          'joint_id_front'
        );
        $ctrl.photos.jointIdBackImage = fetchLastDocument.fetch(
          currentUser.documents(),
          'joint_id_back'
        );
      }
    });
  });

  $ctrl.sendMobileLink = function () {
    onboardingMobileUploadManager.sendMessage(currentUser, _stateUpdateCallback);
  };

  $ctrl.mobileUploadOperationComplete = function () {
    onboardingMobileUploadManager.done();
  };

  onboardingMobileUploadManager.setCallback(_stateUpdateCallback);

  $ctrl.isSpouseJointSubscriber = function () {
    return isSpouseJointSubscriber;
  };

  function _hasIdFrontImage() {
    var primaryId = $ctrl.photos.idFrontImage && $ctrl.photos.idFrontImage.id;
    var secondaryId = true;
    if (isSpouseJointSubscriber) {
      secondaryId = $ctrl.photos.jointIdFrontImage && $ctrl.photos.jointIdFrontImage.id;
    }
    return primaryId && secondaryId;
  }

  function _isOntarioAddress(person) {
    if (!person) {
      return false;
    }
    var regions = _.chain(person.addresses()).filter(_notSameAsAddress).map(_getRegion).value();
    return _.contains(regions, 'ON');

    function _notSameAsAddress(address) {
      return address.sameAsId() === null;
    }

    function _getRegion(address) {
      return address.region();
    }
  }

  function _stateUpdateCallback() {
    $ctrl.emailSentState = onboardingMobileUploadManager.getState();
    $ctrl.imagesConfirmed =
      onboardingMobileUploadManager.enoughImagesCollected(isSpouseJointSubscriber);
  }
}
