'use strict';

angular
  .module('service.enter-key-handler', [])
  .factory('enterKeyHandler', ['$timeout', enterKeyHandlerFactory]);

function enterKeyHandlerFactory($timeout) {
  const ENTER_KEY = 13;
  let _inputField;
  let _callback;

  function setupEnterKeyHandler($scope, id, callback) {
    _callback = callback;
    $timeout(() => {
      _inputField = angular.element(`input#${id}`)[0];
      if (_inputField) {
        _inputField.addEventListener('keyup', listener);
      }
    });

    $scope.$on('$destroy', () => {
      if (_inputField) {
        _inputField.removeEventListener('keyup', listener);
        _inputField = null;
        _callback = null;
      }
    });
  }

  function listener(event) {
    event.preventDefault();
    if (event.keyCode === ENTER_KEY) {
      if (_callback) {
        _callback();
      }
    }
  }

  return {
    setupEnterKeyHandler,
  };
}
