'use strict';

angular
  .module('controller.dashboard.navbar', ['service.dashboard-navbar', 'service.feature-toggles'])
  .controller('DashboardNavbarCtrl', [
    '$scope',
    '$state',
    '$document',
    'account',
    'dashboardNavbarService',
    'featureToggles',
    'advisorProfile',
    dashboardNavbarCtrl,
  ]);

function dashboardNavbarCtrl(
  $scope,
  $state,
  $document,
  account,
  dashboardNavbarService,
  featureToggles,
  advisorProfile
) {
  $scope.advisorProfile = advisorProfile;

  $scope.account = account;
  $scope.stateIsAccountRelated = dashboardNavbarService.stateIsAccountRelated;
  $scope.stateIsGoals = dashboardNavbarService.stateIsGoals;
  $scope.stateIsTransfer = function () {
    return ['dashboard.transfer', 'dashboard.transfer-account'].includes($state.$current.name);
  }; // for whatever reason ui-sref-active does not work for dashboard.transfer

  $scope.canGoBack = function () {
    return $state.current.data && $state.current.data.backButtonState;
  };

  $scope.goBack = function () {
    if ($scope.canGoBack()) {
      return $state.go($state.current.data.backButtonState);
    } else {
      return $state.go('^');
    }
  };

  $scope.navbarMiddleText = function () {
    if ($state.current.name === 'dashboard.account') {
      return 'Modify';
    } else {
      return $state.current.data.menuTitle;
    }
  };

  $scope.hideMenuModal = function () {
    $document.find('.current-user-menu').modal('hide');
  };

  $scope.isGoalSummaryFeatureEnabled = featureToggles.isEnabled('goalSummary');
}
