'use strict';

function videoCarouselDirective($sce) {
  return {
    scope: {},
    restrict: 'E',
    templateUrl: 'directives/video-carousel.html',
    link: function ($scope, element) {
      $scope.videos = [
        $sce.trustAsResourceUrl(
          'https://s3-us-west-2.amazonaws.com/modernadvisor-public/testimonials/Kathleen.mp4'
        ),
        $sce.trustAsResourceUrl(
          'https://s3-us-west-2.amazonaws.com/modernadvisor-public/testimonials/Jason.mp4'
        ),
        $sce.trustAsResourceUrl(
          'https://s3-us-west-2.amazonaws.com/modernadvisor-public/testimonials/Patricia.mp4'
        ),
        $sce.trustAsResourceUrl(
          'https://s3-us-west-2.amazonaws.com/modernadvisor-public/testimonials/Paul.mp4'
        ),
        $sce.trustAsResourceUrl(
          'https://s3-us-west-2.amazonaws.com/modernadvisor-public/testimonials/Ryan.mp4'
        ),
      ];

      $scope.totalNumberOfVideos = $scope.videos.length;
      $scope.currentVideoIndex = 0;

      $scope.next = function () {
        if ($scope.currentVideoIndex === $scope.totalNumberOfVideos - 1) {
          $scope.currentVideoIndex = 0;
        } else {
          $scope.currentVideoIndex += 1;
        }
      };

      $scope.previous = function () {
        if ($scope.currentVideoIndex === 0) {
          $scope.currentVideoIndex = $scope.totalNumberOfVideos - 1;
        } else {
          $scope.currentVideoIndex -= 1;
        }
      };

      $scope.$watch('currentVideoIndex', function (newVideoIndex) {
        element.find('video')[0].src = $scope.videos[newVideoIndex];
      });
    },
  };
}

angular
  .module('directive.video-carousel', [])
  .directive('videoCarousel', ['$sce', videoCarouselDirective]);
