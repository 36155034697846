'use strict';

angular.module('service.resolve-helpers.etfs', []).factory('etfsResolveHelpers', ['$q', factory]);

function factory($q) {
  var etfs = {
    xic: {
      name: 'iShares Core S&P/TSX Capped Composite Index ETF',
      ticker: 'XIC',
      mer: '0.06%',
      assetClass: 'Canadian Stocks',
      totalAssets: '$7.9 billion',
      yield: '2.70%',
      geography: 'Canada',
      description:
        'For Canadian Stocks we use the iShares Core S&P/TSX Capped Composite Index ETF. This ETF offers broad exposure to the Canadian stock market for very low cost. XIC is the largest ETF in Canada that tracks the broad Canadian market. Vanguard and BMO also offer ETFs that track the Canadian stock market.',
      img: 'canada-map',
      performance: {
        2013: 12.46,
        2014: 10.95,
        2015: -8.62,
        2016: 21.0,
        2017: 8.98,
        2018: -8.83,
        2019: 22.89,
        2020: 5.66,
      },
    },
    xen: {
      name: 'iShares Jantzi Social Index ETF',
      ticker: 'XEN',
      mer: '0.55%',
      assetClass: 'Canadian Stocks - Socially Responsible',
      totalAssets: '$188 million',
      yield: '2.13%',
      geography: 'Canada',
      description:
        "A socially responsible alternative to XIC. Companies whose shares are included in the ETF's portfolio are screened on environmental, social and governance factors on a scale from 1 to 5, where 5 indicates significant controversies. Companies with 1 or more scores of 4 or 5 are ineligible. Companies involved in military contracting, nuclear power or tobacco are also ineligible.",
      img: 'canada-map',
      performance: {
        2013: 16.19,
        2014: 9.79,
        2015: -5.95,
        2016: 19.67,
        2017: 10.65,
        2018: -7.93,
        2019: 16.64,
        2020: -1.31,
      },
    },
    xcsr: {
      name: 'iShares ESG Advanced MSCI Canada Index ETF',
      ticker: 'XCSR',
      mer: '0.17%',
      assetClass: 'Canadian Stocks - Socially Responsible',
      totalAssets: '$41 million',
      yield: '1.44%',
      geography: 'Canada',
      description:
        'This ETF invests in Canadian companies. Companies are screened on environmental, social and governance factors and must receive an MSCI ESG rating of at least BBB or above. Companies in 13 industries, including fossil fuels, gambling, GMO, nuclear power, alcohol, military contracting, civilian firearms, and tobacco are ineligible.',
      img: 'canada-map',
      extraNote: '* XCSR performance for 2020 is from May 1 to December 31.',
      performance: {
        2013: null,
        2014: null,
        2015: null,
        2016: null,
        2017: null,
        2018: null,
        2019: null,
        2020: 28.62,
      },
    },
    vus: {
      name: 'Vanguard US Total Market Index ETF (CAD-hedged)',
      ticker: 'VUS',
      mer: '0.16%',
      assetClass: 'US Stocks',
      totalAssets: '$858 million',
      yield: '1.10%',
      geography: 'US',
      description:
        'For US Stocks we use the Vanguard US Total Market Index ETF (CAD Hedged). This ETF offers broad exposure to the US stock market and covers large-cap, mid-cap and small-cap stocks. We selected this ETF for its broad exposure and low cost, and because it hedges the US Dollar exposure.',
      img: 'us-map',
      performance: {
        2013: 33.9,
        2014: 13.13,
        2015: -0.81,
        2016: 11.36,
        2017: 20.28,
        2018: -6.85,
        2019: 28.48,
        2020: 17.81,
      },
    },
    dsi: {
      name: 'iShares MSCI KLD 400 Social ETF',
      ticker: 'DSI',
      mer: '0.25%',
      assetClass: 'US Stocks - Socially Responsible',
      totalAssets: 'US $3 billion',
      yield: '1.10%',
      geography: 'US',
      description:
        'A socially responsible alternative to VUS. Companies involved in nuclear power, tobacco, alcohol, gambling, military weapons, civilian firearms, GMOs and adult entertainment are excluded. The remaining companies are screened on environmental, social and governance factors and must maintain a minimum B rating.',
      img: 'us-map',
      performance: {
        2013: 35.43,
        2014: 12.29,
        2015: 0.31,
        2016: 10.53,
        2017: 20.89,
        2018: -3.81,
        2019: 31.27,
        2020: 21.03,
      },
    },
    vef: {
      name: 'Vanguard FTSE Developed ex US Index ETF (CAD-hedged)',
      ticker: 'VEF',
      mer: '0.21%',
      assetClass: 'International Developed Market Stocks',
      totalAssets: '$469 million',
      yield: '1.71%',
      geography: 'Japan, U.K., Europe, Australia, South Korea',
      description:
        'For international exposure to developed markets we use the Vanguard FTSE Developed ex North America Index ETF (CAD-hedged). This ETF covers stocks from Asian, European and Australian stock markets at very low cost.',
      img: 'vef-map',
      performance: {
        2013: 26.32,
        2014: 3.72,
        2015: 4.8,
        2016: 6.15,
        2017: 16.94,
        2018: -11.54,
        2019: 22.35,
        2020: 2.24,
      },
    },
    vee: {
      name: 'Vanguard FTSE Emerging Markets All Cap Index ETF',
      ticker: 'VEE',
      mer: '0.24%',
      assetClass: 'Emerging Market Stocks',
      totalAssets: '$896 million',
      yield: '1.54%',
      geography: 'China, Taiwan, India, South Africa, Brazil, Mexico, Russia',
      description:
        'For emerging market stocks we use the Vanguard FTSE Emerging Markets Index ETF (VEE). This ETF provides exposure to emerging market stocks from countries such as China, India, South Africa and Brazil. VEE is the lowest cost emerging markets ETF in Canada.',
      img: 'vee-map',
      performance: {
        2013: 1.05,
        2014: 8.37,
        2015: 0.21,
        2016: 8.1,
        2017: 22.55,
        2018: -7.91,
        2019: 14.12,
        2020: 12.92,
      },
    },
    vsb: {
      name: 'Vanguard Canadian Short-Term Index Bond ETF',
      ticker: 'VSB',
      mer: '0.11%',
      assetClass: 'Canadian Bonds',
      totalAssets: '$1.2 billion',
      yield: '2.20%',
      geography: 'Canada',
      description:
        'For Canadian bonds we use the Vanguard Canadian Short-Term Bond Index ETF. We have chosen to use a short-term bond ETF to provide clients with lower exposure to interest rate risk than they would get from many other bond ETFs. VSB is the lowest cost short-term bond ETF in Canada. It holds mostly Canadian federal and provincial bonds, as well as bonds issued by Canadian companies such as banks.',
      img: 'canada-map',
      performance: {
        2013: 1.58,
        2014: 2.8,
        2015: 2.02,
        2016: 1.25,
        2017: -0.36,
        2018: 1.79,
        2019: 2.86,
        2020: 5.31,
      },
    },
    clf: {
      name: 'iShares 1-5 Year Laddered Government Bond Index ETF',
      ticker: 'CLF',
      mer: '0.17%',
      assetClass: 'Canadian Bonds - Socially Responsible',
      totalAssets: '$703 million',
      yield: '2.12%',
      geography: 'Canada',
      description:
        'A socially responsible alternative to VSB. This ETF invests only in Canadian government bonds. By avoiding corporate bonds it will not have any exposure to bonds from companies that may have a questionable environmental, social, or governance record.',
      img: 'canada-map',
      performance: {
        2013: 1.7,
        2014: 2.95,
        2015: 2.51,
        2016: 0.14,
        2017: -0.49,
        2018: 1.74,
        2019: 2.33,
        2020: 4.81,
      },
    },
    zef: {
      name: 'BMO Emerging Markets Bond Hedged to CAD Index ETF',
      ticker: 'ZEF',
      mer: '0.55%',
      assetClass: 'Emerging Market Bonds',
      totalAssets: '$420 million',
      yield: '4.39%',
      geography: 'China, Turkey, Russia, Mexico, Brazil, Indonesia',
      description:
        'For emerging market bonds we use the BMO Emerging Markets Bond Hedged to CAD Index ETF. This ETF invests in bonds from countries like South Korea, Russia, Mexico and Indonesia. This is the only emerging market bond ETF available in Canada. The bonds held by ZEF are US Dollar denominated, BMO hedges the US Dollar exposure back to Canadian Dollars. While there are US-based emerging market bond ETFs that are less expensive, the cost of currency conversions makes ZEF the better choice for Canadians.',
      img: 'zef-map',
      performance: {
        2013: -4.73,
        2014: 8.04,
        2015: 3.38,
        2016: 6.63,
        2017: 4.84,
        2018: -3.99,
        2019: 7.24,
        2020: 4.54,
      },
    },
    vre: {
      name: 'Vanguard FTSE Canadian Capped REIT Index ETF',
      ticker: 'VRE',
      mer: '0.38%',
      assetClass: 'Real Estate',
      totalAssets: '$258 million',
      yield: '3.51%',
      geography: 'Canada',
      description:
        "For Real Estate Investment Trusts (REITs) we use Vanguard's FTSE Canadian Capped REIT Index ETF. While there are other larger ETFs available in Canada, this is the least expensive. As VRE continues to attract assets, the costs are likely to continue to decline. Real Estate provides good diversification benefits for portfolios already containing stocks and bonds. VRE provides broad exposure to industrial, office, retail and residential real estate.",
      img: 'canada-map',
      performance: {
        2013: -1.5,
        2014: 11.57,
        2015: 0.48,
        2016: 14.47,
        2017: 9.94,
        2018: 1.65,
        2019: 21.23,
        2020: -10.67,
      },
    },
    esgd: {
      name: 'iShares ESG Aware MSCI EAFE ETF',
      ticker: 'ESGD',
      mer: '0.20%',
      assetClass: 'International Developed Market Stocks - Socially Responsible',
      totalAssets: 'US $5.3 billion',
      yield: '1.57%',
      geography: 'Japan, U.K, Europe, Australia',
      description:
        'A socially responsible alternative to VEF. Companies involved in tobacco or military weapons are excluded. The remaining companies are screened on environmental, social and governance factors and must maintain a minimum B rating.',
      img: 'vef-map',
      performance: {
        2013: null,
        2014: null,
        2015: null,
        2016: null,
        2017: 25.12,
        2018: 13.62,
        2019: 23.45,
        2020: 8.23,
      },
    },
    esge: {
      name: 'iShares ESG Aware MSCI EM ETF',
      ticker: 'ESGE (US)',
      mer: '0.25%',
      assetClass: 'Emerging Market Stocks - Socially Responsible',
      totalAssets: 'US $7.2 billion',
      yield: '1.28%',
      geography: 'China, South Korea, Taiwan, India, South Africa, Brazil',
      description:
        'A socially responsible alternative to VEE. Companies involved in tobacco or military weapons are excluded. The remaining companies are screened on environmental, social and governance factors and must maintain a minimum B rating.',
      img: 'vee-map',
      performance: {
        2013: null,
        2014: null,
        2015: null,
        2016: null,
        2017: 38.88,
        2018: -14.31,
        2019: 19.11,
        2020: 19.88,
      },
    },
    reet: {
      name: 'iShares Global REIT ETF',
      ticker: 'REET (US)',
      mer: '0.14%',
      assetClass: 'Real Estate - Socially Responsible',
      totalAssets: 'US $3 billion',
      yield: '2.39%',
      geography: 'US, Australia, Japan, UK, France, Canada',
      description:
        "A socially responsible alternative to VRE. This ETF has received a 'High' rating from Morningstar for sustainability.",
      img: 'reet-map',
      performance: {
        2013: null,
        2014: null,
        2015: 0.64,
        2016: 5.3,
        2017: 7.48,
        2018: -4.89,
        2019: 23.89,
        2020: -8.3,
      },
    },
  };

  var resolveChartData = function (etf) {
    var getCsv = function (resolve, reject) {
      var dataset;
      // d3.csv is async and operations depending on data must happen in callback
      d3.csv('assets/etf-csvs/' + etf.ticker + '-5Y-reduced.csv', function (error, data) {
        if (error) {
          reject(error);
        } else {
          var dateFormat = d3.time.format('%Y-%m-%d');

          // Parse dates and coerce to num
          var parseData = function (data) {
            data.forEach(function (d) {
              d.date = dateFormat.parse(d.date);
              d.adjClose = +d.adjClose;
            });
            return data;
          };
          dataset = parseData(data);

          dataset.sort(function (x, y) {
            return d3.descending(x.date, y.date);
          });

          resolve(dataset);
        }
      });
    };

    return $q(getCsv).catch(function (error) {
      console.log(error);
    });
  };

  return {
    resolveChartData,
    etfs,
  };
}
