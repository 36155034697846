'use strict';

import beneficiaryFormRespComponent from './beneficiary-form-resp.component';

const BeneficiaryFormRespModule = angular
  .module('component.beneficiary-form-resp', [
    'model.RespBeneficiary',
    'model.AdvisorBeneficiaryForm',
    'model.Beneficiary',
    'service.spouse-or-partner',
    'service.beneficiary-params-generator',
  ])
  .component('beneficiaryFormResp', beneficiaryFormRespComponent);

export default BeneficiaryFormRespModule;
