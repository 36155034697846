'use strict';

import allocationOrPositionColourMapperAndSorter from './allocation-or-position-colour-mapper-and-sorter.service';

angular
  .module('service.investment-charts.allocation-or-position-colour-mapper-and-sorter', [
    'model.Fund',
    'service.recolor-funds',
  ])
  .service(
    'investmentChartsAllocationOrPositionColourMapperAndSorter',
    allocationOrPositionColourMapperAndSorter
  );
