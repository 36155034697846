'use strict';

/***********************************************************************************
 * General case controller for this component
 */
class IdVerificationSectionController {
  constructor($http, $rootScope) {
    this.$http = $http;
    this.$rootScope = $rootScope;
  }

  $onInit() {}

  rerunExternalIdentityVerfication() {
    var title = 'Rerun Identity Verification';
    var modalBody = 'Are you sure you want to rerun identity verification?';
    this.$rootScope
      .genericConfirmationModal(title, modalBody)
      .result.then(() => {
        this.isExternallyVerifying = true;
        return this.$http.post('/api/advisor/external_identity_checks.json', {
          personId: this.personId,
        });
      })
      .then(() => {
        return this.afterIdentityCheck({
          personId: this.personId,
        });
      })
      .finally(() => {
        this.isExternallyVerifying = false;
      });
  }

  manuallyVerifyIdentity() {
    this.$rootScope
      .advisorIdVerifyConfirm(this.personId)
      .result.then(() => {
        this.isManuallyVerifying = true;
        return this.afterIdentityCheck({
          personId: this.personId,
        });
      })
      .finally(() => {
        this.isManuallyVerifying = false;
      });
  }
}

export default IdVerificationSectionController;
