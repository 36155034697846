'use strict';

function questionFactory(ram, Dependency, Choice) {
  /**
   * @class Represents a question to be presented to the user.
   *
   * @method show
   * @method skip
   * @method isMultipleChoice
   * @method save
   */
  var Question = new ram.Collection('Question', {
    hasMany: {
      choices: Choice,
      dependencies: Dependency,
    },
    resources: {
      default: new ram.resources.Http('/api/questions/:id.json'),
      cookie: new ram.resources.Cookie('questions'),
    },
  });

  /**
   * Whether to show the current question based on answers to previous questions.
   *
   * @param {Array}     resources An array of objects to use to attempt to
   *                              resolve dependencies of this question.
   * @return {Boolean}
   */
  Question.prototype.show = function (resources) {
    return !this.skip(resources);
  };

  /**
   * Whether to skip the current question based on the answer to previous
   * questions.
   *
   * @param {Array}     resources An array of objects to use to attempt to
   *                              resolve dependencies of this question.
   * @return {Boolean}
   */
  Question.prototype.skip = function (resources) {
    return !!_.find(this.dependencies(), function (dependency) {
      return !dependency.satisfiedBy(resources);
    });
  };

  /**
   * Should the question be treated as multiple choice?
   *
   * @return {Boolean}
   */
  Question.prototype.isMultipleChoice = function () {
    return this.choices().length > 0;
  };

  /**
   * Does the question have both a min and a max defined?
   *
   * @return {Boolean}
   */
  Question.prototype.isBounded = function () {
    return _.isFinite(parseFloat(this.min())) && _.isFinite(parseFloat(this.max()));
  };

  /**
   * If a question is bounded, or multiple choice, normalize an answer
   * against the full range of possible answers.
   *
   * @param  {Answer} answer The number to normalize
   * @return {Number}        Normalized answer between 0 and 1
   */
  Question.prototype.normalize = function (answer) {
    var normal = answer === undefined ? 0 : answer;

    if (this.isBounded()) {
      normal =
        (answer - parseFloat(this.min())) / (parseFloat(this.max()) - parseFloat(this.min()));
    }

    if (this.isMultipleChoice()) {
      var values = _.map(this.choices(), function (choice) {
        return choice.value();
      });
      var max = Math.max.apply(null, values);
      var min = Math.min.apply(null, values);

      normal = (answer - min) / (max - min);
    }

    return Math.min(1, Math.max(0, normal));
  };

  /**
   * If a question is multiple choice, returns the description of the answer
   * with the specified value.
   *
   * @param  {Primative} value The value of the answer
   * @return {String}           Description of the specified choice, or undefined
   */
  Question.prototype.choiceDescription = function (value) {
    var choice = _.find(this.choices(), function (choice) {
      return value === choice.value();
    });
    return choice && choice.description();
  };

  /**
   * Returns a human readable textual interpretation of an answer using a
   * questions' prefix and suffix fields or the choice text if the question
   * is multiple choice.
   *
   * @param  {Primative} value The value of the answer
   * @return {String}          Text representation of the answer
   */
  Question.prototype.answerText = function (value) {
    var choice = _.find(this.choices(), function (choice) {
      return value === choice.value();
    });
    return (choice && choice.label()) || (this.prefix() || '') + value + (this.suffix() || '');
  };

  return Question;
}

angular
  .module('model.Question', ['ram', 'model.Dependency', 'model.Choice'])
  .factory('Question', ['ram', 'Dependency', 'Choice', questionFactory]);
