'use strict';

angular
  .module('routes.advise.upload-mutual-fund-trades', [
    'react.component.advise.upload-mutual-fund-trades',
    'templates',
    'ui.router',
    'model.OrderTradeTicket',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function authorizeSuperAdvisor(authorizer) {
    return authorizer.checkPermission('superAdvisor');
  }

  function resolveMissingTradeFiles(OrderTradeTicket) {
    return OrderTradeTicket.constructor.outstandingTickets();
  }

  $stateProvider
    .state('advise.upload-mutual-fund-trades', {
      url: '/upload-mutual-fund-trades',
      abstract: false,
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
      },
      template: '<div ui-view></div>',
    })
    .state('advise.upload-mutual-fund-trades.file', {
      url: '/file',
      controller: [
        '$scope',
        'missingTradeFiles',
        ($scope, missingTradeFiles) => {
          $scope.missingTradeFiles = missingTradeFiles.data;
        },
      ],
      resolve: {
        missingTradeFiles: ['OrderTradeTicket', resolveMissingTradeFiles],
      },
      template:
        '<react-upload-mutual-fund-trades missing-trade-file-info="missingTradeFiles"></react-upload-mutual-fund-trades>',
    });
}
