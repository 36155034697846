'use strict';

function modalTransferController($scope, transferInstruction, transferDisplayData) {
  $scope.transferInstruction = transferInstruction;
  $scope.transferDisplayData = transferDisplayData;

  $scope.setBigModal = function () {
    if ($scope.confirmMessage) {
      angular.element('.modal:not(.menu)').addClass('big-dialog-disable-flexbox');
    }
  };

  $scope.transferFromToTheSame = function () {
    const from = $scope.transferDisplayData.fromAccount;
    const to = $scope.transferDisplayData.toAccount;
    if (from.constructor.name === 'BankAccount' || to.constructor.name === 'BankAccount') {
      // Return early if one is a bank account. This error used to be hidden by AngularJS, the comparison from the bottom was always done (in the HTML) with bank accounts before.
      return false;
    }
    const fromVerb =
      from.constructor.name === 'AdvisorCreateNewAccountFlow' ? 'accountTypeId' : 'typeId';
    const toVerb =
      to.constructor.name === 'AdvisorCreateNewAccountFlow' ? 'accountTypeId' : 'typeId';
    return from[fromVerb]() === to[toVerb]();
  };
}

angular
  .module('controller.modal.transfer', [])
  .controller('modalTransferCtrl', [
    '$scope',
    'transferInstruction',
    'transferDisplayData',
    modalTransferController,
  ]);
