'use strict';

angular
  .module('controller.marketing.spring', ['directive.open-account-lead'])
  .controller('SpringPromoCtrl', [
    '$scope',
    'storedUser',
    'storedGoal',
    'presetQuestion',
    springPromoController,
  ]);

function springPromoController($scope, user, goal, question) {
  $scope.user = user;
  $scope.goal = goal;
  $scope.question = question;

  if (!goal.preset()) {
    question.choices.ready().then(function (choices) {
      goal.preset(choices[1].value());
      goal.save();
    });
  }
}
