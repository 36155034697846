'use strict';

function recommendationRadioButtonDirective(optionHelper) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      form: '=',
      name: '@',
      otherOptions: '=',
      optionLabel: '@',
      optionValue: '@',
      optionTitle: '@',
      recommendedOptions: '=',
      ngModel: '=',
      ngChange: '&',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      class: '@',
      inline: '@',
      labelClass: '@',
      helpClass: '@',
    },
    templateUrl: 'directives/input/recommendation-radio-button.html',
    link: optionHelper.link,
  };
}

angular
  .module('directive.input.recommendation-radio-button', [
    'templates',
    'animations',
    'directive.focus',
    'directive.input.option-helper',
  ])
  .directive('recommendationRadioButton', ['optionHelper', recommendationRadioButtonDirective]);
