'use strict';

/**
 * Initialize angular UI router for the mobile upload request
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider) {
  function resolveUserName($stateParams) {
    return $stateParams.user;
  }

  function resolvePassword($stateParams) {
    return $stateParams.password;
  }

  function resolveByAdvisor($stateParams) {
    return !!$stateParams.invitedByAdvisor;
  }

  function resolveUploadData($http, userName, password) {
    return $http
      .get('/api/one_time_passwords', {
        headers: {
          Authorization: 'Basic ' + btoa(userName + ':' + password),
        },
      })
      .then(
        function (passwordRecords) {
          // There should only be one.
          if (
            _.isUndefined(passwordRecords) ||
            _.isUndefined(passwordRecords.data) ||
            passwordRecords.data.length === 0
          ) {
            return null;
          }
          return _.first(passwordRecords.data);
        },
        function () {
          // controller handles null for error case
          return null;
        }
      );
  }

  $stateProvider.state('mobile-upload-request', {
    url: '/mobile-upload-request?user&password&invitedByAdvisor',
    parent: 'site',
    controller: 'MobileUploadRequestCtrl',
    resolve: {
      userName: ['$stateParams', resolveUserName],
      password: ['$stateParams', resolvePassword],
      invitedByAdvisor: ['$stateParams', resolveByAdvisor],
      uploadData: ['$http', 'userName', 'password', resolveUploadData],
    },
    views: {
      main: {
        controller: 'MobileUploadRequestCtrl',
      },
      navbar: {
        templateUrl: 'navbar/mobile-upload-navbar.html',
      },
      footer: {
        templateUrl: 'mobile/partials/footer.html',
      },
    },
  });
}

angular
  .module('routes.mobile-upload-request', [
    'controller.mobile-upload-request',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);
