'use strict';

function directive($timeout) {
  return {
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/input/help-block.html',
    link: function (scope, element, attrs) {
      var errors = {};
      var events = attrs.events || 'change blur focus';

      _.mapObject(attrs, function (v, k) {
        if (k.startsWith('error')) {
          var error = k.replace('error', '').toLowerCase();
          errors[error] = v;
        }
      });

      $timeout(function () {
        var field = angular.element(attrs.for);
        var ngModel = field.controller('ngModel');

        function setMessage(message, type) {
          scope.errorMessage = type === 'error' ? message : null;
          scope.focusMessage = type === 'focus' ? message : null;
          scope.defaultMessage = type === 'default' ? message : null;
        }

        function updateMessage(event) {
          var focus = event.type === 'focus' || event.type === 'change';
          var error = _.find(errors, function (msg, name) {
            return ngModel && ngModel.$error[name] && msg;
          });

          // Need to check that ngModel exists since it is possible
          // for a slider input to have ngModel = undefined
          if (ngModel && ngModel.$dirty && error) {
            setMessage(error, 'error');
          } else if (focus && attrs.focusText) {
            setMessage(attrs.focusText, 'focus');
          } else if (event.name !== 'submit') {
            setMessage(attrs.defaultText, 'default');
          }

          scope.$applyAsync();
        }

        field.on(events, _.debounce(updateMessage, 100));

        var showAlways = attrs.showAlways;
        if (showAlways && showAlways === 'default') {
          setMessage(attrs.defaultText, 'default');
        } else if (showAlways && showAlways === 'focus') {
          setMessage(attrs.focusText, 'focus');
        }

        scope.$on('submit', updateMessage);

        scope.message = attrs.defaultText;
        scope.$apply();
      });
    },
  };
}

angular.module('directive.input.help-block', []).directive('helpBlock', ['$timeout', directive]);
