import React from 'react';
import FileUpload from '../file-upload/FileUpload';

function UploadFundPricesModal({ dismiss, close, csrfToken, userNotifications }) {
  const api = '/api/super_advisor/fund_prices/upload_fund_prices.json';
  const tooltipText =
    "Upload a CSV.  Valid headings are ['Symbol', 'Bid', 'Ask'] or ['Financial Instrument', 'Bid', 'Ask'].  Extra columns are ignored.  Headings are not case sensitive.  Symbols must be UPPER CASE.";

  function onload({ success, result }) {
    if (success) {
      close({ success: true });
    } else {
      const errorMessage =
        result.error || 'There was an unexpected result. Please try again later.';
      userNotifications.showError(errorMessage);
    }
  }

  function onerror() {
    userNotifications.showError(
      'Something went wrong while uploading the data. Please try again later.'
    );
  }

  return (
    <>
      <div className="modal-header">
        <h4 className="modal-title text-center">Upload ETF Prices CSV</h4>
      </div>
      <div className="modal-body">
        <div className="panel-body">
          <div id="user-notification" className="m-b-0-alert-override" />

          <FileUpload
            buttonText="Pick"
            tooltipText={tooltipText}
            api={api}
            acceptList=".csv"
            csrfToken={csrfToken}
            onload={onload}
            onerror={onerror}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-default" onClick={dismiss}>
          Cancel
        </button>
      </div>
    </>
  );
}

export default UploadFundPricesModal;
