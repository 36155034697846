'use strict';

import maReact2Angular from '../maReact2Angular';
import AdvisorReports from './AdvisorReports';

angular
  .module('react.component.advise.advisor-reports', [
    'config',
    'csrf',
    'service.user-notifications',
  ])
  .component(
    'reactAdvisorReports',
    maReact2Angular(
      AdvisorReports,
      {
        accounts: '<',
      },
      ['csrfToken', 'userNotifications', 'config']
    )
  );
