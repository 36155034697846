'use strict';

function factory() {
  function CashFlow(date, amount) {
    this.amount = amount || 0;
    this.date = new Date(date);
  }

  return CashFlow;
}

angular.module('model.CashFlow', []).factory('CashFlow', [factory]);
