'use strict';

angular
  .module('controller.dashboard.activity', [
    'service.activityDisplayHelper',
    'service.timeframeDisplayHelper',
    'directive.dashboard-account-processing-messages',
    'directive.mobile-list-cards',
    'ram',
    'service.group-by-date',
  ])
  .controller('DashboardActivityController', [
    '$scope',
    'activityDisplayHelper',
    'identityVerificationStatus',
    'timeframeDisplayHelper',
    'ram',
    'groupByDateService',
    activityController,
  ]);

function activityController(
  $scope,
  activityDisplayHelper,
  identityVerificationStatus,
  timeframeDisplayHelper,
  ram,
  groupByDateService
) {
  var data = activityDisplayHelper.getActivityTypes();
  data = data.filter(function (item) {
    return !item.hidden;
  });
  $scope.activityTypes = data;
  $scope.identityVerificationStatus = identityVerificationStatus;

  $scope.activityPeriods = timeframeDisplayHelper.getPeriods();
  $scope.accountById = activityDisplayHelper.accountById;

  $scope.activityTypeValue = $scope.activityTypes[0];
  $scope.activityType = new ram.Accessor($scope.activityTypes[0]);
  $scope.activityPeriod = new ram.Accessor(
    $scope.isMobile ? $scope.activityPeriods[0] : $scope.activityPeriods[3]
  );

  $scope.$watchGroup(['account', 'activityType()', 'activityPeriod()'], function () {
    $scope.allActivities = activityDisplayHelper.activities(
      $scope.account,
      $scope.activityType(),
      $scope.activityPeriod()
    );

    $scope.displayTotalAmount = activityDisplayHelper.displayTotalAmount($scope.activityType());
    $scope.totalAmount = activityDisplayHelper.getTotalAmount($scope.allActivities);
    if ($scope.activityType().name === 'buy') {
      $scope.totalAmount = Math.abs($scope.totalAmount);
    }

    $scope.activitiesByDate = groupByDateService.call($scope.allActivities, 'date');
  });
}
