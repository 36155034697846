'use strict';

angular
  .module('routes.profile', [
    'controller.minimal-footer',
    'controller.profile',
    'model.BankAccount',
    'routes.profile.bank',
    'routes.profile.household',
    'service.adaptive-views',
    'service.address-service',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', '$urlRouterProvider', 'adaptiveViewsProvider', configRoutes]);

/**
 * Initialize angular UI router for user profile.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, $urlRouterProvider, adaptiveViewsProvider) {
  function authorize(authorizer) {
    return authorizer('Client');
  }

  function resolveIsMobile() {
    return adaptiveViewsProvider.isMobile();
  }

  function resolveIsDesktopSafari() {
    return adaptiveViewsProvider.isDesktopSafari();
  }

  function person(currentUser) {
    return currentUser.person.ready();
  }

  function spousalRelation(person) {
    return person.spouseRelation.ready();
  }

  function resolveAssociation(association) {
    return function (model) {
      return model[association].ready();
    };
  }

  function resolveAccounts(currentUser) {
    return currentUser.accounts.ready().then(function (accounts) {
      return _.filter(accounts, function (account) {
        return !!account.number() && !account.type.is.trial();
      });
    });
  }

  function resolveSuccessorHolders($q, accounts) {
    var successorHolderPromises = _.map(accounts, function (account) {
      return account.successorHolder.ready();
    });

    return $q.all(successorHolderPromises);
  }

  function fetchCurrentUser(currentUser) {
    return currentUser.fetch(true);
  }

  function routeToPersonal($state) {
    $state.transitionTo('profile.personal');
  }

  function routeToNav($state) {
    $state.transitionTo('profile.nav');
  }

  if (!adaptiveViewsProvider.isMobile()) {
    $urlRouterProvider.when('/profile', ['$state', routeToPersonal]);
    $urlRouterProvider.when('/profile/nav', ['$state', routeToPersonal]);
  } else {
    $urlRouterProvider.when('/profile', ['$state', routeToNav]);
  }

  function resolveProfile(Profile) {
    return Profile.where({}).then(function (profiles) {
      return profiles[0];
    });
  }

  $stateProvider
    .state('profile', {
      url: '/profile',
      data: {
        menuTitle: 'Profile',
      },
      hasMobileView: true,
      parent: 'site',
      resolve: {
        account: function () {
          return undefined;
        }, // Needed for navbar
        authorize: ['authorizer', authorize],
        person: ['currentUser', 'authorize', person],
        spousalRelation: ['person', spousalRelation],
        addressService: 'addressService',
        homeAddress: [
          'person',
          'addressService',
          function (person, addressService) {
            return addressService.resolveHomeAddress(person);
          },
        ],
        mailingAddress: [
          'person',
          'homeAddress',
          'addressService',
          function (person, homeAddress, addressService) {
            return addressService.resolveMailingAddress(person, homeAddress);
          },
        ],
        legalInfo: ['person', resolveAssociation('legalInfo')],
        brokerageAccounts: ['currentUser', resolveAssociation('brokerageAccounts')],
        insiderRoles: ['person', resolveAssociation('insiderRoles')],
        accounts: ['currentUser', resolveAccounts],
        authenticationFactor: ['currentUser', resolveAssociation('authenticationFactor')],
        redirectToDefault: [
          '$state',
          'redirect',
          function ($state, redirect) {
            if (
              $state.is('profile-activate-two-factor-authentication') ||
              $state.is('profile-deactivate-two-factor-authentication') ||
              $state.is('profile-deactivate-authenticator-app')
            ) {
              redirect.to('profile.security');
            }
          },
        ],
        advisorProfile: ['Profile', resolveProfile],
      },
      views: {
        main: {
          controller: 'ProfileCtrl',
        },
        navbar: {
          controller: 'DashboardNavbarCtrl',
          templateUrl: adaptiveViewsProvider.getTemplateUrlForSrc('navbar/dashboard.html', true),
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
    })
    .state('profile.nav', {
      url: '/nav',
      data: {
        menuTitle: 'Profile',
      },
      templateUrl: 'views/profile/nav.html',
    })
    .state('profile.personal', {
      url: '/personal',
      data: {
        menuTitle: 'Your Info',
        backButtonState: 'profile.nav',
      },
      resolve: {
        fetchedUser: ['currentUser', fetchCurrentUser],
      },
      templateUrl: 'partials/profile/personal.html',
    })
    .state('profile.legal', {
      url: '/legal',
      data: {
        menuTitle: 'Legal',
        backButtonState: 'profile.nav',
      },
      templateUrl: 'partials/profile/legal.html',
    })
    .state('profile.risk', {
      url: '/risk',
      data: {
        menuTitle: 'Risk Appetite',
        backButtonState: 'profile.nav',
      },
      templateUrl: 'partials/profile/risk-appetite.html',
    })
    .state('profile.beneficiaries', {
      url: '/beneficiaries',
      data: {
        menuTitle: 'Beneficiaries',
        backButtonState: 'profile.nav',
      },
      resolve: {
        successorHolders: ['$q', 'accounts', resolveSuccessorHolders],
      },
      templateUrl: 'partials/profile/beneficiaries.html',
    })
    .state('profile.security', {
      url: '/security',
      controller: 'profileTwoFactorAuthenticationCtrl',
      data: {
        menuTitle: 'Security',
        backButtonState: 'profile.nav',
      },
      resolve: {
        isMobile: resolveIsMobile,
        isDesktopSafari: resolveIsDesktopSafari,
      },
      templateUrl: 'partials/profile/two-factor-authentication.html',
    })
    .state('profile-activate-two-factor-authentication', {
      parent: 'site',
      resolve: {
        isMobile: resolveIsMobile,
      },
      views: {
        main: {
          controller: 'ModalActivateTwoFactorAuthenticationController',
          templateUrl: 'modals/activate-two-factor-authentication.html',
        },
      },
    })
    .state('profile-deactivate-two-factor-authentication', {
      parent: 'site',
      resolve: {
        authenticationFactor: ['currentUser', resolveAssociation('authenticationFactor')],
        isMobile: resolveIsMobile,
      },
      views: {
        main: {
          controller: 'ModalTwoFactorAuthenticationCtrl',
          templateUrl: 'modals/two-factor-authentication.html',
        },
      },
    })
    .state('profile-deactivate-authenticator-app', {
      parent: 'site',
      resolve: {
        authenticationFactor: ['currentUser', resolveAssociation('authenticationFactor')],
        isMobile: resolveIsMobile,
      },
      views: {
        main: {
          controller: 'ModalDeactivateAuthenticatorAppController',
          templateUrl: 'modals/deactivate-authenticator-app.html',
        },
      },
    })
    .state('profile.test', {
      url: '/test',
      data: {
        menuTitle: 'Test',
        backButtonState: 'profile.nav',
      },
      templateUrl: 'partials/profile/test.html',
    });
}
