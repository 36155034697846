'use strict';

angular
  .module('controller.profile.personal', [
    'directive.input',
    'directive.profile-form',
    'directive.user.personal-info',
    'component.trusted-contact-status',
    'component.trusted-contact-edit',
    'component.trusted-contact-tooltip',
    'model.PersonalRelation',
    'model.Person',
    'model.PhoneNumber',
    'model.TrustedContact',
    'service.dropdown-options',
  ])
  .controller('ProfilePersonalEmailCtrl', [
    '$scope',
    '$q',
    'userNotifications',
    profilePersonalEmailController,
  ])
  .controller('ProfilePersonalCtrl', [
    '$scope',
    '$q',
    'profilePersonalService',
    'dropdownOptions',
    profilePersonalController,
  ])
  .controller('ProfilePersonalEmploymentCtrl', ['$scope', profilePersonalEmploymentController])
  .controller('ProfilePersonalSpouseCtrl', [
    '$scope',
    '$q',
    'profilePersonalService',
    'dropdownOptions',
    profilePersonalSpouseController,
  ])
  .controller('ProfilePersonalTrustedContactController', [
    '$scope',
    '$http',
    '$q',
    'Person',
    'PhoneNumber',
    'TrustedContact',
    'dropdownOptions',
    'trustedContactText',
    profilePersonalTrustedContactController,
  ])
  .factory('profilePersonalService', [
    '$rootScope',
    'userNotifications',
    profilePersonalServiceFactory,
  ]);

// wrapper around save function to enable easier chaining of promises
function saveWrapper(entity) {
  return function () {
    return entity.save();
  };
}

function savePhoneNumbers($q, person) {
  var promises = [];
  angular.forEach(person.phoneNumbers(), function (phoneNumber) {
    promises.push(phoneNumber.save());
  });
  return $q.all(promises);
}

function initFormPersonal($scope, $q, person, profilePersonalService) {
  var hadASpouse = person.hasSpouse();

  $scope.onChange = angular.noop;

  return {
    save: function () {
      var haveASpouse = person.hasSpouse();
      profilePersonalService.spouseWasAdded(!hadASpouse && haveASpouse);
      hadASpouse = haveASpouse;

      return (
        $q
          .all([
            $scope.person.mailingAddress().save(),
            $scope.person.homeAddress().save(),
            savePhoneNumbers($q, $scope.person),
          ])
          // person have to be saved in the end otherwise it will refresh addresses, etc.
          // and all the changes there will be overriden
          .then(saveWrapper($scope.person))
          .then(function () {
            return $scope.person.spouseRelation.ready(true);
          })
      );
    },
    reset: function () {
      return person.fetch(true);
    },
  };
}

function profilePersonalEmailController($scope, $q, userNotifications) {
  $scope.resendConfirmationEmail = function () {
    $scope.isResendingConfirmationEmail = true;
    userNotifications.clearNotification();

    $scope.currentUser
      .sendConfirmation()
      .then(function () {
        userNotifications.showSuccess(
          'A new confirmation email has been sent to ' + $scope.currentUser.unconfirmedEmail()
        );
      })
      .catch(function () {
        userNotifications.showError(
          'Something went wrong while resending the confirmation email. Please try again later.'
        );
      })
      .finally(function () {
        $scope.isResendingConfirmationEmail = false;
      });
  };

  /* jshint validthis: true */
  this.save = function () {
    userNotifications.clearNotification();

    return $scope.currentUser.save().catch(function (response) {
      if (response.data && response.data.errors && response.data.errors.email) {
        userNotifications.showError('Email ' + response.data.errors.email);
      } else {
        userNotifications.showError(
          'Something went wrong while saving your changes. Please try again later.'
        );
      }

      // The 'cancel' value is so that the 'Error' text doesn't show up beside
      // the Cancel button since we are already giving an error message.
      return $q.reject({
        $value: 'cancel',
      });
    });
  };
  this.reset = function () {
    userNotifications.clearNotification();

    return $scope.currentUser.fetch(true);
  };
}

function profilePersonalController($scope, $q, profilePersonalService, dropdownOptions) {
  var formPersonal = initFormPersonal($scope, $q, $scope.person, profilePersonalService);

  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.residencies = dropdownOptions.residencies();

  /* jshint validthis: true */
  this.save = formPersonal.save;
  this.reset = formPersonal.reset;
}

function profilePersonalEmploymentController($scope) {
  /* jshint validthis: true */
  this.save = function () {
    return $scope.person.makeConsistentAndSave();
  };
  this.reset = function () {
    return $scope.person.fetch(true);
  };
}

function profilePersonalSpouseController($scope, $q, profilePersonalService, dropdownOptions) {
  /* jshint validthis: true */

  $scope.onChange = angular.noop;

  var spouseIsEmpty = function () {
    if ($scope.spouseRelation) {
      var spouse = $scope.spouseRelation.relative();
      return (
        !spouse.firstName() || !spouse.lastName() || !spouse.gender() || !spouse.employmentStatus()
      );
    }
  };

  this.$onInit = function () {
    if (spouseIsEmpty() || profilePersonalService.spouseWasAdded()) {
      $scope.editingEnabled = true;
      $scope.preventCancel = true;
    }
  };

  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.residencies = dropdownOptions.residencies();

  this.save = function () {
    $scope.preventCancel = false;
    if ($scope.spouseRelation) {
      var spouse = $scope.spouseRelation.relative();
      return $q.all([
        spouse.makeConsistentAndSave(),
        spouse.mailingAddress() && spouse.mailingAddress().save(),
        spouse.homeAddress().save(),
        savePhoneNumbers($q, spouse),
      ]);
    }
    return $q.when(null);
  };
  this.reset = function () {
    return $scope.person.spouseRelation.ready(true);
  };
}

function profilePersonalTrustedContactController(
  $scope,
  $http,
  $q,
  Person,
  PhoneNumber,
  TrustedContact,
  dropdownOptions,
  trustedContactText
) {
  function _asDisplay(designated) {
    if (designated === null || designated === undefined) {
      return 'No selection';
    } else if (designated) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  function _convertJson(object, Klass) {
    return object ? new Klass(object.toJSON()) : new Klass();
  }

  $scope.trustedContactTooltipText = trustedContactText.client;
  $scope.trustedContact = $scope.person.trustedContact() || new TrustedContact();
  $scope.initialTcDesignated = $scope.trustedContact.designated();

  $scope.trustedContactStaticDesignated = () => _asDisplay($scope.trustedContact.designated());
  $scope.trustedContactStaticEmail = () => $scope.trustedContact.email();
  $scope.trustedContactStaticRelationship = () => $scope.trustedContact.relationship();

  // The following 2 are in their own models
  $scope.trustedContactContact = _convertJson($scope.trustedContact.contact(), Person);
  $scope.trustedContactPhone = _convertJson(
    $scope.trustedContact.contact()?.phoneNumbers()?.[0],
    PhoneNumber
  ); // jshint ignore:line
  $scope.trustedContactStaticName = () => $scope.trustedContactContact.jsFullName();
  $scope.trustedContactStaticPhone = () => $scope.trustedContactPhone.full();
  _saveBackupForWhenCancelPressed();

  $scope.onChangePhoneNumber = angular.noop;

  $scope.yesOrNo = dropdownOptions.yesOrNo();

  $scope.$watch('trustedContact.designated()', (newValue, oldValue) => {
    if (newValue === true && !oldValue) {
      $scope.$root.$broadcast('Profile-Form-Open-Pencils');
    } else if (oldValue && !newValue) {
      $scope.$root.$broadcast('resetToValidForm');
    }
  });

  /* jshint validthis: true */
  this.save = function () {
    if ($scope.initialTcDesignated === undefined) {
      // No trusted contact ever
      $scope.trustedContact
        .save({
          clientId: $scope.person.id,
        })
        .then((response) => {
          $scope.trustedContact.id = response.id;
          $scope.initialTcDesignated = null; // No longer undefined, so we don't end up here again
          if ($scope.trustedContact.designated()) {
            return $http
              .put(
                `/api/trusted_contacts/${$scope.trustedContact.id}/update_details.json`,
                $scope.trustedContact.detailsPayload(
                  $scope.trustedContactContact,
                  $scope.trustedContactPhone
                )
              )
              .then((result) => {
                $scope.trustedContactContact.id = result.data.contact.id;
                _saveBackupForWhenCancelPressed();
              })
              .catch((err) => console.log(err));
          }
        });
    } else if ($scope.trustedContact.designated()) {
      // Designated == YES
      return $scope.trustedContact
        .save()
        .then(() => {
          $http
            .put(
              `/api/trusted_contacts/${$scope.trustedContact.id}/update_details.json`,
              $scope.trustedContact.detailsPayload(
                $scope.trustedContactContact,
                $scope.trustedContactPhone
              )
            )
            .then((result) => {
              $scope.trustedContactContact.id = result.data.contact.id;
              _saveBackupForWhenCancelPressed();
            })
            .catch((err) => console.log('inner', err));
        })
        .catch((err) => console.log('outer', err));
    } else {
      // Designated == NO
      $scope.trustedContact.email(null);
      $scope.trustedContact.relationship(null);
      $scope.trustedContact
        .save()
        .then(() => {
          $scope.trustedContactContact.setFieldsEmpty();
          $scope.trustedContactPhone.setFieldsEmpty();
          _saveBackupForWhenCancelPressed();
        })
        .catch((err) => console.log(err));
    }
    return $q.when(null);
  };

  /* jshint validthis: true */
  this.resetToValidForm = function (formController) {
    const workList = [];

    _.each(formController.$error, function (errors) {
      _.each(errors, function (control) {
        workList.push({
          // One can't mutate the errors while iterating on them. Build a working list.
          control,
          errors: control.$error,
        });
      });
    });

    workList.forEach(({ control, errors }) => {
      Object.keys(errors).forEach((error) => {
        control.$setValidity(error, true); // hint -> console.log(`fixing ${error} with ${control.$name}`);
      });
    });
  };

  /* jshint validthis: true */
  this.reset = function () {
    return $scope.person.fetch(true).then((person) => {
      $scope.trustedContact = person.trustedContact() || new TrustedContact();
      _reApplyCancelBackup();
    });
  };

  function _saveBackupForWhenCancelPressed() {
    $scope.previousContactJson = $scope.trustedContactContact.toJSON();
    $scope.previousPhoneJson = $scope.trustedContactPhone.toJSON();
  }

  function _reApplyCancelBackup() {
    $scope.trustedContactContact = new Person($scope.previousContactJson);
    // In theory, it would be less code to just re-create the previousContactPhone and previousContactContact, but
    // since phone number is in a partial in the HTML, the new object doesn't make its way to the final spot.
    // Need to be explicit and re-use the same object.
    $scope.trustedContactPhone.countryCode($scope.previousPhoneJson.countryCode);
    $scope.trustedContactPhone.areaCode($scope.previousPhoneJson.areaCode);
    $scope.trustedContactPhone.exchangeCode($scope.previousPhoneJson.exchangeCode);
    $scope.trustedContactPhone.stationCode($scope.previousPhoneJson.stationCode);
    $scope.trustedContactPhone.initialize(); // re-build `full`
  }
}

function profilePersonalServiceFactory() {
  var spouseWasAdded = false;

  return {
    spouseWasAdded: function (wasAdded) {
      if (arguments.length) {
        spouseWasAdded = wasAdded;
      }
      return spouseWasAdded;
    },
  };
}
