'use strict';

import BeneficiaryDetailsInputController from './beneficiary-details-input.controller';

angular
  .module('component.beneficiary-details-input', [
    'service.account-service',
    'service.spouse-or-partner',
    'service.beneficiary-service',
    'model.Beneficiary',
    'directive.percentages-total-to-one-hundred',
  ])
  .component('beneficiaryDetailsInput', {
    templateUrl: 'components/beneficiary-details-input.html',
    bindings: {
      person: '<',
      personalRelation: '<',
      beneficiary: '<',
      currentUser: '<',
      onPersonChange: '<',
      onBeneficiaryChange: '<',
      onPersonalRelationChange: '<',
      rowClass: '<',
      deleteAction: '<',
      key: '@',
      labelIndentClass: '@',
      canDelete: '@',
      showPercent: '@',
      showRelationship: '@',
      setReadOnly: '@', // [combinations of:  spouse-name, spouse-relationship, spouse-sin]
      sinOptional: '@', // [undefined, 'true']
    },
    controller: ['$scope', 'Beneficiary', BeneficiaryDetailsInputController],
    controllerAs: '$ctrl',
  });
