'use strict';

angular
  .module('component.step.contact-info', ['model.PhoneNumber', 'ram'])
  .component('contactInfoStep', {
    templateUrl: 'components/step/contact-info.html',
    bindings: {
      onChange: '&',
    },
    controller: ['$scope', 'PhoneNumber', 'ram', controller],
    controllerAs: '$ctrl',
  });

function controller($scope, PhoneNumber, ram) {
  var $ctrl = this; // jshint ignore:line

  $scope.email = new ram.Accessor();
  $scope.firstName = new ram.Accessor();
  $scope.lastName = new ram.Accessor();
  $scope.phoneNumber = PhoneNumber.new();

  $scope.onChange = function () {
    $ctrl.onChange({
      firstName: $scope.firstName(),
      lastName: $scope.lastName(),
      email: $scope.email(),
      phoneNumber: $scope.phoneNumber.full(),
    });
  };
}
