'use strict';

function notificationsUnmatchedTransferQuery(Notification) {
  return {
    run: function (force) {
      return Notification.where(
        {},
        {
          force,
        }
      ).then(function (notifications) {
        return _.filter(notifications, function (notification) {
          return notification.relatedToType() === 'Transfer';
        });
      });
    },
  };
}

angular
  .module('query.notifications.unmatched-transfer', ['model.Notification'])
  .factory('NotificationsUnmatchedTransferQuery', [
    'Notification',
    notificationsUnmatchedTransferQuery,
  ]);
