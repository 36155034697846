'use strict';

angular
  .module('model.RifDetail', ['ram', 'config'])
  .factory('RifDetail', ['ram', 'config', rifDetailFactory]);

function rifDetailFactory(ram, config) {
  var RifDetail = new ram.Collection('RifDetail', {
    belongsTo: {
      account: 'Account',
      payToBankAccount: 'BankAccount',
      payToAccount: 'Account',
    },
    enums: {
      ageToUse: config.types.RifAgeToUse,
      paymentAmountType: config.types.RifPaymentAmountType,
      paymentAmountTax: config.types.RifPaymentAmountTax,
      taxWithholdingType: config.types.RifTaxWithholding,
      paymentFrequency: config.types.RifPaymentFrequency,
    },
    schema: config.schemas.RifDetail,
    resources: {
      default: new ram.resources.Http('/api/rif_details/:id.json'),
    },
  });

  return RifDetail;
}
