'use strict';

angular
  .module('controller.advise.adaptive-tactical-edit', [
    'ram',
    'csrf',
    'model.ModelPortfolio',
    'model.PortfolioTacticalTarget',
    'service.form-helper',
    'service.user-notifications',
  ])
  .controller('AdviseAdaptiveTacticalEdit', [
    '$document',
    '$scope',
    'csrfToken',
    'config',
    'ram',
    'formHelper',
    'ModelPortfolio',
    'PortfolioTacticalTarget',
    'userNotifications',
    adviseAdaptiveTacticalEdit,
  ]);

function adviseAdaptiveTacticalEdit(
  $document,
  $scope,
  csrfToken,
  config,
  ram,
  formHelper,
  ModelPortfolio,
  PortfolioTacticalTarget,
  userNotifications
) {
  var taxableMapping = {
    taxable: false,
    'non-taxable': false,
    both: false,
  }; // Use `taxable === false` for all reads now.  Taxable and non-taxable are the same right now.
  var portfolioOptionId = 2; // Adaptive portfolio
  var targetStartDate = null; // Caches the date of the PortfolioTacticalTarget that was just loaded.
  var fileInput = $document[0].getElementById('select-file');

  //
  // Initialize $scope
  //
  $scope.portfolioTacticalTarget = null;
  $scope.newStartDate = new ram.Accessor(new Date());
  $scope.modelPortfolio = null;
  $scope.oldValues = [];
  $scope.newValues = [];
  $scope.fileStatus = '';
  $scope.bulkFileName = '';
  $scope.isValidBulkFile = false;

  $scope.taxableOptions = getTaxableOptions();
  $scope.taxableOption = new ram.Accessor(_.last($scope.taxableOptions).id);

  $scope.riskLevelOptions = getRiskLevelOptions();
  $scope.riskLevelOption = new ram.Accessor(_.first($scope.riskLevelOptions).id);

  $scope.$watchGroup(['taxableOption()', 'riskLevelOption()'], function (val) {
    var taxableOption = val[0];
    var riskLevel = val[1];
    _resetUx(taxableOption, riskLevel);
  });

  $scope.$watch('newStartDate()', function (newVal, oldVal) {
    if (!_.isUndefined(newVal) && !_.isUndefined(oldVal) && newVal !== oldVal) {
      $scope.reset();
    }
  });

  //
  // $scope methods
  //
  $scope.changed = function () {
    userNotifications.clearNotification();
  };

  $scope.totalWeights = function () {
    if (!$scope.portfolioTacticalTarget) {
      return 0;
    }
    var result = _.reduce(
      $scope.newValues,
      function (sum, item) {
        return sum + parseFloat(item());
      },
      0.0
    );
    result = Math.round(result * 10000) / 10000;
    return result;
  };

  $scope.submitData = function (form) {
    if (_invalid(form)) {
      return;
    }
    _save();
  };

  $scope.reset = function () {
    _resetUx($scope.taxableOption(), $scope.riskLevelOption());
  };

  $scope.closeAlert = function () {
    userNotifications.clearNotification();
  };

  $scope.selectFile = function () {
    userNotifications.clearNotification();
    fileInput.click();
  };

  $scope.updateFileNameAndContent = function () {
    var newFile = fileInput.files[0];

    $scope.isValidBulkFile = _validateFile(newFile);
    if (!$scope.isValidBulkFile) {
      userNotifications.showError($scope.fileStatus);
      return;
    }
    $scope.bulkFileName = newFile.name;
    $scope.fileStatus = '(' + newFile.size + ' bytes)';
  };

  $scope.submitBulkLoad = function (formController) {
    userNotifications.clearNotification();
    if (!$scope.isValidBulkFile) {
      userNotifications.showError('Please select your file');
      return;
    }

    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $scope.isSaving = true;
      var formData = _generateFormData();

      var request = new XMLHttpRequest();
      request.open('POST', '/api/portfolio_tactical_targets/bulk_load.json');
      request.setRequestHeader('X-CSRF-Token', csrfToken());
      request.onload = function () {
        if (this.status === 200) {
          _resetUx($scope.taxableOption(), $scope.riskLevelOption());
        } else {
          var errorMessage = 'There was an unexpected result. Please try again later.';
          userNotifications.showError(errorMessage);
          $scope.isSaving = false;
        }
      };
      request.onerror = function () {
        userNotifications.showError(
          'Something went wrong while creating the report. Please try again later.'
        );
        $scope.isSaving = false;
      };
      request.send(formData);
    }
  };

  //
  // Private helper methods
  //
  function _invalid(form) {
    userNotifications.clearNotification();
    if (!$scope.portfolioTacticalTarget || form.$pristine) {
      userNotifications.showWarning('Nothing has changed');
      return true;
    }

    if (_hasBlankTacticalWeight($scope.portfolioTacticalTarget)) {
      userNotifications.showError('There are blanks');
      return true;
    }

    if (!_addsToOneHundred()) {
      userNotifications.showError("Values don't add up to 100.0");
      return true;
    }

    var isOutOfRange = _.any(
      $scope.portfolioTacticalTarget.portfolioTacticalAllocationTargets(),
      function (allocTarget) {
        var weight = parseFloat(allocTarget.tacticalWeight());
        return weight < 0 || weight > 1;
      }
    );

    if (isOutOfRange) {
      userNotifications.showError('There are numbers out of range');
    }
    return isOutOfRange;
  }

  function _loadAllocations(taxableOption, riskLevel) {
    return ModelPortfolio.where(
      {
        risk: riskLevel,
        taxable: taxableMapping[taxableOption],
        portfolioOptionId,
      },
      {
        force: true,
      }
    )
      .then(_.first)
      .then(function (modelPortfolio) {
        $scope.modelPortfolio = modelPortfolio;
        return PortfolioTacticalTarget.where({
          // Using ".find({})" would seem appropriate, but doesn't work as records are sparse, and we want the most recent before or equal to the start date.
          modelPortfolioId: modelPortfolio.id,
          startDate: _formattedStartDate(),
        });
      })
      .then(_.first)
      .then(function (portfolioTacticalTarget) {
        if (portfolioTacticalTarget) {
          $scope.portfolioTacticalTarget = portfolioTacticalTarget;
          targetStartDate = portfolioTacticalTarget.startDate();
          _createDisplayValues(portfolioTacticalTarget);
        } else {
          userNotifications.showError('Please bulk-load the initial tactical targets.');
        }
      });
  }

  function _createDisplayValues(portfolioTacticalTarget) {
    _.each(
      portfolioTacticalTarget.portfolioTacticalAllocationTargets(),
      function (allocationTarget) {
        var percent = Math.round(allocationTarget.tacticalWeight() * 10000.0) / 100;
        $scope.oldValues.push(percent);
        $scope.newValues.push(new ram.Accessor(percent));
      }
    );
    const total = _.reduce(
      $scope.oldValues,
      function (sum, item) {
        return sum + item;
      },
      0.0
    );
    $scope.oldValues.push(Math.round(total * 100.0) / 100);
  }

  function _save() {
    var portfolioTacticalTarget = null;

    _.each(
      $scope.portfolioTacticalTarget.portfolioTacticalAllocationTargets(),
      function (allocation, index) {
        allocation.tacticalWeight($scope.newValues[index]() / 100);
      }
    );

    if (_saveModifiesExisting()) {
      portfolioTacticalTarget = $scope.portfolioTacticalTarget;
    } else {
      var allocationItems = _(
        $scope.portfolioTacticalTarget.portfolioTacticalAllocationTargets()
      ).map(function (allocTarget) {
        return {
          allocationId: allocTarget.allocationId(),
          tacticalWeight: allocTarget.tacticalWeight(),
        };
      });
      portfolioTacticalTarget = PortfolioTacticalTarget.new({
        startDate: _selectedSaveMoment().toDate(),
        modelPortfolioId: $scope.modelPortfolio.id,
        portfolioTacticalAllocationTargets: allocationItems,
      });
    }
    portfolioTacticalTarget
      .save()
      .then(function (result) {
        userNotifications.showInfo('Saved');
        $scope.portfolioTacticalTarget = result;
        targetStartDate = result.startDate();
      })
      .catch(function () {
        userNotifications.showError('Error Saving');
      });
  }

  function _generateFormData() {
    var taxableOption = $scope.taxableOption();

    var formData = new FormData();
    var params = {
      startDate: _formattedStartDate(),
      taxable: taxableOption === 'both' || taxableOption === 'taxable',
      nonTaxable: taxableOption === 'both' || taxableOption === 'non-taxable',
      bulkFileName: $scope.bulkFileName,
    };

    formData.append('file', fileInput.files[0], $scope.bulkFileName);

    _.each(params, function (val, key) {
      var address = s.sprintf('portfolio_tactical_target[%s]', s.underscored(key));
      formData.append(address, val);
    });

    return formData;
  }

  function _validateFile(file) {
    if (!file) {
      $scope.fileStatus = 'No file selected.';
      $scope.bulkFileName = 'Please select your file.';
      return false;
    }

    if (file.size / 1024 / 1024 > 2) {
      $scope.fileStatus = 'File is too big.';
      $scope.bulkFileName = file.name;
      return false;
    }
    return true;
  }

  function _resetUx(taxableOption, riskLevel) {
    userNotifications.clearNotification();
    $scope.portfolioTacticalTarget = null;
    $scope.modelPortfolio = null;
    $scope.oldValues = [];
    $scope.newValues = [];

    _loadAllocations(taxableOption, riskLevel);
  }

  function _addsToOneHundred() {
    return $scope.totalWeights() === 100.0;
  }

  function _hasBlankTacticalWeight(data) {
    return _.any(data.portfolioTacticalAllocationTargets(), function (item) {
      return _.isNaN(parseFloat(item.tacticalWeight()));
    });
  }

  function _saveModifiesExisting() {
    var createdMoment = moment(targetStartDate).startOf('day');
    return createdMoment.format('YYYY-MM-DD') === _selectedSaveMoment().format('YYYY-MM-DD');
  }

  function _selectedSaveMoment() {
    return moment($scope.newStartDate()).startOf('day');
  }

  function _formattedStartDate() {
    return moment($scope.newStartDate()).format('YYYY-MM-DD');
  }
}

function getTaxableOptions() {
  // ** ** Only support operations on both taxable and non-taxable at the same time right now. ** **
  return [
    {
      // label: 'Taxable',
      // name: 'taxable',
      // id: 'taxable'
      // }, {
      //   label: 'Non-Taxable',
      //   name: 'non-taxable',
      //   id: 'non-taxable'
      // }, {
      label: 'Both Taxable and Non-Taxable',
      name: 'both',
      id: 'both',
    },
  ];
}

function getRiskLevelOptions() {
  // Risk level 1 is a savings account, not an account with allocations and targets.
  var VALID_RISK_LEVELS = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  return _(VALID_RISK_LEVELS).map(function (level) {
    var levelString = level.toString();
    return {
      label: levelString,
      name: levelString,
      id: level,
    };
  });
}
