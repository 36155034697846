'use strict';

angular
  .module('controller.modal.advisor.notification-subscription-modal', [
    'ram',
    'service.user-notifications',
    'model.AdvisorNotificationSubscription',
  ])
  .controller('ModalNotificationSubscriptionCtrl', [
    '$scope',
    'config',
    'ram',
    'currentUser',
    'userNotifications',
    'AdvisorNotificationSubscription',
    'currentUserSubscriptions',
    controller,
  ]);

function controller(
  $scope,
  config,
  ram,
  currentUser,
  userNotifications,
  AdvisorNotificationSubscription,
  currentUserSubscriptions
) {
  $scope.subscriptions = config.types.AdvisorNotification.map((notification) =>
    _dataObject(notification, currentUserSubscriptions)
  );

  $scope.save = function () {
    const valuesToSave = $scope.subscriptions.map((record) => [
      record.camelCaseName,
      record.model(),
    ]);
    AdvisorNotificationSubscription.save(valuesToSave)
      .then(() => $scope.$close())
      .catch(() =>
        userNotifications.showError(
          'There was a problem saving. Please try again. If the issue persists please contact us for assistance.'
        )
      );
  };

  function _dataObject(enumRecord, currentUserSubscriptions) {
    const camelCaseName = s.camelize(enumRecord.name);
    const currentValue = currentUserSubscriptions[camelCaseName];
    const model = new ram.Accessor(currentValue);
    return {
      ...enumRecord,
      camelCaseName,
      model,
    };
  }
}
