'use strict';

angular
  .module('controller.modal.advisor.confirm-account-approval', ['ram'])
  .controller('ModalAdvisorConfirmAccountApprovalCtrl', [
    '$scope',
    'ram',
    'identityVerificationStatus',
    'userId',
    controller,
  ]);

function controller($scope, ram, identityVerificationStatus, userId) {
  $scope.identityVerified = identityVerificationStatus;
  $scope.naafSigned = new ram.Accessor(true);
  $scope.imaSigned = new ram.Accessor(true);
  $scope.validIdOnFile = new ram.Accessor(false);
  $scope.userId = userId;
}
