'use strict';

angular
  .module('model.AdvisorCreateNewClientFlow', [
    'model.AdvisorCreateNewAccountFlow',
    'model.AdvisorCreateNewTransferFlow',
    'model.AdvisorCreateNewTransferInstructionFlow',
    'ram',
  ])
  .factory('AdvisorCreateNewClientFlow', [
    'ram',
    'config',
    '$http',
    advisorCreateNewClientFlowFactory,
  ]);

function advisorCreateNewClientFlowFactory(ram, config, $http) {
  var AdvisorCreateNewClientFlow = new ram.Collection('AdvisorCreateNewClientFlow', {
    accessors: ['status', 'person', 'phoneNumber'],
    belongsTo: {
      advisor: 'Person',
      client: 'Person',
    },
    hasMany: {
      advisorCreateNewAccountFlows: 'AdvisorCreateNewAccountFlow',
      advisorCreateNewTransferFlows: 'AdvisorCreateNewTransferFlow',
      advisorCreateNewTransferInstructionFlows: 'AdvisorCreateNewTransferInstructionFlow',
      accountFlowJointApplicants: 'AccountFlowJointApplicant',
    },
    resources: {
      default: new ram.resources.Http('/api/advisor_create_new_client_flows/:id.json'),
      cookie: new ram.resources.Cookie('advisor_create_new_client_flows'),
    },
  });

  AdvisorCreateNewClientFlow.prototype.delete = function () {
    return $http.delete('/api/advisor_create_new_client_flows/' + this.id + '.json');
  };

  AdvisorCreateNewClientFlow.prototype.updateName = function () {
    return $http.put('/api/advisor_create_new_client_flows/' + this.id + '/update_name.json');
  };

  AdvisorCreateNewClientFlow.prototype.relatedNames = function () {
    return $http
      .get('/api/advisor_create_new_client_flows/' + this.id + '/related_names')
      .then(function (response) {
        return response.data;
      });
  };

  AdvisorCreateNewClientFlow.prototype.accountFlowForNewClient = function (index) {
    return $http.get(
      '/api/advisor_create_new_client_flows/' + this.id + '/account_record_for_new_client.json',
      {
        params: {
          index,
        },
      }
    );
  };

  AdvisorCreateNewClientFlow.prototype.sendToClient = function () {
    return $http.get('/api/advisor_create_new_client_flows/' + this.id + '/send_to_client');
  };

  AdvisorCreateNewClientFlow.prototype.canSendToClient = function () {
    return (
      this.status() !== 'completed' && this.hasAllRequiredFieldsFilled() && this.hasAccountFlows()
    );
  };

  AdvisorCreateNewClientFlow.prototype.canEdit = function () {
    return this.status() !== 'completed';
  };

  AdvisorCreateNewClientFlow.prototype.canDeleteByAdvisor = function (applicationReadModel) {
    return (
      ((this.status() === 'created' || this.status() === 'emailed to client') &&
        applicationReadModel.openedAt() === null) ||
      (applicationReadModel.statusId() !==
        config.types.ApplicationReadModelStatus.filter(
          (status) => status.name === 'pending_review'
        )[0].id &&
        applicationReadModel.statusId() !==
          config.types.ApplicationReadModelStatus.filter((status) => status.name === 'approved')[0]
            .id &&
        applicationReadModel.statusId() !==
          config.types.ApplicationReadModelStatus.filter((status) => status.name === 'open')[0].id)
    );
  };

  AdvisorCreateNewClientFlow.lastJointApplicantEmail = function (newAccountFlows) {
    const count = newAccountFlows.length;
    if (count === 0) {
      return null;
    }
    for (let i = count - 1; i >= 0; i--) {
      const jointFlow = newAccountFlows[i].accountFlowJointApplicants()?.[0];
      if (jointFlow) {
        return jointFlow.email;
      }
    }
    return null;
  };

  return AdvisorCreateNewClientFlow;
}
