'use strict';

angular
  .module('model.SuccessorHolder', ['model.Account', 'model.PersonalRelation', 'ram'])
  .factory('SuccessorHolder', ['ram', '$http', successorHolderFactory]);

function successorHolderFactory(ram, $http) {
  var SuccessorHolder = new ram.Collection('SuccessorHolder', {
    bind: ['justCreated'],
    belongsTo: {
      account: 'Account',
      personalRelation: 'PersonalRelation',
    },
    resources: {
      default: new ram.resources.Http('/api/successor_holders/:id.json'),
      cookie: new ram.resources.Cookie('successor_holders'),
    },
  });

  SuccessorHolder.prototype.initialize = function () {
    this._justCreated = _.isUndefined(this._justCreated) ? false : this._justCreated;
  };

  SuccessorHolder.prototype.justCreated = function (val) {
    // To be polymorphic with Beneficiary
    if (!_.isUndefined(val)) {
      this._justCreated = val;
    }
    return this._justCreated;
  };

  SuccessorHolder.prototype.delete = function () {
    return $http.delete('/api/successor_holders/' + this.id + '.json');
  };

  return SuccessorHolder;
}
