'use strict';

angular
  .module('controller.kyc', [
    'directive.input',
    'directive.input.manual-help-block',
    'directive.profile-form',
    'service.adaptive-views',
    'service.form-modification-tracker',
    'service.init-unsaved-changes-tracking',
    'service.navigation',
    'controller.profile.personal',
    'controller.profile.finance',
    'controller.profile.legal',
    'controller.profile.risk',
    'controller.profile.test',
    'controller.profile.account-settings',
    'multiStepForm',
    'ram',
    'service.risk-level-business-rules',
  ])
  .controller('KycCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$state',
    '$http',
    'formModificationTracker',
    'config',
    'person',
    'brokerageAccounts',
    'insiderRoles',
    'accounts',
    'questions',
    'kycUpdate',
    'navigationService',
    'adaptiveViews',
    'unsavedChangesTracking',
    'riskLevelBusinessRules',
    kycController,
  ])
  .directive('multiStepBreadcrumbs', [multiStepBreadcrumbsDirective])
  .directive('multiStepMobileProgressBar', [multiStepMobileProgressBarDirective]);

var scrollUp = function () {
  window.scrollTo(0, 0);
};

function kycController(
  $rootScope,
  $scope,
  $window,
  $state,
  $http,
  formModificationTracker,
  config,
  person,
  brokerageAccounts,
  insiderRoles,
  accounts,
  questions,
  kycUpdate,
  navigationService,
  adaptiveViews,
  unsavedChangesTracking,
  riskLevelBusinessRules
) {
  $scope.config = config;
  $scope.person = person;
  $scope.isApp = adaptiveViews.isApp();
  $scope.kycUpdate = kycUpdate;
  $scope.questions = _.indexBy(questions, function (question) {
    return question.accessor();
  });

  $scope.brokerageAccounts = brokerageAccounts;
  $scope.insiderRoles = insiderRoles;

  $scope.accountsWithBeneficiaries = accounts.filter(function (account) {
    return account.type().beneficiary;
  });
  $scope.riskLevelBusinessRules = riskLevelBusinessRules;

  function setAccountDisplayIndexes() {
    var index = 1;
    _.each(accounts, function (account) {
      account.displayIndex = index;
      index++;
    });
  }
  setAccountDisplayIndexes();

  $scope.pleaseConfirmMessage = {
    text: 'Please confirm your information before continuing',
    show: false,
  };
  $scope.errorSavingMessage = {
    text: 'There was an error saving your data.  Please contact us and try again.',
    show: false,
  };

  unsavedChangesTracking.init($rootScope, $scope, $window, $state);

  /** @type {object} These are the steps for the KYC wizard */
  $scope.steps = [
    {
      templateUrl: 'partials/profile/personal.html',
      title: 'Your Info',
      serverTag: 'YourInfo',
      needsConfirmation: true,
      userHasConfirmed: false,
    },
    {
      templateUrl: 'partials/profile/finance.html',
      title: 'Finances',
      serverTag: 'Finances',
      needsConfirmation: true,
      userHasConfirmed: false,
    },
    {
      templateUrl: 'partials/profile/legal.html',
      title: 'Legal',
      serverTag: 'Legal',
      needsConfirmation: true,
      userHasConfirmed: false,
    },
    {
      templateUrl: 'partials/profile/risk-appetite.html',
      title: 'Investor Type',
      serverTag: 'InvestorType',
      needsConfirmation: true,
      userHasConfirmed: false,
    },
  ];

  function appendAccountPagesToSteps() {
    _.each(accounts, function (account) {
      $scope.steps.push({
        templateUrl: 'partials/profile/account-settings.html',
        title: 'Account Info',
        serverTag: 'Account_' + account.number(),
        needsConfirmation: true,
        userHasConfirmed: false,
        controller: [
          function () {
            $scope.account = account;
            $scope.accounts = accounts;
          },
        ],
      });
    });
  }
  appendAccountPagesToSteps();

  // --== Beneficiary page is last ==--------------------
  if ($scope.accountsWithBeneficiaries.length > 0) {
    $scope.steps.push({
      templateUrl: 'partials/profile/beneficiaries.html',
      title: 'Beneficiaries',
      serverTag: 'Beneficiaries',
      needsConfirmation: false,
      userHasConfirmed: false,
    });
  }

  var _highestPageReached = function () {
    for (var i = 0; i < $scope.steps.length; i++) {
      if (!_.contains($scope.kycUpdate.confirmedSteps(), $scope.steps[i].serverTag)) {
        return i + 1;
      }
    }
    return $scope.steps.length;
  };

  function setPreviouslyVisitedPagesFromDatabase() {
    var highest = _highestPageReached() - 1; // Convert back to zero-based
    for (var i = 0; i < highest; i++) {
      // 'highest' itself has not been checked yet so use '<' operator
      $scope.steps[i].userHasConfirmed = true;
    }
  }
  setPreviouslyVisitedPagesFromDatabase();

  /**
   * Make an array of 'sections' for the breadcrumbs.  Each page is generally a section.
   * The account-settings pages are all grouped together in one section.
   * You control what goes in what section by giving all the pages in that section the same title.
   *
   * Outputs $scope.breadCrumbItems
   *
   * @return nothing.
   */
  function createBreadcrumbSourceArray() {
    $scope.breadCrumbItems = [];
    var lastPageName = null;

    _.each($scope.steps, function (step, index) {
      if (step.title !== lastPageName) {
        lastPageName = step.title;
        $scope.breadCrumbItems.push({
          title: step.title,
          wizardSteps: [index + 1],
        });
      } else {
        $scope.breadCrumbItems[$scope.breadCrumbItems.length - 1].wizardSteps.push(index + 1);
      }
    });
  }
  createBreadcrumbSourceArray();

  var _changePageWorker = function (changePageAction) {
    formModificationTracker.onFormExit(null, null, changePageAction, changePageAction);
  };

  var _notifyServerAndChangePage = function () {
    $rootScope.showLoading();

    $http
      .put('/api/kyc_updates/' + $scope.kycUpdate.id + '/confirm.json', {
        step: $scope.currentStep.serverTag,
      })
      .then(function () {
        return $scope.kycUpdate.fetch();
      })
      .then(
        function () {
          $scope.currentAction();
          $scope.errorSavingMessage.show = false;
          $scope.pleaseConfirmMessage.show = false;
          scrollUp();
        },
        function () {
          $scope.errorSavingMessage.show = true;
        }
      )
      .finally(function () {
        $rootScope.hideLoading();
      });
  };

  var _pageConfirmed = function (pageIndex) {
    var currentStep = $scope.steps[pageIndex];
    return !currentStep.needsConfirmation || currentStep.userHasConfirmed;
  };

  /**
   * Advance to the next wizard page
   * @param  {function} nextStepAction - This is the function from the angular-multi-step-directive that switches the page.
   * @return nothing
   */
  $scope.nextPage = function (nextStepAction, getActiveIndexAction) {
    $scope.currentStepNumber = getActiveIndexAction() - 1;
    $scope.currentStep = $scope.steps[$scope.currentStepNumber];
    $scope.currentAction = function () {
      $scope.$broadcast('kycWizardNextStep');
      nextStepAction();
    };

    if (!_pageConfirmed($scope.currentStepNumber)) {
      $scope.pleaseConfirmMessage.show = true;
      return;
    }
    _changePageWorker(_notifyServerAndChangePage);
  };

  /**
   * Advance to the previous wizard page
   * @param  {function} prevStepAction - This is the function from the angular-multi-step-directive that switches the page.
   * @return nothing
   */
  $scope.prevPage = function (prevStepAction) {
    var _compoundAction = function () {
      prevStepAction();
      scrollUp();
    };
    _changePageWorker(_compoundAction);
  };

  $scope.lastPageVisited = function () {
    if ($scope.kycUpdate.confirmedSteps().length === 0) {
      return 1;
    }
    return _highestPageReached();
  };

  $scope.doneWizard = function () {
    var lastIndex = $scope.steps.length - 1;
    $scope.currentStepNumber = lastIndex;
    $scope.currentStep = $scope.steps[lastIndex];
    $scope.currentAction = function () {
      $scope.$broadcast('kycWizardCompleted');
      navigationService.onKycCompleted();

      $state.go(
        'kyc-completed',
        {},
        {
          reload: true,
        }
      );
    };

    _changePageWorker(_notifyServerAndChangePage);
  };

  $scope.asDate = function (date) {
    return moment(date).toDate();
  };
  $scope.today = moment().toDate();

  // Only show the wizard footer when not editing.
  var isEditing;
  $scope.isMobileAndEditing = function () {
    return isEditing && $scope.isMobile;
  };

  $scope.$on('editingToggled', function (event, editingEnabled) {
    event.stopPropagation(); // No need to let the event bubble further.
    isEditing = editingEnabled;
  });
}

function multiStepBreadcrumbsDirective() {
  return {
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/multi-step-breadcrumb.html',
    controller: [
      '$scope',
      function ($scope) {
        $scope.isActive = function (wizardSteps, activeIndex) {
          return _.contains(wizardSteps, activeIndex);
        };
      },
    ],
  };
}

/* Workaround solution to the problem that the progressbar is displayed outside of the   multi-step-container directive, but the progress is only available within that directive. */
function multiStepMobileProgressBarDirective() {
  return {
    restrict: 'E',
    scope: true,
    controller: [
      '$scope',
      function ($scope) {
        var anchorId = 'mandatory-kyc-info-bar';
        var template =
          '<!-- progress bar on mobile inserted by multi-step-mobile-progress-bar -->' +
          '<div class="mobile-progress-bar"></div>';

        // initialize progress bar
        document.getElementById(anchorId).insertAdjacentHTML('beforebegin', template);

        function updateProgress(currentIndex) {
          var progress = ((currentIndex / $scope.steps.length) * 100).toFixed() + '%';
          angular.element('.mobile-progress-bar').attr('style', 'width: ' + progress);
        }

        $scope.$watch('$getActiveIndex()', updateProgress);
      },
    ],
  };
}
