'use strict';

angular
  .module('model.CompletedRiskAppetiteQuiz', [
    'model.RiskAppetiteQuiz',
    'model.RiskAppetiteCalculation',
    'model.SelectedRiskAppetiteQuizChoice',
    'model.Person',
  ])
  .factory('CompletedRiskAppetiteQuiz', ['$http', 'ram', completedRiskAppetiteQuizFactory]);

function completedRiskAppetiteQuizFactory($http, ram) {
  /**
   * Constructor for resource-aware CompletedRiskAppetiteQuiz model.
   * @class
   */
  var CompletedRiskAppetiteQuiz = new ram.Collection('CompletedRiskAppetiteQuiz', {
    belongsTo: {
      riskAppetiteQuiz: 'RiskAppetiteQuiz',
      person: 'Person',
    },
    hasMany: {
      riskAppetiteCalculations: 'RiskAppetiteCalculation',
      selectedRiskAppetiteQuizChoices: 'SelectedRiskAppetiteQuizChoice',
    },
    serialize: ['selectedRiskAppetiteQuizChoices'],
    resources: {
      default: new ram.resources.Http('/api/completed_risk_appetite_quizzes/:id.json'),
      cookie: new ram.resources.Cookie('completed_risk_appetite_quizzes'),
    },
  });

  CompletedRiskAppetiteQuiz.prototype.calculateRiskAppetiteTypeId = function () {
    var self = this;
    return $http
      .post('/api/completed_risk_appetite_quizzes/calculate_risk_appetite', self.toJSON())
      .then(function (response) {
        return response.data.riskAppetiteTypeId;
      });
  };

  return CompletedRiskAppetiteQuiz;
}
