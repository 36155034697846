'use strict';

function positionFactory(ram, config) {
  /**
   * Constructor for Position models.
   * @class
   */
  var Position = new ram.Collection('Position', {
    belongsTo: {
      account: 'Account',
      fund: 'Fund',
    },
    bind: ['marketValue'],
    hasOne: {
      allocation: 'Allocation',
    },
    schema: config.schemas.Position,
    resources: {
      default: new ram.resources.Http('/api/positions/:id.json'),
      cookie: new ram.resources.Cookie('positions'),
    },
  });

  Position.prototype.percentOfPortfolio = function (shares) {
    var fund = this.fund();
    var account = this.account();
    if (fund && account) {
      shares = arguments.length ? shares : this.shares();
      var balance = account.balance();
      if (balance === 0) {
        return shares === 0 ? 0.0 : Infinity;
      }
      return (100 * shares * fund.lastTrade()) / balance;
    }
  };

  return Position;
}

angular
  .module('model.Position', ['ram', 'config', 'model.Fund', 'model.Account', 'model.Allocation'])
  .factory('Position', ['ram', 'config', positionFactory]);
