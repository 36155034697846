'use strict';

angular
  .module('controller.advisor-new-client.verify-identity', [
    'model.Person',
    'model.PhoneNumber',
    'service.form-helper',
    'service.user-notifications',
  ])
  .controller('AdvisorNewClientVerifyIdentityController', [
    '$http',
    '$scope',
    '$state',
    'formHelper',
    'Person',
    'PhoneNumber',
    'details',
    'userNotifications',
    advisorNewClientVerifyIdentityController,
  ]);

function advisorNewClientVerifyIdentityController(
  $http,
  $scope,
  $state,
  formHelper,
  Person,
  PhoneNumber,
  details,
  userNotifications
) {
  $scope.$root.branding = details.branding;

  $scope.person = new Person();
  $scope.advisorName = details.advisorName;

  $scope.onDateOfBirthChange = function (person, date) {
    person.birthDate(date.toDate());
  };

  $scope.onPhoneNumberChange = angular.noop;
  $scope.phoneNumber = new PhoneNumber();
  $scope.phoneNumber.full('');
  $scope.phoneNumber.parseFull();

  function params() {
    return {
      firstName: $scope.person.firstName(),
      lastName: $scope.person.lastName(),
      sin: $scope.person.sin(),
      birthDate: $scope.person.birthDate(),
      phoneNumber: $scope.phoneNumber.full(),
    };
  }

  $scope.verifyIdentity = function (formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $http
        .put(
          '/api/advisor_create_new_client_flows/' +
            $state.params.advisorNewClientFlowPassword +
            '/verify_identity',
          params()
        )
        .then(
          function () {
            // Refresh page so $scope.currentUser is set
            $scope.$root.toState = 'advisor-new-client.add-password';
            $state.reload('site');
          },
          function (result) {
            switch (result?.data?.message) {
              case 'Authentication Error':
                userNotifications.showError(
                  'The information you have provided does not match our records. Please try again. If the issue persists please contact ' +
                    $scope.advisorName +
                    ' for assistance.'
                );
                break;
              case 'General Error':
              default:
                userNotifications.showError(
                  'An internal error has occurred. Please log out and try again. Clicking the link in the email message from ' +
                    $scope.advisorName +
                    ' will also help.  If the issue persists please contact ' +
                    $scope.advisorName +
                    ' for assistance.'
                );
                break;
            }
          }
        );
    }
  };
}
