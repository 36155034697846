'use strict';

function sortableNumber(number) {
  var str = '0000000000' + number;
  return str.substring(str.length - 10);
}

function adviseOrderController($scope, order, funds) {
  $scope.order = order;

  var allotments = _.reduce(
    order.trades(),
    function (allotments, trade) {
      return allotments.concat(trade.allotments());
    },
    []
  );

  $scope.funds = _.indexBy(funds, function (fund) {
    return fund.symbol();
  });

  $scope.allotments = _.sortBy(allotments, function (allotment) {
    return sortableNumber(allotment.accountId()) + allotment.symbol();
  });

  $scope.minLimitPrice = 0.01;
  $scope.maxLimitPrice = 9999.99;
  $scope.now = new Date();
}

angular
  .module('controller.advise.order', ['directive.input'])
  .controller('AdviseOrderCtrl', ['$scope', 'order', 'funds', adviseOrderController]);
