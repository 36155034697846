'use strict';

angular
  .module('service.woopra-wrapper', [])
  .factory('woopraWrapper', ['$window', woopraWrapperFactory]);

function woopraWrapperFactory($window) {
  function sendPersonalLeadSignupEvent(lead) {
    $window.woopra.identify({
      name: lead.firstName() + ' ' + lead.lastName(),
      email: lead.email(),
      phoneNumber: lead.phoneNumber(),
    });
    $window.woopra.track('personal_lead_signup');
  }

  function sendSelectedServiceOptionEvent(serviceOption) {
    $window.woopra.track('selected_' + serviceOption + '_option');
  }

  return {
    sendPersonalLeadSignupEvent,
    sendSelectedServiceOptionEvent,
  };
}
