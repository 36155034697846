'use strict';

angular
  .module('directive.goal-summary', [
    'directive.goal-progress',
    'directive.simple-allocation-bar',
    'service.risk-level-business-rules',
  ])
  .directive('goalSummary', ['$state', 'config', 'riskLevelBusinessRules', goalSummaryDirective]);

function goalSummaryDirective($state, config, riskLevelBusinessRules) {
  return {
    restrict: 'E',
    scope: {
      goal: '=',
      transferInstructions: '=',
      closeAccount: '=',
      view: '@',
      showClientName: '<',
    },
    templateUrl: 'directives/goal-summary.html',
    link: function ($scope) {
      var goal = $scope.goal;
      var account = goal.accountGoals()[0].account();
      $scope.account = account;
      $scope.riskLevelBusinessRules = riskLevelBusinessRules;
      $scope.allocationsForBarChart = goal.accountGoals()[0].modelPortfolio().nonZeroAllocations();
      $scope.householdAccount = $scope.view !== 'advisor' && !$scope.account.userIsHolder();
      $scope.clientId = $scope.view === 'advisor' && $scope.account.userId();
      $scope.state = {
        showDetails: false,
      };

      $scope.canCloseAccount = function (account) {
        return (
          $scope.view === 'advisor' &&
          !account.status.is.closed() &&
          (account.type.is.trial() || account.balance() === 0)
        );
      };

      $scope.title = account.clientLabel();

      $scope.loadClientNickNameModal = function (account) {
        $scope.$root.accountNicknameModal(account); // Save happens in modal.  No need to handle anything here.
      };

      $scope.autoDeposits = function () {
        return _.chain($scope.transferInstructions)
          .filter(function (transferInstruction) {
            return (
              transferInstruction.transferToType() === 'Account' &&
              transferInstruction.transferToId() === account.id &&
              !transferInstruction.frequency.is.onlyOnce() &&
              !transferInstruction.status.is.finished() &&
              !transferInstruction.status.is.cancelled()
            );
          })
          .value();
      };

      var maxNumberOfAutoDepositsToShow = 2;

      $scope.autoDepositsToShow = function () {
        return _.first($scope.autoDeposits(), maxNumberOfAutoDepositsToShow);
      };

      $scope.autoDepositCancellationDetails = $scope
        .autoDepositsToShow()
        .map((transferInstruction) => {
          let status = '';
          if (transferInstruction.continueUntil()) {
            status = `until ${moment(transferInstruction.continueUntil()).format('YYYY-MM-DD')}`;
          } else if (transferInstruction.manualCancel()) {
            status = 'until cancelled';
          } else if (transferInstruction.maxOccurrence()) {
            const elapsed = transferInstruction.transferRequests().length;
            status = `${elapsed} of ${transferInstruction.maxOccurrence()} complete`;
          }
          return status;
        });

      $scope.numberOfOtherAutoDeposits = function () {
        var numberOfAutoDeposits = $scope.autoDeposits().length;

        if (numberOfAutoDeposits > maxNumberOfAutoDepositsToShow) {
          return numberOfAutoDeposits - maxNumberOfAutoDepositsToShow;
        } else {
          return 0;
        }
      };

      var ongoingAmount = function () {
        return _.reduce(
          $scope.autoDeposits(),
          function (sum, transferInstruction) {
            return sum + transferInstruction.amountAsMonthlyRate();
          },
          0
        );
      };
      var projections = goal.accountGoals()[0].modelPortfolio().projections();
      var simulation = projections[1].simulate(goal.initial(), ongoingAmount());
      var simulatedInitialAmount = function () {
        return simulation.findInitial(goal.targetAmount(), goal.timeframe(), ongoingAmount());
      };
      $scope.onTrackMonthlyContribution = function () {
        return (
          simulation.findOngoing(goal.targetAmount(), goal.timeframe(), account.balance()) || 0
        );
      };
      $scope.onTrackDeposit = function () {
        return simulatedInitialAmount() - account.balance() || 0;
      };
      $scope.goalOntrack = function () {
        return (
          ongoingAmount() >= $scope.onTrackMonthlyContribution() ||
          account.balance() >= simulatedInitialAmount()
        );
      };
      $scope.goToCreateNewDeposit = function () {
        $state.go('dashboard.transfer', {
          defaultTransferType: config.types.TransferInstruction.findByName('deposit').id,
        });
      };

      $scope.portfolioOption = function () {
        return account.portfolioOption().label;
      };

      $scope.contributions = function () {
        return account.contributions();
      };

      $scope.earnings = function () {
        return account.performance();
      };

      $scope.balance = function () {
        return account.balance();
      };

      $scope.firstBalanceDate = function () {
        return account.balances()[0] && account.balances()[0].date();
      };
    },
  };
}
