'use strict';

angular
  .module('controller.profile.two-factor-authentication', ['service.two-factor-authenticator'])
  .controller('profileTwoFactorAuthenticationCtrl', [
    '$scope',
    '$state',
    '$cookies',
    'isMobile',
    'isDesktopSafari',
    'twoFactorAuthenticator',
    profileTwoFactorAuthenticationController,
  ]);

function profileTwoFactorAuthenticationController(
  $scope,
  $state,
  $cookies,
  isMobile,
  isDesktopSafari,
  twoFactorAuthenticator
) {
  $scope.isMobile = isMobile;
  $scope.isDesktopSafari = isDesktopSafari;

  function handleDeactivation(code) {
    twoFactorAuthenticator.deactivate(code);
  }

  $scope.activate = function () {
    if (!$cookies.get('app_version')) {
      $scope.upgradeAppModal();
    } else {
      openActivationModalOrRedirect();
    }
  };

  $scope.authDeactivate = function () {
    if (isMobile) {
      $state.go('profile-deactivate-authenticator-app');
    } else {
      $scope.deactivateAuthenticatorAppModal();
    }
  };

  function openActivationModalOrRedirect() {
    if (isMobile) {
      $state.go('profile-activate-two-factor-authentication');
    } else {
      $scope.activateTwoFactorAuthenticationModal();
    }
  }

  $scope.deactivate = function () {
    if (isMobile) {
      $state.go('profile-deactivate-two-factor-authentication');
    } else {
      $scope.twoFactorAuthenticationModal(handleDeactivation);
    }
  };
}
