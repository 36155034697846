'use strict';

/**
 * Defines a wrapper for the slider input that adds bootstrap styling.
 */
function sliderDirective() {
  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      ngChange: '&',
      ngMin: '=',
      ngMax: '=',
      ngDisabled: '=',
      step: '=',
      options: '=',
      optionLabel: '@',
      optionValue: '@',
      optionDescription: '@',
      form: '=',
      block: '=',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      name: '@',
      class: '@',
      inputClass: '@',
      labelClass: '@',
      helpClass: '@',
      prefix: '@',
      suffix: '@',
    },
    templateUrl: 'directives/input/slider.html',
    controller: [
      '$scope',
      function ($scope) {
        $scope.$watchGroup(['ngMin', 'ngMax', 'ngModel()'], function (vals) {
          var ngMin = vals[0];
          var ngMax = vals[1];
          var ngModel = vals[2];
          var factor = (ngModel - ngMin) / (ngMax - ngMin) || 0;

          $scope.factor = factor < 0 ? 0 : factor;
        });
      },
    ],
    link: function (scope) {
      scope.$watch('ngModel()', function () {
        scope.ngChange();
      });

      scope.popoverTitle = function () {
        return scope.options[scope.ngModel() - 1][scope.optionLabel || 'label']();
      };
    },
  };
}

angular
  .module('directive.input.slider', ['templates', 'animations', 'directive.focus', 'ui.slider'])
  .directive('slider', [sliderDirective]);
