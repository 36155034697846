'use strict';

angular.module('directive.portfolio', []).directive('portfolio', [portfolioDirective]);

function portfolioDirective() {
  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'directives/portfolio.html',
    controller: ['$scope', portfolioController],
  };
}

function portfolioController($scope) {
  $scope.currentRiskIndex = 1;
  $scope.options = ['Risk Level 3', 'Risk Level 7', 'Risk Level 10'];
  $scope.performanceNumber = [
    {
      // level3
      sixMonths: '0.2%',
      oneYear: '2.0%',
      annualized: '4.2%',
      sinceInception: '10.5%',
    },
    {
      // level7
      sixMonths: '0.0%',
      oneYear: '4.8%',
      annualized: '7.1%',
      sinceInception: '18.6%',
    },
    {
      // level10
      sixMonths: '-0.2%',
      oneYear: '6.4%',
      annualized: '10.7%',
      sinceInception: '27.8%',
    },
  ];

  $scope.setCurrentRiskIndex = function (choice) {
    $scope.currentRiskIndex = choice;
  };
  $scope.shiftLeft = function () {
    switch ($scope.currentRiskIndex) {
      case 0:
        $scope.currentRiskIndex = $scope.options.length - 1;
        break;
      default:
        $scope.currentRiskIndex--;
        break;
    }
  };
  $scope.shiftRight = function () {
    switch ($scope.currentRiskIndex) {
      case $scope.options.length - 1:
        $scope.currentRiskIndex = 0;
        break;
      default:
        $scope.currentRiskIndex++;
        break;
    }
  };
}
