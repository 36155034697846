'use strict';

angular
  .module('model.PaginatedIssue', ['ram', 'config', 'model.PaginationMeta', 'model.Issue'])
  .factory('PaginatedIssue', ['ram', 'config', paginatedIssueFactory]);

function paginatedIssueFactory(ram, config) {
  /**
   * Constructor for PaginatedIssue model.
   * @class
   */
  var PaginatedIssue = new ram.Collection('PaginatedIssue', {
    schema: config.schemas.PaginatedIssue,
    hasOne: {
      paginationMeta: 'PaginationMeta',
    },
    hasMany: {
      issues: 'Issue',
    },
    resources: {
      default: new ram.resources.Http('/api/paginated_issues/:id.json'),
      cookie: new ram.resources.Cookie('paginated_issues'),
    },
  });

  return PaginatedIssue;
}
