'use strict';

angular
  .module('controller.iframe-widgets.mutual-fund-fees-page', ['directive.mutual-fund-fees-page'])
  .controller('iframeWidgetsMutualFundFeesPageCtrl', [
    '$scope',
    'mutualFunds',
    iframeWidgetsMutualFundFeesPageController,
  ]);

function iframeWidgetsMutualFundFeesPageController($scope, mutualFunds) {
  $scope.mutualFunds = mutualFunds;
}
