'use strict';

angular
  .module('directive.user.employment', ['service.account-service'])
  .directive('employment', ['config', employmentDirective]);

function employmentDirective(config) {
  return {
    restrict: 'E',
    scope: {
      accounts: '=',
      person: '=',
      onChange: '=',
      editToggle: '=',
      editingEnabled: '=',
      optional: '=',
    },
    templateUrl: 'directives/user/employment.html',
    controller: [
      '$scope',
      'accountService',
      function ($scope, accountService) {
        $scope.employmentStatusOptions = config.types.EmploymentStatus;
        $scope.hasGroupSavingsPlan = accountService.hasGroupSavingsPlan($scope.accounts);
        $scope.hasFccAccount = accountService.hasFccAccount($scope.accounts);

        if ($scope.hasGroupSavingsPlan) {
          $scope.employmentStatusOptions = _.filter(
            $scope.employmentStatusOptions,
            function (option) {
              return option.isEmployed;
            }
          );
        }
      },
    ],
  };
}
