'use strict';

function factory(ram, config) {
  var AccountGoal = new ram.Collection('AccountGoal', {
    belongsTo: {
      account: 'Account',
      goal: 'Goal',
      modelPortfolio: 'ModelPortfolio',
    },
    resources: {
      default: new ram.resources.Http('/api/account_goals/:id.json'),
      cookie: new ram.resources.Cookie('account_goals'),
    },
    schema: config.schemas.AccountGoal,
  });

  return AccountGoal;
}

angular
  .module('model.AccountGoal', ['model.Goal', 'model.ModelPortfolio'])
  .factory('AccountGoal', ['ram', 'config', factory]);
