'use strict';

const generateReportWorker = ({
  reportName,
  params,
  csrfToken,
  userNotifications,
  afterGenerateReport,
  isRunning,
  setIsSubmitting,
}) => {
  setIsSubmitting(true);
  userNotifications.clearNotification();

  if (isRunning) {
    userNotifications.showTransientWarning(
      'Another report is currently running. Please wait for it to complete and try again.'
    );
    setIsSubmitting(false);
    return;
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params()),
  };
  fetch('api/advisor/advisor_reports/generate', requestOptions)
    .then((res) => res.json())
    .then(
      () => {
        userNotifications.showTransientSuccess(
          `${reportName} is being generated. The finished report will display here.`
        );
      },
      (error) => {
        userNotifications.showError(error);
      }
    )
    .finally(() => {
      afterGenerateReport();
      setIsSubmitting(false);
    });
};

export default generateReportWorker;
