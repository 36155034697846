'use strict';

angular
  .module('directive.input.sin', ['service.patterns', 'ui.mask'])
  .directive('sin', ['patterns', sinDirective]);

function sinDirective(patterns) {
  return {
    replace: true,
    restrict: 'E',
    scope: true,
    require: 'ngModel',
    templateUrl: 'directives/input/sin.html',

    // Neither of these are needed right now.
    // controller: ['$scope', function($scope) {}],
    link: function (scope, element, attr, ngModelCtrl) {
      scope.sinPattern = patterns.sin;
      scope.optional = attr.optional === 'true';

      $(element).mask('000 000 000');

      function parser(view) {
        return view.replace(/[^\d]/g, '');
      }

      function formatter(model) {
        if (!model) {
          return '';
        }
        var length = model.length;
        var formatted = model.substr(0, 3);
        if (length > 3) {
          formatted += ' ' + model.substr(3, 3);
        }
        if (length > 6) {
          formatted += ' ' + model.substr(6, 3);
        }
        return formatted;
      }

      // convert data from view format to model format
      ngModelCtrl.$parsers.unshift(parser);

      // convert data from model format to view format
      ngModelCtrl.$formatters.unshift(formatter);
    },
  };
}
