'use strict';

function errorHandlerFactory(
  $state,
  modals,
  handlerRegistry,
  NotAuthenticatedErrorHandler,
  NotAuthorizedErrorHandler
) {
  handlerRegistry.addCodeHandler('ERR-AC-004', function (error) {
    return modals.errorModal(error.data.title, error.data.message).result.finally(function () {
      return $state.go(
        'dashboard.summary',
        {},
        {
          reload: true,
        }
      );
    });
  });
  handlerRegistry.addHandler(new NotAuthenticatedErrorHandler());
  handlerRegistry.addHandler(new NotAuthorizedErrorHandler());

  return handlerRegistry;
}

angular
  .module('service.error-handler', [
    'service.modals',
    'service.error.handler-registry',
    'service.error.NotAuthenticatedErrorHandler',
    'service.error.NotAuthorizedErrorHandler',
  ])
  .factory('errorHandler', [
    '$state',
    'modals',
    'handlerRegistry',
    'NotAuthenticatedErrorHandler',
    'NotAuthorizedErrorHandler',
    errorHandlerFactory,
  ]);
