'use strict';

angular
  .module('controller.onboarding.documents', ['ram', 'service.securityService'])
  .controller('OnboardingDocumentsController', [
    '$scope',
    'account',
    'currentUser',
    'ram',
    'securityServiceFactory',
    onboardingDocumentsController,
  ]);

function onboardingDocumentsController($scope, account, currentUser, ram, securityServiceFactory) {
  $scope.currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);
  $scope.account = account;
  $scope.userDidCheck = new ram.Accessor(false);
  $scope.custodian = account.custodian();

  function getAll() {
    return (
      currentUser.acknowledgeAadd() &&
      currentUser.acknowledgeSoag() &&
      currentUser.acknowledgeCipfb() &&
      currentUser.acknowledgeIirocb() &&
      currentUser.acknowledgeRdi() &&
      account.acknowledgeFs() &&
      account.acknowledgeIps()
    );
  }

  function setAll(all) {
    currentUser.save({
      acknowledgeAadd: all,
      acknowledgeCipfb: all,
      acknowledgeIirocb: all,
      acknowledgeRdi: all,
    });
    account.save({
      acknowledgeIps: all,
    });

    if ($scope.custodian.isCsi()) {
      currentUser.save({
        acknowledgeSoag: all,
      });
      account.save({
        acknowledgeFs: all,
      });
    } else if ($scope.custodian.isBbs()) {
      // 2019.08.12 - Navid decided to NOT set these bits for BBS.
    }
  }

  $scope.$parent.done = function () {
    if ($scope.isMobile || $scope.userDidCheck()) {
      if (!getAll()) {
        setAll(true);
      }
      return true;
    }
    return false;
  };

  $scope.$parent.notDone = function () {
    $scope.validateForm($scope.acknowledgeForm);
  };
}
