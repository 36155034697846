'use strict';

function directive($timeout, currencyFilter) {
  return {
    replace: true,
    require: 'ngModel',
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/input/currency-input.html',
    link: function (scope, element, attrs, ngModelCtrl) {
      scope.inputName = attrs.inputName;

      function formatter(model) {
        if (model == null || isNaN(model)) {
          return '';
        }
        var prefix = ''; // scope.prefix || '';
        var suffix = scope.suffix || '';
        var view = prefix + currencyFilter(model, '$', 0) + suffix;
        return view;
      }

      function parser(view) {
        $timeout(function () {
          ngModelCtrl.$modelValue = undefined;
        });
        var stripped = view
          .split(scope.prefix)
          .join('')
          .split(scope.suffix)
          .join('')
          .split('$')
          .join('')
          .split(',')
          .join('');
        return parseFloat(stripped);
      }

      ngModelCtrl.$render = function () {
        var oldView = element.val();
        var newView = ngModelCtrl.$viewValue;
        var shift = newView.length - oldView.length;
        var oldCaret = element.caret();
        var newCaret = _.constrain(1, oldCaret + shift, newView.lastIndexOf(scope.suffix || ''));

        element.val(newView);

        if (element.is(':focus')) {
          element.caret(newCaret);
        }
      };

      // convert data from view format to model format
      ngModelCtrl.$parsers.unshift(parser);

      // convert data from model format to view format
      ngModelCtrl.$formatters.unshift(formatter);
    },
  };
}

angular
  .module('directive.input.currency-input', [])
  .directive('currencyInput', ['$timeout', 'currencyFilter', directive]);
