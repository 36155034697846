'use strict';

function controller($scope, $state, currentUser, ram, Auth, passwordChecker) {
  var currentPassword = ($scope.currentPassword = new ram.Accessor());
  var password = ($scope.password = new ram.Accessor());
  var passwordConfirmation = ($scope.passwordConfirmation = new ram.Accessor());

  $scope.$watch('password()', function (password) {
    $scope.confirmPattern = passwordChecker.checkPasswordPattern(password);
  });

  /**
   * When password is change the server automatically logs the user out. This
   * logs the client out and causes currentUser to be re-resolved before
   * navigating to signin route.
   */
  function changeSuccess() {
    currentUser.undefine();
    Auth.logout().then(function () {
      $state.transitionTo(
        'account.signin',
        {
          previousSuccessAction: 'Password change',
        },
        {
          reload: true,
        }
      );
    });
  }

  /**
   * If an error occurs during password change, translate it to a human-friendly
   * message.
   * @param  {Object} error Error returned by save method.
   */
  function changeError(error) {
    $scope.errors = _.chain(error.data.errors)
      .camelizeKeys()
      .mapObject(function (val, key) {
        if (key === 'currentPassword') {
          return 'Your current password ' + val;
        } else if (key === 'passwordConfirmation') {
          return 'Password confirmation ' + val;
        }
        return val;
      })
      .value();
  }

  /**
   * When the change password form is valid, this scope method is called to
   * persist the new password.
   */
  $scope.changePassword = function () {
    $scope.errors = undefined;
    $scope.success = '';
    currentUser
      .save({
        currentPassword: currentPassword(),
        password: password(),
        passwordConfirmation: passwordConfirmation(),
      })
      .then(changeSuccess, changeError);
  };
}

angular
  .module('controller.password.change', ['ram', 'service.passwordChecker', 'directive.secured'])
  .controller('PasswordChangeController', [
    '$scope',
    '$state',
    'currentUser',
    'ram',
    'Auth',
    'passwordChecker',
    controller,
  ]);
