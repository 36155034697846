'use strict';

angular
  .module('controller.dashboard-footer', [])
  .controller('DashboardFooterCtrl', ['$scope', 'advisorProfile', dashboardFooterCtrl]);

function dashboardFooterCtrl($scope, advisorProfile) {
  if (advisorProfile) {
    $scope.hubspotUrl = advisorProfile.hubspotUrl();
  }
}
