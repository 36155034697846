'use strict';

angular
  .module('routes.advise.add-client', [
    'model.PortfolioManager',
    'controller.advise.add-client',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function resolveRedirectToState($stateParams, redirect, advisorDetails) {
    if (!$stateParams.redirect) {
      return;
    }

    if (advisorDetails.multiServiceLevels) {
      redirect.to('advise.add-client.service-level').always();
    } else {
      redirect.to('advise.add-client.add-advisors').always();
    }
  }

  function resolveAdvisorDetails($http) {
    return $http.get('/api/advisor/advisor_details.json').then(function (response) {
      if (response.data) {
        return response.data;
      }
    });
  }

  function parseGroupUrlIdAndLink(groupUrlAndLink) {
    // It is of the form 'group_url_id,xxxxxxxx' where the ',xxxxxxxx' part is optional
    const parts = groupUrlAndLink.split(',');
    return {
      groupUrlId: parts[0],
      link: parts.length > 1 ? parts[1] : null,
    };
  }

  function resolveGroupUrlId($stateParams) {
    return parseGroupUrlIdAndLink($stateParams.groupUrlIdAndLink).groupUrlId;
  }

  function resolveLink($stateParams) {
    return parseGroupUrlIdAndLink($stateParams.groupUrlIdAndLink).link;
  }

  function resolvePortfolioManagers(PortfolioManager, advisorDetails) {
    return advisorDetails.allowedPortfolioManagers.map((pm) => new PortfolioManager(pm));
  }

  $stateProvider
    .state('advise.add-client', {
      url: '/add-client',
      params: {
        redirect: false,
      },
      controller: 'AdviseAddClientCtrl',
      templateUrl: 'views/advise/add-client.html',
      resolve: {
        advisorDetails: ['$http', resolveAdvisorDetails],
        redirectToDefault: ['$stateParams', 'redirect', 'advisorDetails', resolveRedirectToState],
        portfolioManagers: ['PortfolioManager', 'advisorDetails', resolvePortfolioManagers],
      },
    })
    .state('advise.add-client.service-level', {
      url: '/service-level',
      controller: 'AddClientServiceLevelCtrl',
      resolve: {},
      templateUrl: 'views/advise/add-client/service-level.html',
    })
    .state('advise.add-client.add-advisors', {
      url: '/relationship-managers',
      controller: 'AddClientAddAdvisorsCtrl',
      resolve: {},
      templateUrl: 'views/advise/add-client/add-advisors.html',
    })
    .state('advise.add-client.preferred-portfolio-manager', {
      url: '/preferred-portfolio-manager/?groupUrlIdAndLink',
      params: {
        groupUrlIdAndLink: null,
      },
      resolve: {
        groupUrlId: ['$stateParams', resolveGroupUrlId],
        link: ['$stateParams', resolveLink],
      },
      controller: 'AdviseClientApplicationPreferredPortfolioManagerCtrl',
      templateUrl: 'views/advise/add-client/preferred-portfolio-manager.html',
    });
}
