'use strict';

angular
  .module('directive.advise.linkToRelated', [])
  .directive('linkToRelated', [linkToRelatedDirective]);

function linkToRelatedDirective() {
  return {
    scope: {
      type: '@',
      id: '@',
      label: '@',
    },
    restrict: 'E',
    replace: true,
    template:
      '<span><a ng-if="routeName" ui-sref="{{routeName}}">{{label}}</a><span ng-if="!routeName">{{label}}</span></span>',

    controller: [
      '$scope',
      function ($scope) {
        var getRouteName = function () {
          var type = $scope.type;
          var id = $scope.id;
          switch (type) {
            case 'User':
              return 'advise.client.view({id: ' + id + '})';
            case 'Issue':
              return 'advise.issue({id: ' + id + '})';
            case 'UpdateRecord':
              return 'advise.update-record({id: ' + id + '})';
            case 'Account':
              return 'advise.account-link({id: ' + id + '})';
            case 'BankAccount':
              return 'advise.bank-account-link({id: ' + id + '})';
            case 'TransferRequest':
              return 'advise.transfer-request-link({id: ' + id + '})';
            default:
              return null;
          }
        };

        if (!$scope.label) {
          $scope.label = $scope.type + ' ' + $scope.id;
        }
        $scope.routeName = getRouteName();
      },
    ],
  };
}
