'use strict';

angular
  .module('controller.dashboard.refer-a-friend', [
    'ram',
    'model.Referral',
    'ngclipboard',
    'service.group-by-date',
    'service.user-notifications',
    'service.link-copy-handler',
    'directive.secured',
    'directive.mobile-list-cards',
  ])
  .controller('DashboardReferAFriendController', [
    '$scope',
    '$timeout',
    '$http',
    'referrals',
    'referralDetails',
    'ram',
    'Referral',
    'groupByDateService',
    'userNotifications',
    'linkCopyHandler',
    referAFriendController,
  ]);

function referAFriendController(
  $scope,
  $timeout,
  $http,
  referrals,
  referralDetails,
  ram,
  Referral,
  groupByDateService,
  userNotifications,
  linkCopyHandler
) {
  $scope.referrals = referrals;
  $scope.referralsByDate = groupByDateService.call($scope.referrals, 'createdAt');

  $scope.referralDetails = referralDetails;
  $scope.refereeEmail = new ram.Accessor();

  $scope.progressWidthPercent = (referralDetails.totalEarned / 250) * 100;
  $scope.currentAmountLabel = '$' + (referralDetails.totalEarned / 100) * 100;

  $scope.onLinkCopyError = function (e) {
    linkCopyHandler.onLinkCopyError(e);
  };

  $scope.onLinkCopied = function (e) {
    linkCopyHandler.onLinkCopied(e);
  };

  var urlEncode = function (url) {
    return encodeURIComponent(url);
  };

  $scope.twitterShare = function () {
    var tweetContent =
      "I'm using @ModernAdvsr to manage my savings, sign up using this link to get a $50 bonus:";
    var url =
      'http://twitter.com/share?text=' +
      urlEncode(tweetContent) +
      '&url=' +
      urlEncode(referralDetails.referralUrl);

    window.open(
      url,
      'Share',
      'height=400,width=500,left=200,top=200,chrome=yes,centerscreen=yes,dialog=yes,modal=yes,alwaysRaised=yes'
    );
  };

  $scope.fbShare = function () {
    window.FB.ui({
      method: 'feed',
      display: 'popup',
      picture: 'https://s3-us-west-2.amazonaws.com/modernadvisor-public/01_2-Small.png',
      name: $scope.currentUser.person().firstName() + ' invites you to try ModernAdvisor',
      caption: 'modernadvisor.ca',
      description: 'Get a $50 credit when you sign up for an account.',
      link: referralDetails.referralUrl,
    });
  };

  $scope.inviteByEmail = function (refereeEmail) {
    $scope.inviteEmailSentError = '';
    $scope.inviteEmailSentSuccess = '';

    $http
      .post('/api/referrals/invite_by_email', {
        refereeEmail,
        referrerId: $scope.currentUser.id,
      })
      .then(
        function (response) {
          if (response.status === 200) {
            $scope.inviteEmailSentError =
              refereeEmail + ' has already been invited, try a different email';
            userNotifications.showError($scope.inviteEmailSentError);

            $scope.inviteEmailSentSuccess = '';
          } else {
            $scope.inviteEmailSentError = '';
            $scope.inviteEmailSentSuccess = 'Invite email sent successfully';

            userNotifications.showSuccess($scope.inviteEmailSentSuccess);
            $scope.refereeEmail('');

            Referral.where({
              referrerId: $scope.currentUser.id,
            }).then(function (referrals) {
              $scope.referrals = referrals;
              $scope.referralsByDate = groupByDateService.call($scope.referrals, 'createdAt');
            });
          }
        },
        function () {
          $scope.inviteEmailSentSuccess = '';
          $scope.inviteEmailSentError =
            'We were unable to send an invite to ' +
            refereeEmail +
            '. Please try again later or try a different email address.';

          userNotifications.showError($scope.inviteEmailSentError);
        }
      );
  };
}
