'use strict';

function factory() {
  function checkPasswordPattern(password) {
    if (password) {
      password = password.replace(
        /([\`\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\\\|\;\:\'\"\,\.\>\/\?])/g, // eslint-disable-line no-useless-escape
        '\\$1'
      );
      var confirmPattern = new RegExp('^' + password + '$');
      return confirmPattern;
    }
  }

  return {
    checkPasswordPattern,
  };
}

angular.module('service.passwordChecker', []).factory('passwordChecker', [factory]);
