'use strict';

angular
  .module('controller.onboarding.identification', [])
  .controller('OnboardingIdentificationController', [
    '$scope',
    'backImage',
    'frontImage',
    'jointBackImage',
    'jointFrontImage',
    'spouse',
    onboardingIdentificationController,
  ]);

function onboardingIdentificationController(
  $scope,
  backImage,
  frontImage,
  jointBackImage,
  jointFrontImage,
  spouse
) {
  $scope.backImage = backImage;
  $scope.frontImage = frontImage;
  $scope.jointBackImage = jointBackImage;
  $scope.jointFrontImage = jointFrontImage;
  $scope.spouse = spouse;
}
