'use strict';

/**
 * Simple decimal directive. Allows a number input to save to a Big Decimal on the server
 * This converts the Big Decimal string back to a number for the number input.
 */

angular.module('directive.input.decimal', []).directive('decimal', [decimalDirective]);

function decimalDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$formatters.push(function (value) {
        if (!_.isUndefined(value)) {
          return Number(value);
        }
      });
    },
  };
}
