'use strict';

angular
  .module('directive.transfers-table', [
    'service.adaptive-views',
    'service.transfer-account-data-provider',
  ])
  .directive('transfersTable', [
    'adaptiveViews',
    '$http',
    'transferAccountDataProvider',
    transfersTableDirective,
  ]);

function transfersTableDirective(adaptiveViews, $http, transferAccountDataProvider) {
  return {
    restrict: 'E',
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/transfers-table.html', false),
    scope: {
      accounts: '=',
      targetAccount: '=',
      bankAccounts: '=',
      heading: '@',
      transferInstructionsContainer: '=',
    },
    controller: [
      '$scope',
      function ($scope) {
        transferAccountDataProvider.setInputs(undefined, $scope.accounts, $scope.bankAccounts);

        var setUpTransferInstructions = function (transferInstructions) {
          return _.chain(transferInstructions)
            .each(function (transferInstruction) {
              _.extend(transferInstruction, {
                transferFrom: transferAccountDataProvider.getAccountById(
                  transferInstruction.transferFromType(),
                  transferInstruction.transferFromId()
                ),
                transferTo: transferAccountDataProvider.getAccountById(
                  transferInstruction.transferToType(),
                  transferInstruction.transferToId()
                ),
              });
            })
            .value();
        };

        $scope.$watchCollection(
          'transferInstructionsContainer.transferInstructions',
          function (transferInstructions) {
            $scope.transferInstructions = setUpTransferInstructions(transferInstructions);
          }
        );

        $scope.accountTypeLabel = function (sourceAccount) {
          if (sourceAccount && sourceAccount.bankCode) {
            return '';
          }
          return sourceAccount ? sourceAccount.type().label : '';
        };

        $scope.accountNumberLabel = function (sourceAccount, secondAccount) {
          if (sourceAccount && sourceAccount.bankCode) {
            return sourceAccount.label();
          }
          var firstName = secondAccount ? secondAccount.clientFirstName() : undefined;
          return sourceAccount ? sourceAccount.number() : firstName + "'s bank account";
        };

        $scope.getSummaryDetails = function (transferInstruction) {
          return transferAccountDataProvider.getSummaryMessage(
            transferInstruction,
            $scope.targetAccount
          );
        };

        $scope.getCancelledInProgressDetails = function (transferInstruction) {
          return transferAccountDataProvider.getCancelledInProgressMessage(transferInstruction);
        };

        $scope.getFrequency = function (transferInstruction) {
          return transferAccountDataProvider.getDetailsMessage(transferInstruction);
        };

        $scope.canCancel = function (transferInstruction) {
          return (
            !transferInstruction.status.is.cancelled() &&
            !transferInstruction.status.is.finished() &&
            transferInstruction.canCancel()
          );
        };

        $scope.cancel = function (transferInstruction) {
          $scope.$root
            .advisorConfirmCancelTransferInstruction(transferInstruction)
            .result.then(function () {
              return $http.put(
                'api/advisor/transfer_instructions/' + transferInstruction.id + '/cancel.json'
              );
            })
            .finally(function () {
              transferInstruction.reload();
            });
        };
      },
    ],
  };
}
