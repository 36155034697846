'use strict';

angular
  .module('controller.modal.advisor-download-promotions-report', [
    'service.loading-indicator',
    'service.form-helper',
    'directive.staticField',
  ])
  .controller('advisorDownloadPromotionsReportController', [
    '$scope',
    '$http',
    '$uibModalInstance',
    'formHelper',
    'ram',
    'loadingIndicator',
    advisorDownloadPromotionsReportCtrl,
  ]);

function advisorDownloadPromotionsReportCtrl(
  $scope,
  $http,
  $modalInstance,
  formHelper,
  ram,
  loadingIndicator
) {
  $scope.availableReports = [];

  function _packageNameInObject(name) {
    var match = name.match(/^.*_(\d\d\d\d)_(\d\d)_(\d*).(csv|xlsx)$/);
    var period = '';
    var timeGenerated = null;
    if (match) {
      period = match[1] + '-' + match[2];
      timeGenerated = new Date(parseInt(match[3] * 1000));
    }
    return {
      raw: name,
      period,
      timeGenerated,
    };
  }

  function _getData() {
    loadingIndicator.show();
    return $http
      .get('/api/advisor/promotion_reports_from_last_two_months.json')
      .then(function (result) {
        $scope.availableReports = _.map(result.data, function (name) {
          return _packageNameInObject(name);
        });
        loadingIndicator.hide();
        return true;
      })
      .catch(function () {
        $scope.availableReports = [];
        loadingIndicator.hide();
      });
  }
  _getData();
}
