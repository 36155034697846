'use strict';

angular
  .module('mobile.controller.dashboard.summary', [
    'service.investments-bar-prep-data',
    'service.trim-balances',
    'service.account-service',
    'service.investment-charts.holdings-data-generator',
    'service.user-notifications',
    'mobile.directive.accounts-dropdown',
    'filter.currency-mobile',
  ])
  .controller('MobileDashboardSummaryCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    '$cookies',
    'config',
    'currentUser',
    'account',
    'accounts',
    'balances',
    'positions',
    'ram',
    'xirr',
    'identityVerificationStatus',
    'invBarPrepData',
    'investmentChartsHoldingsDataGenerator',
    'trimBalances',
    'accountService',
    'userNotifications',
    accountMobileCtrl,
  ]);

var periods = [
  {
    label: 'All',
  },
  {
    label: '1 Year',
    start: moment().subtract(1, 'years').toDate(),
  },
  {
    label: 'YTD',
    start: moment().startOf('years').toDate(),
  },
  {
    label: '3 Months',
    start: moment().subtract(3, 'months').toDate(),
  },
  {
    label: '1 Month',
    start: moment().subtract(1, 'months').toDate(),
  },
];

function accountMobileCtrl(
  $scope,
  $state,
  $stateParams,
  $filter,
  $cookies,
  config,
  currentUser,
  account,
  accounts,
  balances,
  positions,
  ram,
  xirr,
  identityVerificationStatus,
  invBarPrepData,
  investmentChartsHoldingsDataGenerator,
  trimBalances,
  accountService,
  userNotifications
) {
  if (!account || !positions) {
    return;
  }

  var firstDate, lastDate;

  if (currentUser.id === 3 && accounts.length > 2) {
    if (!$cookies.get('on-track')) {
      $scope.offTrackAccount = accounts[2];
    } else if (!$cookies.get('spousal-rrsp')) {
      $scope.showSpousalRrsp = true;
    }
  }

  if ($stateParams.showFormEsignedAlert) {
    userNotifications.showInfo(
      'You have successfully signed the account transfer form.' +
        '<br>' +
        'Account transfers could take up to 4 weeks. ' +
        'We will send you an email when we receive your funds.'
    );
  }

  if ($stateParams.showSuccessMessage) {
    userNotifications.showInfo($stateParams.showSuccessMessage);
  }

  $scope.dismissSpousalRrsp = function () {
    $cookies.put('spousal-rrsp', true);
  };

  const platform = config.env.platform;

  angular.extend($scope, {
    isWsi: platform && platform.name === 'WSI',
    absContributions: 0,
    account,
    end: new ram.Accessor(),
    formatX: function (d) {
      return d3.time.format("%b '%y")(d);
    },
    formatY: function (d) {
      return d3.format('$,.2r')(d);
    },
    isPercent: new ram.Accessor(false),
    period: new ram.Accessor(periods[0]),
    periods,
    start: new ram.Accessor(),
    hideFees:
      account.isCombinedAccount() ||
      account.type.is.trial() ||
      !account.status.is.open() ||
      account.transfers().length === 0,
    hideCharts: account.transfers().length === 0,
    hasOpenAccount: accountService.hasOpenAccount($scope.accounts),
    hasFundedAccount: accountService.hasFundedAccount($scope.accounts),
    identityVerificationStatus,
  });

  $scope.$parent.messageBoard.initializeHoldingsPage(currentUser, accounts, account);

  $scope.goToFundUnfundedAccount = function () {
    $state.go('dashboard.transfer');
  };

  if (!account.isCombinedAccount()) {
    $scope.sortedPositions = investmentChartsHoldingsDataGenerator.getNonAggregateData(
      positions,
      account.accountGoals()[0].modelPortfolio()
    );
  }

  var invChartDataObject = invBarPrepData.getChartDataForPositions($scope.sortedPositions);

  if (invChartDataObject) {
    $scope.toBeStacked = invChartDataObject.toBeStacked;
    $scope.investmentsChartData = invChartDataObject.investmentsChartData;
  }

  // Everything involving ror/RoR is only temporary until we have an appropriate chart
  $scope.rorActive = false;
  $scope.activate = function (dataSet) {
    if (dataSet === 'earningsRoR') {
      dataSet = $scope.earnings;
      $scope.rorActive = true;
    } else {
      $scope.rorActive = false;
    }
    $scope.chartData = dataSet;
  };

  $scope.isActive = function (dataSet) {
    return $scope.rorActive ? false : $scope.chartData === dataSet;
  };

  $scope.$watchGroup(['start()', 'end()'], startEndDateListener);

  // Must be run before period() watch
  $scope.$watchCollection('account.balancesHistory()', function (balances) {
    if (!balances || !balances.length) {
      return;
    }

    var indexOfLastZeroBalance = trimBalances.indexOfLastZeroBalance(balances);
    balances = trimBalances.toLastZeroBalance(balances);

    if (!balances) {
      return;
    } // Cover case of only 0 balances

    $scope.balances = balances;

    if (!$scope.chartData) {
      $scope.chartData = $scope.balances;
    }

    var account = $scope.account;

    $scope.earnings = account.earningsHistory().slice(indexOfLastZeroBalance);

    var firstBalance = balances[0];
    firstDate = firstBalance && firstBalance.date;

    var lastBalance = balances[balances.length - 1];
    lastDate = lastBalance && lastBalance.date;
  });

  // Must be run after account.balancesHistory watch
  $scope.$watch('period()', function (period) {
    $scope.start((period && period.start) || firstDate);
    $scope.end(lastDate);
  });

  // ------------------------- Helpers ----------------------------------------

  $scope.dataPointsInPeriod = function () {
    var start = $scope.start();
    var end = $scope.end();

    if (!$scope.chartData) {
      return;
    }

    return $scope.chartData.filter(function (datum) {
      return datum.date >= start && datum.date <= end;
    }).length;
  };

  function startEndDateListener(values) {
    var currentDate = $filter('date')(Date.now(), 'mediumDate');
    var periodStartDate = $filter('date')(values[0], 'mediumDate');
    $scope.periodEndDate = $filter('date')(values[1], 'mediumDate');

    if ($scope.periodEndDate === currentDate) {
      $scope.dateRangeText = 'Since ' + periodStartDate;
      $scope.dateRangeTextLowerCase = 'since ' + periodStartDate;
    } else {
      $scope.dateRangeText = 'Between ' + periodStartDate + ' and ' + $scope.periodEndDate;
      $scope.dateRangeTextLowerCase = 'between ' + periodStartDate + ' and ' + $scope.periodEndDate;
    }

    redoCalculations(values);
  }

  function redoCalculations(values) {
    $scope.calcPerformance =
      $scope.account.performance(values[1]) - $scope.account.performance(values[0]);
    $scope.absPerformance = Math.abs($scope.calcPerformance);
    $scope.calcContributions = $scope.account.contributions(values[1], values[0]);
    $scope.absContributions = Math.abs($scope.calcContributions);

    try {
      $scope.calcPercent = xirr($scope.account.cashFlows(values[0], values[1]), {
        annualized: false,
      });
      $scope.percent = ($scope.calcPercent * 100).toFixed(2) + '%';
    } catch (e) {
      $scope.calcPercent = 0;
      $scope.percent = '-';
    }
  }

  $scope.cursorText = [
    {
      value: function (d) {
        return d3.time.format('%b %d %Y')(d.date);
      },
      styles: {
        'font-weight': 'bold',
      },
      attrs: {
        dy: '1em',
      },
    },
    {
      value: function (d) {
        return $filter('currency')(d.amount);
      },
      attrs: {
        dy: '2.5em',
      },
    },
  ];
}
