'use strict';

angular
  .module('model.ModelPortfolio', [
    'ram',
    'model.Allocation',
    'model.Projection',
    'model.UnitQuote',
  ])
  .factory('ModelPortfolio', ['ram', 'config', modelPortfolioFactory]);

function modelPortfolioFactory(ram, config) {
  var ModelPortfolio = new ram.Collection('ModelPortfolio', {
    hasMany: {
      allocations: 'Allocation',
      projections: 'Projection',
      unitQuotes: 'UnitQuote',
    },
    schema: config.schemas.ModelPortfolio,
    resources: {
      default: new ram.resources.Http('/api/model_portfolios/:id.json'),
      cookie: new ram.resources.Cookie('model_portfolios'),
    },
  });

  /**
   * Generate a title for this based on risk factor, income, and tax status.
   *
   * @return {String}
   */
  ModelPortfolio.prototype.title = function () {
    var title = [];

    title.push(
      [
        'Extremely Cautious',
        'Very Cautious',
        'Cautious',
        'Somewhat Cautious',
        'Moderate',
        'Moderate Risk',
        'Somewhat Aggressive',
        'Aggressive',
        'Very Aggressive',
        'Extremely Aggressive',
      ][this.risk - 1]
    );

    title.push(this.taxable ? 'Taxable' : 'Tax-free');

    return title.join(' ');
  };

  /**
   * Calculate the weighted total fund fee for this model portfolio.
   *
   * @return {Number} annual percentage
   */
  ModelPortfolio.prototype.fee = function () {
    return _.reduce(
      this.allocations(),
      function (sum, allocation) {
        var fund = allocation.fund();
        return sum + (fund ? fund.fee() * allocation.weight() : 0);
      },
      0
    );
  };

  ModelPortfolio.prototype.basePrice = function () {
    return _.min(this.unitQuotes(), function (unitQuote) {
      return unitQuote.date;
    }).adjusted;
  };

  ModelPortfolio.prototype.performance = function (initial) {
    var numberOfUnits = initial / this.basePrice();
    return _.map(this.unitQuotes(), function (quote) {
      return {
        date: quote.date,
        value: quote.value(numberOfUnits),
      };
    });
  };

  ModelPortfolio.prototype.nonZeroAllocations = function () {
    return _.reject(this.allocations(), function (allocation) {
      return parseFloat(allocation.weight()) === 0.0;
    });
  };

  ModelPortfolio.prototype.nonZeroTacticalAllocations = function () {
    return _.reject(this.allocations(), function (allocation) {
      return parseFloat(allocation.tacticalWeight()) === 0.0;
    });
  };

  return ModelPortfolio;
}
