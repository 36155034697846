'use strict';

angular
  .module('routes.advise.create-external-advisor', [
    'controller.advise.create-external-advisor',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  $stateProvider.state('advise.create-external-advisor', {
    url: '/create-external-advisor',
    abstract: false,
    controller: 'AdviseCreateExternalAdvisorCtrl',
    templateUrl: 'views/advise/create-external-advisor.html',
  });
}
