'use strict';

angular
  .module('controller.navbar.default', [])
  .controller('NavbarDefaultCtrl', ['$cookies', '$scope', '$state', navbarDefaultController]);

function navbarDefaultController($cookies, $scope) {
  $scope.dismissProAlert = function () {
    $cookies.put('dismissed_pro_alert', true, {
      expires: 'Fri, 31 Dec 9999 23:59:59 GMT',
    });
  };

  $scope.dismissPromoAlert = function () {
    $cookies.put('dismissed_promo_alert', true, {
      expires: 'Fri, 31 Dec 9999 23:59:59 GMT',
    });
  };
}
