'use strict';

angular
  .module('model.AdvisorBeneficiaryForm', [
    'ram',
    'config',
    'model.PersonalRelation',
    'model.AdvisorCreateNewAccountFlow',
  ])
  .factory('AdvisorBeneficiaryForm', ['$http', 'ram', 'config', advisorBeneficiaryFormFactory]);

function advisorBeneficiaryFormFactory($http, ram, config) {
  var AdvisorBeneficiaryForm = new ram.Collection('AdvisorBeneficiaryForm', {
    belongsTo: {
      personalRelation: 'PersonalRelation',
      advisorCreateNewAccountFlow: 'AdvisorCreateNewAccountFlow',
    },
    schema: config.schemas.AdvisorBeneficiaryForm,
    serialize: ['personalRelation'],
    resources: {
      default: new ram.resources.Http('/api/advisor_beneficiary_forms/:id.json'),
      cookie: new ram.resources.Cookie('advisor_beneficiary_forms'),
    },
  });

  AdvisorBeneficiaryForm.prototype.save = function () {
    var relative = this.personalRelation().relative();
    var beneficiaryParams = this.toJSON();
    var relativeParams = relative.toJSON();
    var homeAddressParams = relative.homeAddress().toJSON();
    relativeParams.homeAddress = homeAddressParams;
    beneficiaryParams.personalRelation.relative = relativeParams;
    return $http.post('/api/advisor_beneficiary_forms.json', beneficiaryParams);
  };

  return AdvisorBeneficiaryForm;
}
