'use strict';

angular
  .module('model.AdvisorCreateNewTransferFlow', [
    'model.AdvisorCreateNewTransferSecurity',
    'config',
    'ram',
  ])
  .factory('AdvisorCreateNewTransferFlow', ['$http', 'ram', advisorCreateNewTransferFlowFactory]);

function advisorCreateNewTransferFlowFactory($http, ram) {
  var AdvisorCreateNewTransferFlow = new ram.Collection('AdvisorCreateNewTransferFlow', {
    bind: ['account'],
    belongsTo: {
      advisor: 'Person',
      client: 'Person',
      advisorCreateNewClientFlow: 'AdvisorCreateNewClientFlow',
      accountFlow: 'AdvisorCreateNewAccountFlow',
    },
    resources: {
      default: new ram.resources.Http('/api/advisor_create_new_transfer_flows/:id.json'),
      cookie: new ram.resources.Cookie('advisor_create_new_transfer_flows'),
    },
    hasMany: {
      advisorCreateNewTransferSecurity: 'AdvisorCreateNewTransferSecurity',
    },
    enums: {},
  });

  AdvisorCreateNewTransferFlow.prototype.account = function () {
    return this.accountFlow();
  };

  AdvisorCreateNewTransferFlow.prototype.from = function () {
    return this.institutionName() + ' - ' + this.externalAccountNumber();
  };
  return AdvisorCreateNewTransferFlow;
}
