'use strict';

angular
  .module('controller.modal.review', [
    'component.investment-charts.model-portfolio-pie-chart',
    'filter.a-or-an',
    'directive.charts',
    'service.investment-charts.asset-allocation-data-generator',
    'service.risk-level-business-rules',
  ])
  .controller('modalReviewCtrl', [
    '$scope',
    'account',
    'goal',
    'portfolio',
    'user',
    'questions',
    'toBeStacked',
    'investmentsChartData',
    'investmentChartsAssetAllocationDataGenerator',
    'riskLevelBusinessRules',
    'recommendedPortfolio',
    modalReviewController,
  ]);

function modalReviewController(
  $scope,
  account,
  goal,
  portfolio,
  user,
  questions,
  toBeStacked,
  investmentsChartData,
  investmentChartsAssetAllocationDataGenerator,
  riskLevelBusinessRules,
  recommendedPortfolio
) {
  $scope.account = account;
  $scope.recommendedPortfolio = recommendedPortfolio;
  $scope.goal = goal;
  $scope.portfolio = portfolio;
  $scope.questions = questions;
  $scope.toBeStacked = toBeStacked;
  $scope.investmentsChartData = investmentsChartData;
  $scope.riskLevelBusinessRules = riskLevelBusinessRules;

  $scope.hasPortfolioChanged =
    $scope.recommendedPortfolio().id !== $scope.account.portfolioOption().id;

  if (user) {
    $scope.currentUser = user;
  }

  var chartData = investmentChartsAssetAllocationDataGenerator.getData(
    portfolio.nonZeroAllocations()
  );

  $scope.allocationDataForPieChart = _.map(chartData, function (chartDataRow) {
    return {
      color: chartDataRow.color,
      label: chartDataRow.allocation.fund().label(),
      value: chartDataRow.allocation.weight(),
    };
  });

  $scope.allocationDataForLegend = _.map(chartData, function (chartDataRow) {
    return {
      color: chartDataRow.color,
      label: chartDataRow.allocation.fund().label(),
      weight: chartDataRow.allocation.weight(),
    };
  });

  $scope.setBigModal = function () {
    angular.element('.modal:not(.menu)').addClass('big-dialog-disable-flexbox');
  };
}
