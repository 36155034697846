'use strict';

angular
  .module('directive.user.risk-appetite-indicator', [])
  .directive('riskAppetiteIndicator', ['config', riskAppetiteIndicatorDirective]);

function riskAppetiteIndicatorDirective() {
  return {
    controller: ['$scope', 'config', riskAppetiteIndicatorCtrl],
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/user/risk-appetite-indicator.html',
  };

  function riskAppetiteIndicatorCtrl($scope, config) {
    $scope.emptyItemSrc = 'assets/images/risk-indicator/Pepper_empty.svg';
    $scope.$watch('user.investorType().value', updateIndicators);

    function updateIndicators() {
      $scope.fullIndicators = _.range(1, $scope.user.investorType().value + 1);
      $scope.emptyIndicators = _.range(
        1,
        config.types.Investor.length - $scope.fullIndicators.length + 1
      );
    }
  }
}
