'use strict';

function issueCommentFactory(ram, config) {
  var IssueComment = new ram.Collection('IssueComment', {
    schema: config.schemas.IssueComment,
    resources: {
      default: new ram.resources.Http('/api/issue_comments/:id.json'),
      cookie: new ram.resources.Cookie('issue_comments'),
    },
  });

  return IssueComment;
}

angular
  .module('model.IssueComment', ['ram', 'config'])
  .factory('IssueComment', ['ram', 'config', issueCommentFactory]);
