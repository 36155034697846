'use strict';

angular
  .module('directive.user.address-field', ['service.patterns'])
  .directive('addressField', ['$timeout', 'config', 'patterns', addressRowDirective]);

function addressRowDirective($timeout, config) {
  return {
    restrict: 'E',
    scope: {
      address: '=',
      addressLabel: '@',
      sameAs: '=',
      sameAsLabel: '@',
      onChange: '&',
      optional: '=',
    },
    templateUrl: 'directives/user/address-field.html',
    controller: [
      '$scope',
      'patterns',
      function ($scope, patterns) {
        $scope.config = config;
        $scope.patterns = patterns;
        var waitingForBrowserPrefill = false;

        $scope.notCanada = function () {
          return $scope.address.country() !== 'CA';
        };

        $scope.onCountryChange = function (address) {
          $scope.address.region(null);
          $scope.onAddressChange(address);
        };

        $scope.onAddressChange = function (address) {
          if (waitingForBrowserPrefill === false) {
            waitingForBrowserPrefill = true;

            $timeout(function () {
              waitingForBrowserPrefill = false;

              // Delay sending the packet.  We may get a bunch of entries at the same time because of browser prefill.
              // This delay has no impact when manually typing in data.  It helps to group together prefill data, though,
              // to avoid having multiple packets.
              // The problem was that the packets were getting out of order and sometimes the order would put one without the
              // street address last.  This would clear out the input field.
              $scope.onChange({
                address,
              });
            }, 100);
          }
        };

        $scope.$watch('[address.scopeId, address.sameAsId()]', function (val) {
          var id = val[0];
          var sameAsId = val[1];

          if (sameAsId || _.isUndefined(id) || !window.config.env.addressCompleteKey) {
            return;
          }
          var pca = window.pca;

          var fields = [
            {
              element: 'streetAddress' + id,
              field: 'Line1',
              mode: pca.fieldMode.SEARCH,
            },
            {
              element: 'postal' + id,
              field: 'PostalCode',
              mode: pca.fieldMode.SEARCH,
            },
            {
              element: 'country' + id,
              field: 'CountryIso2',
              mode: pca.fieldMode.COUNTRY,
            },
          ];
          var options = {
            key: window.config.env.addressCompleteKey,
            autoSearch: true,
          };

          var control = new pca.Address(fields, options);
          $timeout(function () {
            control.load();
          });
          /***
           * The following address fields need to be initialized with empty strings
           * instead of undefined/null value otherwise the populateFromAddressComplete
           * function won't populate the fields at the first time user clicking on
           * Address Complete
           ***/
          control.listen('populate', function (address) {
            $scope.$apply(function () {
              $scope.address.populateFromAddressComplete(address);
              _.each(
                ['streetAddress', 'unit', 'city', 'region', 'postal', 'country'],
                function (input) {
                  var elementId = input + id;
                  angular.element('#' + elementId).trigger('change');
                }
              );
              $scope.onAddressChange($scope.address);
            });
          });
        });
      },
    ],
  };
}
