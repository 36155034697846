'use strict';

angular
  .module('controller.onboarding.confirmEmail', ['service.reload-current-user'])
  .controller('OnboardingConfirmEmailAddressController', [
    '$scope',
    '$timeout',
    'reloadCurrentUser',
    onboardingConfirmEmailAddressController,
  ]);

function onboardingConfirmEmailAddressController($scope, $timeout, reloadCurrentUser) {
  $scope.$parent.done = function () {
    return $scope.currentUser.confirmed();
  };

  $scope.$parent.notDone = function () {
    $scope.emailNotConfirmed = !$scope.currentUser.confirmed();
    $scope.emailResent = false;
  };

  $scope.sendReconfirmation = function () {
    $scope.error = '';
    $scope.currentUser.sendConfirmation().then(function () {
      $scope.emailResent = true;
      $scope.emailNotConfirmed = false;
    });
  };

  reloadCurrentUser($scope);
}
