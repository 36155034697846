'use strict';

angular
  .module('controller.modal.super-advisor.switch-account-types-modal', [])
  .controller('ModalSuperAdvisorSwitchAccountTypesCtrl', [
    '$scope',
    '$state',
    'account',
    'client',
    '$http',
    'config',
    switchAccountTypesModalController,
  ]);

function switchAccountTypesModalController($scope, $state, account, client, $http, config) {
  $scope.account = account;
  $scope.accountType = account.type().label;
  $scope.client = client.fullName();

  $scope.custodianCode = function (account) {
    return account.custodian().code();
  };

  $scope.isLira = function () {
    return config.types.Account[account.typeId()].name === 'lira';
  };

  $scope.isLockedInRrsp = function () {
    return config.types.Account[account.typeId()].name === 'locked_in_rrsp';
  };

  $scope.convertToLockedInRrspOrToLira = function (account) {
    return $http
      .put(`/api/accounts/${account.id}/convert_to_locked_in_rrsp_or_to_lira.json`, {
        accountId: account.id,
      })
      .then(function () {
        return $state.reload();
      })
      .then(function () {
        return $scope.$close();
      });
  };
}
