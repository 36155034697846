'use strict';

angular
  .module('model.OrderTradeTicket', ['ram', 'config', 'model.Order', 'model.Custodian'])
  .factory('OrderTradeTicket', ['ram', 'config', '$http', orderTradeTicketFactory]);

function orderTradeTicketFactory(ram, config, $http) {
  var OrderTradeTicket = new ram.Collection('OrderTradeTicket', {
    accessors: [],
    hasOne: {
      custodian: 'Custodian',
      order: 'Order',
    },
    schema: config.schemas.OrderTradeTicket,
    resources: {
      default: new ram.resources.Http('/api/order_trade_tickets/:id.json'),
      cookie: new ram.resources.Cookie('order_trade_tickets'),
    },
  });

  OrderTradeTicket.constructor.create = function (orderId, custodianId) {
    return $http.post('/api/order_trade_tickets.json', {
      orderId,
      custodianId,
    });
  };

  OrderTradeTicket.constructor.outstandingTickets = function () {
    return $http.get('/api/order_trade_tickets/outstanding_tickets.json');
  };

  return OrderTradeTicket;
}
