'use strict';

angular
  .module('controller.advise.client-application.account', [
    'config',
    'ram',
    'directive.account-goal-detail',
    'model.Account',
    'model.AdvisorCreateNewAccountFlow',
    'model.AccountFlowJointApplicant',
    'model.InvestmentManagementAgreement',
    'model.PortfolioManager',
    'model.PersonalRelation',
    'service.form-helper',
    'service.securityService',
  ])
  .controller('AdviseClientApplicationAccountCtrl', [
    '$scope',
    '$state',
    'config',
    'ram',
    'Account',
    'AdvisorCreateNewAccountFlow',
    'AccountFlowJointApplicant',
    'InvestmentManagementAgreement',
    'PortfolioManager',
    'PersonalRelation',
    'formHelper',
    'ima',
    'newAccountFlows',
    'userNotifications',
    'securityServiceFactory',
    'portfolioOptions',
    'currentUser',
    'client',
    'advisorDetails',
    AdviseClientApplicationAccountCtrl,
  ]);

function AdviseClientApplicationAccountCtrl(
  $scope,
  $state,
  config,
  ram,
  Account,
  AdvisorCreateNewAccountFlow,
  AccountFlowJointApplicant,
  InvestmentManagementAgreement,
  PortfolioManager,
  PersonalRelation,
  formHelper,
  ima,
  newAccountFlows,
  userNotifications,
  securityServiceFactory,
  portfolioOptions,
  currentUser,
  client,
  advisorDetails
) {
  const defaultPortfolioOption = advisorDetails.defaultPortfolioOption;
  const currentUserSecurity = securityServiceFactory.create(currentUser);
  const needsDefaultPortfolioOption =
    !currentUserSecurity.canSeePortfolioOption() || !currentUserSecurity.canEditPortfolioOption();
  const defaultPortfolioValidForPM =
    portfolioOptions.filter((option) => option.id === defaultPortfolioOption.id).length > 0;

  angular.extend($scope, {
    newAccountFlows,
    accountCount: newAccountFlows.length,
    portfolioOptions, // This is pre-filtered to be options for the PM selected on the prior page.
    clientPerson: client,
    cancelAction,
    ima,
    submitForm,
    attemptingJointNonSpouse: false,
    selectJointSubscriber: new ram.Accessor(false),
    relationship: new ram.Accessor(null),
  });

  // View initialization
  resetScope();

  // Scoped Functions

  function submitForm(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      doSave(formController).then(goNext);
    }
  }

  function cancelAction() {
    goToReview();
  }

  // Helper Functions

  function resetScope() {
    $scope.account = new Account();
    $scope.accountFlowJointApplicant = new AccountFlowJointApplicant();
    $scope.accountCount++;

    if (defaultPortfolioOption.id && defaultPortfolioValidForPM) {
      // Business Logic:
      //   If the external advisor can be given a default portfolio option.
      //   They also can be assigned a range of portfolio managers.
      //   It is possible that they pick a portfolio manager that doesn't support their given portfolio option.
      //   To resolve this dilemma, we check 'defaultPortfolioValidForPM'.  If the advisor doesn't have have this
      //   portfolio option in the list, then it is for a PM other than the one they picked.
      //   ==> The decision was to move along with the PM they picked.  Display options for that PM.  Ignore the default.
      $scope.account.portfolioOption(defaultPortfolioOption);
      $scope.account.portfolioOptionId(defaultPortfolioOption.id);
    } else if ($scope.portfolioOptions.length === 1) {
      $scope.account.portfolioOption($scope.portfolioOptions[0]);
      $scope.account.portfolioOptionId($scope.portfolioOptions[0].id);
    } else if (needsDefaultPortfolioOption) {
      // This is different than app/assets/javascripts/controller/advise/add-client-account.js because we know the PM here.
      const portfolioOption = PortfolioManager.constructor.defaultPortfolioOption(
        $scope.portfolioOptions
      );
      $scope.account.portfolioOption(portfolioOption);
      $scope.account.portfolioOptionId(portfolioOption.id);
    }
  }

  function doSave(formController) {
    $scope.isSaving = true;
    var newAccountFlow;

    return AdvisorCreateNewAccountFlow.create(
      accountFlowOptions($scope.account, $scope.newClientFlow)
    )
      .then(function (accountFlow) {
        newAccountFlow = accountFlow;
        return saveAccountFlowJointApplicant(accountFlow);
      })
      .then(function () {
        formController.$setPristine();
        resetScope();
        return newAccountFlow;
      })
      .catch(function (e) {
        console.log(e);
        userNotifications.showError(
          'Something went wrong while saving the new account. Please try again later.'
        );
      })
      .finally(function () {
        $scope.isSaving = false;
      });
  }

  async function saveAccountFlowJointApplicant(accountFlow) {
    const jointAccount = $scope.account.type.joint();
    const prefillingJointApplicant =
      $scope.account.type().canHaveJointApplicant && $scope.selectJointSubscriber();

    if (jointAccount && !prefillingJointApplicant) {
      // Creating a joint account, but not spouse
      $scope.accountFlowJointApplicant.advisorCreateNewAccountFlowId(accountFlow.id);
      return $scope.accountFlowJointApplicant.save();
    } else if (jointAccount || prefillingJointApplicant) {
      let newSpouse = $scope.clientPerson?.spouseRelation()?.relative(); // can be undefined

      if ($scope.clientPerson.maritalStatus() === undefined) {
        const relationshipOption = config.types.MaritalStatus.filter(
          (option) => option.label === $scope.relationship()
        )[0].id;
        if (relationshipOption !== null) {
          $scope.clientPerson.maritalStatusId(relationshipOption);
          await $scope.clientPerson.save();
          await $scope.clientPerson.spouseRelation.ready();
          newSpouse = $scope.clientPerson?.spouseRelation()?.relative();
        }
      }
      $scope.accountFlowJointApplicant.advisorCreateNewAccountFlowId(accountFlow.id);
      $scope.accountFlowJointApplicant.personId(newSpouse?.id);
      return await $scope.accountFlowJointApplicant.save();
    }
  }

  function accountFlowOptions(account, clientFlow) {
    return {
      accountTypeId: account.typeId(),
      responsible: account.responsible(),
      advisorCreateNewClientFlowId: clientFlow.id,
      clientId: clientFlow.clientId(),
      jurisdictionId: account.jurisdictionId(),
      portfolioOptionId: account.portfolioOptionId(),
      accountNickname: account.nickname(),
    };
  }

  function goNext(newAccountFlow) {
    if ($scope.selectJointSubscriber()) {
      $state.go('advise.client-application.joint-applicant', {
        newAccountFlowId: newAccountFlow.id,
      });
    } else if (newAccountFlow.accountType().beneficiary) {
      $state.go('advise.client-application.beneficiary', {
        newAccountFlowId: newAccountFlow.id,
      });
    } else {
      goToReview();
    }
  }

  function goToReview() {
    $state.go('advise.client-application.review');
  }
}
