'use strict';

function fullNameDirective() {
  return {
    restrict: 'E',
    scope: {
      person: '=',
      onChange: '&',
      optional: '=',
      piecesDisabled: '@', // Parse it as text
    },
    compile: function (element, attrs) {
      if (!attrs.onChange) {
        attrs.onChange = angular.noop;
      }
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.disableList = $scope.piecesDisabled ? JSON.parse($scope.piecesDisabled) : {};
      },
    ],
    templateUrl: 'directives/user/full-name.html',
  };
}

angular.module('directive.input.full-name', []).directive('fullName', [fullNameDirective]);
