'use strict';

angular
  .module('directive.mobile-household-member', [])
  .directive('mobileHouseholdMember', [mobileHouseholdMemberDirective]);

function mobileHouseholdMemberDirective() {
  return {
    restrict: 'E',
    templateUrl: 'directives/mobile-household-member.html',
    scope: {
      householdMembership: '=',
      householdInvitation: '=',
      isDisabled: '=',
      isCurrentUser: '=',
      canModifyHousehold: '=',
      deleteCallback: '=',
    },
    link: function (scope) {
      if (scope.householdMembership) {
        scope.name = scope.householdMembership.fullName();
        scope.email = scope.householdMembership.email();
        scope.access = scope.householdMembership.accessLevel();
      } else {
        scope.name = '';
        scope.email = scope.householdInvitation.inviteeEmail();
        scope.access = 'Pending';
      }
    },
  };
}
