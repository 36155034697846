'use strict';

angular
  .module('service.resolve-helpers.model-portfolio', [
    'model.ModelPortfolio',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investments-bar-prep-data',
    'service.portfolio-line-chart-data-service',
    'ram',
  ])
  .factory('modelPortfolioResolveHelpers', [
    'ModelPortfolio',
    'config',
    'invBarPrepData',
    'investmentChartsAssetAllocationDataGenerator',
    'portfolioLineChartDataService',
    factory,
  ]);

function factory(
  ModelPortfolio,
  config,
  invBarPrepData,
  investmentChartsAssetAllocationDataGenerator,
  portfolioLineChartDataService
) {
  var resolveDefaultModelPortfolio = function () {
    var corePortofolioOption = _.findWhere(config.types.AccountPortfolioOption, {
      name: 'core_portfolio',
    });

    return ModelPortfolio.where({
      portfolioOptionId: corePortofolioOption.id,
      risk: 5,
      taxable: false,
    }).then(_.first);
  };

  var resolvePortfolioSelectionsWithDefaultAndPseudoAccount = function (defaultModelPortfolio) {
    var chartData = investmentChartsAssetAllocationDataGenerator.getData(
      defaultModelPortfolio.allocations()
    );

    var allocationDataForLegend = _.map(chartData, function (chartDataRow) {
      return {
        color: chartDataRow.color,
        symbol: chartDataRow.allocation.fund().symbol(),
        label: chartDataRow.allocation.fund().label(),
        assetClass: chartDataRow.allocation.fund().assetClass().label,
        weight: chartDataRow.allocation.weight(),
      };
    });

    var allocationsForBarChart = _.map(chartData, function (chartDataRow) {
      return chartDataRow.allocation;
    });

    const corePortfolioOptionId = config.types.AccountPortfolioOption.find(
      ({ name }) => name === 'core_portfolio'
    )?.id;

    return portfolioLineChartDataService.fetch(5, corePortfolioOptionId).then(function (data) {
      return {
        '05': {
          allocationLegendData: allocationDataForLegend,
          barChartData: invBarPrepData.getChartDataForAllocations(allocationsForBarChart),
          lineChartData: data,
          mer: defaultModelPortfolio.fee(),
        },
      };
    });
  };

  return {
    resolveDefaultModelPortfolio,
    resolvePortfolioSelectionsWithDefaultAndPseudoAccount,
  };
}
