'use strict';

angular
  .module('directive.social-media-icons', [])
  .directive('socialMediaIcons', [socialMediaIconsDirective]);

function socialMediaIconsDirective() {
  return {
    link,
    restrict: 'E',
    // The functions to be executed upon ng-click on icon are provided via optional attributes corresponding to the sites' names. Currently, the template includes Facebook, Twitter, and sharing through Email.
    scope: {
      emailShare: '&email',
      fbShare: '&fb',
      twitterShare: '&twitter',
    },
    templateUrl: 'directives/social-media-icons.html',
  };

  function link(scope, el, attrs) {
    // Directive only shows icons for sites for which an attribute is provided.
    angular.extend(scope, {
      email: attrs.email,
      fb: attrs.fb,
      twitter: attrs.twitter,
    });
  }
}
