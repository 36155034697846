'use strict';

class PickAdvisorController {
  constructor($scope, $http, $timeout, ram) {
    this.$scope = $scope;
    this.$http = $http;
    this.$timeout = $timeout;
    this.ram = ram;
  }

  $onInit() {
    this.searchName = new this.ram.Accessor();
    this.advisorOptions = [];
    this.advisorToCopy = new this.ram.Accessor();
    if (this.resetCallback) {
      this.resetCallback(this.reset.bind(this));
    }

    this.$timeout(() => {
      const ENTER_KEY = 13;
      this.inputField = angular.element('#add-to-existing-advisor-panel input')[0];
      if (this.inputField) {
        this.inputField.addEventListener('keyup', (event) => {
          event.preventDefault();
          if (event.keyCode === ENTER_KEY) {
            this.searchAction();
          }
        });
      }
    }, 0);
  }

  $onDestroy() {
    if (this.inputField) {
      this.inputField.removeEventListener('keyup', this.eventHandler);
    }
  }

  reset() {
    this.advisorToCopy(null);
    this.advisorOptions = [];
    this.searchName(null);
  }

  searchAction() {
    this._search(this.searchName());
  }

  _search(name) {
    this.advisorToCopy(null);
    this.onSelectAdvisor(null);
    const displayAllClause = this.displayAll === 'true' ? '&display_all=true' : '';
    this.$http
      .get(
        `/api/super_advisor/new_external_advisor_details/advisor_search.json?name=${name}${displayAllClause}`
      )
      .then((result) => {
        this._setSearchOnScreen(result.data);
      })
      .catch(() => console.log('Error'));
  }

  _setSearchOnScreen(data) {
    this.advisorOptions = data
      .map((item) => {
        const itemsList = [];
        if (item.institution) {
          itemsList.push(item.institution);
        }
        if (item.advisor) {
          itemsList.push(item.advisor);
        }
        return {
          label: `${itemsList.join(' - ')}`,
          payload: item,
        };
      })
      .sort((a, b) => compareLabel(a, b));

    // if there is only one option, select it
    this.$timeout(() => {
      if (this.advisorOptions.length === 1) {
        const element = angular.element('#add-to-existing-advisor-panel input')[1];
        element.click();
      }
    }, 0);

    function compareLabel(a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }
  }

  get optionalText() {
    return this.optional === 'true' ? 'optional ' : '';
  }
}

export default PickAdvisorController;
