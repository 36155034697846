'use strict';

angular
  .module('controller.modal.generic-confirmation-modal', [])
  .controller('modalGenericConfirmationCtrl', [
    '$scope',
    'title',
    'body',
    'acceptButtonClass',
    'acceptButtonText',
    'cancelText',
    'otherButtonText',
    modalGenericConfirmationCtrl,
  ]);

function modalGenericConfirmationCtrl(
  $scope,
  title,
  body,
  acceptButtonClass,
  acceptButtonText,
  cancelText,
  otherButtonText
) {
  $scope.title = title;
  $scope.body = body;
  $scope.acceptButtonClass = acceptButtonClass || 'btn-danger';
  $scope.acceptButtonText = acceptButtonText || 'Continue';
  $scope.cancelText = cancelText || 'Go Back';
  $scope.otherButtonText = otherButtonText;
}
