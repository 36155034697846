'use strict';

function modalCancelTransferController($scope, cancelDisplayOptions) {
  $scope.cancelDisplayOptions = cancelDisplayOptions;
}

angular
  .module('controller.modal.transfer-cancel-confirmation-modal', [])
  .controller('modalCancelTransferCtrl', [
    '$scope',
    'transferInstruction',
    modalCancelTransferController,
  ]);
