'use strict';

function previewProjectionsController($scope, goal) {
  $scope.projectionPoint = {};
  $scope.showRecom = false;

  if (!_.isNumber(goal.initial())) {
    goal.initial(0);
  }

  if (!_.isNumber(goal.ongoing())) {
    goal.ongoing(0);
  }

  function updateProjections(current, old) {
    if (current === old) {
      return;
    }

    $scope.portfolio.projections.ready().then(function (projections) {
      $scope.projections = _.map(projections, function (projection) {
        return projection.simulate(goal.initial() || 0, goal.ongoing() || 0);
      });
      $scope.recommendedOngoing = $scope.projections[1].findOngoingForGoal(goal);
      $scope.recommendedInitial = $scope.projections[1].findInitialForGoal(goal);
      $scope.recommendedTarget = Math.floor(
        $scope.projections[1].calculateValue(goal.initial(), goal.ongoing(), goal.timeframe() * 12)
      );
      $scope.recommendationAvailable =
        $scope.recommendedTarget < goal.targetAmount() && goal.showTarget();
    });
    $scope.maturity = moment().add(goal.timeframe(), 'y')._d;
  }

  /**
   * Watch for changes to projections caused by portfolio change or by changes
   * to the initial investment.
   */
  $scope.$watch('goal.initial()', updateProjections);
  $scope.$watch('goal.ongoing()', function (current, old) {
    if (current !== 0) {
      goal.unpredictable(false);
    }
    updateProjections(current, old);
  });
  $scope.$watch('goal.unpredictable()', function (unpredictable) {
    if (unpredictable) {
      goal.ongoing(0);
    }
  });
  $scope.$watch('goal.timeframe()', updateProjections);
  $scope.$watch('goal.targetAmount()', updateProjections);
  $scope.$watch('portfolio.projections()', updateProjections);

  $scope.setOngoing = function () {
    $scope.goal.ongoing($scope.recommendedOngoing);
    goal.save();
  };

  $scope.setInitial = function () {
    $scope.goal.initial($scope.recommendedInitial);
    goal.save();
  };

  $scope.setTarget = function () {
    $scope.goal.targetAmount($scope.recommendedTarget);
    goal.save();
  };

  $scope.saveProjectionsParameters = function () {
    goal.save();
  };

  /**
   * Trigger the first projections calculation.
   */
  updateProjections(true);
}

angular
  .module('controller.preview.projections', ['directive.chart.stacked', 'filter.currency'])
  .controller('PreviewProjectionsController', ['$scope', 'goal', previewProjectionsController]);
