'use strict';

angular
  .module('model.PortfolioPreferenceAnswer', ['model.User'])
  .factory('PortfolioPreferenceAnswer', ['ram', 'config', portfolioPreferenceAnswerFactory]);

function portfolioPreferenceAnswerFactory(ram, config) {
  const PortfolioPreferenceAnswer = new ram.Collection('PortfolioPreferenceAnswer', {
    belongsTo: {
      account: 'Account',
    },
    resources: {
      default: new ram.resources.Http('/api/portfolio_preference_answers/:id.json'),
      cookie: new ram.resources.Cookie('portfolio_preference_answers'),
    },
    schema: config.schemas.PortfolioPreferenceAnswer,
  });

  return PortfolioPreferenceAnswer;
}
