'use strict';

angular
  .module('form-helper-resp-beneficiary', ['model.RespBeneficiary'])
  .factory('formHelperRespBeneficiary', ['$q', '$http', 'RespBeneficiary', factory]);

function factory($q, $http, RespBeneficiary) {
  function save(vars, beneficiary) {
    var data = beneficiary.toJSON();
    var relative = beneficiary.personalRelation().relative().toJSON();
    if (beneficiary.addressChanged()) {
      relative.homeAddress = beneficiary.personalRelation().relative().homeAddress().toJSON();
      beneficiary.addressChanged(false);
    }
    data.personalRelation.relative = relative;

    return $http.post('/api/resp_beneficiaries/advisor_update', {
      data,
    });
  }

  function createBeneficiary(vars) {
    var params = {
      accountId: vars.account.id,
      userId: vars.baseScope.client.id,
      personalRelation: {
        relative: {
          homeAddress: {},
        },
      },
    };

    if (vars.inlineSave) {
      return RespBeneficiary.create(params).then(function (beneficiary) {
        beneficiary.justCreated(true);
        vars.baseScope.beneficiaries.push(beneficiary);
      });
    } else {
      var respBeneficiary = RespBeneficiary.new(params);
      respBeneficiary.justCreated(true);
      vars.baseScope.beneficiaries.push(respBeneficiary);
      return respBeneficiary;
    }
  }

  function addRespBeneficiary(vars) {
    if (!vars.baseScope.canAddBeneficiary) {
      return;
    }
    createBeneficiary(vars);
  }

  function deleteBeneficiary(vars, beneficiary) {
    var scope = vars.baseScope;
    if (
      !scope.isFamilyResp ||
      scope.beneficiaries.length <= 1 ||
      _.indexOf(scope.beneficiaries, beneficiary) === -1
    ) {
      return;
    }
    vars.loadingOn();

    beneficiary
      .destroy()
      .then(function () {
        var index = _.indexOf(scope.beneficiaries, beneficiary);
        if (index >= 0) {
          scope.beneficiaries.splice(index, 1);
        }
      })
      .then(postUpdateRecord.bind(null, vars, 'delete resp beneficiary'))
      .finally(function () {
        vars.loadingOff();
      });
  }

  function postUpdateRecord(vars, message) {
    if (!vars.scope.client || !vars.advisor) {
      return;
    }
    return $http.post('/api/update_records/audit', {
      userId: vars.scope.client.id,
      reason:
        'Advisor ' +
        (vars.advisor && vars.advisor.person().fullName()) +
        ' (id = ' +
        (vars.advisor && vars.advisor.id) +
        ') ' +
        message,
    });
  }

  return {
    save,
    createBeneficiary,
    deleteBeneficiary,
    addRespBeneficiary,
  };
}
