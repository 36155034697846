'use strict';

angular
  .module('service.group-activities-service', ['model.Activity'])
  .factory('groupActivitiesService', ['Activity', groupActivitiesServiceFactory]);

function groupActivitiesServiceFactory(Activity) {
  var _cloneActivity = function (activity) {
    var clone = new Activity({
      date: activity.date(),
      typeId: activity.typeId(),
      amount: activity.amount(),
      description: activity.description(),
    });
    return clone;
  };

  var groupByDateTypeDescription = function (activities) {
    return _.chain(activities)
      .groupBy(function (activity) {
        return activity.date() + '#' + activity.typeId() + '#' + activity.description();
      })
      .map(function (activityGroup) {
        var clone = _cloneActivity(_.first(activityGroup));
        var amount = _.chain(activityGroup)
          .map(function (activity) {
            return parseFloat(activity.amount());
          })
          .reduce(function (acc, val) {
            return acc + val;
          })
          .value();
        clone.amount(_.isNaN(amount) ? null : amount);
        return clone;
      })
      .filter(function (activityGroup) {
        // Ignore types: Fee (2), GST/HST(12) & Service Fee(16)
        const ignoreTypes = [2, 12, 16];
        return ignoreTypes.indexOf(activityGroup.typeId()) === -1;
      })
      .value();
  };

  return {
    groupByDateTypeDescription,
  };
}
