'use strict';

angular
  .module('controller.modal.incomplete-account-transfer', [])
  .controller('ModalIncompleteAccountTransferCtrl', [
    '$scope',
    '$state',
    'toParams',
    'toState',
    'userWantsToLeaveCallback',
    incompleteAccountTransferCtrl,
  ]);

function incompleteAccountTransferCtrl(
  $scope,
  $state,
  toParams,
  toState,
  userWantsToLeaveCallback
) {
  $scope.leave = function () {
    userWantsToLeaveCallback.call();
    $state.go(toState, toParams).then(function () {
      $scope.$close();
    });
  };
}
