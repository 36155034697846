'use strict';

angular.module('service.trim-balances', []).factory('trimBalances', [trimBalances]);

function trimBalances() {
  var service = {
    indexOfLastZeroBalance: getIndexOfLastZeroBalance,
    toLastZeroBalance: trimToLastZeroBalance,
  };

  return service;

  /**
   * It returns the index of the last day with a balance of zero, when the next day has a non-zero balance.
   * If there are no days with a non-zero balance it returns <pre>undefined</pre>. If the balance is non-zero
   * from the very first day, it returns 0.
   * @param balances the balances
   * @returns {number}
   */
  function getIndexOfLastZeroBalance(balances) {
    var indexOfFirstNonZeroBalance = _.findIndex(balances, function (balance) {
      return balance.amount !== 0;
    });

    if (indexOfFirstNonZeroBalance > 0) {
      return indexOfFirstNonZeroBalance - 1;
    } else if (indexOfFirstNonZeroBalance === 0) {
      return 0;
    }
  }

  function trimToLastZeroBalance(balances) {
    var i = getIndexOfLastZeroBalance(balances);
    if (!_.isUndefined(i)) {
      return balances.slice(i);
    }
  }
}
