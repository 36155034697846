'use strict';

function participantFactory(ram, config) {
  var Participant = new ram.Collection('Participant', {
    enums: {
      type: config.types.Participant,
    },
    belongsTo: {
      person: 'Person',
      account: 'Account',
    },
    schema: config.schemas.Participant,
    resources: {
      default: new ram.resources.Http('/api/participants/:id.json'),
      cookie: new ram.resources.Cookie('participants'),
    },
  });

  return Participant;
}

angular
  .module('model.Participant', ['ram', 'config', 'model.Person', 'model.Account'])
  .factory('Participant', ['ram', 'config', participantFactory]);
