'use strict';

angular
  .module('controller.advisor-new-client.invalid-link', [])
  .controller('AdvisorNewClientInvalidLinkController', [
    '$scope',
    '$stateParams',
    'currentUser',
    advisorNewClientInvalidLinkController,
  ]);

const HTTP_CODES = {
  CONFLICT: 409, // Show name of logged in user and buttons to log out/continue
  UNAUTHORIZED: 401, // Show name of logged in user and buttons to log out/continue
  RESOURCE_NOT_FOUND: 404, // Show 'Invalid link'/'Check with your advisor'
  RANGE_NOT_SATISFIED: 416, // Show 'Incomplete record'/'Check with your advisor'
};

function advisorNewClientInvalidLinkController($scope, $stateParams, currentUser) {
  $scope.userName = currentUser?.person()?.fullName();
  $scope.action = {
    performing: 'new-client',
  };
  $scope.HTTP_CODES = HTTP_CODES;
  $scope.errorCode = Number($stateParams.errorCode || 404);

  $scope.logout = function () {
    $scope.$root.logout(linkAddress());
  };

  function linkAddress() {
    const link = document.location.href.replace('invalid-link', 'view');
    const index = link.indexOf('?');
    return index > 0 ? link.substring(0, index) : link;
  }
}
