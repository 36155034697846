class BeneficiaryAccountModifyAllCardController {
  constructor(
    $scope,
    $http,
    $q,
    $timeout,
    ram,
    Beneficiary,
    SuccessorHolder,
    PendingChange,
    Person,
    PersonalRelation,
    accountService,
    spouseOrPartner,
    beneficiaryService,
    userNotifications,
    pendingChangesService
  ) {
    this.$scope = $scope;
    this.$http = $http;
    this.$q = $q;
    this.$timeout = $timeout;
    this.ram = ram;
    this.Beneficiary = Beneficiary;
    this.SuccessorHolder = SuccessorHolder;
    this.PendingChange = PendingChange;
    this.Person = Person;
    this.PersonalRelation = PersonalRelation;
    this.accountService = accountService;
    this.spouseOrPartnerService = spouseOrPartner;
    this.beneficiaryService = beneficiaryService;
    this.userNotifications = userNotifications;
    this.pendingChangesService = pendingChangesService;
    this.onPersonChange = this.onPersonChangeUnbound.bind(this);
    this.onPersonalRelationChange = this.onPersonalRelationChangeUnbound.bind(this);
    this.onBeneficiaryChange = this.onBeneficiaryChangeUnbound.bind(this);
    this.deleteAction = this.deleteActionUnbound.bind(this);
  }

  $onInit() {
    this.registerParent({
      childRef: this, // this gets set with `this.childCtrl = child;` in app/assets/javascripts/controller/advise/account.js:748
    });
    this.person = this.client.person();
    this.currentUser = this.client; // This may be used in the context of superadvisor is the currentUser
    this.spouseRelationId = this.person.spouseRelation()?.id;
    this.spouse = this.person.spouseRelation() && this.person.spouseRelation().relative();
    this.savedSpouseTypeId = this.person.spouseRelation() && this.person.spouseRelation().typeId(); // Because it gets lost.
    this.spouseIsOneOfTheBeneficiaries = new this.ram.Accessor(false);
    this.spousalBeneficiary = null;
    this.otherBeneficiaries = [];
    this.$scope.totalIsOneHundred = false;
    this.spouseIsSuccessorHolder = new this.ram.Accessor(!!this.successorHolder);
    this.rowClass = '';
    this.form = this.getForm();

    if (this.usesSuccessorHolder()) {
      this.originalSuccessorHolder = this.successorHolder;
      this.successorHolder = this.successorHolder ?? {};
      this.successorHolder = this._wrapSuccessorHolder(this.successorHolder);
    }
    this._sortInputBeneficiaries(this.beneficiaries);
  }

  getForm() {
    let parent = this.$scope.$parent;
    while (parent !== null) {
      if (Object.getOwnPropertyNames(parent).includes('form')) {
        return parent.form;
      }
      parent = parent.$parent;
    }
    return null;
  }

  callback(command, ...args) {
    if (command === 'validate') {
      this._validatePercent();
      if (!this.estateIsBeneficiary() && !this.$scope.totalIsOneHundred) {
        // This is a temporary measure.  I can refine it later .
        this.userNotifications.showError('The percentages must add to 100');
        window.scrollTo(0, 0);
        return false;
      }
      return true;
    } else if (command === 'save') {
      return this.save();
    } else if (command === 'reset') {
      this.otherBeneficiaries = [];
      this.spouseIsOneOfTheBeneficiaries(false);
      this.spousalBeneficiary = null;
      this._sortInputBeneficiaries(this.beneficiaries);
    } else if (command === 'mutate-data') {
      const [beneficiaries, successorHolder] = args;
      this.mutateBeneficiaries(beneficiaries);
      this.mutateSuccessorHolder(successorHolder);
    }
  }

  save() {
    const self = this;
    const withSuccessorHolder = this.spouseIsSuccessorHolder();
    const successorHolder = {
      personalRelationId: null,
      label: 'None',
    };

    if (this.originalSuccessorHolder && withSuccessorHolder) {
      this.$scope.$root.genericOkModal('Operation cancelled', 'No changes have been made.');
      const defer = self.$q.defer();
      defer.resolve();
      return defer.promise;
    }

    if (withSuccessorHolder) {
      successorHolder.personalRelationId = this.person.spouseRelation().id;
      successorHolder.fullName = this.spouse.fullName();
      successorHolder.label = `${this.spouseOrPartner({
        capitalize: true,
      })} - ${this.spouse.fullName()}`;
    }

    const beneficiaries = [];
    if (!withSuccessorHolder) {
      if (this.spouseIsOneOfTheBeneficiaries()) {
        beneficiaries.push(this._spouseBeneficiaryRecord());
      }
      this.otherBeneficiaries.forEach((beneficiary) => {
        beneficiaries.push(this._otherBeneficiaryRecord(beneficiary));
      });
    }

    return this.pendingChangesService
      .createPendingBeneficiaryDesignation(this.currentUser.id, this.account.id, {
        successorHolder,
        beneficiaries,
      })
      .then(function () {
        const defer = self.$q.defer();
        defer.resolve();
        return defer.promise;
      });
  }

  usesSuccessorHolder() {
    return this.spouse && this._accountCanHaveSuccessorHolder();
  }

  canHaveBeneficiaries() {
    const cannotHaveBeneficiaries =
      this.account.type.canHaveSuccessorHolder() &&
      this.spouse &&
      this.spouseRelationId &&
      this.spouseIsSuccessorHolder();
    return !cannotHaveBeneficiaries;
  }

  dataExists() {
    return (
      this.otherBeneficiaries.length > 0 ||
      this.spouseIsOneOfTheBeneficiaries() ||
      this.spouseIsSuccessorHolder()
    );
  }

  state() {
    return {
      single: () => !this.spouse || !this.spouseRelationId,
      married: () => this.spouse && this.spouseRelationId,
      spouseSelected: () => this.spouseIsOneOfTheBeneficiaries() || this.spouseIsSuccessorHolder(),
      hasBeneficiaries: () =>
        this.otherBeneficiaries.length > 0 || this.spouseIsOneOfTheBeneficiaries(),
      hasOtherBeneficiaries: () => this.otherBeneficiaries.length > 0,
      usesSuccessorHolder: this.usesSuccessorHolder.bind(this),
      canHaveBeneficiaries: this.canHaveBeneficiaries.bind(this),
      canHaveSuccessorHolder: () =>
        this.beneficiaryService.accountUsesSuccessorHolder(this.account),
    };
  }

  visibility() {
    const state = this.state();
    return {
      shHeading: () => state.usesSuccessorHolder(),
      designateSH: () => state.married() && state.usesSuccessorHolder(),
      shEdit: () => state.married() && state.spouseSelected() && state.usesSuccessorHolder(),
      beneHeadingSimple: () =>
        state.usesSuccessorHolder() && (state.spouseSelected() || state.hasOtherBeneficiaries()),
      beneHeadingSpacer: () => state.married() && !state.canHaveSuccessorHolder(),
      designateSpouseBene: () => state.married() && !state.usesSuccessorHolder(),
      spouseBeneEdit: () =>
        state.married() && state.spouseSelected() && !state.usesSuccessorHolder(),
      otherBeneEdit: () => state.canHaveBeneficiaries(),
      contingentBene: () =>
        state.married() && state.spouseSelected() && state.usesSuccessorHolder(),
      addButton: () => state.canHaveBeneficiaries(),
      noSpouseBeneSpacer: () => state.single() && state.hasOtherBeneficiaries(),

      dataExists: () =>
        this.otherBeneficiaries.length > 0 ||
        this.spouseIsOneOfTheBeneficiaries() ||
        this.spouseIsSuccessorHolder(),
      beneTopLine: () => !state.usesSuccessorHolder() && state.married(),
    };
  }

  _spouseBeneficiaryRecord() {
    return {
      id: this.spousalBeneficiary.id, // might be null if record is new
      firstName: this.spousalBeneficiary.localPerson().firstName(),
      middleName: this.spousalBeneficiary.localPerson().middleName(),
      lastName: this.spousalBeneficiary.localPerson().lastName(),
      sin: this.spousalBeneficiary.localPerson().sin(),
      personalRelationTypeId: this.savedSpouseTypeId,
      otherRelationName: null,
      percent: this.spousalBeneficiary.percent(),
    };
  }

  _otherBeneficiaryRecord(beneficiary) {
    return {
      id: beneficiary.id, // might be null if record is new
      firstName: beneficiary.localPerson().firstName(),
      middleName: beneficiary.localPerson().middleName(),
      lastName: beneficiary.localPerson().lastName(),
      sin: beneficiary.localPerson().sin(),
      personalRelationTypeId: beneficiary.localPersonalRelation().typeId(),
      otherRelationName: beneficiary.localPersonalRelation().otherRelationName(),
      percent: beneficiary.percent(),
    };
  }

  addBeneficiary() {
    this.otherBeneficiaries.push(this._createNewBeneficiary());
    this.onBeneficiaryChangeUnbound();
  }

  deleteActionUnbound(beneficiary) {
    this.otherBeneficiaries = this.otherBeneficiaries.filter((item) => item !== beneficiary);
    this.onBeneficiaryChangeUnbound();
  }

  spouseOrPartner(options) {
    return this.spouseOrPartnerService.relationshipName(this.person, options);
  }

  onSpousalBeneficiaryOptionChanged() {
    if (this.spouseIsOneOfTheBeneficiaries()) {
      const newBeneficiary = this._linkSpouseAsBeneficiary();
      this.spousalBeneficiary = this._wrapBeneficiary(newBeneficiary);
    } else {
      // similar to deleteAction, but no 'delete' button
      this.spousalBeneficiary = null;
    }
    this.onBeneficiaryChangeUnbound();
  }

  onBeneficiaryChangeUnbound(/* beneficiary */) {
    this._validatePercent();
    if (this.$scope.totalIsOneHundred) {
      // This is sensitive to timing.  Allow the pending error to be recorded before clearing them all.
      this.$timeout(() => {
        this._clearPercentMessages();
      });
    }
  }

  estateIsBeneficiary() {
    return !this.spouseIsOneOfTheBeneficiaries() && this.otherBeneficiaries.length === 0;
  }

  onPersonalRelationChangeUnbound(/* personalRelation */) {
    angular.noop();
  }

  onPersonChangeUnbound(/* person */) {
    angular.noop();
  }

  _validatePercent() {
    this.$scope.totalIsOneHundred = this._calcTotalPercent() === 100.0;
    if (this.$scope.totalIsOneHundred) {
      this.userNotifications.clearNotification();
    }
  }

  _clearPercentMessages() {
    // The $error array mutates as you work with it.  We need to act on all that are here at the start.
    const errors = this.form.$error.percentagestotaltoonehundred;
    const savedErrors =
      errors === undefined || errors.length === 0
        ? []
        : [...this.form.$error.percentagestotaltoonehundred];

    for (const control of savedErrors) {
      if (!control.$name) {
        continue;
      }
      angular.element('[name=' + control.$name + ']').trigger('blur');
      control.$setValidity('percentagestotaltoonehundred', true);
    }
  }

  _accountCanHaveSuccessorHolder() {
    return this.beneficiaryService.accountUsesSuccessorHolder(this.account);
  }

  _createNewBeneficiary() {
    const percent = this.estateIsBeneficiary()
      ? 100
      : this.Beneficiary.constructor.suggestedPercent(this._calcTotalPercent());
    const person = new this.Person({
      firstName: null,
      lastName: null,
    });
    const personalRelation = new this.PersonalRelation();
    personalRelation.relative(person);
    const beneficiary = new this.Beneficiary({
      percent,
    });
    beneficiary.localPerson = new this.ram.Accessor(person);
    beneficiary.localPersonalRelation = new this.ram.Accessor(personalRelation);

    return beneficiary;
  }

  _linkSpouseAsBeneficiary() {
    const percent = this.otherBeneficiaries.length === 0 ? 100 : null;
    const person = this.spouse;
    const personalRelation = this.person.spouseRelation();
    const beneficiary = new this.Beneficiary({
      personalRelation,
      percent,
      personalRelationId: personalRelation.id,
    });
    beneficiary.localPerson = new this.ram.Accessor(person);
    beneficiary.localPersonalRelation = new this.ram.Accessor(personalRelation);

    return beneficiary;
  }

  _sortInputBeneficiaries(beneficiaries) {
    const isSpouse = (relationId) => relationId === this.spouseRelationId;

    beneficiaries.forEach((beneficiary) => {
      if (isSpouse(beneficiary.personalRelationId())) {
        this.spousalBeneficiary = this._wrapBeneficiary(beneficiary);
        this.spouseIsOneOfTheBeneficiaries(true);
      } else {
        this.otherBeneficiaries.push(this._wrapBeneficiary(beneficiary));
      }
    });

    this._validatePercent();
  }

  _wrapBeneficiary(beneficiary) {
    // localXXX is used to store the value the user is typing in.  Same below.
    const data = beneficiary.toJSON(); // so the percent isn't modified
    const newBeneficiary = new this.Beneficiary(data);
    newBeneficiary.localPerson = new this.ram.Accessor(beneficiary.personalRelation().relative());
    newBeneficiary.localPersonalRelation = new this.ram.Accessor(beneficiary.personalRelation());
    return newBeneficiary;
  }

  _wrapSuccessorHolder(successorHolder) {
    successorHolder.localPerson = new this.ram.Accessor(
      this.successorHolder.personalRelation
        ? this.successorHolder?.personalRelation()?.relative()
        : this.spouse
      // personalRelation only exists if it comes from the DB
    );
    return successorHolder;
  }

  _calcTotalPercent() {
    const reduceFn = (sum, bene) => sum + parseFloat(bene.percent());
    const total =
      this.otherBeneficiaries.reduce(reduceFn, 0.0) +
      (this.spouseIsOneOfTheBeneficiaries() ? parseFloat(this.spousalBeneficiary.percent()) : 0);
    // 33.3 + 33.3 + 33.4 = 99.999999999
    // Use 3 digits because user can input two.   So 33.33 + 33.33 + 33.33 = 99.99 will not validate.
    return Number(total.toFixed(3));
  }
}

export default BeneficiaryAccountModifyAllCardController;
