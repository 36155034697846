'use strict';

angular
  .module('routes.onboarding', [
    'controller.interview.navbar',
    'controller.onboarding',
    'service.account-service',
    'service.address-service',
    'service.adaptive-views',
    'service.branding-machine',
    'model.AdvisorCreateNewAccountFlow',
    'model.AdvisorCreateNewTransferFlow',
    'model.BankAccount',
    'model.PersonalRelation',
    'model.Beneficiary',
    'model.RespBeneficiary',
    'model.AccountBeneficiary',
    'templates',
    'ui.router',
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'adaptiveViewsProvider',
    'brandingMachineProvider',
    configRoutes,
  ]);

function getDocumentTypeId(documentType, config) {
  return _.find(config.types.Document, {
    name: documentType,
  }).id;
}

function fetchDocumentsByType(documentTypeId, currentUser, Document) {
  return Document.where({
    typeId: documentTypeId,
    userId: currentUser.id,
  });
}

/**
 * Gets the image specified by the imageType
 * @return {Document}     The document, or a new empty document if none exist.
 */
function resolveImage(imageType, config, Document, currentUser) {
  var typeId = getDocumentTypeId(imageType, config);

  return fetchDocumentsByType(typeId, currentUser, Document).then(function (documents) {
    if (!documents || documents.length === 0) {
      return Document.new({
        typeId,
        userId: currentUser.id,
      });
    } else {
      return _.last(documents);
    }
  });
}

var resolveFrontIdentificationImage = _.partial(resolveImage, 'id_front');
var resolveBackIdentificationImage = _.partial(resolveImage, 'id_back');
var resolveJointFrontIdentificationImage = _.partial(resolveImage, 'joint_id_front');
var resolveJointBackIdentificationImage = _.partial(resolveImage, 'joint_id_back');

function getBankStatementsForBankAccount(bankStatements, bankAccount) {
  return _.filter(bankStatements, function (bankStatement) {
    return (
      bankStatement.relatedToType() === 'BankAccount' &&
      bankStatement.relatedToId() === bankAccount.id
    );
  });
}

function resolveBankStatementImage(config, Document, currentUser, bankAccount) {
  var typeId = getDocumentTypeId('bank_statement', config);

  return fetchDocumentsByType(typeId, currentUser, Document).then(function (bankStatements) {
    var bankStatementsForBankAccount = getBankStatementsForBankAccount(bankStatements, bankAccount);

    if (bankStatementsForBankAccount && bankStatementsForBankAccount.length > 0) {
      return _.last(bankStatementsForBankAccount);
    } else {
      return Document.new({
        typeId,
        userId: currentUser.id,
        relatedToType: 'BankAccount',
        relatedToId: bankAccount.id,
      });
    }
  });
}

/**
 * Initialize angular UI router for advisor dashboard.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes(
  $stateProvider,
  $urlRouterProvider,
  adaptiveViewsProvider,
  brandingMachineProvider
) {
  function resolveAssociation(association) {
    return function (model) {
      return model[association].ready(true);
    };
  }

  var resolvePersonalRelations = resolveAssociation('personalRelations');

  function resolveIsNewClient(currentUser, accountService) {
    return !accountService.hasCompletedAccount(currentUser.accounts());
  }

  function resolveIsFirstRealAccount(currentUser, accountService) {
    return accountService.isFirstRealAccount(currentUser.accounts());
  }

  function shouldSkipBankView(account) {
    return !account.bankAccount().status.is.draft() && !account.type.registeredIncomeFund();
  }

  function resolveAccount(currentUser, Account) {
    return currentUser.accounts.ready(true).then(function (accounts) {
      // Filter out all of the trial accounts and all of those with account numbers.
      var incompleteAccounts = _.reject(accounts, function (account) {
        return account.applicationCompleted() || account.type.is.trial();
      });
      if (incompleteAccounts.length > 0) {
        // This logic and the logic below guarantees there will only be one.  It is safe to
        // return element zero.
        return incompleteAccounts[0];
      }
      return Account.create({
        userId: currentUser.id,
        number: null,
      });
    });
  }

  function resolveHomeAddress(person, addressService) {
    return addressService.resolveHomeAddress(person);
  }

  function resolveMailingAddress(person, homeAddress, addressService) {
    return addressService.resolveMailingAddress(person, homeAddress);
  }

  function resolveSpouse(personalRelations, personalInfoResolveHelpers) {
    return personalInfoResolveHelpers.resolveSpouse(personalRelations);
  }

  function resolveSpouseRelation(person) {
    return person.spouseRelation.ready();
  }

  function resolveAddresses(currentUser, spouse, $q) {
    if (spouse) {
      return $q.all([currentUser.person().addresses.ready(), spouse.addresses.ready()]);
    }

    return currentUser.person().addresses.ready();
  }

  function resolveJointApplicants(account) {
    return account.jointApplicants.ready();
  }

  function resolveAccountBeneficiaries(account, AccountBeneficiary) {
    return AccountBeneficiary.where({
      accountId: account.id,
    }).then(function (beneficiaries) {
      return beneficiaries;
    });
  }

  function resolveRespBeneficiaries(account, RespBeneficiary) {
    return RespBeneficiary.where({
      accountId: account.id,
    }).then(function (beneficiaries) {
      return beneficiaries;
    });
  }

  function resolveBeneficiaries(accountBeneficiaries, respBeneficiaries) {
    return _.union(accountBeneficiaries, respBeneficiaries);
  }

  function resolveBankAccount(account) {
    return account.bankAccount.ready();
  }

  function resolveValidBankAccounts(currentUser, BankAccount) {
    return BankAccount.where(
      {
        userId: currentUser.id,
      },
      {
        force: true,
      }
    ).then(function (bankAccounts) {
      return _.filter(bankAccounts, function (bankAccount) {
        return bankAccount.transferable() || bankAccount.status.is.draft();
      });
    });
  }

  function completeApplication($http, account) {
    var path = ['', 'api', 'accounts', account.id, 'complete_application.json'].join('/');
    return $http.put(path).then(function (results) {
      return account.reload().then(function () {
        return results.data;
      });
    });
  }

  function isConfirmedOrAdvisorInPerson(currentUser, clientFlow) {
    return currentUser.confirmed() || clientFlow?.isInPerson();
  }

  function authorize(authorizer) {
    return authorizer('Client');
  }

  function routeToPersonal($state) {
    $state.transitionTo('onboarding.personal-combined');
  }

  function resolveStates($state) {
    return _.filter($state.get(), function (state) {
      return state.url && /onboarding\./.test(state.name);
    });
  }

  function resolveAnalytics($analytics) {
    $analytics.eventTrack('completed-onboarding');
  }

  function skipRifDetail(account) {
    return !account.type.registeredIncomeFund();
  }

  function resolveRifDetail(account) {
    return account.rifDetail();
  }

  function resolveTaxableAccounts(currentUser) {
    return currentUser.accounts.ready().then(function (accounts) {
      return _.filter(accounts, function (account) {
        return account.type.is.nonRegistered() && account.number();
      });
    });
  }

  function resolveNewAccountApplicationForm(account) {
    return account.newAccountApplicationForm.ready();
  }

  function resolveIncompleteAccountFlows(AdvisorCreateNewAccountFlow) {
    return AdvisorCreateNewAccountFlow.constructor.incompleteFlowsForCurrentUser();
  }

  function resolveSuccessorHolder(account) {
    return account.successorHolder.ready();
  }

  function resolveIdentityVerificationStatus($http) {
    return $http.get('api/identity_verification_status.json').then(function (response) {
      return response.data;
    });
  }

  function resolveClientFlow(AdvisorCreateNewClientFlow, currentUser) {
    if (!currentUser) {
      return null;
    }
    return AdvisorCreateNewClientFlow.where({
      clientId: currentUser.person().id,
    }).then(function (list) {
      if (list.length === 0) {
        return null;
      }
      return _.first(list);
    });
  }

  function resolveAdvisorReferralDetails(currentUser, clientFlow) {
    return brandingMachineProvider.getAdvisorDetails(
      currentUser,
      clientFlow && clientFlow.advisorReferralCode()
    );
  }

  function skipCommunicationAndConsent(account) {
    return !account.custodian().isFcc();
  }

  function resolveJurisdiction(config, account) {
    const jurisdictionsAndAccountsWithSpouseSinAndDob = [
      'federal',
      'new_brunswick',
      'newfoundland_and_labrador',
      'northwest_territories',
      'nova_scotia',
      'nunavut',
      'ontario',
      'quebec',
      'saskatchewan',
      'yukon',
      'lira',
      'lif',
    ];

    const lockedInRrsp = config.types.Account[account.typeId()].name === 'locked_in_rrsp';

    if (account.jurisdictionId()) {
      const jurisdictionName = config.types.AccountJurisdiction[account.jurisdictionId()].name;
      const accountType = config.types.Account[account.typeId()].name;
      return (
        jurisdictionsAndAccountsWithSpouseSinAndDob.includes(jurisdictionName) &&
        jurisdictionsAndAccountsWithSpouseSinAndDob.includes(accountType)
      );
    } else if (lockedInRrsp) {
      return true;
    } else {
      return false;
    }
  }

  $urlRouterProvider.when('/onboarding', ['$state', routeToPersonal]);

  $urlRouterProvider.when('/onboarding/partner', ['$state', routeToPersonal]);
  $urlRouterProvider.when('/onboarding/employment', ['$state', routeToPersonal]);
  $urlRouterProvider.when('/onboarding/partner-employment', ['$state', routeToPersonal]);
  $urlRouterProvider.when('/onboarding/beneficiary', ['$state', routeToPersonal]);
  $urlRouterProvider.when('/onboarding/legal', ['$state', routeToPersonal]);
  $urlRouterProvider.when('/onboarding/additional', ['$state', routeToPersonal]);

  // This list should be kept in sync with the list of states in account-service.getStateForIncompleteAccount
  $stateProvider
    .state('onboarding', {
      url: '/onboarding',
      parent: 'site',
      hasMobileView: true,
      resolve: {
        isNewClient: ['currentUser', 'accountService', resolveIsNewClient],
        account: ['currentUser', 'Account', 'authorize', resolveAccount],
        authorize: ['authorizer', authorize],
        person: ['currentUser', 'authorize', resolveAssociation('person')],
        states: ['$state', resolveStates],
        incompleteAccountFlows: ['AdvisorCreateNewAccountFlow', resolveIncompleteAccountFlows],
        clientFlow: ['AdvisorCreateNewClientFlow', 'currentUser', resolveClientFlow],
        advisorReferralDetails: ['currentUser', 'clientFlow', resolveAdvisorReferralDetails],
      },
      views: {
        main: {
          controller: 'OnboardingController',
        },
        navbar: {
          controller: 'InterviewNavbarController',
          templateUrl: 'navbar/interview.html',
        },
        footer: {
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
      abstract: true,
    })
    .state('onboarding.personal-combined', {
      url: '/personal',
      controller: 'OnboardingPersonalCombinedController',
      data: {
        description: 'We will use the following information to open your financial account.',
        title: 'Tell us about yourself',
        interviewStep: 2,
        progress: 50,
        step: 5,
      },
      resolve: {
        addressService: 'addressService',
        homeAddress: ['person', 'addressService', resolveHomeAddress],
        mailingAddress: ['person', 'homeAddress', 'addressService', resolveMailingAddress],
        // spouse-personal
        personalRelations: ['person', resolvePersonalRelations],
        person: ['currentUser', 'authorize', resolveAssociation('person')], // force reload this here too to enable trusted contact behviour
        spouse: ['personalRelations', 'personalInfoResolveHelpers', resolveSpouse],
        spouseRelation: ['person', resolveSpouseRelation],
        jointApplicants: ['account', resolveJointApplicants],
        spouseHomeAddress: ['spouse', 'addressService', resolveHomeAddress],
        spouseMailingAddress: [
          'spouse',
          'spouseHomeAddress',
          'addressService',
          resolveMailingAddress,
        ],
        jurisdictionAndAccountWithSpouseSinAndDob: ['config', 'account', resolveJurisdiction],
        // beneficiary
        accountBeneficiaries: ['account', 'AccountBeneficiary', resolveAccountBeneficiaries],
        respBeneficiaries: ['account', 'RespBeneficiary', resolveRespBeneficiaries],
        beneficiaries: ['accountBeneficiaries', 'respBeneficiaries', resolveBeneficiaries],
        successorHolder: ['account', resolveSuccessorHolder],
        identityVerificationStatus: ['$http', resolveIdentityVerificationStatus],
      },
    })
    .state('onboarding.legalities', {
      url: '/legalities',
      controller: 'OnboardingLegalitiesController',
      data: {
        interviewStep: 2,
        progress: 60,
        title: 'Legalities',
        step: 6,
      },
      resolve: {
        legalInfo: ['person', resolveAssociation('legalInfo')],
        brokerageAccounts: ['currentUser', resolveAssociation('brokerageAccounts')],
        insiderRoles: ['person', resolveAssociation('insiderRoles')],
      },
    })
    .state('onboarding.communication-and-consent', {
      url: '/communication-and-consent',
      controller: 'OnboardingCommunicationAndConsentController',
      data: {
        skip: ['account', skipCommunicationAndConsent],
        interviewStep: 2,
        progress: 65,
        title: 'Communication and Consent',
        step: 7,
      },
    })
    .state('onboarding.rif-detail', {
      url: '/rifDetail',
      controller: 'OnboardingRifDetailController',
      data: {
        skip: ['account', skipRifDetail],
        title: 'Registered Income Fund payment information',
        interviewStep: 3,
        progress: 70,
        step: 8,
      },
      resolve: {
        bankAccount: ['account', resolveBankAccount],
        bankAccounts: ['currentUser', 'BankAccount', resolveValidBankAccounts],
        personalRelations: ['person', resolvePersonalRelations],
        rifDetail: ['account', resolveRifDetail],
        spouse: ['personalRelations', 'personalInfoResolveHelpers', resolveSpouse],
        taxableAccounts: ['currentUser', resolveTaxableAccounts],
      },
    })
    .state('onboarding.bank', {
      url: '/bank-account',
      controller: 'OnboardingBankController',
      data: {
        skip: ['account', shouldSkipBankView],
        description:
          'Linking your bank account will simplify transferring money to and from your investment account and helps confirm your identity',
        title: 'Banking Info',
        interviewStep: 3,
        progress: 75,
        step: 9,
      },
      resolve: {
        bankAccount: ['account', resolveBankAccount],
        documents: ['currentUser', resolveAssociation('documents')],
        bankStatementImageInput: [
          'config',
          'Document',
          'currentUser',
          'bankAccount',
          resolveBankStatementImage,
        ],
        institutions: [
          'bankingResolveHelpers',
          function (bankingResolveHelpers) {
            return bankingResolveHelpers.resolveInsitutions();
          },
        ],
      },
    })
    .state('onboarding.identification', {
      url: '/identity',
      controller: 'OnboardingIdentificationController',
      data: {
        description:
          'Please upload an image of government issued photo ID for personal identification purposes',
        title: 'Identification',
        interviewStep: 3,
        progress: 85,
        step: 10,
      },
      resolve: {
        documents: ['currentUser', resolveAssociation('documents')],
        personalRelations: ['person', resolvePersonalRelations],
        spouse: ['personalRelations', 'personalInfoResolveHelpers', resolveSpouse],
        addresses: ['currentUser', 'spouse', '$q', resolveAddresses],
        frontImage: ['config', 'Document', 'currentUser', resolveFrontIdentificationImage],
        backImage: ['config', 'Document', 'currentUser', resolveBackIdentificationImage],
        jointFrontImage: [
          'config',
          'Document',
          'currentUser',
          resolveJointFrontIdentificationImage,
        ],
        jointBackImage: ['config', 'Document', 'currentUser', resolveJointBackIdentificationImage],
      },
    })
    .state('onboarding.documents', {
      url: '/documents',
      controller: 'OnboardingDocumentsController',
      data: {
        title: 'Documents',
        interviewStep: 3,
        progress: 95,
        step: 11,
      },
      resolve: {
        ips: ['account', resolveAssociation('investmentPolicyStatement')],
      },
    })
    .state('onboarding.confirmEmail', {
      url: '/confirmEmail',
      controller: 'OnboardingConfirmEmailAddressController',
      hasMobileView: true,
      data: {
        skip: ['currentUser', 'clientFlow', isConfirmedOrAdvisorInPerson],
        title: 'Please confirm your email address',
        interviewStep: 3,
        progress: 95,
        step: 12,
      },
    })
    .state('onboarding.signatures', {
      url: '/signatures',
      controller: 'OnboardingSignaturesController',
      data: {
        loadText: 'Please wait...',
        title: 'Signature',
        interviewStep: 3,
        progress: 100,
        step: 13,
        hideNavigationButtons: true,
        finalStep: true,
      },
      resolve: {
        completeApplication: ['$http', 'account', completeApplication],
        trackStep: ['$analytics', resolveAnalytics],
      },
    })
    .state('onboarding.congratulations', {
      url: '/congratulations',
      controller: 'OnboardingCongratulationsController',
      data: {
        loadText: 'Please wait...',
        title: 'Congratulations',
        interviewStep: 4,
        progress: 100,
        step: 14,
        finalStep: true,
        hideNavBar: true,
      },
      resolve: {
        isFirstRealAccount: ['currentUser', 'accountService', resolveIsFirstRealAccount],
        trackStep: ['$analytics', resolveAnalytics],
        naaf: ['account', resolveNewAccountApplicationForm],
      },
    });
}
