'use strict';

angular
  .module('model.Profile', ['ram', 'config'])
  .factory('Profile', ['ram', 'config', profileFactory]);

function profileFactory(ram, config) {
  var Profile = new ram.Collection('Profile', {
    schema: config.schemas.Profile,
    resources: {
      default: new ram.resources.Http('/api/profiles/:id.json'),
      cookie: new ram.resources.Cookie('profiles'),
    },
  });

  return Profile;
}
