'use strict';

angular
  .module('model.User', [
    'Devise',
    'model.Account',
    'model.AccountTransfer',
    'model.AuthenticationFactor',
    'model.BankAccount',
    'model.BrokerageAccount',
    'model.CombinedAccount',
    'model.Document',
    'model.Goal',
    'model.InvestmentManagementAgreement',
    'model.KnowYourClient',
    'model.Notification',
    'model.Person',
    'model.SecondaryEmail',
    'ram',
    'service.riskAppetiteFieldNames',
  ])
  .factory('User', [
    '$q',
    '$http',
    'ram',
    'config',
    'CombinedAccount',
    'Auth',
    'riskAppetiteFieldNames',
    'Account',
    'SecondaryEmail',
    userFactory,
  ]);

function userFactory(
  $q,
  $http,
  ram,
  config,
  CombinedAccount,
  Auth,
  riskAppetiteFieldNames,
  Account,
  SecondaryEmail
) {
  /**
   * Constructor for User models built on ram models.
   * @class
   */
  var User = new ram.Collection('User', {
    accessors: [
      'password',
      'passwordConfirmation',
      'currentPassword',
      'promoCode',
      'updatedAt',
      'canEditPortfolioOption',
      'canSeePortfolioOption',
      'standardTwoFactorSelected',
      'abilityToViewIsRestricted',
    ],
    bind: ['age', 'netWorth', 'investorType', 'fullName', 'closeProfile'],
    hasMany: {
      accounts: 'Account',
      viewableAccounts: 'Account',
      bankAccounts: 'BankAccount',
      brokerageAccounts: 'BrokerageAccount',
      documents: 'Document',
      goals: 'Goal',
      investmentManagementAgreements: 'InvestmentManagementAgreement',
      knowYourClients: 'KnowYourClient',
      notifications: 'Notification',
      promotionRedemptions: 'PromotionRedemption',
      secondaryEmails: 'SecondaryEmail',
    },
    hasOne: {
      person: 'Person',
      investmentManagementAgreement: 'InvestmentManagementAgreement',
      knowYourClient: 'KnowYourClient',
      authenticationFactor: 'AuthenticationFactor',
      // kycUpdate: 'KycUpdate', // It seems intuitive to add this here, but it just doesn't work.
    },
    associationKeys: {
      viewableAccounts: {
        viewable: true,
      },
    },
    schema: config.schemas.User,
    serialize: ['person'],
    resources: {
      default: new ram.resources.Http('/api/users/:id.json'),
      cookie: new ram.resources.Cookie('users'),
    },
  });

  User.currentUser = function (options) {
    options = options || {};
    var deferred = $q.defer();
    var errorHandler = function () {
      deferred.resolve();
    };
    Auth.currentUser().then(function (response) {
      User.find(
        {
          id: response.id,
        },
        options
      ).then(function (user) {
        deferred.resolve(user);
      }, errorHandler);
    }, errorHandler);
    return deferred.promise;
  };

  /**
   * Convert user's birthday to a javascript date object and instantiate a
   * CombinedAccount sub-model for the user.
   * @method initialize
   */
  User.prototype.initialize = function () {
    /**
     * A duck-typed Account that represents the combined properties of a users'
     * accounts.
     */
    this.accountsCombined = new CombinedAccount(this, 'accounts', '-', 'My Accounts');
    this.householdCombined = new CombinedAccount(
      this,
      'viewableAccounts',
      'h',
      'Household Accounts'
    );
    this.initializeSecondaryEmail();

    // viewableAccounts is a virtual relationship. Updating the _fetch method to query the right API.
    this.viewableAccounts._fetch = function (force) {
      var self = this; // jshint ignore:line
      var promise = Account.where(
        {
          viewable: true,
        },
        {
          force,
        }
      );
      if (promise !== self._promise) {
        self._promise = promise;
      }
      return promise;
    };
  };

  /**
   * Concatenate the user's first and last name.
   * @method fullName
   *
   * @return {String} User's full name.
   */
  User.prototype.fullName = function () {
    var person = this.person();
    return person && person.fullName();
  };

  /**
   * GetterSetter that aliases user's birthday. Sets birthday to the beginning
   * of the birth year. Gets the number of years since birthday.
   * @method age
   *
   * @param  {Number} val Age of the user
   * @return {Number}
   */
  User.prototype.age = function () {
    var person = this.person();
    return person && person.age.apply(person, arguments);
  };

  /**
   * Calculate user's net worth (assets - liabilities)
   *
   * @method netWorth
   * @return {Number}
   */
  User.prototype.netWorth = function () {
    var financialAssets = this.financialAssets() || 0;
    var nonFinancialAssets = this.nonFinancialAssets() || 0;
    var totalLiabilities = this.totalLiabilities() || 0;
    return financialAssets + nonFinancialAssets - totalLiabilities;
  };

  /**
   * Get the investor type object corresponding to the user's risk appetite.
   *
   * @method investorType
   * @return {Object}
   */
  User.prototype.investorType = function () {
    return _.findWhere(config.types.Investor, {
      value: this.riskAppetite(),
    });
  };

  User.prototype.riskSurveyComplete = function () {
    var self = this;
    var accessors = riskAppetiteFieldNames.fieldNameList;
    return _.all(accessors, function (accessor) {
      return !_.isNaN(parseInt(self[accessor]()));
    });
  };

  User.prototype.financialSituationComplete = function () {
    var self = this;
    var accessors = ['income', 'financialAssets', 'nonFinancialAssets', 'totalLiabilities'];
    return _.all(accessors, function (accessor) {
      return !_.isNaN(parseInt(self[accessor]()));
    });
  };

  User.prototype.closeProfile = function () {
    var self = this;
    return $http.put('/api/users/' + this.id + '/close_profile').then(function () {
      return self.reload();
    });
  };

  User.prototype.sendConfirmation = function () {
    var self = this;
    return $http.post('/users/confirmation', {
      user: {
        email: self.email(),
      },
    });
  };

  User.prototype.initializeSecondaryEmail = function () {
    try {
      if (!this.secondaryEmails() || this.secondaryEmails().length === 0) {
        this.secondaryEmails().push(new SecondaryEmail());
      }
    } catch (e) {}
  };

  User.prototype.sendConfirmationSecondary = function (secondaryEmail) {
    return $http.post(`/api/secondary_emails/${secondaryEmail.id}/re_send_confirmation_email.json`);
  };

  User.prototype.removeUnconfirmedSecondaryEmail = function (secondaryEmail) {
    return $http.delete(`/api/secondary_emails/${secondaryEmail.id}/remove_unconfirmed.json`);
  };

  User.advisorResendConfirmationEmail = function (userId) {
    return $http.post(`/api/users/${userId}/re_send_confirmation_email.json`);
  };

  User.prototype.setPassword = function (newPassword) {
    var self = this;
    return $http
      .post('/api/users/set_password', {
        password: newPassword,
      })
      .then(function () {
        return self.reload();
      });
  };

  return User;
}
