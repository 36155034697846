'use strict';

angular
  .module('controller.advisor-new-account.view', [
    'model.AdvisorCreateNewTransferFlow',
    'model.AdvisorCreateNewTransferInstructionFlow',
  ])
  .controller('AdvisorNewAccountViewController', [
    '$scope',
    '$state',
    '$http',
    'config',
    'incompleteAccountFlows',
    'AdvisorCreateNewTransferFlow',
    'newTransferInstructionFlows',
    'bankAccounts',
    'newAccountFlows',
    'transferAccountDataProvider',
    function (
      $scope,
      $state,
      $http,
      config,
      incompleteAccountFlows,
      AdvisorCreateNewTransferFlow,
      newTransferInstructionFlows,
      bankAccounts,
      newAccountFlows,
      transferAccountDataProvider
    ) {
      if ($scope.newAccountFlow.advisorCreateNewClientFlowId()) {
        if (incompleteAccountFlows.length === 0) {
          $state.go('interview.goal');
        }
        $scope.preventDiscardApplication = false;
        $scope.simpleAccountDescription = true;
      }

      $scope.advisorCreateNewTransferFlows = $scope.newAccountFlow
        .advisorCreateNewTransferFlows()
        .map(function (x) {
          return new AdvisorCreateNewTransferFlow(x);
        });

      transferAccountDataProvider.setInputs($scope.client, newAccountFlows, bankAccounts);
      $scope.advisorCreateNewTransferInstructionFlows = _.filter(
        newTransferInstructionFlows,
        function (transferInstruction) {
          return (
            transferInstruction.transferFromId() === $scope.newAccountFlow.id ||
            transferInstruction.transferToId() === $scope.newAccountFlow.id
          );
        }
      );

      $scope.accountType = config.types.Account[$scope.newAccountFlow.accountTypeId()].label;

      if ($scope.newAccountFlow.status() === 'completed') {
        $state.go('advisor-new-account.stale');
      }

      $scope.onAccept = function () {
        $http
          .put('/api/advisor_create_new_account_flows/' + $scope.newAccountFlow.id + '/approve')
          .then(function () {
            $state.go('advisor-new-account.continue');
          });
      };

      $scope.onReject = function () {
        if ($scope.preventDiscardApplication) {
          return;
        }
        $http
          .put('/api/advisor_create_new_account_flows/' + $scope.newAccountFlow.id + '/reject')
          .then(function () {
            $state.go('advisor-new-account.reject');
          });
      };
    },
  ]);
