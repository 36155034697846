'use strict';

angular
  .module('controller.account.signin', [
    'Devise',
    'service.manage-user-cookie',
    'ram',
    'service.user-session-service',
    'service.navigation',
    'service.loading-indicator',
    'model.User',
    'model.KycUpdate',
    'service.user-notifications',
    'service.two-factor-authenticator',
    'service.enter-key-handler',
  ])
  .controller('AccountSigninCtrl', [
    '$scope',
    '$state',
    '$timeout',
    '$q',
    '$stateParams',
    '$rootScope',
    '$http',
    'Auth',
    'ram',
    '$analytics',
    'manageUserCookie',
    'userSessionService',
    'User',
    'KycUpdate',
    'navigationService',
    'loadingIndicator',
    'twoFactorAuthenticator',
    'enterKeyHandler',
    accountSigninController,
  ]);

function accountSigninController(
  $scope,
  $state,
  $timeout,
  $q,
  $stateParams,
  $rootScope,
  $http,
  Auth,
  ram,
  $analytics,
  manageUserCookie,
  userSessionService,
  User,
  KycUpdate,
  navigationService,
  loadingIndicator,
  twoFactorAuthenticator,
  enterKeyHandler
) {
  $scope.email = new ram.Accessor();
  $scope.password = new ram.Accessor();
  $scope.code = new ram.Accessor();
  $scope.otpCode = new ram.Accessor();
  $scope.rememberDevice = new ram.Accessor();

  $scope.state = 'sign-in';

  /**
   * Set $scope.close to angular.noop if it's not defined so that this controller
   * can be used in modals and page views.
   */
  _.defaults($scope, {
    $close: angular.noop,
  });

  $scope.feedbackMessage = {};

  if ($stateParams.previousSuccessAction) {
    $scope.feedbackMessage.success = {
      server: $stateParams.previousSuccessAction + ' successful. Please sign in.',
    };
  }

  if ($stateParams.emailConfirmed === 'true' && !$stateParams.error) {
    $scope.feedbackMessage.success = {
      server: 'Your email has been successfully confirmed. Please sign in.',
    };
  } else if ($stateParams.emailConfirmed === 'true') {
    $scope.feedbackMessage.success = {
      server: 'Your email has already been confirmed. Please sign in.',
    };
  } else if ($stateParams.emailConfirmed === 'false') {
    $scope.feedbackMessage.error = {
      server: $stateParams.error,
    };
  } else if ($stateParams.advisorNewAccount === 'true') {
    $scope.advisorNewAccount = true;
  } else if ($stateParams.sessionExpired) {
    $scope.feedbackMessage.info = {
      sessionExpired: true,
    };
  }

  function onUserSignedIn(user) {
    var promises = [
      User.currentUser(),
      KycUpdate.find({
        userId: user.id,
        completed: false,
      }),
    ];

    $q.all(promises)
      .then(function (results) {
        var currentUser = results[0];
        var kyc = results[1];

        return navigationService.onUserSignedIn(currentUser, currentUser?.accounts(), kyc);
      })
      .finally(function () {
        $scope.$close();
        $analytics.setUsername($scope.email());
        $analytics.eventTrack('signed-in');
      });
  }

  function handleCurrentUser(currentUser) {
    if (currentUser.email === $scope.email()) {
      // same user is logged in
      onUserSignedIn(currentUser);
      return $q.reject();
    } else {
      // different user wants to log in
      return $rootScope.otherUserLoggedInModal(currentUser.fullName).result.then(function (result) {
        if (result === 'sign-in') {
          return Auth.logout();
        }
      });
    }
  }

  $scope.send2faCodeVia = function (method) {
    $scope.feedbackMessage = {};
    twoFactorAuthenticator
      .sendCodeForLogin($scope.email(), $scope.password(), method)
      .then(function (result) {
        $scope.feedbackMessage = result;
      });
  };

  function switchTo2faView() {
    loadingIndicator.show();
    twoFactorAuthenticator
      .getSanitizedDetailsForLogin($scope.email(), $scope.password())
      .then(function (result) {
        if (result.data) {
          if (result.data.contactMethod === 'text' || result.data.contactMethod === 'call') {
            $scope.usePrimary = true;
            $scope.phoneNumber = result.data.phoneNumber;
            $scope.contactMethod = result.data.contactMethod;
            twoFactorAuthenticator.sendCodeForLogin($scope.email(), $scope.password());
            $scope.state = 'two-factor-authentication';
          } else if (result.data.contactMethod === 'AuthApp') {
            $scope.state = 'two-factor-authentication-with-authenticator-app';
          } else {
            twoFactorAuthenticator.sendCodeForLogin($scope.email(), $scope.password(), 'email');
            $scope.useFallback = true;
            $scope.contactMethod = result.data.contactMethod;
            $scope.state = 'fallback-two-factor-authentication';
          }
          enterKeyHandler.setupEnterKeyHandler($scope, '2fa-code-input-box', $scope.signin);
        }
      })
      .catch(function (error) {
        $scope.feedbackMessage.error = {
          server: error.data.error,
        };
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  }

  $scope.signin = function () {
    $scope.feedbackMessage = {};
    manageUserCookie.setCookie();

    loadingIndicator.show(null, 'sign-in');
    userSessionService
      .getSessionStatus()
      .then(function (result) {
        var currentUser = result.data || {};
        if (currentUser.userId) {
          return handleCurrentUser(currentUser);
        }
      })
      .then(function () {
        return Auth.login({
          email: $scope.email(),
          password: $scope.password(),
          otpAttempt: $scope.code() || $scope.otpCode(),
          rememberDevice: $scope.rememberDevice(),
          appVersion: 'web-1.0.0',
        });
      })
      .then(onUserSignedIn)
      .catch(function (result) {
        if (result && result.data) {
          if (result.data.error === 'Need two factor authentication code') {
            switchTo2faView();
          } else {
            $scope.feedbackMessage.error = {
              server: result.data.error,
            };
          }
        }
      })
      .finally(function () {
        loadingIndicator.hide('sign-in');
      });
  };

  $scope.sendReconfirmation = function (email) {
    $scope.feedbackMessage = {};
    $http
      .post('/users/confirmation', {
        user: {
          email,
        },
      })
      .then(function () {
        $scope.feedbackMessage.success = {
          server: 'Sent confirmation email successfully. Please check your email.',
        };
      });
  };
}
