'use strict';

class BeneficiaryParamsGenerator {
  getAdvisorBeneficiaryFormParams(userId, accountFlowId, personalRelationId, addressSameAsId) {
    const params = {
      userId,
      advisorCreateNewAccountFlowId: accountFlowId,
    };
    if (personalRelationId) {
      params.personalRelationId = personalRelationId;
    } else {
      // homeAddress needs to be initialized with empty string to make the
      // AddressComplete work
      params.personalRelation = {
        relative: {
          homeAddress: {
            streetAddress: '',
            unit: '',
            locality: '',
            region: '',
            country: 'CA',
            postalCode: '',
            sameAsId: addressSameAsId,
          },
        },
      };
    }
    return params;
  }
}

BeneficiaryParamsGenerator.$inject = [];

export default BeneficiaryParamsGenerator;
