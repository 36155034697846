'use strict';

angular
  .module('controller.advise.add-client-account', [
    'config',
    'directive.account-goal-detail',
    'model.Account',
    'model.AdvisorCreateNewAccountFlow',
    'model.AccountFlowJointApplicant',
    'model.Goal',
    'model.InvestmentManagementAgreement',
    'service.form-helper',
    'service.securityService',
  ])
  .controller('AdviseAddClientAccountCtrl', [
    '$scope',
    'config',
    'Account',
    'AdvisorCreateNewAccountFlow',
    'AccountFlowJointApplicant',
    'InvestmentManagementAgreement',
    'newAccountFlows',
    'client',
    'formHelper',
    'ima',
    'userNotifications',
    'securityServiceFactory',
    'currentUser',
    'advisorDetails',
    adviseClientController,
  ]);

function adviseClientController(
  $scope,
  config,
  Account,
  AdvisorCreateNewAccountFlow,
  AccountFlowJointApplicant,
  InvestmentManagementAgreement,
  newAccountFlows,
  client,
  formHelper,
  ima,
  userNotifications,
  securityServiceFactory,
  currentUser,
  advisorDetails
) {
  const defaultPortfolioOption = advisorDetails.defaultPortfolioOption;
  const currentUserSecurity = securityServiceFactory.create(currentUser);
  const needsDefaultPortfolioOption =
    !currentUserSecurity.canSeePortfolioOption() || !currentUserSecurity.canEditPortfolioOption();
  const fallbackPortfolioOption = advisorDetails.fallbackPortfolioOption;
  const successMsg = 'The new account application has been sent to the client successfully.';
  const errorMsg =
    'Something went wrong while sending the new account application to the client. Please try again later.';

  angular.extend($scope, {
    newAccountFlows,
    client,
    ima,
    portfolioOptions: advisorDetails.allowedPortfolioOptions,
    submitForm,
  });
  resetScope();

  function resetScope() {
    $scope.account = new Account();
    $scope.accountFlowJointApplicant = new AccountFlowJointApplicant();

    if (defaultPortfolioOption.id) {
      $scope.account.portfolioOption(defaultPortfolioOption);
      $scope.account.portfolioOptionId(defaultPortfolioOption.id);
    } else if ($scope.portfolioOptions.length === 1) {
      $scope.account.portfolioOption($scope.portfolioOptions[0]);
      $scope.account.portfolioOptionId($scope.portfolioOptions[0].id);
    } else if (needsDefaultPortfolioOption) {
      // Must use the fallback as we don't have a PM.  Distinct from app/assets/javascripts/controller/advise/client-application/account.js
      $scope.account.portfolioOption(fallbackPortfolioOption);
      $scope.account.portfolioOptionId(fallbackPortfolioOption.id);
    }
  }

  function doSave(formController) {
    $scope.isSaving = true;

    const account = $scope.account;
    const accountFlowOptions = {
      accountTypeId: account.typeId(),
      responsible: account.responsible(),
      clientId: client.person().id,
      jurisdictionId: account.jurisdictionId(),
      portfolioOptionId: account.portfolioOptionId(),
      accountNickname: account.nickname(),
    };

    const advisorCreateNewAccountFlow = new AdvisorCreateNewAccountFlow(accountFlowOptions);

    return advisorCreateNewAccountFlow
      .save()
      .then(function (accountFlow) {
        saveAccountFlowJointApplicant(accountFlow);
      })
      .then(function () {
        formController.$setPristine();
        userNotifications.showSuccess(successMsg);
        resetScope();
      })
      .catch(function () {
        userNotifications.showError(errorMsg);
      })
      .finally(function () {
        $scope.isSaving = false;
      });
  }

  function saveAccountFlowJointApplicant(accountFlow) {
    if ($scope.account.type().joint) {
      $scope.accountFlowJointApplicant.advisorCreateNewAccountFlowId(accountFlow.id);
      $scope.accountFlowJointApplicant.save();
    }
  }

  function submitForm(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      doSave(formController);
    }
  }
}
