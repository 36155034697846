'use strict';

function xirrFactory(newtonMethodSolver) {
  function calculateYears(startDate, endDate) {
    return moment(endDate).diff(moment(startDate), 'days') / 365;
  }

  /**
   * Calculates the net present value.
   *
   * @param  {Number} rate      The rate of return
   * @param  {Array}  cashFlows The cash flows
   * @return {Number}           The net present value
   */
  function npv(rate, cashFlows) {
    return _.reduce(
      cashFlows,
      function (npvResult, cashFlow) {
        var year = calculateYears(_.first(cashFlows).date, cashFlow.date);

        return npvResult + cashFlow.amount / Math.pow(1 + rate, year);
      },
      0
    );
  }

  // based on the fact that:
  //
  // f(x) = C / (1 + x)^n
  // f'(x) = (C * -n) / (1 + x)^(n + 1)
  //
  function npvFirstDerivative(rate, cashFlows) {
    return _.reduce(
      cashFlows,
      function (npvDerivativeResult, cashFlow) {
        var year = calculateYears(_.first(cashFlows).date, cashFlow.date);

        return npvDerivativeResult + (cashFlow.amount * -year) / Math.pow(1 + rate, year + 1);
      },
      0
    );
  }

  /**
   * Calculates the inital rate (guess) for use in xirr calculations.
   *
   * Equation:
   *   Initial rate = ((End balance + All withdrawals) / All contributions - 1) / years
   *
   * @param  {Array} cashFlows  Cash Flows containing amounts and dates
   * @return {Number}           An initial rate estimated from the cash flows
   */
  function initialRate(cashFlows) {
    var allWithdrawals = _.chain(_.initial(cashFlows))
      .filter(function (cashFlow) {
        return cashFlow.amount > 0;
      })
      .reduce(function (allWithdrawals, cashFlow) {
        return allWithdrawals + cashFlow.amount;
      }, 0)
      .value();

    var allContributions = _.chain(_.initial(cashFlows))
      .filter(function (cashFlow) {
        return cashFlow.amount < 0;
      })
      .reduce(function (allContributions, cashFlow) {
        return allContributions + -cashFlow.amount;
      }, 0)
      .value();

    var years = calculateYears(_.first(cashFlows).date, _.last(cashFlows).date);

    return ((_.last(cashFlows).amount + allWithdrawals) / allContributions - 1) / years;
  }

  function isHundredPercentLoss(cashFlows) {
    var noWithdrawls = !_.find(cashFlows, function (cashFlow) {
      return cashFlow.amount > 0;
    });

    if (_.last(cashFlows).amount === 0 && noWithdrawls) {
      return true;
    }

    return false;
  }

  function isZeroPercentReturn(cashFlows) {
    return _.every(cashFlows, function (cashFlow) {
      return cashFlow.amount === 0;
    });
  }

  function hasPositiveAndNegative(cashFlows) {
    var hasPositive = _.find(cashFlows, function (cashFlow) {
      return cashFlow.amount > 0;
    });

    var hasNegative = _.find(cashFlows, function (cashFlow) {
      return cashFlow.amount < 0;
    });

    return hasPositive && hasNegative;
  }

  function unannualize(cashFlows, annualizedResult) {
    var firstDeposit = _.find(cashFlows, function (cashFlow) {
      return cashFlow.amount !== 0;
    });

    var startDate =
      _.first(cashFlows).date >= firstDeposit.date ? _.first(cashFlows).date : firstDeposit.date;

    return Math.pow(1 + annualizedResult, calculateYears(startDate, _.last(cashFlows).date)) - 1;
  }

  /**
   * Calculates the annualized money-weighted rate of return based on irregular
   * cashflow periods.
   *
   * If annualized option is set to false, it will calculate the money-weighted
   * rate of return for the period given but it will not be annualized.
   *
   * The resulting percentage is rounded to four decimal places.
   * (ex. 0.1492 is the returned value, which is 14.92%)
   *
   * @param  {Array}   cashFlows  Cash Flows containing amounts and dates
   * @param  {Object}  options    Options to customize function
   * @return {Number}             The money-weighted rate of return
   */
  function xirr(cashFlows, options) {
    var xirrResult;
    var fx;
    var fxPrime;

    options = options || {};
    _.defaults(options, {
      annualized: true,
    });

    if (!cashFlows) {
      throw new Error('Cash flows are undefined.');
    } else if (isZeroPercentReturn(cashFlows)) {
      return 0;
    } else if (isHundredPercentLoss(cashFlows)) {
      return -1;
    } else if (!hasPositiveAndNegative(cashFlows)) {
      throw new Error('Invalid cash flows.');
    }

    fx = function (x) {
      return npv(x, cashFlows);
    };

    fxPrime = function (x) {
      return npvFirstDerivative(x, cashFlows);
    };

    xirrResult = newtonMethodSolver(initialRate(cashFlows), fx, fxPrime);

    if (!options.annualized) {
      xirrResult = unannualize(cashFlows, xirrResult);
    }

    return Number(xirrResult.toFixed(4));
  }

  return xirr;
}

angular
  .module('service.xirr', ['service.newton-method-solver'])
  .factory('xirr', ['newtonMethodSolver', xirrFactory]);
