'use strict';

angular.module('directive.close-button', []).directive('closeButton', [closeButtonDirective]);

function closeButtonDirective() {
  return {
    replace: true,
    restrict: 'E',
    templateUrl: 'directives/close-button.html',
  };
}
