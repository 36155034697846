'use strict';

function resolveCurrentUserPerson(currentUser) {
  return currentUser.person.ready();
}

function resolveCurrentUserSpousalRelation(person) {
  return person.spouseRelation.ready();
}

function resolveCurrentUserSpouse(spousalRelation) {
  return spousalRelation[0].person.ready();
}

function resolveAccount(currentUser, $stateParams) {
  var accountId = $stateParams.accountId;
  return _.find(currentUser.accounts(), function (account) {
    return account.id === parseInt(accountId);
  });
}

function resolveBankAccounts(currentUser) {
  return currentUser && currentUser.bankAccounts.ready();
}

function resolveProjections(account) {
  if (!account) {
    return [];
  }

  return account
    .accountGoals()[0]
    .modelPortfolio.ready()
    .then(function (mp) {
      return mp.projections.ready();
    });
}

function configRoutes($stateProvider, adaptiveViewsProvider) {
  $stateProvider
    .state('advice-centre', {
      url: '/advice-centre',
      data: {
        menuTitle: 'Advice Centre',
      },
      parent: 'site',
      views: {
        main: {
          controller: 'adviceCentreCtrl',
        },
        navbar: {
          templateUrl: adaptiveViewsProvider.getTemplateUrlForSrc(
            'navbar/advice-center.html',
            true
          ),
        },
      },
      abstract: true,
    })
    .state('advice-centre.all-messages', {
      url: '/all-messages',
      data: {
        menuTitle: 'Advice Centre',
      },
      controller: 'adviceCentreAllMessagesCtrl',
    })
    .state('advice-centre.account-off-track', {
      url: '/account-off-track/:accountId',
      resolve: {
        account: ['currentUser', '$stateParams', resolveAccount],
        projections: ['account', resolveProjections],
        bankAccounts: ['currentUser', resolveBankAccounts],
      },
      data: {
        menuTitle: 'Advice Centre',
      },
      controller: 'adviceCentreAccountOffTrackCtrl',
    })
    .state('advice-centre.account-off-track-transfer-created', {
      url: '/account-off-track-transfer-created',
      data: {
        menuTitle: 'Advice Centre',
      },
    })
    .state('advice-centre.spousal-rrsp', {
      url: '/spousal-rrsp',
      controller: 'AdviceCentreSpousalRRSPCtrl',
      data: {
        menuTitle: 'Save with Spousal RRSP',
      },
      resolve: {
        person: ['currentUser', resolveCurrentUserPerson],
        spousalRelation: ['person', resolveCurrentUserSpousalRelation],
        spouse: ['spousalRelation', resolveCurrentUserSpouse],
      },
      abstract: true,
    })
    .state('advice-centre.spousal-rrsp.step-1', {
      url: '/step-1',
    })
    .state('advice-centre.spousal-rrsp.schedule-call', {
      url: '/schedule-call',
      data: {
        previousState: 'advice-centre.spousal-rrsp.step-1',
      },
    })
    .state('advice-centre.spousal-rrsp.end', {
      url: '/end',
      data: {
        previousState: 'advice-centre.spousal-rrsp.step-1',
      },
    });
}

angular
  .module('routes.advice-centre', [
    'controller.advice-centre',
    'controller.advice-centre.spousal-rrsp',
    'service.adaptive-views',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', configRoutes]);
