'use strict';

angular
  .module('controller.advise.client', [
    'controller.advise.client.prepare-documents',
    'controller.advise.client.identity-verification',
    'controller.advise.client.view',
  ])
  .controller('AdviseClientCtrl', [
    '$rootScope',
    '$scope',
    '$http',
    'client',
    'clientRelationshipManagerInfo',
    'identityVerificationStatus',
    'pendingAdvisorInfo',
    'userNotifications',
    adviseClientController,
  ])
  .controller('AdvisePersonalInfoChangeController', ['$scope', advisePersonalInfoChangeController]);

function adviseClientController(
  $rootScope,
  $scope,
  $http,
  client,
  clientRelationshipManagerInfo,
  identityVerificationStatus,
  pendingAdvisorInfo,
  userNotifications
) {
  $scope.client = client;
  $scope.person = client.person();
  $scope.identityVerificationStatus = identityVerificationStatus;
  $rootScope.pendingAdvisor = pendingAdvisorInfo;
  $scope.today = moment().toDate();
  $scope.showTheAlert = true;

  $scope.accordionState = [];
  $scope.accordionHasData = [];
  $scope.targetAmount = [];
  $scope.initial = [];
  $scope.withdrawals = [];

  function line(account, property) {
    return Math.abs(account.accountGoals()[0].goal()[property]())?.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  client.accounts().forEach((account) => {
    $scope.initial[account.id] = line(account, 'initial');
    $scope.withdrawals[account.id] = line(account, 'ongoing');
    $scope.targetAmount[account.id] = line(account, 'targetAmount');
    $scope.accordionState[account.id] = false;
    $scope.accordionHasData[account.id] =
      account.nickname() ||
      account.accountGoals()[0].goal().initial() ||
      account.accountGoals()[0].goal().ongoing() ||
      account.accountGoals()[0].goal().targetAmount() !== null;
  });

  $scope.showDetails = function (account) {
    $scope.accordionState[account.id] = !$scope.accordionState[account.id];
  };

  $scope.showDetail = function (dataType, account) {
    switch (dataType) {
      case 'nickname':
        return !!account.nickname();
      case 'initialInvestment':
        return !!account.accountGoals()[0].goal().initial();
      case 'expectedWithdrawal':
        return !!account.accountGoals()[0].goal().ongoing();
      case 'targetAmount':
        return !!account.accountGoals()[0].goal().targetAmount();
      default:
        return false;
    }
  };
  fetchKycUpdate();
  fetchReminder();

  function fetchReminder() {
    const kycReminder = $scope.client.sentReminderNotification();
    $scope.kycReminderLastSent = kycReminder
      ? moment(kycReminder.createdAt).toDate().toLocaleDateString('en-CA')
      : '';
  }

  function asDateTime(date) {
    return moment(date).toDate();
  }

  function asDate(date) {
    return asDateTime(asDateTime(date).toISOString().split('T')[0]);
  }

  $scope.formatDate = function (date) {
    if (!date) {
      return '';
    }

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    return dateTimeFormat.format(moment(date).toDate());
  };

  $scope.shouldShowTheAlert = function () {
    return (
      $scope.showTheAlert &&
      $scope.kycUpdate &&
      ($scope.kycReminderLastSent === '' ||
        asDate($scope.kycReminderLastSent) < asDate($scope.today)) &&
      (asDateTime($scope.kycUpdate.dueDate) <= $scope.today ||
        asDateTime($scope.kycUpdate.deadline) <= $scope.today)
    );
  };

  $scope.hideTheAlert = function () {
    $scope.showTheAlert = false;
  };

  function fetchKycUpdate() {
    if ($scope.kycUpdate) {
      return;
    }
    $http.get(`/api/kyc_updates/${$scope.client.kycUpdateId()}.json`).then((response) => {
      $scope.kycUpdate = response.data;
    });
  }

  $scope.resendKycUpdateReminder = function () {
    $http
      .post('/api/kyc_updates/' + $scope.kycUpdate.id + '/send_reminder.json')
      .then((response) => {
        $scope.kycReminderLastSent = new Date(response.data.createdAt).toLocaleDateString('en-CA');
        userNotifications.showTransientSuccess('Reminder email was sent successfully.');
      });
  };

  $scope.saveSensitiveInfo = function () {
    const secondaryEmailsData = $scope.secondaryEmails().map(function (item) {
      return {
        id: item.id,
        email: item.email(),
      };
    });

    return $http.post('/api/people/' + client.person().id + '/advisor_sensitive_info_update', {
      firstName: $scope.person.firstName(),
      middleName: $scope.person.middleName(),
      lastName: $scope.person.lastName(),
      sin: $scope.person.sin(),
      email: $scope.client.email(),
      secondaryEmail: secondaryEmailsData,
      birthDate: $scope.person.birthDate(),
      employer: $scope.person.employer(),
      occupation: $scope.person.occupation(),
      homeAddress: $scope.person.homeAddress() ? $scope.person.homeAddress().toJSON() : null,
      mailingAddress: $scope.person.mailingAddress()
        ? $scope.person.mailingAddress().toJSON()
        : null,
      pro: $scope.person.pro(),
    });
  };

  $scope.canCloseProfile = function (client) {
    return (
      !client.deactivatedAt() &&
      _.all(client.accounts(), function (account) {
        return account.status.is.closed();
      })
    );
  };

  $scope.closeProfile = function (client) {
    /* jshint quotmark:false */
    var modalBody = s.sprintf(
      "Are you sure you want to de-activate %s's profile?<br>The client will not be able to log in after this. ",
      client.fullName()
    );
    var title = s.sprintf("Closing %s's profile", client.fullName());
    /* jshint quotmark:single */

    $scope.genericConfirmationModal(title, modalBody).result.then(function () {
      return client.closeProfile();
    });
  };

  $scope.relationshipManager = function () {
    return clientRelationshipManagerInfo.primaryRelationshipManager;
  };

  $scope.institution = function () {
    return clientRelationshipManagerInfo.institution;
  };

  $scope.relationshipManagerUserId = function () {
    return clientRelationshipManagerInfo.primaryRelationshipManagerUserId;
  };

  $scope.reloadIdentityVerificationStatus = function (personId) {
    return $http
      .get('/api/advisor/people/' + personId + '/identity_verification_status.json')
      .then(function (response) {
        $scope.identityVerificationStatus = response.data;
      });
  };

  $scope.pendingServiceLevel = function () {
    if (!$rootScope.pendingAdvisor || !$rootScope.pendingAdvisor.serviceLevel) {
      return '';
    }

    return `${$rootScope.pendingAdvisor.serviceLevel}`;
  };
}

function advisePersonalInfoChangeController($scope) {
  /* jshint validthis: true */
  function reload() {
    var person = $scope.person || $scope.client.person();
    return person.fetch(true) && $scope.client.fetch(true);
  }

  this.save = function () {
    return $scope.saveSensitiveInfo().then(function () {
      return reload();
    });
  };
  this.reset = function () {
    return reload();
  };
}
