'use strict';

angular
  .module('model.Order', [
    'ram',
    'config',
    'model.Trade',
    'model.TradingEngineResult',
    'model.Allotment',
    'model.Custodian',
  ])
  .factory('Order', ['ram', 'config', 'Custodian', orderFactory]);

function orderFactory(ram, config, Custodian) {
  const custodians = {
    1: 'CSI',
    2: 'BBS',
  };

  /**
   * Constructor for Application models.
   * @class
   */
  var Order = new ram.Collection('Order', {
    accessors: ['validTradeTickets'],
    enums: {
      status: config.types.OrderStatus,
    },
    hasMany: {
      trades: 'Trade',
      tradingEngineResults: 'TradingEngineResult',
    },
    schema: config.schemas.Order,
    resources: {
      default: new ram.resources.Http('/api/orders/:id.json'),
      cookie: new ram.resources.Cookie('orders'),
    },
  });

  /**
   * Convert createdAt to a date.
   *
   * @param  {Object} attrs Properties used to initialize this model.
   */
  Order.prototype.initialize = function (attrs) {
    this.createdAt(new Date(attrs.createdAt));
  };

  Order.prototype.tradesFilled = function () {
    return _.all(this.trades(), function (trade) {
      var filled = trade.filled();
      return _.isFinite(filled);
    });
  };

  Order.prototype.tradeTicketDisplayString = function () {
    return this.validTradeTickets()
      .map((custodianId) => Custodian.codeFor(custodianId))
      .sort()
      .join(', ');
  };

  Order.prototype.hasAllTradeTickets = function () {
    const today = moment().startOf('day').toDate();
    if (this.createdAt() >= today) {
      // Comparing date-time to date, so `>` uses the time part.
      return true;
    }

    return Object.keys(custodians).every((custodianId) =>
      this.validTradeTickets().includes(parseInt(custodianId))
    );
  };

  return Order;
}
