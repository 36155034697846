'use strict';

import { useRef, useState } from 'react';

const useFilePicker = (
  csrfTokenFunc,
  api,
  onload,
  onerror,
  setIsProcessing,
  resetUxCallback = null
) => {
  const filePicker = useRef();
  const [fileName, setFileName] = useState('No File');
  const [fileStatus, setFileStatus] = useState('No File');

  function filePicked() {
    setFileStatus(undefined);
    setFileName(undefined);
    if (resetUxCallback) {
      resetUxCallback();
    }

    const newFile = filePicker.current?.files[0];
    setFileName(newFile?.name);
    if (newFile?.name && _validateFile(newFile)) {
      setFileStatus(`Successfully loaded file! (${newFile.size} bytes)`);
      _process();
    }
  }

  function _process() {
    setIsProcessing(true);
    const formData = _generateFormData();
    const request = new XMLHttpRequest();
    request.open('POST', api);
    request.setRequestHeader('X-CSRF-Token', csrfTokenFunc());
    request.onload = _onload.bind(null, request);
    request.onerror = _onerror;
    request.send(formData);
  }

  function _onload(request) {
    const result = JSON.parse(request.response);
    if (request.status === 200) {
      setIsProcessing(false);
      onload({
        success: true,
        result,
        resetFileUx,
      });
    } else {
      onload({
        success: false,
        result,
        resetFileUx,
      });
      setIsProcessing(false);
    }
  }

  function _onerror() {
    onerror({
      resetFileUx,
    });
    setIsProcessing(false);
  }

  function resetFileUx(success, status) {
    if (!success) {
      setFileName(null);
    }
    setFileStatus(status);
    filePicker.current.value = '';
  }

  function _validateFile(file) {
    if (!file) {
      setFileStatus('No file selected.');
      setFileName('Please select your file.');
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      setFileStatus('file is too big.');
      return false;
    }
    return true;
  }

  function _generateFormData() {
    const formData = new FormData();
    const newFile = filePicker.current?.files[0];

    formData.append('file', newFile);
    return formData;
  }

  return {
    filePicker,
    filePicked,
    fileName,
    fileStatus,
  };
};

export default useFilePicker;
