'use strict';

angular
  .module('model.PortfolioTacticalAllocationTarget', [
    'ram',
    'config',
    'model.PortfolioTacticalTarget',
  ])
  .factory('PortfolioTacticalAllocationTarget', ['ram', 'config', factory]);

function factory(ram, config) {
  var PortfolioTacticalAllocationTarget = new ram.Collection('PortfolioTacticalAllocationTarget', {
    hasOne: {
      portfolioTacticalTarget: 'PortfolioTacticalTarget',
      allocation: 'Allocation',
    },
    resources: {
      default: new ram.resources.Http('/api/portfolio_tactical_allocation_targets/:id.json'),
      cookie: new ram.resources.Cookie('portfolio_tactical_allocation_targets'),
    },
    schema: config.schemas.PortfolioTacticalAllocationTarget,
  });

  return PortfolioTacticalAllocationTarget;
}
