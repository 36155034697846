'use strict';

angular
  .module('controller.advisor-new-client.goodbye', [])
  .controller('AdvisorNewClientGoodbyeController', [
    '$scope',
    '$stateParams',
    'details',
    advisorNewClientGoodbyeController,
  ]);

function advisorNewClientGoodbyeController($scope, $stateParams, details) {
  $scope.$root.branding = details.branding;
  $scope.deleted = $stateParams.deleted;
}
