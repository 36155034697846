'use strict';

function factory() {
  return {
    timeFromNow: function (d) {
      var now = moment().startOf('month');
      var date = moment(d);
      var months = date.diff(now, 'months') % 12;
      var years = date.diff(now, 'years');
      var fromNow = '';
      if (years === 1) {
        fromNow += '1 year ';
      } else if (years) {
        fromNow += years + ' years ';
      }
      if (months === 1) {
        fromNow += '1 month ';
      } else if (months) {
        fromNow += months + ' months ';
      }
      if (months || years) {
        fromNow += 'from now';
      }
      return fromNow;
    },
  };
}

angular.module('service.format', []).factory('format', [factory]);
