'use strict';
import React, { useState } from 'react';
import generateReportWorker from './generate-report-worker';

const ClientContactListReport = ({
  csrfToken,
  userNotifications,
  isRunning,
  afterGenerateReport,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const reportName = 'Client Contact List Report';
  const params = () => {
    return { name: 'client_contact_list_report' };
  };

  const generateReport = () => {
    generateReportWorker({
      reportName,
      params,
      csrfToken,
      userNotifications,
      afterGenerateReport,
      isRunning,
      setIsSubmitting,
    });
  };

  return (
    <>
      <h4>{reportName}</h4>
      <div className="float-r">
        <button
          type="submit"
          onClick={generateReport}
          disabled={isSubmitting}
          className="btn btn-success m-t-10 m-r-15"
        >
          {!isSubmitting && <span>Generate</span>}
          {isSubmitting && (
            <span>
              <img className="btn-icon" src="assets/images/loading-spinner.svg" /> Generating
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default ClientContactListReport;
