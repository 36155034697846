'use strict';

var luhnArr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

var luhnChk = function (ccNum) {
  var len = ccNum.length;
  var bit = 1;
  var sum = 0;
  var val;

  while (len) {
    val = parseInt(ccNum.charAt(--len), 10);
    // eslint-disable-next-line no-cond-assign
    sum += (bit ^= 1) ? luhnArr[val] : val;
  }

  return sum && sum % 10 === 0;
};

function luhnDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $el, attrs, ctrl) {
      ctrl.$validators.luhn = function (modelValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }
        return luhnChk(modelValue.replace(/[^\d]/g, ''));
      };
    },
  };
}

angular.module('directive.luhn', []).directive('luhn', [luhnDirective]);
