'use strict';

function rrspCampaignController(
  $scope,
  $filter,
  question,
  user,
  goal,
  rrspCampaignNumberOfAccounts
) {
  $scope.user = user;
  $scope.goal = goal;
  $scope.question = question;

  if (!goal.preset()) {
    question.choices.ready().then(function (choices) {
      goal.preset(choices[1].value());
      goal.save();
    });
  }

  $scope.charityGoalAmount = 20000;
  $scope.amountRaised = 5000 + rrspCampaignNumberOfAccounts * 100;
  $scope.percentDone = ($scope.amountRaised / $scope.charityGoalAmount) * 100;
  $scope.minAmountLabel = '$0';
  $scope.maxAmountLabel = $filter('currency')($scope.charityGoalAmount, '$', 0);
  $scope.amountRaisedLabel = $filter('currency')($scope.amountRaised, '$', 0);

  //= ========= Social Media Icons ============================================//

  var urlEncode = function (url) {
    return encodeURIComponent(url);
  };

  var shareUrl = 'www.ModernAdvisor.ca/save4refugeechildren';

  $scope.emailShare = function () {
    var emailSubject = 'Support a good cause';
    var emailBodyOne =
      'In collaboration with Save the Children Canada, ModernAdvisor is supporting Syrian refugee children and their families. You can help them reach more refugees by opening an RRSP with ModernAdvisor, or transferring your existing one to them.';
    var newLinePlusSpace = '%0A%0A';
    var emailBodyTwo = 'Learn more here: www.modernadvisor.ca/save4refugeechildren';

    return (
      'mailto:' +
      '?subject=' +
      urlEncode(emailSubject) +
      '&body=' +
      urlEncode(emailBodyOne) +
      newLinePlusSpace +
      urlEncode(emailBodyTwo)
    );
  };

  $scope.twitterShare = function () {
    var tweetContent =
      'I’m supporting refugee children in Syria by investing my #RRSP @ModernAdvsr. www.modernadvisor.ca/save4refugeechildren #Save4RefugeeChildren @SaveChildrenCan';
    var url =
      'http://twitter.com/share?text=' + urlEncode(tweetContent) + '&url=' + urlEncode(shareUrl);

    window.open(
      url,
      'Share',
      'height=400,width=500,left=200,top=200,chrome=yes,centerscreen=yes,dialog=yes,modal=yes,alwaysRaised=yes'
    );
  };

  $scope.fbShare = function () {
    window.FB.ui({
      method: 'feed',
      display: 'popup',
      picture: 'https://s3-us-west-2.amazonaws.com/modernadvisor-public/Save4Refugees.png',
      name: "Help someone else's future by saving for yours",
      caption: 'modernadvisor.ca',
      description:
        'With your help ModernAdvisor is aiming to donate $20,000 to Save the Children charity to support Syrian refugee children and their families.',
      link: shareUrl,
    });
  };

  //= ========= Social Media Icons End ========================================//
}

angular
  .module('controller.marketing.rrsp-campaign', [
    'ui.bootstrap',
    'directive.input',
    'directive.countdown',
    'directive.progress-bar',
    'directive.open-account-lead',
  ])
  .controller('marketingRrspCampaignCtrl', [
    '$scope',
    '$filter',
    'presetQuestion',
    'storedUser',
    'storedGoal',
    'rrspCampaignNumberOfAccounts',
    rrspCampaignController,
  ]);
