'use strict';

/**
 * Creates a return percentage legend for historical charts based
 * on the performance.
 *
 * If it is unable to calculate a return percentage, it will display
 * a dash in place of the percentage.
 *
 * @return {Object} directive definition
 */
function historicalLegendDirective() {
  return {
    restrict: 'E',
    templateUrl: 'directives/historical-legend.html',
    scope: true,
    link: function (scope) {
      scope.$watch('performance', function (performance) {
        if (performance) {
          var sortedPerformance = _.sortBy(performance, 'date');
          var dateToday = _.last(sortedPerformance).date;
          var performanceToday = _.last(sortedPerformance).value;

          var getStartPerformance = function (startDate) {
            for (var i = 0; i < sortedPerformance.length; i++) {
              if (sortedPerformance[i].date.getTime() === startDate.getTime()) {
                return sortedPerformance[i].value;
              } else if (sortedPerformance[i].date.getTime() > startDate.getTime()) {
                if (sortedPerformance[i - 1]) {
                  return sortedPerformance[i - 1].value;
                } else {
                  return 0;
                }
              }
            }
          };

          var performanceAtStartOfYear = getStartPerformance(
            new Date(dateToday.getFullYear() + '-01-01T00:00:00.000Z')
          );
          var performanceOneYearAgo = getStartPerformance(
            moment(dateToday).subtract(1, 'years').toDate()
          );
          var performanceThreeYearsAgo = getStartPerformance(
            moment(dateToday).subtract(3, 'years').toDate()
          );

          if (performanceAtStartOfYear) {
            scope.ytd =
              (
                ((performanceToday - performanceAtStartOfYear) / performanceAtStartOfYear) *
                100
              ).toFixed(1) + '%';
          } else {
            scope.ytd = '-';
          }

          if (performanceOneYearAgo) {
            scope.oneYear =
              (((performanceToday - performanceOneYearAgo) / performanceOneYearAgo) * 100).toFixed(
                1
              ) + '%';
          } else {
            scope.oneYear = '-';
          }

          if (performanceThreeYearsAgo) {
            scope.threeYear =
              (
                (Math.pow(
                  1 + (performanceToday - performanceThreeYearsAgo) / performanceThreeYearsAgo,
                  1 / 3
                ) -
                  1) *
                100
              ).toFixed(1) + '%';
          } else {
            scope.threeYear = '-';
          }
        } else {
          scope.ytd = '-';
          scope.oneYear = '-';
          scope.threeYear = '-';
        }
      });
    },
  };
}

angular
  .module('directive.historical-legend', [])
  .directive('historicalLegend', [historicalLegendDirective]);
