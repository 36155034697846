'use strict';

class HoldingsDataGenerator {
  constructor(investmentChartsAllocationOrPositionColourMapperAndSorter) {
    this.investmentChartsAllocationOrPositionColourMapperAndSorter =
      investmentChartsAllocationOrPositionColourMapperAndSorter;
  }

  getAggregateData(positions) {
    positions = this._generateAggregatedPositions(positions);
    positions =
      this.investmentChartsAllocationOrPositionColourMapperAndSorter.getColourMappedAndSortedPositions(
        positions
      );

    return positions;
  }

  getNonAggregateData(positions, modelPortfolio) {
    return this.investmentChartsAllocationOrPositionColourMapperAndSorter.getColourMappedAndSortedPositions(
      positions,
      modelPortfolio
    );
  }

  _aggregateMarketValue(positions) {
    var reducer = function (acc, position) {
      return acc + parseFloat(position.marketValue());
    };
    return _.reduce(positions, reducer, 0);
  }

  _aggregateBookValue(positions) {
    var reducer = function (acc, position) {
      return acc + parseFloat(position.bookValue());
    };
    return _.reduce(positions, reducer, 0);
  }

  _aggregateShares(positions) {
    var reducer = function (acc, position) {
      return acc + parseFloat(position.shares());
    };
    return _.reduce(positions, reducer, 0);
  }

  _aggregatePositionsOfSameFund(primitivePositions) {
    var position = {};
    var fund = {};
    var primitiveFund = primitivePositions[0].fund();
    position._marketValue = this._aggregateMarketValue(primitivePositions);
    position.marketValue = function () {
      return this._marketValue;
    }.bind(position); // use bind to fix the lexical scope of 'this'

    position._bookValue = this._aggregateBookValue(primitivePositions);
    position.bookValue = function () {
      return this._bookValue;
    }.bind(position);

    position._shares = this._aggregateShares(primitivePositions);
    position.shares = function () {
      return this._shares;
    }.bind(position);

    fund.label = primitiveFund.label;
    fund.color = primitiveFund.color;
    fund.assetClassId = primitiveFund.assetClassId;
    fund.symbol = primitiveFund.symbol;
    position._fund = fund;
    position.fund = function () {
      return this._fund;
    }.bind(position);
    return position;
  }

  _generateAggregatedPositions(positions) {
    return _.chain(positions)
      .groupBy(function (position) {
        return position.fundId();
      })
      .map(this._aggregatePositionsOfSameFund.bind(this))
      .value();
  }
}

HoldingsDataGenerator.$inject = ['investmentChartsAllocationOrPositionColourMapperAndSorter'];

export default HoldingsDataGenerator;
