'use strict';

function px(value) {
  return value + 'px';
}

function initButton(element) {
  var button = {
    element,
    position: {
      top: 0,
    },
    size: {
      outerWidth: element.outerWidth(),
      originalHeight: element.outerHeight(),
    },
    setTop: function (top) {
      this.position.top = top;
      this.element.css('top', px(top));
    },
    resetSize: function () {
      this.element.animate(
        {
          height: px(button.size.originalHeight),
          right: px(-button.size.originalHeight),
        },
        200,
        'swing'
      );
    },
    expand: function (expandByPx) {
      this.element.animate(
        {
          height: px(button.size.originalHeight + expandByPx),
          right: px(-button.size.originalHeight - expandByPx),
        },
        200,
        'swing'
      );
    },
  };

  button.element
    .bind('mouseenter', function () {
      button.expand(7);
    })
    .bind('mouseleave', function () {
      button.resetSize();
    });

  return button;
}

function initInfo(element) {
  return {
    element,
    setTop: function (top) {
      this.element.css('top', px(top));
    },
  };
}

function needHelpSidebar($scope, wrapper, $window) {
  var topOffset = parseInt($scope.topOffset);

  var button = initButton(wrapper.find('.need-help-button'));
  var info = initInfo(wrapper.find('.need-help-info'));
  var container = wrapper.parents($scope.containerClass);

  var initPositions = function () {
    container.css('position', 'relative');
    button.setTop(topOffset);
  };

  var updatePosition = function (position) {
    button.setTop(position);
    info.setTop(position - button.size.outerWidth);
  };

  var initScrollHandler = function () {
    var scrollHandler = function () {
      var parentHeight = container.height();

      var position = topOffset + $window.pageYOffset;
      if (parentHeight - position < button.size.outerWidth) {
        position = parentHeight - button.size.outerWidth;
      }
      updatePosition(position);
    };

    $window.addEventListener('scroll', scrollHandler);
    $scope.$on('$destroy', function () {
      $window.removeEventListener('scroll', scrollHandler);
    });
  };

  initPositions();
  initScrollHandler();

  return {
    syncInfoPosition: function () {
      updatePosition(button.position.top);
    },
  };
}

function directive($window, $timeout) {
  return {
    replace: true,
    restrict: 'E',
    transclude: true,
    scope: {
      topOffset: '@',
      containerClass: '@',
    },
    templateUrl: 'directives/need-help-sidebar.html',

    link: function ($scope, wrapper) {
      var sidebar = needHelpSidebar($scope, wrapper, $window, $timeout);

      $scope.showHelp = false;

      $scope.toggleHelpWindow = function () {
        if (!$scope.showHelp) {
          sidebar.syncInfoPosition();
        }
        $scope.showHelp = !$scope.showHelp;
      };

      $scope.closeHelpWindow = function () {
        $scope.showHelp = false;
      };
    },
  };
}

angular
  .module('directive.need-help-sidebar', ['ngAnimate'])
  .directive('needHelpSidebar', ['$window', directive]);
