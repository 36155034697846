'use strict';

angular
  .module('controller.interview.personal.end', [])
  .controller('interviewPersonalEndCtrl', [
    '$scope',
    '$state',
    '$window',
    interviewPersonalEndCtrl,
  ]);

function interviewPersonalEndCtrl($scope, $state, $window) {
  $scope.$parent.$parent.nextSection = function () {
    $window.location = $scope.$root.config.env.marketingUrl;
  };
  $scope.$parent.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };

  $scope.$parent.$parent.step = $state.current.data.step;
  $scope.$parent.$parent.header = $state.current.data.header;
  $scope.$parent.$parent.userCanGoBack = false;
}
