'use strict';

angular
  .module('routes.advise.person', ['templates', 'ui.router'])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function resolvePerson(Person, $stateParams) {
    return Person.find({
      id: $stateParams.id,
    });
  }

  function inspectAuthorizeIdentityVerification(authorizer) {
    return authorizer.inspectPermission('identity_verification');
  }

  function resolveIdentityVerificationStatus($http, authorizeIdentityVerification, person) {
    if (!authorizeIdentityVerification) {
      return {};
    }

    return $http
      .get('api/advisor/people/' + person.id + '/identity_verification_status.json')
      .then(function (response) {
        return response.data;
      });
  }

  function resolveEmail($stateParams, person, User) {
    var jointApplicantId = $stateParams.jointApplicantId;

    if (jointApplicantId) {
      return _.find(person.jointApplicants(), function (jointApplicant) {
        return jointApplicant.id === Number(jointApplicantId);
      }).email;
    } else if (person.userId()) {
      return User.find({
        id: person.userId(),
      }).then(function (user) {
        return user.email;
      });
    }
    window.Sentry &&
      window.Sentry.captureMessage('jointApplicantId and person.userId() is missing.');
  }

  function resolvePersonAccounts($stateParams, person, User, Account) {
    var jointApplicantId = $stateParams.jointApplicantId;

    if (jointApplicantId) {
      var jointApplicant = _.find(person.jointApplicants(), function (jointApplicant) {
        return jointApplicant.id === Number(jointApplicantId);
      });

      return Account.find({
        id: jointApplicant.accountId(),
      }).then(function (account) {
        return [account];
      });
    } else if (person.userId()) {
      return User.find({
        id: person.userId(),
      }).then(function (user) {
        return user.accounts();
      });
    }
    window.Sentry &&
      window.Sentry.captureMessage('jointApplicantId and person.userId() is missing.');
  }

  function resolveClientUser($stateParams, personAccounts) {
    if (!$stateParams.jointApplicantId) {
      return;
    }

    return personAccounts[0].user.ready();
  }

  $stateProvider
    .state('advise.person', {
      url: '/person/{id:[0-9]+}?jointApplicantId',
      abstract: true,
      controller: 'AdvisePersonCtrl',
      params: {
        jointApplicantId: null,
      },
      resolve: {
        person: ['Person', '$stateParams', resolvePerson],
        authorizeSuperAdvisor: ['authorizer', inspectAuthorizeIdentityVerification],
        identityVerificationStatus: [
          '$http',
          'authorizeSuperAdvisor',
          'person',
          resolveIdentityVerificationStatus,
        ],
        email: ['$stateParams', 'person', 'User', resolveEmail],
      },
    })
    .state('advise.person.view', {
      url: '',
      controller: 'AdvisePersonViewCtrl',
      resolve: {
        personAccounts: ['$stateParams', 'person', 'User', 'Account', resolvePersonAccounts],
        clientUser: ['$stateParams', 'personAccounts', resolveClientUser],
      },
      templateUrl: 'views/advise/person/view.html',
    })
    .state('advise.person.identity-verification', {
      url: '/identity-verification',
      controller: 'AdvisePersonIdentityVerificationCtrl',
      resolve: {},
      templateUrl: 'views/advise/person/identity-verification.html',
    });
}
