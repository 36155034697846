'use strict';

function combinedAccountFactory(
  $q,
  ram,
  config,
  Account,
  DerivedHasMany,
  Enum,
  combineDollarAmountsOnSameDay,
  accountService
) {
  /**
   * A pseudo-account that represents the combined activity of all of a users
   * real accounts.
   *
   * @param {User} user The account owner.
   * @param accountsAssociation the name of the association to be used. Can be 'accounts' or 'viewableAccounts'.
   * @param id the id of the combined account.
   * @param label the label of the combined account.
   * @class
   */
  function CombinedAccount(user, accountsAssociation, id, label) {
    accountsAssociation = accountsAssociation || 'accounts';

    this._prevAccounts = null;
    this._sortedAccounts = null;
    this._visibleAccounts = null;

    this.accounts = function () {
      // eslint-disable-next-line no-useless-call
      var userAccounts = this.user()[accountsAssociation].call(this.user());
      if (this._prevAccounts !== userAccounts) {
        this._prevAccounts = userAccounts;
        this._sortedAccounts = _.sortBy(userAccounts, function (account) {
          return account.userIsHolder() ? 0 : 1;
        });
        this._visibleAccounts = accountService.getVisibleAccounts(this._sortedAccounts);
      }
      return this._sortedAccounts;
    };

    this.visibleAccounts = function () {
      // ensuring the values are populated.
      this.accounts();
      return this._visibleAccounts;
    };

    this.user = function () {
      return user;
    };

    this.id = id;
    this.title = 'Combined';

    this.label = function () {
      return label;
    };

    this.clientLabel = function () {
      return label;
    };

    this.number = function () {
      return 'N/A';
    };

    this.typeId = function () {
      return null;
    };

    this.type = new Enum(this.typeId, config.types.Account);

    var sortByDate = function (obj) {
      return obj.date().getTime();
    };

    function openAccount(account) {
      return account.status && account.status.is.open();
    }

    this.balances = new DerivedHasMany(
      user,
      accountsAssociation,
      'balances',
      sortByDate,
      openAccount
    );
    this.transfers = new DerivedHasMany(
      user,
      accountsAssociation,
      'transfers',
      sortByDate,
      openAccount
    );
    this.activities = new DerivedHasMany(
      user,
      accountsAssociation,
      'activities',
      sortByDate,
      openAccount
    );
    this.positions = new DerivedHasMany(user, accountsAssociation, 'positions', null, openAccount);
    this.accountTransfers = new DerivedHasMany(user, accountsAssociation, 'accountTransfers');
    this.transferInstructions = new DerivedHasMany(
      user,
      accountsAssociation,
      'transferInstructions'
    );
  }

  /**
   * This method is used by the has many associations to determine how to get
   * the combined accounts foreign key. We always return false so to force using
   * the fk method;
   *
   * @return {Boolean}
   */
  CombinedAccount.prototype.isNew = function () {
    return false;
  };

  /**
   * Use by the has many associations to filter the associated resources. We
   * don't want any filtering so an empty object is returned.
   *
   * @return {Object}
   */
  CombinedAccount.prototype.fk = function () {
    return {};
  };

  CombinedAccount.prototype.isCombinedAccount = function () {
    return true;
  };

  CombinedAccount.prototype.isVisible = function () {
    return true;
  };

  CombinedAccount.prototype.openAccounts = function () {
    return _.filter(this.accounts(), function (account) {
      return account.status.is.open();
    });
  };

  /**
   * Calculates the total cumulative contributions made to each date for which
   * there is a balance. See Account for more info.
   * @method contributionHistory
   */
  CombinedAccount.prototype.contributionHistory = Account.prototype.contributionHistory;
  CombinedAccount.prototype.earningsHistory = Account.prototype.earningsHistory;
  CombinedAccount.prototype.cashFlows = Account.prototype.cashFlows;

  /**
   * Find the balance on the given date of a user's entire holdings.
   * @method balance
   *
   * @param  {Date}   date Date for balance query or undefined for current.
   * @return {Number}      Sum of account balances.
   */
  CombinedAccount.prototype.balance = function (date) {
    return (
      _.reduce(
        this.openAccounts(),
        function (sum, account) {
          return sum + account.balance(date) * 100;
        },
        0
      ) / 100
    );
  };

  function filteredDates(balances) {
    var allDates = _.chain(balances)
      .pluck('date')
      .sortBy(function (date) {
        return moment(date);
      })
      .uniq(function (date) {
        return date().toString();
      })
      .value();
    var startDate = _.first(allDates);
    var endDate = _.last(allDates);

    var lastYear = moment().subtract(1, 'years');
    return _.filter(allDates, function (date) {
      if (date() === startDate() || date() === endDate()) {
        return true;
      }
      var m = moment(date());
      return m.weekday() === 1 || m.isAfter(lastYear); // keep the balance only if it's on a Monday or is more recent than 1 year.
    });
  }

  /**
   * Create the balancesHistoryData.  Called by the router.
   * @return true to kick off promises.
   */
  CombinedAccount.prototype.createBalancesHistory = function () {
    var dates = filteredDates(this.balances());
    var accounts = this.openAccounts();

    var filled = _.chain(dates)
      .map(function (date) {
        return _.map(accounts, function (account) {
          return {
            date: date(),
            amount: account.balance(date()) || 0,
            toDisplayFormat: function () {
              return this;
            },
          };
        });
      })
      .flatten()
      .value();
    this.balancesHistoryData = combineDollarAmountsOnSameDay.process(filled);
    return true;
  };

  /**
   * Accessor to get this object's balancesHistoryData
   * @return {Array} The computed balancesHistoryData.  In the case of this object
   *                 it has been combined by date to give an aggregate result.
   */
  CombinedAccount.prototype.balancesHistory = function () {
    return this.balancesHistoryData;
  };

  /**
   * Create the transfersHistoryData.  Called by the router.
   * @return true to kick off promises.
   */
  CombinedAccount.prototype.createTransfersHistory = function () {
    var transfers = this.transfers();
    this.transfersHistoryData = combineDollarAmountsOnSameDay.process(transfers);
    return true;
  };

  /**
   * Accessor to get this object's transferHistoryData object
   * @return {Array} The computed transfersHistoryData.  In the case of this object
   *                 it has been combined by date to give an aggregate result.
   */
  CombinedAccount.prototype.transfersHistory = function () {
    return this.transfersHistoryData;
  };

  /**
   * Find the total contributions made across all accounts to the given date.
   * @method contributions
   *
   * @param  {Date}   date Date for contribution query or undefined for current.
   * @return {Number}      Sum of all contributions.
   */
  CombinedAccount.prototype.contributions = function (endDate, startDate) {
    return (
      _.reduce(
        this.openAccounts(),
        function (sum, account) {
          return sum + account.contributions(endDate, startDate) * 100;
        },
        0
      ) / 100
    );
  };

  /**
   * Find the total earnings made across all accounts to the given date.
   * @method performance
   *
   * @param  {Date}   date Date for earnings query or undefined for current.
   * @return {Number}      Sum of account earnings.
   */
  CombinedAccount.prototype.performance = function (date) {
    return (
      _.reduce(
        this.openAccounts(),
        function (sum, account) {
          return sum + account.performance(date) * 100;
        },
        0
      ) / 100
    );
  };

  /**
   * Find the total portfolio fees paid across all accounts.
   * @method  fee
   *
   * @return {Number} Fees expressed as a percentage.
   */
  CombinedAccount.prototype.fee = function () {
    var accountsWithFee = _.reject(this.accounts(), function (account) {
      return account.number() === null;
    });
    return (
      _.reduce(
        accountsWithFee,
        function (sum, account) {
          return sum + account.fee() * account.balance();
        },
        0
      ) / this.balance()
    );
  };

  CombinedAccount.prototype.userIsHolder = function () {
    return _.all(this.accounts(), function (account) {
      return account.userIsHolder();
    });
  };

  CombinedAccount.prototype.nonZeroPositions = function () {
    return _.chain(this.accounts())
      .map(function (account) {
        return account.nonZeroPositions();
      })
      .flatten()
      .value();
  };

  return CombinedAccount;
}

angular
  .module('model.CombinedAccount', [
    'model.Account',
    'ram',
    'ram.enum',
    'ram.association.DerivedHasMany',
    'service.combine-dollar-amounts-on-same-day',
    'service.account-service',
  ])
  .factory('CombinedAccount', [
    '$q',
    'ram',
    'config',
    'Account',
    'DerivedHasMany',
    'Enum',
    'combineDollarAmountsOnSameDay',
    'accountService',
    combinedAccountFactory,
  ]);
