'use strict';

function fancyLoadDirective() {
  return {
    templateUrl: 'directives/fancy-load.html',
    templateNamespace: 'svg',
    link: function (scope, el) {
      var logo = d3.select(el.get(0)).select('#logo');
      var n = 0;

      logo.selectAll('g.bar path, #arrow, #frame').style('fill-opacity', 0);

      (function loop() {
        var transition = logo.transition().ease('exp-in-out');

        transition.select('#frame').transition().duration(500).style('fill-opacity', 1);

        transition
          .selectAll('path.bar')
          .transition()
          .duration(1000)
          .delay(function (d, i) {
            return i * 500;
          })
          .each(function () {
            var bar = d3.select(this);
            var coords = _.chain(bar.attr('d').split(' '))
              .map(function (coord) {
                return parseFloat(coord);
              })
              .filter(_.isFinite)
              .value();
            var coord5 = coords[5];
            var coord7 = coords[7];

            var d = function (t) {
              coords[5] = t * coord5;
              coords[7] = t * coord7 + (t - 1) * coords[3];
              return ['m'].concat(coords, ['z']).join(' ');
            };

            bar
              .style('fill-opacity', 1)
              .attr('d', d(0))
              .transition()
              .attrTween('d', function () {
                return d;
              });
          });

        transition
          .select('#arrow')
          .transition()
          .delay(1500)
          .duration(1000)
          .style('fill-opacity', 1);

        // transition.select('#frame')
        //   .transition()
        //   .delay(2500)
        //   .duration(1500)
        //   .style('fill', '#025fa8');

        transition
          .selectAll('path.bar, #arrow, #frame')
          .transition()
          .delay(2500)
          .duration(500)
          .style('fill-opacity', 0)
          .each(function () {
            ++n;
          })
          .each('end', function () {
            if (!--n) {
              loop();
            }
          });
      })();
    },
  };
}

angular.module('directive.fancyLoad', []).directive('fancyLoad', [fancyLoadDirective]);
