'use strict';

/**
 * Iterate over the keys of an object renaming them using a transform function.
 *
 * @param  {Function} transform Function accepting a string as it's only
 *                              argument and returning a new, transformed
 *                              string.
 * @param  {Object} obj         The object with the keys to be transformed.
 * @return {Object}             Object with transformed keys.
 */
function transformKeys(transform, obj) {
  var transformed = {};
  _.each(obj, function (val, key) {
    transformed[transform(key)] = val;
  });
  return transformed;
}

/**
 * Iterate over the keys of an object, converting them to camelcase.
 * @type {Function}
 */
var camelize = _.partial(transformKeys, s.camelize);

/**
 * Iterate over the keys of an object, converting them to underscore notation.
 * @type {Function}
 */
var underscored = _.partial(transformKeys, s.underscored);

/**
 * Cache of initialized resource services.
 * @type {Array}
 */
var resources = [];

/**
 * Search initialized resources for one associated with the specified model.
 *
 * @param  {Function} model Constructor for a model
 * @return {Object}         Resource service associate with model
 */
function findResource(model) {
  return _.find(resources, function (resource) {
    return resource.model === model;
  });
}

/**
 * Converts a JSON list to an object to prevent $resource complaining about
 * the wrong response type.
 *
 * @param  {String} json Serialized response from the server
 * @return {Array}       Deserialized response
 */
function jsonToArray(json) {
  var array = [];
  _.each(angular.fromJson(json), function (record) {
    array.push(camelize(record));
  });
  return array;
}

/**
 * Converts a JSON list to an object to prevent $resource complaining about
 * response being an array.
 *
 * @param  {String} json JSON response from the server
 * @return {Object}      Deserialized response stored in an object
 */
function jsonToObject(json) {
  return _.extend({}, jsonToArray(json));
}

/**
 * Converts a javascript-style camelcase object into a rails-style underscored
 * object including fields specified by the object's toJSON function.
 *
 * @param  {Object} object Object to be serialized
 * @return {String}        Serialized data to send
 */
function objectToJson(object) {
  return angular.toJson(underscored(object.toJSON ? object.toJSON() : object));
}

function createTransform(options) {
  var Model =
    options.model ||
    function (val) {
      return val;
    };

  /**
   * Instantiate a new model using the provided constructor, then initialize
   * the model's associations using resource services, or embedded records, if
   * available.
   *
   * @param  {Object} record  The database record for this object.
   * @return {Model}          The record, after being transformed to a Model.
   */
  function createModel(record) {
    var model = new Model(record);

    // try to get each association using a service, if available
    // otherwise, transform and return the record directly
    _.each(Model.hasOne, function (Sub, name) {
      var service = findResource(Sub);
      if (service) {
        service.get(options.key, function (response) {
          model.name = response;
        });
      } else {
        model[name] = new Sub(record[name]);
      }
    });

    _.each(Model.hasMany, function (Sub, name) {
      var service = findResource(Sub);
      var query = {};
      if (service && Model.instanceName) {
        // should use options.key to derive the query
        query[Model.instanceName + 'Id'] = record.id;
        service.query(query, function (response) {
          model[name] = response;
        });
      } else {
        model[name] = _.map(record[name], function (record) {
          return new Sub(record);
        });
      }
    });

    return model;
  }

  /**
   * Extracts the primary key from a record for use in determining whether this
   * response has already been cached.
   *
   * @param  {Object} record The database record for this object.
   * @return {Object}        Subset of record fields that uniquely identify
   *                         this object.
   */
  function modelHash(record) {
    return _.pick(record, options.key);
  }

  return options.cache ? _.memoize(createModel, modelHash) : createModel;
}

function resourceFactory($resource, config) {
  /**
   * A wrapper around $resource that supports response transformation
   * and automatically converts between camelcase and underscored notation.
   *
   * @param  {String} path    Path of the resource
   * @param  {Object} options Resource options. Currently only supports a
   *                          transform function.
   * @return {Object}         Resource object
   */
  function createResource(path, options) {
    if (config.env.name === 'developement') {
      console.groupCollapsed(
        'Warning: service.resource is deprecated. Please use ram instead.',
        path
      );
      console.trace();
      console.groupEnd();
    }

    options = options || {};
    options.cache = _.has(options, 'cache') ? options.cache : true;
    options.key = options.key || 'id';
    var transform = options.transform || createTransform(options);

    /**
     * When an object response is received, transform and return it.
     *
     * @param  {Object} response Deserialized response from the server
     * @return {Object}          Transformed response
     */
    function interceptObjectResponse(response) {
      // this step is for debugging only
      // in production, the server should only return the requested object
      var record = _.findWhere(response.data, response.config.params);
      return record && transform(record);
    }

    /**
     * When an array response is received, transform each element before
     * returning the array.
     *
     * @param  {Array} response Deserialized array response from the server
     * @return {Array}          Array of transformed response objects
     */
    function interceptArrayResponse(response) {
      return _.chain(response.data).where(response.config.params).map(transform).value();
    }

    var resource = $resource(path, null, {
      get: {
        method: 'GET',
        cache: true,
        transformResponse: jsonToObject,
        interceptor: {
          response: interceptObjectResponse,
        },
      },
      query: {
        method: 'GET',
        cache: true,
        isArray: true,
        transformResponse: jsonToArray,
        interceptor: {
          response: interceptArrayResponse,
        },
      },
      update: {
        method: 'PUT',
        transformRequest: objectToJson,
        params: {
          id: '@id',
        },
      },
    });

    // store a reference to the model associated with this service
    resource.model = options.model;

    // store a reference to this service to use in resolving associations
    if (options.model) {
      resources.push(resource);
    }

    return resource;
  }

  return {
    create: createResource,
    find: findResource,
    camelize,
    underscored,
  };
}

angular
  .module('service.resource', ['ngResource'])
  .factory('resource', ['$resource', 'config', resourceFactory]);
