'use strict';

function tradeFactory(ram, config) {
  /**
   * Constructor for Trade models.
   * @class
   */
  var Trade = new ram.Collection('Trade', {
    belongsTo: {
      fund: 'Fund',
      order: 'Order',
    },
    hasMany: {
      allotments: 'Allotment',
    },
    schema: config.schemas.Trade,
    resources: {
      default: new ram.resources.Http('/api/trades/:id.json'),
      cookie: new ram.resources.Cookie('trades'),
    },
  });

  return Trade;
}

angular
  .module('model.Trade', ['ram', 'config', 'model.Fund'])
  .factory('Trade', ['ram', 'config', tradeFactory]);
