'use strict';

/**
 * Define a directive for a static input field.
 */
function staticDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@',
      text: '@',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      class: '@',
      labelClass: '@',
      inputClass: '@',
      helpClass: '@',
    },
    templateUrl: 'directives/input/static.html',
  };
}

angular
  .module('directive.input.static', ['templates', 'animations', 'directive.focus'])
  .directive('static', [staticDirective]);
