import template from './account-beneficiaries-form.html';
import controller from './account-beneficiaries-form.controller';

const accountBeneficiariesFormComponent = {
  template,
  bindings: {
    account: '<',
    autoSave: '@', // strings ['true', 'false']
    estateMsgPosition: '@', // ['onboarding--inline-with-title', 'prefill--at-top']
    beneficiaries: '<',
    client: '<',
    personalSubforms: '=',
    spouse: '<',
    successorHolder: '<',
    accountFlow: '<',
  },
  controller: [
    '$scope',
    '$http',
    '$q',
    '$timeout',
    'ram',
    'AccountBeneficiary',
    'AdvisorBeneficiaryForm',
    'Beneficiary',
    'Person',
    'PersonalRelation',
    'SuccessorHolder',
    'accountService',
    'beneficiaryService',
    'beneficiaryParamsGenerator',
    'spouseOrPartner',
    controller,
  ],
  controllerAs: '$ctrl',
};

export default accountBeneficiariesFormComponent;
