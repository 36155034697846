'use strict';

angular
  .module('model.FeeCreditTransaction', ['ram', 'model.FeeCreditAccount', 'model.Person'])
  .factory('FeeCreditTransaction', ['ram', 'config', feeCreditTransactionFactory]);

function feeCreditTransactionFactory(ram, config) {
  var FeeCreditTransaction = new ram.Collection('FeeCreditTransaction', {
    belongsTo: {
      feeCreditAccount: 'FeeCreditAccount',
    },
    bind: ['credit', 'debit'],
    resources: {
      default: new ram.resources.Http('/api/fee_credit_transactions/:id.json'),
      cookie: new ram.resources.Cookie('fee_credit_transactions'),
    },
    schema: config.schemas.FeeCreditTransaction,
  });

  FeeCreditTransaction.prototype.credit = function () {
    return this.amount() < 0 ? this.amount() : '';
  };

  FeeCreditTransaction.prototype.debit = function () {
    return this.amount() > 0 ? this.amount() : '';
  };

  return FeeCreditTransaction;
}
