'use strict';

angular
  .module('routes.profile.household', [
    'controller.profile.household',
    'service.adaptive-views',
    'templates',
    'model.Household',
    'model.HouseholdInvitation',
    'ui.router',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', 'config', configRoutes]);

function configRoutes($stateProvider, adaptiveViewsProvider, config) {
  function resolveIsDesktopSafari() {
    return adaptiveViewsProvider.isDesktopSafari();
  }

  function resolveRedirectToState(redirect, householdMembership, pendingHouseholdInvitations) {
    if (householdMembership) {
      redirect.to('profile.household.overview').always();
    } else if (pendingHouseholdInvitations.length > 0) {
      redirect.to('profile.household.invitations').always();
    } else {
      redirect.to('profile.household.create').always();
    }
  }

  function resolvePendingHouseholdInvitations(HouseholdInvitation) {
    return HouseholdInvitation.where(
      {
        statusId: config.types.HouseholdInvitationStatus.findByName('pending').id,
      },
      {
        force: true,
      }
    );
  }

  function resolveHousehold(Household) {
    return Household.where(
      {},
      {
        force: true,
      }
    );
  }

  $stateProvider
    .state('profile.household', {
      url: '/household',
      controller: 'profileHouseholdCtrl',
      templateUrl: 'views/profile/household.html',
      data: {
        menuTitle: 'Household',
        backButtonState: 'profile.nav',
      },
      resolve: {
        currentPerson: [
          'currentUser',
          function (currentUser) {
            return currentUser.person.ready(true);
          },
        ],
        householdMembership: [
          'currentPerson',
          function (currentPerson) {
            return currentPerson.householdMembership.ready(true);
          },
        ],
        pendingHouseholdInvitations: ['HouseholdInvitation', resolvePendingHouseholdInvitations],
        householdList: ['Household', resolveHousehold], // resolves as an array, take first
        isDesktopSafari: resolveIsDesktopSafari,
        redirectToDefault: [
          'redirect',
          'householdMembership',
          'pendingHouseholdInvitations',
          resolveRedirectToState,
        ],
      },
    })
    .state('profile.household.create', {
      controller: 'profileHouseholdCreateCtrl',
      resolve: {},
      templateUrl: 'partials/profile/household/create-household.html',
    })
    .state('profile.household.invitations', {
      controller: 'profileHouseholdInvitationsCtrl',
      resolve: {},
      templateUrl: 'views/profile/household/invitations.html',
    })
    .state('profile.household.overview', {
      controller: 'profileHouseholdOverviewCtrl',
      resolve: {},
      templateUrl: 'views/profile/household/overview.html',
    });
}
