'use strict';

angular
  .module('service.resolve-helpers.mutual-funds', [])
  .factory('mutualFundsResolveHelpers', ['$http', factory]);

function factory($http) {
  var resolveMutualFunds = function () {
    return $http.get('assets/mutual-funds.csv').then(function (response) {
      return response.data;
    });
  };

  return {
    resolveMutualFunds,
  };
}
