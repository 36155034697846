'use strict';

angular
  .module('model.Projection', ['model.ModelPortfolio', 'model.Simulation', 'ram'])
  .factory('projectionResource', ['$http', 'ModelPortfolio', 'config', projectionResource])
  .factory('Projection', ['ram', 'projectionResource', 'Simulation', projectionFactory]);

function ProjectionLayer(layer, percentile, risk, title) {
  this.layer = layer;
  this.percentile = percentile;
  this.risk = risk;
  this.title = title;
  this.values = [];
}

/**
 * This is a mock for projected performance of a model portfolio, which should
 * should be calculated.
 */
function projectionResource($http, ModelPortfolio, config) {
  function parseCSV(risk, data) {
    var projections = [
      new ProjectionLayer(1, 5, risk),
      new ProjectionLayer(2, 25, risk, 'Very Poor Market Returns'),
      new ProjectionLayer(3, 50, risk, 'Poor Market Returns'),
      new ProjectionLayer(4, 75, risk, 'Average Market Returns'),
      new ProjectionLayer(5, 95, risk, 'Above Average Market Returns'),
    ];

    d3.csv.parseRows(data, function (row, i) {
      // drop the data column
      row.shift();
      var date = moment().add(i, 'months').startOf('month')._d;
      _.each(row, function (col, j) {
        var gain = parseFloat(col);
        if (projections[j]) {
          projections[j].values.push({
            date,
            gain,
          });
        }
      });
    });

    return projections;
  }

  function query(req) {
    return ModelPortfolio.find({
      id: req.modelPortfolioId,
    })
      .then(function (mp) {
        var sociallyResponsiblePortfolioOption = _.findWhere(config.types.AccountPortfolioOption, {
          name: 'socially_responsible_portfolio',
        });

        // PM_todo - 2022.01.25
        //           This won't work with BCV or Guardian.  Navid gave the OK to hide the Projections tab for non-ModernAdvisor PMs.
        //           We need to re-visit this.
        // For now we will use the core portolfio projections for the adaptive portfolios as well.
        var url =
          mp.portfolioOptionId() === sociallyResponsiblePortfolioOption.id
            ? '/api/projections/' + mp.risk() + '_esg.csv'
            : '/api/projections/' + mp.risk() + '.csv';

        return $http.get(url, {
          transformResponse: _.partial(parseCSV, mp.risk()),
        });
      })
      .then(function (projections) {
        return projections.data;
      })
      .catch((exception) => {
        const errorMessage = `Could not load ${exception.config.url}. Status code: ${exception.status}.`;
        window.Sentry && window.Sentry.captureMessage(errorMessage);
      });
  }

  return {
    get: angular.noop,
    query,
    remove: angular.noop,
    save: angular.noop,
    update: angular.noop,
  };
}

function projectionFactory(ram, projectionResource, Simulation) {
  var Projection = new ram.Collection('Projection', {
    accessors: ['values'],
    resources: {
      default: projectionResource,
    },
    key: ['layer', 'risk', 'percentile'],
  });

  /**
   * Transform precalculated returns (gains) into a series of hypothetical
   * future balances.
   *
   * @param  {Number} initial The initial deposit at the beginning of the
   *                          simulation.
   * @param  {Number} ongoing The ongoing deposits expected to be made on a
   *                          monthly basis.
   * @return {Object}         Copy of the projection area with transformed
   *                          values.
   */
  Projection.prototype.simulate = function (initial, ongoing) {
    return new Simulation(initial, ongoing, this);
  };

  return Projection;
}
