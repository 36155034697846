class TrustedContactEditController {
  constructor($scope, $http, ram, config, patterns, Person, PhoneNumber, TrustedContact) {
    this.$scope = $scope;
    this.$http = $http;
    this.ram = ram;
    this.config = config;
    this.patterns = patterns;
    this.Person = Person;
    this.PhoneNumber = PhoneNumber;
    this.TrustedContact = TrustedContact;
    this.onTrustedContactChange = this.onTrustedContactChangeUnbound.bind(this);
  }

  $onInit() {
    this.trustedContactId = this.client.trustedContact()?.id;
    this._setEditBuffers(this.client.trustedContact());
    this.clientIsInPerson = this.client === this.$scope.$root.currentUser.person(); // "in person" vs "advisor doing it for them"
  }

  _setEditBuffers(trustedContact) {
    this.trustedContact = trustedContact;
    this.email = new this.ram.Accessor(this.trustedContact?.email());
    this.relationship = new this.ram.Accessor(this.trustedContact?.relationship());
    this.contact = _convertJson(this.trustedContact?.contact(), this.Person);
    this.$scope.phoneNumber = _convertJson(
      this.trustedContact?.contact()?.phoneNumbers()?.[0],
      this.PhoneNumber
    ); // jshint ignore:line

    function _convertJson(object, Klass) {
      return object ? new Klass(object.toJSON()) : new Klass();
    }
  }

  onTrustedContactChangeUnbound(source) {
    this.form.email?.$validate();
    if (source === 'email' && !this.form.email?.$valid) {
      return;
    }
    if (source === 'person') {
      this.contact.makeConsistent();
    }

    const email = this.form.email?.$valid ? this.email() : undefined; // So you can set relationship when email is invalid
    const data = this.trustedContact.detailsPayload(
      this.contact,
      this.$scope.phoneNumber,
      email,
      this.relationship()
    );

    return this.trustedContact
      .save()
      .then(() => {
        // Have to use $http.put as you cannot nest `phoneNumber` inside `contact` inside `trustedContact`
        this.$http
          .put(`/api/trusted_contacts/${this.trustedContactId}/update_details.json`, data)
          .then((trustedContact) => (this.contact.id = trustedContact.data.contactId))
          .catch((err) => console.log('inner', err));
      })
      .catch((err) => console.log('outer', err));
  }
}

export default TrustedContactEditController;
