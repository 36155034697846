'use strict';

angular
  .module('model.TrustedContact', ['ram'])
  .factory('TrustedContact', ['ram', 'config', trustedContactFactory]);

function trustedContactFactory(ram, config) {
  var TrustedContact = new ram.Collection('TrustedContact', {
    belongsTo: {
      client: 'Person',
      contact: 'Person',
    },
    serialize: ['contact'],
    resources: {
      default: new ram.resources.Http('/api/trusted_contacts/:id.json'),
      cookie: new ram.resources.Cookie('trusted_contacts'),
    },
    schema: config.schemas.TrustedContact,
  });

  TrustedContact.prototype.detailsPayload = function (contact, phoneNumber, email, relationship) {
    return {
      designated: this.designated(),
      email: email ?? this.email(), // Onboarding overrides these two
      relationship: relationship ?? this.relationship(),
      contact: {
        id: contact.id,
        firstName: contact.firstName() || null, // set nulls so record is updated, then phone number can be set down below
        middleName: contact.middleName() || null,
        lastName: contact.lastName() || null,
      },
      phoneNumber: {
        ...phoneNumber.toJSON(),
        typeId: config.types.PhoneNumber.findByName('home').id,
      },
    };
  };

  return TrustedContact;
}
