'use strict';

angular
  .module('controller.profile.bank.view', [
    'directive.bank-accounts-section',
    'service.account-service',
  ])
  .controller('ProfileFinancialCtrl', ['$scope', profileFinancialController])
  .controller('ProfileBankViewCtrl', [
    '$scope',
    '$state',
    'accountService',
    profileBankViewController,
  ]);

function profileFinancialController($scope) {
  var currentUser = $scope.currentUser;

  /* jshint validthis: true */
  this.save = function () {
    return currentUser.save();
  };

  this.reset = function () {
    return currentUser.fetch(true);
  };
}

function profileBankViewController($scope, $state, accountService) {
  $scope.hasFccAccount = accountService.hasFccAccount($scope.currentUser.accounts());

  $scope.inKycUpdateView = function () {
    return $state.current.name === 'kyc';
  };

  $scope.filteredBankAccounts = _.filter($scope.currentUser.bankAccounts(), function (bankAccount) {
    return !bankAccount.status.is.draft();
  });

  $scope.totalBankAccountsCount = $scope.currentUser.bankAccounts().length;
}
