'use strict';

angular
  .module('controller.minimal-footer', [])
  .controller('minimalFooterCtrl', ['$scope', '$state', minimalFooterCtrl]);

function minimalFooterCtrl($scope, $state) {
  $scope.isDashboard = function () {
    return $state.current.name.includes('dashboard');
  };
}
