import React from 'react';

export default function CallAttentionDot({ size = 48, marginTop = -5, marginLeft = -5 }) {
  function dotText() {
    return 'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIyIiBmaWxsPSJyZ2IoMjM2LCA1MywgNTMpIi8+Cjwvc3ZnPgo=';
  }

  return (
    <img
      style={{ marginTop: `${marginTop}px`, marginLeft: `${marginLeft}px` }}
      src={`data:image/svg+xml;charset=utf-8;base64,${dotText()}`}
      alt="Dot"
      width={size}
      height={size}
    />
  );
}
