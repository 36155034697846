'use strict';

function entityListServiceFactory(
  config,
  BrokerageAccount,
  InsiderRole,
  AccountTransferSecurity,
  AdvisorCreateNewTransferSecurity
) {
  function markParentFormDirty($scope) {
    if ($scope.parentForm) {
      $scope.parentForm.$setDirty();
    }
  }

  var EntityList = function ($scope, currentList, destroyLastEmitString, entityController) {
    return {
      saved: currentList,
      added: [],
      removed: [],

      createNew: function () {
        this.added.push(entityController.createNew());
        markParentFormDirty($scope);
      },

      getAll: function () {
        return _.union(this.saved, this.added);
      },

      getRemoved: function () {
        return this.removed;
      },

      remove: function (entity) {
        if (_.contains(this.saved, entity)) {
          this.saved = _.without(this.saved, entity);
          this.removed.push(entity);
        } else {
          this.added = _.without(this.added, entity);
        }
        markParentFormDirty($scope);
        if ($scope.saveOnChange) {
          entity.destroy();
        }
        if (this.getAll().length === 0) {
          $scope.$emit(destroyLastEmitString);
        }
      },

      save: function (entity) {
        if ($scope.saveOnChange && entityController.isValid(entity)) {
          return entity.save();
        }
      },

      saveAll: function (preSaveCallback) {
        var promises = [];

        _.each(this.getAll(), function (entity) {
          if (preSaveCallback) {
            preSaveCallback(entity);
          }
          if (entityController.isValid(entity)) {
            promises.push(entity.save());
          }
        });

        _.each(this.getRemoved(), function (entity) {
          promises.push(entity.destroy());
        });

        return promises;
      },

      isValid: function (entity) {
        return entityController.isValid(entity);
      },

      reset: function (currentList) {
        this.saved = currentList;
        this.added = [];
        this.removed = [];
      },
    };
  };

  return {
    createInsiderRolesController: function ($scope, initialData) {
      $scope.insiderRoleTypes = config.types.InsiderRole.slice(0, 4);

      return new EntityList($scope, initialData, 'destroyLastInsiderRecord', {
        createNew: function () {
          return InsiderRole.new();
        },
        isValid: function (insiderRole) {
          return (
            insiderRole.company() && insiderRole.ticker() && insiderRole.typeId() !== undefined
          );
        },
      });
    },
    createBrokerageAccountsController: function ($scope, initialData) {
      return new EntityList($scope, initialData, 'destroyLastBrokerageRecord', {
        createNew: function () {
          return BrokerageAccount.new();
        },
        isValid: function (brokerageAccount) {
          return brokerageAccount.number() && brokerageAccount.firmName();
        },
      });
    },
    createAccountTransferPositionsController: function ($scope, initialData) {
      return new EntityList($scope, initialData, 'destroyLastPositionRecord', {
        createNew: function () {
          return AccountTransferSecurity.new();
        },
        isValid: function (accountTransferSecurity) {
          return (
            accountTransferSecurity.accountTransferId() &&
            accountTransferSecurity.description() &&
            accountTransferSecurity.quantity()
          );
        },
      });
    },
    createAccountTransferFlowPositionsController: function ($scope, initialData) {
      return new EntityList($scope, initialData, 'destroyLastPositionRecord', {
        createNew: function () {
          return AdvisorCreateNewTransferSecurity.new();
        },
        isValid: function (accountTransferSecurity) {
          return (
            accountTransferSecurity.advisorCreateNewTransferFlowId() &&
            accountTransferSecurity.description() &&
            accountTransferSecurity.quantity()
          );
        },
      });
    },
  };
}

angular
  .module('service.entity-list-service', [
    'model.BrokerageAccount',
    'model.InsiderRole',
    'model.AccountTransferSecurity',
    'model.AdvisorCreateNewTransferSecurity',
  ])
  .factory('entityListService', [
    'config',
    'BrokerageAccount',
    'InsiderRole',
    'AccountTransferSecurity',
    'AdvisorCreateNewTransferSecurity',
    entityListServiceFactory,
  ]);
