'use strict';

angular
  .module('controller.advise.orders', [
    'model.Order',
    'service.loading-indicator',
    'service.user-notifications',
  ])
  .controller('AdviseOrdersCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    '$http',
    '$timeout',
    '$window',
    '$document',
    'orders',
    'funds',
    'Order',
    'loadingIndicator',
    'userNotifications',
    adviseOrdersController,
  ]);

function adviseOrdersController(
  $scope,
  $state,
  $stateParams,
  $http,
  $timeout,
  $window,
  $document,
  orders,
  funds,
  Order,
  loadingIndicator,
  userNotifications
) {
  $scope.orders = orders;
  $scope.showAll = false;
  $scope.moment = moment;

  $scope.uploadSuccess = $stateParams.upload === 'true';
  if ($scope.uploadSuccess) {
    $timeout(function () {
      $window.scrollTo({
        top: $document[0].getElementById('user-notification').offsetTop - 75,
        behavior: 'instant',
      });
      userNotifications.showSuccess('ETF Prices uploaded successfully.');
    }, 250);
  }

  $scope.canCreateOrder = function () {
    return orders.every((order) => order.hasAllTradeTickets());
  };

  $scope.createOrder = function () {
    if (!$scope.canCreateOrder()) {
      $scope.genericOkModal(
        'Missing Trade Tickets',
        'One or more existing Orders is missing a trade ticket.  Please review the missing custodians under "Trade Tickets" on the red shaded lines.'
      );
      return;
    }

    loadingIndicator.show();
    Order.new()
      .save()
      .then(function () {
        $state.reload();
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  $scope.deleteOrder = function (order) {
    loadingIndicator.show();
    $http
      .delete('/api/orders/' + order.id + '.json')
      .then(function () {
        $state.reload();
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  $scope.refreshQuotes = function (source) {
    loadingIndicator.show();
    $http
      .put('/api/funds/update_quotes.json')
      .then(function () {
        $state.go(
          'advise.orders',
          {
            upload: source?.upload,
          },
          {
            reload: true,
          }
        );
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  $scope.$watch('showAll', function (showAll) {
    const itemsToExcludeFromFunds = [
      'XEN',
      'REM',
      'ANT420',
      'ANY620',
      'DGRC',
      'PABF',
      'XMI',
      'XMM',
      'ZJPN',
      'ZLU',
      '',
    ]; // Funds to hide per N. B., June 21, 2024
    $scope.funds = _.chain(funds)
      .filter(function (fund) {
        return fund.symbol() !== 'CAD' && (showAll || !fund.assetClass.is.otherFunds());
      })
      .filter((fund) => showAll || !itemsToExcludeFromFunds.includes(fund.symbol()))
      .sortBy(function (fund) {
        return fund.assetClass.is.otherFunds() + fund.symbol();
      })
      .value();
  });

  $scope.showAllFunds = function () {
    $scope.showAll = true;
  };

  $scope.hideOtherFunds = function () {
    $scope.showAll = false;
  };

  $scope.uploadEtfPrices = () => {
    $scope.uploadEtfPricesModal().result.then((result) => {
      if (result?.success) {
        $scope.refreshQuotes({
          upload: true,
        });
      }
    });
  };

  $scope.highlightFund = (dateTimeUpdated) => {
    const oneMinuteAgo = moment().subtract(1, 'minute');
    return $scope.uploadSuccess && dateTimeUpdated && moment(dateTimeUpdated).isAfter(oneMinuteAgo);
  };
}
