'use strict';

var MIN_RISK = 1;
var MAX_RISK = 10;

/**
 * Default values for the preset method below.
 */
var presetDefaults = {
  'Save for Retirement': {
    timeframe: 25,
    notimeframe: false,
    ongoing: 0,
  },
  "Save for Child's Education": {
    timeframe: 18,
    notimeframe: false,
    ongoing: 0,
  },
  'Save for a Rainy Day': {
    timeframe: 1,
    notimeframe: false,
    ongoing: 0,
  },
  'Save for Short-term': {
    timeframe: 1,
    notimeframe: false,
    ongoing: 0,
  },
  'Generate Income': {
    timeframe: 15,
    notimeframe: false,
    ongoing: 0,
  },
  'Build Wealth': {
    timeframe: 15,
    ongoing: 0,
  },
};

function goalFactory(ram, config) {
  var Goal = new ram.Collection('Goal', {
    belongsTo: {
      user: 'User',
    },
    hasMany: {
      accountGoals: 'AccountGoal',
      audits: 'Audit',
    },
    bind: [
      'childsAge',
      'retirementAge',
      'risk',
      'preset',
      'withdrawals',
      'showTarget',
      'setAdvisorRiskOffset',
      'incrementClientRiskOffset',
      'decrementClientRiskOffset',
    ],
    resources: {
      default: new ram.resources.Http('/api/goals/:id.json'),
      cookie: new ram.resources.Cookie('goals'),
    },
    schema: config.schemas.Goal,
  });

  Goal.prototype.initialize = function () {
    this._notimeframe = new ram.Accessor();
    this.riskOffset(this.riskOffset() || 0);
  };

  /**
   * An interface to timeframe that assumes the account will be closed
   * when the child turns 18.
   *
   * @param  {Number} val Age of the child or undefined
   * @return {Number}     undefined or age of child
   */
  Goal.prototype.childsAge = function (val) {
    return val === undefined ? 18 - this.timeframe() : this.timeframe(18 - val);
  };

  /**
   * An alias for timeframe
   */
  Goal.prototype.retirementAge = function () {
    var args = Array.prototype.slice.call(arguments, 0, 1);
    return this.timeframe.apply(this, args);
  };

  /**
   * Common method to constrain a risk setting to be between 1 and 10
   * @param  {integer} setting What you want it to be
   * @return {integer}         The input constrained from 1 to 10
   */
  var constrainedRiskSetting = function (setting) {
    if (setting < MIN_RISK || setting > MAX_RISK) {
      setting = _.constrain(MIN_RISK, setting, MAX_RISK);
    }
    return setting;
  };

  /**
   * Allow the advisor to specify an offset.
   * Alters the riskOffset;  makes sure the resulting value is in range.
   * @param setting
   */
  Goal.prototype.setAdvisorRiskOffset = function (newAdvisorSetting) {
    var newSetting = this.riskRecommendation() + newAdvisorSetting + this.riskOffset();
    var constrainedNewSetting = constrainedRiskSetting(newSetting);
    if (newSetting === constrainedNewSetting) {
      this.advisorRiskOffset(newAdvisorSetting);
    }
  };

  /**
   * Smart increment function for client risk level
   */
  Goal.prototype.incrementClientRiskOffset = function () {
    if (this.riskOffset() > 0) {
      return;
    }
    var newSetting = this.risk() + 1;
    var constrainedNewSetting = constrainedRiskSetting(newSetting);
    if (newSetting === constrainedNewSetting) {
      this.riskOffset.increment(1, 1);
    }
  };

  /**
   * Smart decrement function for client risk level
   */
  Goal.prototype.decrementClientRiskOffset = function () {
    if (this.riskOffset() < 0) {
      return;
    }
    var newSetting = this.risk() - 1;
    var constrainedNewSetting = constrainedRiskSetting(newSetting);
    if (newSetting === constrainedNewSetting) {
      this.riskOffset.decrement(1, -1);
    }
  };

  /**
   * Calculate the overall risk level for a goal.
   *
   * @return {Number}     Current risk setting.  Calculated.
   */
  Goal.prototype.risk = function (val) {
    if (_.isUndefined(val) && this.advisorRiskOffset() === 0) {
      var current = this.riskRecommendation() + this.riskOffset();
      var adjusted = constrainedRiskSetting(current);
      if (current !== adjusted) {
        this.riskOffset(0);
      }
    }
    return this.riskRecommendation() + this.advisorRiskOffset() + this.riskOffset();
  };

  Goal.prototype.withdrawals = function (val) {
    if (!arguments.length) {
      return -this.ongoing();
    } else {
      this.ongoing(-val);
    }
  };

  Goal.prototype.safeWithdrawal = function () {
    return this.initial() / (this.timeframe() * 12);
  };

  Goal.prototype.showTarget = function () {
    return this.label() !== 'Generate Income';
  };

  /**
   * Getter/setter for a preset goal label. When changed, updates timeframe.
   *
   * @param  {String} val
   * @return {String}
   */
  Goal.prototype.preset = function (val) {
    if (val) {
      if (!_.isUndefined(presetDefaults[val].timeframe)) {
        this.timeframe(presetDefaults[val].timeframe);
      }
      if (!_.isUndefined(presetDefaults[val].notimeframe)) {
        this.notimeframe(presetDefaults[val].notimeframe);
      }
      if (!_.isUndefined(presetDefaults[val].ongoing)) {
        this.ongoing(presetDefaults[val].ongoing);
      } else {
        var ongoingValue = this.ongoing();
        if (_.isNaN(ongoingValue) || _.isNull(ongoingValue)) {
          this.ongoing(0);
        }
      }
    }
    var args = Array.prototype.slice.call(arguments, 0, 1);
    return this.label.apply(this, args);
  };

  Goal.prototype.isQuestionsComplete = function () {
    const self = this;
    if (self.label() === undefined) {
      return false;
    }

    const accessors = [];

    switch (self.label()) {
      case 'Save for Retirement':
      case "Save for Child's Education":
        accessors.push('timeframe');
        break;
      case 'Generate Income':
        accessors.push('timeframe', 'initial', 'ongoing');
        break;
      case 'Build Wealth':
        accessors.push('timeframe', 'notimeframe');
        break;
    }

    return _.all(accessors, function (accessor) {
      if (accessor === 'notimeframe') {
        return self[accessor]() === true || self[accessor]() === false;
      }
      return !_.isNaN(parseInt(self[accessor]()));
    });
  };

  return Goal;
}

angular
  .module('model.Goal', ['model.Audit', 'model.User', 'ram'])
  .factory('Goal', ['ram', 'config', goalFactory]);
