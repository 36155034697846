'use strict';

function factory(config) {
  function getActivityTypes() {
    return [
      {
        label: 'All Activities',
        name: 'all',
        // The Dropdown UX needs an id
        id: config.types.Activity.length + 1, // The next in the list
      },
    ].concat(config.types.Activity);
  }

  function activities(account, type, period) {
    return _.chain(account.activities())
      .filter(function (activity) {
        var typeMatch = type.name === 'all' || type.id === activity.typeId();
        return (
          typeMatch &&
          (period.start === null || activity.date() > period.start) &&
          activity.type().hidden !== true
        );
      })
      .sortBy(function (activity) {
        return -activity.date();
      })
      .value();
  }

  function accountById(currentUser, id) {
    if (currentUser) {
      return _(currentUser.viewableAccounts()).findWhere({
        id,
      });
    }
  }

  function getTotalAmount(activities) {
    return activities.reduce(function (acc, activity) {
      return acc + +activity.amount();
    }, 0);
  }

  function displayTotalAmount(type) {
    var typesWithoutAmounts = ['all', 'rebalance', 'modify'];
    return !typesWithoutAmounts.includes(type.name);
  }

  return {
    displayTotalAmount,
    getActivityTypes,
    getTotalAmount,
    activities,
    accountById,
  };
}

angular
  .module('service.activityDisplayHelper', [])
  .factory('activityDisplayHelper', ['config', factory]);
