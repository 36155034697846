'use strict';

angular
  .module('controller.onboarding', [
    'ui.bootstrap',
    'controller.onboarding.bank',
    'controller.onboarding.signatures',
    'controller.onboarding.congratulations',
    'controller.onboarding.confirmEmail',
    'controller.onboarding.identification',
    'controller.onboarding.legalities',
    'controller.onboarding.communication-and-consent',
    'controller.onboarding.personal-combined',
    'controller.onboarding.rif-detail',
    'controller.onboarding.documents',
    'directive.input',
    'directive.ng-scope',
    'directive.partial',
    'directive.with',
    'directive.progress-indicator',
    'directive.validate-date',
    'directive.need-help-sidebar',
    'filter.array',
    'service.account-service',
    'service.manage-user-cookie',
    'service.dropdown-options',
    'service.check-incomplete-accounts-service',
    'service.spouse-or-partner',
    'component.subform.photo-id-upload',
  ])
  .controller('OnboardingController', [
    '$scope',
    '$injector',
    '$state',
    '$http',
    '$q',
    'account',
    'accountService',
    'person',
    'states',
    'storedUser',
    'storedGoal',
    '$analytics',
    'manageUserCookie',
    'dropdownOptions',
    'checkIncompleteAccountsService',
    'spouseOrPartner',
    'incompleteAccountFlows',
    'advisorReferralDetails',
    onboardingController,
  ]);

function onboardingController(
  $scope,
  $injector,
  $state,
  $http,
  $q,
  account,
  accountService,
  person,
  states,
  user,
  goal,
  $analytics,
  manageUserCookie,
  dropdownOptions,
  checkIncompleteAccountsService,
  spouseOrPartner,
  incompleteAccountFlows,
  advisorReferralDetails
) {
  $scope.account = account;
  $scope.person = person;
  $scope.states = states;
  $scope.storedUser = user;
  $scope.storedGoal = goal;

  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.residencies = dropdownOptions.residencies();

  $scope.branding = advisorReferralDetails.branding;
  checkIncompleteAccountsService.active(false);

  var scrollUp = function () {
    window.scrollTo(0, 0);
  };

  $scope.progress = function () {
    return $state.current.data && $state.current.data.progress;
  };

  // The user is now logged in, turn off the scrolling advertisement banner.
  manageUserCookie.setCookie();

  $scope.$on('$locationChangeStart', scrollUp);

  $scope.accountHasBeneficiary = function () {
    return account.hasBeneficiary();
  };

  $scope.spouseOrPartner = function () {
    return spouseOrPartner.relationshipName($scope.person);
  };

  $scope.formNotDone = function (formName) {
    formName = formName || 'form';
    var firstInvalidInput = document[formName].querySelector('.ng-invalid');
    var firstInvalidSelect = document[formName].querySelector('.invalid-select');
    var firstInvalid = firstInvalidInput || firstInvalidSelect;

    var navbarHeight = document.querySelector('nav').clientHeight;

    _.each(this[formName].$error, function (errors) {
      _.each(errors, function (control) {
        control.$setDirty();
      });
    });

    if (firstInvalid) {
      firstInvalid.focus();
      window.scrollBy(0, -(navbarHeight + 10));
    }

    this.$root.$broadcast('submit');
  };

  function resetMethods() {
    $scope.changed = $scope.done = $scope.notDone = $scope.save = angular.noop;
  }

  resetMethods();

  function shouldSkipStep(state) {
    var skip = state.data && state.data.skip;
    if (!skip) {
      return false;
    }
    return $injector.invoke(skip, null, $state.$current.locals.globals);
  }

  function stateIndex(state) {
    return $scope.states.indexOf(state);
  }

  $scope.nextState = function () {
    $scope.prevStateIndex = $scope.stateIndex;
    var nextState = $scope.stateIndex + 1;
    if ($scope.done()) {
      $q.resolve($scope.save()).then(function () {
        while (shouldSkipStep($scope.states[nextState])) {
          nextState += 1;
        }
        account.save({
          progress: Math.max($scope.states[nextState].data.step, account.progress()),
        });
        $state.go($scope.states[nextState]);
      });
    } else {
      $scope.notDone();
    }
  };

  function findLastState() {
    var lastStateIndex = $scope.stateIndex - 1;
    while (lastStateIndex >= 0 && shouldSkipStep($scope.states[lastStateIndex])) {
      lastStateIndex -= 1;
    }
    var lastState = null;
    if (lastStateIndex >= 0) {
      lastState = $scope.states[lastStateIndex];
    }
    return lastState;
  }

  $scope.skipStep = function () {
    if ($scope.prevStateIndex < $scope.stateIndex) {
      $scope.nextState();
    } else {
      $scope.previousState();
    }
  };

  $scope.previousState = function () {
    $scope.prevStateIndex = $scope.stateIndex;
    $state.go(findLastState());
  };

  $scope.canGoBack = function () {
    // prevent going back from personal info
    var currentState = $scope.states[$scope.stateIndex];
    if (!currentState || !currentState.data) {
      return false;
    }
    // 2 - personal info
    if (currentState.data.interviewStep === 2) {
      var lastState = findLastState();
      if (lastState !== null && lastState.data.interviewStep === 1) {
        return false;
      }
    }

    return $scope.stateIndex !== 0 && !currentState.data.finalStep;
  };

  var controlHistory = function () {
    history.pushState(null, null, '/onboarding/personal');
    if (!checkIncompleteAccountsService.active()) {
      checkIncompleteAccountsService.call($scope, $state);
    }
  };

  var getValidRedirectPage = function () {
    var accountProgress = account.progress();
    var stateTarget = $state.current.data.step;

    if (stateTarget < 5 || stateTarget > accountProgress) {
      return accountService.getUrlForIncompleteAccount(account);
    } else {
      return '/onboarding' + $state.current.url;
    }
  };

  // Redirects to the last valid page for the account or the target page
  history.pushState(null, null, getValidRedirectPage());
  window.addEventListener('popstate', controlHistory);

  function onStateChangeStart(event, toState) {
    if (
      (toState.name.match(/interview/) && toState.name !== 'interview.goal') ||
      toState.name.match(/preview/)
    ) {
      event.preventDefault();
    } else if (toState.name === 'onboarding.personal-combined') {
      // In case user navigates back from future onboarding states.
      history.pushState(null, null, '/onboarding/personal');
      window.addEventListener('popstate', controlHistory);
    } else {
      window.removeEventListener('popstate', controlHistory);
      resetMethods();

      if (toState.name.match(/interview/) && toState.name === 'interview.goal') {
        $scope.$root.pushStatePending = '/';
      }
    }
  }

  function onStateChangeSuccess(event, state) {
    $scope.state = state;
    $scope.stateIndex = stateIndex(state);
  }

  $scope.$on('$stateChangeStart', onStateChangeStart);
  $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

  $scope.$watch('account.progress()', function (progress) {
    $analytics.eventTrack('completed-onboarding-step', {
      'onboarding-progress': progress,
    });
    $analytics.setUserProperties({
      email: $scope.currentUser.email(),
      'onboarding-progress': progress,
    });
  });

  // -------------------------------------------------------------------------
  // Advisor assisted onboarding
  //
  $scope.isAdvisorCreateClientMode = function () {
    return !!account.advisorCreateNewAccountFlow();
  };

  $scope.isAdvisorCreateClientModeAllDone = function () {
    return incompleteAccountFlows.length && filteredAccountFlows().length === 0;
  };

  $scope.goToNextAccountFlow = function () {
    if (incompleteAccountFlows.length > 0) {
      var accountFlowId = _.first(filteredAccountFlows()).id;
      $http
        .put('/api/advisor_create_new_account_flows/' + accountFlowId + '/approve')
        .then(function () {
          $state.go('advisor-new-account.view', {
            advisorNewAccountFlowId: accountFlowId,
          });
        });
    }
  };

  function filteredAccountFlows() {
    return _.filter(incompleteAccountFlows, function (accountFlow) {
      return (
        accountFlow &&
        accountFlow.id &&
        account.advisorCreateNewAccountFlow() &&
        accountFlow.id !== account.advisorCreateNewAccountFlow().id &&
        accountFlow.status !== 'completed'
      );
    });
  }

  // -------------------------------------------------------------------------
  // Flags
  //
  $scope.isFamilyResp = accountService.isFamilyResp(account);
  $scope.isResp = accountService.isResp(account);
  $scope.isRdsp = accountService.isRdsp(account);
  $scope.isJoint = accountService.isJoint(account);
  $scope.canHaveSpouseAsJoint = accountService.canHaveSpouseAsJoint(account);
  $scope.isRif = accountService.isRif(account);
  $scope.isSpousal = accountService.isSpousal(account);
}
