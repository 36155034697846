'use strict';

function modalCsrfErrorController($scope) {
  $scope.reload = function () {
    window.location.reload();
  };
}

angular
  .module('controller.modal.csrf-error-handler', [])
  .controller('ModalCsrfErrorCtrl', ['$scope', modalCsrfErrorController]);
