'use strict';

angular
  .module('component.preferred-portfolio-manager-subform', [
    'directive.input',
    'directive.input.option-helper',
    'directive.input.manual-help-block',
  ])
  .component('preferredPortfolioManagerSubform', {
    templateUrl: 'components/preferred-portfolio-manager-subform.html',
    bindings: {
      portfolioManagers: '<',
      defaultPortfolioManagerId: '<',
      onChangePortfolioManager: '<',
      error: '<',
    },
    controller: [
      '$scope',
      'ram',
      'config',
      'optionHelper',
      PreferredPortfolioManagerSubformController,
    ],
    controllerAs: '$ctrl',
  });

function PreferredPortfolioManagerSubformController($scope, ram, config, optionHelper) {
  optionHelper.link($scope);

  $scope.portfolioManagers = this.portfolioManagers;
  $scope.onChangePortfolioManager = this.onChangePortfolioManager;

  const defaultId = this.defaultPortfolioManagerId();
  const defaultValue = _.isNullOrUndefined(defaultId)
    ? undefined
    : $scope.portfolioManagers.filter((manager) => manager.id === defaultId)[0];

  $scope.preferredPortfolioManager = new ram.Accessor(defaultValue); // This is not an id

  $scope.preferredPortfolioManagerChanged = function (newId) {
    $scope.onChangePortfolioManager(newId);
  };

  $scope.maManagementFeeTooltipText = function (portfolioManager) {
    return `<ul>
              <li>${Number(portfolioManager.maCoreManagementFee()).toFixed(
                2
              )}% management fee for Core and Responsible portfolios</li>
              <li>${Number(portfolioManager.maHarmonyManagementFee()).toFixed(
                2
              )}% management fee for Harmony portfolios</li>
            </ul>`;
  };
}
