'use strict';

angular
  .module('routes.advise.advisor-reports', [
    'react.component.advise.advisor-reports',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function authorizeExternalAdvisor(authorizer) {
    return authorizer.checkPermission('advisor');
  }

  function resolveAccounts($http) {
    return $http.get('/api/advisor/basic_account_infos.json').then(function (response) {
      if (response.data) {
        return response.data;
      }
    });
  }

  $stateProvider
    .state('advise.advisor-reports', {
      url: '/advisor-reports',
      abstract: false,
      resolve: {
        authorize: ['authorizer', authorizeExternalAdvisor],
      },
      template: '<div ui-view></div>',
    })
    .state('advise.advisor-reports.report', {
      url: '/report',
      controller: [
        '$scope',
        'accounts',
        function ($scope, accounts) {
          $scope.accounts = accounts;
        },
      ],
      resolve: {
        accounts: ['$http', resolveAccounts],
      },
      template: '<react-advisor-reports accounts="accounts"></react-advisor-reports>',
    });
}
