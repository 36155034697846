'use strict';

function factory($rootScope, $http, Document, exponentialBackoffPoller) {
  var _checkServerForImages;
  var _hasAllImages;
  var _refreshCallback = null;
  var _hasDocumentOfType;

  var _displayState = 'default';
  var _documents = null;

  /** From user click on view */
  function sendMessage(currentUser, refreshCallback) {
    _refreshCallback = refreshCallback;
    $http
      .post('api/users/create_mobile_upload_link', {
        userId: currentUser.id,
      })
      .then(
        function () {
          _displayState = 'link-sent';
          exponentialBackoffPoller.initialize(_hasAllImages, _checkServerForImages);
          exponentialBackoffPoller.start();
          _refreshCallback();
        },
        function () {
          _displayState = 'error';
          _refreshCallback();
        }
      );
  }

  /** From user click on view */
  function done() {
    _displayState = 'default';
    exponentialBackoffPoller.stop();
    _checkServerForImages();
  }

  /** From callback on view */
  function getState() {
    return _displayState;
  }

  /** From callback on view */
  function hasIdAndBank() {
    return _hasDocumentOfType(_documents, 3) && _hasDocumentOfType(_documents, 5);
  }

  /*
   * Local callbacks
   */
  _checkServerForImages = function () {
    Document.where({
      userId: $rootScope.currentUser.id,
    }).then(function (documents) {
      _documents = documents;
      $rootScope.$broadcast('doMobilePhotoRefresh');
      if (_refreshCallback) {
        _refreshCallback();
      }
      if (hasIdAndBank()) {
        _displayState = 'default';
      }
    });
  };

  _hasAllImages = function () {
    return hasIdAndBank() && _hasDocumentOfType(_documents, 4);
  };

  /*
   * Helpers
   */
  _hasDocumentOfType = function (documents, typeId) {
    return !!_.find(documents, function (document) {
      return document.typeId() === typeId;
    });
  };

  function enoughImagesCollected(isSpouseJointSubscriber) {
    var mainDocuments = _hasDocumentOfType(_documents, 3) && _hasDocumentOfType(_documents, 5);
    if (isSpouseJointSubscriber) {
      mainDocuments = mainDocuments && _hasDocumentOfType(_documents, 11);
    }
    if (mainDocuments) {
      var optionalDocuments =
        _hasDocumentOfType(_documents, 4) &&
        (isSpouseJointSubscriber ? _hasDocumentOfType(_documents, 12) : true);
      if (optionalDocuments) {
        return 'all';
      }
      return 'enough';
    }
    return null;
  }

  return {
    sendMessage,
    done,
    getState,
    enoughImagesCollected,
    setCallback: function (callback) {
      _refreshCallback = callback;
    },
  };
}

angular
  .module('service.onboarding-mobile-upload-manager', [
    'model.Document',
    'service.exponential-backoff-polling-service',
  ])
  .factory('onboardingMobileUploadManager', [
    '$rootScope',
    '$http',
    'Document',
    'exponentialBackoffPoller',
    factory,
  ]);
