'use strict';

angular.module('component.chart-set-period', []).component('chartSetPeriod', {
  templateUrl: 'components/chart-set-period.html',
  bindings: {
    period: '&',
    periods: '&',
  },
  controller: ['$scope', chartSetPeriodController],
  controllerAs: '$ctrl',
});

function chartSetPeriodController($scope) {
  var $ctrl = this; // jshint ignore:line
  $scope.period = $ctrl.period();
  $scope.periods = $ctrl.periods();
}
