'use strict';

angular
  .module('directive.flex-container', [
    'service.mobile-browser-detector', // To set the ".mobile" class in the CSS
    'service.loading-indicator',
  ])
  .directive('flexContainer', ['loadingIndicator', flexContainerDirective]);

var defaultFlexContainerInstructions = function () {
  return {
    search: '',
    filter: [],
    sort: {
      sortBy: '',
      order: +1,
    },
    page: 1,
    lastState: '',
  };
};

// for the flexSetXXXXX methods, the general idea is to create a NEW state object that re-uses state
// that hasn't modified, and has NEW variables for the stuff that has modified.  The reducer is a pure
// function and doesn't modify the state.
var flexSetSearch = function (instructions, searchBy) {
  return _.chain(instructions)
    .omit('search')
    .omit('page')
    .extend({
      search: searchBy.searchTerm,
      page: 1,
    })
    .value();
};

var flexSetFilter = function (instructions, filterBy) {
  return _.chain(instructions)
    .omit('filter')
    .omit('page')
    .extend({
      filter: filterBy.filterList,
      page: 1,
    })
    .value();
};

var flexSetSort = function (instructions, sortParams) {
  return _.chain(instructions)
    .omit('sort')
    .omit('page')
    .extend({
      sort: {
        sortBy: sortParams.sort.sortBy,
        order: sortParams.sort.order,
      },
      page: 1,
    })
    .value();
};

var flexSetPage = function (instructions, pageData) {
  return _.chain(instructions)
    .omit('page')
    .extend({
      page: pageData.pageNum,
    })
    .value();
};

var reducer = function (state, action) {
  if (_.isUndefined(state) || _.isNull(state)) {
    return defaultFlexContainerInstructions();
  }
  var newState;

  switch (action.type) {
    case 'FLEX-SEARCH':
      newState = flexSetSearch(state, action);
      break;

    case 'RESET-SEARCH':
      newState = flexSetSearch(state, {
        searchTerm: '',
      });
      break;

    case 'FLEX-FILTER':
      newState = flexSetFilter(state, action);
      break;

    case 'RESET-FILTER':
      newState = flexSetFilter(state, {
        filterList: [],
      });
      break;

    case 'SORT-BY':
      newState = flexSetSort(state, action);
      break;

    case 'FLEX-SET-PAGE':
      newState = flexSetPage(state, action);
      break;

    case 'RELOAD-ONLY':
      newState = state;
      break;

    case 'RESET-ALL':
      newState = defaultFlexContainerInstructions();
      break;

    default:
      newState = state;
  }
  if (action.type !== newState.lastAction) {
    return _.chain(newState)
      .omit('lastState')
      .extend({
        lastState: action.type,
      })
      .value();
  } else {
    return newState;
  }
};
// ================== End of redux methods ================================================

function flexContainerDirective(loadingIndicator) {
  function Controller($scope) {
    this.scope = $scope;
    this.state = reducer(); // get the default state
    this.listeners = [];
    this.hasResults = false;
    this.initialQueryHasResults = null;
  }

  Controller.prototype.setInitialAction = function (action) {
    this.state = reducer(this.state, action);
    // Don't reload.  This is to set initial state.
  };

  Controller.prototype.dispatch = function (actionName, args) {
    var action = _.extend(
      {
        type: actionName,
      },
      args
    );

    this.state = reducer(this.state, action);
    this.reload();
  };

  Controller.prototype.reload = function () {
    var _this = this;
    _this.hasResults = false;

    loadingIndicator.show();

    this.query(
      function (_this, results) {
        _this.scope.paginationMeta = results[0].paginationMeta();
        _.each(_this.listeners, function (listener) {
          listener(_this);
        });
        _this.hasResults = _this.scope.data && _this.scope.data.length > 0;
        _this.initialQueryHasResults =
          _this.initialQueryHasResults ||
          (_this.state.search === '' && _this.state.filter.length === 0 && _this.hasResults);
        loadingIndicator.hide();
      },
      function (_this) {
        _this.hasResults = false;
        loadingIndicator.hide();
      }
    );
  };

  Controller.prototype.query = function (successCallback, failureCallback) {
    var _this = this;
    var tool = this.scope.paginator;

    tool.classToUse
      .where(
        {
          searchTerm: _this.state.search,
          currentPage: _this.state.page,
          sortBy: _this.state.sort.sortBy,
          sortDirection: _this.state.sort.order > 0,
          filter: _this.state.filter.join(','),
        },
        {
          force: true,
        }
      )
      .then(
        function (results) {
          tool.results = results;
          _this.scope.data = tool.getDataFromPaginator(results[0]);
          return tool.loadDependantObjects ? tool.loadDependantObjects(_this.scope.data) : true;
        },
        function () {
          if (failureCallback) {
            failureCallback(_this);
          }
        }
      )
      .then(function () {
        if (successCallback) {
          successCallback(_this, tool.results);
        }
      });
  };

  Controller.prototype.paginationMeta = function () {
    return this.scope.paginationMeta;
  };

  Controller.prototype.addListener = function (fn) {
    return this.listeners.push(fn);
  };

  return {
    restrict: 'E',
    transclude: true,
    scope: {
      data: '=',
      paginator: '=',
      actionManager: '=',
      initialActions: '=',
    },
    templateUrl: 'directives/flex-container.html',
    controller: ['$scope', Controller],
    link: function (scope, element, attrs, controller) {
      scope.internalActionManager = scope.actionManager || {};
      scope.internalActionManager.reload = function () {
        controller.dispatch('RELOAD-ONLY');
      };
      scope.internalActionManager.reset = function () {
        controller.dispatch('RESET-ALL');
      };
      scope.internalActionManager.reloadData = function (action) {
        controller.setInitialAction(action);
        controller.reload();
      };

      if (scope.initialActions) {
        _.each(scope.initialActions(), function (action) {
          controller.setInitialAction(action);
        });
      }
      controller.reload(); // Do the initial load.
    },
  };
}
