'use strict';

class AssetAllocationDataGenerator {
  constructor(investmentChartsAllocationOrPositionColourMapperAndSorter) {
    this.investmentChartsAllocationOrPositionColourMapperAndSorter =
      investmentChartsAllocationOrPositionColourMapperAndSorter;
  }

  getData(allocations) {
    return _.map(
      this.investmentChartsAllocationOrPositionColourMapperAndSorter.getColourMappedAndSortedAllocations(
        allocations
      ),
      function (allocation) {
        return {
          color: allocation.fund().color,
          allocation,
        };
      }
    );
  }
}

AssetAllocationDataGenerator.$inject = [
  'investmentChartsAllocationOrPositionColourMapperAndSorter',
];

export default AssetAllocationDataGenerator;
