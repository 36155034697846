'use strict';

angular
  .module('controller.advise.transfer-requests.bbs-upcoming-deposits-withdrawals', [
    'controller.modal.super-advisor.update-bbs-upcoming-transfer-request-amount',
    'controller.modal.super-advisor.show-internal-transfers-email-text-and-mark-as-sent',
    'query.bbs-upcoming-transfer-requests.deposits-and-withdrawals',
    'service.loading-indicator',
    'service.user-notifications',
    'service.pagination.filter-service',
    'model.Paginator',
    'model.PaginatedTransferRequest',
  ])
  .controller('AdviseTransferRequestsBbsUpcomingDepositsWithdrawalsCtrl', [
    '$http',
    '$uibModal',
    '$scope',
    'BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery',
    'config',
    'loadingIndicator',
    'userNotifications',
    'paginationFilterService',
    'Paginator',
    'PaginatedTransferRequest',
    adviseTransferRequestsBbsUpcomingDepositsWithdrawalsController,
  ]);

function adviseTransferRequestsBbsUpcomingDepositsWithdrawalsController(
  $http,
  $modal,
  $scope,
  BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery,
  config,
  loadingIndicator,
  userNotifications,
  paginationFilterService,
  Paginator,
  PaginatedTransferRequest
) {
  $scope.upcomingTransferRequests = [];
  $scope.transferRequestSelection = {};
  $scope.transferRequestSelectionForPage = {};
  $scope.selectionCount = 0;

  $scope.views = {
    depositsWithdrawals: 'deposits-withdrawals',
  };

  $scope.currentView = $scope.views.depositsWithdrawals;
  $scope.viewChanged = true;
  const readyToSendStatuses = ['Upcoming', 'In Progress'];

  const commonFilterData = [
    {
      id: 0,
      group: 'Status',
      label: 'Upcoming',
      code: 'status.upcoming',
    },
    {
      id: 1,
      group: 'Status',
      label: 'In Progress',
      code: 'status.in_progress',
    },
    {
      id: 2,
      group: 'Status',
      label: 'Failed',
      code: 'status.failed',
    },
  ];

  const commonInitialFilter = ['status.upcoming', 'status.in_progress', 'status.failed'];

  $scope.filterData = commonFilterData;
  $scope.initialFilter = [];
  $scope.filterData.push({
    id: $scope.filterData.length,
    group: 'Request Type',
    label: 'Deposit',
    code: 'type.deposit',
  });
  $scope.filterData.push({
    id: $scope.filterData.length,
    group: 'Request Type',
    label: 'Withdrawal',
    code: 'type.withdrawal',
  });

  paginationFilterService.addEnumFilterData(
    config.types.Account,
    $scope.filterData,
    'Account',
    'account'
  );
  $scope.filterModel = paginationFilterService.toFilterModel(
    $scope.filterData,
    $scope.initialFilter
  );

  $scope.updateInitialFilter = function (view) {
    $scope.initialFilter = angular.copy(commonInitialFilter);
    if (view === $scope.views.depositsWithdrawals) {
      $scope.initialFilter.push(...['type.deposit', 'type.withdrawal']);
    }
    $scope.filterModel = paginationFilterService.toFilterModel(
      $scope.filterData,
      $scope.initialFilter
    );
  };
  /** ***************************** Pagination methods */

  $scope.actionManagerBbsUpcomingTransfer = {};

  $scope.initialActions = function () {
    return [
      {
        type: 'FLEX-FILTER',
        filterList: $scope.initialFilter,
      },
    ];
  };

  var syncSelection = function () {
    if ($scope.sendEftTicketIsAvailable) {
      $scope.upcomingTransferRequests.forEach(function (request) {
        request.selected = $scope.transferRequestSelection[request.id];
      });
    }
  };

  var _getDataFromPaginator = function (parameter) {
    loadingIndicator.show(null, 'deposits-withdrawals');
    $scope.upcomingTransferRequests = parameter.transferRequests();
    if ($scope.currentView === $scope.views.depositsWithdrawals) {
      if ($scope.viewChanged) {
        $scope.selectAll();
      }
    }
    $scope.viewChanged = false;
    syncSelection();
    loadingIndicator.hide('deposits-withdrawals');

    return $scope.upcomingTransferRequests;
  };

  $scope.paginator = new Paginator(PaginatedTransferRequest, null, _getDataFromPaginator);
  /** ***************************** end Pagination methods */

  $scope.option = 'none';

  $scope.selectAll = function () {
    _resetTransferRequestSelection($scope.upcomingDepositsAndWithdrawals);
    _resetTransferRequestSelectionForPage();
    syncSelection();
    $scope.option = 'all';
  };

  $scope.selectNone = function () {
    _.forEach($scope.upcomingTransferRequests, function (request) {
      request.selected = false;
    });
    _.forEach($scope.upcomingDepositsAndWithdrawals, function (request) {
      request.selected = false;
    });
    $scope.transferRequestSelection = $scope.transferRequestSelectionForPage = {};
    calcSelectionCount();
    syncSelection();

    $scope.option = 'none';
  };

  $scope.activateDepositAndWithdrawalView = function () {
    $scope.sendEftTicketIsAvailable = false;
    BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery.run()
      .then(function (upcomingTransferRequestsResults) {
        $scope.upcomingDepositsAndWithdrawals = upcomingTransferRequestsResults;
      })
      .then(function () {
        _resetTransferRequestSelection($scope.upcomingDepositsAndWithdrawals);
      })
      .finally(function () {
        $scope.sendEftTicketIsAvailable = true;
      });

    loadingIndicator.show();
    $scope.currentView = $scope.views.depositsWithdrawals;
    $scope.updateInitialFilter($scope.currentView);
    loadingIndicator.hide();
  };

  $scope.activateDepositAndWithdrawalView();

  $scope.$watch('currentView', function (newValue, oldValue) {
    $scope.viewChanged = $scope.viewChanged || newValue !== oldValue;
  });

  $scope.$watch('filterModel', function () {
    _reloadUpcomingTransferRequests();
  });

  $scope.sendSelectedTransferRequestsToBbs = function () {
    if (!$scope.sendEftTicketIsAvailable || $scope.selectionCount <= 0) {
      return;
    }

    loadingIndicator.show();
    var selectedTransferRequestIds = _getIdsOfCurrentlySelectedTransferRequestIds();
    var nextBusinessDay;

    _getNextBusinessDay().then(function (nextBusinessDayResult) {
      nextBusinessDay = nextBusinessDayResult;
      loadingIndicator.hide();

      return $modal
        .open({
          templateUrl: 'modals/super-advisor/confirm-send-bbs-eft-ticket.html',
          controller: [
            '$scope',
            function ($scope) {
              $scope.numberOfRequests = selectedTransferRequestIds.length;
              $scope.ticketDate = nextBusinessDay;
            },
          ],
        })
        .result.then(function () {
          loadingIndicator.show();
          return _createBbsEftTicket(
            selectedTransferRequestIds,
            moment(nextBusinessDay).format('YYYY-MM-DD')
          );
        })
        .finally(function () {
          loadingIndicator.hide();
        });
    });
  };

  $scope.isWithdrawalAndAvailableCashLessThanAmount = function (transferRequest) {
    return (
      transferRequest.typeLabel() === 'Withdrawal' &&
      transferRequest.availableCash() < transferRequest.amount()
    );
  };

  $scope.updateTransferRequestAmount = function (transferRequest) {
    return $modal
      .open({
        templateUrl: 'modals/super-advisor/update-bbs-upcoming-deposits-withdrawals-amount.html',
        controller: 'ModalSuperAdvisorUpdateBbsUpcomingTransferRequestAmountCtrl',
        resolve: {
          transferRequest,
        },
      })
      .result.then(function (newAmount) {
        loadingIndicator.show();
        return _updateUpcomingTransferRequestAmount(transferRequest.id, newAmount);
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  $scope.isCheckboxAvailable = function (transferRequest) {
    if ($scope.currentView !== $scope.views.depositsWithdrawals) {
      return false;
    }

    return (
      readyToSendStatuses.includes(transferRequest.statusLabel()) &&
      new Date(transferRequest.date()) <= $scope.nextBusinessDay
    );
  };

  $scope.toggleSelectionFor = function (transferRequest) {
    if (transferRequest.selected) {
      $scope.transferRequestSelection[transferRequest.id] = $scope.transferRequestSelectionForPage[
        transferRequest.id
      ] = true;
    } else {
      $scope.transferRequestSelection[transferRequest.id] = $scope.transferRequestSelectionForPage[
        transferRequest.id
      ] = false;
    }
    calcSelectionCount();
  };

  $scope.mark_as_sent = function (transferRequest) {
    userNotifications.clearNotification();
    $http
      .put(`/api/transfer_requests/${transferRequest.id}/bbs_mark_as_sent.json`)
      .then(function () {
        _redisplayWithoutSelectedTransferRequest(transferRequest);
      })
      .catch(function () {
        userNotifications.showError(
          'Something went wrong while marking the internal transfer email as sent. Please try again later.'
        );
      });
  };

  function calcSelectionCount() {
    $scope.selectionCount = Object.values($scope.transferRequestSelection).filter(
      (value) => value === true
    ).length;
  }

  function _resetTransferRequestSelection(requests) {
    $scope.transferRequestSelection = {};
    calcSelectionCount();
    if (!requests) {
      return;
    }
    return _getNextBusinessDay().then(function (nextBusinessDay) {
      $scope.nextBusinessDay = nextBusinessDay;

      requests
        .filter(function (transferRequest) {
          return readyToSendStatuses.includes(transferRequest.statusLabel);
        })
        .forEach(function (transferRequest) {
          transferRequest.selected = new Date(transferRequest.date) <= nextBusinessDay;
          $scope.transferRequestSelection[transferRequest.id] = transferRequest.selected;
        });
      calcSelectionCount();
    });
  }

  function _resetTransferRequestSelectionForPage() {
    $scope.transferRequestSelectionForPage = {};
    return _getNextBusinessDay().then(function (nextBusinessDay) {
      $scope.nextBusinessDay = nextBusinessDay;

      $scope.upcomingTransferRequests
        .filter(function (transferRequest) {
          return readyToSendStatuses.includes(transferRequest.statusLabel());
        })
        .forEach(function (transferRequest) {
          transferRequest.selected = new Date(transferRequest.date()) <= nextBusinessDay;
          $scope.transferRequestSelectionForPage[transferRequest.id] = transferRequest.selected;
        });
    });
  }

  function _getIdsOfCurrentlySelectedTransferRequestIds() {
    var selectedTransferRequestIds = [];

    _.each($scope.transferRequestSelection, function (isSelected, transferRequestId) {
      if (isSelected) {
        selectedTransferRequestIds.push(transferRequestId);
      }
    });

    return selectedTransferRequestIds;
  }

  function _reloadUpcomingTransferRequests() {
    if ($scope.actionManagerBbsUpcomingTransfer.hasOwnProperty('reload')) {
      $scope.actionManagerBbsUpcomingTransfer.reloadData({
        type: 'FLEX-FILTER',
        filterList: $scope.initialFilter,
      });
    }
  }

  function _getNextBusinessDay() {
    return $http
      .get('/api/super_advisor/next_business_day.json', {
        params: {
          date: moment().format('YYYY-MM-DD'),
        },
      })
      .then(function (response) {
        return moment(response.data.nextBusinessDay).toDate();
      });
  }

  function _createBbsEftTicket(selectedTransferRequestIds, nextBusinessDay) {
    userNotifications.clearNotification();
    $scope.bbsEftTickets = null;

    return $http
      .post('/api/super_advisor/bbs_eft_tickets.json', {
        transferRequestIds: selectedTransferRequestIds,
        date: nextBusinessDay,
      })
      .then(function (response) {
        $scope.bbsEftTickets = response.data.bbsEftTickets;
        return _reloadUpcomingTransferRequests();
      })
      .then(_resetTransferRequestSelection($scope.upcomingDepositsAndWithdrawals))
      .catch(function () {
        userNotifications.showError(
          'Something went wrong while sending the BBS EFT ticket. Please try again later.'
        );
      });
  }

  function _updateUpcomingTransferRequestAmount(transferRequestId, newAmount) {
    userNotifications.clearNotification();

    return $http
      .put('/api/super_advisor/bbs_upcoming_transfer_requests/' + transferRequestId + '.json', {
        amount: newAmount,
      })
      .then(_updateDisplayUpcomingTransferRequest)
      .catch(function () {
        userNotifications.showError(
          'Something went wrong while updating the BBS EFT request amount. Please try again later.'
        );
      });
  }

  function _updateDisplayUpcomingTransferRequest(updateUpcomingTransferRequestResponse) {
    var responseTransferRequest = updateUpcomingTransferRequestResponse.data;
    var displayTransferRequestIndex = $scope.upcomingTransferRequests.findIndex(
      (displayTransferRequest) => displayTransferRequest.id === responseTransferRequest.id
    );
    $scope.upcomingTransferRequests[displayTransferRequestIndex].amount.set(
      responseTransferRequest.amount
    );
  }

  function _redisplayWithoutSelectedTransferRequest(sentTransferRequest) {
    $scope.transferRequestSelection[sentTransferRequest.id] =
      $scope.transferRequestSelectionForPage[sentTransferRequest.id] = false;
    $scope.upcomingTransferRequests = $scope.upcomingTransferRequests.filter(
      (transferRequest) => transferRequest.id !== sentTransferRequest.id
    );
    calcSelectionCount();
  }
}
