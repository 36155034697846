'use strict';

class AccountRifDetailController {
  constructor($scope, $q, $http, config, formModificationTracker, securityServiceFactory) {
    this.$scope = $scope;
    this.$q = $q;
    this.$http = $http;
    this.config = config;
    this.formModificationTracker = formModificationTracker;
    this.securityServiceFactory = securityServiceFactory;
  }

  $onInit() {
    const security = this.securityServiceFactory.create(this.$scope.$root.currentUser);
    this.editEnabled = security.can('rrif_payment_details');

    this.ageToUseLabels = {
      my_age: "Client's age",
      my_partners_age: "Client's spouse/partner's age",
    };

    if (this.rifDetail.ageToUse()) {
      this.ageToUseLabel = this.ageToUseLabels[this.rifDetail.ageToUse().name];
    }

    this.paymentDateLabels = [
      {
        label: 'On the 15th of the month',
        value: 15,
      },
      {
        label: 'On the 30th of the month',
        value: 30,
      },
    ];

    this.$scope.$watch('$ctrl.rifDetail.startYear()', (newStartYear) => {
      this.showWithdrawalAmount = false;

      if (newStartYear === moment().year()) {
        this.showWithdrawalAmount = true;
        this.nextYearOfPaymentStartYear = newStartYear + 1;
        this.rifDetail.withdrawalAmountValidUntil(
          moment(this.nextYearOfPaymentStartYear + '-01-16').toDate()
        );
      } else {
        this.rifDetail.withdrawalAmount(null);
        this.rifDetail.withdrawalAmountValidUntil(null);
      }
    });

    this.startMonthOptions = moment.months();

    this.taxWithholdingChoice = null;
    if (this.rifDetail.taxWithholdingType()) {
      this.taxWithholdingChoice = this.rifDetail.taxWithholdingType.is.minimumRequired()
        ? 'minimum_required'
        : 'other';
    }

    this.$scope.$watch('$ctrl.taxWithholdingChoice', (newValue) => {
      if (newValue === 'minimum_required') {
        this.rifDetail.taxWithholdingType(this.config.types.RifTaxWithholding[0]);
        this.rifDetail.taxWithholdingPercent(null);
        this.rifDetail.taxWithholdingAmount(null);
      }
    });

    this.taxableAccountsOptions = this.taxableAccounts.map(function (taxableAccount) {
      return {
        label: taxableAccount.clientLabel,
        value: taxableAccount.id,
      };
    });

    this.rifCtrl = {
      payToChoice: null,
    };
    if (this.rifDetail.payToBankAccountId()) {
      this.rifCtrl.payToChoice = 'bank';
    }
    if (this.rifDetail.payToAccountId()) {
      this.rifCtrl.payToChoice = 'individual';
    }

    this.$scope.$watch('$ctrl.rifCtrl.payToChoice', (newValue) => {
      if (!newValue) {
        return;
      }

      var bankAccountId = null;
      var individualAccountId = null;
      switch (newValue) {
        case 'bank':
          bankAccountId =
            this.bankAccounts.length === 1
              ? _.first(this.bankAccounts).id
              : this.rifDetail.payToBankAccountId();
          break;
        case 'individual':
          individualAccountId =
            this.taxableAccounts.length === 1
              ? _.first(this.taxableAccounts).id
              : this.rifDetail.payToAccountId();
          break;
      }
      this.rifDetail.payToBankAccountId(bankAccountId);
      this.rifDetail.payToAccountId(individualAccountId);
    });

    this._setUI();
  }

  _setStartYearOptions() {
    this.startYearOptions = [moment().year(), moment().year() + 1];
    if (
      !this._isUndefinedOrNull(this.rifDetail.startYear()) &&
      !this.startYearOptions.includes(this.rifDetail.startYear())
    ) {
      this.startYearOptions.unshift(this.rifDetail.startYear());
    }
  }

  _setFirstPaymentMonth() {
    if (
      !this._isUndefinedOrNull(this.rifDetail.startMonth()) &&
      !this._isUndefinedOrNull(this.rifDetail.startYear())
    ) {
      this.firstPaymentMonth = moment()
        .month(this.rifDetail.startMonth())
        .year(this.rifDetail.startYear())
        .format('MMMM YYYY');
    }
  }

  _setPaymentDateLabel() {
    if (this.rifDetail.paymentDayOfMonth()) {
      this.paymentDateLabel = _.find(this.paymentDateLabels, (labelData) => {
        return labelData.value === this.rifDetail.paymentDayOfMonth();
      }).label;
    }
  }

  _setPaymentAmount() {
    if (this.rifDetail.paymentAmountType.is.minimum()) {
      this.rifDetail.paymentAmount(null);
      this.rifDetail.paymentAmountTaxId(null);
    }
  }

  _setTaxWithholdingPercentLabel() {
    if (this.rifDetail.taxWithholdingPercent()) {
      this.taxWithholdingPercentLabel = this.rifDetail.taxWithholdingPercent() + '%';
    } else {
      this.taxWithholdingPercentLabel = null;
    }
  }

  _setPayToLabel() {
    if (this.rifDetail.payToBankAccountId()) {
      this.payToLabel = 'Bank account - ' + this.rifDetail.payToBankAccount().label();
    } else if (this.rifDetail.payToAccountId()) {
      this.payToLabel = 'Account - ' + this.rifDetail.payToAccount().label();
    }
  }

  _setUI() {
    this.dateInThePastError = false;
    this._setStartYearOptions();
    this._setFirstPaymentMonth();
    this._setPaymentDateLabel();
    this._setTaxWithholdingPercentLabel();
    this._setPayToLabel();
  }

  _isUndefinedOrNull(value) {
    return value === undefined || value === null;
  }

  _reload() {
    return this.rifDetail.fetch(true).then(() => {
      const promises = [
        this.rifDetail.payToAccount.ready(true),
        this.rifDetail.payToBankAccount.ready(true),
      ];

      this.$q.all(promises).then(() => {
        this._setUI();
      });
    });
  }

  _setStartDateInputValidity(inputController, validity) {
    inputController.$setDirty();
    inputController.$setValidity('dateinthepast', validity);
  }

  _setStartDateValidity(formController, validity) {
    this._setStartDateInputValidity(formController.startYear, validity);
    this._setStartDateInputValidity(formController.startMonth, validity);
    this._setStartDateInputValidity(formController.paymentDayOfMonth, validity);
  }

  // Returns the start date which will be at the begining of its day
  // e.g. The start date is February 15th, 2017 so the moment-date
  // returned is: Wed Feb 15 2017 00:00:00 GMT-0800 (Pacific Standard Time)
  _getStartDate(day, month, year) {
    if (day === 30 && month === 1) {
      return moment([year, month]).endOf('month').startOf('day');
    } else {
      return moment([year, month, day]);
    }
  }

  _saveRifDetail() {
    return this.$http.put('/api/rif_details/' + this.rifDetail.id + '/super_advisor_update.json', {
      paymentFrequencyId: this.rifDetail.paymentFrequencyId(),
      paymentDayOfMonth: this.rifDetail.paymentDayOfMonth(),
      startMonth: this.rifDetail.startMonth(),
      startYear: this.rifDetail.startYear(),
      paymentAmountTypeId: this.rifDetail.paymentAmountTypeId(),
      withdrawalAmount: this.rifDetail.withdrawalAmount(),
      withdrawalAmountValidUntil: this.rifDetail.withdrawalAmountValidUntil(),
      paymentAmount: this.rifDetail.paymentAmount(),
      paymentAmountTaxId: this.rifDetail.paymentAmountTaxId(),
      taxWithholdingTypeId: this.rifDetail.taxWithholdingTypeId(),
      taxWithholdingPercent: this.rifDetail.taxWithholdingPercent(),
      taxWithholdingAmount: this.rifDetail.taxWithholdingAmount(),
      payToBankAccountId: this.rifDetail.payToBankAccountId(),
      payToAccountId: this.rifDetail.payToAccountId(),
    });
  }

  accountLabel() {
    if (this.account.type.is.lif()) {
      return 'LIF';
    } else {
      return 'RRIF';
    }
  }

  validatePaymentStart() {
    const paymentDay = this.rifDetail.paymentDayOfMonth();
    const startMonth = this.rifDetail.startMonth();
    const startYear = this.rifDetail.startYear();

    const formController = this.formModificationTracker.getCurrentForm().rifDetailForm;

    if (paymentDay && startMonth >= 0 && startYear) {
      let validity = null;
      if (this._getStartDate(paymentDay, startMonth, startYear).diff(moment()) < 0) {
        validity = false;
      } else {
        validity = true;
      }

      this.dateInThePastError = !validity;
      this._setStartDateValidity(formController, validity);
    }
  }

  deductTaxAmount(val) {
    if (val) {
      this.rifDetail.taxWithholdingType(this.config.types.RifTaxWithholding.findByName('amount'));
      this.rifDetail.taxWithholdingPercent(null);
    }
    return this.rifDetail.taxWithholdingType.is.amount();
  }

  deductTaxPercent(val) {
    if (val) {
      this.rifDetail.taxWithholdingType(
        this.config.types.RifTaxWithholding.findByName('percentage')
      );
      this.rifDetail.taxWithholdingAmount(null);
    }
    return this.rifDetail.taxWithholdingType.is.percentage();
  }

  save() {
    this._setPaymentAmount();

    return this._saveRifDetail().then(() => {
      return this._reload();
    });
  }

  reset() {
    return this._reload();
  }
}

export default AccountRifDetailController;
