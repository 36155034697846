'use strict';

angular
  .module('model.Custodian', ['ram', 'config'])
  .factory('Custodian', ['ram', 'config', factory]);

function factory(ram, config) {
  const BBS_ID = 2;

  var Custodian = new ram.Collection('Custodian', {
    accessors: ['name', 'code'],
    resources: {
      default: new ram.resources.Http('/api/custodians/:id.json'),
      cookie: new ram.resources.Cookie('custodians'),
    },
    schema: config.schemas.Custodian,
  });

  Custodian.prototype.isCsi = function () {
    return this.code() === 'CSI';
  };

  Custodian.prototype.isBbs = function () {
    return this.code() === 'BBS';
  };

  Custodian.prototype.isFcc = function () {
    return this.code() === 'FCC';
  };

  Custodian.codeFor = function (id) {
    switch (id) {
      case 1:
        return 'CQS'; // Display name for 'CSI'
      case 2:
        return 'CIIS'; // Display name for 'BBS'
      default:
        return '';
    }
  };

  Custodian.bbsId = function () {
    return BBS_ID;
  };
  return Custodian;
}
