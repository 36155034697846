'use strict';

angular
  .module('controller.advise.client-application.beneficiary', [
    'model.Account',
    'model.Beneficiary',
    'component.beneficiary-form-regular',
    'component.beneficiary-form-resp',
    'service.form-helper',
    'component.account-beneficiaries-form',
  ])
  .controller('AdviseClientApplicationBeneficiaryCtrl', [
    '$scope',
    '$state',
    '$cookies',
    '$q',
    'newAccountFlow',
    'client',
    'user',
    'spouse',
    'formHelper',
    'Account',
    adviseClientApplicationBeneficiaryCtrl,
  ]);

function adviseClientApplicationBeneficiaryCtrl(
  $scope,
  $state,
  $cookies,
  $q,
  newAccountFlow,
  client,
  user,
  spouse,
  formHelper,
  Account
) {
  angular.extend($scope, {
    newAccountFlow,
    isResp: newAccountFlow.accountType().resp,
    account: new Account(),
    client,
    spouse,
    autoSave: false,
    subforms: {},
    submitForm,
    cancelAction,
  });

  $scope.account.type = $scope.newAccountFlow.accountType;

  function submitForm(formController) {
    doSave(formController).then(goNext);
  }

  function cancelAction() {
    goNext();
  }

  function doSave() {
    $scope.isSaving = true;
    return $scope.subforms
      .saveBeneficiary()
      .then((promises) => $q.all(promises))
      .finally(function () {
        $scope.isSaving = false;
      });
  }

  function goNext() {
    $state.go('advise.client-application.review');
  }
}
