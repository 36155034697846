'use strict';

/**
 * Circular Progress Indicator
 *
 * Shows what percent is done and how much time is remaining.
 *
 * @return {Object} directive definition
 */
function directive() {
  return {
    replace: true,
    restrict: 'E',
    templateNameSpace: 'svg',
    templateUrl: 'directives/progress-indicator.html',
    scope: true,
    link: function (scope, element, attrs) {
      var _arc = d3.svg
        .arc()
        .outerRadius(60)
        .innerRadius(55)
        .startAngle(0)
        .endAngle(2 * Math.PI);
      var _currentArc = 0;

      var svg = d3.select(element.get(0));
      svg.select('path.background').attr('d', _arc);

      _arc.endAngle(0);
      var arc = svg.select('path.arc').attr('d', _arc);

      var percent = svg
        .select('g')
        .append('text')
        .attr('class', 'percent')
        .attr('text-anchor', 'middle')
        .attr('transform', 'translate(60, 60)');

      svg
        .select('g')
        .append('text')
        .attr('class', 'complete')
        .attr('text-anchor', 'middle')
        .attr('transform', 'translate(60, 80)')
        .text('complete');

      function arcTween(a) {
        var i = d3.interpolate(_currentArc, a);
        return function (t) {
          _currentArc = i(t);
          return _arc.endAngle(i(t))();
        };
      }

      scope.$watchGroup([attrs.current, attrs.total], function (vals) {
        var current = vals[0];
        var total = vals[1];
        var complete = (scope.complete = current / total || 0);

        percent.text(Math.round(complete * 100) + '%');
        arc.datum(complete * 2 * Math.PI);
        arc.transition().duration(750).attrTween('d', arcTween);
      });
    },
  };
}

angular.module('directive.progress-indicator', []).directive('progressIndicator', [directive]);
