'use strict';

function factory() {
  /**
   * This service is a helper for making graphs that combine dollar values that happen on the
   * same day.
   *
   * For things that happen on the same day we want to combine them
   * for the summary view in the dashboard.
   * @param  {Array} inputArray Has objects of type Balance or Transfer
   *                            Both of these objects provide `toDisplayFormat()` methods.
   * @return {Array}            Array with items from the same day combined
   */
  function combineDollarAmountsOnSameDay(inputArray) {
    if (_.isUndefined(inputArray) || inputArray.length === 0) {
      return [];
    }

    // Flatten the inputArray a bit by removing the RAM objects and just dealing with plain JS objects.
    var inputData = _.map(inputArray, function (element) {
      return element.toDisplayFormat();
    });

    // Group by date, then use map to summarize the totals for each day.
    var outputArray = _.chain(inputData)
      .groupBy('date')
      .map(function (collForDate) {
        var total =
          _.reduce(
            collForDate,
            function (sum, dataPoint) {
              return (sum += dataPoint.amount * 100); // Add them up in cents, not dollars
            },
            0
          ) / 100; // Convert the sum in cents back to dollars
        return {
          date: collForDate[0].date, // They all have the same date, so the first is representative.
          amount: total,
        };
      })
      .value();

    return outputArray;
  }

  return {
    process: combineDollarAmountsOnSameDay,
  };
}

angular
  .module('service.combine-dollar-amounts-on-same-day', [])
  .factory('combineDollarAmountsOnSameDay', [factory]);
