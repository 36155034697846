import React, { useState } from 'react';
import DetailLine from './DetailLine';
import FileUpload from '../file-upload/FileUpload';

function UploadMutualFundTrades({
  missingTradeFileInfo,
  csrfToken,
  userNotifications,
  OrderTradeTicket,
}) {
  const [validLines, setValidLines] = useState([]);
  const [invalidLines, setInvalidLines] = useState([]);
  const [missingTradeFiles, setMissingTradeFiles] = useState(missingTradeFileInfo);

  const api = '/api/super_advisor/allotments/upload_mutual_fund_trades.json';
  const tooltipText =
    "The valid file name format for CIIS is 'ModernAdvisorMF20220310.csv'. The column headings are important, but they can be in any order. CQS uses an Excel file.  The valid file name format for CQS is 'Aviso MF Trades - March 10, 2022.xlsx'. Both the column headings and their order match ['Action', 'Client', 'Fund Code/Switch From', 'Switch To Fund Code', 'Dollar Amount', 'Units', 'Account No.', 'Dividend Distribution'] on rows 4 or 5 respectively.";

  function onload({ success, result, resetFileUx }) {
    if (success) {
      userNotifications.showTransientSuccess(
        `File processed ${
          result.invalid_lines.length > 0 ? 'with some unmatched lines' : 'successfully'
        }.`
      );
      setValidLines(result.valid_lines);
      setInvalidLines(result.invalid_lines);
      OrderTradeTicket.constructor
        .outstandingTickets()
        .then((response) => setMissingTradeFiles(response.data))
        .then(() => resetFileUx(true, 'Processed'));
    } else {
      const errorMessage =
        result.error || 'There was an unexpected result. Please try again later.';
      userNotifications.showError(errorMessage);
      resetFileUx(false, 'Error');
    }
  }

  function onerror({ resetFileUx }) {
    userNotifications.showError(
      'Something went wrong while uploading the data. Please try again later.'
    );
    resetFileUx(false, 'Error');
  }

  const noTradeClick = (line) => {
    OrderTradeTicket.constructor
      .create(line.order.id, line.custodian.id)
      .then(() => OrderTradeTicket.constructor.outstandingTickets())
      .then((response) => setMissingTradeFiles(response.data));
  };

  function resetUxCallback() {
    setValidLines([]);
    setInvalidLines([]);
  }

  return (
    <>
      <div className="container">
        <h3>Upload Mutual Fund Trades</h3>
        <div className="panel panel-default">
          <div className="panel-body">
            <div id="user-notification" className="m-b-0-alert-override" />

            <FileUpload
              buttonText="Pick"
              tooltipText={tooltipText}
              api={api}
              acceptList=".csv, .xlsx"
              csrfToken={csrfToken}
              onload={onload}
              onerror={onerror}
              resetUxCallback={resetUxCallback}
            />

            <h4>Orders without trade tickets</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Custodian</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {missingTradeFiles.length === 0 && (
                  <tr>
                    <td colSpan="99" align="center">
                      <i className="text-muted">No orders without trade tickets</i>
                    </td>
                  </tr>
                )}
                {missingTradeFiles.length > 0 &&
                  missingTradeFiles.map((line, index) => (
                    <tr key={index}>
                      <td>{line.order.id}</td>
                      <td>{moment(line.order.createdAt).format('YYYY-MM-DD hh:mm')}</td>
                      <td>{line.custodian.name}</td>
                      <td>
                        <button
                          type={'button'}
                          className={'btn btn-white h-30px w-100px border-grey p-t-4'}
                          onClick={() => noTradeClick(line)}
                        >
                          No trade file
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <DetailLine title={'Allotments In This Order'} data={validLines} />
            <DetailLine title={'Unmatched Lines'} data={invalidLines} />
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadMutualFundTrades;
