'use strict';

angular.module('service.filtered-document-holder', []).factory('FilteredDocumentHolder', [factory]);

function factory() {
  function FilteredDocumentHolder(documents) {
    this.documents = documents;
    this.filteredSet = null;
  }

  FilteredDocumentHolder.prototype.reset = function () {
    this.filteredSet = this.documents;
    return this;
  };

  FilteredDocumentHolder.prototype.getYears = function () {
    return _.chain(this.documents).map(_year).sortBy(_keyForReversedSort).uniq(true).value();
  };

  FilteredDocumentHolder.prototype.filterByYear = function (year) {
    var reference = moment();
    var start;
    var end;

    if (year === 'all') {
      return this;
    } else if (year === 'ytd') {
      end = reference.clone().endOf('day').toDate();
      start = reference.clone().startOf('day').subtract(1, 'year').toDate();
    } else {
      reference = moment(year + '0101', 'YYYYMMDD');
      start = reference.clone().startOf('year').toDate();
      end = reference.clone().endOf('year').toDate();
    }

    this.filteredSet = _.filter(this.filteredSet, function (document) {
      var documentCreatedAt = moment(document.createdAt()).toDate();
      return start <= documentCreatedAt && documentCreatedAt <= end;
    });

    return this;
  };

  FilteredDocumentHolder.prototype.filterByAccount = function (accountId) {
    if (accountId === 'all') {
      // Special case
      return this;
    }

    var filterPredicate = accountId === 'user' ? _userDocumentFilter : _accountsFilter;
    this.filteredSet = _.filter(this.filteredSet, filterPredicate);

    // ----- Helpers -----
    function _accountsFilter(document) {
      return document.relatedToId() === accountId && document.relatedToType() === 'Account';
    }

    function _userDocumentFilter(document) {
      return document.relatedToType() !== 'Account';
    }

    return this;
  };

  FilteredDocumentHolder.prototype.filterByDocumentType = function (documentTypeId) {
    if (_.isUndefined(documentTypeId)) {
      // Since this one is converted to the ID automatically, the 'all' case is undefined
      return this;
    }

    this.filteredSet = _.filter(this.filteredSet, function (document) {
      if (
        document.type().matchingFilterTypeId !== undefined &&
        document.type().matchingFilterTypeId !== null
      ) {
        return document.type().matchingFilterTypeId === documentTypeId;
      }

      return document.typeId() === documentTypeId;
    });

    return this;
  };

  FilteredDocumentHolder.prototype.result = function () {
    return this.filteredSet;
  };

  function _year(document) {
    return moment(document.createdAt()).year();
  }

  function _keyForReversedSort(number) {
    return -1 * number;
  }

  return FilteredDocumentHolder;
}
