'use strict';

angular
  .module('directive.bank-account-formatted-display', [])
  .directive('bankAccountFormattedDisplay', [bankAccountFormattedDisplayDirective]);

function bankAccountFormattedDisplayDirective() {
  return {
    restrict: 'E',
    scope: {
      bankAccount: '=',
      status: '@',
    },
    templateUrl: 'directives/bank-account-formatted-display.html',
    controller: [
      '$scope',
      function ($scope) {
        $scope.displayStatus = _.isUndefined($scope.status) || $scope.status === 'true';
      },
    ],
  };
}
