'use strict';

var resendForms = angular.module('controller.modal.advisor.resend-forms', ['model.User']);

resendForms.controller('ModalAdvisorResendFormsCtrl', [
  '$scope',
  'User',
  'accountReadModel',
  'applicationReadModel',
  'clientFlow',
  'config',
  resendFormsController,
]);

function resendFormsController(
  $scope,
  User,
  accountReadModel,
  applicationReadModel,
  clientFlow,
  config
) {
  $scope.resendDocs = true;
  $scope.accountReadModel = accountReadModel;
  $scope.applicationReadModel = applicationReadModel;
  $scope.clientFlow = clientFlow;
  $scope.DocumentsArr = [];
  $scope.data = {
    hasPrefill: applicationReadModel && applicationReadModel.advisorCreateNewAccountFlowId(),
    client: null,
    account: null,
    startDate: null,
    prefillDate: null,
    passwordSet: null,
    applicationCompleted: false,
    sentAt: null,
    openedAt: null,
    rejectedAt: null,
  };

  if (!accountReadModel && applicationReadModel) {
    $scope.data.client = _trimOrDefault(applicationReadModel.clientName());
    /* beautify ignore:start */
    $scope.data.account =
      applicationReadModel.type()?.statementName || applicationReadModel.type()?.label; // jshint ignore:line
    /* beautify ignore:end */

    $scope.data.prefillDate = applicationReadModel.startedAt();
    $scope.data.sentAt = applicationReadModel.sentAt();
    $scope.data.openedAt = applicationReadModel.openedAt();
    $scope.data.passwordSet = clientFlow && clientFlow.status() === 'completed';
    $scope.data.rejectedAt = applicationReadModel.rejectedAt();
    $scope.data.noDataPresent =
      !applicationReadModel.accountId() && !applicationReadModel.advisorCreateNewAccountFlowId();
  } else if (accountReadModel && $scope.data.hasPrefill) {
    $scope.data.client = accountReadModel.clientName();
    $scope.data.account = accountReadModel.type().statementName || accountReadModel.type().label;
    $scope.data.startDate = accountReadModel.startedAt();
    $scope.data.prefillDate = applicationReadModel.startedAt();
    $scope.data.sentAt = applicationReadModel.sentAt();
    $scope.data.openedAt = applicationReadModel.openedAt();
    $scope.data.rejectedAt = applicationReadModel.rejectedAt();
  } else {
    $scope.data.client = accountReadModel.clientName();
    $scope.data.account = accountReadModel.type().statementName || accountReadModel.type().label;
    $scope.data.startDate = accountReadModel.startedAt();
  }

  if (accountReadModel) {
    accountReadModel.approvalDetails = {};
    accountReadModel.approvalDetails.loading = true;
    loadApplicationStatus();
  }

  function _trimOrDefault(clientName) {
    if (clientName.trim().length === 0) {
      return '(blank)';
    }
    return clientName;
  }

  function loadApplicationStatus() {
    accountReadModel
      .applicationStatus()
      .then(
        function (result) {
          accountReadModel.approvalDetails = result.data;
          $scope.data.applicationCompleted = accountReadModel.approvalDetails.applicationCompleted;

          const progress = accountReadModel.approvalDetails.progress;
          const lastStep = 14;
          const percentage = Math.min(progress / lastStep, 1) * 100;

          accountReadModel.approvalDetails.progressStyle = {
            width: percentage + '%',
          };
        },
        function () {
          accountReadModel.approvalDetails.error = true;
        }
      )
      .finally(function () {
        accountReadModel.approvalDetails.loading = false;
      });
    $scope.DocumentsArr = [];
  }

  $scope.addDocToResend = function (event) {
    if (event.target.checked) {
      $scope.DocumentsArr.push(event.target.value);
    } else {
      var index = $scope.DocumentsArr.indexOf(event.target.value);
      $scope.DocumentsArr.splice(index, 1);
    }
  };

  $scope.regenerateForms = function () {
    const docLabels = $scope.DocumentsArr.map(function (docType) {
      return _.findWhere(config.types.Document, {
        name: docType,
      }).label;
    });

    $scope
      .genericConfirmationModal(
        'Resend documents?',
        'Are you sure you want to regenerate and resend the ' +
          docLabels.join(', ') +
          ' for this account application?',
        'btn-success'
      )
      .result.then(function () {
        $scope.$root.showLoading();
        return $scope.accountReadModel.regenerateForms($scope.DocumentsArr);
      })
      .finally(function () {
        $scope.$root.hideLoading();
        loadApplicationStatus();
      });
  };

  $scope.sendReconfirmation = function () {
    User.advisorResendConfirmationEmail(accountReadModel.userId());
  };
}
