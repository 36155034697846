'use strict';

/**
 * Initialize angular UI router for home states.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider) {
  $stateProvider.state('optimizing', {
    params: {
      redirect: 'preview.investments',
      after: 2000,
    },
    parent: 'site',
    data: {
      interviewStep: 1,
    },
    views: {
      main: {
        controller: 'OptimizingCtrl',
      },
      navbar: {
        templateUrl: 'navbar/preview.html',
      },
      footer: {
        templateUrl: adaptiveViewsProvider.isMobile()
          ? 'mobile/partials/footer.html'
          : 'partials/footer-minimal.html',
      },
    },
  });
}

angular
  .module('routes.optimizing', [
    'controller.optimizing',
    'templates',
    'ui.router',
    'service.adaptive-views',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', configRoutes]);
