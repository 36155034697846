'use strict';

import { errorStatus } from './eft-validation-service/eft-validation-service.service';
import { errorText } from './eft-validation-service/eft-validation-service.rules';

angular
  .module('service.transfer-account-data-provider', ['model.Account', 'model.BankAccount'])
  .factory('transferAccountDataProvider', ['$filter', factory]);

function factory($filter) {
  var _accounts;
  var _bankAccounts;

  function _isAccountLike(type) {
    return type === 'Account' || type === 'AdvisorCreateNewAccountFlow';
  }

  var _getModernAdvisorAccountId = function (mode, transferInstruction) {
    var id =
      mode === 'from' ? transferInstruction.transferFromId() : transferInstruction.transferToId();
    return id;
  };

  var _getModernAdvisorAccount = function (accountId) {
    return _.find(_accounts, function (account) {
      return account.id === accountId;
    });
  };

  var _getBankAccountId = function (mode, transferInstruction) {
    var id =
      mode === 'from' ? transferInstruction.transferFromId() : transferInstruction.transferToId();
    return id;
  };

  var _getBankAccount = function (bankAccountId) {
    return _.find(_bankAccounts, function (bankAccount) {
      return bankAccount.id === bankAccountId;
    });
  };

  var _getCancelText = function (transferInstruction, options) {
    if (transferInstruction.frequency.is.onlyOnce()) {
      return '';
    }

    var output;
    if (transferInstruction.manualCancel()) {
      output = 'until cancelled';
    } else if (transferInstruction.maxOccurrence()) {
      output = 'for ' + transferInstruction.maxOccurrence() + ' transfers';
    } else {
      output = 'ending ' + $filter('date')(transferInstruction.continueUntil());
    }

    if (options && options.capitalize) {
      output = output.charAt(0).toUpperCase() + output.slice(1);
    }

    return output;
  };

  var _getTransferNotes = function (transferInstruction) {
    if (!transferInstruction) {
      return null;
    }
    if (transferInstruction.frequency.is.semiMonthly()) {
      return 'Your transfers will occur on the 1st and 15th of the month.';
    }
    return null;
  };

  function getDateLabel(transferInstruction) {
    if (transferInstruction.frequency.is.onlyOnce()) {
      return 'Date';
    } else {
      return 'Start Date';
    }
  }

  function setInputs(currentUser, accounts, bankAccounts) {
    _accounts =
      accounts.length > 1 && accounts[0].title === 'Combined' ? accounts.slice(1) : accounts;
    _bankAccounts = bankAccounts;
  }

  function getAccount(whichAccount, transferInstruction) {
    var type;
    var id;
    if (whichAccount === 'from') {
      type = transferInstruction.transferFromType();
    } else {
      type = transferInstruction.transferToType();
    }
    if (_isAccountLike(type)) {
      id = _getModernAdvisorAccountId(whichAccount, transferInstruction);
      return _getModernAdvisorAccount(id);
    } else {
      id = _getBankAccountId(whichAccount, transferInstruction);
      return _getBankAccount(id);
    }
  }

  function getAccountById(accountType, accountId) {
    if (accountType === 'Account') {
      return _getModernAdvisorAccount(accountId);
    } else {
      return _getBankAccount(accountId);
    }
  }

  function hasUnopenedAccount(transferInstruction, accountFrom, accountTo) {
    return (
      (_isAccountLike(transferInstruction.transferFromType()) && !accountFrom.status?.is?.open()) ||
      (_isAccountLike(transferInstruction.transferToType()) && !accountTo.status?.is?.open())
    );
  }

  function getConfirmationData(confirmationMode, transferInstruction, eftCheckResult) {
    var accountFrom = getAccount('from', transferInstruction);
    var accountTo = getAccount('to', transferInstruction);
    var data = {
      confirmationMode,
      fromAccount: accountFrom,
      toAccount: accountTo,
      displayAmount: Math.abs(transferInstruction.amount()),
      frequency: transferInstruction.frequency().label,
      dateLabel: getDateLabel(transferInstruction),
      cancelText: _getCancelText(transferInstruction, {
        capitalize: true,
      }),
      transferNotes: _getTransferNotes(transferInstruction),
      hasUnopenedAccount: hasUnopenedAccount(transferInstruction, accountFrom, accountTo),
      displayTransferId: getDisplayTransferId(transferInstruction),
      transferInstruction,
      accountLabel: '',
      accountNumber: '',
      confirmMessage: '',
      inProgressTransfer: null,
    };

    function _verb(transferFromType) {
      if (transferFromType === 'Account') {
        return 'type';
      } else if (transferFromType === 'AdvisorCreateNewAccountFlow') {
        return 'accountType';
      } else {
        return null; // should never happen
      }
    }

    if (_isAccountLike(transferInstruction.transferFromType())) {
      const accountLabel = accountFrom.type().label;
      const accountNumber =
        transferInstruction.transferFromType() === 'Account' ? accountFrom.number() : null; // Use null for BankAccount AND AdvisorCreateNewAccountFlow
      let confirmMessage = '';
      const verb = _verb(transferInstruction.transferFromType());
      if (accountFrom[verb].is.individualRrsp() || accountFrom[verb].is.spousalRrsp()) {
        confirmMessage = 'RRSP';
      } else if (accountFrom[verb].is.tfsa()) {
        confirmMessage = 'TFSA';
      }

      _.extend(data, {
        accountLabel,
        accountNumber,
        confirmMessage,
        eftCheckResult: _getEftCheckResult(eftCheckResult, transferInstruction, confirmMessage),
      });
    }

    if (transferInstruction.id) {
      // Transfers that are just created do not have an id yet and cannot call transferRequests()
      const upcomingAndInProgressTransferRequests = transferInstruction
        .transferRequests()
        ?.filter((transferRequest) => {
          if (transferRequest.custodianIsBbs()) {
            return transferRequest.status.is.inProgress() && transferRequest.sentAt() !== null;
          } else {
            return transferRequest.status.is.inProgress();
          }
        });

      if (upcomingAndInProgressTransferRequests?.length > 0) {
        data.inProgressTransfer = upcomingAndInProgressTransferRequests.first();
      }
    }
    return data;
  }

  function _getEftCheckResult(eftCheckResult, transferInstruction, confirmMessage) {
    if (eftCheckResult && transferInstruction.type.is.transfer()) {
      // Transfer needs an `eftCheckResult` to be displayed in the confirmation page.
      if (eftCheckResult.status !== errorStatus.warning) {
        return null;
      }
      return eftCheckResult;
    } else if (confirmMessage === 'RRSP') {
      // RRSP and TFSA don't require an `eftCheckResult` to be displayed in the confirmation page at this time.
      return {
        // for now, for RRSP withdrawals, display all text in the bottom box.  Don't use
        // the top box at all until we find a UX solution that prevents two top yellow boxes.
        message: null,
        moreInfo: errorText.rrsp_withdrawal.text + ' ' + errorText.rrsp_withdrawal_info.text, // jshint ignore:line
        alertStyle: 'alert-warning',
      };
    } else if (confirmMessage === 'TFSA') {
      return {
        // for now, for TFSA withdrawals, display all text in the bottom box.  Don't use
        // the top box at all until we find a UX solution that prevents two top yellow boxes.
        message: null,
        moreInfo: errorText.tfsa_withdrawal.text, // jshint ignore:line
        alertStyle: 'alert-warning',
      };
    }
  }

  function getDisplayTransferId(transferInstruction) {
    if (!transferInstruction.id) {
      return '';
    }
    return s.sprintf(
      '%03d-%05d',
      getAccountById(transferInstruction.transferFromType(), transferInstruction.transferFromId())
        .id,
      transferInstruction.id
    );
  }

  function getDetailsMessage(transferInstruction) {
    return transferInstruction.frequency().label + ' ' + _getCancelText(transferInstruction);
  }

  // Quick replacement for Array.prototype.at(-1) which is not supported in old versions of Safari
  function lastFor(array) {
    return array[array.length - 1];
  }

  function getSummaryMessage(transferInstruction, targetAccount) {
    if (transferInstruction.cancelled()) {
      return 'Cancelled on ' + $filter('date')(transferInstruction.cancelledAt());
    }
    if (transferInstruction.status.is.finished()) {
      const lastTransfer = lastFor(transferInstruction.transferRequests());
      return 'Completed on ' + $filter('date')(lastTransfer.dateToBeProcessed());
    }

    if (transferInstruction.frequency.is.onlyOnce()) {
      return '';
    }

    const type = transferInstruction.transferToId() === targetAccount.id ? 'deposit' : 'withdrawal';
    let date = '';
    const transferRequest = transferInstruction.mostRecentTransferRequest();
    if (transferRequest && transferRequest.status.is.upcoming()) {
      date = $filter('date')(transferRequest.dateToBeProcessed());
    } else {
      date = $filter('date')(transferInstruction.nextTransferDate());
    }
    return date ? `Next ${type}: ${date}` : '';
  }

  function getCancelledInProgressMessage(transferInstruction) {
    if (transferInstruction.cancelled()) {
      const inProgressTransfers = transferInstruction
        .transferRequests()
        .filter((transferRequest) => transferRequest.status.is.inProgress());
      if (inProgressTransfers.length > 0) {
        const lastTransfer = lastFor(inProgressTransfers);
        return 'The final transfer is due on ' + $filter('date')(lastTransfer.dateToBeProcessed());
      }
    }
    return null;
  }

  return {
    setInputs,
    displayId: getDisplayTransferId,
    getAccountById,
    getConfirmationData,
    getDateLabel,
    getDetailsMessage,
    getSummaryMessage,
    getCancelledInProgressMessage,
  };
}
