'use strict';

angular
  .module('routes.password', [
    'controller.minimal-footer',
    'controller.password.change',
    'controller.password.recover',
    'controller.password.reset',
    'controller.password.advisor-initial-set',
    'controller.navbar.advise',
    'templates',
    'ui.router',
    'service.adaptive-views',
    'service.two-factor-authenticator',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', configRoutes]);

/**
 * Initialize angular UI router for password management.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider) {
  function resolveOneTimeUserName($stateParams) {
    return $stateParams.u;
  }

  function resolveOneTimePassword($stateParams) {
    return $stateParams.p;
  }

  function resolveAdvisorFirstName($http, $stateParams) {
    return $http
      .get(`/api/advisor/advisor_name.json?one_time_user_name=${$stateParams.u}`)
      .then((response) => {
        return response.data.firstName;
      })
      .catch((error) => {
        console.warn(`User name could not be fetched - ${error.statusText}`);
      });
  }

  $stateProvider
    .state('password', {
      abstract: true,
      parent: 'site',
      url: '/password',
      hasMobileView: true,
      views: {
        main: {
          templateUrl: adaptiveViewsProvider.getTemplateUrlForSrc('views/password.html', true),
        },
        navbar: {
          templateUrl: 'navbar/password.html',
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
    })
    .state('password.change', {
      url: '/change',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordChangeController',
    })
    .state('password.recover', {
      url: '/recover',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordRecoverController',
    })
    .state('password.reset', {
      url: '/reset?token',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordResetController',
    })
    .state('password.advisor-initial-set', {
      url: '/advisor-initial-set?u&p',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordAdvisorInitialSetController',
      resolve: {
        oneTimeUserName: ['$stateParams', resolveOneTimeUserName],
        oneTimePassword: ['$stateParams', resolveOneTimePassword],
        advisorFirstName: ['$http', '$stateParams', resolveAdvisorFirstName],
      },
    });
}
