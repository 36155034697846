'use strict';

angular
  .module('controller.advise.transfer-requests', [
    'controller.advise.transfer-requests.all-transfer-requests',
    'controller.advise.transfer-requests.bbs-upcoming-deposits-withdrawals',
    'controller.advise.transfer-requests.bbs-upcoming-internal-transfers',
    'controller.advise.transfer-requests.unmatched-transfers',
    'controller.advise.transfer-requests.account-transfers',
    'service.modals',
  ])
  .controller('AdviseTransferRequestsCtrl', [
    '$scope',
    'unmatchedTransferNotifications',
    'unmatchedTransfers',
    'modals',
    adviseTransferRequestsController,
  ]);

function adviseTransferRequestsController(
  $scope,
  unmatchedTransferNotifications,
  unmatchedTransfers,
  modals
) {
  $scope.transferInstructions = [];
  $scope.unmatchedTransfers = {
    notifications: unmatchedTransferNotifications,
    transfers: unmatchedTransfers,
  };

  $scope.actionManagerAllRequests = {};
  $scope.actionManagerAccountTransfers = {};

  $scope.loadStatus = function (accountTransfer) {
    modals.accountTransferModal(accountTransfer).result.then(function () {});
  };

  $scope.onChangeTabDoResetAllRequests = function () {
    $scope.actionManagerAllRequests.reset();
  };

  $scope.onChangeTabDoResetAccountTransfers = function () {
    $scope.actionManagerAccountTransfers.reset();
  };

  $scope.numColumns = 12; // manual count by programmer.
}
