'use strict';

angular.module('service.download-csv', []).factory('downloadCsv', [downloadCsvFactory]);

function downloadCsvFactory() {
  function downloadBalancesCsv(accounts) {
    var csvRows = ['account number' + ',' + 'date' + ',' + 'balance'];
    var accountsProcessed = [];

    accounts.forEach(function (account) {
      accountsProcessed.push(account.number());
      let lastBalance = account.balances()[0];
      let calendarDate = moment(lastBalance.date());
      const oneYearAgo = moment().subtract(1, 'years');

      account.balances().forEach(function (balance) {
        if (moment(balance.date()).isAfter(oneYearAgo, 'day')) {
          while (calendarDate.isBefore(balance.date(), 'day')) {
            _pushData(csvRows, account.number(), calendarDate, lastBalance.amount());
            calendarDate = calendarDate.add(1, 'days');
          }
        }
        _pushData(csvRows, account.number(), moment(balance.date()), balance.amount());
        calendarDate = moment(balance.date()).add(1, 'days');
        lastBalance = balance;
      });
    });

    var csvString = csvRows.join('\n');
    var downloadButton = document.getElementById('download-balances');
    downloadButton.href = 'data:attachment/csv,' + encodeURIComponent(csvString);

    // Currently assumes all accounts belong to the same client
    var filename =
      accounts[0].clientName() + ' - balances for accounts ' + accountsProcessed.join(' ') + '.csv';

    downloadButton.download = filename;
  }

  function _pushData(csvRows, number, date, balance) {
    csvRows.push(`${number},${date.format('YYYY-MM-DD')},${balance}`);
  }

  return {
    balances: downloadBalancesCsv,
  };
}
