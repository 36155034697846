import React from 'react';

interface PropTypes {
  text: string;
}

export default function HelpTooltip({ text }: PropTypes) {
  return (
    <i
      className="fa fa-question-circle"
      title={text}
      data-toggle="tooltip"
      data-placement="right"
    />
  );
}
