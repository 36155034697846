'use strict';

angular
  .module('routes.advise', [
    'controller.advise',
    'controller.minimal-footer',
    'controller.navbar.advise',
    'model.Account',
    'model.AccountApproval',
    'model.AccountTransfer',
    'model.Audit',
    'model.ApplicationReadModel',
    'model.FeeCreditAccount',
    'model.Issue',
    'model.Order',
    'model.Promotion',
    'model.TransferRequest',
    'model.UpdateRecord',
    'model.User',
    'model.UpdateRecord',
    'model.Referral',
    'model.ReferralPayment',
    'model.AdvisorEftDataset',
    'model.ModelPortfolio',
    'model.Person',
    'model.AdvisorCreateNewAccountFlow',
    'model.AdvisorCreateNewTransferFlow',
    'query.notifications.unmatched-transfer',
    'query.transfers.unmatched',
    'routes.advise.add-client',
    'routes.advise.advisor-reports',
    'routes.advise.create-client',
    'routes.advise.client',
    'routes.advise.client-application',
    'routes.advise.adaptive-tactical-edit',
    'routes.advise.trade-reconciliation',
    'routes.advise.bulk-id-verification',
    'routes.advise.person',
    'routes.advise.csv-reports-view',
    'routes.advise.create-external-advisor',
    'routes.advise.upload-mutual-fund-trades',
    'react.component.advise.upload-changed-charge-records',
    'service.adaptive-views',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', configRoutes]);

/**
 * Initialize angular UI router for advisor dashboard.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider) {
  function resolveIsDesktopSafari() {
    return adaptiveViewsProvider.isDesktopSafari();
  }

  function resolveNewAccountFlows(person, AdvisorCreateNewAccountFlow) {
    return AdvisorCreateNewAccountFlow.where({
      clientId: person.id,
    }).then(function (newAccountFlows) {
      return _.filter(newAccountFlows, function (newAccountFlow) {
        return newAccountFlow.clientId() === person.id;
      });
    });
  }

  function resolveClient(User, $stateParams) {
    return User.find({
      id: $stateParams.id,
    });
  }

  function resolveAccount(Account, $q, $stateParams) {
    var account;
    return Account.find({
      id: $stateParams.accountId,
    })
      .then(function (_account) {
        account = _account;
        return account.positions.ready();
      })
      .then(function (positions) {
        return $q.all(
          _.map(positions, function (position) {
            return position.fund.ready();
          })
        );
      })
      .then(function () {
        return account.notes.ready();
      })
      .then(function () {
        return (
          account.rifDetail() &&
          $q.all([
            account.rifDetail().payToBankAccount.ready(),
            account.rifDetail().payToAccount.ready(),
          ])
        );
      })
      .then(function () {
        return account;
      });
  }

  function resolveAccountApprovals(AccountApproval, account) {
    return AccountApproval.where({
      accountId: account.id,
    }).then((list) => list.filter((item) => !!item.note()));
  }

  function resolveTransferInstruction(TransferInstruction, $stateParams) {
    return TransferInstruction.find({
      id: $stateParams.id,
    });
  }

  function resolveStatements(client, account) {
    return client.documents.ready().then(function (documents) {
      return _.chain(documents)
        .filter(function (doc) {
          return (
            doc.type().statement === true &&
            doc.relatedToType() === 'Account' &&
            doc.relatedToId() === account.id
          );
        })
        .value();
    });
  }

  function resolveAccGoalAudits(account) {
    return account.accountGoals()[0].goal().audits();
  }

  function resolveActivities(account) {
    return account.activities.ready(true);
  }

  function resolveOrders(Order) {
    return Order.where(
      {},
      {
        force: true,
      }
    );
  }

  function resolveOrder(Order, $stateParams) {
    return Order.find({
      id: $stateParams.id,
    });
  }

  function resolvePromotions(Promotion) {
    return Promotion.where({});
  }

  function resolveIssue(Issue, $stateParams) {
    return Issue.find({
      id: $stateParams.id,
    });
  }

  function resolveAssocUserId(Account, UpdateRecord, BankAccount, TransferRequest, issue) {
    var type = issue.relatedToType();
    var id = issue.relatedToId();
    if (type === 'User') {
      return id;
    } else if (type === 'UpdateRecord') {
      return resolveAssocUserIdFromUpdateRecord(UpdateRecord, Account, id);
    } else if (type === 'Account') {
      return resolveAssocUserIdFromAccountId(Account, id);
    } else if (type === 'BankAccount') {
      return resolveAssocUserIdFromBankAccountId(BankAccount, id);
    } else if (type === 'TransferRequest') {
      return resolveAssocUserIdFromTransferRequestId(TransferRequest, id);
    }
  }

  function resolveAssocUserIdFromTransferRequestId(TransferRequest, transferRequestid) {
    var transferRequestResult;
    return TransferRequest.find({
      id: transferRequestid,
    })
      .then(function (value) {
        transferRequestResult = value;
        return value.transferInstruction.ready();
      })
      .then(function () {
        return transferRequestResult.transferInstruction().userId();
      })
      .catch(function () {
        return null;
      });
  }

  function resolveAssocUserIdFromBankAccountId(BankAccount, bankAccountId) {
    return BankAccount.find({
      id: bankAccountId,
    }).then(
      function (value) {
        return value.userId();
      },
      function () {
        return null;
      }
    );
  }

  function resolveAssocUserIdFromAccountId(Account, accountId) {
    return Account.find({
      id: accountId,
    }).then(
      function (value) {
        return value.userId();
      },
      function () {
        return null;
      }
    );
  }

  function resolveAssocUserIdFromUpdateRecord(UpdateRecord, Account, updateRecordId) {
    return UpdateRecord.find({
      id: updateRecordId,
    }).then(
      function (updateRecord) {
        if (updateRecord.rootEntityType() === 'User') {
          return updateRecord.rootEntityId();
        } else if (updateRecord.rootEntityType() === 'Account') {
          return resolveAssocUserIdFromAccountId(Account, updateRecord.rootEntityId());
        }
      },
      function () {
        return null;
      }
    );
  }

  function redirectToAccount($state, Account, $stateParams) {
    return Account.find({
      id: $stateParams.id,
    }).then(
      function (value) {
        $state.go('advise.account', {
          id: value.userId(),
          accountId: $stateParams.id,
        });
      },
      function (reason) {
        console.log('Error fetching associated user id.');
        console.log(reason);
      }
    );
  }

  function redirectToBankAccount($state, BankAccount, $stateParams) {
    return BankAccount.find({
      id: $stateParams.id,
    }).then(
      function (value) {
        $state.go('advise.client.view', {
          id: value.userId(),
          '#': 'bank-accounts',
        });
      },
      function (reason) {
        console.log('Error fetching associated user id.');
        console.log(reason);
      }
    );
  }

  function redirectToTransferInstruction($state, TransferRequest, $stateParams) {
    return TransferRequest.find({
      id: $stateParams.id,
    }).then(
      function (value) {
        $state.go('advise.transfer-instruction', {
          id: value.transferInstructionId(),
        });
      },
      function (reason) {
        console.log('Error fetching associated transfer instruction id.');
        console.log(reason);
      }
    );
  }

  function resolveAssocUser(User, issue, assocUserId) {
    var assocUser, temp;
    if (issue.relatedToType() === 'User') {
      temp = User.find({
        id: issue.relatedToId(),
      }).then(
        function (value) {
          assocUser = value;
        },
        function (reason) {
          console.log('Error fetching user...');
          console.log(reason);
        }
      );
    } else {
      if (!assocUserId) {
        return null;
      }
      temp = User.find({
        id: assocUserId,
      }).then(
        function (value) {
          assocUser = value;
        },
        function (reason) {
          console.log('Error fetching user...');
          console.log(reason);
        }
      );
    }
    return temp.then(function () {
      return assocUser;
    });
  }

  function resolveOpenUpdateRecords(UpdateRecord) {
    return UpdateRecord.where({
      status: 'Open',
    });
  }

  function resolveClosedUpdateRecords(UpdateRecord) {
    return UpdateRecord.where({
      status: 'Closed',
    });
  }

  function resolveUpdateRecords(UpdateRecord) {
    return UpdateRecord.where({});
  }

  function checkPermission(authorizer, requiredPermission) {
    return authorizer.checkPermission(requiredPermission);
  }

  function authorizeSuperAdvisor(authorizer) {
    return checkPermission(authorizer, 'superAdvisor');
  }

  function authorizeTrade(authorizer) {
    return checkPermission(authorizer, 'trade');
  }

  function resolveClientAccounts(client) {
    return client.accounts.ready();
  }

  function resolveClientTransferableAccounts($http, Account, client) {
    return $http
      .get('/api/accounts/transferable_accounts_for_user.json?user_id=' + client.id)
      .then(function (results) {
        return _.map(results.data, function (account) {
          return new Account(account);
        });
      });
  }

  function resolveClientBankAccounts(client) {
    return client.bankAccounts.ready();
  }

  function queryUpdateRecord($http, UpdateRecord, updateRecord, queryType) {
    return $http
      .get('api/update_records/' + updateRecord.id + '/' + queryType + '.json')
      .then(function (response) {
        if (response.data) {
          return UpdateRecord.new(response.data);
        }
      });
  }

  function resolvePreviousUpdateRecord($http, UpdateRecord, updateRecord) {
    return queryUpdateRecord($http, UpdateRecord, updateRecord, 'previous');
  }

  function resolveNewestUpdateRecord($http, UpdateRecord, updateRecord) {
    return queryUpdateRecord($http, UpdateRecord, updateRecord, 'newest');
  }

  function resolveUpdateRecord(UpdateRecord, $stateParams) {
    return UpdateRecord.find({
      id: $stateParams.id,
    });
  }

  function resolveUpdateRecordIssue(Issue, updateRecord) {
    return Issue.find({
      relatedToType: 'UpdateRecord',
      relatedToId: updateRecord.id,
    });
  }

  function resolveOverview($http) {
    return $http.get('/api/advisor/overview.json').then(function (response) {
      if (response.data) {
        return response.data;
      }
    });
  }

  function resolveAssetsUnderManagementHistories($http) {
    return $http
      .get('/api/advisor/assets_under_management_histories.json')
      .then(function (response) {
        if (response.data) {
          return response.data;
        }
      });
  }

  function resolveAdvisorDetails($http) {
    return $http.get('/api/advisor/advisor_details.json').then(function (response) {
      if (response.data) {
        return response.data;
      }
    });
  }

  function resolveRifDetails(RifDetail, $stateParams) {
    var condition = {
      status: 'all',
    };
    var status = $stateParams.status;
    if (status) {
      if (status === 'all') {
        condition = {};
      } else {
        condition.status = status;
      }
    }
    return RifDetail.where(condition);
  }

  function resolveReferrals(Referral, $stateParams) {
    var condition = {
      status: 'signed-up',
    };
    var status = $stateParams.status;
    if (status) {
      if (status === 'all') {
        condition = {};
      } else {
        condition.status = status;
      }
    }
    return Referral.where(condition);
  }

  function resolveReferral(Referral, $stateParams) {
    return Referral.find({
      id: $stateParams.id,
    });
  }

  function resolveReferralPayments(referral) {
    return referral.payments.ready();
  }

  function resolveReferralPaymentsList(ReferralPayment, $stateParams) {
    var condition = {};
    if ($stateParams.state && $stateParams.state !== 'all') {
      condition.state = $stateParams.state;
    }
    return ReferralPayment.where(condition);
  }

  function resolvePerson(Person, client) {
    client.person().initialize();
    return client.person().spouseRelation.ready();
  }

  function resolveBeneficiaries($q, account) {
    var _beneficiaries = null;
    return account.beneficiaries
      .ready()
      .then(function (beneficiaries) {
        _beneficiaries = beneficiaries;
        return $q.all(
          _.map(beneficiaries, function (beneficiary) {
            return beneficiary.loadDependantObjects();
          })
        );
      })
      .then(function () {
        return _beneficiaries;
      });
  }

  function resolveDefaultModelPortfolioSummary($http) {
    return $http
      .get('/api/model_portfolios/summary.json?risk=5&portfolio_option_id=0')
      .then(function (response) {
        return response.data;
      });
  }

  function resolvePortfolioSelectionsWithDefault(defaultModelPortfolio) {
    return {
      core5: {
        allocations: defaultModelPortfolio.allocations,
        mer: defaultModelPortfolio.fee,
        marketValues: defaultModelPortfolio.marketValues,
      },
    };
  }

  function resolveSuccessorHolder(account) {
    return account.successorHolder.ready();
  }

  function resolveAccountHolders($q, Person, client, account) {
    var _jointApplicants = null;
    return account.jointApplicants
      .ready()
      .then(function (jointApplicants) {
        _jointApplicants = jointApplicants;
        var promises = _.map(jointApplicants, function (record) {
          if (record.personId() === null) {
            // Business logic decided 2020.04.06 - Joint Applicant records will all have valid Person IDs
            // Ignore the ones that do not.
            return null;
          }
          return Person.find({
            id: record.personId(),
          });
        });
        return $q.all(_.compact(promises));
      })
      .then(function (jointPeople) {
        var jointObjects = _.map(jointPeople, function (jointPerson) {
          return {
            person: jointPerson,
            jointApplicant: _.find(_jointApplicants, function (ja) {
              return ja.personId() === jointPerson.id;
            }),
          };
        });
        return [
          {
            person: client.person(),
            jointApplicant: null,
          },
        ].concat(jointObjects);
      });
  }

  function resolveTaxableAccounts(client) {
    return client.accounts.ready().then(function (accounts) {
      return _.filter(accounts, function (account) {
        return account.type.is.nonRegistered() && account.number();
      });
    });
  }

  function resolveValidBankAccounts(client, BankAccount) {
    return BankAccount.where(
      {
        userId: client.id,
      },
      {
        force: true,
      }
    ).then(function (bankAccounts) {
      return _.filter(bankAccounts, function (bankAccount) {
        return bankAccount.transferable() || bankAccount.status.is.draft();
      });
    });
  }

  /*****************************************************************************
   * Used solely for the 2016 calculation of xirrs for funded, non-trial       *
   * accounts.                                                                 *
   *****************************************************************************/
  function resolveNonTrialAccs($http, Account) {
    console.log('resolveFundedAccounts start');
    var timeTaken = 0;

    var timerId = window.setInterval(function () {
      timeTaken += 1;
    }, 1000);

    return Account.where({}).then(function (accounts) {
      var masterTrialAccId = 811;

      var nonTrialAccs = accounts.filter(function (acc) {
        return !acc.type.is.trial() && acc.userId() !== masterTrialAccId;
      });
      console.log('Finished getting non-trial accs in ' + timeTaken + ' seconds.');
      clearInterval(timerId);
      return nonTrialAccs;
    });
  }

  function resolveBalances(nonTrialAccs) {
    _.map(nonTrialAccs, function (account) {
      account.createBalancesHistory();
    });
    return true;
  }
  /*****************************************************************************
   * End 2016 xirr calculation resolutions.                                    *
   *****************************************************************************/

  $stateProvider
    .state('advise', {
      url: '/advise',
      parent: 'site',
      resolve: {
        authorize: ['authorizer', (a) => a.checkPermission('view_advisor_dashboard')],
        advisorDetails: ['$http', resolveAdvisorDetails],
      },
      views: {
        main: {
          controller: 'AdviseCtrl',
        },
        navbar: {
          controller: 'NavbarAdviseCtrl',
          templateUrl: 'navbar/advise.html',
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
      abstract: true,
    })
    .state('advise.overview', {
      url: '/overview',
      controller: 'AdviseOverviewCtrl',
      resolve: {
        overview: ['$http', resolveOverview],
        assetsUnderManagementHistories: ['$http', resolveAssetsUnderManagementHistories],
      },
    })
    .state('advise.reports', {
      url: '/reports',
      controller: 'AdviseReportsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeTrade],
        defaultModelPortfolio: ['$http', resolveDefaultModelPortfolioSummary],
        portfolioSelections: ['defaultModelPortfolio', resolvePortfolioSelectionsWithDefault],
      },
    })
    .state('advise.run-fees', {
      url: '/run-fees',
      controller: [() => {}],
      resolve: {},
      template: '<react-upload-changed-charge-records />',
    })
    .state('advise.xirr', {
      url: '/xirr',
      controller: 'AdviseXirrCtrl',
      resolve: {
        nonTrialAccs: ['$http', 'Account', resolveNonTrialAccs],
        balances: ['nonTrialAccs', resolveBalances],
      },
    })
    .state('advise.accounts', {
      url: '',
      controller: 'AdviseAccountsCtrl',
      resolve: {},
    })
    .state('advise.clients', {
      url: '/clients',
      controller: 'AdviseClientsCtrl',
    })
    .state('advise.eft', {
      url: '/client/{id:[0-9]+}/eft',
      resolve: {
        client: ['User', '$stateParams', resolveClient],
        bankAccounts: ['client', resolveClientBankAccounts],
        clientAccounts: ['$http', 'Account', 'client', resolveClientTransferableAccounts],
      },
      controller: 'adviseEftCtrl',
      templateUrl: 'views/advise/create-eft.html',
    })
    .state('advise.add-client-account', {
      url: '/client/{id:[0-9]+}/add-account',
      controller: 'AdviseAddClientAccountCtrl',
      resolve: {
        client: ['User', '$stateParams', resolveClient],
        person: [
          'client',
          function (client) {
            return client.person.ready();
          },
        ],
        ima: [
          'client',
          function (client) {
            return client.investmentManagementAgreement.ready();
          },
        ],
        newAccountFlows: ['person', 'AdvisorCreateNewAccountFlow', resolveNewAccountFlows],
      },
    })
    .state('advise.bulk-client-upload', {
      url: '/bulk-client-upload',
      controller: 'AdviseBulkClientUploadCtrl',
      templateUrl: 'views/advise/bulk-client-upload.html',
    })
    .state('advise.bulk-client-upload-help', {
      url: '/bulk-client-upload-help',
      controller: 'AdviseBulkClientUploadHelpCtrl',
      templateUrl: 'views/advise/bulk-client-upload-help.html',
      resolve: {
        advisorDetails: ['$http', resolveAdvisorDetails],
      },
    })
    .state('advise.account', {
      url: '/client/{id:[0-9]+}/account/{accountId:[0-9]+}?initialTab',
      controller: 'AdviseAccountCtrl',
      resolve: {
        client: ['User', '$stateParams', resolveClient],
        person: ['Person', 'client', resolvePerson],
        account: ['Account', '$q', '$stateParams', resolveAccount],
        accountApprovals: ['AccountApproval', 'account', resolveAccountApprovals],
        accGoalAudits: ['account', resolveAccGoalAudits],
        activities: ['account', resolveActivities],
        statements: ['client', 'account', resolveStatements],
        beneficiaries: ['$q', 'account', resolveBeneficiaries],
        successorHolder: ['account', resolveSuccessorHolder],
        accountHolders: ['$q', 'Person', 'client', 'account', resolveAccountHolders],
        taxableAccounts: ['client', resolveTaxableAccounts],
        bankAccounts: ['client', 'BankAccount', resolveValidBankAccounts],
      },
    })
    .state('advise.new-accounts', {
      url: '/new-accounts',
      controller: 'AdviseNewAccountsCtrl',
      templateUrl: 'views/advise/new-accounts.html',
      resolve: {},
    })
    .state('advise.new-account-applications', {
      url: '/new-account-applications',
      controller: 'AdviseNewAccountApplicationsCtrl',
      templateUrl: 'views/advise/new-account-applications.html',
      resolve: {
        advisorDetails: ['$http', resolveAdvisorDetails],
      },
    })
    .state('advise.orders', {
      url: '/orders?upload',
      params: {
        upload: null,
      },
      controller: 'AdviseOrdersCtrl',
      resolve: {
        authorize: ['authorizer', authorizeTrade],
        orders: ['Order', resolveOrders],
      },
    })
    .state('advise.order', {
      url: '/order/{id:[0-9]+}',
      controller: 'AdviseOrderCtrl',
      resolve: {
        authorize: ['authorizer', authorizeTrade],
        order: ['Order', '$stateParams', resolveOrder],
      },
    })
    .state('advise.promotions', {
      url: '/promotions',
      controller: 'AdvisePromotionsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        promotions: ['Promotion', resolvePromotions],
      },
    })
    // The explicit templateUrls are necessary as Angular gets confused otherwise
    .state('advise.issues', {
      url: '/issues',
      templateUrl: 'views/advise/issues-view.html',
      controller: 'AdviseIssuesCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
      },
    })
    .state('advise.bank-accounts', {
      url: '/bank-accounts',
      templateUrl: 'views/advise/bank-accounts-view.html',
      controller: 'AdviseBankAccountsCtrl',
      resolve: {
        authorize: ['authorizer', (a) => a.checkPermission('bank_accounts')],
      },
    })
    // state that can be used to link to accounts based only on its id
    .state('advise.account-link', {
      url: '/account-link/{id:[0-9]+}',
      controller: [function () {}],
      resolve: {
        route: ['$state', 'Account', '$stateParams', redirectToAccount],
      },
      template: '',
    })
    // temporary redirect to client view until a bank account issues view is created
    .state('advise.bank-account-link', {
      url: '/bank-account-link/{id:[0-9]+}',
      resolve: {
        route: ['$state', 'BankAccount', '$stateParams', redirectToBankAccount],
      },
      template: '',
    })
    // state used to link to transfer requests to their transfer instruction
    .state('advise.transfer-request-link', {
      url: '/transfer-request-link/{id:[0-9]+}',
      resolve: {
        route: ['$state', 'TransferRequest', '$stateParams', redirectToTransferInstruction],
      },
      template: '',
    })
    .state('advise.issue', {
      url: '/issue/{id:[0-9]+}',
      controller: 'AdviseIssueCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        issue: ['Issue', '$stateParams', resolveIssue],
        assocUserId: [
          'Account',
          'UpdateRecord',
          'BankAccount',
          'TransferRequest',
          'issue',
          resolveAssocUserId,
        ],
        assocUser: ['User', 'issue', 'assocUserId', resolveAssocUser],
      },
    })
    .state('advise.transfer-client-account', {
      url: '/client/{id:[0-9]+}/transfer-account',
      controller: 'AdviseTransferClientAccountCtrl',
      resolve: {
        client: ['User', '$stateParams', resolveClient],
        accounts: ['client', resolveClientAccounts],
        transferableAccounts: ['$http', 'Account', 'client', resolveClientTransferableAccounts],
      },
    })
    .state('advise.update-records', {
      url: '/update-records',
      templateUrl: 'views/advise/update-records-parent.html',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        redirect: [
          'redirect',
          function (redirect) {
            redirect.to('advise.update-records.open').always();
          },
        ],
      },
    })
    // Currently a placeholder state as a template for later
    .state('advise.update-records.open', {
      url: '/open',
      templateUrl: 'views/advise/update-records-view.html',
      controller: 'AdviseUpdateRecordsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        updateRecords: ['UpdateRecord', resolveOpenUpdateRecords],
      },
    })
    .state('advise.update-records.closed', {
      url: '/closed',
      templateUrl: 'views/advise/update-records-view.html',
      controller: 'AdviseUpdateRecordsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        updateRecords: ['UpdateRecord', resolveClosedUpdateRecords],
      },
    })
    .state('advise.update-records.all', {
      url: '/all',
      templateUrl: 'views/advise/update-records-view.html',
      controller: 'AdviseUpdateRecordsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        updateRecords: ['UpdateRecord', resolveUpdateRecords],
      },
    })
    .state('advise.update-record', {
      url: '/update-record/{id:[0-9]+}',
      controller: 'AdviseUpdateRecordCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        updateRecord: ['UpdateRecord', '$stateParams', resolveUpdateRecord],
        previousUpdateRecord: [
          '$http',
          'UpdateRecord',
          'updateRecord',
          resolvePreviousUpdateRecord,
        ],
        newestUpdateRecord: ['$http', 'UpdateRecord', 'updateRecord', resolveNewestUpdateRecord],
        issue: ['Issue', 'updateRecord', resolveUpdateRecordIssue],
      },
    })
    .state('advise.referrals', {
      url: '/referrals/{status:[a-z-]*}',
      controller: 'AdviseReferralsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        referrals: ['Referral', '$stateParams', 'redirect', resolveReferrals],
      },
    })
    .state('advise.rif-details', {
      url: '/rif-details/{status:[a-z-]*}',
      controller: 'AdviseRifDetailsCtrl',
      resolve: {
        authorize: ['authorizer', (a) => a.checkPermission('rrif_payment_details')],
        rifDetails: ['RifDetail', '$stateParams', resolveRifDetails],
      },
    })
    .state('advise.referral', {
      url: '/referral/{id:[0-9]+}',
      controller: 'AdviseReferralCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        referral: ['Referral', '$stateParams', resolveReferral],
        referralPayments: ['referral', resolveReferralPayments],
      },
    })
    .state('advise.referral-payments', {
      url: '/referral-payments/{state:[a-z-]*}',
      controller: 'AdviseReferralPaymentsCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        referralPayments: ['ReferralPayment', '$stateParams', resolveReferralPaymentsList],
      },
    })
    .state('advise.transfer-requests', {
      url: '/transfer-requests',
      controller: 'AdviseTransferRequestsCtrl',
      resolve: {
        unmatchedTransferNotifications: [
          'NotificationsUnmatchedTransferQuery',
          function (NotificationsUnmatchedTransferQuery) {
            return NotificationsUnmatchedTransferQuery.run();
          },
        ],
        unmatchedTransfers: [
          'TransfersUnmatchedQuery',
          function (TransfersUnmatchedQuery) {
            return TransfersUnmatchedQuery.run();
          },
        ],
      },
    })
    .state('advise.transfer-instruction', {
      url: '/transfer-request/{id:[0-9]+}',
      controller: 'AdviseTransferInstructionCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor],
        transferInstruction: ['TransferInstruction', '$stateParams', resolveTransferInstruction],
      },
    })
    .state('advise.security', {
      url: '/security',
      controller: 'profileTwoFactorAuthenticationCtrl',
      resolve: {
        isMobile: function () {
          return null;
        },
        isDesktopSafari: resolveIsDesktopSafari,
      },
    });
}
