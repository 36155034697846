'use strict';

angular
  .module('controller.marketing.research', ['service.promotion-redemption-cookie-creator'])
  .controller('marketingResearchCtrl', [
    '$scope',
    '$analytics',
    '$location',
    '$state',
    'promotionRedemptionCookieCreator',
    marketingResearchController,
  ]);

function marketingResearchController(
  $scope,
  $analytics,
  $location,
  $state,
  promotionRedemptionCookieCreator
) {
  $scope.track = function (event) {
    $analytics.pageTrack($state.current.url + '-' + event, $location);
  };

  $scope.cfsCallToAction = function () {
    $scope.track('cta-click');
    promotionRedemptionCookieCreator.createAutoApplyPromoCode('CFS2017');
    $state.go('interview.goal');
  };
}
