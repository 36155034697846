'use strict';

function adviseRifDetailsController($scope, rifDetails) {
  $scope.rifDetails = rifDetails;
  $scope.sortColumn = 'nextPaymentDate()';

  $scope.rifDetailsStatuses = ['not-setup'];
}

angular
  .module('controller.advise.rif-details', [])
  .controller('AdviseRifDetailsCtrl', ['$scope', 'rifDetails', adviseRifDetailsController]);
