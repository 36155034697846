'use strict';

angular
  .module('routes.advisor-new-account', [
    'controller.advisor-new-account',
    'controller.minimal-footer',
    'controller.advisor-new-account.invalid-link',
    'model.AdvisorCreateNewAccountFlow',
    'model.AdvisorCreateNewTransferFlow',
    'service.adaptive-views',
    'templates',
    'service.branding-machine',
    'ui.router',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', 'brandingMachineProvider', configRoutes]);

function configRoutes($stateProvider, adaptiveViewsProvider, brandingMachineProvider) {
  function authorize(authorizer) {
    return authorizer('Client');
  }

  function resolveBranding(currentUser) {
    return brandingMachineProvider.getAdvisorDetails(currentUser, null);
  }

  function resolveAdvisorCreateNewAccountFlow(
    $stateParams,
    currentUser,
    AdvisorCreateNewAccountFlow
  ) {
    var id = $stateParams.advisorNewAccountFlowId;
    if (id) {
      return AdvisorCreateNewAccountFlow.find({
        id,
      });
    } else {
      return AdvisorCreateNewAccountFlow.where({}).then(function (accountFlows) {
        return _.chain(accountFlows)
          .filter(function (accountFlow) {
            return (
              accountFlow.clientId() === currentUser.person().id &&
              accountFlow.status() !== 'completed'
            );
          })
          .first()
          .value();
      });
    }
  }

  function resolveAccounts(currentUser) {
    return currentUser.accounts.ready(true);
  }

  function resolveIncompleteAccountFlows(AdvisorCreateNewAccountFlow) {
    return AdvisorCreateNewAccountFlow.constructor.incompleteFlowsForCurrentUser();
  }

  function resolveClientFlow(AdvisorCreateNewClientFlow, currentUser) {
    if (!currentUser) {
      return null;
    }
    return AdvisorCreateNewClientFlow.where({
      clientId: currentUser.person().id,
    }).then(function (list) {
      if (list.length === 0) {
        return null;
      }
      return _.first(list);
    });
  }

  function resolveClientBankAccounts(user) {
    return user.bankAccounts.ready();
  }

  function resolveTransferInstructionFlows(clientFlow) {
    if (!clientFlow) {
      return [];
    }

    return clientFlow.advisorCreateNewTransferInstructionFlows.ready(true);
  }

  function resolveAccountFlows(clientFlow) {
    if (!clientFlow) {
      return [];
    }

    return clientFlow.advisorCreateNewAccountFlows.ready(true);
  }

  $stateProvider
    .state('advisor-new-account', {
      url: '/advisor-new-account/:advisorNewAccountFlowId',
      abstract: true,
      parent: 'site',
      resolve: {
        authorize: ['authorizer', authorize],
        newAccountFlow: [
          '$stateParams',
          'currentUser',
          'AdvisorCreateNewAccountFlow',
          resolveAdvisorCreateNewAccountFlow,
        ],
        clientFlow: ['AdvisorCreateNewClientFlow', 'currentUser', resolveClientFlow],
        advisorDetails: ['currentUser', resolveBranding],
        newTransferInstructionFlows: ['clientFlow', resolveTransferInstructionFlows],
        newAccountFlows: ['clientFlow', resolveAccountFlows],
        bankAccounts: ['currentUser', resolveClientBankAccounts],
      },
      views: {
        main: {
          controller: 'AdvisorNewAccountController',
        },
        navbar: {
          templateUrl: 'navbar/interview.html',
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
    })
    .state('advisor-new-account.view', {
      url: '/view',
      controller: 'AdvisorNewAccountViewController',
      resolve: {
        incompleteAccountFlows: ['AdvisorCreateNewAccountFlow', resolveIncompleteAccountFlows],
      },
    })
    .state('advisor-new-account.continue', {
      url: '/continue',
      controller: 'AdvisorNewAccountContinueController',
      resolve: {
        accounts: ['currentUser', resolveAccounts],
      },
    })
    .state('advisor-new-account.reject', {
      url: '/reject',
      controller: 'AdvisorNewAccountRejectController',
      resolve: {
        accounts: ['currentUser', resolveAccounts],
        incompleteAccountFlows: ['AdvisorCreateNewAccountFlow', resolveIncompleteAccountFlows],
      },
    })
    .state('advisor-new-account.stale', {
      url: '/stale',
      controller: 'AdvisorNewAccountStaleController',
    })
    .state('advisor-new-account-invalid', {
      // This and the next state are to display a friendly message when the link for 'add account to existing client'
      // is wrong, or for a different client than the one that is logged in.
      url: '/advisor-new-account-invalid',
      abstract: true,
      parent: 'site',
      resolve: {},
      views: {
        main: {
          templateUrl: 'views/advisor-new-account.html', // Borrow layout from above so it is visually the same.
        },
        navbar: {
          templateUrl: 'navbar/minimal.html',
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
    })
    .state('advisor-new-account-invalid.link', {
      url: '/:link?errorCode',
      params: {
        errorCode: undefined,
      },
      controller: 'AdvisorNewAccountInvalidLinkController',
      templateUrl: 'views/advisor-new-client/invalid-link.html', // Borrow sister implementation so it is visually the same.
    });
}
