'use strict';

angular
  .module('model.Activity', ['ram', 'ram.DateAccessor'])
  .factory('Activity', ['$filter', 'ram', 'config', 'DateAccessor', activityFactory]);

function activityFactory($filter, ram, config, DateAccessor) {
  var Activity = new ram.Collection('Activity', {
    bind: ['amountOrDescription', 'showBeneficiary', 'typeLabel', 'setToolTips', 'toolTipType'],
    enums: {
      type: config.types.Activity,
    },
    resources: {
      default: new ram.resources.Http(config.schemas.Activity.resourceApiUrl),
      cookie: new ram.resources.Cookie('activities'),
    },
    schema: config.schemas.Activity,
  });

  /**
   * Convert the date into a Date object, which is easier to work with.
   * @param  {Object} attrs Activity object attributes
   */
  Activity.prototype.initialize = function (attrs) {
    this.date = new DateAccessor(attrs.date);
    this.setToolTips();
  };

  Activity.prototype.showBeneficiary = function () {
    return this.type.is.respGrant() && !!this.description();
  };

  Activity.prototype.setToolTips = function () {
    const description = this.description() || '';
    if (this.showBeneficiary()) {
      this.toolTip = 'beneficiary';
    } else if (this.type.is.serviceFee() || (this.type.is.taxOnFee() && description.match(/GST/))) {
      this.toolTip = 'serviceFee';
    } else {
      this.toolTip = null;
    }
  };

  Activity.prototype.toolTipType = function () {
    return this.toolTip;
  };

  Activity.prototype.typeLabel = function () {
    const description = this.description();
    if (this.showBeneficiary()) {
      return `${this.type().label} for ${description}`;
    } else if (this.type.is.serviceFee()) {
      return `Payment to ${description || 'Advisor'}`;
    } else if (this.type.is.taxOnFee() && this.toolTipType() === 'serviceFee') {
      return `Payment to ${description}`; // No sensible default available.
    } else {
      return this.type().label;
    }
  };

  /**
   * Format the amount and description for display
   */
  Activity.prototype.amountOrDescription = function () {
    if (this.amount()) {
      return this.type().label === 'Buy'
        ? $filter('currency')(Math.abs(this.amount()))
        : $filter('currency')(this.amount());
    }
    return this.description();
  };

  Activity.prototype.label = function () {
    const description = this.description();
    const label = this.type().label;
    if (label === 'Buy' || label === 'Sell') {
      return description;
    }
    return label;
  };

  return Activity;
}
