'use strict';

function paginationNavigatorDirective($injector, loadingIndicator) {
  return {
    restrict: 'E',
    scope: {
      state: '@',
      usePagination: '@',
      restApi: '@',
      order: '@',
      resultSet: '=',
    },
    templateUrl: 'directives/pagination-navigator.html',
    controller: [
      '$scope',
      function ($scope) {
        var _reload;
        var _forcePage;

        /*
         * Setup
         */
        var service = $injector.get('bulkDataLoader');
        $scope.perPage = service.perPage();

        service.restApi($scope.restApi); // Must set this
        $scope.paginationSetting = service.usePagination($scope.usePagination === 'true');

        var args = service.defaultPaginationData();
        args.state = $scope.state; // optional
        args.order = $scope.order; // optional
        service.setPaginationData(args);

        /*
         * Trigger the first load
         */
        $scope.currentPage = 1;

        /*
         * UX handlers
         */
        $scope.canNextPage = function () {
          return (
            $scope.paginationSetting && service.page() < service.getPaginationData().totalPages
          );
        };

        $scope.canPreviousPage = function () {
          return $scope.paginationSetting && service.page() > 1;
        };

        $scope.previousPage = function () {
          $scope.currentPage--;
        };

        $scope.nextPage = function () {
          $scope.currentPage++;
        };

        $scope.lastPage = function () {
          return service.numPages();
        };

        $scope.getRecordRange = function () {
          return (
            service.getPaginationData().fromIndex + ' - ' + service.getPaginationData().toIndex
          );
        };

        $scope.togglePagination = function () {
          $scope.paginationSetting = !$scope.paginationSetting;
          service.usePagination($scope.paginationSetting);
          _forcePage(1);
        };

        $scope.setCurrentPage = function (value) {
          $scope.currentPage = value;
        };

        /*
         * Watch methods
         */
        $scope.$watch('currentPage', function (pageNumber) {
          if (
            _.isUndefined(pageNumber) ||
            pageNumber === null ||
            pageNumber === service.page() ||
            pageNumber < 1 ||
            pageNumber > service.numPages()
          ) {
            return;
          }
          _reload(pageNumber);
        });

        $scope.$watch('perPage', function (perPage) {
          if (_.isUndefined(perPage) || perPage === null || perPage === service.perPage()) {
            return;
          }
          service.perPage(perPage);
          _forcePage(1);
        });

        /*
         * Private functions
         */
        _forcePage = function (pageNumber) {
          if ($scope.currentPage === pageNumber) {
            _reload($scope.currentPage); // Don't trigger the watch, just reload
          } else {
            $scope.currentPage = pageNumber; // Triggers the watch.
          }
        };

        _reload = function (pageNumber) {
          loadingIndicator.show();
          service.getResults(pageNumber).then(function (results) {
            if ($scope.paginationSetting) {
              // The last element is the meta data about what page we are on
              // Pop it off and pass to the service.  results.data will shrink automatically.
              service.setPaginationData(results.data.pop());
            }
            if (service.page() !== pageNumber) {
              console.log(
                'There was a problem retrieving data from the server.  The results are not accurate.'
              );
            }
            $scope.resultSet = results.data;
            loadingIndicator.hide();
          });
        };
      },
    ],
  };
}

angular
  .module('directive.paginationnavigator', ['service.bulkDataLoader', 'service.loading-indicator'])
  .directive('paginationNavigator', [
    '$injector',
    'loadingIndicator',
    paginationNavigatorDirective,
  ]);
