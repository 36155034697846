'use strict';

var MAX_NUM_RETRIES = 120;
var EXPONENTIAL_THRESHOLD = 30;
var INITIAL_DELAY_MS = 2000;

function factory($rootScope, $timeout) {
  var _retries = 0;
  var _allDoneCallback = null;
  var _workerCallback = null;
  var _delay = INITIAL_DELAY_MS;
  var _currentTimer = null;

  var _retryDelay = function (retry) {
    while (retry > EXPONENTIAL_THRESHOLD) {
      _delay *= 2;
      retry /= 2;
    }
    return _delay;
  };

  var _onTimeInterval = function () {
    if (_retries > MAX_NUM_RETRIES) {
      return;
    }
    _workerCallback();

    if (_allDoneCallback()) {
      return;
    }

    _currentTimer = $timeout(_onTimeInterval, _retryDelay(++_retries));
  };

  function _initialize(allDoneCallback, workerCallback) {
    if (_currentTimer) {
      $timeout.cancel(_currentTimer);
    }
    _currentTimer = null;
    _allDoneCallback = allDoneCallback;
    _workerCallback = workerCallback;
    _delay = INITIAL_DELAY_MS;
    _retries = 0;
  }

  function _stop() {
    $timeout.cancel(_currentTimer);
    _currentTimer = null;
  }

  return {
    initialize: _initialize,
    start: _onTimeInterval,
    stop: _stop,
  };
}

angular
  .module('service.exponential-backoff-polling-service', [])
  .factory('exponentialBackoffPoller', ['$rootScope', '$timeout', factory]);
