'use strict';

angular
  .module('controller.advise.add-client.preferred-portfolio-manager', [
    'ram',
    'component.preferred-portfolio-manager-subform',
    'directive.input',
    'service.form-helper',
    'directive.input.manual-help-block',
  ])
  .controller('AdviseClientApplicationPreferredPortfolioManagerCtrl', [
    '$scope',
    '$window',
    'ram',
    'formHelper',
    'groupUrlId',
    'link',
    'portfolioManagers',
    adviseClientApplicationPreferredPortfolioManagerCtrl,
  ]);

function adviseClientApplicationPreferredPortfolioManagerCtrl(
  $scope,
  $window,
  ram,
  formHelper,
  groupUrlId,
  link,
  portfolioManagers
) {
  $scope.portfolioManagers = portfolioManagers;
  $scope.groupUrlId = groupUrlId;
  $scope.link = link;
  $scope.preferredPortfolioManagerId = new ram.Accessor(undefined); // No default on this screen
  $scope.preferredPortfolioManagerError = {
    show: false,
    text: 'Please select a portfolio manager.',
  };

  $scope.onChange = function (newId) {
    $scope.preferredPortfolioManagerId(newId);
    $scope.preferredPortfolioManagerError.show = false;
  };

  $scope.back = function () {
    $window.history.back();
  };

  $scope.submit = function (formController) {
    $scope.isSubmitting = true;
    $scope.preferredPortfolioManagerError.show = false;

    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
      $scope.preferredPortfolioManagerError.show = true;
    } else {
      $scope.inviteClientModal({
        groupUrlId: $scope.groupUrlId,
        multiAdvisorLink: $scope.link,
        portfolioManagerId: $scope.preferredPortfolioManagerId(),
      });
    }
    $scope.isSubmitting = false;
  };
}
