'use strict';

function allotmentFactory(ram, config) {
  /**
   * Constructor for Allotment models.
   * @class
   */
  var Allotment = new ram.Collection('Allotment', {
    belongsTo: {
      position: 'Position',
      trade: 'Trade',
    },
    schema: config.schemas.Allotment,
    resources: {
      default: new ram.resources.Http('/api/allotments/:id.json'),
      cookie: new ram.resources.Cookie('allotments'),
    },
  });

  Allotment.prototype.shares = function () {
    return _.isNumber(this.adjusted()) ? this.adjusted() : this.target();
  };

  Allotment.prototype.percentOfPortfolio = function () {
    var position = this.position();
    if (position) {
      var shares = parseFloat(position.shares()) + parseFloat(this.shares());
      return position.percentOfPortfolio(shares);
    }
  };

  Allotment.prototype.tradingEngineResult = function (order) {
    if (!this._tradingEngineResult) {
      var accountId = this.accountId();
      this._tradingEngineResult = _.find(order.tradingEngineResults(), function (result) {
        return result.accountId() === accountId;
      });
    }

    return this._tradingEngineResult;
  };

  return Allotment;
}

angular
  .module('model.Allotment', ['ram', 'config', 'model.Position', 'model.Trade'])
  .factory('Allotment', ['ram', 'config', allotmentFactory]);
