'use strict';

angular
  .module('controller.advise.client-application.transfer-instruction', [
    'directive.account-goal-detail',
    'model.AdvisorCreateNewAccountFlow',
    'model.AdvisorCreateNewTransferInstructionFlow',
    'service.controller.eft.transfer',
    'directive.account-transfers-table',
    'directive.input.date-input',
    'directive.input.number',
    'directive.user.address-field',
    'directive.input.manual-help-block',
    'mobile.directive.transfer-type-dropdown',
    'service.form-helper',
    'service.eft-validation-service',
  ])
  .controller('AdviseClientApplicationTransferInstructionCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    'AdvisorCreateNewAccountFlow',
    'AdvisorCreateNewTransferInstructionFlow',
    'formHelper',
    'newTransferInstructionFlows',
    'newAccountFlows',
    'bankAccounts',
    'questions',
    'userNotifications',
    'transferAccountDataProvider',
    'adaptiveViews',
    'config',
    'ram',
    'groupByDateService',
    'currencyFilter',
    '$timeout',
    'client',
    'eftValidationService',
    adviseClientApplicationTransactionInstructionCtrl,
  ]);

function adviseClientApplicationTransactionInstructionCtrl(
  $scope,
  $state,
  $stateParams,
  AdvisorCreateNewAccountFlow,
  AdvisorCreateNewTransferInstructionFlow,
  formHelper,
  newTransferInstructionFlows,
  newAccountFlows,
  bankAccounts,
  questions,
  userNotifications,
  transferAccountDataProvider,
  adaptiveViews,
  config,
  ram,
  groupByDateService,
  currencyFilter,
  $timeout,
  client,
  eftValidationService
) {
  angular.extend($scope, {
    newTransferInstructionFlows,
    newAccountFlows,
    client,
    transferCount: newTransferInstructionFlows.length,
    cancelAction,
    tab: 'new-transfer',
    onboarding: true,
    questions,
  });

  var _setFromAndToLists;
  var _reset;
  var _from;
  var _to;
  var _getDefaultItem;
  var _setGuiObjectsAndCancelFlags;
  var _checkDataPassesDateCancel;
  var _checkDataPassesElapsedCancel;
  var _getFormattedDate;
  var _getJsDate;
  var _validateDateCancel;
  var _validateElapsedCancel;
  var _sortTransferInstructionsByDate;
  var _sortTransferRequestsByDate;
  var _insertSpaceForCurrencyError;

  var _isMobile = adaptiveViews.isMobile();

  var _onlyOnceFrequencyId = config.types.TransferInstructionFrequency.findByName('only_once').id;
  var _onlyOnceFrequencyOptions = _.where(config.types.TransferInstructionFrequency, {
    name: 'only_once',
  });

  var defaultTransferType = $stateParams.defaultTransferType;

  // MNG-1143-internal-transfer-errors-and-warnings:  config

  $scope.eftCheckResult = null;
  $scope.client = client;
  $scope.advisor = client.id !== $scope.currentUser.id && $scope.currentUser;
  $scope.isApp = adaptiveViews.isApp();
  $scope.transferableAccounts = newAccountFlows;

  // The transfer instructions need to be placed in an object in order for the
  // directives to pick up any changes to the transfer instructions
  $scope.transferInstructionsContainer = {
    transferInstructions: [],
  };
  $scope.fromAccount = new ram.Accessor(); // This is the id of the object selected
  $scope.toAccount = new ram.Accessor(); // This is the id of the object selected
  $scope.cancelDate = new ram.Accessor();
  $scope.transferableBankAccounts = bankAccounts;

  $scope.showNewTransferInputs = function () {
    return newAccountFlows.length > 1 || $scope.transferableBankAccounts.length > 0;
  };

  $scope.showOnlyTransferTypeInput = function () {
    return (
      ($scope.transferInstruction.type.is.deposit() ||
        $scope.transferInstruction.type.is.withdrawal()) &&
      $scope.transferableBankAccounts.length === 0
    );
  };

  var _invalidAccountForOperation = function (control) {
    return (
      $scope.transferInstruction.type.fieldWithBankLabel() === control &&
      $scope.transferableBankAccounts.length === 0
    );
  };

  $scope.fromToPlaceholderText = function (control) {
    var type = $scope.transferInstruction.typeId();
    if (_.isUndefined(type) || type === null) {
      return 'Please select a Transfer Type first';
    } else if (_invalidAccountForOperation(control)) {
      return 'No verified bank accounts are set up';
    }
    return "Please select a '" + control + "' account";
  };

  if ($stateParams.showFormEsignedAlert) {
    userNotifications.showInfo(
      'You have successfully signed the account transfer form.' +
        '<br>' +
        'Account transfers could take up to 4 weeks. ' +
        'We will send you an email when we receive your funds.'
    );
  }

  if ($stateParams.showSuccessMessage) {
    userNotifications.showInfo($stateParams.showSuccessMessage);
  }

  transferAccountDataProvider.setInputs(client, newAccountFlows, $scope.transferableBankAccounts);

  $scope.messages = {
    number: 'Enter a number greater than zero',
    date: 'Continue until must be after Start Date',
    thisFieldIsRequired: 'This field is required',
  };
  /***************************************************************************
   *
   * Private methods - Setup
   */
  // Formats into MM-DD-YYYY
  _getFormattedDate = function (date) {
    return moment(date).startOf('day').format('MM-DD-YYYY');
  };

  // Formats into Javascript Date
  _getJsDate = function (date) {
    return moment(date).startOf('day').toDate();
  };

  _reset = function () {
    if (_isMobile) {
      defaultTransferType = config.types.TransferInstruction.findByName('deposit').id;
    }
    $scope.transferInstruction = AdvisorCreateNewTransferInstructionFlow.new();
    // If the user selected "Add Funds" then defaultTransferType will have a non-null value.  That is our
    // default transfer type.  Set it here.  It is either 'null' or 'not-null-integer' set in the routes.
    $scope.transferInstruction.clientId($scope.newClientFlow.clientId());
    $scope.transferInstruction.advisorCreateNewClientFlowId($scope.newClientFlow.id);
    $scope.transferInstruction.typeId(defaultTransferType);
    $scope.fromAccounts = [];
    $scope.toAccounts = [];
    $scope.fromAccount(null);
    $scope.toAccount(null);
    _setFromAndToLists();
    $scope.transferDisplayData = null;
    $scope.showTransferInstructionCreationResult = false;

    $scope.transferInstruction.startDate(_getJsDate());

    var tomorrow = moment().add(1, 'd');
    $scope.cancelDate(_getJsDate(tomorrow));
    $scope.cancelDateMinDate = _getFormattedDate(tomorrow);

    $scope.transferInstruction.frequencyId(_onlyOnceFrequencyId);

    $scope.cancelOption = {
      name: 'user-cancel',
    };
    $scope.cancelNumber = {
      value: 1,
    };

    $scope.mobileTransferTypeErrorMessage = false;
  };

  _setFromAndToLists = function () {
    if (_.isUndefined($scope.transferInstruction.type())) {
      return;
    }
    if ($scope.transferInstruction.type.is.withdrawal()) {
      _from($scope.transferableAccounts);
      _to($scope.transferableBankAccounts);
    } else if ($scope.transferInstruction.type.is.deposit()) {
      _from($scope.transferableBankAccounts);
      _to($scope.transferableAccounts);
    } else if ($scope.transferInstruction.type.is.transfer()) {
      _from($scope.transferableAccounts);
      _to($scope.transferableAccounts);
    }
  };

  /**
   * Resets the visual error state of a UX control for the 'from' and 'to'
   * @param  {string}   fieldName  The name of the field to modify, from the view's HTML
   * @param  {function} accessor   The RAM accessor used as the ng-model for the field
   *                               Pass the function.
   */
  var _resetIfValid = function (fieldName, accessor) {
    if (accessor()) {
      // It has a valid value
      if ($scope.myForm) {
        $scope.myForm[fieldName].$invalid = false;
        // Need to 'click' on it to reset the error
        angular.element('select[name=' + fieldName + ']').trigger('focus');
      }
    }
  };

  _from = function (accountList) {
    $scope.fromAccounts = accountList;
    $scope.fromAccount(_getDefaultItem($scope.fromAccounts));
    _resetIfValid('from', $scope.fromAccount);
  };

  _to = function (accountList) {
    $scope.toAccounts = accountList;
    $scope.toAccount(_getDefaultItem($scope.toAccounts));
    _resetIfValid('to', $scope.toAccount);
  };

  _getDefaultItem = function (list) {
    if (list && list[0] && list.length === 1) {
      return list[0].id;
    }
    // If more than one item in list pick nothing.
    return null;
  };

  var _getAccountById = function (accountType, accountId) {
    return transferAccountDataProvider.getAccountById(accountType, accountId);
  };

  $scope.getAccountById = function (accountType, accountId) {
    return _getAccountById(accountType, accountId);
  };

  var _getObjects = function (input) {
    return {
      transferFrom: _getAccountById(input.transferFromType(), input.transferFromId()),
      transferTo: _getAccountById(input.transferToType(), input.transferToId()),
    };
  };

  var isTransferInstructionEndDateTodayOrInThePast = function (transferInstruction) {
    var mostRecentTransferRequest;
    var endDate;

    if (transferInstruction.transferRequests().length === 0) {
      return false;
    }

    if (!transferInstruction.hasCreatedAllTransferRequests()) {
      return false;
    }

    mostRecentTransferRequest = _.chain(transferInstruction.transferRequests())
      .sortBy(function (transferRequest) {
        return transferRequest.dateToBeProcessed();
      })
      .last()
      .value();

    endDate = moment(mostRecentTransferRequest.dateToBeProcessed()).toDate();

    return endDate <= moment().toDate();
  };

  _setGuiObjectsAndCancelFlags = function () {
    _.each(
      $scope.transferInstructionsContainer.transferInstructions,
      function (transferInstruction) {
        var cancelDisplayOptions = {
          isEft: true,
          canCancel: transferInstruction.canCancel(),
          cancelWithMessage: !transferInstruction.frequency.is.onlyOnce(),
        };
        _.extend(transferInstruction, {
          cancelDisplayOptions,
        });
        _.extend(transferInstruction, _getObjects(transferInstruction));
      }
    );
    $scope.transferInstructionsContainer.transferInstructions = _.reject(
      $scope.transferInstructionsContainer.transferInstructions,
      function (transferInstruction) {
        return (
          transferInstruction.status.is.finished() ||
          isTransferInstructionEndDateTodayOrInThePast(transferInstruction)
        );
      }
    );
    _sortTransferInstructionsByDate();
  };

  var _setGuiTransferRequestObjects = function () {
    _.each($scope.pastTransferRequests, function (transferRequest) {
      _.extend(transferRequest, _getObjects(transferRequest));
    });
    _sortTransferRequestsByDate();
  };

  _sortTransferInstructionsByDate = function () {
    $scope.transferInstructionsByDate = groupByDateService.call(
      $scope.transferInstructionsContainer.transferInstructions,
      'startDate'
    );
  };

  _sortTransferRequestsByDate = function () {
    $scope.transferRequestsByDate = groupByDateService.call(
      $scope.pastTransferRequests,
      'dateToBeProcessed'
    );
  };

  var _isTfsaAccount = function (accountId) {
    var account = _getAccountById('Account', accountId);

    return account && account.accountType.is.tfsa();
  };

  var _setFrequencyOptions = function () {
    if ($scope.transferInstruction.type.is.withdrawal()) {
      $scope.transferInstructionFrequencyOptions = _.where(
        config.types.TransferInstructionFrequency,
        {
          allowableForWithdrawals: true,
        }
      );
    } else if ($scope.transferInstruction.type.is.transfer()) {
      if (_isTfsaAccount($scope.fromAccount()) && _isTfsaAccount($scope.toAccount())) {
        $scope.transferInstructionFrequencyOptions = _onlyOnceFrequencyOptions;
        $scope.transferInstruction.frequencyId(_onlyOnceFrequencyId);
      } else {
        $scope.transferInstructionFrequencyOptions = _.where(
          config.types.TransferInstructionFrequency,
          {
            allowableForInternalTransfers: true,
          }
        );
      }
    } else {
      $scope.transferInstructionFrequencyOptions = config.types.TransferInstructionFrequency;
    }
  };

  /***************************************************************************
   *
   * Scope methods
   */
  $scope.allDataIncluded = function () {
    // return ($scope.fromAccount() >= 1 && $scope.toAccount() >= 1);
    return true;
  };

  $scope.transferFromToSameAccount = function () {
    return (
      $scope.transferInstruction.type().name === 'transfer' &&
      $scope.fromAccount() === $scope.toAccount()
    );
  };

  $scope.$watch('transferInstruction.typeId()', function (value) {
    if (_.isUndefined(value) || value === null) {
      return;
    }
    $scope.mobileTransferTypeErrorMessage = false;
    _setFromAndToLists();
    _setFrequencyOptions();
  });

  /*
   * Catch errors with the cancelNumber and cancelDate as the user is typing.
   */
  $scope.$watch('cancelNumber.value', function (value) {
    if (_.isUndefined(value) || value === null) {
      // The view sets this to null when clicked on.  Null falls into this case and triggers
      // no validation.  Validation will kick in when you press 'Make Transfer' though.
      return;
    }
    _validateDateCancel();
    _validateElapsedCancel();
  });

  $scope.$watch('cancelDate()', function (value) {
    if (_.isUndefined(value) || value === null) {
      return;
    }
    _validateDateCancel();
    _validateElapsedCancel();
  });

  $scope.$watch('transferInstruction.startDate()', function (value) {
    if (_.isUndefined(value) || value === null) {
      return;
    }

    var minDate = moment(value).add(1, 'd');

    if (minDate > $scope.cancelDate()) {
      $scope.cancelDate(_getJsDate(minDate));
    }
    $scope.cancelDateMinDate = _getFormattedDate(minDate);

    _validateDateCancel();
    _validateElapsedCancel();
  });

  /*
   * Reset invalid values when you pick another cancellation option.
   */
  $scope.$watch('cancelOption.name', function () {
    if ($scope.cancellationInstructionsAreUsed()) {
      if ($scope.cancelOption.name !== 'date-cancel' && !_checkDataPassesDateCancel()) {
        $scope.cancelDate(moment().startOf('day').toDate());
      }
      if ($scope.cancelOption.name !== 'elapsed-cancel' && !_checkDataPassesElapsedCancel()) {
        $scope.cancelNumber.value = 1;
      }
    }
  });

  $scope.$watch('transferInstruction.amount()', function (value) {
    if (_.isUndefined(value) || value === null) {
      return;
    } else if (_.isNaN(value)) {
      _insertSpaceForCurrencyError(true);
      return;
    }
    _insertSpaceForCurrencyError(false);
  });

  /*
   * Filter the 'to' accounts list based on the selection in the 'from' (for transfers only)
   */
  var _canFilterTransferAccounts = function (value) {
    return (
      value &&
      $scope.transferInstruction &&
      $scope.transferInstruction.typeId() ===
        config.types.TransferInstruction.findByName('transfer').id
    );
  };

  var _filterTransferrableAccounts = function (accountIdToExclude) {
    return _.filter($scope.transferableAccounts, function (account) {
      return account.id !== accountIdToExclude;
    });
  };

  $scope.$watch('fromAccount()', function (accountIdSelected) {
    if (_canFilterTransferAccounts(accountIdSelected)) {
      $scope.toAccounts = _filterTransferrableAccounts(accountIdSelected);
      $scope.toAccount(null);
    }
    _compareFromToForInternal();
  });

  $scope.$watch('toAccount()', function () {
    _compareFromToForInternal();
    _setFrequencyOptions();
  });

  function _compareFromToForInternal() {
    $scope.eftCheckResult = eftValidationService.result(
      $scope.transferInstruction,
      transferAccountDataProvider,
      $scope.fromAccount(),
      $scope.toAccount()
    );
  }

  $scope.setUpAnotherTransfer = function (form) {
    defaultTransferType = null;
    _reset();
    if (form) {
      form.$setPristine();
    }
  };

  var createTransferRequest = function () {
    $scope.creationStatus = true;
    $scope.transferInstruction
      .save()
      .then(function () {
        goToReview();
      })
      .catch(function () {
        $scope.creationStatus = false;
        if ($scope.advisor) {
          userNotifications.showError(
            'Something went wrong while sending the EFT request to the client. Please try again later.'
          );
        } else {
          $scope.showTransferInstructionCreationResult = true;
        }
      });
  };

  function _getAccountType(typeId, fromOrTo) {
    switch (typeId) {
      case config.types.TransferInstruction.findByName('withdrawal').id:
        return fromOrTo === 'from' ? 'AdvisorCreateNewAccountFlow' : 'BankAccount';
      case config.types.TransferInstruction.findByName('deposit').id:
        return fromOrTo === 'to' ? 'AdvisorCreateNewAccountFlow' : 'BankAccount';
      case config.types.TransferInstruction.findByName('transfer').id:
        return 'AdvisorCreateNewAccountFlow';
    }
  }

  function _assignTransferInstructions() {
    $scope.transferInstruction.resetCancelData();

    if ($scope.cancellationInstructionsAreUsed()) {
      if ($scope.cancelOption.name === 'user-cancel') {
        $scope.transferInstruction.manualCancel(true);
      } else if ($scope.cancelOption.name === 'date-cancel') {
        $scope.transferInstruction.continueUntil($scope.cancelDate());
      } else if ($scope.cancelOption.name === 'elapsed-cancel') {
        $scope.transferInstruction.maxOccurrence(parseInt($scope.cancelNumber.value));
      }
    }

    if (
      $scope.transferInstruction.frequencyId() ===
      config.types.TransferInstructionFrequency.findByName('semi_monthly').id
    ) {
      var start = moment($scope.transferInstruction.startDate());
      var day = parseInt(start.format('D'));
      if (day >= 2 && day <= 14) {
        $scope.transferInstruction.startDate(start.startOf('month').add(14, 'day').toDate());
      } else if (day >= 16) {
        $scope.transferInstruction.startDate(start.startOf('month').add(1, 'month').toDate());
      }
    }
  }

  _checkDataPassesDateCancel = function () {
    var now = moment().startOf('day').toDate();
    if ($scope.cancelDate() < now) {
      // message handled by validation directive.
      return false;
    }
    if ($scope.cancelDate() < $scope.transferInstruction.startDate()) {
      $scope.dateErrorMessage = true;
      return false;
    }
    return true;
  };

  _validateDateCancel = function () {
    $scope.dateErrorMessage = null;
    if ($scope.cancelOption.name === 'date-cancel') {
      if (!_checkDataPassesDateCancel()) {
        return false;
      }
    }
    return true;
  };

  _checkDataPassesElapsedCancel = function () {
    var value = $scope.cancelNumber.value;

    if (!_.isNumber(value) || value <= 0) {
      $scope.numberErrorMessage = true;
      return false;
    }
    return true;
  };

  _validateElapsedCancel = function () {
    $scope.numberErrorMessage = null;
    if ($scope.cancelOption.name === 'elapsed-cancel') {
      if (!_checkDataPassesElapsedCancel()) {
        return false;
      }
    }
    return true;
  };

  var _validateCancellationInstructions = function () {
    if (
      _isMobile &&
      (_.isUndefined($scope.transferInstruction.typeId()) ||
        _.isNull($scope.transferInstruction.typeId()))
    ) {
      $scope.mobileTransferTypeErrorMessage = true;
      return false;
    }
    if (
      !$scope.cancellationInstructionsAreUsed() ||
      ($scope.cancellationInstructionsAreUsed() &&
        _validateDateCancel() &&
        _validateElapsedCancel())
    ) {
      return true;
    }
    return false;
  };

  _insertSpaceForCurrencyError = function (insert) {
    var panel = angular.element('currency + div.panel');
    if (insert) {
      panel.addClass('top-margin-23');
    } else {
      panel.removeClass('top-margin-23');
    }
  };

  var _isWithdrawingMoreThanBalance = function () {
    // return ($scope.transferInstruction.type.is.withdrawal() || $scope.transferInstruction.type.is.transfer()) &&
    //     _getAccountById('Account', $scope.fromAccount()).balance() < $scope.transferInstruction.amount();
    // todo
    return false;
  };

  $scope.done = function (form) {
    var navbarHeight = document.querySelector('nav').clientHeight;

    if (_isWithdrawingMoreThanBalance()) {
      $scope.withdrawingMoreThanBalance = true;
      var alertElement = document.form.querySelector('#withdrawing-more-than-balance-alert');
      if (alertElement) {
        alertElement.scrollIntoView();
        window.scrollBy(0, -(navbarHeight + 10));
      }

      return false;
    } else {
      $scope.withdrawingMoreThanBalance = false;
    }

    const validationResult = _validateCancellationInstructions();
    return form.$valid && validationResult && !$scope.eftCheckResult.isError;
  };

  $scope.notDone = function (form) {
    var firstInvalidInput = document.form.querySelector('.ng-invalid');
    var navbarHeight = document.querySelector('nav').clientHeight;

    _.each(form.$error, function (errors) {
      _.each(errors, function (control) {
        control.$setDirty();
      });
    });

    if (firstInvalidInput) {
      firstInvalidInput.focus();
      window.scrollBy(0, -(navbarHeight + 10));
    }

    if (_.isUndefined($scope.transferInstruction.amount())) {
      _insertSpaceForCurrencyError(true);
    }

    this.$root.$broadcast('submit');
  };

  $scope.submitTransferRequest = function (form) {
    if (!$scope.done(form)) {
      $scope.notDone(form);
      return;
    }
    _insertSpaceForCurrencyError(false);

    angular.element('.currency-input > input').trigger('blur'); // In case user presses 'ENTER'

    $scope.transferInstruction.setFrom(
      _getAccountType($scope.transferInstruction.typeId(), 'from'),
      $scope.fromAccount()
    );
    $scope.transferInstruction.setTo(
      _getAccountType($scope.transferInstruction.typeId(), 'to'),
      $scope.toAccount()
    );
    _assignTransferInstructions();

    if (!_validateCancellationInstructions()) {
      // Its an edge case, but semi-montlhy may have changed the start date to be beyond the cancel date
      return;
    }

    const transferDisplayData = transferAccountDataProvider.getConfirmationData(
      'dialog',
      $scope.transferInstruction,
      $scope.eftCheckResult
    );
    $scope
      .transferConfirmationModal($scope.transferInstruction, transferDisplayData)
      .result.then(function () {
        createTransferRequest(form);
      });
  };

  $scope.cancellationInstructionsAreUsed = function () {
    return !$scope.transferInstruction.frequency.is.onlyOnce();
  };

  $scope.getDateLabel = function () {
    return transferAccountDataProvider.getDateLabel($scope.transferInstruction);
  };

  $scope.getStatusLabel = function (statusId) {
    if (
      statusId === config.types.TransferRequestStatus.findByName('upcoming').id ||
      statusId === config.types.TransferRequestStatus.findByName('in_progress').id
    ) {
      return 'In progress';
    }
    var status = _.find(config.types.TransferRequestStatus, function (element) {
      return element.id === statusId;
    });
    return status.label;
  };

  $scope.getTransferInstructionTypeLabel = function (typeId) {
    var type = _.find(config.types.TransferInstruction, function (element) {
      return element.id === typeId;
    });
    return type.label;
  };

  $scope.setRadioFocus = function (arg) {
    $scope.cancelOption.name = arg;
  };

  $scope.setForm = function (form) {
    $scope.myForm = form;
  };

  $scope.clientLabel = function (sourceAccount, secondAccount) {
    // Due to policy scopes and access levels, the account passed to the UX may be undefined.
    // In this case, we know it is a bank account.  Polymorphic for Account and BankAccount.
    var firstName = secondAccount ? secondAccount.clientFirstName() : undefined;
    return sourceAccount ? sourceAccount.clientLabel() : firstName + "'s bank account";
  };

  // don't use a function, it gets called every digest cycle
  $scope.oneYearInFuture = moment().add(1, 'y').toDate();

  /***************************************************************************
   *
   * Private methods for on load
   */
  var _getValidTransactionTypes = function () {
    var types = _.where(config.types.TransferInstruction, {
      system: false,
    });

    var list = $scope.transferableAccounts;
    if (list && list[0] && list.length === 1) {
      types = _.filter(types, function (selectedType) {
        return selectedType.name !== 'transfer';
      });
    }

    return types;
  };
  $scope.transactionTypes = _getValidTransactionTypes();

  // Do these on load
  _reset();
  if (!$scope.advisor) {
    _setGuiObjectsAndCancelFlags();
    _setGuiTransferRequestObjects();
  }

  // ////////////////////////////////////////////////////
  // Mobile integration
  $scope.selectedTabIndex = 0;

  // Mobile Dropdown options
  $scope.transferTypes = [
    {
      id: 1,
      label: 'Current & Upcoming Transfers',
      name: 'upcoming-transfers',
    },
    {
      id: 2,
      label: 'Past Transfers',
      name: 'past-transfers',
    },
  ];

  if ($scope.isMobile && $scope.accountTransfers && $scope.accountTransfers.length > 0) {
    $scope.transferTypes.push({
      id: 3,
      label: 'Account Transfers',
      name: 'account-transfers',
    });
  }

  $scope.transferType = new ram.Accessor();
  $scope.setViewToDisplayUpcomingTransfers = function () {
    $scope.transferType($scope.transferTypes[0]);
  };

  var initialState = _.findWhere($scope.transferTypes, {
    name: $stateParams.initialMobileViewTransfersState,
  });

  $scope.transferType(initialState);

  $scope.setNewTransferTab = function () {
    $scope.tab = 'new-transfer';
  };

  $scope.setViewTransfersTab = function () {
    $scope.tab = 'view-transfers';
  };
  // View initialization
  resetScope();

  // Scoped Functions

  function cancelAction() {
    goToReview();
  }

  function resetScope() {
    $scope.transferCount++;
  }

  function goToReview() {
    $state.go('advise.client-application.review');
  }
}
