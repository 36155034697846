'use strict';

angular.module('model.TaxStatus', []).factory('TaxStatus', ['ram', 'config', taxStatusFactory]);

function taxStatusFactory(ram, config) {
  var TaxStatus = new ram.Collection('TaxStatus', {
    belongsTo: {
      person: 'Person',
    },
    bind: ['hasTin', 'isValid'],
    enums: {
      reason: config.types.TaxStatusReason,
    },
    resources: {
      default: new ram.resources.Http('/api/tax_statuses/:id.json'),
      cookie: new ram.resources.Cookie('accounts'),
    },
  });

  TaxStatus.prototype.hasTin = function (arg) {
    if (arguments.length) {
      this._hasTin = arg;
      if (arg) {
        this.reasonText(null);
        this.reasonId(null);
      } else {
        this.taxIdentificationNumber(null);
      }
    } else {
      if (_.isUndefined(this._hasTin)) {
        if (this.taxIdentificationNumber()) {
          return true;
        } else if (Number.isInteger(this.reasonId())) {
          return false;
        } else {
          return undefined;
        }
      } else {
        return this._hasTin;
      }
    }
  };

  TaxStatus.prototype.isValid = function () {
    var usSSNPattern = /^\d{9}$/;

    var personAndJurisdiction = this.personId() && this.jurisdiction();

    var tinAndNoReason = this.taxIdentificationNumber() && !this.reasonId() && !this.reasonText();
    var withTinNotUs = this.jurisdiction() !== 'US' && tinAndNoReason;
    var withTinUs =
      this.jurisdiction() === 'US' &&
      usSSNPattern.test(this.taxIdentificationNumber()) &&
      tinAndNoReason;

    var withoutTinReasonNotOther =
      !this.taxIdentificationNumber() &&
      this.jurisdiction() !== 'US' &&
      Number.isInteger(this.reasonId()) &&
      !this.reason.is.other() &&
      !this.reasonText();

    var withoutTinReasonIsOther =
      !this.taxIdentificationNumber() &&
      this.jurisdiction() !== 'US' &&
      this.reason.is.other() &&
      this.reasonText();

    return (
      (personAndJurisdiction && withTinNotUs) ||
      (personAndJurisdiction && withTinUs) ||
      (personAndJurisdiction && withoutTinReasonNotOther) ||
      (personAndJurisdiction && withoutTinReasonIsOther)
    );
  };

  return TaxStatus;
}
