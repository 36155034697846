'use strict';

angular
  .module('controller.advise.trade-reconciliation.reports', [
    'model.TradeReconciliationReport',
    'ram',
  ])
  .controller('AdviseTradeReconciliationReportsCtrl', [
    '$scope',
    '$state',
    '$http',
    'ram',
    'loadingIndicator',
    'TradeReconciliationReport',
    'tradeReconciliationReports',
    adviseTradeReconciliationReportsController,
  ]);

function adviseTradeReconciliationReportsController(
  $scope,
  $state,
  $http,
  ram,
  loadingIndicator,
  TradeReconciliationReport,
  tradeReconciliationReports
) {
  var latestReportsNum = 10;
  var latestTradeReconciliationReports = getLatestReports(
    latestReportsNum,
    tradeReconciliationReports
  );

  $scope.showChoices = [
    {
      id: 1,
      label: 'Show last ' + latestReportsNum,
    },
    {
      id: 2,
      label: 'Show all',
    },
  ];

  var showLatestReport = new ram.Accessor($scope.showChoices[0]);
  $scope.showChoice = showLatestReport;

  $scope.$watch('showChoice()', function (showChoice) {
    $scope.tradeReconciliationReports =
      showChoice.id === 1 ? latestTradeReconciliationReports : tradeReconciliationReports;
  });

  $scope.deleteReport = function (report) {
    loadingIndicator.show();
    $http
      .delete('/api/trade_reconciliation_reports/' + report.id + '.json')
      .then(function () {
        $state.reload();
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  function getLatestReports(showNum, tradeReconciliationReports) {
    var reportCount = tradeReconciliationReports.length;
    var sliceBeginIndex = reportCount - showNum;

    if (sliceBeginIndex < 0) {
      return tradeReconciliationReports;
    }

    return _.sortBy(tradeReconciliationReports, function (report) {
      return report.id;
    }).slice(sliceBeginIndex, reportCount + 1);
  }
}
