'use strict';

function notificationFactory(ram, config) {
  var Notification = new ram.Collection('Notification', {
    belongsTo: {
      user: 'User',
    },
    resources: {
      default: new ram.resources.Http('/api/notifications/:id.json'),
      cookie: new ram.resources.Cookie('notifications'),
    },
    schema: config.schemas.Notification,
  });

  return Notification;
}

angular
  .module('model.Notification', ['ram', 'config'])
  .factory('Notification', ['ram', 'config', notificationFactory]);
