'use strict';

angular
  .module('service.portfolio-line-chart-data-service', [])
  .factory('portfolioLineChartDataService', ['$http', 'config', dataService]);

function dataService($http, config) {
  const sourceDateFormat = 'YYYY-MM-DD';
  const chartDateFormat = 'D-MMM-YY';
  const startAmount = 1000;

  const startDateByPortfolioOptionName = {
    core_portfolio: '2016-01-01',
    socially_responsible_portfolio: '2017-01-01',
    bcv_portfolio: '2022-04-01',
    harmony_portfolio: '2022-10-01',
    guardian_core: '2023-04-01',
    guardian_sustainable: '2023-04-01',
    savings_portfolio: '2023-04-01',
  };

  const firstBusinessDayByPortfolioOptionName = {
    core_portfolio: '2016-01-04',
    socially_responsible_portfolio: '2017-01-03',
    bcv_portfolio: '2022-04-01',
    harmony_portfolio: '2022-10-03',
    guardian_core: '2023-04-03',
    guardian_sustainable: '2023-04-03',
    savings_portfolio: '2023-04-03',
  };

  const noDataDataset = [
    {
      date: '2023-06-30',
      percentOfPrevious: '1.0',
    },
  ];

  const getPortfolioOptionId = (portfolioOptionName) => {
    return config.types.AccountPortfolioOption.find(({ name }) => name === portfolioOptionName)?.id;
  };

  const getPortfolioOptionName = (portfolioOptionId) => {
    return config.types.AccountPortfolioOption.find(({ id }) => id === portfolioOptionId)?.name;
  };

  const startOfYearDate = (portfolioOptionId) => {
    const portfolioOptionName = getPortfolioOptionName(portfolioOptionId);
    return moment(startDateByPortfolioOptionName[portfolioOptionName]);
  };

  const fetch = (riskLevel, portfolioOptionId) => {
    const params = {
      portfolioOptionId,
    };
    const hisPortfolioOptionId = getPortfolioOptionId('savings_portfolio');

    if (portfolioOptionId !== hisPortfolioOptionId) {
      params.riskLevel = riskLevel;
    }

    return $http
      .get('/api/portfolio_performance_histories.json', {
        params,
      })
      .then(function (historyResponse) {
        var previous;
        var firstDataPointDate;
        var startOfYear = startOfYearDate(portfolioOptionId);

        let histories =
          historyResponse.data.length === 0 ? [...noDataDataset] : [...historyResponse.data];

        histories = trimToStartOfyear(histories, startOfYear);
        histories = setStartIfNecessary(histories, startOfYear);
        firstDataPointDate = histories[0].date;
        histories = prependStartValues(histories, startOfYear);

        const formattedHistoriesForGraph = histories.map((history, index) => {
          const formattedHistory = {};
          formattedHistory.date = moment(history.date).toDate();
          formattedHistory.pctOfPrevious = +history.percentOfPrevious;

          if (index === 0) {
            formattedHistory.amount = startAmount;
          } else {
            formattedHistory.amount = previous * formattedHistory.pctOfPrevious;
          }
          previous = formattedHistory.amount;

          return formattedHistory;
        });

        const sortedDescHistory = formattedHistoriesForGraph.sort(
          (historyA, historyB) => historyB.date - historyA.date
        );

        return {
          data: sortedDescHistory,
          firstDataPointDate,
          firstBusinessDay: firstBusinessDay(portfolioOptionId),
          overlayEndDate: overlayEndDate(firstDataPointDate),
        };
      });
  };

  function trimToStartOfyear(data, startOfYear) {
    while (firstDate(data).isBefore(startOfYear)) {
      data.shift();
    }
    return data;
  }

  function setStartIfNecessary(data, startOfYear) {
    if (firstDate(data).isSame(startOfYear)) {
      data[0].percentOfPrevious = '1.000';
    }
    return data;
  }

  function prependStartValues(data, startOfYear) {
    while (firstDate(data).isAfter(startOfYear)) {
      data.unshift({
        date: moment(data[0].date, sourceDateFormat).subtract(1, 'days').format(sourceDateFormat),
        percentOfPrevious: '1.000',
      });
    }
    return data;
  }

  function firstDate(data) {
    return moment(data[0].date, sourceDateFormat);
  }

  function overlayEndDate(firstDataPointDate) {
    return moment(firstDataPointDate, sourceDateFormat).subtract(1, 'days').format(chartDateFormat);
  }

  function firstBusinessDay(portfolioOptionId) {
    const portfolioOptionName = getPortfolioOptionName(portfolioOptionId);
    return moment(firstBusinessDayByPortfolioOptionName[portfolioOptionName]);
  }

  return {
    fetch,
  };
}
