'use strict';

angular
  .module('controller.modal.deactivate-authenticator-app', [
    'ram',
    'ngSanitize',
    'model.User',
    'service.adaptive-views',
    'service.patterns',
    'service.two-factor-authenticator',
    'service.enter-key-handler',
  ])
  .controller('ModalDeactivateAuthenticatorAppController', [
    '$rootScope',
    '$scope',
    '$sce',
    '$http',
    '$state',
    'ram',
    'patterns',
    'twoFactorAuthenticator',
    'User',
    'isMobile',
    'adaptiveViews',
    'enterKeyHandler',
    modalDeactivateAuthenticatorAppController,
  ]);

function modalDeactivateAuthenticatorAppController(
  $rootScope,
  $scope,
  $sce,
  $http,
  $state,
  ram,
  patterns,
  twoFactorAuthenticator,
  User,
  isMobile,
  adaptiveViews,
  enterKeyHandler
) {
  $scope.isMobile = isMobile;
  $scope.isSafari = adaptiveViews.isSafari;
  $scope.dismissOrGoToProfile = function () {
    if ($state.is('profile-deactivate-authenticator-app')) {
      return $state.go('profile.security');
    } else {
      $scope.$dismiss();
    }
  };
  $scope.otpCode = new ram.Accessor();

  $scope.steps = ['enter-code'];

  $scope.currentStep = 0;
  $scope.isSaving = false;

  $scope.stepIs = function (stepName) {
    return $scope.steps[$scope.currentStep] === stepName;
  };

  function reloadUser() {
    return User.find({
      id: $rootScope.currentUser.id,
    }).then(function (user) {
      $rootScope.currentUser = user;
    });
  }

  $scope.validateForm = function () {
    $scope.feedbackMessage = {};
    if ($scope.currentStep === 0) {
      const otpCode = $scope.otpCode();
      if (!otpCode || otpCode.length === 0) {
        return;
      }

      $scope.isSaving = true;
      twoFactorAuthenticator.authenticatorAppDeactivate(otpCode).then(function (result) {
        if (result.error) {
          $scope.feedbackMessage = result;
          $scope.isSaving = false;
        } else {
          reloadUser().then(function () {
            $scope.isSaving = false;
            $scope.dismissOrGoToProfile();
          });
        }
      });
    }
  };
  enterKeyHandler.setupEnterKeyHandler($scope, '2fa-code-input-box-app', $scope.validateForm);
}
