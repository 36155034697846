'use strict';

angular
  .module('controller.advisor-new-account.invalid-link', [])
  .controller('AdvisorNewAccountInvalidLinkController', [
    '$scope',
    '$stateParams',
    'currentUser',
    advisorNewAccountInvalidLinkController,
  ]);

const HTTP_CODES = {
  CONFLICT: 409, // Show name of logged in user and buttons to log out/continue
  UNAUTHORIZED: 401, // Show name of logged in user and buttons to log out/continue
  RESOURCE_NOT_FOUND: 404, // Show 'Invalid link'/'Check with your advisor'
  RANGE_NOT_SATISFIED: 416, // Show 'Incomplete record'/'Check with your advisor'
};

function advisorNewAccountInvalidLinkController($scope, $stateParams, currentUser) {
  $scope.userName = currentUser?.person()?.fullName();
  $scope.action = {
    performing: 'new-account',
  };
  $scope.HTTP_CODES = HTTP_CODES;
  $scope.errorCode = Number($stateParams.errorCode || 404); // Avoid NaN

  $scope.logout = function () {
    $scope.$root.logout(linkAddress());
  };

  function linkAddress() {
    const url = document.location.href.split('?')?.at(0);
    return url.replace('advisor-new-account-invalid', 'continue-account-application');
  }
}
