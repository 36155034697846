'use strict';
import React, { useRef, useState } from 'react';
import AccountSelector from './AccountSelector';
import DateRange from './DateRange';
import generateReportWorker from './generate-report-worker';

const RealizedGainLossReport = ({
  accounts,
  csrfToken,
  userNotifications,
  isRunning,
  afterGenerateReport,
  config,
}) => {
  const lastYear = new Date().getFullYear() - 1;
  const [startDate] = useState(new Date(lastYear, 0, 1));
  const [endDate] = useState(new Date(lastYear, 11, 31));
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const accountRef = useRef();
  const dateRef = useRef();
  const reportName = 'Realized Gain Loss Report';
  const params = () => {
    const accountId = parseInt(accountRef.current.getAccountId());
    const startDate = dateRef.current.getStartDate();
    const endDate = dateRef.current.getEndDate();

    return {
      name: 'realized_gain_loss_report',
      account_id: accountId,
      start_date: startDate,
      end_date: endDate,
    };
  };

  const onAccountIdChange = (accountId) => {
    if (accountId) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const generateReport = () => {
    generateReportWorker({
      reportName,
      params,
      csrfToken,
      userNotifications,
      afterGenerateReport,
      isRunning,
      setIsSubmitting,
    });
  };

  return (
    <>
      <h4>{reportName}</h4>
      <AccountSelector
        accounts={accounts}
        ref={accountRef}
        onAccountIdChange={onAccountIdChange}
        config={config}
      />
      <DateRange initialStartDate={startDate} initialEndDate={endDate} ref={dateRef} />
      <div className="float-r">
        <button
          type="submit"
          onClick={generateReport}
          disabled={!isFormValid || isSubmitting}
          className="btn btn-success m-t-10 m-r-15"
        >
          {!isSubmitting && <span>Generate</span>}
          {isSubmitting && (
            <span>
              <img className="btn-icon" src="assets/images/loading-spinner.svg" /> Generating
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default RealizedGainLossReport;
