'use strict';

angular
  .module('directive.user.risk-appetite', [
    'directive.user.risk-appetite-indicator', // Currently only used in mobile version
    'service.adaptive-views',
    'filter.a-or-an',
  ])
  .directive('riskAppetite', ['adaptiveViews', riskAppetiteDirective]);

function riskAppetiteDirective(adaptiveViews) {
  return {
    restrict: 'E',
    scope: {
      user: '=',
      openAndHandleAppetiteModal: '&',
      riskSurveyComplete: '=',
    },
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/user/risk-appetite.html', true),
  };
}
