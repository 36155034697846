'use strict';

angular.module('directive.staticField', []).directive('staticField', [staticFieldDirective]);

function staticFieldDirective() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      fieldLabel: '@',
    },
    template:
      '<div class="row advise-static-field">' +
      '<div class="col-sm-3 control-label p-t-0">{{fieldLabel}}</div>' +
      '<div class="col-sm-9 static-content p-l-0"><ng-transclude></ng-transclude></div>' +
      '</div>',
  };
}
