'use strict';

angular
  .module('routes.advise.create-client', [
    'controller.advise.create-client',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  $stateProvider
    .state('advise.create-client', {
      url: '/create-client',
      abstract: true,
      controller: 'AdviseCreateClientCtrl',
      template: '<div id="create-client" class="container" ui-view></div>',
    })
    .state('advise.create-client.email', {
      url: '/email/?advisorReferralCode&portfolioManagerId',
      controller: 'AdviseCreateClientEmailCtrl',
      templateUrl: 'views/advise/create-client/email.html',
    });
}
