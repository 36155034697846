'use strict';

angular
  .module('service.new-client-creator', [
    'model.AdvisorCreateNewClientFlow',
    'model.PortfolioManager',
  ])
  .factory('newClientCreator', [
    'AdvisorCreateNewClientFlow',
    'PortfolioManager',
    newClientCreatorFactory,
  ]);

function newClientCreatorFactory(AdvisorCreateNewClientFlow, PortfolioManager) {
  function doCreate(email, advisorReferralCode, portfolioManagerId) {
    portfolioManagerId = _.isNullOrUndefined(portfolioManagerId)
      ? PortfolioManager.constructor.modernAdvisorId()
      : portfolioManagerId;
    return AdvisorCreateNewClientFlow.create({
      clientId: null,
      email,
      person: {
        firstName: null,
        lastName: null,
        birthDate: null,
        sin: null,
      },
      phoneNumber: {
        countryCode: null,
        areaCode: null,
        exchangeCode: null,
      },
      advisorReferralCode,
      preferredPortfolioManagerId: portfolioManagerId,
    }).then(function (flow) {
      return flow;
    });
  }

  return {
    create: doCreate,
  };
}
