'use strict';

angular
  .module('controller.onboarding.rif-detail', [
    'directive.interview.question-field',
    'directive.input.numeric-value-select',
    'directive.input',
    'model.RifDetail',
    'model.Account',
  ])
  .controller('OnboardingRifDetailController', [
    '$q',
    '$rootScope',
    '$scope',
    'RifDetail',
    'rifDetail',
    'spouse',
    'taxableAccounts',
    'account',
    'bankAccount',
    'bankAccounts',
    'config',
    onboardingRifDetailController,
  ]);

function onboardingRifDetailController(
  $q,
  $rootScope,
  $scope,
  RifDetail,
  rifDetail,
  spouse,
  taxableAccounts,
  account,
  bankAccount,
  bankAccounts,
  config
) {
  $scope.account = account;
  $scope.rifDetail = new RifDetail(_saveableFields(rifDetail));
  $scope.bankAccount = bankAccount;
  $scope.bankAccounts = bankAccounts;

  $scope.taxableAccounts = taxableAccounts;
  $scope.taxableAccountsOptions = taxableAccounts.map(function (taxableAccount) {
    return {
      label: taxableAccount.clientLabel,
      value: taxableAccount.id,
    };
  });

  $scope.days = [
    {
      label: 'On the 15th of the month',
      value: 15,
    },
    {
      label: 'On the 30th of the month',
      value: 30,
    },
  ];
  $scope.startMonthOptions = moment.months();
  $scope.startYearOptions = [moment().year(), moment().year() + 1];

  var setStartDateInputValidity = function (inputController, validity) {
    inputController.$setDirty();
    inputController.$setValidity('dateinthepast', validity);
  };

  var setStartDateValidity = function (validity) {
    $scope.dateInThePastError = !validity;
    setStartDateInputValidity($scope.form.startYear, validity);
    setStartDateInputValidity($scope.form.startMonth, validity);
    setStartDateInputValidity($scope.form.paymentDayOfMonth, validity);
  };

  // Returns the start date which will be at the begining of its day
  // e.g. The start date is February 15th, 2017 so the moment-date
  // returned is: Wed Feb 15 2017 00:00:00 GMT-0800 (Pacific Standard Time)
  var getStartDate = function (day, month, year) {
    if (day === 30 && month === 1) {
      return moment([year, month]).endOf('month').startOf('day');
    } else {
      return moment([year, month, day]);
    }
  };

  $scope.validatePaymentStart = function () {
    var paymentDay = $scope.rifDetail.paymentDayOfMonth();
    var startMonth = $scope.rifDetail.startMonth();
    var startYear = $scope.rifDetail.startYear();

    if (paymentDay && startMonth >= 0 && startYear) {
      if (getStartDate(paymentDay, startMonth, startYear).diff(moment()) < 0) {
        setStartDateValidity(false);
      } else {
        setStartDateValidity(true);
      }
    }
  };

  // Age to Use
  if (!spouse) {
    $scope.rifDetail.ageToUse(config.types.RifAgeToUse[0]);
  }

  // tax withholding
  $scope.taxWithholdingChoice = null;
  if ($scope.rifDetail.taxWithholdingType()) {
    $scope.taxWithholdingChoice = $scope.rifDetail.taxWithholdingType.is.minimumRequired()
      ? 'minimum_required'
      : 'other';
  }
  $scope.$watch('taxWithholdingChoice', function (newValue) {
    if (newValue === 'minimum_required') {
      $scope.rifDetail.taxWithholdingType(config.types.RifTaxWithholding[0]);
      $scope.rifDetail.taxWithholdingPercent(null);
      $scope.rifDetail.taxWithholdingAmount(null);
    } else if (!newValue) {
      $scope.rifDetail.taxWithholdingType(config.types.RifTaxWithholding[1]);
    }
  });
  $scope.deductTaxAmount = function (val) {
    if (val) {
      $scope.rifDetail.taxWithholdingType(config.types.RifTaxWithholding[1]);
      $scope.rifDetail.taxWithholdingPercent(null);
    }
    return $scope.rifDetail.taxWithholdingType.is.amount();
  };
  $scope.deductTaxPercent = function (val) {
    if (val) {
      $scope.rifDetail.taxWithholdingType(config.types.RifTaxWithholding[2]);
      $scope.rifDetail.taxWithholdingAmount(null);
    }
    return $scope.rifDetail.taxWithholdingType.is.percentage();
  };

  // send payment to
  $scope.rifCtrl = {
    payToChoice: null,
  };
  if ($scope.rifDetail.payToBankAccountId()) {
    $scope.rifCtrl.payToChoice = 'bank';
  }
  if ($scope.rifDetail.payToAccountId()) {
    $scope.rifCtrl.payToChoice = 'individual';
  }
  $scope.$watch('rifCtrl.payToChoice', function (newValue) {
    if (!newValue) {
      return;
    }
    var bankAccountId = null;
    var individualAccountId = null;
    switch (newValue) {
      case 'bank':
        bankAccountId = $scope.rifDetail.payToBankAccountId() || bankAccount.id;
        break;
      case 'individual':
        individualAccountId =
          taxableAccounts && taxableAccounts.length === 1
            ? _.first(taxableAccounts).id
            : $scope.rifDetail.payToAccountId();
        break;
    }
    $scope.rifDetail.payToBankAccountId(bankAccountId);
    $scope.rifDetail.payToAccountId(individualAccountId);
  });

  if (!taxableAccounts || taxableAccounts.length === 0) {
    var bankAccountId = $scope.rifDetail.payToBankAccountId() || bankAccount.id;
    $scope.rifDetail.payToBankAccountId(bankAccountId);
  }

  $scope.$parent.save = function () {
    var promises = [rifDetail.save(_saveableFields($scope.rifDetail))];

    if ($scope.rifDetail.payToBankAccountId()) {
      promises.push(account.updateBankAccount($scope.rifDetail.payToBankAccountId()));
    }

    return $q.all(promises);
  };

  $scope.$parent.done = function () {
    return $rootScope.validateForm($scope.form) && $scope.form.$valid;
  };

  $scope.$parent.notDone = function () {
    return $scope.formNotDone();
  };

  $scope.accountLabel = function () {
    if (account.type.is.lif()) {
      return 'LIF';
    } else {
      return 'RRIF';
    }
  };

  function _saveableFields(obj) {
    return _.omit(obj.toJSON(), 'id');
  }
}
