import React from 'react';
import FileUpload from '../file-upload/FileUpload';

function UploadChangedChargeRecords({ csrfToken, userNotifications }) {
  const api = '/api/super_advisor/charge/upload_changed_charge_data.json';
  const tooltipText = 'Extra columns beyond those in the template are ignored.';

  function onload({ success, result }) {
    if (success) {
      userNotifications.showSuccess('File processed successfully.  Fee Reports are running.');
    } else {
      const errorMessage =
        result.error || 'There was an unexpected result. Please try again later.';
      userNotifications.showError(errorMessage);
    }
  }

  function onerror() {
    userNotifications.showError(
      'Something went wrong while uploading the data. Please try again later.'
    );
  }

  return (
    <div className="container">
      <h3>Upload Changed Charge Records</h3>
      <div className="panel panel-default">
        <div className="panel-body">
          <div id="user-notification" className="m-b-0-alert-override" />

          <FileUpload
            buttonText="Pick file and process"
            tooltipText={tooltipText}
            api={api}
            acceptList=".xlsx"
            csrfToken={csrfToken}
            onload={onload}
            onerror={onerror}
          />
        </div>
      </div>
    </div>
  );
}

export default UploadChangedChargeRecords;
