'use strict';

angular
  .module('directive.advise.beneficiary-modify-card', [
    'service.account-service',
    'service.spouse-or-partner',
    'service.beneficiary-service',
    'model.Beneficiary',
    'directive.beneficiary-regular',
    'directive.beneficiary-resp',
    'directive.with',
    'directive.staticField',
    'directive.staticFieldVertical',
    'filter.masked-text',
  ])
  .directive('beneficiaryModifyCard', [
    '$http',
    'accountService',
    'spouseOrPartner',
    'beneficiaryService',
    beneficiaryCardDirective,
  ]);

function beneficiaryCardDirective($http, accountService, spouseOrPartner, beneficiaryService) {
  return {
    restrict: 'E',
    scope: {
      advisor: '=',
      allBeneficiaries: '=',
      singleBeneficiary: '=',
      successorHolder: '=',
      account: '=',
      client: '=',
      editToggle: '=',
      editingEnabled: '=',
      foreignScope: '=',
    },
    replace: true,
    templateUrl: 'directives/beneficiary-modify-card.html',

    controller: [
      '$scope',
      function ($scope) {
        $scope.isResp = accountService.isResp($scope.account);
        $scope.isFamilyResp = accountService.isFamilyResp($scope.account);

        $scope.person = $scope.client.person();
        $scope.currentUser = $scope.client; // This may be used in the context of superadvisor is the currentUser
        $scope.spouse = $scope.person.spouseRelation() && $scope.person.spouseRelation().relative();

        beneficiaryService.setScope($scope);
        beneficiaryService.setAdvisor($scope.advisor);
        beneficiaryService.setAccount($scope.account);
        beneficiaryService.inlineSave(false);

        if (!$scope.isResp) {
          $scope.beneficiary = $scope.successorHolder || _.first($scope.allBeneficiaries);
          $scope.beneficiarySummaryStatement = beneficiaryService.beneficiarySummaryStatement;
          $scope.$watch('beneficiary', function (newVal) {
            if (
              newVal === null || // delete operation gives this
              (newVal && newVal.id)
            ) {
              // Was getting half-baked objects with not enough data
              $scope.personalRelation = newVal && newVal.personalRelation();
              $scope.relative = $scope.personalRelation && $scope.personalRelation.relative();
            }
          });
        } else {
          $scope.beneficiary = $scope.singleBeneficiary;
          $scope.personalRelationFor = function (beneficiary) {
            return beneficiary && beneficiary.personalRelation();
          };
          $scope.relativeFor = function (beneficiary) {
            var personalRelation = $scope.personalRelationFor(beneficiary);
            return personalRelation && personalRelation.relative();
          };
        }

        $scope.personalSubforms = {};

        $scope.spouseOrPartner = function () {
          return spouseOrPartner.relationshipName($scope.person);
        };
      },
    ],
  };
}
