'use strict';

angular
  .module('directive.document-upload', ['csrf', 'model.Document', 'service.adaptive-views'])
  .directive('documentUpload', DocumentUploadDirective);

/**
 * Creates an image uploading widget. This widget will upload an image to S3
 * and override an image that has already been uploaded with the same name.
 *
 * It requires that the file-name attribute contains a name that is delimited
 * by an underscore and has all lowercase letters (ex. "my_file_name"). The
 * ngModel also needs to be set to a Document.
 *
 * @param {Object} $parse
 * @param {Object} $timeout
 * @param {Object} config
 * @param {Function} Document
 * @return {Object} directive definition
 */
DocumentUploadDirective.$inject = [
  '$analytics',
  '$parse',
  '$timeout',
  'config',
  'csrfToken',
  'Document',
  'adaptiveViews',
];

function DocumentUploadDirective(
  $analytics,
  $parse,
  $timeout,
  config,
  csrfToken,
  Document,
  adaptiveViews
) {
  function DocumentUploadLink(scope, element, attrs, ngModelController) {
    var Dropzone = window.Dropzone;
    var theDropzone = null;
    var parentSetter = $parse(attrs.ngModel).assign;

    var docTypeName = attrs.type.length > 0 ? attrs.type : 'unspecified';
    var docType = _.findWhere(config.types.Document, {
      name: docTypeName,
    });

    function targetUserId() {
      return scope.clientId
        ? scope.clientId
        : scope.$root.currentUser && scope.$root.currentUser.id;
    }

    angular.extend(scope, {
      helpText: '',
      successText: '',
      credentials: null,
      loading: false,
      index: 0, // If there are multiple instances of this control on a page, give them a unique ID and index. `id="upload-index0" index="0"`
      documentUrl: null,
      multiFile: false,
      elementId: attrs.id,
    });

    ngModelController.$isEmpty = function (inputValue) {
      return !inputValue || !inputValue.id;
    };

    function createDocument() {
      var newDoc = Document.new({
        userId: targetUserId(),
      });
      newDoc.type(docType);
      parentSetter(scope.$parent, newDoc);
      scope.helpText = '';
      scope.successText = '';
      return newDoc;
    }

    function attachFormData(_file, _request, formData) {
      if (scope.relatedToType && scope.relatedToId) {
        scope.document.relatedToType(scope.relatedToType);
        scope.document.relatedToId(scope.relatedToId);
      }
      if (scope.documentType) {
        scope.document.typeId(scope.documentType);
      }

      _.each(scope.document.toJSON(true), function (val, key) {
        var address = s.sprintf('document[%s]', s.underscored(key));
        formData.append(address, val);
      });
    }

    function logError(file, msg, xhr) {
      scope.$apply(function () {
        scope.helpText = xhr?.statusText ?? msg; // jslint ignore:line
        scope.successText = '';
      });
      var errorMsg = 'An error occurred while uploading documents.';
      window.Sentry &&
        window.Sentry.withScope((scope) => {
          scope.setExtra('userId', targetUserId());
          scope.setExtra('fileName', file.name);
          scope.setExtra('errorMsg', msg);
          window.Sentry.captureMessage(errorMsg);
        }); // jshint ignore:line
    }

    function updateDocument(file, response) {
      var attrs = _.camelizeKeys(response);
      scope.$root.$broadcast('documentUploaded', attrs, file);

      scope.$apply(function () {
        scope.helpText = '';
        scope.successText = file.name + ' uploaded successfully';
        $analytics.eventTrack('uploaded-document');
        scope.document.update(attrs);
      });
      // Allow the help-block to update its message
      ngModelController.$validate();
      element.trigger('change');
    }

    function enableUploadSpinner(enabled) {
      $('#upload-index' + scope.index + ' .loading').toggleClass('hidden', !enabled);
      $('#upload-index' + scope.index + ' .not-loading').toggleClass('hidden', enabled);
    }

    function enableDropzone(enabledState) {
      if (theDropzone) {
        if (enabledState) {
          theDropzone.enable();
        } else {
          theDropzone.disable();
        }
      }
    }

    function getHttpHeaders() {
      var retHash = {
        'X-CSRF-Token': csrfToken(),
      };

      if (scope.anonymous && scope.credentials) {
        retHash.Authorization = 'Basic ' + btoa(scope.credentials);
      }
      return retHash;
    }

    function getHttpUrl() {
      if (scope.anonymous && scope.credentials) {
        return 'api/add_mobile_photo' + (scope.invitedByAdvisor ? '?invitedByAdvisor=true' : '');
      }
      return '/api/documents';
    }

    attrs.$observe('clientId', function (value) {
      scope.clientId = value;
    });

    attrs.$observe('relatedToId', function (value) {
      scope.relatedToId = value;
    });

    attrs.$observe('relatedToType', function (value) {
      scope.relatedToType = value;
    });

    attrs.$observe('documentType', function (value) {
      scope.documentType = value;
      if (theDropzone) {
        theDropzone.affectedFiles = config.types.Document[value].mimeTypes;
      }
    });

    attrs.$observe('index', function (value) {
      // Make sure this matches the id in your view
      scope.index = value;
    });

    attrs.$observe('multiFile', function (value) {
      scope.multiFile = value === 'true';
    });

    attrs.$observe('enable', function (value) {
      // observe makes value a string
      if (value === 'true') {
        enableDropzone(true);
      } else {
        enableDropzone(false);
      }
    });

    attrs.$observe('anonymous', function (value) {
      // observe makes value a string
      scope.anonymous = value === 'true';
    });

    attrs.$observe('credentials', function (value) {
      // value is of the form "username:password" in plain text
      scope.credentials = value;
    });

    scope.$on('$destroy', function () {
      if (theDropzone) {
        theDropzone.destroy();
      }
    });

    const pdfRegex = /\.pdf$/;

    scope.$watch(attrs.ngModel, function (doc) {
      scope.document = doc;
      if (!doc && parentSetter) {
        scope.document = createDocument();
      }
      if (doc && scope.documentUrl !== doc.url()) {
        scope.documentUrl = !pdfRegex.test(doc.awsKey()) ? doc.url() : null;
        scope.isPdf = pdfRegex.test(doc.awsKey());
      }
    });

    $timeout(function () {
      var div = element.find('.dropzone').get(0);
      var dropzoneConfig = {
        acceptedFiles: docType.mimeTypes,
        addedFile: angular.noop,
        dictDefaultMessage: '',
        headers: getHttpHeaders(),
        maxFilesize: parseInt(attrs.maxSize) || 10,
        previewTemplate: '<div style="display: none;"></div>',
        thumbnail: angular.noop,
        url: getHttpUrl(),
      };

      theDropzone = new Dropzone(div, dropzoneConfig)
        .on('addedfile', function () {
          // Reset and prepare for new file
          scope.$apply(function () {
            scope.helpText = '';
            scope.successText = '';
          });
          enableUploadSpinner(true);
        })
        .on('sending', attachFormData)
        .on('error', logError)
        .on('success', updateDocument)
        .on('complete', function () {
          this.removeAllFiles();
          enableUploadSpinner(false);
          scope.$apply(function () {
            scope.documentUrl = !pdfRegex.test(scope.document.awsKey())
              ? scope.document.url()
              : null;
            scope.isPdf = pdfRegex.test(scope.document.awsKey());
          });
        });

      if (_.chain(attrs).keys().contains('enable').value()) {
        theDropzone.disable();
      }
    });
  }

  return {
    restrict: 'E',
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/document-upload.html', true),
    require: 'ngModel',
    transclude: true,
    scope: true,
    controller: ['$scope', '$attrs', DocumentUploadController],
    link: DocumentUploadLink,
  };
}

DocumentUploadController.$inject = ['$scope', '$attrs'];

function DocumentUploadController($scope, $attrs) {
  $scope.anonymous = !!($attrs.anonymous && $attrs.anonymous === 'true');
  $scope.wide = $attrs.wide && $attrs.wide === 'true';
}
