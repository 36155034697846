'use strict';

/***********************************************************************************
 * OLOO base object - https://github.com/nboostani/modernadvisor-main/wiki/OLOO-Pattern---JavaScript-Objects-Linking-to-Other-Objects
 */
var dropdownDataHolder = {
  options: [],
  setOptions: null, // to be provided below...
  defaultOption: 0,
  filterOptions: parentFilterOptions,
  getSelected: parentGetSelected,
  setDefault: parentSetDefault,
  setModel: parentSetModel,
};

/**
 * OLOO derived objects, one for each dropdown
 */
var documentTypeDropdownData = Object.create(dropdownDataHolder);
documentTypeDropdownData.setOptions = setOptionsDocumentType;
documentTypeDropdownData.getSelected = getSelectedDocumentTypeId;

var yearDropdownData = Object.create(dropdownDataHolder);
yearDropdownData.setOptions = setOptionsYear;

var accountDropdownData = Object.create(dropdownDataHolder);
accountDropdownData.setOptions = setOptionsAccount;

/***********************************************************************************
 * General case controller for this component
 */
class FilteredClientDocumentsController {
  constructor($scope, ram, config, FilteredDocumentHolder, securityServiceFactory) {
    this.$scope = $scope;
    this.ram = ram;
    this.config = config;
    this.FilteredDocumentHolder = FilteredDocumentHolder;
    this.securityServiceFactory = securityServiceFactory;
  }

  $onInit() {
    const platform = this.config.env.platform;
    const isWsi = platform && platform.name === 'WSI';

    this.filterTool = new this.FilteredDocumentHolder(this.client.documents());

    const currentUserSecurity = this.securityServiceFactory.create(this.$scope.$root.currentUser);

    documentTypeDropdownData.filterOptions = filterOptionsDocumentType.bind(
      this,
      isWsi,
      currentUserSecurity
    );
    documentTypeDropdownData.setOptions(
      this.config.types.Document.filter(
        (type) => type.matchingFilterTypeId === undefined || type.matchingFilterTypeId === null
      )
    );
    this.documentTypeSelected = new this.ram.Accessor(documentTypeDropdownData.defaultOption);
    this.documentTypes = documentTypeDropdownData.options;
    documentTypeDropdownData.setModel(this.documentTypeSelected);

    yearDropdownData.setOptions(this.filterTool);
    this.yearSelected = new this.ram.Accessor(yearDropdownData.defaultOption);
    this.years = yearDropdownData.options;
    yearDropdownData.setModel(this.yearSelected);

    accountDropdownData.setOptions(this.clientAccounts);
    this.accountSelected = new this.ram.Accessor(accountDropdownData.defaultOption);
    this.displayAccounts = accountDropdownData.options;
    accountDropdownData.setModel(this.accountSelected);

    this.filterAction();
  }

  getAccountNumberText(document) {
    const account = _(this.client.viewableAccounts()).findWhere({
      id: document.relatedToId(),
    });
    const accountNumber = !account ? 'not available' : account.number() || 'not assigned';
    const shortLabel = !account ? 'not available' : account.shortLabel();

    return shortLabel + ' - ' + accountNumber;
  }

  getDescriptionText(document) {
    const documentTypesWithPersonNameInFilename = [
      'eid_results',
      'w8ben',
      'w8ben_joint',
      'w9',
      'w9_joint',
    ];

    if (documentTypesWithPersonNameInFilename.includes(document.type().name) && document.awsKey()) {
      const personNameRegex = new RegExp('(.*)_' + document.type().name.toUpperCase() + '_[0-9]*');
      const nameInFileMatch = document.awsKey().match(personNameRegex);
      const personName = (nameInFileMatch && nameInFileMatch[1]) || this.client.person().fullName();

      return document.type().label + ' - ' + personName;
    } else {
      return document.type().label;
    }
  }

  loadModifyDocumentModal(document) {
    this.$scope.$root.advisorModifyDocumentModal(
      document,
      [...this.config.types.Document].sort((x, y) => x.label.localeCompare(y.label)),
      this.attachToOptions
    );
  }

  filterAction(hint = null) {
    if (hint) {
      this._processHint(hint);
    }
    const year = yearDropdownData.getSelected();
    const accountId = accountDropdownData.getSelected();
    const documentTypeId = documentTypeDropdownData.getSelected();

    this.filteredDocuments = this.filterTool
      .reset()
      .filterByYear(year)
      .filterByAccount(accountId)
      .filterByDocumentType(documentTypeId)
      .result();
  }

  _processHint(hint) {
    if (hint === 'account' && accountDropdownData.getSelected() === 'user') {
      this.documentTypeSelected(0);
      this.yearSelected(0);
    }
  }
}

export default FilteredClientDocumentsController;

/***********************************************************************************
 * Per-dropdown setup methods
 */

// Note use of .bind() to pre-fill the first 2 parameters
function filterOptionsDocumentType(isWsi, currentUserSecurity, item) {
  if (!isWsi && item.fccShow) {
    return false;
  } else if (item.superadvisorOnly) {
    return currentUserSecurity.permissions.viewSuperAdvisorDocuments();
  }
  return true;
}

function setOptionsDocumentType(input) {
  var _this = this; // jshint ignore:line

  var prependOptions = [
    {
      label: 'All',
      name: 'all',
    },
  ];

  var filteredInput = _.filter(input, function (item) {
    return _this.filterOptions(item);
  });
  // The document dropdown list needs to be sorted Alphabetically for documents here so
  // that 'All' can still be prepended to the top of the list.
  var options = prependOptions.concat(
    _.map(
      filteredInput.sort((x, y) => x.label.localeCompare(y.label)),
      function (item) {
        return {
          label: item.label,
          name: item.name,
          typeId: item.id,
        };
      }
    )
  );

  _this.options = mapSetValueIndex(options);
  _this.setDefault('all');
}

function getSelectedDocumentTypeId(index) {
  var parent = this; // jshint ignore:line
  index = index || (parent.model && parent.model());
  return parent.options[index].typeId;
}

function setOptionsYear(filterTool) {
  var parent = this; // jshint ignore:line

  var prependOptions = [
    {
      label: 'All',
      name: 'all',
    },
    {
      label: 'YTD',
      name: 'ytd',
    },
  ];

  var years = filterTool.getYears();
  var options = prependOptions.concat(
    _.map(years, function (item) {
      return {
        label: item,
        name: parseInt(item),
      };
    })
  );

  parent.options = mapSetValueIndex(options);
  parent.setDefault(years[0] || 'all');
}

function setOptionsAccount(input) {
  var parent = this; // jshint ignore:line

  var prependOptions = [
    {
      label: 'All',
      name: 'all',
    },
    {
      label: 'User Documents',
      name: 'user',
    },
  ];

  var options = prependOptions.concat(
    _.map(input, function (item) {
      return {
        label: item.label(),
        name: item.id,
      };
    })
  );

  parent.options = mapSetValueIndex(options);
  parent.setDefault('all');
}

function mapSetValueIndex(options) {
  return _.map(options, function (item, index) {
    return Object.assign(item, {
      value: index,
    });
  });
}

/***********************************************************************************
 * Parent object helper methods
 */
function parentFilterOptions() {
  return true;
}

function parentGetSelected(index) {
  var self = this; // jshint ignore:line
  index = index || (self.model && self.model());
  return self.options[index].name;
}

function parentSetDefault(name) {
  var self = this; // jshint ignore:line
  self.defaultOption = _.find(self.options, function (item) {
    return item.name === name;
  }).value;
}

function parentSetModel(model) {
  var self = this; // jshint ignore:line
  self.model = model;
}
