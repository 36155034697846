'use strict';

angular
  .module('controller.dashboard.holdings', [
    'component.investment-charts.holdings-pie-chart',
    'component.investment-charts.holdings-pie-chart-legend',
    'directive.dashboard-account-processing-messages',
    'service.investment-charts.holdings-data-generator',
  ])
  .controller('DashboardHoldingsCtrl', [
    '$scope',
    'account',
    'accounts',
    'currentUser',
    'identityVerificationStatus',
    'investmentChartsHoldingsDataGenerator',
    'positions',
    'invBarPrepData',
    holdingsController,
  ]);

function holdingsController(
  $scope,
  account,
  accounts,
  currentUser,
  identityVerificationStatus,
  investmentChartsHoldingsDataGenerator,
  positions,
  invBarPrepData
) {
  $scope.account = account;
  $scope.identityVerificationStatus = identityVerificationStatus;
  if (account.isCombinedAccount()) {
    $scope.positions = investmentChartsHoldingsDataGenerator.getAggregateData(positions);
  } else {
    $scope.positions = investmentChartsHoldingsDataGenerator.getNonAggregateData(
      positions,
      account.accountGoals()[0].modelPortfolio()
    );
  }
  $scope.last = _.last;

  if (positions && positions.length > 0) {
    $scope.bookValueBalance = $scope.positions
      .map((pos) => parseFloat(pos.bookValue()))
      .reduce((a, b) => a + b);
    $scope.$parent.$parent.messageBoard.initializeHoldingsPage(currentUser, accounts, account);

    const invChartDataObject = invBarPrepData.getChartDataForPositions($scope.positions);

    if (invChartDataObject) {
      $scope.toBeStacked = invChartDataObject.toBeStacked;
      $scope.investmentsChartData = invChartDataObject.investmentsChartData;
    }
  }
}
