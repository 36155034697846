'use strict';

function adviseTransferRequestsAccountTransfersController(
  $scope,
  loadingIndicator,
  Paginator,
  PaginatedAccountTransfer
) {
  var modalPresets = {
    sent: {
      title: 'Send Transfer',
      body: 'Are you sure you want to mark this transfer as sent?',
    },
    rejected: {
      title: 'Reject Transfer',
      body: 'Are you sure you want to reject this transfer?',
    },
    completed: {
      title: 'Finish Transfer',
      body: 'Are you sure you want to mark this transfer as finished?',
    },
    failed: {
      title: 'Fail Transfer',
      body: 'Are you sure you want to fail this transfer?',
    },
  };

  /** ***************************** Set up filters */
  $scope.filterData = [
    {
      id: 0,
      group: 'Status',
      label: 'Waiting for signature',
      code: 'status.created',
    },
    {
      id: 1,
      group: 'Status',
      label: 'Pending Review',
      code: 'status.signed',
    },
    {
      id: 2,
      group: 'Status',
      label: 'Cancelled',
      code: 'status.cancelled',
    },
    {
      id: 3,
      group: 'Status',
      label: 'Complete',
      code: 'status.completed',
    },
    {
      id: 4,
      group: 'Status',
      label: 'Verifying signature',
      code: 'status.verifyingsignature',
    },
    {
      id: 5,
      group: 'Status',
      label: 'Declined',
      code: 'status.failed',
    },
    {
      id: 6,
      group: 'Status',
      label: 'Rejected',
      code: 'status.rejected',
    },
    {
      id: 7,
      group: 'Status',
      label: 'Sent for processing',
      code: 'status.sent',
    },
  ];

  /** ***************************** Pagination methods */
  $scope.filterModel = [];

  var _getDataFromPaginator = function (parameter) {
    return parameter.accountTransfers();
  };

  $scope.paginator = new Paginator(PaginatedAccountTransfer, null, _getDataFromPaginator);

  /** ***************************** `Mark as` methods */
  function userOperation(accountTransfer, options, callback) {
    $scope
      .genericConfirmationModal(
        modalPresets[options.dialogState].title,
        modalPresets[options.dialogState].body
      )
      .result.then(function () {
        loadingIndicator.show();
        callback()
          .then(function () {
            return accountTransfer.reload();
          })
          .then(function () {
            loadingIndicator.hide();
          });
      });
  }

  $scope.markAsSent = function (accountTransfer) {
    userOperation(
      accountTransfer,
      {
        dialogState: 'sent',
      },
      function () {
        return accountTransfer.markAsSent();
      }
    );
  };

  $scope.markAsFailed = function (accountTransfer) {
    userOperation(
      accountTransfer,
      {
        dialogState: 'failed',
      },
      function () {
        return accountTransfer.markAsFailed();
      }
    );
  };

  $scope.markAsRejected = function (accountTransfer) {
    userOperation(
      accountTransfer,
      {
        dialogState: 'rejected',
      },
      function () {
        return accountTransfer.markAsRejected();
      }
    );
  };

  $scope.markAsCompleted = function (accountTransfer) {
    userOperation(
      accountTransfer,
      {
        dialogState: 'completed',
      },
      function () {
        return accountTransfer.markAsCompleted();
      }
    );
  };

  $scope.canSendOrReject = function (accountTransfer) {
    return accountTransfer.status.is.signed() || accountTransfer.status.is.verifyingSignature();
  };

  $scope.canFailOrComplete = function (accountTransfer) {
    return accountTransfer.status.is.sent();
  };
}

angular
  .module('controller.advise.transfer-requests.account-transfers', [
    'model.Account',
    'model.PaginatedAccountTransfer',
    'service.loading-indicator',
    'model.Paginator',
  ])
  .controller('AdviseTransferRequestsAccountTransfersCtrl', [
    '$scope',
    'loadingIndicator',
    'Paginator',
    'PaginatedAccountTransfer',
    adviseTransferRequestsAccountTransfersController,
  ]);
