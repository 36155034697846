'use strict';

angular
  .module('controller.advise.accounts', [
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedAccount',
    'model.Paginator',
    'directive.secured',
    'service.pagination.filter-service',
  ])
  .controller('AdviseAccountsCtrl', [
    '$scope',
    '$rootScope',
    'PaginatedAccount',
    'Paginator',
    'config',
    'paginationFilterService',
    adviseAccountsController,
  ]);

function adviseAccountsController(
  $scope,
  $rootScope,
  PaginatedAccount,
  Paginator,
  config,
  paginationFilterService
) {
  $scope.bulkAccounts = [];
  $scope.numColumns = $scope.security.can('superAdvisor') ? 10 : 7;
  $scope.accountPortfolioOptionIds = {};
  _.each(config.types.AccountPortfolioOption, function (option) {
    $scope.accountPortfolioOptionIds[option.name] = option.id;
  });

  var _getDataFromPaginator = function (parameter) {
    return parameter.accounts();
  };

  var initialFilter = ['status.open'];
  $scope.initialActions = function () {
    return [
      {
        type: 'FLEX-FILTER',
        filterList: initialFilter,
      },
    ];
  };

  $scope.paginator = new Paginator(PaginatedAccount, null, _getDataFromPaginator);

  var filterData = [];

  // filter out statuses related to "New Accounts".
  paginationFilterService.addEnumFilterData(
    _.reject(config.types.AccountStatus, function (status) {
      return _.contains(['started', 'pending', 'approved', 'rejected', 'expired'], status.name);
    }),
    filterData,
    'Status',
    'status'
  );

  paginationFilterService.addEnumFilterData(config.types.Account, filterData, 'Type', 'type');

  if ($rootScope.currentUser.canSelectAdaptivePortfolio()) {
    filterData.push({
      id: filterData.length,
      group: 'Portfolio Type',
      label: 'Adaptive',
      code: 'portfolio.adaptive',
    });
  }

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Harmony',
    code: 'portfolio.harmony',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Responsible',
    code: 'portfolio.responsible',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Core',
    code: 'portfolio.core',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'BCV',
    code: 'portfolio.bcv',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Core Ex-Russia',
    code: 'portfolio.core_ex_russia',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Responsible Ex-Russia',
    code: 'portfolio.responsible_ex_russia',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'High Interest Savings',
    code: 'portfolio.savings',
  });

  if ($rootScope.security.can('superAdvisor')) {
    filterData.push({
      id: filterData.length,
      group: 'Advisor',
      label: 'ModernAdvisor',
      code: 'advisor.modernadvisor',
    });

    filterData.push({
      id: filterData.length,
      group: 'Advisor',
      label: 'Other Advisors',
      code: 'advisor.external',
    });

    filterData.push({
      id: filterData.length,
      group: 'Automatic Rebalancing',
      label: 'Enabled',
      code: 'skip_rebalancing.false',
    });

    filterData.push({
      id: filterData.length,
      group: 'Automatic Rebalancing',
      label: 'Disabled',
      code: 'skip_rebalancing.true',
    });

    filterData.push({
      id: filterData.length,
      group: 'Custodian',
      label: 'Credential',
      code: 'custodian.csi',
    });

    filterData.push({
      id: filterData.length,
      group: 'Custodian',
      label: 'BBS Securities',
      code: 'custodian.bbs',
    });

    filterData.push({
      id: filterData.length,
      group: 'Custodian',
      label: 'FCC',
      code: 'custodian.fcc',
    });

    filterData.push({
      id: filterData.length,
      group: 'Other',
      label: 'PRO Only',
      code: 'person.pro',
    });
  }

  $scope.filterData = filterData;
  $scope.filterModel = paginationFilterService.toFilterModel(filterData, initialFilter);
}
