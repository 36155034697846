'use strict';

angular
  .module('service.reload-current-user', [])
  .factory('reloadCurrentUser', ['$timeout', reloadCurrentUserFactory]);

function reloadCurrentUserFactory($timeout) {
  var retries = 0;

  function retryDelay(retry) {
    var delay = 2000;
    while (retry > 30) {
      delay *= 2;
      retry /= 2;
    }
    return delay;
  }

  function reloadCurrentUser($scope, successFn) {
    if ($scope.currentUser.confirmed() || retries > 120) {
      if (successFn) {
        successFn();
      }
      return;
    }

    $scope.currentUser.fetch().then(function (user) {
      $scope.currentUser = user;
    });

    $timeout(reloadCurrentUser, retryDelay(++retries), true, $scope, successFn);
  }

  return reloadCurrentUser;
}
