'use strict';

angular
  .module('controller.onboarding.legalities', [
    'directive.input.button-group',
    'service.dropdown-options',
  ])
  .controller('OnboardingLegalitiesController', [
    '$scope',
    '$q',
    'ram',
    'brokerageAccounts',
    'dropdownOptions',
    'insiderRoles',
    onboardingLegalitiesCtrl,
  ]);

function onboardingLegalitiesCtrl(
  $scope,
  $q,
  ram,
  brokerageAccounts,
  dropdownOptions,
  insiderRoles
) {
  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.anyApply = new ram.Accessor(initialAnyApplyValue());

  $scope.legalScope = {
    legalInfo: $scope.person.legalInfo(),
    brokerageAccounts: {
      initialData: brokerageAccounts,
    },
    insiderRoles: {
      initialData: insiderRoles,
    },
  };

  $scope.$parent.save = function () {
    return $q.all([
      $scope.currentUser.save(),
      $scope.legalScope.insiderRoles.controller.saveAll(),
      $scope.legalScope.brokerageAccounts.controller.saveAll(),
      $scope.person.legalInfo().save(),
    ]);
  };

  $scope.$parent.done = function () {
    if (!$scope.anyApply()) {
      $scope.currentUser.powerOfAttorney(false);
      $scope.currentUser.publicCompanyInsiders(false);
      $scope.currentUser.securitiesIndustryInsiders(false);
      $scope.person.legalInfo().isPepOrHio(false);
      return true;
    } else {
      return $scope.form.$valid && !$scope.person.legalInfo().isPepOrHio();
    }
  };

  $scope.$parent.notDone = function () {
    return $scope.formNotDone('form');
  };

  $scope.$watch('anyApply()', function (newValue, oldValue) {
    if (newValue && !oldValue && $scope.form.$pristine) {
      $scope.currentUser.powerOfAttorney(null);
      $scope.currentUser.publicCompanyInsiders(null);
      $scope.currentUser.securitiesIndustryInsiders(null);
      $scope.person.legalInfo().isPepOrHio(null);
      $scope.currentUser.save();
    }
  });

  $scope.$watchGroup(
    [
      'currentUser.powerOfAttorney()',
      'currentUser.publicCompanyInsiders()',
      'currentUser.securitiesIndustryInsiders()',
      'person.legalInfo().isPepOrHio()',
    ],
    function (newValues) {
      if (_.every(newValues, isFalse)) {
        $scope.anyApply(false);
      } else if (
        newValues.reduce(function (accumulator, currentValue) {
          return accumulator || currentValue;
        })
      ) {
        $scope.anyApply(true);
      }
    }
  );

  $scope.$watch('currentUser.publicCompanyInsiders()', function (setting) {
    if (setting && $scope.legalScope.insiderRoles.controller.getAll().length === 0) {
      $scope.legalScope.insiderRoles.controller.createNew();
    }
  });

  $scope.$on('destroyLastInsiderRecord', function () {
    $scope.currentUser.publicCompanyInsiders(false);
  });

  $scope.$watch('currentUser.hasBrokerageAccounts()', function (setting) {
    if (setting && $scope.legalScope.brokerageAccounts.controller.getAll().length === 0) {
      $scope.legalScope.brokerageAccounts.controller.createNew();
    }
  });

  $scope.$on('destroyLastBrokerageRecord', function () {
    $scope.currentUser.hasBrokerageAccounts(false);
  });

  function isFalse(value) {
    return value === false;
  }

  function initialAnyApplyValue() {
    return !!(
      $scope.currentUser.publicCompanyInsiders() ||
      $scope.currentUser.securitiesIndustryInsiders() ||
      $scope.person.legalInfo().isPepOrHio() ||
      $scope.currentUser.powerOfAttorney()
    );
  }
}
