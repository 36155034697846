'use strict';

import beneficiaryFormRegularComponent from './beneficiary-form-regular.component';

const BeneficiaryFormRegularModule = angular
  .module('component.beneficiary-form-regular', [
    'model.SuccessorHolder',
    'model.AccountBeneficiary',
    'model.Beneficiary',
    'model.AdvisorBeneficiaryForm',
    'service.spouse-or-partner',
    'service.beneficiary-params-generator',
  ])
  .component('beneficiaryFormRegular', beneficiaryFormRegularComponent);

export default BeneficiaryFormRegularModule;
