'use strict';

angular
  .module('controller.advise.client-application', [
    'controller.advise.client-application.setup',
    'controller.advise.client-application.details',
    'controller.advise.client-application.account',
    'controller.advise.client-application.transfer',
    'controller.advise.client-application.transfer-instruction',
    'controller.advise.client-application.beneficiary',
    'controller.advise.client-application.joint-applicant',
    'controller.advise.client-application.review',
    'service.user-notifications',
  ])
  .controller('AdviseClientApplicationCtrl', [
    '$scope',
    '$state',
    '$timeout',
    'newClientFlow',
    'userNotifications',
    adviseClientApplicationCtrl,
  ]);

function adviseClientApplicationCtrl($scope, $state, $timeout, newClientFlow, userNotifications) {
  $scope.newClientFlow = newClientFlow;
  $scope.deleteAction = function (clientFlow) {
    var modalTitle = 'Delete client?';
    var modalBody =
      'Are you sure you want to delete this client and all their associated data and accounts?';
    $scope.$root
      .genericConfirmationModal(modalTitle, modalBody)
      .result.then(function () {
        return clientFlow.delete();
      })
      .then(function () {
        userNotifications.showTransientSuccess('The client has been successfully deleted.');
        $timeout(function () {
          $state.go('advise.overview');
        }, 1200);
      })
      .catch(function () {
        userNotifications.showError(
          'Unable to delete the client. Please try again. If the problem persists, please contact us.'
        );
      });
  };
}
