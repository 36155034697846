'use strict';

angular
  .module('controller.password.advisor-initial-set', ['ram', 'service.passwordChecker'])
  .controller('PasswordAdvisorInitialSetController', [
    'ram',
    '$scope',
    '$state',
    '$http',
    'passwordChecker',
    'oneTimeUserName',
    'oneTimePassword',
    'advisorFirstName',
    PasswordAdvisorInitialSetController,
  ]);

function PasswordAdvisorInitialSetController(
  ram,
  $scope,
  $state,
  $http,
  passwordChecker,
  oneTimeUserName,
  oneTimePassword,
  advisorFirstName
) {
  var password = ($scope.password = new ram.Accessor());
  $scope.passwordConfirmation = new ram.Accessor();
  $scope.advisorFirstName = advisorFirstName;
  $scope.isSaving = false;

  $scope.$watch('password()', function (password) {
    $scope.confirmPattern = passwordChecker.checkPasswordPattern(password);
  });

  $scope.setPassword = function () {
    $scope.isSaving = true;
    $scope.error = undefined;

    return $http
      .put('/api/advisor/set_initial_password.json', {
        oneTimeUserName,
        oneTimePassword,
        password: password(),
      })
      .then(function () {
        $scope.isSaving = false;
        $state.transitionTo(
          'account.signin',
          {
            previousSuccessAction: 'Password change',
          },
          {
            reload: true,
          }
        );
      })
      .catch(function (data) {
        $scope.isSaving = false;

        if (data.data.message) {
          $scope.error = data.data.message;
        } else {
          $scope.error = 'Something went wrong.';
        }
      });
  };
}
