'use strict';

angular
  .module('controller.modal.advisor-modify-document', ['ram'])
  .controller('ModalAdvisorModifyDocumentCtrl', [
    '$scope',
    '$uibModalInstance',
    '$timeout',
    'ram',
    'Document',
    'document',
    'documentTypes',
    'attachToOptions',
    'relatedTo',
    modalAdvisorModifyDocumentCtrl,
  ]);

function modalAdvisorModifyDocumentCtrl(
  $scope,
  $uibModalInstance,
  $timeout,
  ram,
  Document,
  document,
  documentTypes,
  attachToOptions,
  relatedTo
) {
  $scope.document = document;
  $scope.documentTypes = documentTypes.filter(
    (type) => type.matchingFilterTypeId === undefined || type.matchingFilterTypeId === null
  );
  $scope.attachToOptions = attachToOptions;
  $scope.newDocumentType = new ram.Accessor();
  $scope.addDocumentTo = new ram.Accessor();
  $scope.oldDocumentType = $scope.document.typeId();
  $scope.relatedTo = relatedTo;
  $scope.requireRelatedTo = false;
  $scope.signature = {
    add: false,
  };
  $scope.informationMessageForUser = '';
  $scope.infoStyle = 'alert alert-danger';

  var accountDocumentTypeIds = _.chain(documentTypes)
    .filter({
      relatedToType: 'Account',
    })
    .pluck('id')
    .value();

  var bankAccountDocumentTypeIds = _.chain(documentTypes)
    .filter({
      relatedToType: 'Bank Account',
    })
    .pluck('id')
    .value();

  if (!$scope.document.signedAt() && $scope.document.type().signature) {
    $scope.signature.missing = true;
  }

  $scope.$watch('newDocumentType()', function (newDocumentType) {
    if (_.contains(bankAccountDocumentTypeIds, newDocumentType)) {
      $scope.requireRelatedTo = true;
      $scope.attachToOptions = _.filter(attachToOptions, function (option) {
        return option.constructor.name === 'BankAccount';
      });
    } else if (_.contains(accountDocumentTypeIds, newDocumentType)) {
      $scope.requireRelatedTo = true;
      $scope.attachToOptions = _.filter(attachToOptions, function (option) {
        return option.constructor.name === 'Account';
      });
    } else {
      $scope.requireRelatedTo = false;
      $scope.attachToOptions = attachToOptions;
      $scope.addDocumentTo(null);
    }
  });

  $scope.save = function () {
    if ($scope.signature.add && !$scope.document.signedAt()) {
      $scope.document.signedAt(moment().utc().toString());
    }

    if ($scope.newDocumentType() !== undefined) {
      $scope.document.typeId($scope.newDocumentType());
      if (_.contains(bankAccountDocumentTypeIds, $scope.newDocumentType())) {
        $scope.document.relatedToType('BankAccount');
        $scope.document.relatedToId($scope.addDocumentTo());
      } else if (_.contains(accountDocumentTypeIds, $scope.newDocumentType())) {
        $scope.document.relatedToType('Account');
        $scope.document.relatedToId($scope.addDocumentTo());
      }
    }

    $scope.document.save().then(
      function () {
        $scope.informationMessageForUser = 'Success!';
        $scope.infoStyle = 'alert alert-success';
        $timeout($uibModalInstance.close, 500);
      },
      function () {
        $scope.document.typeId($scope.oldDocumentType);
        if ($scope.signature.add) {
          $scope.document.signedAt = $scope.document.signedAt(null);
        }
        $scope.informationMessageForUser = 'Error saving changes!';
      }
    );
  };
}
