'use strict';

angular
  .module('service.invitation-link-creator', [])
  .factory('invitationLinkCreator', ['config', factory]);

function factory(config) {
  return {
    createUrlInfo: function (groupUrlId, multiAdvisorLink, portfolioManagerId) {
      const scheme = config.env.name === 'development' ? 'http' : 'https';
      const commaLinkPart = multiAdvisorLink ? `,${multiAdvisorLink}` : '';
      const portfolioManagerPart = portfolioManagerId ? `?pm=${portfolioManagerId}` : '';
      const host = config.env.host;

      const groupUrlAndLink = `${groupUrlId}${commaLinkPart}`;
      const url = `${scheme}://${host}/adv/${groupUrlAndLink}${portfolioManagerPart}`;
      return {
        groupUrlId,
        groupUrlAndLink,
        portfolioManagerId,
        url,
      };
    },
  };
}
