'use strict';

function passwordDirective() {
  return {
    restrict: 'E',
    scope: {
      passwordModel: '=',
      passwordChange: '&',
      passwordPattern: '=',
      form: '=',
      help: '@',
      focusHelp: '@',
      patternError: '@',
      title: '@',
      label: '@',
      name: '@',
      class: '@',
      placeholder: '@',
      inputClass: '@',
      labelClass: '@',
      helpClass: '@',
    },
    templateUrl: 'directives/input/password.html',
    controller: [
      '$scope',
      function ($scope) {
        $scope.setRightStyleProperty = function () {
          return window.location.pathname === '/signin' ? { right: '10px' } : { right: '20px' };
        };
        const eyeImageBase = 'fa fa-thin fa-eye';
        $scope.passwordFieldType = 'password';
        $scope.eyeImageType = `${eyeImageBase}-slash`;

        $scope.togglePasswordVisibility = function () {
          $scope.passwordFieldType = $scope.passwordFieldType === 'password' ? 'text' : 'password';
          $scope.eyeImageType =
            $scope.eyeImageType === `${eyeImageBase}-slash`
              ? `${eyeImageBase}`
              : `${eyeImageBase}-slash`;
        };
      },
    ],
  };
}

angular
  .module('directive.input.password', ['templates', 'animations', 'directive.focus'])
  .directive('password', [passwordDirective]);
