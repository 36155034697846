'use strict';

function factory() {
  return {
    call: function (intermediateResults, groupByFieldName) {
      var _resultsByDate = _.groupBy(intermediateResults, function (result) {
        var data = result[groupByFieldName];
        if (_.isUndefined(data) || _.isNull(data)) {
          return null;
        }
        if (typeof data() === 'string') {
          return moment(data()).startOf('d').toDate();
        }
        return new Date(data().setHours(0, 0, 0, 0));
      });

      var resultsByDate = _.chain(_resultsByDate)
        .keys()
        .map(function (date) {
          return {
            date: new Date(date),
            data: _resultsByDate[date],
          };
        })
        .sortBy('date')
        .reverse()
        .value();

      return resultsByDate;
    },
  };
}

angular.module('service.group-by-date', []).factory('groupByDateService', [factory]);
