'use strict';

angular
  .module('controller.profile.household.invitations', [
    'service.user-notifications',
    'service.group-by-date',
  ])
  .controller('profileHouseholdInvitationsCtrl', [
    '$scope',
    '$http',
    '$state',
    'userNotifications',
    'groupByDateService',
    'pendingHouseholdInvitations',
    invitationsHouseholdProfileController,
  ]);

function invitationsHouseholdProfileController(
  $scope,
  $http,
  $state,
  userNotifications,
  groupByDateService,
  pendingHouseholdInvitations
) {
  $scope.messages = {
    acceptError: 'Something went wrong when trying to join the household. Please try again.',
    declineError: 'Something went wrong when trying to decline the invitation. Please try again.',
  };

  var modalPresets = {
    acceptInvite: {
      title: 'Accept Invitation',
      body: 'Are you sure you want to join the <b>@householdName@?</b>',
    },
    declineInvite: {
      title: 'Decline Invitation',
      body: 'Are you sure you want to decline the invitation to join the <b>@householdName@</b>?',
    },
  };

  $scope.householdInvitations = pendingHouseholdInvitations;

  function _setHouseholdName(text, householdName) {
    return text.replace('@householdName@', householdName);
  }

  $scope.householdInvitationsByHousehold = _.groupBy(
    $scope.householdInvitations,
    function (householdInvitation) {
      return householdInvitation.householdName();
    }
  );

  $scope.accessText = function (householdInvitation) {
    if (householdInvitation.relationship.is.child()) {
      return (
        householdInvitation.inviterEmail() +
        ' and other household admins will be able to see your ModernAdvisor accounts and documents on their dashboard.'
      );
    } else {
      return (
        householdInvitation.inviterEmail() +
        ' and other household admins will be able to see your ModernAdvisor accounts and documents on their dashboard. ' +
        'You will also see the accounts and documents of everyone in the household on yours.'
      );
    }
  };

  $scope.acceptInvitation = function (householdInvitation) {
    var modalTitle = modalPresets.acceptInvite.title;
    var modalBody = _setHouseholdName(
      modalPresets.acceptInvite.body,
      householdInvitation.householdName()
    );

    $scope.genericConfirmationModal(modalTitle, modalBody, 'btn-success').result.then(function () {
      householdInvitation.accept().then(
        function () {
          $state.go(
            'profile.household',
            {},
            {
              reload: true,
            }
          );
        },
        function () {
          userNotifications.showError($scope.messages.acceptError);
        }
      );
    });
  };

  $scope.declineInvitation = function (householdInvitation) {
    var modalTitle = modalPresets.declineInvite.title;
    var modalBody = _setHouseholdName(
      modalPresets.declineInvite.body,
      householdInvitation.householdName()
    );

    $scope.genericConfirmationModal(modalTitle, modalBody, 'btn-success').result.then(function () {
      householdInvitation.decline().then(
        function () {
          $state.go(
            'profile.household',
            {},
            {
              reload: true,
            }
          );
        },
        function () {
          userNotifications.showError($scope.messages.declineError);
        }
      );
    });
  };
}
