'use strict';

angular
  .module('controller.interview', [
    'controller.interview.account-type',
    'controller.interview.advisor-referral-disclosures',
    'controller.interview.spousal-rrsp',
    'controller.interview.portfolio-preferences',
    'controller.interview.contribution-amount',
    'controller.interview.financial',
    'controller.interview.service-options',
    'ui.bootstrap',
    'ui.router',
    'model.Question',
    'ram',
    'animations',
    'directive.input',
    'ui.slider',
    'directive.ionSlider',
    'directive.interview.navbar',
    'service.adaptive-views',
    'service.check-incomplete-accounts-service',
    'service.modals',
  ])
  .controller('InterviewCtrl', [
    '$cookies',
    '$scope',
    '$state',
    '$stateParams',
    '$rootScope',
    'storedAccount',
    'storedUser',
    'storedGoal',
    'ram',
    '$timeout',
    'modals',
    'checkIncompleteAccountsService',
    'config',
    'advisorReferralDetails',
    interviewController,
  ])
  .controller('InterviewStepCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    'questions',
    InterviewStepController,
  ]);

function interviewController(
  $cookies,
  $scope,
  $state,
  $stateParams,
  $rootScope,
  account,
  user,
  goal,
  ram,
  $timeout,
  modals,
  checkIncompleteAccountsService,
  config,
  advisorReferralDetails
) {
  $rootScope.branding = advisorReferralDetails.branding;

  $scope.account = account;
  $scope.user = user;
  $scope.isOpeningGroupSavingsPlan = function () {
    return !!$cookies.get('group_savings_plan');
  };

  $scope.isAdvisorCreateNewAccountFlow = function () {
    return $scope.account.advisorCreateNewAccountFlowId() !== undefined;
  };

  function newCookieOrCookieIsStale() {
    if (!$scope.user.updatedAt()) {
      // new cookies won't have this
      return true;
    }
    return $scope.currentUser.updatedAt() > $scope.user.updatedAt(); // cookie is stale
  }

  if ($scope.currentUser && newCookieOrCookieIsStale()) {
    // user is already logged in
    var current = _.pick(
      $scope.currentUser.toJSON(),
      'nonFinancialAssets',
      'financialAssets',
      'income',
      'riskAppetite',
      'totalLiabilities',
      'appetite0',
      'appetite1',
      'appetite2',
      'appetite3',
      'appetite4',
      'appetite5',
      'appetite6',
      'appetite7'
    );

    _.each(current, function (value, key) {
      if (value !== null && !_.isUndefined(value)) {
        $scope.user[key](value);
      }
    });
    $scope.user.save(
      {},
      {
        expires: moment().add(30, 'minutes').format(),
      }
    );
  }

  $scope.goal = goal;
  // fix the preview not loading
  goal.advisorRiskOffset(0);

  // Preselections
  if ($scope.isOpeningGroupSavingsPlan()) {
    account.type(
      _.findWhere(config.types.Account, {
        name: 'group_rrsp',
      })
    );
    account.save();
  }

  $scope.$watch('errorMessage', function (newValue) {
    if (newValue) {
      $timeout(function () {
        if ($scope.errorMessage === newValue) {
          $scope.errorMessage = null;
        }
      }, 3000);
    }
  });

  $scope.progress = function () {
    return $state.current.data && $state.current.data.progress;
  };

  $scope.validateCookie = function () {
    if (
      $cookies.getObject('accounts') &&
      $cookies.getObject('goals') &&
      $cookies.getObject('users')
    ) {
      return true;
    }

    if ($state.current.name === 'interview.goal') {
      $scope.account.id = undefined;
      $scope.account.save();
      $scope.goal.id = undefined;
      $scope.goal.save();
      $scope.user.id = undefined;
      $scope.user.save();

      return true;
    }

    $state
      .go(
        'interview.goal',
        {},
        {
          reload: true,
        }
      )
      .then(function () {
        var title = 'Your session has expired!';
        var body =
          'Your session has expired due to inactivity.\n' +
          'You have been redirected to the first step of the account application.';
        $rootScope.errorModal(title, body);
      });

    return false;
  };

  $scope.nextButtonLabel = function () {
    if ($state.is('interview.personal.end')) {
      return 'Return to Home Page';
    } else {
      return 'Next';
    }
  };

  $scope.hideNavbar = function () {
    return $state.current.data.hideNavBar;
  };

  checkIncompleteAccountsService.call($scope, $state);
}

// This seems not used... (?)
function InterviewStepController(/* $scope, $state, questions */) {
  // $scope.questions = _.indexBy(questions, function(question) {
  //   return question.accessor();
  // });
  // $scope.$parent.nextSection = function() {
  //   $state.go($state.current.data.next);
  // };
  // $scope.$parent.lastSection = function() {
  //   $state.go($state.current.data.last);
  // };
  // $scope.$parent.step = $state.current.data.step;
  // $scope.$parent.header = $state.current.data.header;
}
