'use strict';

angular
  .module('controller.advise.transfer-requests.unmatched-transfers', [
    'query.notifications.unmatched-transfer',
    'query.transfers.unmatched',
  ])
  .controller('AdviseTransferRequestsUnmatchedTransfersCtrl', [
    '$scope',
    '$http',
    '$q',
    'NotificationsUnmatchedTransferQuery',
    'TransfersUnmatchedQuery',
    adviseTransferRequestsUnmatchedTransfersController,
  ]);

function adviseTransferRequestsUnmatchedTransfersController(
  $scope,
  $http,
  $q,
  NotificationsUnmatchedTransferQuery,
  TransfersUnmatchedQuery
) {
  var formatTransfersAndNotificationsForView = function () {
    return _.map($scope.unmatchedTransfers.notifications, function (unmatchedTransferNotification) {
      var unmatchedTransfer = _.find(
        $scope.unmatchedTransfers.transfers,
        function (unmatchedTransfer) {
          return unmatchedTransfer.id === unmatchedTransferNotification.relatedToId();
        }
      );

      return {
        transfer: unmatchedTransfer,
        notification: unmatchedTransferNotification,
      };
    });
  };

  $scope.unmatchedTransfersFormatted = formatTransfersAndNotificationsForView();

  var reloadViewData = function () {
    return $q
      .all([NotificationsUnmatchedTransferQuery.run(true), TransfersUnmatchedQuery.run(true)])
      .then(function (results) {
        $scope.unmatchedTransfers.notifications = results[0];
        $scope.unmatchedTransfers.transfers = results[1];
        $scope.unmatchedTransfersFormatted = formatTransfersAndNotificationsForView();
      });
  };

  $scope.clearAllUnmatchedTransfers = function () {
    var modalTitle = 'Clear all unmatched transfer notifications';
    var modalBody = 'Are you sure you want to clear all unmatched transfer notifications?';

    $scope
      .genericConfirmationModal(modalTitle, modalBody)
      .result.then(function () {
        $scope.$root.showLoading();
        return $http.post('api/notifications/destroy_unmatched_transfer_notifications.json');
      })
      .then(function () {
        return reloadViewData();
      })
      .finally(function () {
        $scope.$root.hideLoading();
      });
  };
}
