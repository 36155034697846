import template from './email-client-flow-button.html';
import controller from './email-client-flow-button.controller';

const emailClientFlowButtonComponent = {
  template,
  bindings: {
    clientFlow: '<',
    callback: '=',
  },
  controller: ['$scope', '$timeout', controller],
  controllerAs: '$ctrl',
};

export default emailClientFlowButtonComponent;
