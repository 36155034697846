'use strict';

function paginatorFactory() {
  /**
   * This model holds data used by the UX to do pagination.  The advisor dashboard uses several of these,
   * notably two at the same time when looking at Transfer Requests and Account Transfers.
   *
   */
  var Paginator = function (classToUse, loadDependantObjects, getDataFromPaginator) {
    this.classToUse = classToUse;
    this.loadDependantObjects = loadDependantObjects;
    this.getDataFromPaginator = getDataFromPaginator;
    this.results = null;
  };

  return Paginator;
}

angular.module('model.Paginator', []).factory('Paginator', [paginatorFactory]);
