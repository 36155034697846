'use strict';

angular
  .module('directive.beneficiary-resp', [
    'directive.input',
    'model.Person',
    'model.Beneficiary',
    'service.account-service',
    'service.beneficiary-service',
    'service.save-on-change-handler',
    'service.spouse-or-partner',
  ])
  .directive('beneficiaryResp', [
    '$q',
    'config',
    'Person',
    'Beneficiary',
    'accountService',
    'saveOnChangeHandler',
    'beneficiaryService',
    'spouseOrPartner',
    beneficiaryRespDirective,
  ]);

function beneficiaryRespDirective(
  $q,
  config,
  Person,
  Beneficiary,
  accountService,
  saveOnChangeHandler,
  beneficiaryService,
  spouseOrPartner
) {
  function createOption(label, value) {
    return {
      label,
      value,
    };
  }

  function updateOptions($scope) {
    var options = [createOption($scope.person.fullName(), $scope.person.id)];
    if ($scope.person.hasSpouse() && $scope.spouse) {
      var spouse = $scope.spouse;
      var spouseFullName = spouse ? spouse.fullName() : '';
      options.push(
        createOption(spouseFullName.trim() || 'Your ' + $scope.spouseOrPartner(), $scope.spouse.id)
      );
    }
    options.push(createOption('Other', 'other'));

    $scope.primaryCaregiverOptions = options;
    $scope.legalGuardianOptions = options;
  }

  function beneficiaryRelativeFunction(name) {
    var otherValue = 'other';

    var otherAccessor = function (beneficiary) {
      return beneficiary[name + 'Other'];
    };
    var idAccessor = function (beneficiary) {
      return beneficiary[name + 'Id'];
    };

    var setter = function (beneficiary, value) {
      var other = value === otherValue;
      otherAccessor(beneficiary).call(null, other); // eslint-disable-line no-useless-call
      idAccessor(beneficiary).call(null, other ? null : value); // eslint-disable-line no-useless-call
    };

    var getter = function (beneficiary) {
      return otherAccessor(beneficiary).call(null) // eslint-disable-line no-useless-call
        ? otherValue
        : idAccessor(beneficiary).call(null); // eslint-disable-line no-useless-call
    };

    return function (value) {
      var beneficiary = this.$parent.$parent.beneficiary;
      return arguments.length > 0 ? setter(beneficiary, value) : getter(beneficiary);
    };
  }

  return {
    restrict: 'E',
    scope: {
      account: '=',
      client: '=',
      beneficiaries: '=',
      singleBeneficiary: '=',
      personalSubforms: '=',
      isClientFilling: '=',
      rowClass: '@',
      parentsMutatable: '@',
    },
    replace: true,
    templateUrl: 'directives/beneficiary-resp.html',

    controller: [
      '$scope',
      function ($scope) {
        var parentsMutatable = $scope.parentsMutatable !== 'false';
        $scope.config = config;
        saveOnChangeHandler.initForScope($scope);

        $scope.currentUser = $scope.client; // This may be used in the context of superadvisor is the currentUser
        $scope.person = $scope.client.person();
        $scope.isFamilyResp = accountService.isFamilyResp($scope.account);

        $scope.editingRespBeneficiaries = !$scope.beneficiaries && $scope.singleBeneficiary;
        $scope.allBeneficiaries = $scope.beneficiaries || [$scope.singleBeneficiary];

        $scope.primaryPerson = $scope.person;
        $scope.spouse = $scope.person.spouseRelation() && $scope.person.spouseRelation().relative();
        $scope.respBeneficiaryRelationTypes = Beneficiary.getBeneficiaryRelationTypes(
          'respBeneficiary',
          $scope.account.type().name
        );

        $scope.spouseOrPartner = function () {
          return spouseOrPartner.relationshipName($scope.person);
        };

        $scope.beneficiaryLoading = function () {
          return beneficiaryService.beneficiaryLoading();
        };

        $scope.onPersonChange = function (person) {
          if (!beneficiaryService.inlineSave()) {
            return;
          }
          return person.save();
        };

        $scope.onPersonalRelationChange = function (personalRelation) {
          if (!beneficiaryService.inlineSave()) {
            return;
          }
          return personalRelation.save();
        };

        $scope.onBeneficiaryChange = function (beneficiary) {
          if (!beneficiaryService.inlineSave()) {
            return;
          }
          return beneficiary.save();
        };

        $scope.checkFirstAddressChange = function (address) {
          if (!beneficiaryService.inlineSave()) {
            // Flag the beneficiary as having a new address
            var beneficiary = _.find($scope.allBeneficiaries, function (beneficiary) {
              return beneficiary.personalRelation().relative().id === address.personId();
            });
            if (beneficiary) {
              beneficiary.addressChanged(true);
            }
            return;
          }
          $scope.onAddressChange(address);
        };

        $scope.checkFirstDateOfBirthChange = function (person, date) {
          if (!beneficiaryService.inlineSave()) {
            person.birthDate(date.toDate());
            return;
          }
          return $scope.onDateOfBirthChange(person, date);
        };

        if ($scope.allBeneficiaries.length === 0) {
          beneficiaryService.createRespBeneficiary();
        }

        $scope.$watch('person.fullName()', function () {
          if (parentsMutatable) {
            updateOptions($scope);
          }
        });
        $scope.$watch('spouse.fullName()', function () {
          if (parentsMutatable) {
            updateOptions($scope);
          }
        });
        $scope.$watch('person.maritalStatus()', function () {
          if (parentsMutatable) {
            updateOptions($scope);
          }
        });

        // this is fixing the problem when spouse cannot be removed, because it was also linked
        // as a caregiver or guardian for the beneficiary
        var originalMethod = $scope.person.maritalStatusChange;
        $scope.person.maritalStatusChange = function () {
          var promises = [];
          var newMaritalStatusId = $scope.person.maritalStatusId();
          if (!$scope.person.hasSpouse() && $scope.spouse) {
            promises = _.map($scope.allBeneficiaries, function (beneficiary) {
              if (beneficiary.legalGuardianId() === $scope.spouse.id) {
                beneficiary.legalGuardianId(null);
              }
              if (beneficiary.primaryCaregiverId() === $scope.spouse.id) {
                beneficiary.primaryCaregiverId(null);
              }
              return beneficiary.save();
            });
          }
          return $q.all(promises).then(function () {
            $scope.person.maritalStatusId(newMaritalStatusId);
            return originalMethod.call($scope.person);
          });
        };

        $scope.primaryCaregiver = beneficiaryRelativeFunction('primaryCaregiver');
        $scope.legalGuardian = beneficiaryRelativeFunction('legalGuardian');
        $scope.addBeneficiary = beneficiaryService.addRespBeneficiary;
        $scope.removeBeneficiary = beneficiaryService.removeRespBeneficiary;
        $scope.safeToDeleteFamilyRespBeneficiary =
          beneficiaryService.safeToDeleteFamilyRespBeneficiary();

        updateOptions($scope);

        if ($scope.personalSubforms) {
          $scope.personalSubforms.saveBeneficiary = function () {
            if ($scope.allBeneficiaries) {
              return _.map($scope.allBeneficiaries, function (beneficiary) {
                return $q.all([
                  beneficiary.save(),
                  beneficiary.personalRelation().relative().save(),
                ]);
              });
            }
          };
        }

        $scope.$watch('person.spouseRelation()', function (relation) {
          $scope.spouse = relation && relation.relative();
        });

        $scope.$on('$destroy', function () {
          $scope.person.maritalStatusChange = Person.prototype.maritalStatusChange;
        });
      },
    ],
  };
}
