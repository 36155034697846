'use strict';

angular
  .module('controller.interview.account-credentials', [
    'Devise',
    'model.User',
    'filter.string',
    'service.passwordChecker',
    'angulartics',
    'service.accountCreator',
    'service.google-analytics-wrapper',
    'ngCookies',
    'directive.video-carousel',
  ])
  .controller('AccountCredentialsController', [
    '$scope',
    '$state',
    '$location',
    'User',
    '$analytics',
    'passwordChecker',
    'accountCreator',
    '$cookies',
    'googleAnalyticsWrapper',
    accountCredentialsController,
  ]);

function accountCredentialsController(
  $scope,
  $state,
  $location,
  User,
  $analytics,
  passwordChecker,
  accountCreator,
  $cookies,
  googleAnalyticsWrapper
) {
  var checkWoopra = function () {
    $cookies.remove('woopra_tracker_cookie');
    if (window.woopraTracker && window.woopraTracker.cookie) {
      $cookies.put('woopra_tracker_cookie', window.woopraTracker.cookie);
    }
  };

  $scope.$parent.nextSection = function ($event) {
    $event.preventDefault();
    $scope.register();
  };

  $scope.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = false;

  $scope.user = User.new({
    person: {},
  });

  $scope.errors = '';

  $scope.$watch('user.password()', function (password) {
    $scope.confirmPattern = passwordChecker.checkPasswordPattern(password);
  });

  $scope.register = function () {
    checkWoopra();
    // TODO: Move sending this event to the server
    googleAnalyticsWrapper.sendSignUpEvent($scope.account);
    accountCreator.createUserWithAccount($scope, $scope.user, $scope.account).finally(function () {
      $scope.email = $scope.user.email();
      $analytics.setUsername($scope.user.email());

      // Record analytics for Jelly Marketing campaign
      $analytics.pageTrack('/thank-you-trial', $location);
      /* jshint ignore:start */
      fbq('track', 'CompleteRegistration');
      /* jshint ignore:end */
    });
  };
}
