'use strict';

function accountConfirmEmailController($scope, $stateParams, $state, accountService) {
  var accounts = $scope.currentUser.accounts();
  var trialAccount = accountService.findTrialAccount(accounts);
  var incompleteRegularAccount = accountService.findIncompleteAccount(accounts);

  $scope.showContinueApplicationButton =
    incompleteRegularAccount && (!trialAccount || !trialAccount.status.is.started());

  $scope.showGoToDashboardButton = accountService.hasCompletedAccount(accounts);

  $scope.goToIncomplete = function () {
    $state.go(accountService.getStateForIncompleteAccount(incompleteRegularAccount));
  };

  if ($stateParams.emailConfirmed === 'true' && !$stateParams.error) {
    $scope.success = 'Thank you for confirming your email address.';
  } else if ($stateParams.emailConfirmed === 'true') {
    $scope.success = 'Your email has already been confirmed.';
  } else if ($stateParams.emailConfirmed === 'false') {
    $scope.error = $stateParams.error;
  }
}

angular
  .module('controller.account.confirm-email', ['service.account-service'])
  .controller('AccountConfirmEmailCtrl', [
    '$scope',
    '$stateParams',
    '$state',
    'accountService',
    accountConfirmEmailController,
  ]);
