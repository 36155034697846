'use strict';

angular
  .module('model.SecondaryEmail', ['model.User'])
  .factory('SecondaryEmail', ['ram', 'config', secondaryEmailFactory]);

function secondaryEmailFactory(ram, config) {
  var SecondaryEmail = new ram.Collection('SecondaryEmail', {
    belongsTo: {
      user: 'User',
    },
    resources: {
      cookie: new ram.resources.Cookie('secondary_emails'),
    },
    schema: config.schemas.SecondaryEmail,
  });

  return SecondaryEmail;
}
