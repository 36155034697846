'use strict';

angular
  .module('service.intercom', ['model.User', 'ngCookies'])
  .factory('intercom', ['$cookies', '$rootScope', '$state', 'config', 'User', factory]);

function factory($cookies, $rootScope, $state, config, User) {
  /**
   * Boot Intercom configured for an anonymous user.
   */
  function _bootAnonymous() {
    window.Intercom('boot', window.intercomSettings);
  }

  /**
   * Boot Intercom configured for the current user.
   * @param  {User} currentUser User model for currentUser.
   */
  function _bootCurrentUser(currentUser) {
    window.Intercom('boot', {
      app_id: config.env.intercomAppId,
      user_id: currentUser.id,
      user_hash: currentUser.intercomUserHash(),
      name: currentUser.fullName(),
      email: currentUser.email(),
      created_at: moment(currentUser.createdAt()).unix(),
    });
  }

  /**
   * Check whether Intercom should boot in anonymous or secure mode.
   * @param  {User} currentUser User model for currentUser. If defined and
   *                            isClient is set, boots in secure mode. Otherwise
   *                            boots in anonymous mode.
   */
  function _boot(currentUser) {
    if (currentUser) {
      _bootCurrentUser(currentUser);
    } else {
      _bootAnonymous();
    }
  }

  function _removeCookies() {
    $cookies.remove('intercom-id');
    _.chain($cookies.getAll())
      .filter(function (val, key) {
        return /^intercom-session-/.test(key);
      })
      .each(function (val, key) {
        $cookies.remove(key);
      });
  }

  /**
   * Clear the last Intercom session data and shut down Intercom.
   */
  function shutdown() {
    window.Intercom('shutdown');
    _removeCookies();
  }

  /**
   * Use to boot Intercom when the current User status is unknown.
   */
  function reboot() {
    if (isWsi() && !config.env.intercomAppId) {
      return;
    }
    shutdown();
    User.currentUser().then(_boot);
  }

  /**
   * Use to fetch new messages from Intercom when the user changes views.
   */
  function update() {
    if ($state.includes('iframe-widgets')) {
      return;
    }

    // window.Intercom.booted returns true even if Intercom is shut down. It
    // returns true depending on if Intercom exists and not if it is on or off.
    if (window.Intercom.booted) {
      // Update will open the Intercom bubble even if Intercom was shut down.
      window.Intercom('update');
    }
  }

  $rootScope.$on('devise:login', reboot);
  $rootScope.$on('devise:logout', _bootAnonymous);
  $rootScope.$on('$stateChangeSuccess', update);

  function isWsi() {
    const platform = config.env.platform;
    return platform && platform.name === 'WSI';
  }

  return {
    reboot,
    shutdown,
    update,
  };
}
