import React, { useState } from 'react';
import useFilePicker from '../../hooks/useFilePicker';
import HelpTooltip from './HelpTooltip';

function FileUpload({
  buttonText,
  tooltipText,
  acceptList,
  api,
  csrfToken,
  onload,
  onerror,
  resetUxCallback,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { filePicker, filePicked, fileName, fileStatus } = useFilePicker(
    csrfToken,
    api,
    onload,
    onerror,
    setIsProcessing,
    resetUxCallback
  );

  return (
    <>
      <dl className="dl-horizontal">
        <dt className="p-tb-5 dt-center-align w-150px">
          File <HelpTooltip text={tooltipText} />:
        </dt>
        <dd className="p-tb-5">
          <button
            type="button"
            className="btn btn-white h-30px w-200px border-grey m-t-neg5 p-t-4"
            onClick={() => filePicker.current.click()}
          >
            {buttonText}
          </button>
          <input
            type="file"
            ref={filePicker}
            className="disp-n-imp"
            name="image"
            id="select-file"
            accept={acceptList}
            onChange={filePicked}
          />
        </dd>
        <div className="clearfix" />
        <dl>
          <dt className="p-tb-5 dt-center-align w-150px">File Name:</dt>
          <dd className="p-tb-5">{fileName}</dd>
          <dt className="p-tb-5 dt-center-align w-150px">File Status:</dt>
          <dd className="p-tb-5">
            {!isProcessing && <span>{fileStatus}</span>}
            {isProcessing && (
              <span>
                <img className="btn-icon" src="assets/images/loading-spinner.svg" /> Processing
              </span>
            )}
          </dd>
        </dl>
      </dl>
    </>
  );
}

export default FileUpload;
