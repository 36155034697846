'use strict';

function ssnDirective(ram) {
  return {
    controller: [
      '$scope',
      'patterns',
      function ($scope, patterns) {
        $scope.patterns = patterns;
      },
    ],
    restrict: 'E',
    scope: {
      form: '=',
      ngChange: '&onChange',
      taxStatus: '=',
      optional: '=',
    },
    templateUrl: 'directives/input/ssn.html',
    link: function (scope) {
      angular.extend(scope, {
        ssnFirstPart: new ram.Accessor(),
        ssnSecondPart: new ram.Accessor(),
        ssnThirdPart: new ram.Accessor(),
      });

      var inputNames = ['ssnFirstPart', 'ssnSecondPart', 'ssnThirdPart'].map(function (name) {
        return name + scope.taxStatus.personId();
      });

      scope.ssnFirstPartError = function () {
        return field(0).$dirty && field(0).$invalid;
      };
      scope.ssnSecondPartError = function () {
        return field(1).$dirty && field(1).$invalid;
      };
      scope.ssnThirdPartError = function () {
        return field(2).$dirty && field(2).$invalid;
      };
      scope.ssnError = function () {
        return scope.ssnFirstPartError() || scope.ssnSecondPartError() || scope.ssnThirdPartError();
      };

      function field(partAsInteger) {
        return scope.form[inputNames[partAsInteger]];
      }

      scope.getInputFor = function (partAsInteger) {
        return 'input[name=' + inputNames[partAsInteger] + ']';
      };

      scope.$watch('taxStatus.taxIdentificationNumber()', function (ssn) {
        if (ssn) {
          scope.ssnFirstPart(ssn.substring(0, 3));
          scope.ssnSecondPart(ssn.substring(3, 5));
          scope.ssnThirdPart(ssn.substring(5, 10));
        }
      });

      scope.$watchGroup(
        ['ssnFirstPart()', 'ssnSecondPart()', 'ssnThirdPart()'],
        function (newValues) {
          if (!_.contains(newValues, undefined)) {
            scope.taxStatus.taxIdentificationNumber(newValues[0] + newValues[1] + newValues[2]);
            scope.ngChange();
          }
        }
      );
    },
  };
}

angular.module('directive.input.ssn', ['service.patterns']).directive('ssn', ['ram', ssnDirective]);
