'use strict';

angular
  .module('controller.dashboard.documents', [
    'service.statements',
    'directive.mobile-list-cards',
    'service.group-by-date',
    'service.timeframeDisplayHelper',
    'ram',
  ])
  .controller('DashboardDocumentsCtrl', [
    '$scope',
    'ram',
    'statements',
    'taxDocuments',
    'accountOpeningDocuments',
    'groupByDateService',
    'timeframeDisplayHelper',
    documentsController,
  ]);

function documentsController(
  $scope,
  ram,
  statements,
  taxDocuments,
  accountOpeningDocuments,
  groupByDateService,
  timeframeDisplayHelper
) {
  $scope.statementTypes = [
    {
      id: 1,
      label: 'All Statements',
      name: null,
    },
    {
      id: 2,
      label: 'Quarterly Performance',
      name: 'qtr_statement',
      // Future can add things like this
    },
    {
      id: 3,
      label: 'Annual Statements',
      name: 'annual_statement',
    },
  ];

  $scope.periods = timeframeDisplayHelper.getPeriods();

  $scope.statementType = new ram.Accessor($scope.statementTypes[0]);
  $scope.period = new ram.Accessor($scope.periods[3]);

  var visibleAccountIds = $scope.accountMenu.map(function (account) {
    return account.id;
  });

  function filterByAccount(documents) {
    return documents.filter(function (document) {
      return !document.account || _.contains(visibleAccountIds, document.account.id);
    });
  }

  $scope.documents = filterByAccount(accountOpeningDocuments);
  $scope.taxDocuments = filterByAccount(taxDocuments);

  $scope.statements = statements; // statements will be filtered based on a combination of filters in a watch group below.
  $scope.statementsByDate = [];

  var _getQueriedAccountIds = function (query) {
    var queriedAccountIds = [];
    if (query.accountId === '-' || query.accountId === 'h') {
      _.each($scope.account.visibleAccounts(), function (account) {
        queriedAccountIds.push(account.id);
      });
    } else {
      queriedAccountIds.push(query.accountId);
    }
    return queriedAccountIds;
  };

  var _getSubsetOfStatements = function (collection, query) {
    var queriedAccountIds = _getQueriedAccountIds(query);
    return _.chain(collection)
      .filter(function (statement) {
        // Check against queried accounts and see if there are statements related to
        var result = _.contains(queriedAccountIds, statement.relatedToId());

        // Now AND the result with any specific document type the user is looking for
        result = result && (!query.type || statement.type().name === query.type.name);

        // Use combined result.
        return result;
      })
      .value();
  };

  $scope.$watchGroup(['account', 'statementType()', 'period()'], function (group) {
    var query = {};

    if (group[0].id !== 0) {
      query.accountId = group[0].id;
    }

    if ($scope.statementType().name !== null) {
      query.type = $scope.statementType();
    }

    var result = _getSubsetOfStatements(statements, query);
    $scope.statements = _.chain(result)
      .filter(function (statement) {
        return group[2].start === null || moment(statement.createdAt()).isAfter(group[2].start);
      })
      .sortBy(function (statement) {
        return statement.createdAt();
      })
      .value()
      .reverse();
    $scope.statementsByDate = groupByDateService.call($scope.statements, 'createdAt');
  });

  $scope.isDocumentVisible = function (document) {
    return (
      $scope.account.isCombinedAccount() ||
      !document.account ||
      $scope.account.id === document.account.id
    );
  };

  $scope.accountById = function (id) {
    if ($scope.currentUser) {
      return _($scope.currentUser.viewableAccounts()).findWhere({
        id,
      });
    }
  };

  $scope.documentDescription = function (document) {
    // For contribution receipts we want to show the time period for example:
    // Contribution Receipt (Jan - Feb 2022) or Contribution Receipt (Mar - Dec 2021)
    // When processing contribution receipts the time period is stored in the description of the document.
    // Prior to 2023-01-01 there were some bugs that lead to the incorrect values being stored in the description.
    // Until the old documents descriptions are updated the old descriptions will not be shown on the UI but we will
    // show the description for documents going forward.
    if (
      document.type().label === 'Contribution Receipt' &&
      document.description() &&
      document.createdAt() > '2023-01-01'
    ) {
      return `${document.type().label} (${document.description()})`;
    }
    return document.type().label;
  };
}
