/**
 * This controller is used solely for calculating the xirrs for all funded, non-trial accounts
 * for the year 2016.
 */

'use strict';

angular
  .module('controller.advise.xirr', ['service.xirr'])
  .controller('AdviseXirrCtrl', ['$scope', '$http', 'xirr', 'nonTrialAccs', xirrCtrl]);

function xirrCtrl($scope, $http, xirr, nonTrialAccs) {
  var endOfYear = moment('2016-12-31').endOf('day');
  var startOfYear = moment('2016-01-01').startOf('day');
  var xirrs = [];
  var accsWithErrors = [];

  nonTrialAccs.forEach(buildXirrObject);
  renderAsCsv(xirrs, 'xirr-csv');
  renderAsCsv(accsWithErrors, 'accs-with-errors');

  function renderAsCsv(arr, csvName) {
    arr.forEach(function (xirrObject) {
      var errorField = csvName === 'xirr-csv' ? '<br>' : ', "' + xirrObject.errors + '"<br>';
      angular
        .element('#' + csvName)
        .append(
          xirrObject.accountId +
            ', ' +
            xirrObject.accountNum +
            ', ' +
            moment(xirrObject.start).format('YYYY-MM-DD') +
            ', ' +
            moment(xirrObject.end).format('YYYY-MM-DD') +
            ', ' +
            xirrObject.xirr +
            ', ' +
            xirrObject.xirrAnnualized +
            errorField
        );
    });
  }

  function buildXirrObject(acc) {
    var start = firstNonZeroBalanceDate(acc);
    if (!start) {
      // This means the account was not funded during the year.
      return;
    }
    var end = lastNonZeroBalanceDate(acc);
    var errors = [];

    function getXirr() {
      try {
        return xirr(acc.cashFlows(start, end), {
          annualized: false,
        });
      } catch (error) {
        errors.push(error);
        return 'error';
      }
    }

    function getXirrAn() {
      try {
        return xirr(acc.cashFlows(start, end)); // default is annualized
      } catch (error) {
        errors.push(error);
        return 'error';
      }
    }

    var xirrObject = {
      accountId: acc.id,
      accountNum: acc.number(),
      start,
      end,
      xirr: getXirr(),
      xirrAnnualized: getXirrAn(),
      errors,
    };

    if (errors.length > 0) {
      accsWithErrors.push(xirrObject);
    } else {
      xirrs.push(xirrObject);
    }
  }

  function firstNonZeroBalanceDate(acc) {
    var firstNonZeroBalance = _.find(acc.balancesHistory(), function (balance) {
      return balance.amount > 0 && in2016(balance);
    });
    return firstNonZeroBalance ? firstNonZeroBalance.date : undefined;
  }

  function lastNonZeroBalanceDate(acc) {
    var balancesHistory = acc.balancesHistory();
    var reverseBalancesHistory = balancesHistory.reverse();
    var lastNonZeroBalance = _.find(reverseBalancesHistory, function (balance) {
      return balance.amount > 0 && in2016(balance);
    });
    return lastNonZeroBalance ? lastNonZeroBalance.date : undefined;
  }

  function in2016(balance) {
    return balance.date >= startOfYear && balance.date <= endOfYear;
  }
}
