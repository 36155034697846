'use strict';

angular
  .module('controller.wsi-interview.account-type', ['service.account-service'])
  .controller('WsiInterviewAccountTypeCtrl', [
    '$scope',
    '$state',
    '$cookies',
    'config',
    'ram',
    'accountService',
    'recommendations',
    controller,
  ]);

function controller($scope, $state, $cookies, config, ram, accountService, recommendations) {
  var convertAccountTypeNamesToObjects = function (accountTypeNames) {
    return _.map(accountTypeNames, function (accountTypeName) {
      return _.findWhere(config.types.Account, {
        name: accountTypeName,
      });
    });
  };

  $scope.jointApplicantEmail = new ram.Accessor($cookies.get('joint_applicant_email'));

  $scope.recommendedAccountTypes = convertAccountTypeNamesToObjects(recommendations.recommended);
  $scope.popularAccountTypes = convertAccountTypeNamesToObjects(recommendations.popular);
  $scope.otherAccountTypes = convertAccountTypeNamesToObjects(recommendations.other);

  if (
    ($cookies.get('show_more_account_types') &&
      $cookies.get('show_more_account_types') === 'true') ||
    ($scope.account.type() && _.contains(recommendations.other, $scope.account.type().name))
  ) {
    $scope.showOtherAccountTypes = true;
  } else {
    $scope.showOtherAccountTypes = false;
  }

  if (
    !$cookies.get('group_url_id') &&
    $scope.otherAccountTypes.findIndex((obj) => obj.name === 'rdsp') !== -1
  ) {
    $scope.otherAccountTypes.splice(
      $scope.otherAccountTypes.findIndex((obj) => obj.name === 'rdsp'),
      1
    );
  }

  $scope.setShowOtherAccountTypesToTrue = function () {
    $cookies.put('show_more_account_types', true);
    $scope.showOtherAccountTypes = true;
  };

  $scope.mobileToggleShowOtherAccountTypes = function () {
    if ($scope.showOtherAccountTypes) {
      $cookies.put('show_more_account_types', false);
      $scope.showOtherAccountTypes = false;
    } else {
      $cookies.put('show_more_account_types', true);
      $scope.showOtherAccountTypes = true;
    }
  };

  // Unmaintained!!!
  $scope.$parent.nextSection = function () {
    if ($scope.account.type.is.jointWithRightOfSurvivorship()) {
      $cookies.put('joint_applicant_email', $scope.jointApplicantEmail.get());
    } else {
      $cookies.remove('joint_applicant_email');
    }
    if ($scope.account.type.is.spousalRrsp()) {
      $state.go($state.current.data.next);
    } else {
      $state.go('wsi-interview.financial');
    }
  };
  $scope.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;
}
