'use strict';

angular
  .module('model.RiskAppetiteQuiz', ['model.RiskAppetiteQuizQuestion'])
  .factory('RiskAppetiteQuiz', ['ram', riskAppetiteQuizFactory]);

function riskAppetiteQuizFactory(ram) {
  /**
   * Constructor for resource-aware RiskAppetiteQuiz model.
   * @class
   */
  var RiskAppetiteQuiz = new ram.Collection('RiskAppetiteQuiz', {
    hasMany: {
      riskAppetiteQuizQuestions: 'RiskAppetiteQuizQuestion',
    },
    resources: {
      default: new ram.resources.Http('/api/risk_appetite_quizzes/:id.json'),
      cookie: new ram.resources.Cookie('risk_appetite_quizzes'),
    },
  });

  RiskAppetiteQuiz.active = function () {
    return RiskAppetiteQuiz.where({}).then(function (quizzes) {
      return quizzes[0];
    });
  };

  return RiskAppetiteQuiz;
}
