'use strict';

angular
  .module('component.trusted-contact-tooltip', [])
  .constant('trustedContactText', {
    client:
      'A Trusted Contact Person (TCP) may be a close friend, family member, or caregiver that can be trusted to ensure your interests come first. This protects you, by authorizing us to contact, discuss concerns and disclose certain personal information about you to the TCP, if we suspect you are experiencing financial exploitation or mistreatment, or have concerns about your ability to make financial decisions. A TCP is not the same as a power of attorney; a TCP does not have authority to make financial decisions for you.',
    advisor:
      "A Trusted Contact Person (TCP) may be a close friend, family member, or caregiver that can be trusted to ensure your client's interests come first. This protects your client, by authorizing us to contact, discuss concerns and disclose certain personal information about them to the TCP, if we suspect they are experiencing financial exploitation or mistreatment, or have concerns about their ability to make financial decisions. A TCP is not the same as a power of attorney; a TCP does not have authority to make financial decisions for your client.",
  })
  .component('trustedContactTooltip', {
    template: `
    <i ng-if="clientIsInPerson"
       class="fa fa-question-circle"
       title="{{clientText}}"
       data-toggle="tooltip"
       data-placement="top">
    </i>
    <i ng-if="!clientIsInPerson"
       class="fa fa-question-circle"
       title="{{advisorText}}"
       data-toggle="tooltip"
       data-placement="top">
    </i>
    `,
    bindings: {
      client: '<',
    },
    controller: ['$scope', 'trustedContactText', trustedContactTooltipController],
  });

function trustedContactTooltipController($scope, trustedContactText) {
  const currentUser = $scope.$root.currentUser;

  $scope.clientText = trustedContactText.client;
  $scope.advisorText = trustedContactText.advisor;
  $scope.clientIsInPerson = $scope.$ctrl.client === currentUser.person(); // One needs $scope.$ctrl to access the controller's bindings probably because the controller isn't a class.
}
