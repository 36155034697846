'use strict';

function factory() {
  return {
    relationshipName: function (person, options = {}) {
      if (person.maritalStatus.is.commonLaw()) {
        return options.capitalize ? 'Partner' : 'partner';
      }
      return options.capitalize ? 'Spouse' : 'spouse';
    },
  };
}

angular.module('service.spouse-or-partner', []).factory('spouseOrPartner', [factory]);
