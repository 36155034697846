'use strict';

angular
  .module('model.PaginatedTransferRequest', [
    'ram',
    'config',
    'model.PaginationMeta',
    'model.TransferRequest',
  ])
  .factory('PaginatedTransferRequest', ['ram', 'config', paginatedTransferRequestFactory]);

function paginatedTransferRequestFactory(ram, config) {
  /**
   * Constructor for PaginatedTransferRequest model.
   * @class
   */
  var PaginatedTransferRequest = new ram.Collection('PaginatedTransferRequest', {
    schema: config.schemas.PaginatedTransferRequest,
    hasOne: {
      paginationMeta: 'PaginationMeta',
    },
    hasMany: {
      transferRequests: 'TransferRequest',
    },
    resources: {
      default: new ram.resources.Http('/api/paginated_transfer_requests/:id.json'),
      cookie: new ram.resources.Cookie('paginated_transfer_requests'),
    },
  });

  return PaginatedTransferRequest;
}
