'use strict';

function controller($scope, $http, ram, $timeout) {
  $scope.email = new ram.Accessor();

  $scope.sendPasswordResetEmail = function () {
    $http
      .get('api/users/generate_new_password_email', {
        params: {
          email: $scope.email(),
        },
      })
      .then(function () {
        $scope.success = '';
        $timeout(function () {
          $scope.success = 'Password reset instructions sent. Please check your inbox.';
        }, 1000);
      });
  };
}

angular
  .module('controller.password.recover', ['ram', 'service.user-notifications'])
  .controller('PasswordRecoverController', ['$scope', '$http', 'ram', '$timeout', controller]);
