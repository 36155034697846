'use strict';

// Adapted from: https://github.com/coatue-oss/react2angular/blob/master/LICENSE.md

// See also NgComponent:  https://github.com/coatue-oss/ngcomponent/blob/master/index.ts

import NgComponent from 'ngcomponent';
// eslint-disable-next-line no-unused-vars
import React from 'react'; // jshint ignore:line
import {
  render, // jshint ignore:line
  unmountComponentAtNode,
} from 'react-dom';

/**
 * Wraps a React component in Angular. Returns a new Angular component.
 */
export default function maReact2Angular(Class, bindingNames = {}, injectNames = []) {
  return {
    bindings: bindingNames,
    controller: [
      '$element',
      ...injectNames,
      class extends NgComponent {
        static get $$ngIsClass() {
          return true;
        }

        constructor($element, ...injectedProps) {
          super();
          this.isDestroyed = false;
          this.$element = $element;
          this.injectedProps = {};
          injectNames.forEach((name, i) => {
            this.injectedProps[name] = injectedProps[i];
          });
        }

        /* jshint ignore: start */
        render() {
          if (!this.isDestroyed) {
            render(<Class {...this.props} {...this.injectedProps} />, this.$element[0]);
          }
        }
        /* jshint ignore: end */

        componentWillUnmount() {
          this.isDestroyed = true;
          unmountComponentAtNode(this.$element[0]);
        }
      },
    ],
  };
}
