'use strict';

function controller(
  $scope,
  $state,
  $http,
  ram,
  passwordChecker,
  twoFactorAuthenticator,
  loadingIndicator
) {
  var token = $state.params.token;

  $scope.states = {
    getNewPassword: 1,
    getSixDigitCode: 2,
  };
  $scope.state = 1;

  $scope.newPassword = new ram.Accessor();
  $scope.confirmNewPassword = new ram.Accessor();
  $scope.code = new ram.Accessor();

  $scope.$watch('newPassword()', function (password) {
    $scope.confirmPattern = passwordChecker.checkPasswordPattern(password);
  });

  function resetSuccess() {
    $state.go('account.signin', {
      previousSuccessAction: 'Password reset',
    });
  }

  function getErrorMessage(errors) {
    var firstErrorKey = _.keys(errors)[0];
    var firstErrorValue = errors[firstErrorKey];

    if (firstErrorKey === 'resetPasswordToken') {
      return 'Your reset password token ' + firstErrorValue;
    } else if (firstErrorKey === 'passwordConfirmation') {
      return 'Password confirmation ' + firstErrorValue;
    }

    return firstErrorValue;
  }

  function resetError(error) {
    if (error.data.error && error.data.error === 'Need two factor authentication code') {
      loadingIndicator.show();
      return twoFactorAuthenticator
        .getSanitizedDetailsForPasswordReset(token)
        .then(function (response) {
          $scope.phoneNumber = response.data.phoneNumber;
          $scope.contactMethod = response.data.contactMethod;
          $scope.otherContactMethod = response.data.contactMethod === 'text' ? 'call' : 'text';
          twoFactorAuthenticator.sendCodeForPasswordReset(token);
          $scope.state = $scope.states.getSixDigitCode;
        })
        .catch(function () {
          $scope.feedbackMessage.error = {
            default: true,
          };
        })
        .finally(function () {
          loadingIndicator.hide();
        });
    } else {
      $scope.feedbackMessage.error = {
        server: getErrorMessage(error.data),
      };
    }
  }

  $scope.resetPassword = function () {
    $scope.feedbackMessage = {};

    $http
      .put('api/users/reset_password', {
        resetPasswordToken: token,
        password: $scope.newPassword(),
        passwordConfirmation: $scope.confirmNewPassword(),
      })
      .then(resetSuccess, resetError);
  };

  $scope.resetPasswordWith2fa = function () {
    $scope.feedbackMessage = {};

    $http
      .put('api/users/reset_password', {
        resetPasswordToken: token,
        password: $scope.newPassword(),
        passwordConfirmation: $scope.confirmNewPassword(),
        otpAttempt: $scope.code(),
      })
      .then(resetSuccess)
      .catch(function (error) {
        $scope.feedbackMessage.error = {
          server: getErrorMessage(error.data),
        };
      });
  };

  $scope.send2faCodeVia = function (method) {
    $scope.feedbackMessage = {};
    twoFactorAuthenticator.sendCodeForPasswordReset(token, method).then(function (result) {
      $scope.feedbackMessage = result;
    });
  };
}

angular
  .module('controller.password.reset', [
    'ram',
    'service.passwordChecker',
    'service.user-notifications',
    'service.two-factor-authenticator',
    'service.loading-indicator',
  ])
  .controller('PasswordResetController', [
    '$scope',
    '$state',
    '$http',
    'ram',
    'passwordChecker',
    'twoFactorAuthenticator',
    'loadingIndicator',
    controller,
  ]);
