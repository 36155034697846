'use strict';

angular
  .module('controller.advisor-new-client.add-password', [
    'ram',
    'service.form-helper',
    'service.passwordChecker',
    'service.user-notifications',
  ])
  .controller('AdvisorNewClientAddPasswordController', [
    '$scope',
    '$state',
    'formHelper',
    'passwordChecker',
    'ram',
    'userNotifications',
    'details',
    'accountFlow',
    advisorNewClientAddPasswordController,
  ]);

function advisorNewClientAddPasswordController(
  $scope,
  $state,
  formHelper,
  passwordChecker,
  ram,
  userNotifications,
  details,
  accountFlow
) {
  $scope.$root.branding = details.branding;

  $scope.newPassword = new ram.Accessor();
  $scope.confirmNewPassword = new ram.Accessor();

  $scope.$watch('newPassword()', function (password) {
    $scope.confirmPattern = passwordChecker.checkPasswordPattern(password);
  });

  $scope.submit = function (formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $scope.isSaving = true;

      $scope.currentUser
        .setPassword($scope.newPassword())
        .then(function () {
          // Need to reload person to update the status of hasCompletedClientFlowWithPendingAccountFlows
          $scope.currentUser.person().reload();
        })
        .then(function () {
          $state.go('advisor-new-account.view', {
            advisorNewAccountFlowId: accountFlow.id,
          });
        })
        .catch(function () {
          userNotifications.showError(
            'Something went wrong while setting your password. Please try again later. Should the problem persist, contact us.'
          );
        })
        .finally(function () {
          $scope.isSaving = false;
        });
    }
  };
}
