'use strict';

angular
  .module('model.TrialRiskAppetite', ['model.Person', 'config'])
  .factory('TrialRiskAppetite', ['ram', 'config', trialRiskAppetiteFactory]);

function trialRiskAppetiteFactory(ram, config) {
  var TrialRiskAppetite = new ram.Collection('TrialRiskAppetite', {
    belongsTo: {
      person: 'Person',
    },
    enums: {
      riskAppetiteType: config.types.RiskAppetiteType,
    },
    resources: {
      default: new ram.resources.Http('/api/trial_risk_appetites/:id.json'),
      cookie: new ram.resources.Cookie('trial_risk_appetites'),
    },
  });

  return TrialRiskAppetite;
}
