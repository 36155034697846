'use strict';

angular
  .module('controller.wsi-interview.financial', ['model.Question', 'ram'])
  .controller('WsiInterviewFinancialController', ['$scope', '$state', 'questions', controller]);

function controller($scope, $state, questions) {
  $scope.questions = _.indexBy(questions, function (question) {
    return question.accessor();
  });
  $scope.$parent.nextSection = function () {
    $state.go($state.current.data.next);
  };
  $scope.$parent.lastSection = function () {
    if ($scope.account.type.is.spousalRrsp()) {
      $state.go($state.current.data.last);
    } else {
      $state.go('wsi-interview.account-type');
    }
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;

  $scope.saveFinancialData = function () {
    $scope.user.updatedAt(moment.utc().toDate());
    $scope.user.save();
  };
}
