'use strict';

// TODO: This compenent could be simplified into a generic processing request
// button component when needed.

class EmailClientFlowButtonController {
  constructor($scope, $timeout) {
    this.$scope = $scope;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.states = Object.freeze({
      default: 'default',
      processingRequest: 'processing-request',
      success: 'success',
      error: 'error',
    });

    this.currentState = this.states.default;
  }

  sendOrResendIcon() {
    return this.clientFlow.status() === 'created' ? 'send' : 'resend';
  }

  emailClientFlow() {
    this.currentState = this.states.processingRequest;

    this.clientFlow
      .sendToClient()
      .then(() => {
        this.currentState = this.states.success;

        // This is to help change from the 'send' state to the 'resend' state
        return this.clientFlow.fetch(true);
      })
      .then((clientFlow) => this.callback && this.callback(clientFlow))
      .catch(() => {
        this.currentState = this.states.error;
      })
      .finally(() => {
        this.$timeout(() => {
          this.currentState = this.states.default;
        }, 1500);
      });
  }
}

export default EmailClientFlowButtonController;
