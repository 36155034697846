'use strict';

angular
  .module('routes.kyc', [
    'controller.kyc',
    'controller.minimal-footer',
    'service.address-service',
    'model.BankAccount',
    'model.KycUpdate',
    'templates',
    'ui.router',
    'service.navigation',
    'service.adaptive-views',
  ])
  .config(['$stateProvider', 'adaptiveViewsProvider', configRoutes]);

/**
 * Initialize angular UI router for user kyc.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider) {
  function authorize(authorizer) {
    return authorizer('Client');
  }

  function person(currentUser) {
    return currentUser.person.ready();
  }

  function spousalRelation(person) {
    return person.spouseRelation.ready();
  }

  function resolveAssociation(association) {
    return function (model) {
      return model[association].ready();
    };
  }

  function resolveKycUpdate(currentUser, KycUpdate) {
    return (
      currentUser &&
      KycUpdate.find({
        userId: currentUser.id,
        completed: false,
      })
    );
  }

  function resolveAccounts(currentUser) {
    return currentUser.accounts.ready().then(function (accounts) {
      return _.filter(accounts, function (account) {
        return !!account.number() && !account.type.is.trial();
      });
    });
  }

  function resolveSuccessorHolders($q, accounts) {
    var successorHolderPromises = _.map(accounts, function (account) {
      return account.successorHolder.ready();
    });

    return $q.all(successorHolderPromises);
  }

  function redirectIfNoKycUpdatePending(redirect, kycUpdate) {
    if (!kycUpdate) {
      return redirect.to('dashboard.summary').always();
    }
  }

  function resolveProfile(Profile) {
    return Profile.where({}).then(function (profiles) {
      return profiles[0];
    });
  }

  $stateProvider
    .state('kyc', {
      url: '/kyc',
      parent: 'site',
      resolve: {
        authorize: ['authorizer', authorize],
        person: ['currentUser', 'authorize', person],
        spousalRelation: ['person', spousalRelation],

        addressService: 'addressService',
        homeAddress: [
          'person',
          'addressService',
          function (person, addressService) {
            return addressService.resolveHomeAddress(person);
          },
        ],
        mailingAddress: [
          'person',
          'homeAddress',
          'addressService',
          function (person, homeAddress, addressService) {
            return addressService.resolveMailingAddress(person, homeAddress);
          },
        ],
        legalInfo: ['person', resolveAssociation('legalInfo')],
        brokerageAccounts: ['currentUser', resolveAssociation('brokerageAccounts')],
        insiderRoles: ['person', resolveAssociation('insiderRoles')],
        accounts: ['currentUser', resolveAccounts],
        successorHolders: ['$q', 'accounts', resolveSuccessorHolders],
        kycUpdate: ['currentUser', 'KycUpdate', resolveKycUpdate],

        account: function () {
          return undefined;
        }, // Needed for navbar
        advisorProfile: ['Profile', resolveProfile], // Needed for navbar

        route: ['redirect', 'kycUpdate', redirectIfNoKycUpdatePending],
      },
      views: {
        main: {
          controller: 'KycCtrl',
        },
        navbar: {
          controller: 'DashboardNavbarCtrl',
          templateUrl: adaptiveViewsProvider.getTemplateUrlForSrc('navbar/dashboard.html', true),
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
    })
    .state('kyc-completed', {
      parent: 'site',
      resolve: {
        account: function () {
          return undefined;
        }, // Needed for navbar
        advisorProfile: ['Profile', resolveProfile], // Needed for navbar
      },
      views: {
        main: {},
        navbar: {
          controller: 'DashboardNavbarCtrl',
          templateUrl: adaptiveViewsProvider.getTemplateUrlForSrc('navbar/dashboard.html', true),
        },
        footer: {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
    });
}
