'use strict';

function riskLevelSelectorMethod() {
  return {
    restrict: 'E',
    scope: {
      user: '@',
      autoSave: '@',
      goal: '=',
    },
    templateUrl: 'directives/risk-level-selector.html',
    controller: [
      '$scope',
      function ($scope) {
        var userIsClient = $scope.user !== 'advisor';
        var autoSave = $scope.autoSave === 'true';
        $scope.goal.advisorRiskOffset($scope.goal.advisorRiskOffset() || 0);

        $scope.upDisabled = function () {
          return $scope.goal.risk() >= 10 || (userIsClient && $scope.goal.riskOffset() === 1);
        };

        $scope.upClick = function () {
          if (userIsClient) {
            $scope.goal.incrementClientRiskOffset();
          } else {
            $scope.goal.setAdvisorRiskOffset($scope.goal.advisorRiskOffset() + 1);
          }
          if (autoSave) {
            $scope.goal.save();
          }
        };

        $scope.downDisabled = function () {
          return $scope.goal.risk() <= 1 || (userIsClient && $scope.goal.riskOffset() === -1);
        };

        $scope.downClick = function () {
          if (userIsClient) {
            $scope.goal.decrementClientRiskOffset();
          } else {
            $scope.goal.setAdvisorRiskOffset($scope.goal.advisorRiskOffset() - 1);
          }
          if (autoSave) {
            $scope.goal.save();
          }
        };
      },
    ],
  };
}

angular
  .module('directive.risklevelselector', [])
  .directive('riskLevelSelector', [riskLevelSelectorMethod]);
