'use strict';

angular
  .module('service.beneficiary-service', [
    'model.Person',
    'form-helper-account-beneficiary',
    'form-helper-resp-beneficiary',
    'form-helper-successor-holder',
    'service.spouse-or-partner',
    'service.pending-changes-service',
  ])
  .factory('beneficiaryService', [
    '$q',
    '$http',
    'Person',
    'formHelperAccountBeneficiary',
    'formHelperRespBeneficiary',
    'formHelperSuccessorHolder',
    'spouseOrPartner',
    'pendingChangesService',
    factory,
  ]);

function factory(
  $q,
  $http,
  Person,
  formHelperAccountBeneficiary,
  formHelperRespBeneficiary,
  formHelperSuccessorHolder,
  spouseOrPartner,
  pendingChangesService
) {
  var vars = {
    scope: null,
    baseScope: null,
    inlineSave: true,
    originalBeneficiary: null,
    advisor: null,
    account: null,
    successorHolder: null,
    loadingOn: function () {
      var _scope = this.scope || this.baseScope;
      _scope.$root.showLoadAnimation = true;
      _scope.beneficiaryLoading = true;
    },
    loadingOff: function () {
      var _scope = this.scope || this.baseScope;
      _scope.$root.showLoadAnimation = false;
      _scope.beneficiaryLoading = false;
    },
  };

  function setScope(scope) {
    vars.scope = scope;
  }

  function getScope() {
    return vars.scope;
  }

  function setBaseScope(scope) {
    vars.baseScope = scope;
  }

  function loadingOff() {
    vars.loadingOff();
  }

  function inlineSave(val) {
    if (!_.isUndefined(val)) {
      vars.inlineSave = val;
    }
    return vars.inlineSave;
  }

  function setAdvisor(val) {
    vars.advisor = val;
  }

  function setAccount(val) {
    vars.account = val;
  }

  function save(beneficiary) {
    const beneficiaries = [];
    const successorHolder = {
      personalRelationId: null,
      label: 'None',
    };

    if (vars.scope.isResp) {
      return formHelperRespBeneficiary.save(vars, beneficiary);
    } else if (
      !vars.scope.isResp &&
      vars.scope.beneficiary &&
      spouseIsBeneficiary() &&
      accountUsesSuccessorHolder(vars.account)
    ) {
      const sh = vars.scope.beneficiary.personalRelation().relative().toJSON();
      const fullName = `${sh.firstName} ${
        sh.middleName ? `${sh.middleName} ${sh.lastName}` : `${sh.lastName}`
      }`;
      const relationshipName = sh.maritalStatusId === 1 ? 'Spouse' : 'Partner';
      successorHolder.personalRelationId = vars.scope.beneficiary.personalRelation().id;
      successorHolder.fullName = fullName;
      successorHolder.label = `${relationshipName} - ${fullName}`;
    } else if (!vars.scope.isResp && vars.scope.beneficiary) {
      const benRel = vars.scope.beneficiary.personalRelation().toJSON();
      beneficiaries.push({
        firstName: benRel.relative.firstName,
        middleName: benRel.relative.middleName,
        lastName: benRel.relative.lastName,
        sin: benRel.relative.sin,
        personalRelationTypeId: benRel.typeId,
        otherRelationName: benRel.otherRelationName,
        percent: '100.0',
      });
    }

    return pendingChangesService
      .createPendingBeneficiaryDesignation(vars.scope.client.id, vars.scope.account.id, {
        successorHolder,
        beneficiaries,
      })
      .then(function () {
        const defer = $q.defer();
        defer.resolve();
        return defer.promise;
      });
  }

  function deleteBeneficiary(inheritor) {
    if (!inheritor || !inheritor.id) {
      return;
    }

    if (vars.inlineSave) {
      return inheritor.destroy();
    } else {
      vars.originalBeneficiary = inheritor;
    }
  }

  function createBeneficiary(mode, personalRelationId) {
    if (!vars.inlineSave) {
      deleteBeneficiary(vars.scope.beneficiary);

      if (mode.useSuccessorHolder) {
        return formHelperSuccessorHolder.createBeneficiary(vars, mode, personalRelationId);
      } else {
        return formHelperAccountBeneficiary.createBeneficiary(vars, mode, personalRelationId);
      }
    }

    var currentInheritor = vars.scope.beneficiary;
    // this is to prevent radio from blinking while beneficiary is created
    vars.scope.beneficiary = {
      personalRelationId: function () {
        return personalRelationId;
      },
    };
    return $q
      .when(deleteBeneficiary(currentInheritor))
      .then(function () {
        if (mode.useSuccessorHolder) {
          return formHelperSuccessorHolder.create(vars, personalRelationId);
        } else {
          return formHelperAccountBeneficiary.create(vars, personalRelationId);
        }
      })
      .then(function (inheritor) {
        vars.scope.beneficiary = inheritor;
        if (mode.useSuccessorHolder) {
          vars.scope.successorHolder = inheritor;
        }
        return inheritor;
      })
      .catch(function () {
        vars.scope.beneficiary = currentInheritor;
      });
  }

  function createRespBeneficiary() {
    return formHelperRespBeneficiary.createBeneficiary(vars);
  }

  function beneficiaryChangeWrapper(callback) {
    vars.loadingOn();
    return $q.when(callback()).finally(function () {
      vars.loadingOff();
    });
  }

  function shouldAssignBeneficiary(account) {
    return account.type() && account.type().beneficiary;
  }

  /**
   * As a setter:
   *  - val=true: set estate as a beneficiary (this is default, when there is no beneficiaries assigned to the account).
   * As a getter: Return true if estate is current beneficiary.
   *
   * @param  {Boolean} val
   * @return {Boolean}
   */
  function estateIsBeneficiary(val) {
    if (val) {
      var currentBeneficiary = vars.scope.beneficiary;
      vars.scope.beneficiary = null;

      beneficiaryChangeWrapper(function () {
        return deleteBeneficiary(currentBeneficiary);
      }).catch(function () {
        vars.scope.beneficiary = currentBeneficiary;
      });
      return val;
    }

    return vars.scope.beneficiary === null || vars.scope.beneficiary === undefined;
  }

  /**
   * As a setter:
   *  - val=true: create beneficiary with the spouseRelation for current person
   * As a getter: Return true if spouse is current beneficiary.
   *
   * @param  {Boolean} val
   * @return {Boolean}
   */
  function spouseIsBeneficiary(val) {
    if (!vars.scope.spouse || !vars.scope.person.spouseRelation()) {
      return false;
    }

    var spouseRelationId = vars.scope.person.spouseRelation().id;
    if (arguments.length && val) {
      beneficiaryChangeWrapper(function () {
        return createBeneficiary(
          {
            useSuccessorHolder: accountUsesSuccessorHolder(vars.account),
          },
          spouseRelationId
        );
      });
      return val;
    }
    return (
      vars.scope.beneficiary && vars.scope.beneficiary.personalRelationId() === spouseRelationId
    );
  }

  /**
   * As a setter:
   *  - val=true: create beneficiary with new PersonalRelation
   * As a getter: Return true if the current beneficiary is not the spouse.
   *
   * @param  {Boolean} val
   * @return {Boolean}
   */
  function otherIsBeneficiary(val) {
    if (arguments.length && val) {
      beneficiaryChangeWrapper(function () {
        return createBeneficiary({
          useSuccessorHolder: false,
        });
      });
      return val;
    }

    var spouseRelation = vars.scope.person.spouseRelation();
    var spouseRelationId = spouseRelation ? spouseRelation.id : null;

    return (
      vars.scope.beneficiary && vars.scope.beneficiary.personalRelationId() !== spouseRelationId
    );
  }

  /**
   * Invoke the above getter functions to get a text string that describes the beneficiary
   */
  function beneficiarySummaryStatement() {
    if (estateIsBeneficiary()) {
      return 'Estate';
    } else if (spouseIsBeneficiary()) {
      return 'Spouse';
    } else if (otherIsBeneficiary()) {
      return 'Someone else';
    } else {
      return 'Unknown';
    }
  }

  function advisorBeneficiarySummaryStatement() {
    const spouseRelationId = vars.scope.person.spouseRelation()?.id;
    const beneficiaries = vars.scope.beneficiaries
      ? vars.scope.beneficiaries
      : vars.scope.allBeneficiaries;
    const singleBeneficiary = beneficiaries.length === 1 && beneficiaries[0];
    const successorHolder = vars.scope.successorHolder;
    const numBeneficiaries = beneficiaries.length;

    const retVal = {
      summaryStatement: null,
      showBeneficiaryList: false,
    };

    const isSpouse = (relationId) => relationId === spouseRelationId;
    const spouseReferredToAs = spouseOrPartner.relationshipName(vars.scope.person, {
      capitalize: true,
    });
    if (numBeneficiaries === 0 && !successorHolder) {
      retVal.summaryStatement = {
        label: 'Designated Beneficiary',
        value: 'Estate',
      };
    } else if (successorHolder && isSpouse(successorHolder.personalRelationId())) {
      retVal.summaryStatement = {
        label: vars.account.successorHolderOrAnnuitantLabel(),
        value: `${spouseReferredToAs} - ${successorHolder
          .personalRelation()
          .relative()
          .fullName()}`,
      };
    } // no else

    if (!successorHolder && singleBeneficiary && isSpouse(singleBeneficiary.personalRelationId())) {
      retVal.summaryStatement = {
        label: 'Designated Beneficiary',
        value: `${spouseReferredToAs} - ${singleBeneficiary
          .personalRelation()
          .relative()
          .fullName()}`,
      };
    } else {
      retVal.showBeneficiaryList = true;
    }
    return retVal;
  }

  function maritalStatusChange(person) {
    return beneficiaryChangeWrapper(function () {
      var currentBeneficiary = vars.scope.beneficiary;
      vars.scope.beneficiary = null;

      return deleteBeneficiary(currentBeneficiary)
        .then(function () {
          return Person.prototype.maritalStatusChange.call(person);
        })
        .catch(function () {
          vars.scope.beneficiary = currentBeneficiary;
        });
    });
  }

  function addRespBeneficiary() {
    return formHelperRespBeneficiary.addRespBeneficiary(vars);
  }

  function removeRespBeneficiary(beneficiary) {
    return formHelperRespBeneficiary.deleteBeneficiary(vars, beneficiary);
  }

  function accountUsesSuccessorHolder(account) {
    return account.type && account.type().canHaveSuccessorHolder;
  }

  function getEditError(account) {
    if (account.number()) {
      return {};
    }

    return {
      title: 'Cannot edit beneficiaries',
      message:
        'Beneficiary designations cannot be changed for an account that is not opened yet. Please try again after the account number is assigned, or contact support for assistance.',
    };
  }

  return {
    setScope,
    getScope,
    setBaseScope,
    setAdvisor,
    inlineSave,
    save,
    loadingOff,
    deleteBeneficiary,
    createBeneficiary,
    shouldAssignBeneficiary,
    estateIsBeneficiary,
    spouseIsBeneficiary,
    otherIsBeneficiary,
    beneficiarySummaryStatement,
    advisorBeneficiarySummaryStatement,
    maritalStatusChange,
    beneficiaryLoading: function () {
      return vars.scope.beneficiaryLoading;
    },
    updateScopeBeneficiaries: function (beneficiaries, successorHolder) {
      vars.scope.beneficiaries = beneficiaries;
      vars.scope.beneficiary = successorHolder || _.first(beneficiaries);
      if (vars.scope.beneficiary) {
        vars.scope.personalRelation = vars.scope.beneficiary.personalRelation();
      }
      vars.baseScope.beneficiaries = vars.scope.beneficiaries;
      vars.baseScope.beneficiary = vars.scope.beneficiary;
    },
    createRespBeneficiary,
    addRespBeneficiary,
    removeRespBeneficiary,
    safeToDeleteFamilyRespBeneficiary: function () {
      return vars.baseScope && vars.baseScope.beneficiaries.length > 1;
    },
    setAccount,
    accountUsesSuccessorHolder,
    getEditError,
  };
}
