'use strict';

angular
  .module('service.resolve-helpers.transfer-institutions', [])
  .factory('transferInstitutionsResolveHelpers', ['$http', factory]);

function factory($http) {
  var resolveInsitutions = function () {
    return $http.get('/assets/transfer-institutions.json').then(function (response) {
      if (response.data) {
        return response.data;
      }
    });
  };

  return {
    resolveInsitutions,
  };
}
