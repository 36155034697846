'use strict';

angular
  .module('controller.advise.transfer-requests.all-transfer-requests', [
    'model.PaginatedTransferInstruction',
    'model.Paginator',
    'service.loading-indicator',
    'service.pagination.filter-service',
  ])
  .controller('AdviseTransferRequestsAllTransferRequestsCtrl', [
    '$scope',
    '$http',
    'loadingIndicator',
    'Paginator',
    'PaginatedTransferInstruction',
    'paginationFilterService',
    adviseTransferRequestsAllTransferRequestsController,
  ]);

function adviseTransferRequestsAllTransferRequestsController(
  $scope,
  $http,
  loadingIndicator,
  Paginator,
  PaginatedTransferInstruction,
  paginationFilterService
) {
  $scope.filterData = [
    {
      id: 0,
      group: 'Status',
      label: 'Pending client approval',
      code: 'status.pending_approval',
    },
    {
      id: 1,
      group: 'Status',
      label: 'New',
      code: 'status.new',
    },
    {
      id: 2,
      group: 'Status',
      label: 'Sent for processing',
      code: 'status.sent',
    },
    {
      id: 3,
      group: 'Status',
      label: 'Finished',
      code: 'status.finished',
    },
    {
      id: 4,
      group: 'Status',
      label: 'Cancelled',
      code: 'status.cancelled',
    },
    {
      id: 5,
      group: 'Request Type',
      label: 'Deposit',
      code: 'type.deposit',
    },
    {
      id: 6,
      group: 'Request Type',
      label: 'Withdrawal',
      code: 'type.withdrawal',
    },
    {
      id: 7,
      group: 'Request Type',
      label: 'Internal Transfer',
      code: 'type.transfer',
    },
  ];

  /** ***************************** Pagination methods */
  var initialFilter = ['status.pending_approval', 'status.new', 'status.sent'];
  $scope.filterModel = paginationFilterService.toFilterModel($scope.filterData, initialFilter);

  $scope.initialActions = function () {
    return [
      {
        type: 'FLEX-FILTER',
        filterList: initialFilter,
      },
    ];
  };

  var _getDataFromPaginator = function (parameter) {
    return parameter.transferInstructions();
  };

  $scope.paginator = new Paginator(PaginatedTransferInstruction, null, _getDataFromPaginator);
  /** ***************************** end Pagination methods */

  function _reloadTransferInstructions() {
    $scope.actionManagerAllRequests.reload();
  }

  function _sendTicket(data, transferInstruction) {
    $scope.transferSendConfirmationModal(transferInstruction).result.then(function () {
      transferInstruction.save(data).then(_reloadTransferInstructions);
    });
  }

  $scope.sendTicket = function (transferInstruction) {
    _sendTicket(
      {
        sentAt: moment().toDate(),
      },
      transferInstruction
    );
  };

  $scope.sendCancelTicket = function (transferInstruction) {
    _sendTicket(
      {
        cancellationSentAt: moment().toDate(),
      },
      transferInstruction
    );
  };

  $scope.canForceCancel = function (transferInstruction) {
    return !transferInstruction.status.is.cancelled() && !transferInstruction.status.is.finished();
  };

  $scope.canCancel = function (transferInstruction) {
    return (
      !transferInstruction.status.is.cancelled() &&
      !transferInstruction.status.is.finished() &&
      transferInstruction.canCancel()
    );
  };

  $scope.forceCancel = function (transferInstruction) {
    var modalTitle = 'Cancel Transfer Request';
    var modalBody =
      'Are you sure you want to cancel this transfer request? Doing so will delete any upcoming or in-progress transfer instances of this transfer request.';

    $scope.$root
      .genericConfirmationModal(
        modalTitle,
        modalBody,
        'btn-danger',
        'Cancel transfer request',
        "Don't cancel transfer request"
      )
      .result.then(function () {
        loadingIndicator.show();
        return transferInstruction.forceCancel();
      })
      .then(_reloadTransferInstructions)
      .finally(function () {
        loadingIndicator.hide();
      });
  };

  $scope.cancel = function (transferInstruction) {
    $scope.$root
      .advisorConfirmCancelTransferInstruction(transferInstruction)
      .result.then(function () {
        loadingIndicator.show();
        return $http.put(
          'api/advisor/transfer_instructions/' + transferInstruction.id + '/cancel.json'
        );
      })
      .then(_reloadTransferInstructions)
      .finally(function () {
        loadingIndicator.hide();
      });
  };
}
