'use strict';

angular
  .module('routes.advise.trade-reconciliation', [
    'controller.advise.trade-reconciliation',
    'model.Custodian',
    'templates',
    'ui.router',
    'model.Custodian',
    'model.TradeReconciliationReport',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function resolveTradeReconciliationReports(TradeReconciliationReport) {
    return TradeReconciliationReport.where(
      {},
      {
        force: true,
      }
    );
  }

  function resolveTradeReconciliationReport($stateParams, TradeReconciliationReport) {
    var id = parseInt($stateParams.id);
    var resultsSummary = $stateParams.resultsSummary;

    return TradeReconciliationReport.find({
      id,
    }).then(function (item) {
      item.attachResultsSummary(resultsSummary);
      return item;
    });
  }

  function resolveCustodians(Custodian) {
    return [
      new Custodian({
        id: 1,
        name: 'Credential Qtrade Securities Inc.',
      }),
      new Custodian({
        id: 2,
        name: 'CI Investment Services Inc.',
      }),
    ];
  }

  $stateProvider
    .state('advise.trade-reconciliation', {
      url: '/trade-reconciliation',
      abstract: true,
      controller: 'AdviseTradeReconciliationCtrl',
      resolve: {
        authorize: ['authorizer', (a) => a.checkPermission('trade')],
      },
      template: '<div ui-view></div>',
    })
    .state('advise.trade-reconciliation.reports', {
      url: '/reports',
      controller: 'AdviseTradeReconciliationReportsCtrl',
      resolve: {
        tradeReconciliationReports: [
          'TradeReconciliationReport',
          resolveTradeReconciliationReports,
        ],
      },
      templateUrl: 'views/advise/trade-reconciliation/reports.html',
    })
    .state('advise.trade-reconciliation.report', {
      url: '/reports/{id:[0-9]+}',
      controller: 'AdviseTradeReconciliationReportCtrl',
      resolve: {
        tradeReconciliationReport: [
          '$stateParams',
          'TradeReconciliationReport',
          resolveTradeReconciliationReport,
        ],
      },
      templateUrl: 'views/advise/trade-reconciliation/report.html',
    })
    .state('advise.trade-reconciliation.create-report', {
      url: '/reports/create',
      controller: 'AdviseTradeReconciliationCreateReportCtrl',
      resolve: {
        custodians: ['Custodian', resolveCustodians],
      },
      templateUrl: 'views/advise/trade-reconciliation/create-report.html',
    })
    .state('advise.trade-reconciliation.report-creation-details', {
      url: '/reports/{id:[0-9]+}/creation-details',
      controller: 'AdviseTradeReconciliationReportCreationDetailsCtrl',
      params: {
        resultsSummary: null,
      },
      resolve: {
        tradeReconciliationReport: [
          '$stateParams',
          'TradeReconciliationReport',
          resolveTradeReconciliationReport,
        ],
      },
      templateUrl: 'views/advise/trade-reconciliation/report-creation-details.html',
    });
}
