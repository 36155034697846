'use strict';

angular
  .module('model.PendingChange', ['ram', 'config'])
  .factory('PendingChange', ['ram', 'config', pendingChangesFactory]);

function pendingChangesFactory(ram, config) {
  /**
   * Constructor for Application models.
   * @class
   */
  var PendingChange = new ram.Collection('PendingChange', {
    enums: {
      name: config.types.PendingChange,
      status: config.types.PendingChangeStatus,
    },
    belongsTo: {
      user: 'User',
      account: 'Account',
    },
    schema: config.schemas.PendingChange,
    resources: {
      default: new ram.resources.Http('/api/pending_changes/:id.json'),
      cookie: new ram.resources.Cookie('pending_changes'),
    },
  });

  return PendingChange;
}
