'use strict';

angular
  .module('controller.advise.positions-by-day-report', [])
  .controller('PositionsByDayReportCtrl', ['$scope', 'ram', positionsByDayReportCtrl]);

function positionsByDayReportCtrl($scope, ram) {
  $scope.accountNumbers = new ram.Accessor('');
  $scope.endDate = moment().subtract(1, 'month').endOf('month').toDate(); // it figures out the start date
  $scope.dateRangePicked = $scope.$parent.dateRangePicked;

  $scope.$watch('accountNumbers()', (newValue, oldValue) => {
    if (newValue !== oldValue) {
      const accountNumbers = newValue
        .split(/(\s+|,)/)
        .filter((p) => p.trim().length > 0 && p !== ',')
        .join(', ');
      $scope.$parent.accountNumbers = accountNumbers;
    }
  });
}
