'use strict';

angular
  .module('controller.advise.bank-accounts', [
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedBankAccount',
    'model.Paginator',
    'service.pagination.filter-service',
    'service.securityService',
  ])
  .controller('AdviseBankAccountsCtrl', [
    '$scope',
    'config',
    'Paginator',
    'PaginatedBankAccount',
    'paginationFilterService',
    adviseBankAccountController,
  ]);

function adviseBankAccountController(
  $scope,
  config,
  Paginator,
  PaginatedBankAccount,
  paginationFilterService
) {
  $scope.linkToClient = function (bankAccount) {
    if (bankAccount.userId()) {
      return 'advise.client.view({id: ' + bankAccount.userId() + '})';
    } else {
      return '#';
    }
  };

  $scope.linkToBankAccount = function (bankAccount) {
    if (bankAccount.userId()) {
      return 'advise.client.view({id: ' + bankAccount.userId() + ', "#": "bank-accounts"})';
    } else {
      return '#';
    }
  };

  /** ***************************** Pagination methods */
  $scope.bankAccounts = [];
  $scope.actionManagerBankAccounts = {};

  var initialFilter = ['status.pending_verification'];
  $scope.initialActions = function () {
    return [
      {
        type: 'FLEX-FILTER',
        filterList: initialFilter,
      },
    ];
  };
  var filterData = [];

  var bankAccountStatuses = _.filter(config.types.BankAccountStatus, function (status) {
    return status.name !== 'deactivated';
  });

  paginationFilterService.addEnumFilterData(bankAccountStatuses, filterData, 'Status', 'status');
  $scope.filterData = filterData;
  $scope.filterModel = paginationFilterService.toFilterModel($scope.filterData, initialFilter);

  var _getDataFromPaginator = function (parameter) {
    return parameter.bankAccounts();
  };

  $scope.paginator = new Paginator(PaginatedBankAccount, null, _getDataFromPaginator);
  /** ***************************** end Pagination methods */

  $scope.canResendAddForm = function (bankAccount) {
    return !bankAccount.status.is.draft() && bankAccount.userHasNonTrialCsiAccount();
  };

  $scope.canVerify = function (bankAccount) {
    return bankAccount.status.is.unverified() || bankAccount.status.is.pendingVerification();
  };

  $scope.canUnverify = function (bankAccount) {
    return bankAccount.status.is.verified() || bankAccount.status.is.pendingVerification();
  };

  $scope.resendAddForm = function (bankAccount) {
    $scope.$root.advisorModifyBankApprovalModal(bankAccount, 'resend').result.then(function () {
      return bankAccount.resendAddForm();
    });
  };

  $scope.verify = function (bankAccount) {
    $scope.$root.advisorModifyBankApprovalModal(bankAccount, 'verify').result.then(function () {
      return bankAccount.verify();
    });
  };

  $scope.unverify = function (bankAccount) {
    $scope.$root.advisorModifyBankApprovalModal(bankAccount, 'unverify').result.then(function () {
      return bankAccount.unverify();
    });
  };

  $scope.hasBankStatementUrl = function (bankAccount) {
    return bankAccount.bankStatementUrl();
  };
}
