'use strict';

angular
  .module('controller.home.etfs', [])
  .controller('EtfsCtrl', ['$scope', 'ram', 'etf', 'chartData', etfsController]);

function periodsRelativeToStoredData(date) {
  return [
    {
      label: '1 Year',
      start: moment(date).subtract(1, 'years').startOf('day'),
    },
    {
      label: '3 Years',
      start: moment(date).subtract(3, 'years').startOf('day'),
    },
    {
      label: '5 Years',
      start: moment(date).subtract(5, 'years').startOf('day'),
    },
    {
      label: 'YTD',
      start: moment(date).startOf('years'),
    },
  ];
}

function etfsController($scope, ram, etf, chartData) {
  var amount = 1000;

  $scope.etf = etf;

  $scope.list = [
    {
      assetClass: 'Canadian Stocks',
      etfs: [
        {
          name: 'iShares Core Capped Composite ETF',
          ticker: 'XIC',
          state: 'etfs.ishares-core-cci',
        },
        {
          name: 'iShares ESG Advanced MSCI Canada Index ETF',
          ticker: 'XCSR',
          state: 'etfs.ishares-esg-advanced-msci',
        },
      ],
    },
    {
      assetClass: 'US Stocks',
      etfs: [
        {
          name: 'Vanguard US Total Market ETF (CAD Hedged)',
          ticker: 'VUS',
          state: 'etfs.vanguard-us-tm',
        },
        {
          name: 'iShares MSCI KLD 400 Social ETF',
          ticker: 'DSI (US)',
          state: 'etfs.ishares-msci-kld-400',
        },
      ],
    },
    {
      assetClass: 'Non-North American Developed Market Stocks',
      etfs: [
        {
          name: 'Vanguard FTSE Developed ex US ETF CAD-Hedged',
          ticker: 'VEF',
          state: 'etfs.vanguard-ftse-dena',
        },
        {
          name: 'iShares ESG Aware MSCI EAFE ETF',
          ticker: 'ESGD (US)',
          state: 'etfs.ishares-msci-eafe-esg-optimized',
        },
      ],
    },
    {
      assetClass: 'Emerging Market Stocks',
      etfs: [
        {
          name: 'Vanguard FTSE Emerging Markets ETF',
          ticker: 'VEE',
          state: 'etfs.vanguard-ftse-em',
        },
        {
          name: 'iShares ESG Aware MSCI EM ETF',
          ticker: 'ESGE (US)',
          state: 'etfs.ishares-msc-em-esg-optimized',
        },
      ],
    },
    {
      assetClass: 'Canadian Bonds',
      etfs: [
        {
          name: 'Vanguard Canadian Short-Term Index Bond ETF',
          ticker: 'VSB',
          state: 'etfs.vanguard-canadian-stb',
        },
        {
          name: 'iShares 1-5 Year Laddered Government Bond Index ETF',
          ticker: 'CLF',
          state: 'etfs.ishares-laddered-etf',
        },
      ],
    },
    {
      assetClass: 'Emerging Market Bonds',
      etfs: [
        {
          name: 'BMO Emerging Markets Bond Hedged to CAD Index ETF',
          ticker: 'ZEF',
          state: 'etfs.bmo-emerging-markets-bch',
        },
      ],
    },
    {
      assetClass: 'Real Estate',
      etfs: [
        {
          name: 'Vanguard FTSE Capped REITs Index ETF',
          ticker: 'VRE',
          state: 'etfs.vanguard-capped-reits',
        },
        {
          name: 'iShares Global REIT ETF',
          ticker: 'REET (US)',
          state: 'etfs.ishares-global-reit',
        },
      ],
    },
  ];

  var periods = periodsRelativeToStoredData(chartData ? chartData[0].date : new Date());

  angular.extend($scope, {
    periods,
    period: new ram.Accessor(periods[0]),
    start: new ram.Accessor(),
    end: new ram.Accessor(),
  });

  $scope.data = setDataCalcGrowth(chartData);

  function setDataCalcGrowth(data) {
    if (!data) {
      // Skip during instantiation of abstract parent or if no data.
    } else {
      var startDatum = findStart(data);
      $scope.start(startDatum.date);
      $scope.end(data[0].date);
      var dataSet = addAmountProp(data, startDatum, amount);
      return dataSet;
    }
  }

  // Find the data point corresponding to period().start
  function findStart(data) {
    var len = data.length;
    var i = data.length - 1;
    var start;

    while (i >= 0) {
      // + coerces to num to ensure proper comparison
      if (+data[i].date === +$scope.period().start._d) {
        start = data[i];
        return start;
      }
      i--;
    }

    // Only executed if start not found in while loop.
    // Happens, as there is no data for e.g. weekends.
    // Data is sorted upon resolution in etfs.js routes.
    var earliest = data[len - 1];
    return handleNoDataForPeriodStart(data, earliest);
  }

  // If period().start precedes earliest datum, set to earliest datum.
  // Otherwise, set period().start one day earlier and try findStart again.
  function handleNoDataForPeriodStart(data, earliest) {
    if ($scope.period().start._d < earliest.date) {
      return earliest;
    }

    $scope.period().start = $scope.period().start.subtract(1, 'days');

    return findStart(data);
  }

  function addAmountProp(data, startDatum, amount) {
    var numOfShares = amount / startDatum.adjClose;
    var dataSet = data.map(function (datum) {
      datum.amount = numOfShares * datum.adjClose;
      return datum;
    });
    return dataSet;
  }

  // Note, not only do the periods have to be reset, but the amounts need to be recalculated as well.
  $scope.$watch('period()', function () {
    $scope.data = setDataCalcGrowth(chartData);
  });

  $scope.formatCurrencyEtf = function (d) {
    return d3.format('$')(d);
  };
}
