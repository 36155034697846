'use strict';

angular
  .module('directive.staticFieldVertical', [])
  .directive('staticFieldVertical', [staticFieldVerticalDirective]);

function staticFieldVerticalDirective() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      fieldLabel: '@',
    },
    template:
      '<div class="row advise-static-field-vertical">' +
      '<div class="control-label p-t-0">{{fieldLabel}}</div>' +
      '<div class="static-content p-l-0-imp"><ng-transclude></ng-transclude></div>' +
      '</div>',
  };
}
