'use strict';

function factory() {
  function _getActiveVariationName(experimentId) {
    var variationId;
    var isVariationActive;

    variationId = window.optimizely.variationIdsMap[experimentId][0];
    /* jshint ignore:start */
    isVariationActive = _.contains(
      window.optimizely.allExperiments[experimentId].enabled_variation_ids,
      variationId
    );
    /* jshint ignore:end */

    if (isVariationActive) {
      return window.optimizely.variationNamesMap[experimentId];
    }
  }

  function activatePreviewTestAndGetVariationName() {
    if (!window.optimizely) {
      return;
    }
    // ID of the a/b test for the live server, the one on the dev server has a different ID
    var experimentId = 7575531265;
    var isExperimentActive;

    window.optimizely.push(['activate', experimentId]);
    isExperimentActive = _.contains(window.optimizely.activeExperiments, experimentId);

    if (isExperimentActive) {
      return _getActiveVariationName(experimentId);
    }
  }

  function trackEvent(eventName) {
    if (window.optimizely) {
      window.optimizely.push(['trackEvent', eventName]);
    }
  }

  return {
    activatePreviewTestAndGetVariationName,
    trackEvent,
  };
}

angular.module('service.optimizely', []).factory('optimizely', [factory]);
