'use strict';

function factory() {
  return {
    /*
     * This code is shared between the onboarding and dashboard|account modify views.
     *
     * Given an the questions, determine if text should be shown instead of the timeframe slider.
     * The Dependency.js code controls if the slider is displayed or not.  This code kicks in
     * in the cases where the slider is NOT displayed.  (Hence all the ! operators down below.)
     *
     */
    autoTimeframe: function (goal, currentUser, questions) {
      var timeframe = questions.timeframe;
      var notimeframe = questions.notimeframe;
      var retirementAge = questions.retirementAge;
      var childsAge = questions.childsAge;
      if (timeframe && notimeframe && retirementAge && childsAge) {
        var models = {
          goal,
          user: currentUser,
        };

        return (
          !timeframe.show(models) &&
          !notimeframe.show(models) &&
          !retirementAge.show(models) &&
          !childsAge.show(models)
        );
      }
    },
  };
}

angular.module('service.autoTimeframeService', []).factory('autoTimeframeService', [factory]);
