'use strict';

angular
  .module('controller.modal.super-advisor.show-internal-transfers-email-text-and-mark-as-sent', [
    'ngclipboard',
    'service.link-copy-handler',
  ])
  .controller('ModalSuperAdvisorShowInternalTransfersEmailTextAndMarkAsSentCtrl', [
    '$scope',
    '$filter',
    'linkCopyHandler',
    'transferRequests',
    controller,
  ]);

function controller($scope, $filter, linkCopyHandler, transferRequests) {
  $scope.numberOfRequests = transferRequests.length;
  $scope.emailText = _createEmailText(transferRequests);

  $scope.onLinkCopied = function (e) {
    linkCopyHandler.onLinkCopied(e);
  };

  function _createEmailText(transferRequests) {
    var text = '';
    _.each(transferRequests, function (transferRequest) {
      text =
        text +
        'Please transfer ' +
        $filter('currency')(transferRequest.amount) +
        ' from ' +
        transferRequest.clientName +
        '’s ' +
        transferRequest.fromLabel +
        ' to their ' +
        transferRequest.toLabel +
        '.\n';
    });

    return text;
  }
}
