'use strict';

angular
  .module('routes.advise.client-application', [
    'controller.advise.client-application',
    'model.AdvisorCreateNewClientFlow',
    'model.Person',
    'model.PortfolioManager',
    'service.address-service',
    'service.resolve-helpers.banking',
    'service.resolve-helpers.personal-info',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function resolveClientFlow($stateParams, AdvisorCreateNewClientFlow) {
    return AdvisorCreateNewClientFlow.find({
      id: $stateParams.id,
    });
  }

  function resolveAccountFlows(newClientFlow) {
    return newClientFlow.advisorCreateNewAccountFlows.ready(true);
  }

  function resolveTransferFlows(newClientFlow) {
    return newClientFlow.advisorCreateNewTransferFlows.ready(true);
  }

  function resolveClientBankAccounts(user) {
    return user.bankAccounts.ready();
  }

  function resolveTransferInstructionFlows(newClientFlow) {
    return newClientFlow.advisorCreateNewTransferInstructionFlows.ready(true);
  }

  function resolveClientRelatedNames(newClientFlow) {
    return newClientFlow.relatedNames();
  }

  function resolveAssociation(association) {
    return function (model) {
      return model[association].ready();
    };
  }

  var resolvePersonalRelations = resolveAssociation('personalRelations');

  function resolveSpouse(personalRelations, personalInfoResolveHelpers) {
    return personalInfoResolveHelpers.resolveSpouse(personalRelations);
  }

  function resolveHomeAddress(person, addressService) {
    return addressService.resolveHomeAddress(person);
  }

  function resolveMailingAddress(person, homeAddress, addressService) {
    return addressService.resolveMailingAddress(person, homeAddress);
  }

  function getDocumentTypeId(documentType, config) {
    return _.find(config.types.Document, {
      name: documentType,
    }).id;
  }

  function fetchDocumentsByType(documentTypeId, currentUser, Document) {
    return Document.where({
      typeId: documentTypeId,
      userId: currentUser.id,
    });
  }

  /**
   * Gets the image specified by the imageType
   * @return {Document}     The document, or a new empty document if none exist.
   */
  function resolveImage(imageType, config, Document, user) {
    var typeId = getDocumentTypeId(imageType, config);

    return fetchDocumentsByType(typeId, user, Document).then(function (documents) {
      if (!documents || documents.length === 0) {
        return Document.new({
          typeId,
          userId: user.id,
        });
      } else {
        return _.last(documents);
      }
    });
  }

  var resolveFrontIdentificationImage = _.partial(resolveImage, 'id_front');
  var resolveBackIdentificationImage = _.partial(resolveImage, 'id_back');
  var resolveJointFrontIdentificationImage = _.partial(resolveImage, 'joint_id_front');
  var resolveJointBackIdentificationImage = _.partial(resolveImage, 'joint_id_back');

  function getBankStatementsForBankAccount(bankStatements, bankAccount) {
    return _.filter(bankStatements, function (bankStatement) {
      return (
        bankStatement.relatedToType() === 'BankAccount' &&
        bankStatement.relatedToId() === bankAccount.id
      );
    });
  }

  function resolveBankStatementImage(config, Document, user, bankAccounts) {
    var typeId = getDocumentTypeId('bank_statement', config);
    var bankAccount = _.first(bankAccounts);

    return fetchDocumentsByType(typeId, user, Document).then(function (bankStatements) {
      var bankStatementsForBankAccount = getBankStatementsForBankAccount(
        bankStatements,
        bankAccount
      );

      if (bankStatementsForBankAccount && bankStatementsForBankAccount.length > 0) {
        return _.last(bankStatementsForBankAccount);
      } else {
        return Document.new({
          typeId,
          userId: user.id,
          relatedToType: 'BankAccount',
          relatedToId: bankAccount.id,
        });
      }
    });
  }

  function resolveNewAccountFlow($stateParams, newAccountFlows) {
    var newAccountFlowId = $stateParams.newAccountFlowId;
    var newAccountFlow = _.find(newAccountFlows, function (accountFlow) {
      return parseInt(newAccountFlowId) === accountFlow.id;
    });
    return newAccountFlow.client.ready().then(function () {
      return newAccountFlow;
    });
  }

  function resolvePortfolioOptions($http, PortfolioManager, newClientFlow, clientUser) {
    const pmId =
      newClientFlow.preferredPortfolioManagerId() ?? PortfolioManager.constructor.modernAdvisorId();
    const userIdPortion = _.isNullOrUndefined(clientUser) ? '' : clientUser.id;
    return $http
      .get(`/api/portfolio_managers/${pmId}/portfolio_options.json?user_id=${userIdPortion}`)
      .then((result) => result.data?.portfolioOptions);
  }

  $stateProvider
    .state('advise.client-application', {
      url: '/client-application/{id:[0-9]+}',
      abstract: true,
      controller: 'AdviseClientApplicationCtrl',
      template: '<div class="container" ui-view></div>',
      resolve: {
        newClientFlow: ['$stateParams', 'AdvisorCreateNewClientFlow', resolveClientFlow],
      },
    })
    .state('advise.client-application.setup', {
      url: '/setup',
      controller: 'AdviseClientApplicationSetupCtrl',
      templateUrl: 'views/advise/client-application/setup.html',
      resolve: {
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
      },
    })
    .state('advise.client-application.details', {
      url: '/details',
      controller: 'AdviseClientApplicationDetailsCtrl',
      templateUrl: 'views/advise/client-application/details.html',
      resolve: {
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
        addresses: [
          'client',
          function (client) {
            return client.addresses.ready();
          },
        ],
        user: [
          'client',
          function (client) {
            return client.user.ready();
          },
        ],
        bankAccounts: [
          'user',
          function (user) {
            return user.bankAccounts.ready();
          },
        ],
        addressService: 'addressService',
        homeAddress: ['client', 'addressService', resolveHomeAddress],
        mailingAddress: ['client', 'homeAddress', 'addressService', resolveMailingAddress],
        personalRelations: ['client', resolvePersonalRelations],
        spouse: ['personalRelations', 'personalInfoResolveHelpers', resolveSpouse],
        bankStatementImageInput: [
          'config',
          'Document',
          'user',
          'bankAccounts',
          resolveBankStatementImage,
        ],
        institutions: [
          'bankingResolveHelpers',
          function (bankingResolveHelpers) {
            return bankingResolveHelpers.resolveInsitutions();
          },
        ],
        frontImage: ['config', 'Document', 'user', resolveFrontIdentificationImage],
        backImage: ['config', 'Document', 'user', resolveBackIdentificationImage],
        jointFrontImage: ['config', 'Document', 'user', resolveJointFrontIdentificationImage],
        jointBackImage: ['config', 'Document', 'user', resolveJointBackIdentificationImage],
        newAccountFlows: ['newClientFlow', resolveAccountFlows],
      },
    })
    .state('advise.client-application.account', {
      url: '/account',
      controller: 'AdviseClientApplicationAccountCtrl',
      templateUrl: 'views/advise/client-application/account.html',
      resolve: {
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
        user: [
          'client',
          function (client) {
            return client.user.ready();
          },
        ],
        ima: [
          'user',
          function (user) {
            return user.investmentManagementAgreement.ready();
          },
        ],
        newAccountFlows: ['newClientFlow', resolveAccountFlows],
        portfolioOptions: [
          '$http',
          'PortfolioManager',
          'newClientFlow',
          'user',
          resolvePortfolioOptions,
        ],
      },
    })
    .state('advise.client-application.transfer', {
      url: '/transfer',
      controller: 'AdviseClientApplicationTransferCtrl',
      templateUrl: 'views/advise/client-application/transfer.html',
      resolve: {
        newTransferFlows: ['newClientFlow', resolveTransferFlows],
        newAccountFlows: ['newClientFlow', resolveAccountFlows],
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
      },
    })
    .state('advise.client-application.transfer-instruction', {
      url: '/eft',
      controller: 'AdviseClientApplicationTransferInstructionCtrl',
      templateUrl: 'views/advise/client-application/transfer-instruction.html',
      resolve: {
        newTransferInstructionFlows: ['newClientFlow', resolveTransferInstructionFlows],
        newAccountFlows: ['newClientFlow', resolveAccountFlows],
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
        user: [
          'client',
          function (client) {
            return client.user.ready();
          },
        ],
        bankAccounts: ['user', resolveClientBankAccounts],
      },
    })
    .state('advise.client-application.beneficiary', {
      url: '/{newAccountFlowId:[0-9]+}/beneficiary',
      controller: 'AdviseClientApplicationBeneficiaryCtrl',
      templateUrl: 'views/advise/client-application/beneficiary.html',
      resolve: {
        newAccountFlows: ['newClientFlow', resolveAccountFlows],
        newAccountFlow: ['$stateParams', 'newAccountFlows', resolveNewAccountFlow],
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
        user: [
          'client',
          function (client) {
            return client.user.ready();
          },
        ],
        personalRelations: ['client', resolvePersonalRelations],
        spouse: ['personalRelations', 'personalInfoResolveHelpers', resolveSpouse],
      },
    })
    .state('advise.client-application.joint-applicant', {
      url: '/{newAccountFlowId:[0-9]+}/joint-applicant',
      controller: 'AdviseClientApplicationJointApplicantCtrl',
      templateUrl: 'views/advise/client-application/joint-applicant.html',
      resolve: {
        newAccountFlows: ['newClientFlow', resolveAccountFlows],
        newAccountFlow: ['$stateParams', 'newAccountFlows', resolveNewAccountFlow],
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
        user: [
          'client',
          function (client) {
            return client.user.ready();
          },
        ],
        personalRelations: ['client', resolvePersonalRelations],
        spouse: ['personalRelations', 'personalInfoResolveHelpers', resolveSpouse],
        jointFrontImage: ['config', 'Document', 'user', resolveJointFrontIdentificationImage],
        jointBackImage: ['config', 'Document', 'user', resolveJointBackIdentificationImage],
      },
    })
    .state('advise.client-application.review', {
      url: '/review',
      controller: 'AdviseClientApplicationReviewCtrl',
      templateUrl: 'views/advise/client-application/review.html',
      resolve: {
        newAccountFlows: ['newClientFlow', resolveAccountFlows],
        newTransferFlows: ['newClientFlow', resolveTransferFlows],
        newTransferInstructionFlows: ['newClientFlow', resolveTransferInstructionFlows],
        client: [
          'newClientFlow',
          function (newClientFlow) {
            return newClientFlow.client.ready();
          },
        ],
        user: [
          'client',
          function (client) {
            return client.user.ready();
          },
        ],
        bankAccounts: ['user', resolveClientBankAccounts],
        relatedNames: ['newClientFlow', resolveClientRelatedNames],
      },
    });
}
