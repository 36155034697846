'use strict';

var _leadDynoMessages = {
  200: 'Success',
  201: 'The code was successfully applied',
  422: 'Known error',
  error: 'Something went wrong. Please try again later',
};

function leadDynoNotify($http, Promotion, promotionRedemptionCookieCreator) {
  var _getAffiliatePromoInfo;

  /** This is a trivial implementation.  It can be made more robust as needed in the future. */
  _getAffiliatePromoInfo = function (affiliateLink) {
    return Promotion.promotionInfo(affiliateLink);
  };

  return {
    /**
     * A bit of explanation - we always show the modal to the person that comes from affiliate link AND
     * we always override previous affiliate code with a new one.
     *
     * @param affiliateLink affiliate link from the url, e.g. it will be "XXX" for url "www.modernadvisor.ca/#_a_XXX"
     */
    recordVisitor: function (affiliateLink) {
      $http
        .post('api/lead_dyno_integrations/record_visit', {
          params: {
            code: affiliateLink,
          },
        })
        .then(
          function (response) {
            promotionRedemptionCookieCreator.createAffiliateReferral(
              affiliateLink,
              response.data.trackingCode
            );
          },
          function (error) {
            var status = error.status.toString();
            var messageKey = _.has(_leadDynoMessages, status) ? status : 'error';
            window.Sentry &&
              window.Sentry.captureMessage(
                'LeadDyno API failure. ' + _leadDynoMessages[messageKey]
              );
          }
        );
    },
    getAffiliatePromoInfo: function (affiliateLink) {
      return _getAffiliatePromoInfo(affiliateLink);
    },
  };
}

angular
  .module('service.leadDynoVisitorNotifier', [
    'service.promotion-redemption-cookie-creator',
    'model.Promotion',
  ])
  .factory('leadDynoVisitorNotifier', [
    '$http',
    'Promotion',
    'promotionRedemptionCookieCreator',
    leadDynoNotify,
  ]);
