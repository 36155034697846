import template from './pick-advisor.html';
import controller from './pick-advisor.controller';

const pickAdvisorComponent = {
  template,
  bindings: {
    labelText: '@',
    displayAll: '@',
    resetCallback: '<',
    onSelectAdvisor: '<',
    labelClass: '@',
    optional: '@',
  },
  controller: ['$scope', '$http', '$timeout', 'ram', controller],
  controllerAs: '$ctrl',
};

export default pickAdvisorComponent;
