'use strict';

function factory(SimulatedBalance) {
  function Simulation(initial, ongoing, projection) {
    this.initial = initial;
    this.ongoing = ongoing;
    this.projection = projection;
    this.run();
  }

  Simulation.prototype.run = function () {
    var self = this;
    var amount;
    this.values = _.map(this.projection.values(), function (value, i) {
      amount = i === 0 ? self.initial : Math.max(0, (1 + value.gain) * amount + self.ongoing);
      return new SimulatedBalance(amount, value.date, self);
    });
  };

  Simulation.prototype.toCashFlows = function (endDate) {
    var self = this;
    var cashFlows = [
      {
        amount: -self.values[0].amount,
        date: self.values[0].date,
      },
    ];
    if (self.values[0].date.getTime() < endDate.getTime()) {
      _.find(self.values, function (value) {
        if (self.ongoing && value !== self.values[0]) {
          cashFlows.push({
            amount: -self.ongoing,
            date: value.date,
          });
        }
        if (value.date.getTime() === endDate.getTime()) {
          cashFlows.push({
            amount: value.amount,
            date: value.date,
          });
          return true;
        }
      });
    }
    return cashFlows;
  };

  Simulation.prototype.calculateValue = function (initial, ongoing, timeframe) {
    var result = _.chain(this.projection.values())
      .rest(1)
      .take(timeframe)
      .reduce(function (result, projection) {
        return (1 + projection.gain) * result + ongoing;
      }, initial)
      .value();
    return result;
  };

  Simulation.prototype.search = function (start, end, target, func) {
    if (!_.isNumber(target) || _.isNaN(target) || target < 1) {
      return 0;
    }

    var val = func(end);
    for (var i = 0; i < 40 && val < target; ++i) {
      start = end;
      end = end * 2;
      val = func(end);
    }
    if (i > 40) {
      return undefined;
    }
    return this.binarySearch(start, end, target, func);
  };

  Simulation.prototype.binarySearch = function (start, end, target, func) {
    if (end - start < 1) {
      return Math.ceil(end);
    }
    var middle = (start + end) / 2;
    var midValue = func(middle);
    if (midValue < target) {
      return this.binarySearch(middle, end, target, func);
    }

    return this.binarySearch(start, middle, target, func);
  };

  Simulation.prototype.findOngoingForGoal = function (goal) {
    return this.findOngoing(goal.targetAmount(), goal.timeframe(), goal.initial());
  };

  Simulation.prototype.findOngoing = function (target, timeframe, initial) {
    return this.search(
      0,
      1,
      target,
      _.partial(this.calculateValue, initial, _, timeframe * 12).bind(this)
    );
  };

  Simulation.prototype.findInitialForGoal = function (goal) {
    return this.findInitial(goal.targetAmount(), goal.timeframe(), goal.ongoing());
  };

  Simulation.prototype.findInitial = function (target, timeframe, ongoing) {
    return this.search(
      0,
      1,
      target,
      _.partial(this.calculateValue, _, ongoing, timeframe * 12).bind(this)
    );
  };

  Simulation.prototype.findTarget = function (timeframe) {
    return this.calculateValue(this.initial, this.ongoing, timeframe * 12);
  };

  return Simulation;
}

angular
  .module('model.Simulation', ['model.SimulatedBalance'])
  .factory('Simulation', ['SimulatedBalance', factory]);
