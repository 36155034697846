'use strict';

class ModelPortfolioPieChartController {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.investmentPoint = {};
  }
}

export default ModelPortfolioPieChartController;
