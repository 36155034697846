'use strict';

function errorModalController($scope, title, message) {
  $scope.title = title;
  $scope.message = message;
}

angular
  .module('controller.modal.error-modal', [])
  .controller('ErrorModalController', ['$scope', 'title', 'message', errorModalController]);
