'use strict';

angular
  .module('controller.advise.client-application.transfer', ['directive.account-goal-detail'])
  .controller('AdviseClientApplicationTransferCtrl', [
    '$scope',
    'newTransferFlows',
    'newAccountFlows',
    adviseClientApplicationTransactionCtrl,
  ]);

function adviseClientApplicationTransactionCtrl($scope, newTransferFlows, newAccountFlows) {
  angular.extend($scope, {
    newTransferFlows,
    newAccountFlows,
    transferCount: newTransferFlows.length,
  });
  // View initialization
  resetScope();

  // Helper Functions

  function resetScope() {
    $scope.transferCount++;
  }
}
