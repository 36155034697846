'use strict';

angular
  .module('service.resolve-helpers.personal-info', [])
  .factory('personalInfoResolveHelpers', [factory]);

function factory() {
  var resolveSpouse = function (personalRelations) {
    var relation = _.find(personalRelations, function (relation) {
      return relation.type.is.spouse() || relation.type.is.commonLaw();
    });
    return relation && relation.relative.ready();
  };

  return {
    resolveSpouse,
  };
}
