'use strict';

var COOKIE_PREFIX = 'ma-feature-';

angular.module('service.feature-toggles', []).provider('featureToggles', [featureTogglesProvider]);

function featureTogglesProvider() {
  /* jshint validthis: true */

  var featureToggles = {
    adaptiveViews: 'adaptiveViews',
    householding: 'householding',
    goalSummary: 'goalSummary',
  };
  var globalConfig = window.config.env.featureToggles;
  var $cookies = angular.injector(['ngCookies']).get('$cookies');

  var validateFeatureToggle = function (feature) {
    if (!featureToggles[feature]) {
      // eslint-disable-next-line no-throw-literal
      throw 'Invalid feature toggle: ' + feature;
    }
  };

  var _isGloballyEnabled = function (feature) {
    return globalConfig && globalConfig[feature];
  };

  var _isCookieEnabled = function (feature) {
    return !!$cookies.get(COOKIE_PREFIX + feature);
  };

  var _enableCookie = function (feature) {
    $cookies.put(COOKIE_PREFIX + feature, 1);
  };

  var _disableCookie = function (feature) {
    $cookies.remove(COOKIE_PREFIX + feature);
  };

  this.isEnabled = function (feature) {
    validateFeatureToggle(feature);
    return _isGloballyEnabled(feature) || _isCookieEnabled(feature);
  };

  var _this = this;
  this.$get = function () {
    return {
      isEnabled: _this.isEnabled,
      processUrlToggle: function (toggleInfo, $location) {
        var feature = toggleInfo.feature;
        validateFeatureToggle(feature);

        if (_isGloballyEnabled(feature)) {
          // no need for cookie if it's already enabled
          return;
        }

        if (toggleInfo.enabled) {
          _enableCookie(feature);
        } else {
          _disableCookie(feature);
        }

        // reload the page, so the app initializes with feature toggle turned on
        window.location.href = $location.url();
      },
    };
  };
}
