'use strict';

angular
  .module('service.securityService', [])
  .factory('securityServiceFactory', ['config', securityServiceFactory]);

function securityServiceFactory(config) {
  var SecurityService = function (user) {
    this.user = user;

    var _this = this;
    this.permissions = {
      advisor: function () {
        return _this.isAdvisor();
      },
      viewAdvisorDashboard: function () {
        return _this.isAdvisor();
      },
      viewSuperAdvisorDocuments: function () {
        return _this.isSuperAdvisor();
      },
      viewFees: function () {
        return _this.isInternalClient();
      },
      externalClient: function () {
        return _this.isExternalClient();
      },
      internalClient: function () {
        return _this.isInternalClient();
      },
      digitalClient: function () {
        return _this.isDigitalClient();
      },
      personalClient: function () {
        return _this.isPersonalClient();
      },
      superAdvisor: function () {
        return _this.isSuperAdvisor();
      },
      notSuperAdvisorButExternalAdvisor: function () {
        return _this.isAdvisor() && !_this.isSuperAdvisor();
      },
      client: function () {
        return _this.isClient();
      },
      prefillClient: () => _this.canPrefillClient(),
      visiblePortfolioOption: () => _this.canSeePortfolioOption(),
      editPortfolioOption: () => _this.canEditPortfolioOption(),
      readOnlyPortfolioOption: () => !_this.canEditPortfolioOption(),
    };
  };

  SecurityService.prototype.isAdvisor = function () {
    if (!this.user) {
      return false;
    }
    var roleName = this.user.roleName();
    return roleName === 'advisor' || roleName === 'super_advisor';
  };

  SecurityService.prototype.isSuperAdvisor = function () {
    if (!this.user) {
      return false;
    }
    return this.user.roleName() === 'super_advisor';
  };

  SecurityService.prototype.isClient = function () {
    if (!this.user) {
      return false;
    }
    return this.user.isClient();
  };

  SecurityService.prototype.isExternalClient = function () {
    if (!this.user) {
      return false;
    }
    return this.user.isExternalClient();
  };

  SecurityService.prototype.isInternalClient = function () {
    if (!this.user) {
      return false;
    }
    return !this.user.isExternalClient();
  };

  SecurityService.prototype.isDigitalClient = function () {
    if (!this.user) {
      return false;
    }
    return this.user.isClient() && this.user.isDigital();
  };

  SecurityService.prototype.isPersonalClient = function () {
    if (!this.user) {
      return false;
    }
    return this.user.isClient() && this.user.isPersonal();
  };

  SecurityService.prototype.roleName = function () {
    if (!this.user) {
      return null;
    }
    return s.camelize(this.user.roleName());
  };

  SecurityService.prototype.can = function (action, resource) {
    if (action in this.permissions) {
      return this.permissions[action](resource);
    }

    // With this change, the `authorizer` in app/assets/javascripts/routes.js:142 will work transparently with the new roles.
    // If one wanted to move away from isSuperAdvisor type roles and to more granular, per activity and per function roles,
    // then all you have to do is search for the checkPermission('superAdvisor') and check for one of your new roles instead.
    // Note: action needs to be camelized whereas role name does not.
    const rolesThatCanAccess = config.env.featureAccess[s.camelize(action)];
    return this.user && rolesThatCanAccess && rolesThatCanAccess.includes(this.user.roleName());
  };

  SecurityService.prototype.canAccess = function (...actions) {
    return actions.some((action) => this.can(action));
  };

  SecurityService.prototype.canPrefillClient = function () {
    if (!this.user) {
      return false;
    }
    return this.user.canPrefillClient();
  };

  SecurityService.prototype.canSeePortfolioOption = function () {
    if (!this.user) {
      return false;
    }
    return this.user.canSeePortfolioOption();
  };

  SecurityService.prototype.canEditPortfolioOption = function () {
    if (!this.user) {
      return false;
    }
    return this.user.canEditPortfolioOption();
  };

  return {
    create: function (user) {
      return new SecurityService(user);
    },
  };
}
