'use strict';

function accountTransferSecurityFactory(ram, config) {
  var AccountTransferSecurity = new ram.Collection('AccountTransferSecurity', {
    belongsTo: {
      accountTransfer: 'AccountTransfer',
    },
    resources: {
      default: new ram.resources.Http('/api/account_transfer_securities/:id.json'),
      cookie: new ram.resources.Cookie('account_transfer_securities'),
    },
    schema: config.schemas.AccountTransferSecurity,
  });

  return AccountTransferSecurity;
}

angular
  .module('model.AccountTransferSecurity', ['model.AccountTransfer'])
  .factory('AccountTransferSecurity', ['ram', 'config', accountTransferSecurityFactory]);
