'use strict';

angular
  .module('component.trusted-contact-status', [
    'service.dropdown-options',
    'component.trusted-contact-tooltip',
    'model.TrustedContact',
  ])
  .component('trustedContactStatus', {
    templateUrl: 'components/trusted-contact-status.html',
    bindings: {
      client: '<',
    },
    controller: [
      '$scope',
      '$http',
      'TrustedContact',
      'dropdownOptions',
      TrustedContactStatusController,
    ],
    controllerAs: '$ctrl',
  });

function TrustedContactStatusController($scope, $http, TrustedContact, dropdownOptions) {
  $scope.yesOrNo = dropdownOptions.yesOrNo();

  $scope.clientIsInPerson = this.client === $scope.$root.currentUser.person(); // "in person" vs "advisor doing it for them"
  $scope.clientId = this.client.id;

  if (!this.client.trustedContact()) {
    $scope.trustedContact = new TrustedContact({
      clientId: this.client.id,
    });
  } else {
    $scope.trustedContact = this.client.trustedContact();
  }

  $scope.onChangeTrustedContact = () => {
    if (!$scope.trustedContact.designated()) {
      // Setting these here is pivotal to making the behaviour in app/assets/javascripts/components/trusted-contact-edit.controller.js
      // work.  You have to clear out this data here, so it is cleared in the cache.
      $scope.trustedContact.contact()?.setFieldsEmpty();
      $scope.trustedContact.contact()?.phoneNumbers()?.[0]?.setFieldsEmpty(); // jshint ignore:line
    }
    $scope.trustedContact.save().then((trustedContact) => {
      $scope.trustedContact.id = trustedContact.id;
      $scope.$root.$broadcast('trusted-contact-changed');
    });
  };
}
