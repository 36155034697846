'use strict';

angular
  .module('model.FeeCreditAccount', ['ram', 'model.FeeCreditTransaction', 'model.Person'])
  .factory('FeeCreditAccount', ['$http', 'ram', 'config', feeCreditAccountFactory]);

function feeCreditAccountFactory($http, ram, config) {
  var FeeCreditAccount = new ram.Collection('FeeCreditAccount', {
    belongsTo: {
      person: 'Person',
    },
    bind: ['grantCredit'],
    hasMany: {
      feeCreditTransactions: 'FeeCreditTransaction',
    },
    resources: {
      default: new ram.resources.Http('/api/fee_credit_accounts/:id.json'),
      cookie: new ram.resources.Cookie('fee_credit_accounts'),
    },
    schema: config.schemas.FeeCreditAccount,
  });

  FeeCreditAccount.prototype.grantCredit = function (feeCreditObject) {
    return $http.put('/api/fee_credit_accounts/' + this.id + '/grant_credit.json', {
      feeCreditObject,
    });
  };

  return FeeCreditAccount;
}
