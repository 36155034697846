'use strict';
import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';

const AccountSelector = forwardRef((props, ref) => {
  const [accountId, setAccountId] = useState(null);
  const [clientUserId, setClientUserId] = useState(null);
  const [clientAccounts, setClientAccounts] = useState([]);

  const clientNamesByUserId = {};
  props.accounts.forEach((account) => (clientNamesByUserId[account.userId] = account.clientName));

  const clients = _.map(clientNamesByUserId, (clientName, userId) => `${userId} - ${clientName}`);

  const accountsByUserId = _.groupBy(props.accounts, (account) => account.userId);

  useImperativeHandle(ref, () => ({
    getAccountId: () => {
      return accountId;
    },
  }));

  useEffect(() => {
    if (clientUserId) {
      setClientAccounts(accountsByUserId[clientUserId]);
      setAccountId(accountsByUserId[clientUserId][0].accountId);
    }
  }, [clientUserId]);

  useEffect(() => {
    props.onAccountIdChange(accountId);
  }, [accountId]);

  const updateCurrentUserId = (userId) => {
    if (!clientNamesByUserId[userId]) {
      return;
    }
    setClientUserId(userId);
  };

  const onAutoCompleteSelect = (_event, ui) => {
    if (ui.item) {
      updateCurrentUserId(ui.item.value.match(/(\d*) - .*/)[1]);
    }
  };

  var codesInitialized = false;

  const initAutocomplete = (event) => {
    if (!codesInitialized) {
      $(event.target).autocomplete({
        appendTo: '#autocomplete-menu',
        delay: 100,
        minLength: 2,
        source: clients,
        select: onAutoCompleteSelect,
      });
    }

    codesInitialized = true;
  };

  return (
    <>
      <form
        name="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="clearfix" />
        <div>
          <h4>Account</h4>
          <div className="col-xs-6 m-b-20">
            <label className="field-label m-0-impt">Client Name</label>
            <input
              type="text"
              className="form-control"
              name="client-name"
              placeholder="Input client's name"
              onFocus={initAutocomplete}
              onChange={(e) => {
                updateCurrentUserId(e.target.value);
              }}
            ></input>
            <div id="autocomplete-menu"></div>
          </div>
          {clientAccounts.length > 0 && (
            <div className="col-xs-6 m-b-20">
              <label className="field-label m-0-impt">Account</label>
              <select
                value={accountId}
                onChange={(e) => {
                  setAccountId(e.target.value);
                }}
                className="form-control"
              >
                {clientAccounts.map((account, index) => {
                  return (
                    <option key={index} value={account.accountId}>
                      {props.config.types.Account[account.typeId].label} - {account.accountNumber}{' '}
                      ($
                      {Number(account.balance).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      )
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
      </form>
    </>
  );
});

export default React.memo(AccountSelector);
