'use strict';

import TrustedContactEditController from './trusted-contact-edit.controller';

angular
  .module('component.trusted-contact-edit', [
    'ram',
    'model.Person',
    'model.PhoneNumber',
    'model.TrustedContact',
    'service.patterns',
  ])
  .component('trustedContactEdit', {
    templateUrl: 'components/trusted-contact-edit.html',
    bindings: {
      client: '=',
      form: '<',
    },
    controller: [
      '$scope',
      '$http',
      'ram',
      'config',
      'patterns',
      'Person',
      'PhoneNumber',
      'TrustedContact',
      TrustedContactEditController,
    ],
    controllerAs: '$ctrl',
  });
