'use strict';

angular
  .module('controller.marketing', [
    'controller.marketing.research',
    'controller.marketing.rrsp',
    'controller.marketing.rrsp-info',
    'controller.marketing.rrsp-campaign',
    'controller.marketing.spring',
    'controller.marketing.robo-advisor',
  ])
  .controller('MarketingCtrl', [marketingController]);

function marketingController() {}
