'use strict';

angular
  .module('model.Promotion', ['ram', 'config', 'model.PromotionRedemption'])
  .factory('Promotion', ['$http', 'ram', 'config', promotionFactory]);

function promotionFactory($http, ram, config) {
  var Promotion = new ram.Collection('Promotion', {
    hasMany: {
      promotionRedemptions: 'PromotionRedemption',
    },
    enums: {
      status: config.types.PromotionStatus,
    },
    resources: {
      default: new ram.resources.Http('/api/promotions/:id.json'),
      cookie: new ram.resources.Cookie('promotions'),
    },
    schema: config.schemas.Promotion,
  });

  Promotion.promotionInfo = function (affiliateLink) {
    return $http
      .get('/api/promotion_info.json?code=' + affiliateLink)
      .then(function (response) {
        return response.data;
      })
      .catch(function () {
        return null;
      });
  };

  Promotion.prototype.expire = function () {
    const self = this;

    return $http
      .put('/api/super_advisor/promotions/' + this.id + '/expiration.json')
      .then(function () {
        return self.reload({
          force: true,
        });
      });
  };

  return Promotion;
}
