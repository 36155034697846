'use strict';

class AllocationOrPositionColourMapperAndSorter {
  constructor(Fund, recolorFundsService) {
    this.Fund = Fund;
    this.recolorFundsService = recolorFundsService;
  }

  getColourMappedAndSortedAllocations(allocations) {
    return this._prepareAllocationsOrPositions(allocations);
  }

  getColourMappedAndSortedPositions(positions, modelPortfolio) {
    return this._prepareAllocationsOrPositions(positions, modelPortfolio);
  }

  _prepareAllocationsOrPositions(allocationsOrPositions, modelPortfolio) {
    var sortedStocks = this._filterAndSortByAssetClassCategory(
      allocationsOrPositions,
      'stock',
      modelPortfolio
    );
    var sortedFixedIncome = this._filterAndSortByAssetClassCategory(
      allocationsOrPositions,
      'fixedIncome',
      modelPortfolio
    );
    var sortedCash = this._filterAndSortByAssetClassCategory(allocationsOrPositions, 'cash');
    var sortedOther = this._filterAndSortByAssetClassCategory(allocationsOrPositions, 'otherFunds');
    var sortedAllocationsOrPostions = sortedStocks.concat(
      sortedFixedIncome,
      sortedCash,
      sortedOther
    );
    return this.recolorFundsService.recolorFunds(sortedAllocationsOrPostions);
  }

  _compareFundAssetClassIds(allocationOrPositionA, allocationOrPositionB) {
    const fundAssetClassIdA = allocationOrPositionA.fund().assetClassId();
    const fundAssetClassIdB = allocationOrPositionB.fund().assetClassId();
    const cashAssetClassId = 8;

    if (fundAssetClassIdA === cashAssetClassId) {
      // Cash is always sorted last in the cash category
      return 1;
    } else if (fundAssetClassIdB === cashAssetClassId) {
      // Cash is always sorted last in the cash category
      return -1;
    } else {
      return fundAssetClassIdA - fundAssetClassIdB;
    }
  }

  _sortByModelPortfolio(allocationsOrPositions, modelPortfolio) {
    const modelPortfolioFundIds = modelPortfolio
      .allocations()
      .map((allocation) => allocation.fundId());

    return allocationsOrPositions.sort((allocationOrPositionA, allocationOrPositionB) => {
      const fundIndexA = modelPortfolioFundIds.findIndex(
        (fundId) => fundId === allocationOrPositionA.fundId()
      );
      const fundIndexB = modelPortfolioFundIds.findIndex(
        (fundId) => fundId === allocationOrPositionB.fundId()
      );

      if (fundIndexA === -1 && fundIndexB === -1) {
        // If both funds are not in the model portfolio, don't sort them
        return 0;
      } else if (fundIndexA === -1) {
        // Sort funds not included in model portfolio to be last in category
        return 1;
      } else if (fundIndexB === -1) {
        // Sort funds not included in model portfolio to be last in category
        return -1;
      } else if (fundIndexA > fundIndexB) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  _filterAndSortByAssetClassCategory(allocationsOrPositions, category, modelPortfolio) {
    if (modelPortfolio) {
      allocationsOrPositions = this._sortByModelPortfolio(allocationsOrPositions, modelPortfolio);
    }

    return allocationsOrPositions
      .filter((allocationOrPosition) =>
        this.Fund.constructor.assetClassIdsByColourCategory[category].includes(
          allocationOrPosition.fund().assetClassId()
        )
      )
      .sort(this._compareFundAssetClassIds);
  }
}

AllocationOrPositionColourMapperAndSorter.$inject = ['Fund', 'recolorFundsService'];

export default AllocationOrPositionColourMapperAndSorter;
