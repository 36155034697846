'use strict';

angular
  .module('directive.input.phone-number-international', [])
  .directive('phoneNumberInternational', [
    function () {
      return {
        replace: true,
        restrict: 'E',
        scope: true,
        require: 'ngModel',
        templateUrl: 'directives/input/phone-number-international.html',

        link: function (scope, element) {
          $(element).mask('(000) 000-0000');

          scope.phoneNumberPattern = (function () {
            return {
              test: function () {
                return scope.phoneNumber.validate();
              },
            };
          })();

          scope.onPhoneNumberChange = function (phoneNumber) {
            if (scope.optional && phoneNumber.isEmpty()) {
              // isEmpty looks at `full`
              phoneNumber.setFieldsEmpty(); // empties db fields
              scope.onChange(phoneNumber);
              return;
            }

            if (phoneNumber.validate()) {
              phoneNumber.parseFull();
              scope.onChange(phoneNumber);
            }
          };
        },
      };
    },
  ]);
