'use strict';

/**
 * Controller for the preview view.
 */

angular
  .module('controller.preview', [
    'model.PortfolioManager',
    'component.investment-charts.model-portfolio-pie-chart',
    'component.preferred-portfolio-manager-subform',
    'controller.preview.fees',
    'controller.preview.investments',
    'controller.preview.performance',
    'controller.preview.projections',
    'config',
    'directive.input',
    'ui.bootstrap',
    'service.accountCreator',
    'service.account-service',
    'service.check-incomplete-accounts-service',
    'service.goal-service',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investments-bar-prep-data',
    'service.optimizely',
    'service.securityService',
    'service.risk-level-business-rules',
    'ram',
  ])
  .controller('PreviewCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$cookies',
    '$http',
    'config',
    'ima',
    'ram',
    'PortfolioManager',
    'modelPortfolio',
    'questions',
    'storedAccount',
    'storedUser',
    'storedGoal',
    'currentUser',
    'accountCreator',
    'accountService',
    'optimizely',
    'invBarPrepData',
    'advisorGroupUrl',
    'checkIncompleteAccountsService',
    'goalService',
    'investmentChartsAssetAllocationDataGenerator',
    'securityServiceFactory',
    'advisorReferralDetails',
    'defaultPortfolioManagerId',
    'portfolioManagers',
    'portfolioOptions',
    'riskLevelBusinessRules',
    'recommendedPortfolioOption',
    'chosenPortfolioPreferenceAnswer',
    previewController,
  ]);

function previewController(
  $rootScope,
  $scope,
  $state,
  $window,
  $cookies,
  $http,
  config,
  ima,
  ram,
  PortfolioManager,
  modelPortfolio,
  questions,
  account,
  user,
  goal,
  currentUser,
  accountCreator,
  accountService,
  optimizely,
  invBarPrepData,
  advisorGroupUrl,
  checkIncompleteAccountsService,
  goalService,
  investmentChartsAssetAllocationDataGenerator,
  securityServiceFactory,
  advisorReferralDetails,
  defaultPortfolioManagerId,
  portfolioManagers,
  portfolioOptions,
  riskLevelBusinessRules,
  recommendedPortfolioOptionId,
  chosenPortfolioPreferenceAnswer
) {
  let savedPortfolioId;

  $rootScope.branding = advisorReferralDetails.branding;
  $scope.optimizelyVariationName = optimizely.activatePreviewTestAndGetVariationName();

  $scope.user = user;
  $scope.goal = goal;
  $scope.account = account;
  $scope.portfolioManagers = portfolioManagers;
  $scope.preferredPortfolioManagerId = new ram.Accessor(defaultPortfolioManagerId);
  $scope.preferredPortfolioManager = new ram.Accessor(
    portfolioManagers.find((pm) => pm.id === $scope.preferredPortfolioManagerId())
  );
  $scope.accountPortfolioOptions = portfolioOptions.filter(
    (item) => item.name !== 'guardian_core' && item.name !== 'guardian_sustainable'
  );
  $scope.riskLevelBusinessRules = riskLevelBusinessRules;
  $scope.chosenPortfolioPreferenceAnswer = chosenPortfolioPreferenceAnswer;

  $scope.isHISA =
    goal.label() === 'Save for a Rainy Day' || $scope.goal.label() === 'Save for Short-term';

  const modernAdvisorPmId = PortfolioManager.constructor.modernAdvisorId();
  $scope.displayProjections = () =>
    $scope.preferredPortfolioManagerId() === modernAdvisorPmId &&
    $scope.riskLevelBusinessRules.displayRiskChangeFor(account);

  // For checking if it is possible to generate the graph on page, if invalid go to /interview/goal
  // Only a temporary fix. Should be changed to check in the route.
  if (!account.type() || !goal.risk()) {
    $window.location = $scope.$root.config.env.marketingUrl;
  }

  $scope.questions = _.indexBy(questions, function (question) {
    return question.accessor();
  });

  // Jan 23, 2023 Fees tab is hidden for everyone at request of Navid.
  $scope.showFees = false;

  $scope.onChange = function (newId) {
    if ($state.current.name === 'preview.projections') {
      $state.go('preview.investments');
    }
    $scope.preferredPortfolioManagerId(newId);
    $scope.preferredPortfolioManager(
      portfolioManagers.find((pm) => pm.id === $scope.preferredPortfolioManagerId())
    );
    $scope.accountPortfolioOptions = _loadPortfolioOptions(newId).then((portfolioOptions) => {
      $scope.accountPortfolioOptions = portfolioOptions;
      _setRecommendedPortfolioOption();
      _updatePortfolioWorker();
    });
  };

  function _loadPortfolioOptions(pmId) {
    const userIdPortion = _.isNullOrUndefined(currentUser) ? '' : currentUser.id;
    return $http
      .get(`/api/portfolio_managers/${pmId}/portfolio_options.json?user_id=${userIdPortion}`)
      .then((result) => result.data?.portfolioOptions);
  }

  /****************************************
   * Mobile code
   */
  $scope.projection = {
    initial: new ram.Accessor(),
    target: new ram.Accessor(),
    monthlyContribution: new ram.Accessor(),
  };
  /* End of mobile code **********************/

  var _prepareGraph = function (portfolio) {
    if (!portfolio) {
      return;
    }

    var chartData = investmentChartsAssetAllocationDataGenerator.getData(
      portfolio.nonZeroAllocations()
    );

    $scope.allocationDataForPieChart = _.map(chartData, function (dataRow) {
      return {
        color: dataRow.color,
        label: dataRow.allocation.fund().assetClass().label,
        value: dataRow.allocation.weight(),
      };
    });

    $scope.allocationDataForChartLegend = _.map(chartData, function (dataRow) {
      return {
        color: dataRow.color,
        assetClass: dataRow.allocation.fund().assetClass().label,
        ticker: dataRow.allocation.fund().symbol(),
        weight: dataRow.allocation.weight(),
      };
    });

    var allocationsForBarChart = _.map(chartData, function (chartDataRow) {
      return chartDataRow.allocation;
    });

    var invChartDataObject = invBarPrepData.getChartDataForAllocations(allocationsForBarChart);
    if (invChartDataObject) {
      $scope.toBeStacked = invChartDataObject.toBeStacked;
      $scope.investmentsChartData = invChartDataObject.investmentsChartData;
    }
  };

  $scope.portfolio = modelPortfolio;
  savedPortfolioId = $scope.portfolio.id;
  _prepareGraph($scope.portfolio);

  /* // PM_todo - This is only used in preview-variation-one.html, thus is never used now.
    $scope.amountAtEndOfTimeframe = function() {
      var projection = _.findWhere($scope.portfolio.projections(), {
        percentile: 50
      });
      var simulatedProjection = projection.simulate($scope.goal.initial(), $scope.goal.ongoing());
      var startDate = moment(_.first(simulatedProjection.values).date);
      var dateAtEndOfTimeframe = startDate.add(goal.timeframe(), 'years');
      return _.find(simulatedProjection.values, function(simulatedBalance) {
        return simulatedBalance.date.getTime() === dateAtEndOfTimeframe.toDate().getTime();
      }).balance();
    }; */

  $scope.$watch('goal.riskRecommendation()', function () {
    $scope.goal.save();
  });
  $scope.$watch('goal.riskOffset()', function () {
    $scope.goal.save();
  });

  function _updatePortfolioWorker() {
    goalService
      .fetchModelPortfolio($scope.goal, $scope.account, $scope.accountPortfolioOptions[0])
      .then(function (modelPortfolio) {
        $scope.portfolio = modelPortfolio;
        savedPortfolioId = modelPortfolio.id;
        _prepareGraph(modelPortfolio);
      });
  }

  function _setRecommendedPortfolioOption() {
    $scope.account.portfolioOptionId(recommendedPortfolioOptionId);
    $scope.account.save();
  }

  function showMinimumInvestment() {
    for (const item of $scope.accountPortfolioOptions) {
      if (item.id === account.portfolioOptionId()) {
        $scope.minimumInvestmentText = `Minimum initial investment: $${item.minimumInvestment.toLocaleString()}`;
      }
    }
  }

  $scope.noRecommendationPortfolioOption = $scope.chosenPortfolioOption === 3;

  $scope.showRecommendedPortfolio = function () {
    for (const item of $scope.accountPortfolioOptions) {
      if (item.id === recommendedPortfolioOptionId && !$scope.noRecommendationPortfolioOption) {
        item.label += ' (Recommended)';
        break;
      }
    }
    return $scope.accountPortfolioOptions;
  };

  $scope.showRecommendedPortfolio();

  /**
   * Watch for changes that affect portfolio selection.
   */
  $scope.$watchGroup(['goal.risk()', 'account.portfolioOptionId()'], (newValue) => {
    if (_isUndefinedOrNull(newValue[0]) || _isUndefinedOrNull(newValue[1])) {
      return;
    }
    _updatePortfolioWorker();
  });
  $scope.$watch('account.portfolioOptionId()', (newValue) => {
    if (_isUndefinedOrNull(newValue)) {
      return;
    }
    showMinimumInvestment();
    $scope.recommendedPortfolio();
  });

  $scope.recommendedPortfolio = function () {
    $scope.accountPortfolioOptionsCopy = angular.copy($scope.accountPortfolioOptions);
    const regex = /\s*\(\s*Recommended\s*\)\s*/;
    for (const portfolio of $scope.accountPortfolioOptionsCopy) {
      if (portfolio.id === recommendedPortfolioOptionId) {
        portfolio.label = portfolio.label.replace(regex, '');
        return portfolio;
      }
    }
  };

  function onPreviewConfirmed() {
    if (!$scope.currentUser) {
      return $state.go('interview.account-credentials');
    }

    accountCreator
      .addAccount($scope, $scope.currentUser, $scope.account)
      .then(function () {
        // refresh user's accounts
        return $scope.currentUser.accounts.ready();
      })
      .then(function () {
        $scope.account = null;
      });
  }

  /** For the new onboarding demo, next will jump straight into email and password for trial accounts, or
   * continue with onboarding by launching the goalReviewModal.
   **/
  $scope.nextButton = function () {
    optimizely.trackEvent('clickedNextInPreview');

    if ($scope.account.type.is.trial()) {
      onPreviewConfirmed();
    } else {
      $scope
        .goalReviewModal(
          $scope.account,
          $scope.goal,
          savedPortfolioId, // $scope.portfolio is getting changed by another callback after this page is rendered.  This local
          // variable shadows the setting and is used to pass the portfolio into the review modal.
          $scope.user,
          $scope.questions,
          $scope.toBeStacked,
          $scope.investmentsChartData,
          $scope.recommendedPortfolio
        )
        .result.then(function (result) {
          if (result) {
            onPreviewConfirmed();
          }
        });
    }
  };

  checkIncompleteAccountsService.call($scope, $state);

  _setRecommendedPortfolioOption();

  function _isUndefinedOrNull(value) {
    return value === undefined || value === null;
  }
}
