'use strict';

angular
  .module('controller.advisor-new-account.reject', [])
  .controller('AdvisorNewAccountRejectController', [
    '$scope',
    '$state',
    'accounts',
    'incompleteAccountFlows',
    advisorNewAccountRejectController,
  ]);

function advisorNewAccountRejectController($scope, $state, accounts, incompleteAccountFlows) {
  var successButtons = {
    signOut: 'Sign out',
    goToDashboard: 'Go to Dashboard',
    addYourNextAccount: 'Add your next account',
  };

  $scope.showAddAccountButton = false;

  if (accounts.length > 0) {
    $scope.successButton = successButtons.goToDashboard;
    $scope.successButtonHandler = _goToDashboard;
  } else if (incompleteAccountFlows.length > 0) {
    $scope.successButton = successButtons.addYourNextAccount;
    $scope.successButtonHandler = _addYourNextAccount;
  } else {
    $scope.successButton = successButtons.signOut;
    $scope.successButtonHandler = $scope.logout;

    $scope.showAddAccountButton = true;
  }

  function _goToDashboard() {
    $state.go('dashboard.summary');
  }

  function _addYourNextAccount() {
    $state.go('advisor-new-account.view', {
      advisorNewAccountFlowId: _.first(incompleteAccountFlows).id,
    });
  }

  $scope.addAccount = function () {
    $state.go('interview.goal');
  };
}
