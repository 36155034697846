'use strict';

angular
  .module('controller.wsi-interview.preview', [
    'component.investment-charts.model-portfolio-pie-chart',
    'controller.preview.fees',
    'controller.preview.investments',
    'controller.preview.performance',
    'controller.preview.projections',
    'config',
    'directive.input',
    'ui.bootstrap',
    'service.accountCreator',
    'service.account-service',
    'service.goal-service',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investments-bar-prep-data',
    'service.securityService',
    'service.wsi.risk-calculator',
    'ram',
    'service.risk-level-business-rules',
  ])
  .controller('WsiInterviewPreviewCtrl', [
    '$scope',
    '$state',
    '$window',
    'config',
    'ram',
    'modelPortfolio',
    'questions',
    'riskAbility',
    'accountCreator',
    'invBarPrepData',
    'advisorGroupUrl',
    'goalService',
    'investmentChartsAssetAllocationDataGenerator',
    'wsiRiskCalculator',
    'securityServiceFactory',
    'unconstrainedRecommendedRiskLevel',
    'riskLevelBusinessRules',
    controller,
  ]);

function controller(
  $scope,
  $state,
  $window,
  config,
  ram,
  modelPortfolio,
  questions,
  riskAbility,
  accountCreator,
  invBarPrepData,
  advisorGroupUrl,
  goalService,
  investmentChartsAssetAllocationDataGenerator,
  wsiRiskCalculator,
  securityServiceFactory,
  unconstrainedRecommendedRiskLevel,
  riskLevelBusinessRules
) {
  var savedPortfolioId;
  // For checking if it is possible to generate the graph on page, if invalid go to /interview/goal
  // Only a temporary fix. Should be changed to check in the route.
  if (!$scope.account.type() || !$scope.goal.risk()) {
    $window.location = $scope.$root.config.env.marketingUrl;
  }

  $scope.questions = _.indexBy(questions, function (question) {
    return question.accessor();
  });

  if ($scope.currentUser) {
    var currentUserSecurity = securityServiceFactory.create($scope.currentUser);
    $scope.showFees =
      currentUserSecurity.isInternalClient() && currentUserSecurity.isDigitalClient();
  } else {
    $scope.showFees = !advisorGroupUrl;
  }

  $scope.accountPortfolioOptions = _.reject(
    config.types.AccountPortfolioOption,
    function (accountPortfolioOption) {
      return accountPortfolioOption.name === 'adaptive_portfolio';
    }
  );

  $scope.riskLevelBusinessRules = riskLevelBusinessRules;

  /****************************************
   * Mobile code
   */
  $scope.projection = {
    initial: new ram.Accessor(),
    target: new ram.Accessor(),
    monthlyContribution: new ram.Accessor(),
  };
  /* End of mobile code **********************/

  var _prepareGraph = function (portfolio) {
    if (!portfolio) {
      return;
    }

    var chartData = investmentChartsAssetAllocationDataGenerator.getData(
      portfolio.nonZeroAllocations()
    );

    $scope.allocationDataForPieChart = _.map(chartData, function (dataRow) {
      return {
        color: dataRow.color,
        label: dataRow.allocation.fund().assetClass().label,
        value: dataRow.allocation.weight(),
      };
    });

    $scope.allocationDataForChartLegend = _.map(chartData, function (dataRow) {
      return {
        color: dataRow.color,
        assetClass: dataRow.allocation.fund().assetClass().label,
        ticker: dataRow.allocation.fund().symbol(),
        weight: dataRow.allocation.weight(),
      };
    });

    var allocationsForBarChart = _.map(chartData, function (chartDataRow) {
      return chartDataRow.allocation;
    });

    var invChartDataObject = invBarPrepData.getChartDataForAllocations(allocationsForBarChart);
    if (invChartDataObject) {
      $scope.toBeStacked = invChartDataObject.toBeStacked;
      $scope.investmentsChartData = invChartDataObject.investmentsChartData;
    }
  };

  $scope.portfolio = modelPortfolio;
  savedPortfolioId = $scope.portfolio.id;
  _prepareGraph($scope.portfolio);

  $scope.amountAtEndOfTimeframe = function () {
    var projection = _.findWhere($scope.portfolio.projections(), {
      percentile: 50,
    });
    var simulatedProjection = projection.simulate($scope.goal.initial(), $scope.goal.ongoing());
    var startDate = moment(_.first(simulatedProjection.values).date);
    var dateAtEndOfTimeframe = startDate.add($scope.goal.timeframe(), 'years');
    return _.find(simulatedProjection.values, function (simulatedBalance) {
      return simulatedBalance.date.getTime() === dateAtEndOfTimeframe.toDate().getTime();
    }).balance();
  };

  $scope.$watch('goal.riskRecommendation()', function () {
    $scope.goal.save();
  });
  $scope.$watch('goal.riskOffset()', function () {
    $scope.goal.save();
  });

  function updatePortfolio(newValues, oldValues) {
    if (_.isEqual(newValues, oldValues) || !$scope.account || !$scope.goal) {
      return;
    }

    goalService.fetchModelPortfolio($scope.goal, $scope.account).then(function (modelPortfolio) {
      $scope.portfolio = modelPortfolio;
      savedPortfolioId = modelPortfolio.id;
      _prepareGraph(modelPortfolio);
    });
  }

  /**
   * Watch for changes that affect portfolio selection.
   */
  $scope.$watchGroup(['goal.risk()', 'account.portfolioOptionId()'], updatePortfolio);

  function onPreviewConfirmed() {
    if (!$scope.currentUser) {
      return $state.go('wsi-interview.account-credentials');
    }

    accountCreator
      .addAccount($scope, $scope.currentUser, $scope.account)
      .then(function () {
        // refresh user's accounts
        return $scope.currentUser.accounts.ready();
      })
      .then(function () {
        $scope.account = null;
      });
  }

  function hasReturnObjectiveConflict() {
    return wsiRiskCalculator.hasConflict(
      $scope.account.returnObjective(),
      unconstrainedRecommendedRiskLevel
    );
  }

  /** For the new onboarding demo, next will jump straight into email and password for trial accounts, or
   * continue with onboarding by launching the goalReviewModal.
   **/
  $scope.$parent.nextSection = function () {
    if ($scope.account.type.is.trial()) {
      onPreviewConfirmed();
    } else {
      $scope
        .wsiGoalReviewModal(
          $scope.account,
          $scope.goal,
          savedPortfolioId, // $scope.portfolio is getting changed by another callback after this page is rendered.  This local
          // variable shadows the setting and is used to pass the portfolio into the review modal.
          $scope.user,
          $scope.questions,
          $scope.toBeStacked,
          $scope.investmentsChartData,
          riskAbility
        )
        .result.then(function (result) {
          if (result) {
            onPreviewConfirmed();
          }
        });
    }
  };
  $scope.$parent.lastSection = function () {
    if ($scope.account.returnObjective() && hasReturnObjectiveConflict()) {
      $state.go($state.current.data.last);
    } else {
      $state.go('wsi-interview.risk');
    }
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;
}
