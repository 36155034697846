'use strict';

angular
  .module('service.user-notifications', ['ngSanitize', 'service.adaptive-views'])
  .factory('userNotifications', [
    '$rootScope',
    '$compile',
    '$templateRequest',
    '$timeout',
    'adaptiveViews',
    userNotificationsFactory,
  ]);

function userNotificationsFactory($rootScope, $compile, $templateRequest, $timeout, adaptiveViews) {
  // TODO currently supports only one notification at a time
  var activeNotification = null;

  var createNotification = function (message, notificationType, transient) {
    if (activeNotification !== null && activeNotification.closeNotification) {
      activeNotification.closeNotification();
      activeNotification = null;
    }

    $templateRequest(
      adaptiveViews.getTemplateUrlForSrc('partials/user-notification.html', true)
    ).then(function (result) {
      var $scope = $rootScope.$new();
      activeNotification = $scope;

      $scope.contents = message;
      $scope.notificationType = notificationType;

      var element = $compile(result)($scope);
      var clearListener = $rootScope.$on('$stateChangeSuccess', function () {
        $scope.closeNotification();
      });

      $scope.closeNotification = function () {
        removeFromView(element);
        clearListener();
        $scope.$destroy();

        activeNotification = null;
      };

      if (transient) {
        $timeout($scope.closeNotification, 3000);
      }

      addToView(element);
    });
  };

  function addToView(element) {
    element.css('opacity', 0);
    if ($rootScope.isMobile) {
      $('body').append(element);
    } else {
      $('#dashboard').prepend(element);
      $('#profile-user-notification').prepend(element); // Place `#profile-user-notification` where you need it in the profile page you want to display the notification on.
      $('#user-notification').prepend(element);
    }
    element.animate(
      {
        opacity: 1,
      },
      300
    );
  }

  function removeFromView(element) {
    $(element).animate(
      {
        opacity: 0,
      },
      300,
      'linear',
      function () {
        element.remove();
      }
    );
  }

  return {
    clearNotification: function () {
      if (activeNotification) {
        activeNotification.closeNotification();
      }
    },
    showSuccess: function (message) {
      createNotification(message, 'success');
    },
    showTransientSuccess: function (message) {
      createNotification(message, 'success', true);
    },
    showInfo: function (message) {
      createNotification(message, 'info');
    },
    showWarning: function (message) {
      createNotification(message, 'warning');
    },
    showTransientWarning: function (message) {
      createNotification(message, 'warning', true);
    },
    showError: function (message) {
      createNotification(message, 'danger');
    },
    showTransientError: function (message) {
      createNotification(message, 'danger', true);
    },
  };
}
