'use strict';

angular
  .module('controller.mutual-fund-fees', ['directive.mutual-fund-fees-page'])
  .controller('MutualFundFeesCtrl', ['$scope', 'mutualFunds', controller]);

function controller($scope, mutualFunds) {
  $scope.mutualFunds = mutualFunds;

  $scope.headlineCallToAction = function () {
    var imageHeight = document.querySelector('.header_new.header_new__bg-image').clientHeight;
    window.scrollBy(0, imageHeight);
  };
}
