'use strict';

angular
  .module('model.BankAccount', ['ram', 'config', 'filter.masked-text'])
  .factory('BankAccount', ['$http', 'ram', 'config', '$filter', bankAccountFactory]);

function bankAccountFactory($http, ram, config, $filter) {
  var BankAccount = new ram.Collection('BankAccount', {
    belongsTo: {
      user: 'User',
    },
    enums: {
      status: config.types.BankAccountStatus,
      verificationMethod: config.types.VerificationMethod,
    },
    bind: ['label', 'descriptiveLabel'],
    resources: {
      default: new ram.resources.Http('/api/bank_accounts/:id.json'),
      cookie: new ram.resources.Cookie('bank_accounts'),
    },
    schema: config.schemas.BankAccount,
  });

  /* CONSTANTS */
  BankAccount.constructor.MAX_NUMBER_PER_CLIENT = 9;

  BankAccount.prototype.label = function () {
    if (this.status.is.draft()) {
      return 'New Bank Account';
    }
    return s.sprintf(
      '%s-%s-%s',
      this.transit(),
      this.bankCode(),
      $filter('maskedText')(this.number())
    );
  };

  BankAccount.prototype.clientLabel = function () {
    return this.label();
  };

  /*********************
   * BEGIN duck-type functions to make BankAccount polymorphic with Account in the TransferInstruction
   * and Transfer Request code.  Some return values are not used.  The function just exists to make the
   * same interface.
   */

  /*
   * @return {String} label
   */
  BankAccount.prototype.descriptiveLabel = function () {
    return this.label();
  };

  BankAccount.prototype.userIsHolder = function () {
    return false; // This return value is NOT needed or used.
  };

  BankAccount.prototype.clientFirstName = function () {
    return ''; // This return value is NOT needed or used.
  };
  /** ****************** END duck-type functions */

  /**
   * A short label for use in tables
   * @return {string} label
   */
  BankAccount.prototype.shortLabel = function () {
    return this.number();
  };

  BankAccount.prototype.resendAddForm = function () {
    var self = this;
    return $http.put('/api/bank_accounts/' + this.id + '/resend_add_form').then(function () {
      return self.reload({
        force: true,
      });
    });
  };

  BankAccount.prototype.delete = function () {
    var self = this;
    return $http.put('/api/bank_accounts/' + this.id + '/delete').then(function () {
      return self.reload({
        force: true,
      });
    });
  };

  BankAccount.prototype.verify = function () {
    var self = this;
    return $http.put('/api/bank_accounts/' + this.id + '/verify').then(function () {
      return self.reload({
        force: true,
      });
    });
  };

  BankAccount.prototype.unverify = function () {
    var self = this;
    return $http.put('/api/bank_accounts/' + this.id + '/unverify').then(function () {
      return self.reload({
        force: true,
      });
    });
  };

  BankAccount.prototype.finalize = function () {
    var self = this;
    return $http.put('/api/bank_accounts/' + this.id + '/finalize').then(function () {
      return self.reload({
        force: true,
      });
    });
  };

  BankAccount.prototype.editNumbersAndBankName = function (bankCode, transit, number, bankName) {
    var self = this;
    return $http
      .put('/api/bank_accounts/' + this.id + '/edit_numbers_and_bank_name', {
        bankCode,
        transit,
        number,
        bankName,
      })
      .then(function () {
        return self.reload({
          force: true,
        });
      });
  };

  return BankAccount;
}
