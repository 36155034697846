'use strict';

angular
  .module('controller.advise', [
    'controller.advise.overview',
    'controller.advise.reports',
    'controller.advise.run-fees',
    'controller.advise.account',
    'controller.advise.accounts',
    'controller.advise.rif-details',
    'controller.advise.bank-accounts',
    'controller.advise.add-client',
    'controller.advise.add-client-account',
    'controller.advise.bulk-client-upload',
    'controller.advise.bulk-client-upload-help',
    'controller.advise.new-accounts',
    'controller.advise.new-account-applications',
    'controller.advise.client',
    'controller.advise.clients',
    'controller.advise.client-application',
    'controller.advise.issue',
    'controller.advise.issues',
    'controller.advise.order',
    'controller.advise.orders',
    'controller.advise.transfer-instruction',
    'controller.advise.transfer-requests',
    'controller.advise.update-records',
    'controller.advise.promotions',
    'controller.advise.update-record',
    'controller.advise.referrals',
    'controller.advise.referral',
    'controller.advise.referral-payments',
    'controller.advise.transfer-client-account',
    'controller.advise.xirr',
    'controller.advise.client.eft',
    'controller.advise.person',
    'service.accountSwitch',
    'directive.advise.linkToRelated',
  ])
  .controller('AdviseCtrl', ['$scope', 'accountSwitch', adviseController]);

function adviseController($scope, accountSwitch) {
  // Branding is set in the site controller.
  var findNotification, findNotificationById;
  var _clearNotification;
  var _isMatchingNotification;

  $scope.clearNotification = function (relatedTo, currentUser) {
    _clearNotification(findNotification(relatedTo, currentUser), currentUser);
  };

  $scope.clearNotificationById = function (relatedToType, relatedToId, currentUser) {
    _clearNotification(findNotificationById(relatedToType, relatedToId, currentUser), currentUser);
  };

  $scope.switchToUser = function (newUser) {
    accountSwitch.switchToUser(newUser);
  };

  _clearNotification = function (notification, currentUser) {
    if (notification) {
      notification.destroy().then(function () {
        currentUser.notifications.ready();
      });
    }
  };

  findNotification = $scope.findNotification = function (relatedTo, currentUser) {
    if (!currentUser) {
      return null;
    }
    return _.find(currentUser.notifications(), function (notification) {
      return _isMatchingNotification(notification, relatedTo.constructor.name, relatedTo.id);
    });
  };

  findNotificationById = $scope.findNotificationById = function (
    relatedToType,
    relatedToId,
    currentUser
  ) {
    if (!currentUser) {
      return null;
    }
    return _.find(currentUser.notifications(), function (notification) {
      return _isMatchingNotification(notification, relatedToType, relatedToId);
    });
  };

  _isMatchingNotification = function (notification, relatedToType, relatedToId) {
    /**
     * @todo Find another way to detect the type. Internet Explorer doesn't
     *       support function name property.
     */
    return (
      notification.relatedToType() === relatedToType && notification.relatedToId() === relatedToId
    );
  };
}
