'use strict';

angular
  .module('controller.advise.client-application.review', [
    'ram',
    'directive.staticField',
    'service.form-helper',
    'service.user-notifications',
    'model.AdvisorCreateNewAccountFlow',
  ])
  .controller('AdviseClientApplicationReviewCtrl', [
    '$http',
    '$scope',
    '$state',
    '$rootScope',
    '$q',
    'AdvisorCreateNewAccountFlow',
    'config',
    'newAccountFlows',
    'newTransferFlows',
    'newTransferInstructionFlows',
    'relatedNames',
    'userNotifications',
    'bankAccounts',
    'transferAccountDataProvider',
    adviseClientApplicationReviewCtrl,
  ]);

function adviseClientApplicationReviewCtrl(
  $http,
  $scope,
  $state,
  $rootScope,
  $q,
  AdvisorCreateNewAccountFlow,
  config,
  newAccountFlows,
  newTransferFlows,
  newTransferInstructionFlows,
  relatedNames,
  userNotifications,
  bankAccounts,
  transferAccountDataProvider
) {
  angular.extend($scope, {
    newAccountFlows,
    newTransferFlows,
    newTransferInstructionFlows,
    relatedNames,
    goToAccount,
    goToTransfer,
    goToEFT,
    sendToClient,
  });
  transferAccountDataProvider.setInputs($scope.client, newAccountFlows, bankAccounts);

  function sendToClient() {
    if (!atLeastMinAccounts()) {
      return userNotifications.showError(
        'Please add at least one account before sending the application to the client.'
      );
    }

    $scope.isSaving = true;
    return $scope.newClientFlow
      .sendToClient()
      .then(function () {
        goToOverview().then(function () {
          $rootScope.genericOkModal(
            'Application Sent',
            'The new client application has been sent to the client successfully.'
          );
        });
      })
      .catch(function () {
        userNotifications.showError(
          'Something went wrong while sending the document. Please try again later.'
        );
      })
      .finally(function () {
        $scope.isSaving = false;
      });
  }

  function goToAccount() {
    $state.go('advise.client-application.account');
  }

  function goToTransfer() {
    $state.go('advise.client-application.transfer');
  }

  function goToEFT() {
    $state.go('advise.client-application.transfer-instruction');
  }

  function goToOverview() {
    return $state.go('advise.overview');
  }

  function atLeastMinAccounts() {
    return $scope.newAccountFlows && $scope.newAccountFlows.length >= 1;
  }

  $scope.deleteAccountFlow = function (accountFlow) {
    AdvisorCreateNewAccountFlow.constructor
      .delete(accountFlow.id)
      .then(function () {
        return $scope.newClientFlow.advisorCreateNewAccountFlows.ready(true);
      })
      .then(function (accountFlows) {
        $scope.newAccountFlows = accountFlows;
        const promises = [
          $scope.newClientFlow.advisorCreateNewTransferFlows.ready(true),
          $scope.newClientFlow.advisorCreateNewTransferInstructionFlows.ready(true),
        ];
        return $q.all(promises);
      })
      .then(function ([transferFlows, transferInstructionFlows]) {
        $scope.newTransferFlows = transferFlows;
        $scope.newTransferInstructionFlows = transferInstructionFlows;
      });
  };

  $scope.deleteTransferFlow = function (transferFlow) {
    $http
      .delete('/api/advisor_create_new_transfer_flows/' + transferFlow.id + '.json')
      .then(function () {
        return $scope.newClientFlow.advisorCreateNewTransferFlows.ready(true);
      })
      .then(function (transferFlows) {
        $scope.newTransferFlows = transferFlows;
      });
  };

  $scope.deleteTransferInstructionFlow = function (transferFlow) {
    $http
      .delete('/api/advisor_create_new_transfer_instruction_flows/' + transferFlow.id + '.json')
      .then(function () {
        return $scope.newClientFlow.advisorCreateNewTransferInstructionFlows.ready(true);
      })
      .then(function (transferFlows) {
        $scope.newTransferInstructionFlows = transferFlows;
      });
  };

  const scheme = config.env.name === 'development' ? 'http' : 'https';
  const host = config.env.host;
  $scope.onboardInPersonUrl = `${scheme}://${host}/advisor-new-client/${$scope.newClientFlow.password()}/view?onboardType=advisorInPerson`;
}
