'use strict';

angular.module('model.Lead', ['ram']).factory('Lead', ['ram', 'config', factory]);

function factory(ram, config) {
  var Lead = new ram.Collection('Lead', {
    resources: {
      default: new ram.resources.Http('/api/leads/:id.json'),
      cookie: new ram.resources.Cookie('leads'),
    },
    schema: config.schemas.Lead,
  });

  return Lead;
}
