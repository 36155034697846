'use strict';

angular
  .module('controller.interview.advisor-referral-disclosures', [
    'ram',
    'model.PortfolioManager',
    'component.advisor-disclosure-form',
  ])
  .controller('InterviewAdvisorReferralDisclosuresController', [
    '$cookies',
    '$scope',
    '$state',
    'ram',
    'PortfolioManager',
    'advisorReferralDetails',
    'preferredPortfolioManagerId',
    interviewAdvisorReferralDisclosuresController,
  ]);

function interviewAdvisorReferralDisclosuresController(
  $cookies,
  $scope,
  $state,
  ram,
  PortfolioManager,
  advisorReferralDetails,
  preferredPortfolioManagerId
) {
  $scope.showGroupUnderOneName = advisorReferralDetails.showGroupUnderOneName;
  $scope.advisorName = advisorReferralDetails.showGroupUnderOneName
    ? advisorReferralDetails.institutionName
    : advisorReferralDetails.advisorName;
  $scope.institutionName = advisorReferralDetails.institutionName;
  $scope.hasServiceFee = advisorReferralDetails.hasServiceFee;
  $scope.preferredPortfolioManagerId = preferredPortfolioManagerId;
  // Note - When the back-end is ready, it will supply the special disclosures via controller endpoints.  They will be in
  //        the `advisorReferralDetails`, and need to be passed as `$scope.disclosures` for the AdvisorDisclosureForm
  //        component to pick them up.

  $scope.$parent.nextSection = function () {
    $cookies.put('acknowledged_advisor_referral_disclosures', true);
    $state.go($state.current.data.next);
  };

  $scope.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };

  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = false;
}
