'use strict';

function largeImgLoaderDirective() {
  return {
    restrict: 'A',
    scope: {
      lowResImg: '@',
      highResImg: '@',
    },
    link: function (scope, element) {
      element.css('background-image', 'url(' + scope.lowResImg + ')');
      var img = new Image();
      img.onload = function () {
        element.css('background-image', 'url(' + scope.highResImg + ')');
      };
      img.src = scope.highResImg;
    },
  };
}

angular
  .module('directive.largeImgLoader', [])
  .directive('largeImgLoader', [largeImgLoaderDirective]);
