'use strict';

angular
  .module('controller.interview.portfolio-preferences', ['model.PortfolioPreferenceAnswer'])
  .controller('InterviewPortfolioPreferencesController', [
    '$scope',
    '$state',
    '$cookies',
    'PortfolioPreferenceAnswer',
    'completedPortfolioPreferenceNotHisa',
    'currentAccount',
    interviewPortfolioPreferencesController,
  ]);

function interviewPortfolioPreferencesController(
  $scope,
  $state,
  $cookies,
  PortfolioPreferenceAnswer,
  completedPortfolioPreferenceNotHisa,
  currentAccount
) {
  const questionsArray = [
    {
      headline: 'Passive investments',
      label:
        "I prefer investments that track market indices, offering lower costs and fees, with returns that mirror the market's performance.",
      value: 0,
    },
    {
      headline: 'Actively managed investments',
      label:
        'I prefer actively managed investments for their potential to outperform market indices. These investments generally cost 0.5% more than passive portfolios per year.',
      value: 1,
    },
    {
      headline: 'A blend of both',
      label:
        'I prefer a mix of actively managed investments for their potential to outperform market indices and passive investments for their lower costs and stable market tracking. The fees are between active and passive options.',
      value: 2,
    },
    {
      headline: "I don't have a preference.",
      label: '',
      value: 3,
    },
    {
      headline: 'Short Term Goal',
      label: 'High Interest Savings Account',
      value: 4,
    },
  ];

  $scope.questions = questionsArray.slice(0, 4);
  // Option #5 is hidden purposefully as we want to map number 4 as a portfolio preference when
  // a client is choosing Short Term goal and does not get to choose the actual portfolio preference.
  $scope.portfolioPreferenceAnswer = completedPortfolioPreferenceNotHisa;
  $scope.currentAccount = currentAccount;

  const removeCookie = function () {
    $cookies.remove('portfolio_preference_answers');
  };

  $scope.saveResult = function () {
    const resources = {};
    removeCookie();
    resources.resource = 'cookie';

    const answer = PortfolioPreferenceAnswer.new(
      {
        portfolioPreferenceId: $scope.portfolioPreferenceAnswer.portfolioPreferenceId(),
      },
      resources
    );
    return answer.save();
  };

  $scope.$parent.nextSection = function () {
    $scope.saveResult();
    $state.go('optimizing');
  };

  $scope.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };

  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;
}
