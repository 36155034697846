'use strict';

angular
  .module('controller.advise.client-application.setup', [
    'ram',
    'directive.input',
    'service.form-helper',
    'service.user-notifications',
  ])
  .controller('AdviseClientApplicationSetupCtrl', [
    '$scope',
    '$state',
    'client',
    'newClientFlow',
    'formHelper',
    'userNotifications',
    adviseClientApplicationSetupCtrl,
  ]);

function adviseClientApplicationSetupCtrl(
  $scope,
  $state,
  client,
  newClientFlow,
  formHelper,
  userNotifications
) {
  $scope.person = client;
  $scope.phoneNumber = client.phoneNumbers()[0];
  $scope.isSubmitting = false;

  $scope.onPhoneNumberChange = angular.noop;
  $scope.onDateOfBirthChange = function (person, date) {
    person.birthDate(date.toDate());
  };

  $scope.getDetails = function (formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $scope.isSubmitting = true;

      $scope.phoneNumber
        .save()
        .then(function () {
          return $scope.person.save();
        })
        .then(function () {
          return newClientFlow.updateName();
        })
        .then(function () {
          $state.go('advise.client-application.details');
        })
        .catch(function () {
          userNotifications.showError('Something went wrong. Please try again later.');
        })
        .finally(function () {
          $scope.isSubmitting = false;
        });
    }
  };
}
