'use strict';

angular
  .module('controller.advise.new-accounts', [
    'service.modals',
    'service.bulkDataLoader',
    'directive.paginationnavigator',
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedAccount',
    'model.User',
    'model.Paginator',
    'model.Account',
    'directive.secured',
    'service.pagination.filter-service',
  ])
  .controller('AdviseNewAccountsCtrl', [
    '$scope',
    '$rootScope',
    '$state',
    '$http',
    '$q',
    '$window',
    'User',
    'Account',
    'PaginatedAccount',
    'Paginator',
    'modals',
    'config',
    'paginationFilterService',
    'securityServiceFactory',
    controllerv2,
  ]);

function controllerv2(
  $scope,
  $rootScope,
  $state,
  $http,
  $q,
  $window,
  User,
  Account,
  PaginatedAccount,
  Paginator,
  modals,
  config,
  paginationFilterService,
  securityServiceFactory
) {
  $scope.bulkAccounts = [];

  /** ***************************** Pagination methods */
  var _getDataFromPaginator = function (parameter) {
    return parameter.accounts();
  };

  var initialFilter = ['status.started', 'status.pending', 'status.approved'];
  $scope.initialActions = function () {
    return [
      {
        type: 'SORT-BY',
        sort: {
          sortBy: 'id',
          order: -1,
        },
      },
      {
        type: 'FLEX-FILTER',
        filterList: initialFilter,
      },
    ];
  };

  var filterData = [];

  paginationFilterService.addEnumFilterData(
    _.filter(config.types.AccountStatus, function (status) {
      return !_.contains(['open', 'deactivated', 'closing', 'closed'], status.name);
    }),
    filterData,
    'Status',
    'status'
  );

  paginationFilterService.addEnumFilterData(config.types.Account, filterData, 'Type', 'type');

  if ($rootScope.currentUser.canSelectAdaptivePortfolio()) {
    filterData.push({
      id: filterData.length,
      group: 'Portfolio Type',
      label: 'Adaptive',
      code: 'portfolio.adaptive',
    });
  }

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Harmony',
    code: 'portfolio.harmony',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Responsible',
    code: 'portfolio.responsible',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'BCV',
    code: 'portfolio.bcv',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Core',
    code: 'portfolio.core',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Core Ex-Russia',
    code: 'portfolio.core_ex_russia',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'Responsible Ex-Russia',
    code: 'portfolio.responsible_ex_russia',
  });

  filterData.push({
    id: filterData.length,
    group: 'Portfolio Type',
    label: 'High Interest Savings',
    code: 'portfolio.savings',
  });

  if ($rootScope.security.can('superAdvisor')) {
    filterData.push({
      id: filterData.length,
      group: 'Advisor',
      label: 'ModernAdvisor',
      code: 'advisor.modernadvisor',
    });

    filterData.push({
      id: filterData.length,
      group: 'Advisor',
      label: 'Other Advisors',
      code: 'advisor.external',
    });

    filterData.push({
      id: filterData.length,
      group: 'Custodian',
      label: 'Credential',
      code: 'custodian.csi',
    });

    filterData.push({
      id: filterData.length,
      group: 'Custodian',
      label: 'BBS Securities',
      code: 'custodian.bbs',
    });

    filterData.push({
      id: filterData.length,
      group: 'Custodian',
      label: 'FCC',
      code: 'custodian.fcc',
    });
  }

  $scope.filterData = filterData;
  $scope.filterModel = paginationFilterService.toFilterModel(filterData, initialFilter);

  $scope.paginator = new Paginator(PaginatedAccount, null, _getDataFromPaginator);

  /** ***************************** end Pagination methods */

  $scope.canApprove = (accountReadModel) => {
    return accountReadModel.accountApprovalRole() === $rootScope.currentUser.roleName();
  };

  $scope.loadStatus = function (accountReadModel) {
    modals.applicationStatusModal(accountReadModel).result.then(function () {
      // Nothing to do once this modal is closed. The modal is purely informational.
    });
  };

  $scope.resendForms = function (accountReadModel) {
    modals.resendFormsModal(accountReadModel).result.then(function () {
      // Nothing to do once this modal is closed. The modal is purely informational.
    });
  };

  $scope.approve = function (accountReadModel) {
    const identityVerificationStatus = accountReadModel.identityVerified();
    const userId = accountReadModel.userId();
    modals.confirmAccountApproval(identityVerificationStatus, userId).result.then(function () {
      return accountReadModel.approve();
    });
  };

  $scope.setNumber = function (accountReadModel) {
    Account.find({
      id: accountReadModel.accountId(),
    }).then(function (account) {
      modals.accountNumberModal(account).result.then(function () {
        $state.reload();
      });
    });
  };

  $scope.reject = function (accountReadModel) {
    modals.rejectApplicationModal().result.then(function (reason) {
      var path = ['', 'api', 'accounts', accountReadModel.accountId(), 'reject'].join('/');
      $http
        .put(path, {
          account: {
            rejectionReason: reason,
          },
        })
        .then(function () {
          $state.reload();
        });
    });
  };

  $scope.delete = function (accountReadModel) {
    modals.deleteApplicationModal(accountReadModel.accountId()).result.then(function () {
      $state.reload();
    });
  };

  $scope.regenerateForms = function (accountRM) {
    modals
      .genericConfirmationModal(
        'Are you sure you want to regenerate the forms for this account?',
        'The old NAAF will be deleted. A new NAAF will be sent to the client. The IMA will be included as well if the client has not signed one.',
        'btn-success'
      )
      .result.then(function () {
        $scope.$root.showLoading();
        return accountRM.regenerateForms();
      })
      .finally(function () {
        $scope.$root.hideLoading();
      });
  };

  $scope.downloadAccountOpeningCsvs = function () {
    $window.open('/api/advisor/api_friendly_account_opening.csv', '_blank');
    $window.open('/api/advisor/manual_account_opening.csv', '_blank');
  };

  const security = securityServiceFactory.create($scope.$root.currentUser);

  $scope.showActions = security.canAccess(
    'new_accounts_delete',
    'new_accounts_approve',
    'new_accounts_reject',
    'new_accounts_set_number'
  );

  $scope.showExtraActions = security.canAccess('new_accounts_regenerate_forms', 'log_in_as_client');

  $scope.numColumns = 8;

  if ($scope.showActions) {
    $scope.numColumns++;
  }
  if ($scope.showExtraActions) {
    $scope.numColumns++;
  }
}
