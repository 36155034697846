import React from 'react';

export default function DetailLine({ title, data }) {
  if (data.length === 0) {
    return null;
  }

  return (
    <div className="panel panel-default m-t-10">
      <div className="panel-body">
        <h4>{title}</h4>
        <table className="table">
          <thead>
            <tr>
              <th>Account</th>
              <th>Side</th>
              <th>Fund</th>
              <th>Type</th>
              <th>Value</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.account}</td>
                <td>{item.side}</td>
                <td>{item.fund}</td>
                <td>{item.type}</td>
                <td>{item.value}</td>
                <td>{item.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
