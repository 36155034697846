'use strict';

angular
  .module('controller.advise.csv-reports-view', [
    'component.advisor.date-range',
    'component.advisor.pick-advisor',
  ])
  .controller('AdviseCsvReportsViewCtrl', [
    '$scope',
    '$state',
    '$http',
    'ram',
    'userNotifications',
    adviseReportsViewCtrl,
  ]);

function adviseReportsViewCtrl($scope, $state, $http, ram, userNotifications) {
  const DEFAULT_ENDPOINT = 'api/super_advisor/csv_reports/generate';

  const reports = [
    {
      label: 'InvestorDNA Balances',
      value: 'advise.csv-reports-view.idna',
      name: 'investor_dna',
    },
    {
      label: 'AUM Demographics',
      value: 'advise.csv-reports-view.aum',
      name: 'aum_demographics',
    },
    {
      label: 'AUM Advisor Report',
      value: 'advise.csv-reports-view.aum-advisor',
      name: 'aum_advisor',
    },
    {
      label: 'Snapshot Beneficiary Report',
      value: 'advise.csv-reports-view.beneficiary',
      name: 'beneficiary',
    },
    {
      label: 'External Advisor Group Report',
      value: 'advise.csv-reports-view.advisor-group',
      name: 'advisor_group',
    },
    {
      label: 'Fund Flows Report',
      value: 'advise.csv-reports-view.fund-flows',
      name: 'fund_flows',
    },
    {
      label: 'Fund Flows by Advisor Report',
      value: 'advise.csv-reports-view.fund-flows-by-advisor',
      name: 'fund_flows_by_advisor',
    },
    {
      label: 'Account Balances Report',
      value: 'advise.csv-reports-view.account-balances',
      name: 'account_balances',
    },
    {
      label: 'AUM Report',
      value: 'advise.csv-reports-view.quarterly-aum',
      name: 'quarterly_aum',
    },
    {
      label: 'Advisors Added Report',
      value: 'advise.csv-reports-view.advisors-added',
      name: 'advisors_added',
    },
    {
      label: 'Custodian RESP Beneficiary Report',
      value: 'advise.csv-reports-view.rrsp-beneficiary',
      name: 'resp_beneficiary',
    },
    {
      label: 'GGEM Report',
      value: 'advise.csv-reports-view.ggem-monthly',
      name: 'ggem_monthly',
    },
    {
      label: 'Rebalance Status',
      value: 'advise.csv-reports-view.rebalance-status',
      name: 'rebalance_status',
    },
    {
      label: 'Person AML Report',
      value: 'advise.csv-reports-view.person-aml-report',
      name: 'person_aml',
    },
    {
      label: 'Account Opening Tracking',
      value: 'advise.csv-reports-view.account-opening-tracking',
      name: 'account_opening_tracking',
    },
    {
      label: 'EFT & RIF Transfers for Advisor',
      value: 'advise.csv-reports-view.eft-rif-transfer-for-advisor',
      name: 'advisor_eft_rif',
    },
    {
      label: 'Guardian Funds',
      value: 'advise.csv-reports-view.guardian-funds',
      name: 'guardian_funds',
    },
    {
      label: 'Foreign Asset Report',
      value: 'advise.csv-reports-view.foreign-asset-report',
      name: 'foreign_asset_report',
    },
    {
      label: 'Realized Gain Loss Report',
      value: 'advise.csv-reports-view.realized-gain-loss-report',
      name: 'realized_gain_loss_report',
    },
    {
      label: 'Realized Income Report',
      value: 'advise.csv-reports-view.realized-income-report',
      name: 'realized_income_report',
    },
    {
      label: 'Connect Wealth Clients Report',
      value: 'advise.csv-reports-view.connect-wealth-clients-report',
      name: 'connect_wealth_clients_report',
    },
    {
      label: 'Connect Wealth Transactions Report',
      value: 'advise.csv-reports-view.connect-wealth-transactions-report',
      name: 'connect_wealth_transactions_report',
    },
    {
      label: 'KYC Status Report',
      value: 'advise.csv-reports-view.kyc-status-report',
      name: 'kyc_status_report',
    },
    {
      label: 'Client Contact List Report',
      value: 'advise.csv-reports-view.client-contact-list-report',
      name: 'client_contact_list_report',
    },
    {
      label: 'Beneficiary Report',
      value: 'advise.csv-reports-view.beneficiary-report',
      name: 'beneficiary_report',
    },
    {
      label: 'Positions By Day Report for Fees',
      value: 'advise.csv-reports-view.positions-by-day-report',
      name: 'positions_by_day_report',
      endpoint: 'api/super_advisor/csv_reports/generate_positions_by_day_report',
    },
  ];
  $scope.reports = reports.sort((a, b) => a.label.localeCompare(b.label));

  $scope.selectedReport = new ram.Accessor($state.current.name);
  $scope.startDate = null;
  $scope.endDate = null;
  $scope.name = _getReportObject($scope.selectedReport()).name;
  $scope.href = null;
  $scope.data = {};

  loadExistingReports();

  $scope.dateRangePicked = function (startDate, endDate) {
    $scope.startDate = startDate;
    $scope.endDate = endDate;
  };

  $scope.reportSelected = function () {
    $state.go($scope.selectedReport());
    $scope.name = _getReportObject($scope.selectedReport()).name;
    $scope.startDate = null;
    $scope.endDate = null;
    loadExistingReports();
  };

  $scope.generateReport = function () {
    let errorState = false;
    userNotifications.clearNotification();
    userNotifications.showSuccess('Report is being generated. Finished report will display here.');

    setTimeout(() => {
      if (!errorState) {
        userNotifications.clearNotification();
      }
    }, 5000);

    const { endpoint } = _getReportObject($scope.selectedReport());
    $http
      .post((endpoint ?? DEFAULT_ENDPOINT) + $scope.params(), {})
      .then(function (response) {
        $scope.existingReports.unshift(response.data);
        setTimeout(() => {
          loadExistingReports();
        }, 3000);
      })
      .catch((error) => {
        errorState = true;
        userNotifications.clearNotification();
        userNotifications.showError(error.data);
      })
      .finally(function () {});
  };

  $scope.duration = (report) => {
    const endTime = report.completedAt ? Date.parse(report.completedAt) : Date.now();
    const diffMs = Math.abs(endTime - Date.parse(report.createdAt));
    const secs = Math.abs(diffMs) / 1000;
    if (secs < 60) {
      return `${secs.toFixed(2)} seconds`;
    }
    const mins = secs / 60;
    return `${mins.toFixed(2)} minutes`;
  };

  $scope.paramsText = (params) => {
    const parsed = _.camelizeKeys(JSON.parse(params));
    if (parsed?.options?.accountNumbers) {
      return `${parsed.startDate} - ${parsed.endDate}, Account Numbers: ${parsed.options.accountNumbers}`;
    }
    if (parsed.startDate && parsed.endDate) {
      return `${parsed.startDate} - ${parsed.endDate}`;
    }
    if (parsed.endDate) {
      return `${parsed.endDate}`;
    }
    if (parsed?.options?.groupId) {
      return `Group Id: ${parsed.options.groupId}`;
    }
    return 'N/A';
  };

  $scope.downloadUrl = (report) => {
    return `api/super_advisor/csv_reports/${report.id}/download_report`;
  };

  $scope.params = function () {
    var parts = [];
    switch ($scope.name) {
      case 'connect_wealth_clients_report':
      case 'connect_wealth_transactions_report':
      case 'person_aml':
      case 'rebalance_status':
      case 'advisor_group':
      case 'beneficiary':
        parts.push('name=' + $scope.name);
        break;

      case 'guardian_funds':
      case 'quarterly_aum':
      case 'aum_demographics':
        if ($scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'aum_advisor':
      case 'ggem_monthly':
        if ($scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'investor_dna':
        if ($scope.startDate && $scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'account_opening_tracking':
      case 'fund_flows':
      case 'fund_flows_by_advisor':
      case 'account_balances':
      case 'advisors_added':
        if ($scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'resp_beneficiary':
      case 'advisor_eft_rif':
      case 'kyc_status_report':
      case 'client_contact_list_report':
      case 'beneficiary_report':
        if ($scope.groupId) {
          parts.push('name=' + $scope.name);
          parts.push('group_id=' + $scope.groupId);
        }
        break;

      case 'realized_gain_loss_report':
      case 'realized_income_report':
      case 'foreign_asset_report':
        if ($scope.data.accountNumber) {
          parts.push('name=' + $scope.name);
          parts.push('account_number=' + $scope.data.accountNumber);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'positions_by_day_report':
        if ($scope.accountNumbers) {
          parts.push('name=' + $scope.name);
          parts.push('account_numbers=' + $scope.accountNumbers);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;
    }

    if (parts.length === 0) {
      return null;
    }

    return '?' + parts.join('&');
  };

  function _formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  function loadExistingReports(retryAutomatically = true) {
    if (!$scope.name) {
      return;
    }
    $http
      .get('api/super_advisor/csv_reports?name=' + $scope.name, {})
      .then(function (response) {
        const reports = response.data;
        $scope.existingReports = reports;

        if (reports.some((x) => x.completedAt === null)) {
          if (retryAutomatically) {
            setTimeout(() => {
              loadExistingReports();
            }, 5000);
          }
        }
      })
      .finally(function () {});
  }

  function _getReportObject(value) {
    return (
      _.find($scope.reports, function (report) {
        return report.value === value;
      }) || {}
    );
  }
}
