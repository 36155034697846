'use strict';

angular
  .module('directive.account-goal-detail', [
    'config',
    'service.user-notifications',
    'model.AdvisorCreateNewClientFlow',
  ])
  .directive('accountGoalDetail', ['config', 'userNotifications', accountGoalDetailDirective]);

function accountGoalDetailDirective(config) {
  return {
    restrict: 'E',
    scope: {
      clientPerson: '<',
      newAccountFlows: '=',
      account: '=',
      accountFlowJointApplicant: '=',
      ima: '=',
      portfolioOptions: '<',
      prefill: '@',
      attemptingJointNonSpouse: '=',
      selectJointSubscriber: '=',
      relationship: '=',
      accountForm: '=',
    },
    templateUrl: 'directives/account-goal-detail.html',
    controller: [
      '$scope',
      'AdvisorCreateNewClientFlow',
      'userNotifications',
      function ($scope, AdvisorCreateNewClientFlow, userNotifications) {
        $scope.showCheckbox = false;
        $scope.showRelationshipToSubscriber = false;
        $scope.showJointApplicantEmail = false;
        $scope.disableRelationship = true;
        $scope.disableJointApplicantEmail = true;
        $scope.isPrefill = $scope.prefill === 'true';

        const defaultEmail = AdvisorCreateNewClientFlow.lastJointApplicantEmail(
          $scope.newAccountFlows
        );
        if (defaultEmail) {
          $scope.accountFlowJointApplicant.email(defaultEmail);
        }

        $scope.$watch('account.type()', function (accountType) {
          $scope.showJurisdiction = false;
          $scope.showCheckbox = false;
          $scope.showRelationshipToSubscriber = false;
          $scope.showJointApplicantEmail = false;
          $scope.disableRelationship = true;
          $scope.disableJointApplicantEmail = true;
          $scope.isPrefill = $scope.prefill === 'true'; // ----- MNG-1275 -----

          const defaultEmail = AdvisorCreateNewClientFlow.lastJointApplicantEmail(
            $scope.newAccountFlows
          );
          if (defaultEmail) {
            $scope.accountFlowJointApplicant.email(defaultEmail);
          }

          $scope.$watch('account.type()', function (accountType) {
            $scope.showJurisdiction = false;
            $scope.showCheckbox = false;
            $scope.showRelationshipToSubscriber = false;
            $scope.showJointApplicantEmail = false;

            if (accountType && (accountType.name === 'lif' || accountType.name === 'lira')) {
              $scope.showJurisdiction = true;
            } else {
              $scope.account.jurisdictionId.reset();
            }

            if ($scope.isPrefill) {
              if (accountType?.canHaveJointApplicant) {
                if (!$scope.clientPerson.maritalStatus()) {
                  $scope.showJointApplicantEmail = true;
                  $scope.showCheckbox = true;
                  $scope.showRelationshipToSubscriber = true;
                } else if ($scope.clientPerson.hasSpouse()) {
                  $scope.showJointApplicantEmail = true;
                  $scope.showCheckbox = true;
                }
              } else {
                userNotifications.clearNotification();
                $scope.selectJointSubscriber(false);
                $scope.attemptingJointNonSpouse = false;
                $scope.disableRelationship = true;
                $scope.disableJointApplicantEmail = true;
                _resetUx('relationship');
                _resetUx('email');
                _resetRelationship();
              }
            }

            if (
              (!$scope.isPrefill || ($scope.isPrefill && !$scope.showJointApplicantEmail)) &&
              accountType?.joint
            ) {
              // ----- MNG-1275 -----
              $scope.showJointApplicantEmail = true;
              $scope.disableJointApplicantEmail = false;
            }
          });

          $scope.$watch('selectJointSubscriber()', (selection) => {
            if (!$scope.prefill) {
              return;
            }
            userNotifications.clearNotification();
            $scope.attemptingJointNonSpouse = false;
            $scope.disableRelationship = true;
            $scope.disableJointApplicantEmail = true;
            if (selection) {
              $scope.disableRelationship = false;
              $scope.disableJointApplicantEmail = false;
            } else {
              _resetUx('relationship');
              _resetUx('email');
              _resetRelationship();
            }
          });

          if (accountType?.joint) {
            $scope.showJointApplicantEmail = true;
            $scope.disableJointApplicantEmail = false;
          }
        });

        $scope.$watch('selectJointSubscriber()', (selection) => {
          if (!$scope.prefill) {
            return;
          }
          userNotifications.clearNotification();
          $scope.attemptingJointNonSpouse = false;
          $scope.disableRelationship = true;
          $scope.disableJointApplicantEmail = true;
          if (selection) {
            $scope.disableRelationship = false;
            $scope.disableJointApplicantEmail = false;
          } else {
            _resetUx('relationship');
            _resetUx('email');
            _resetRelationship();
          }

          if ($scope.account?.type()?.joint) {
            $scope.showJointApplicantEmail = true;
            $scope.disableJointApplicantEmail = false;
          }
        });

        $scope.$watch('relationship()', (relationship) => {
          if (!$scope.prefill) {
            return;
          }
          userNotifications.clearNotification();
          $scope.attemptingJointNonSpouse = false;
          if (relationship === 'Other') {
            $scope.attemptingJointNonSpouse = true;
            userNotifications.showInfo('Please contact support');
          }
        });

        function _resetRelationship() {
          if ($scope.relationship() === 'Other') {
            $scope.relationship(null);
          }
        }

        function _resetUx(fieldName) {
          if (!$scope.prefill) {
            return;
          }
          const form = $scope.accountForm;
          if (form[fieldName]?.$error) {
            form[fieldName].$error = {};
          }
          form[fieldName]?.$setPristine();
          form[fieldName]?.$setValidity('required', true);
          angular.element(`input[name="${fieldName}"]`).trigger('blur');
        }

        $scope.accountTypes = _.reject(config.types.Account, function (accountType) {
          return (
            accountType.name === 'corporate' ||
            accountType.name === 'joint_no_right_of_survivorship' ||
            accountType.name === 'group_rrsp'
          );
        });

        removeTrialAccountType();

        $scope.jurisdictions = config.types.AccountJurisdiction.filter(
          (province) => province.name !== 'prince_edward_island'
        );
        $scope.relationships = ['Married', 'Common Law', 'Separated', 'Other'];

        function removeTrialAccountType() {
          $scope.accountTypes = _.reject($scope.accountTypes, function (accountType) {
            return accountType.name === 'trial';
          });
        }

        $scope.spouseName = function () {
          const name = $scope.clientPerson?.spouseRelation()?.relative()?.fullName(); // jshint ignore:line
          const len = name?.trim()?.length;
          return !name || len === 0 ? null : name;
        };
        $scope.spouseName(); // First time, this is undefined for some reason.

        $scope.defaultRelationshipName = function () {
          if ($scope.clientPerson.maritalStatus.is.commonLaw()) {
            return 'Common Law Partner';
          } else if ($scope.clientPerson.hasSpouse()) {
            return 'Spouse';
          }
          return null;
        };
      },
    ],
  };
}
