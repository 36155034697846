'use strict';

angular
  .module('controller.modal.eft-confirmation-result', ['service.adaptive-views'])
  .controller('ModalEftConfirmationResultCtrl', ['$scope', '$window', 'adaptiveViews', controller]);

function controller($scope, $window, adaptiveViews) {
  $scope.isSafari = adaptiveViews.isSafari;

  $scope.redirectToDashboard = function () {
    window.location.href = '/';
  };

  $scope.closeTheWindow = function () {
    $window.close();
  };
}
