'use strict';

angular
  .module('controller.modal.advisor-change-client-group', [
    'service.loading-indicator',
    'service.form-helper',
    'directive.input.date-input',
    'directive.staticField',
    'model.Person',
  ])
  .controller('advisorChangeClientGroupModalController', [
    '$scope',
    '$http',
    '$uibModalInstance',
    'formHelper',
    'Person',
    'ram',
    'loadingIndicator',
    'client',
    'currentAdvisor',
    'allAdvisors',
    'pendingAdvisor',
    advisorChangeClientGroupModalCtrl,
  ]);

function advisorChangeClientGroupModalCtrl(
  $scope,
  $http,
  $modalInstance,
  formHelper,
  Person,
  ram,
  loadingIndicator,
  client,
  currentAdvisor,
  allAdvisors,
  pendingAdvisor
) {
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - Helpers
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  function _dataFor(results, key) {
    var data = results && results.status === 200 && results.data ? results.data : null;
    if (data && key) {
      return data[key];
    }
    return data;
  }

  function _asLabelValue(label, value) {
    return {
      label,
      value,
    };
  }

  function _getFormattedDate(date) {
    return moment(date).startOf('day').toDate();
  }

  function _getSortedNames(allAdvisors) {
    return _.chain(_dataFor(allAdvisors))
      .map(function (datum, index) {
        const fullName = datum.firstName + ' ' + datum.lastName;
        const label = fullName + ' - ' + datum.id.toString() + ' - ' + datum.institution;
        return _asLabelValue(label, index);
      })
      .sortBy('label')
      .value();
  }

  const messages = {
    imaChanged: 'The change will be applied when the client signs the new IMA.',
    imaSame: 'The change has been applied as the IMA is the same.',
    imaNone: "The change has been applied as the IMA hasn't been sent yet.",
    secondaryAdvisor: 'This client has a secondary advisor.  Please ask the Dev team link it up.',
  };

  function _sendToServer(currentUserId, newGroupUrl, effectiveDate) {
    loadingIndicator.show();
    $scope.error = false;
    $scope.state = 'working';
    $scope.informationMessageForUser = 'Working...';

    return $http
      .post('/api/advisor/set_new_relationship_advisor', {
        currentUserId,
        newGroupUrl,
        effectiveDate,
      })
      .then(function (result) {
        let messageList = ['An error has occurred'];
        if (result.data) {
          messageList = [_getServerMessage(result.data)];
          if (result.data.warnSecondaryAdvisor) {
            messageList.push(messages.secondaryAdvisor);
          }
        }
        $scope.informationMessageForUser = messageList.join('  ');
        $scope.state = 'done';
        loadingIndicator.hide();
        return true;
      })
      .catch(function () {
        $scope.informationMessageForUser = 'An error occurred.';
        $scope.error = true;
        $scope.state = 'preparing';
        loadingIndicator.hide();
      });
  }

  function _getServerMessage(data) {
    if (!data.imaPresent) {
      return messages.imaNone;
    }
    return data.imaChanged ? messages.imaChanged : messages.imaSame;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - Scope
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  angular.extend($scope, {
    state: 'preparing',
    amount: new ram.Accessor(),
    client,
    advisor: new Person(_dataFor(currentAdvisor, 'advisor')),
    pendingAdvisor,
    since: _dataFor(currentAdvisor, 'since'),
    effectiveDate: new ram.Accessor(moment().startOf('day').toDate()),
    allAdvisors: _dataFor(allAdvisors),
    names: _getSortedNames(allAdvisors),
    serviceLevels: [],
    newAdvisor: new ram.Accessor(
      currentAdvisor.data
        ? _dataFor(allAdvisors).findIndex(function (advisor) {
            return advisor.id === _dataFor(currentAdvisor, 'advisor').userId;
          })
        : null
    ),
    newServiceLevel: new ram.Accessor(null),
    changeName,
    confirm,
    advisorInfo,
    pendingAdvisorInfo,
    pendingServiceLevel,
  });

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - Scope Methods
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  function changeName(form) {
    if (!$scope.newAdvisor()) {
      return;
    }

    $scope.serviceLevels = _.map(
      $scope.allAdvisors[$scope.newAdvisor()].serviceLevels,
      function (datum) {
        return _asLabelValue(datum.name, datum.groupUrl);
      }
    );
    $scope.newServiceLevel(_.first($scope.serviceLevels).value);
    if (form && form.serviceLevel) {
      form.serviceLevel.$invalid = false;
      angular.element('select[name=serviceLevel]').trigger('focus');
    }
  }

  function validate() {
    return $scope.newAdvisor() !== null;
  }

  function confirm(form) {
    var effectiveDate = _getFormattedDate($scope.effectiveDate());
    if (form.$pristine || !formHelper.done(form, validate)) {
      formHelper.notDone($scope, form);
      return;
    }
    _sendToServer($scope.client.id, $scope.newServiceLevel(), effectiveDate);
  }

  function advisorInfo() {
    const institution = _dataFor(currentAdvisor, 'institution');
    const advisorUserId = $scope.advisor.userId();
    const fullName = $scope.advisor.fullName();
    if (!fullName) {
      return '';
    }
    return `${fullName} - ${advisorUserId} - ${institution}`;
  }

  function pendingAdvisorInfo() {
    const institution = _dataFor(pendingAdvisor, 'institution');
    const advisorUserId = _dataFor(pendingAdvisor, 'advisorId');
    const fullName = _dataFor(pendingAdvisor, 'advisorName');

    if (!fullName) {
      return '';
    }
    return `${fullName} - ${advisorUserId} - ${institution}`;
  }

  function pendingServiceLevel() {
    const serviceLevel = _dataFor(pendingAdvisor, 'serviceLevel');

    if (!serviceLevel) {
      return '';
    }
    return `${serviceLevel}`;
  }
}
