'use strict';

angular
  .module('service.recolor-funds', ['model.Fund'])
  .factory('recolorFundsService', ['Fund', 'colors', recolorFundsFactory]);

function recolorFundsFactory(Fund, colors) {
  var colours = {
    stock: {
      dark: colors.purple[0],
      light: colors.purple[3],
    },
    fixedIncome: {
      dark: colors.green[0],
      light: colors.green[3],
    },
    cash: {
      dark: colors.blue[0],
      light: colors.blue[3],
    },
    otherFunds: {
      dark: colors.orange[0],
      light: colors.orange[3],
    },
  };

  function _convertHexColorToRGB(hexColor) {
    var rgb = {};
    rgb.r = parseInt(hexColor.substring(1, 3), 16);
    rgb.g = parseInt(hexColor.substring(3, 5), 16);
    rgb.b = parseInt(hexColor.substring(5), 16);
    return rgb;
  }

  function _convertRGBColorToHex(rgbColor) {
    return (
      '#' +
      rgbColor.r.toString(16).padStart(2, '0') +
      rgbColor.g.toString(16).padStart(2, '0') +
      rgbColor.b.toString(16).padStart(2, '0')
    );
  }

  function _recolorFunds(positions, colorDark, colorLight) {
    const len = positions.length;

    if (len === 0) {
      return;
    } else if (len === 1) {
      positions[0].fund().color = colorDark;
      return;
    }

    const colorDarkRGB = _convertHexColorToRGB(colorDark);
    const colorLightRGB = _convertHexColorToRGB(colorLight);
    const rDiff = Math.floor((colorLightRGB.r - colorDarkRGB.r) / (len - 1));
    const gDiff = Math.floor((colorLightRGB.g - colorDarkRGB.g) / (len - 1));
    const bDiff = Math.floor((colorLightRGB.b - colorDarkRGB.b) / (len - 1));

    for (var i = 0; i < len; i++) {
      var color = {};
      color.r = colorDarkRGB.r + i * rDiff;
      color.g = colorDarkRGB.g + i * gDiff;
      color.b = colorDarkRGB.b + i * bDiff;
      positions[i].fund().color = _convertRGBColorToHex(color);
    }
  }

  function recolorFundsBasedOnCategory(positions, category) {
    const filteredPositions = positions.filter((position) =>
      Fund.constructor.assetClassIdsByColourCategory[category].includes(
        position.fund().assetClassId()
      )
    );
    const colorDark = colours[category].dark;
    const colorLight = colours[category].light;
    _recolorFunds(filteredPositions, colorDark, colorLight);

    return positions;
  }

  function recolorFunds(positions) {
    recolorFundsBasedOnCategory(positions, 'stock');
    recolorFundsBasedOnCategory(positions, 'fixedIncome');
    recolorFundsBasedOnCategory(positions, 'cash');
    recolorFundsBasedOnCategory(positions, 'otherFunds');
    return positions;
  }

  return {
    recolorFunds,
    recolorFundsBasedOnCategory,
  };
}
