'use strict';

angular
  .module('directive.portfolio-performance', [
    'model.ModelPortfolio',
    'ram',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investments-bar-prep-data',
    'service.loading-indicator',
    'service.portfolio-line-chart-data-service',
  ])
  .directive('portfolioPerformance', [
    '$q',
    'ModelPortfolio',
    'config',
    'invBarPrepData',
    'investmentChartsAssetAllocationDataGenerator',
    'loadingIndicator',
    'portfolioLineChartDataService',
    'ram',
    portfolioPerformanceDirective,
  ]);

function portfolioPerformanceDirective(
  $q,
  ModelPortfolio,
  config,
  invBarPrepData,
  investmentChartsAssetAllocationDataGenerator,
  loadingIndicator,
  portfolioLineChartDataService,
  ram
) {
  return {
    restrict: 'E',
    scope: {
      portfolioSelections: '=',
    },
    templateUrl: 'directives/portfolio-performance.html',
    link: function ($scope) {
      const getPortfolioOptionId = (portfolioOptionName) => {
        return config.types.AccountPortfolioOption.find(({ name }) => name === portfolioOptionName)
          ?.id;
      };

      var defaultSelection = {
        portfolioOptionId: getPortfolioOptionId('core_portfolio'),
        riskLevel: 5,
      };

      var indexOf = {
        ytd: 0,
        threeMonth: 1,
        oneYear: 2,
        threeYear: 3,
        fiveYear: 4,
        inception: 5,
        annualizedInception: 6,
      };

      angular.extend($scope, {
        cumulativePerformance: {},
        end: new ram.Accessor(),
        formatCurrency,
        formatDate,
        point: {},
        portfolioType: {
          options: [
            {
              label: 'Core ETF Portfolios',
              value: getPortfolioOptionId('core_portfolio'),
              description:
                'Passive ETF Investing - The Core Portfolios invest in equity and fixed income Exchange Traded Funds. Portfolio managers seek to optimize the portfolio for the highest risk-adjusted returns using various frameworks including resampled Mean Variance Optimization. The underlying funds are index funds that provide broad exposure to equities and fixed income.',
            },
            {
              label: 'Socially Responsible ETF Portfolios',
              value: getPortfolioOptionId('socially_responsible_portfolio'),
              description:
                'Passive ETF Investing - The Core Portfolios invest in equity and fixed income Exchange Traded Funds. Portfolio managers seek to optimize the portfolio for the highest risk-adjusted returns using various frameworks including resampled Mean Variance Optimization. The underlying funds are index funds with an ESG screen where possible that provide broad exposure to equities and fixed income.',
            },
            {
              label: 'Harmony Portfolios',
              value: getPortfolioOptionId('harmony_portfolio'),
              description:
                'Hybrid of Passive and Active Management - ModernAdvisor Harmony portfolios invest in equity and fixed income Exchange Traded Funds (ETFs), Mutual Funds, and Pooled Investment Funds. The Harmony Portfolios aim to offer the benefits of low-cost, broad diversification of passive investing, coupled with the higher potential return of an actively managed set of high conviction investments.',
            },
            {
              label: 'BCV Actively Managed Portfolios',
              value: getPortfolioOptionId('bcv_portfolio'),
              description:
                'Actively Managed Investments - BCV Portfolios invest in equity and fixed income investment funds.  These portfolios are sub-advised by BCV Asset Management Inc. ("BCV"). The BCV approach is based on investing in a high-qualify and focused set of growing businesses purchased at reasonable valuations.',
            },
            {
              label: 'Guardian Capital Core Portfolios',
              value: getPortfolioOptionId('guardian_core'),
              description:
                'Actively Managed Investments - Guardian Capital Core Portfolios are multi-asset solutions with a tactical advantage, sub-advised by a team of experienced portfolio managers at Guardian Capital.',
            },
            {
              label: 'Guardian Capital Sustainable Portfolios',
              value: getPortfolioOptionId('guardian_sustainable'),
              description:
                'Actively Managed Investments - Guardian Capital Sustainable Portfolios are multi-asset solutions that align with core ESG values and are optimized via factor exposures, derivative strategies and liquid alternatives. Guardian Capital Sustainable Portfolios are sub-advised by a team of experienced portfolio managers at Guardian Capital.',
            },
            {
              label: 'High Interest Savings Portfolio',
              value: getPortfolioOptionId('savings_portfolio'),
              description:
                "ModernAdvisor's High Interest Savings portfolios are designed as low-risk investment options that provide a rate of return on your investment that is directly tied to prevailing interest rates. Unlike our other investment options, these portfolios are not affected by the fluctuations of the stock or bond markets.",
            },
          ],
          selection: new ram.Accessor(defaultSelection.portfolioOptionId),
        },
        riskLevel: new ram.Accessor(defaultSelection.riskLevel),
        start: new ram.Accessor(),
        portfolioTypeDescription: () => {
          return (
            $scope.portfolioType.options.find(
              (option) => option.value === $scope.portfolioType.selection()
            )?.description || ''
          );
        },
        hisPortfolioOptionId: getPortfolioOptionId('savings_portfolio'),
      });

      const accountPortfolioOptions = config.types.AccountPortfolioOption;

      $scope.nonHisOptions = accountPortfolioOptions.filter((option) => option.nonHisOption);
      $scope.hisOptions = accountPortfolioOptions.filter((option) => option.hisOption);

      function formatCurrency(d) {
        return d3.format('$')(d);
      }

      function formatDate(d) {
        var formatString = $scope.$root.isMobile ? "%b '%y" : '%b %Y';
        return d3.time.format(formatString)(d);
      }

      const getSelectionValue = (portfolioOptionId, riskLevel) => {
        return portfolioOptionId?.toString() + riskLevel?.toString();
      };

      $scope.currentSelection = function () {
        return getSelectionValue($scope.portfolioType.selection(), $scope.riskLevel());
      };

      $scope.$watchGroup(['portfolioType.selection()', 'riskLevel()'], handleSelectionUpdate);

      function fetchModelPortfolio(riskLevel, portfolioOptionId) {
        return ModelPortfolio.where({
          portfolioOptionId,
          risk: riskLevel,
          taxable: false,
        }).then(_.first);
      }

      function handleSelectionUpdate(values) {
        var portfolioOptionId = values[0];
        var riskLevel = values[1];
        var selection = getSelectionValue(portfolioOptionId, riskLevel);

        if (!$scope.portfolioSelections[selection]) {
          loadingIndicator.show();
          var promises = [];
          $scope.portfolioSelections[selection] = {};

          promises.push(
            portfolioLineChartDataService
              .fetch(riskLevel, portfolioOptionId)
              .then(addToLineChartDataObject)
          );

          promises.push(
            fetchModelPortfolio(riskLevel, portfolioOptionId).then(addSelectionToSelectionsObject)
          );

          $q.all(promises).then(function () {
            updateView(selection);
            loadingIndicator.hide();
          });
        } else {
          updateView(selection);
        }

        function addToLineChartDataObject(data) {
          $scope.portfolioSelections[selection].lineChartData = data;
        }

        function addSelectionToSelectionsObject(modelPortfolio) {
          var chartData = investmentChartsAssetAllocationDataGenerator.getData(
            modelPortfolio.allocations()
          );

          var allocationDataForLegend = _.map(chartData, function (chartDataRow) {
            return {
              color: chartDataRow.color,
              symbol: chartDataRow.allocation.fund().symbol(),
              label: chartDataRow.allocation.fund().label(),
              assetClass: chartDataRow.allocation.fund().assetClass().label,
              weight: chartDataRow.allocation.weight(),
            };
          });

          var allocationsForBarChart = _.map(chartData, function (chartDataRow) {
            return chartDataRow.allocation;
          });

          $scope.portfolioSelections[selection].allocationLegendData = allocationDataForLegend;
          $scope.portfolioSelections[selection].barChartData =
            invBarPrepData.getChartDataForAllocations(allocationsForBarChart);
          $scope.portfolioSelections[selection].mer = modelPortfolio.fee();
        }
      }

      function updateView(selection) {
        var portfolioSelection = $scope.portfolioSelections[selection];
        updatePerformanceAsOfDate(portfolioSelection);
        updateStartEnd(portfolioSelection);
        updateOverlayStatus();
        calcCumulativePerf(selection);
      }

      function updatePerformanceAsOfDate(portfolioSelection) {
        $scope.performanceAsOfDate =
          performanceAsOfDate(portfolioSelection).format('MMMM Do, YYYY');
      }

      function updateStartEnd(portfolioSelection) {
        const len = portfolioSelection.lineChartData
          ? portfolioSelection.lineChartData.data.length
          : 0;

        if (len === 0) {
          $scope.start(undefined);
          $scope.end(undefined);
        } else {
          $scope.start(portfolioSelection.lineChartData.data[len - 1].date);
          $scope.end(portfolioSelection.lineChartData.data[0].date);
        }
      }

      function updateOverlayStatus() {
        const portfolioSelection = $scope.portfolioSelections[$scope.currentSelection()];

        if (Object.keys(portfolioSelection).length === 0) {
          $scope.overlayEndDate = undefined;
          $scope.overlayNeeded = false;
        } else {
          $scope.overlayEndDate = portfolioSelection.lineChartData.overlayEndDate;
          $scope.overlayNeeded = moment($scope.overlayEndDate, 'D-MMM-YY').isAfter(
            portfolioSelection.lineChartData.firstBusinessDay
          );
        }
      }

      function calcCumulativePerf(selection) {
        // No need to recalculate
        if ($scope.cumulativePerformance[selection]) {
          return;
        } else {
          $scope.cumulativePerformance[selection] = {};
        }

        var portfolioSelection = $scope.portfolioSelections[selection];
        var fromDates = [
          performanceAsOfDate(portfolioSelection).startOf('year'),
          performanceAsOfDate(portfolioSelection).startOf('month').subtract(2, 'months'),
          performanceAsOfDate(portfolioSelection).startOf('month').subtract(11, 'months'),
          performanceAsOfDate(portfolioSelection).startOf('month').subtract(35, 'months'),
          performanceAsOfDate(portfolioSelection).startOf('month').subtract(59, 'months'),
          moment($scope.start()),
        ];
        fromDates.forEach(calcPerfAndSave);

        $scope.cumulativePerformance[selection][indexOf.threeYear] = _calcAnnualized(
          $scope.cumulativePerformance[selection][indexOf.threeYear],
          _daysSinceDate(fromDates[indexOf.threeYear])
        );
        $scope.cumulativePerformance[selection][indexOf.fiveYear] = _calcAnnualized(
          $scope.cumulativePerformance[selection][indexOf.fiveYear],
          _daysSinceDate(fromDates[indexOf.fiveYear])
        );
        $scope.cumulativePerformance[selection][indexOf.annualizedInception] = _calcAnnualized(
          $scope.cumulativePerformance[selection][indexOf.inception],
          _totalDaysSinceInception()
        );

        function calcPerfAndSave(date, index) {
          var data = portfolioSelection.lineChartData.data;
          var len = data.length;
          var lastDataPointDate = moment(data[len - 1].date);
          var tempResult;

          if (date.isBefore(lastDataPointDate, 'day')) {
            return;
          }

          for (var i = 0; i < len; i++) {
            var currentDataPointDate = moment(data[i].date);
            if (date.isAfter(currentDataPointDate, 'day')) {
              break;
            } else if (
              currentDataPointDate.isAfter(performanceAsOfDate(portfolioSelection), 'day')
            ) {
              continue;
            } else {
              if (!tempResult) {
                tempResult = data[i].pctOfPrevious;
              } else {
                tempResult = tempResult * data[i].pctOfPrevious;
              }
            }
          }

          $scope.cumulativePerformance[selection][index] =
            Math.floor((tempResult - 1) * 10000) / 100;
        }

        function _calcAnnualized(performance, totalDays) {
          // Assuming performance is in percentage. ie) 13.4
          performance = performance / 100;
          // Annualize formula: (1 + performance) ^ (365 / totalDates) - 1
          return (Math.pow(1 + performance, 365 / totalDays) - 1) * 100;
        }

        function _totalDaysSinceInception() {
          var data = portfolioSelection.lineChartData.data;
          var lastDataPointDate = performanceAsOfDate(portfolioSelection);
          var startDate;

          for (var i = data.length - 1; i >= 0; i--) {
            if (data[i].pctOfPrevious !== 1) {
              startDate = moment(data[i].date).startOf('month');
              break;
            }
          }

          return moment.duration(lastDataPointDate.diff(startDate)).asDays();
        }

        function _daysSinceDate(dateInPast) {
          const lastDataPointDate = performanceAsOfDate(portfolioSelection);
          return moment.duration(lastDataPointDate.diff(dateInPast)).asDays();
        }
      }

      // For performance calculations, we take the most recent last day of month
      function performanceAsOfDate(selection) {
        const defaultPortfolio = '05';
        var mostRecentDate;

        if (!selection.lineChartData) {
          mostRecentDate = moment(
            $scope.portfolioSelections[defaultPortfolio].lineChartData.data[0].date
          );
        } else {
          mostRecentDate = moment(selection.lineChartData.data[0].date);
        }

        var lastDayOfCurrentMonth = mostRecentDate.endOf('month');

        if (mostRecentDate.isSame(lastDayOfCurrentMonth, 'day')) {
          return mostRecentDate;
        } else {
          return mostRecentDate.startOf('month').subtract(1, 'days');
        }
      }

      $scope.displayPerformance = function (period) {
        if (!$scope.cumulativePerformance[$scope.currentSelection()]) {
          return;
        }
        var performance = $scope.cumulativePerformance[$scope.currentSelection()][indexOf[period]];
        performance = performance ? performance.toFixed(1) + '%' : 'n/a';
        performance = performance === '-0.0%' ? '0.0%' : performance;
        return performance;
      };
    },
  };
}
