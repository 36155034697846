'use strict';

angular
  .module('routes.advise.csv-reports-view', [
    'controller.advise.csv-reports-view',
    'controller.advise.positions-by-day-report',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  const clientLevelReportTemplate =
    '<div class="m-t-20"><pick-advisor optional="true" label-text="Advisor or Institution" display-all="true" on-select-advisor="onSelectAdvisor" label-class="col-sm-2 m-t-20"></pick-advisor></div>';

  const clientLevelReportController = function ($scope) {
    $scope.$parent.groupId = '*';
    $scope.onSelectAdvisor = function (data) {
      $scope.$parent.groupId = data?.payload?.groupId;
    };
  };

  $stateProvider
    .state('advise.csv-reports-view', {
      url: '/reports-view',
      abstract: false,
      controller: 'AdviseCsvReportsViewCtrl',
      templateUrl: 'views/advise/reports/csv-reports-view.html',
    })
    .state('advise.csv-reports-view.idna', {
      url: '/investorDNA-Report',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="1" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.aum', {
      url: '/aum-report',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="0" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.aum-advisor', {
      url: '/aum-advisor-report',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.beneficiary', {
      url: '/beneficiary-report',
      template: '<div></div>',
    })
    .state('advise.csv-reports-view.advisor-group', {
      url: '/advisor-group-report',
      template: '<div></div>',
    })
    .state('advise.csv-reports-view.fund-flows', {
      url: '/fund-flows',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.fund-flows-by-advisor', {
      url: '/fund-flows-by-advisor',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.account-balances', {
      url: '/account-balances',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.quarterly-aum', {
      url: '/quarterly-aum',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="0" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.advisors-added', {
      url: '/advisors-added',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.rrsp-beneficiary', {
      url: '/resp-beneficiary',
      controller: [
        '$scope',
        function ($scope) {
          $scope.onSelectAdvisor = function (data) {
            $scope.$parent.groupId = data?.payload?.groupId;
          };
        },
      ],
      template:
        '<div class="m-t-20"><pick-advisor label-text="Advisor or Institution" display-all="true" on-select-advisor="onSelectAdvisor" label-class="col-sm-2 m-t-20"></pick-advisor></div>',
    })
    .state('advise.csv-reports-view.ggem-monthly', {
      url: '/ggem-monthly',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="1" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.rebalance-status', {
      url: '/rebalance-status-report',
      template: '<div></div>',
    })
    .state('advise.csv-reports-view.person-aml-report', {
      url: '/person-aml-report',
      template: '<div></div>',
    })
    .state('advise.csv-reports-view.connect-wealth-clients-report', {
      url: '/connect-wealth-clients-report',
      template: '<div></div>',
    })
    .state('advise.csv-reports-view.connect-wealth-transactions-report', {
      url: '/connect-wealth-transactions-report',
      template: '<div></div>',
    })
    .state('advise.csv-reports-view.account-opening-tracking', {
      url: '/account-opening-tracking',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval-in-days="1"  date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.eft-rif-transfer-for-advisor', {
      url: '/advisor_eft_rif',
      controller: [
        '$scope',
        function ($scope) {
          $scope.onSelectAdvisor = function (data) {
            $scope.$parent.groupId = data?.payload?.groupId;
          };
        },
      ],
      template:
        '<div class="m-t-20"><pick-advisor label-text="Advisor or Institution" display-all="true" on-select-advisor="onSelectAdvisor" label-class="col-sm-2 m-t-20"></pick-advisor></div>',
    })
    .state('advise.csv-reports-view.guardian-funds', {
      url: '/guardian_funds',
      controller: [
        '$scope',
        function ($scope) {
          $scope.endDate = moment().toDate();
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<date-range end-date="endDate" interval="0" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>',
    })
    .state('advise.csv-reports-view.foreign-asset-report', {
      url: '/foreign-asset-report',
      controller: [
        '$scope',
        function ($scope) {
          const lastYear = new Date().getFullYear() - 1;
          $scope.endDate = new Date(lastYear, 11, 31);
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<div class="m-t-20"><date-range end-date="endDate" interval="12" date-range-picked="dateRangePicked(startDate, endDate)"></date-range><div class="m-b-10"><h4>Account number:</h4> <input style="width: 300px;" type="text" class="form-control m-l-15" ng-model="data.accountNumber"></input></div></div>',
    })
    .state('advise.csv-reports-view.realized-gain-loss-report', {
      url: '/realized-gain-loss-report',
      controller: [
        '$scope',
        function ($scope) {
          const lastYear = new Date().getFullYear() - 1;
          $scope.endDate = new Date(lastYear, 11, 31);
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<div class="m-t-20"><date-range end-date="endDate" interval="12" date-range-picked="dateRangePicked(startDate, endDate)"></date-range><div class="m-b-10"><h4>Account number:</h4> <input style="width: 300px;" type="text" class="form-control m-l-15" ng-model="data.accountNumber"></input></div></div>',
    })
    .state('advise.csv-reports-view.realized-income-report', {
      url: '/realized-income-report',
      controller: [
        '$scope',
        function ($scope) {
          const lastYear = new Date().getFullYear() - 1;
          $scope.endDate = new Date(lastYear, 11, 31);
          $scope.dateRangePicked = $scope.$parent.dateRangePicked;
        },
      ],
      template:
        '<div class="m-t-20"><date-range end-date="endDate" interval="12" date-range-picked="dateRangePicked(startDate, endDate)"></date-range><div class="m-b-10"><h4>Account number:</h4> <input style="width: 300px;" type="text" class="form-control m-l-15" ng-model="data.accountNumber"></input></div></div>',
    })
    .state('advise.csv-reports-view.kyc-status-report', {
      url: '/kyc_status_report',
      controller: ['$scope', clientLevelReportController],
      template: clientLevelReportTemplate,
    })
    .state('advise.csv-reports-view.client-contact-list-report', {
      url: '/client_contact_list_report',
      controller: ['$scope', clientLevelReportController],
      template: clientLevelReportTemplate,
    })
    .state('advise.csv-reports-view.beneficiary-report', {
      url: '/beneficiary_report',
      controller: ['$scope', clientLevelReportController],
      template: clientLevelReportTemplate,
    })
    .state('advise.csv-reports-view.positions-by-day-report', {
      url: '/positions_by_day_report',
      controller: 'PositionsByDayReportCtrl',
      templateUrl: 'views/advise/reports/positions-by-day-report.html',
    });
}
