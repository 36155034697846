'use strict';

angular
  .module('model.PaginatedClient', [
    'ram',
    'config',
    'model.PaginationMeta',
    'model.ClientReadModel',
  ])
  .factory('PaginatedClient', ['ram', 'config', paginatedClientFactory]);

function paginatedClientFactory(ram, config) {
  /**
   * Constructor for PaginatedUser model.
   * @class
   */
  var PaginatedClient = new ram.Collection('PaginatedClient', {
    schema: config.schemas.PaginatedClient,
    hasOne: {
      paginationMeta: 'PaginationMeta',
    },
    hasMany: {
      clients: 'ClientReadModel',
    },
    resources: {
      default: new ram.resources.Http('/api/paginated_clients/:id.json'),
      cookie: new ram.resources.Cookie('paginated_clients'),
    },
  });

  return PaginatedClient;
}
