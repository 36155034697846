'use strict';

angular
  .module('controller.advisor-new-account.continue', ['model.Account', 'service.account-service'])
  .controller('AdvisorNewAccountContinueController', [
    '$scope',
    '$state',
    '$timeout',
    '$cookies',
    'accountService',
    'storedAccount',
    'storedGoal',
    'storedUser',
    'accounts',
    advisorNewAccountContinueController,
  ]);

function advisorNewAccountContinueController(
  $scope,
  $state,
  $timeout,
  $cookies,
  accountService,
  storedAccount,
  storedGoal,
  storedUser,
  accounts
) {
  if ($scope.newAccountFlow.status() === 'completed') {
    $state.go('advisor-new-account.stale');
  }

  var incompleteAccount = _getIncompleteAccount(accounts);
  if (incompleteAccount && incompleteAccount.progress() >= 5) {
    $state.go('onboarding.personal-combined');
    return;
  }

  storedAccount.portfolioOptionId($scope.newAccountFlow.portfolioOptionId());
  storedAccount.typeId($scope.newAccountFlow.accountTypeId());
  storedAccount.advisorCreateNewAccountFlowId($scope.newAccountFlow.id);
  storedAccount.nickname($scope.newAccountFlow.accountNickname());
  storedAccount.save();

  storedGoal.advisorRiskOffset(0);
  storedGoal.save();

  var current = _.pick(
    $scope.currentUser.toJSON(),
    'nonFinancialAssets',
    'financialAssets',
    'income',
    'riskAppetite',
    'totalLiabilities',
    'appetite0',
    'appetite1',
    'appetite2',
    'appetite3',
    'appetite4',
    'appetite5',
    'appetite6',
    'appetite7'
  );

  _.each(current, function (value, key) {
    if (value !== null && !_.isUndefined(value)) {
      storedUser[key](value);
    }
  });
  storedUser.save();

  $cookies.put('currentAdvisorNewAccountFlow', $scope.newAccountFlow.id);

  $cookies.remove('joint_applicant_email');
  const jointApplicantInfo = $scope.newAccountFlow.accountFlowJointApplicants()?.[0]; // jshint ignore:line
  if (storedAccount.type().canHaveJointApplicant && jointApplicantInfo) {
    $cookies.put('joint_applicant_email', jointApplicantInfo.email);
  }

  const collectJointApplicantsEmail =
    storedAccount.type.is.jointWithRightOfSurvivorship() ||
    (storedAccount.type().canHaveJointApplicant && !!$cookies.get('joint_applicant_email'));

  if (!storedGoal.isQuestionsComplete()) {
    _doDigestThenStateGo('interview.goal');
  } else if (collectJointApplicantsEmail) {
    _doDigestThenStateGo('interview.account-type');
  } else if (!storedUser.financialSituationComplete()) {
    _doDigestThenStateGo('interview.financial');
  } else if (!$scope.currentUser.riskSurveyComplete()) {
    _doDigestThenStateGo('interview.risk');
  } else {
    _doDigestThenStateGo('preview.investments');
  }

  function _doDigestThenStateGo(newState) {
    // Without this $timeout it was jumping to the wrong state.
    $timeout(function () {
      $state.go(newState);
    });
  }

  function _getIncompleteAccount(accounts) {
    return accountService.findIncompleteAccount(accounts);
  }
}
