'use strict';

angular
  .module('model.Beneficiary', [
    'ram',
    'config',
    'model.PersonalRelation',
    'model.Account',
    'model.Person',
  ])
  .factory('Beneficiary', ['ram', 'config', 'Person', beneficiaryFactory]);

function beneficiaryFactory(ram, config, Person) {
  var Beneficiary = new ram.Collection('Beneficiary', {
    belongsTo: {
      personalRelation: 'PersonalRelation',
      // primaryCaregiver: 'Person',  // This line and the next are broken.  Ram will change the Person associated with User when you change one of these.  Its a bug
      // legalGuardian: 'Person',
      account: 'Account',
    },
    schema: config.schemas.Beneficiary,
    serialize: ['personalRelation'],
    resources: {
      default: new ram.resources.Http('/api/beneficiaries/:id.json'),
      cookie: new ram.resources.Cookie('beneficiaries'),
    },
  });

  /**
   * Utility function to get an object for a 'belongsTo' relationship
   * This is a workaround until Ram can be fixed.
   *
   * @param _this - 'this' for the base object in question
   * @param accessor - The base name of the accessor
   * @returns ram.Accessor function that displays data (allows getter only)
   */
  function sideLoadPerson(_this, accessor) {
    var personId = _this[accessor + 'Id']();
    if (_.isUndefined(personId) || personId === null) {
      _this[accessor] = new ram.Accessor(function () {
        return '';
      });
    } else {
      Person.find({
        id: personId,
      }).then(function (person) {
        _this[accessor] = new ram.Accessor(person);
      });
    }
  }

  Beneficiary.prototype.initialize = function () {
    sideLoadPerson(this, 'primaryCaregiver');
    sideLoadPerson(this, 'legalGuardian');
    this._addressChanged = _.isUndefined(this._addressChanged) ? false : this._addressChanged;
    this._justCreated = _.isUndefined(this._justCreated) ? false : this._justCreated;
  };

  Beneficiary.getBeneficiaryRelationTypes = function (beneficiaryType, accountType) {
    var validFamilyRespRelations = ['child', 'grandchild', 'sibling'];
    return _.chain(config.types.PersonalRelation)
      .filter(function (relation) {
        return relation[beneficiaryType] !== -1;
      })
      .filter(function (relation) {
        return accountType !== 'family_resp'
          ? true
          : validFamilyRespRelations.includes(relation.name);
      })
      .sortBy(function (relation) {
        return relation[beneficiaryType];
      })
      .value();
  };

  /**
   * Load sub-objects
   * @returns an array of promises, will be fed to $q.all()
   */
  Beneficiary.prototype.loadDependantObjects = function () {
    return [
      this.personalRelation.ready(true),
      // In the future, primaryCaregiver and legalGuardian will go in this array, too.
    ];
  };

  Beneficiary.prototype.addressChanged = function (val) {
    if (!_.isUndefined(val)) {
      this._addressChanged = val;
    }
    return this._addressChanged;
  };

  Beneficiary.prototype.justCreated = function (val) {
    if (!_.isUndefined(val)) {
      this._justCreated = val;
    }
    return this._justCreated;
  };

  Beneficiary.prototype.label = function () {
    const relationType = [this.personalRelation().type().label];
    if (this.personalRelation().type.is.other() && this.personalRelation().otherRelationName()) {
      // handles null & empty string
      relationType.push(this.personalRelation().otherRelationName());
    }
    return `${this.personalRelation().relative().fullName()}, ${relationType.join(
      ', '
    )}, ${this.percent()}`;
  };

  Beneficiary.constructor.suggestedPercent = function (existingTotalPercent) {
    // The Ruby model won't accept zero.  Keep the old behaviour of suggesting 100 unless the user first changes the percentages to allow another fraction.
    return existingTotalPercent >= 100 ? 100 : 100 - existingTotalPercent;
  };

  return Beneficiary;
}
