'use strict';

class AccountBeneficiariesFormController {
  constructor(
    $scope,
    $http,
    $q,
    $timeout,
    ram,
    AccountBeneficiary,
    AdvisorBeneficiaryForm,
    Beneficiary,
    Person,
    PersonalRelation,
    SuccessorHolder,
    accountService,
    beneficiaryService,
    beneficiaryParamsGenerator,
    spouseOrPartner
  ) {
    this.$scope = $scope;
    this.$http = $http;
    this.$q = $q;
    this.$timeout = $timeout;
    this.ram = ram;
    this.AccountBeneficiary = AccountBeneficiary;
    this.AdvisorBeneficiaryForm = AdvisorBeneficiaryForm;
    this.Beneficiary = Beneficiary;
    this.Person = Person;
    this.PersonalRelation = PersonalRelation;
    this.SuccessorHolder = SuccessorHolder;
    this.accountService = accountService;
    this.beneficiaryService = beneficiaryService;
    this.beneficiaryParamsGenerator = beneficiaryParamsGenerator;
    this.spouseOrPartnerSerivce = spouseOrPartner;

    this.onBeneficiaryChange = this.onBeneficiaryChangeUnbound.bind(this);
    this.onPercentChange = this.onPercentChangeUnbound.bind(this);
    this.onPersonChange = this.onPersonChangeUnbound.bind(this);
    this.onPersonalRelationChange = this.onPersonalRelationChangeUnbound.bind(this);
    this.removeBeneficiary = this.removeBeneficiaryUnbound.bind(this);
  }

  $onInit() {
    this.$scope.isClientFilling = true;
    this.person = this.client;
    this.rowClass = '';
    this.form = this.getForm();
    if (!this.beneficiaries) {
      this.beneficiaries = [];
    }
    this.model = this.accountFlow ? 'AdvisorBeneficiaryForm' : 'Beneficiary';
    this.readOnlyFlags = 'spouse-name, spouse-relationship'; // used in `scope-set-read-only="$ctrl.readOnlyFlags"`
    this.autoSaveBoolean = this.autoSave === 'true';
    this.possessivePronoun = this.estateMsgPosition === 'prefill--at-top' ? "client's" : 'my';

    this.spouseRelationId = this._getSpouseRelation()?.id;
    this.spouseIsSuccessorHolder = new this.ram.Accessor(!!this.successorHolder);
    this.spousalBeneficiary = this.beneficiaries.find(
      (beneficiary) => beneficiary.personalRelationId() === this.spouseRelationId
    );
    this.spouseIsOneOfTheBeneficiaries = new this.ram.Accessor(!!this.spousalBeneficiary);

    this.otherBeneficiaries = this.beneficiaries.filter(
      (beneficiary) => beneficiary.personalRelationId() !== this.spouseRelationId
    );
    this.$scope.totalIsOneHundred = false;
    this._validatePercent();

    this.beneficiaryRelationTypes =
      this.Beneficiary.getBeneficiaryRelationTypes('regularBeneficiary');

    if (this.personalSubforms) {
      this.personalSubforms.saveBeneficiary = this._saveAccountBeneficiaryForm.bind(this);
    }

    this.$scope.$watch(
      '$ctrl.spouseIsSuccessorHolder()',
      (spouseIsSuccessorHolderNewValue, spouseIsSuccessorHolderOldValue) => {
        if (spouseIsSuccessorHolderNewValue === spouseIsSuccessorHolderOldValue) {
          return;
        }

        if (spouseIsSuccessorHolderNewValue) {
          if (!this.canHaveBeneficiaries()) {
            this.removeBeneficiaries();
          }
          this.addSuccessorHolder();
        } else {
          this.removeSuccessorHolder();
        }
      }
    );

    this.$scope.$watch(
      '$ctrl.spouseIsOneOfTheBeneficiaries()',
      (spouseIsOneOfTheBeneficiariesNewValue, spouseIsOneOfTheBeneficiariesOldValue) => {
        if (spouseIsOneOfTheBeneficiariesNewValue === spouseIsOneOfTheBeneficiariesOldValue) {
          return;
        }

        if (spouseIsOneOfTheBeneficiariesNewValue) {
          this.addSpousalBeneficiary();
        } else {
          this.removeSpousalBeneficiary();
        }
      }
    );

    this.$scope.$watch('$ctrl.spouse', (spouseNewValue, spouseOldValue) => {
      if (spouseNewValue === spouseOldValue) {
        return;
      }

      // if changed to married/common-law
      if (spouseNewValue) {
        if (!this.canHaveBeneficiaries()) {
          this.removeBeneficiaries();
        }
        this.setInitialSpouseValues();
      } else {
        this.spouseIsSuccessorHolder = new this.ram.Accessor(false);
        this.removeSuccessorHolder();

        this.spouseIsOneOfTheBeneficiaries = new this.ram.Accessor(false);
        this.removeSpousalBeneficiary();

        this.spouseRelationId = null;
      }
    });
  }

  getForm() {
    let parent = this.$scope.$parent;
    while (parent !== null) {
      if (Object.getOwnPropertyNames(parent).includes('form')) {
        return parent.form;
      }
      parent = parent.$parent;
    }
    return null;
  }

  setInitialSpouseValues() {
    this.spouseRelationId = this._getSpouseRelation()?.id;
    this.spouseIsSuccessorHolder = new this.ram.Accessor(false);
    this.spouseIsOneOfTheBeneficiaries = new this.ram.Accessor(false);
    this.spousalBeneficiary = null;
  }

  usesSuccessorHolder() {
    return (
      this.spouse &&
      this.spouseRelationId &&
      this.beneficiaryService.accountUsesSuccessorHolder(this.account)
    );
  }

  canHaveBeneficiaries() {
    const cannotHaveBeneficiaries =
      this.account.type.canHaveSuccessorHolder() &&
      this.spouse &&
      this.spouseRelationId &&
      this.spouseIsSuccessorHolder();
    return !cannotHaveBeneficiaries;
  }

  state() {
    return {
      inOnboarding: () => this.estateMsgPosition !== 'prefill--at-top',
      inPrefill: () => this.estateMsgPosition === 'prefill--at-top',
      single: () => !this.spouse || !this.spouseRelationId,
      married: () => this.spouse && this.spouseRelationId,
      spouseSelected: () => this.spouseIsOneOfTheBeneficiaries() || this.spouseIsSuccessorHolder(),
      hasBeneficiaries: () =>
        this.otherBeneficiaries.length > 0 || this.spouseIsOneOfTheBeneficiaries(),
      hasOtherBeneficiaries: () => this.otherBeneficiaries.length > 0,
      usesSuccessorHolder: this.usesSuccessorHolder.bind(this),
      canHaveBeneficiaries: this.canHaveBeneficiaries.bind(this),
      canHaveSuccessorHolder: () =>
        this.beneficiaryService.accountUsesSuccessorHolder(this.account),
    };
  }

  visibility() {
    const state = this.state();
    return {
      preamble: () => state.inPrefill(),
      shHeading: () => state.usesSuccessorHolder(),
      shHeadingSimple: () => state.inPrefill() && state.usesSuccessorHolder(),
      shHeadingCompound: () =>
        state.inOnboarding() && state.married() && state.usesSuccessorHolder(),
      designateSH: () => state.married() && state.usesSuccessorHolder(),
      shEdit: () => state.married() && state.spouseSelected() && state.usesSuccessorHolder(),
      beneHeadingSimple: () =>
        state.usesSuccessorHolder() && (state.spouseSelected() || state.hasOtherBeneficiaries()),
      beneHeadingCompound: () =>
        state.inOnboarding() &&
        ((state.canHaveSuccessorHolder() && state.single()) || !state.usesSuccessorHolder()),
      beneHeadingSpacer: () => state.married() && !state.canHaveSuccessorHolder(),
      designateSpouseBene: () => state.married() && !state.usesSuccessorHolder(),
      spouseBeneEdit: () =>
        state.married() && state.spouseSelected() && !state.usesSuccessorHolder(),
      otherBeneEdit: () => state.canHaveBeneficiaries(),
      contingentBene: () =>
        state.married() && state.spouseSelected() && state.usesSuccessorHolder(),
      addButton: () => state.canHaveBeneficiaries(),
      noSpouseBeneSpacer: () =>
        state.inPrefill() &&
        state.single() &&
        (state.hasOtherBeneficiaries() || !state.hasBeneficiaries()),

      dataExists: () =>
        this.otherBeneficiaries.length > 0 ||
        this.spouseIsOneOfTheBeneficiaries() ||
        this.spouseIsSuccessorHolder(),
      beneTopLine: () => !state.usesSuccessorHolder() && state.married(),
    };
  }

  addSuccessorHolder() {
    if (this.model === 'AdvisorBeneficiaryForm') {
      this.successorHolder = this._createAccountBeneficiaryForm(
        'SuccessorHolder',
        this.spouseRelationId
      );
      return;
    }

    this.successorHolder = new this.SuccessorHolder({
      accountId: this.account.id,
      personalRelationId: this.spouseRelationId,
    });

    this.successorHolder.save();
  }

  removeSuccessorHolder() {
    this.successorHolder?.destroy();
    this.successorHolder = null;
  }

  removeBeneficiaries() {
    this.otherBeneficiaries.forEach((beneficiary) => {
      // removeBeneficiaryUnbound will splice the array, rendering the forEach invalid
      beneficiary?.destroy();
    });
    this.otherBeneficiaries = [];
  }

  addBeneficiary() {
    this.otherBeneficiaries.push(this._createNewBeneficiary());
    this.onBeneficiaryChangeUnbound();
  }

  removeBeneficiaryUnbound(beneficiary) {
    beneficiary?.destroy();

    const index = _.indexOf(this.otherBeneficiaries, beneficiary);
    if (index >= 0) {
      this.otherBeneficiaries.splice(index, 1);
    }

    this.onBeneficiaryChangeUnbound();
  }

  addSpousalBeneficiary() {
    this.spousalBeneficiary = this._createNewBeneficiary(this.spouseRelationId);
    this.onBeneficiaryChangeUnbound();
  }

  removeSpousalBeneficiary() {
    this.spousalBeneficiary?.destroy();
    this.spousalBeneficiary = null;
    this.onBeneficiaryChangeUnbound();
  }

  onPersonChangeUnbound(person) {
    if (!this.autoSaveBoolean) {
      return;
    }
    return person.save();
  }

  onPersonalRelationChangeUnbound(personalRelation) {
    if (!this.autoSaveBoolean) {
      return;
    }
    return personalRelation.save();
  }

  onPercentChangeUnbound(beneficiary) {
    if (beneficiary.percent() !== null && beneficiary.percent() !== '') {
      let value = parseFloat(beneficiary.percent());
      value = Math.round(value * 100) / 100;
      beneficiary.percent(value.toString(10));
    }

    this.onBeneficiaryChangeUnbound(beneficiary);
  }

  onBeneficiaryChangeUnbound(beneficiary) {
    this._validatePercent();

    if (this.$scope.totalIsOneHundred) {
      // This is sensitive to timing.  Allow the pending error to be recorded before clearing them all.
      this.$timeout(() => {
        this._clearPercentMessages();
      });
    } else {
      // This is needed so that the percent inputs are set as invalid when a beneficiary is removed
      this._validatePercentFields();
    }
    if (!this.autoSaveBoolean) {
      return;
    }

    return beneficiary?.save();
  }

  estateIsBeneficiary() {
    return !this.spouseIsOneOfTheBeneficiaries() && this.otherBeneficiaries.length === 0;
  }

  spouseOrPartner() {
    return this.spouseOrPartnerSerivce.relationshipName(this.person);
  }

  spouseOrPartnerName() {
    return this.spouse?.fullName()?.trim();
  }

  _getSpouseRelation() {
    return this.person
      .personalRelations()
      .find((relation) => relation.type.is.spouse() || relation.type.is.commonLaw());
  }

  _validatePercent() {
    this.$scope.totalIsOneHundred = this._calcTotalPercent() === 100.0;
  }

  _validatePercentFields() {
    const percentFormKeys = Object.getOwnPropertyNames(this.form).filter((key) =>
      key.startsWith('percent-')
    );
    const formControls = percentFormKeys.map((key) => this.form[key]);
    formControls.forEach((control) => control.$validate());
  }

  _clearPercentMessages() {
    // The $error array mutates as you work with it.  We need to act on all that are here at the start.
    const errors = this.form.$error.percentagestotaltoonehundred;
    const savedErrors =
      errors === undefined || errors.length === 0
        ? []
        : [...this.form.$error.percentagestotaltoonehundred];

    for (const control of savedErrors) {
      if (!control.$name) {
        continue;
      }
      angular.element('[name=' + control.$name + ']').trigger('blur');
      control.$setValidity('percentagestotaltoonehundred', true);
    }
  }

  _createNewBeneficiary(spouseRelationId) {
    if (this.model === 'AdvisorBeneficiaryForm') {
      return this._createAccountBeneficiaryForm('Beneficiary', spouseRelationId);
    }

    const beneficiary = new this.AccountBeneficiary({
      userId: this.client.userId(),
      accountId: this.account.id,
      percent: this.Beneficiary.constructor.suggestedPercent(this._calcTotalPercent()),
    });
    if (spouseRelationId) {
      beneficiary.personalRelationId(spouseRelationId);
    }

    if (this.autoSaveBoolean) {
      beneficiary.save();
    }
    return beneficiary;
  }

  _createAccountBeneficiaryForm(modelType, personalRelationId) {
    const params = this.beneficiaryParamsGenerator.getAdvisorBeneficiaryFormParams(
      this.client.user().id,
      this.accountFlow.id,
      personalRelationId
    );
    const beneficiary = this.AdvisorBeneficiaryForm.new(params);
    if (modelType === 'SuccessorHolder') {
      beneficiary.type(this.SuccessorHolder.name);
    } else {
      beneficiary.type(this.AccountBeneficiary.name);
      beneficiary.percent(this.Beneficiary.constructor.suggestedPercent(this._calcTotalPercent()));
    }
    return beneficiary;
  }

  _saveAccountBeneficiaryForm() {
    const saveResults = [];

    if (this.successorHolder) {
      saveResults.push(this.successorHolder.save());
    }

    if (this.spousalBeneficiary) {
      saveResults.push(this.spousalBeneficiary.save());
    }

    if (this.otherBeneficiaries) {
      this.otherBeneficiaries.forEach((beneficiary) => {
        if (this.model === 'Beneficiary') {
          saveResults.push(beneficiary.personalRelation().save());
          saveResults.push(beneficiary.personalRelation().relative().save());
        }
        saveResults.push(beneficiary.save());
      });
    }

    return this.$q.all(saveResults);
  }

  _calcTotalPercent() {
    const reduceFn = (sum, bene) => sum + parseFloat(bene.percent());
    const total =
      this.otherBeneficiaries.reduce(reduceFn, 0.0) +
      (this.spouseIsOneOfTheBeneficiaries() && this.spousalBeneficiary
        ? parseFloat(this.spousalBeneficiary.percent())
        : 0);
    // 33.3 + 33.3 + 33.4 = 99.999999999
    // Use 3 digits because user can input two.   So 33.33 + 33.33 + 33.33 = 99.99 will not validate.
    return Number(total.toFixed(3));
  }
}

export default AccountBeneficiariesFormController;
