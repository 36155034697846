'use strict';

function adviseReferralController($scope, referral, referralService) {
  $scope.referral = referral;
  $scope.referralService = referralService;

  $scope.completeReferral = function () {
    $scope.referral.status('completed');
    return $scope.referral.save();
  };
}

angular
  .module('controller.advise.referral', ['ui.bootstrap', 'service.advisor.referral-service'])
  .controller('AdviseReferralCtrl', [
    '$scope',
    'referral',
    'referralService',
    adviseReferralController,
  ]);
