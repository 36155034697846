'use strict';

angular
  .module('controller.advise.run-fees', ['service.csv-table-creator'])
  .directive('customOnChange', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var onChangeFunc = scope.$eval(attrs.customOnChange);
        element.bind('change', function () {
          scope.$apply(onChangeFunc);
        });
      },
    };
  })
  .controller('AdviseRunFeesCtrl', [
    '$scope',
    '$document',
    '$http',
    'csvTableCreator',
    adviseRunFeesController,
  ]);

function adviseRunFeesController($scope, $document, $http, csvTableCreator) {
  var date = new Date();
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var textFromFileLoaded = null;
  var fileInput = $document[0].getElementById('select-file');
  date.setDate(0); // last day of previous month
  $scope.month = months[date.getMonth()] + ' ' + date.getFullYear();
  $scope.fileName = 'Please select your file';

  $scope.closeAlert = function () {
    $scope.alert = null;
  };

  $scope.selectFile = function () {
    fileInput.click();
  };

  $scope.showFileName = function () {
    if (!fileInput.files[0]) {
      $scope.fileName = 'Please select your file.';
    } else {
      $scope.fileName = fileInput.files[0].name;
    }
  };

  $scope.loadFile = function () {
    var file = fileInput.files[0];
    if (!file) {
      $scope.alert = {
        msg: 'No file selected.',
      };
      return;
    }
    var fileReader = new FileReader();
    fileReader.readAsText(file, 'utf-8');
    fileReader.onload = function (fileLoadedEvent) {
      var lines = [];
      var header = '';
      var error = false;
      textFromFileLoaded = fileLoadedEvent.target.result;
      lines = textFromFileLoaded.split('\n');
      if (lines.length <= 1) {
        error = true;
      }
      if (!error) {
        header = lines[0].replace(/,/g, '').replace(/\s+/g, '');
        if (
          !header.match(
            /^AccountcusipMgtCompanyCodeFundTypeTrailerRep_CodeProcess_DateTrailer_Share/
          )
        ) {
          error = true;
        }
      }
      if (error) {
        $scope.alert = {
          msg: 'This is not a Trailer Share file.',
        };
        return;
      }
      document.getElementById('inputTextToSave').innerHTML =
        csvTableCreator.csvToHtmlTable(textFromFileLoaded);
    };
  };

  $scope.runFees = function () {
    if (
      confirm(
        `Are you sure you want to run the fees for ${
          months[date.getMonth()] + ' ' + date.getFullYear()
        }?`
      ) === false
    ) {
      return;
    }

    $http
      .post('/api/super_advisor/charge/run_fees.json', {
        month: date.getMonth() + 1, // month 1-12
        year: date.getFullYear(),
      })
      .then(function (respond) {
        $scope.alert = {
          msg: respond.data,
          type: 'success',
        };
      })
      .catch(function (respond) {
        $scope.alert = {
          msg: respond.data,
          type: 'danger',
        };
      });
  };

  $scope.submitFile = function () {
    if (!textFromFileLoaded) {
      $scope.alert = {
        msg: 'Please load a file first.',
      };
    } else {
      $http
        .post('/api/super_advisor/charge/trailer_share.json', {
          fileName: $scope.fileName,
          month: parseInt($scope.fileName.match(/\d+/)[0].substring(4, 6)),
          year: parseInt($scope.fileName.match(/\d+/)[0].substring(0, 4)),
          content: textFromFileLoaded,
        })
        .then(function (respond) {
          $scope.alert = {
            msg: respond.data,
            type: 'success',
          };
        })
        .catch(function (respond) {
          $scope.alert = {
            msg: respond.data,
            type: 'danger',
          };
        });
    }
  };
}
