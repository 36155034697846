'use strict';

/**
 * URL of YQL public API
 * @type {String}
 */
var url = 'http://query.yahooapis.com/v1/public/yql';

/**
 * Builds a historical data query for use with the YQL API.
 *
 * @param  {Array}  symbols A list of ticker symbols to query
 * @param  {Date}   start   Start date for the historical data
 * @param  {Date}   end     End date for the historical data
 * @return {String}         Querystring to append to the URL
 */
function query(symbols, start, end) {
  // Yahoo appends .TO onto TSX symbols
  symbols = _.map(symbols, function (symbol) {
    return symbol + '.TO';
  });

  var q = encodeURIComponent(
    [
      'select Symbol, Date, Adj_Close from yahoo.finance.historicaldata where symbol in ("',
      symbols.join('","'),
      '") and startDate = "',
      moment(start).format('YYYY-MM-DD'),
      '" and endDate = "',
      moment(end).format('YYYY-MM-DD'),
      '"',
    ].join('')
  );

  return (
    '?' + ['q=' + q, 'env=http%3A%2F%2Fdatatables.org%2Falltables.env', 'format=json'].join('&')
  );
}

/**
 * Singleton factory provides an API for fetching fund performance data.
 *
 * @param  {Object} $http Http service
 * @return {Object}       performance API
 */
function performanceFactory($http) {
  /**
   * Fetch historical performance data with the date range from start to and
   * for funds in symbols. Calls callback with a list of quotes containing
   * the fields symbol, close, and date.
   *
   * @param  {Array}    symbols  A list of symbols to lookup
   * @param  {Date}     start    The start time for the query
   * @param  {Date}     end      The end time for the query
   * @param  {Function} callback A function provider by the API consumer that
   *                             gets called when the query successfully
   *                             completes.
   */
  function get(symbols, start, end, callback) {
    $http.get(url + query(symbols, start, end)).success(function (data) {
      var response = data && data.query && data.query.results && data.query.results.quote;

      var quotes = _.map(response, function (quote) {
        /* jshint camelcase: false */
        return {
          symbol: quote.Symbol.replace('.TO', ''),
          close: quote.Adj_Close,
          date: new Date(quote.Date),
        };
      });

      callback(quotes);
    });
  }

  return {
    get,
  };
}

angular
  .module('service.funds.performance', [])
  .factory('performance', ['$http', performanceFactory]);
