'use strict';

angular
  .module('routes.profile.bank', [
    'controller.profile.bank',
    'model.User',
    'templates',
    'ui.router',
  ])
  .config(['$stateProvider', configRoutes]);

function configRoutes($stateProvider) {
  function resolveCurrentUserBankAccounts(currentUser) {
    return currentUser.bankAccounts.ready(true);
  }

  $stateProvider
    .state('profile.bank', {
      url: '/bank',
      abstract: true,
      template: '<div class="disp-flex flx-dir-col flx-g-1" ui-view></div>',
    })
    .state('profile.bank.view', {
      url: '/view',
      data: {
        menuTitle: 'Banking and Finance',
        backButtonState: 'profile.nav',
      },
      templateUrl: 'views/profile/bank/view.html',
      resolve: {
        bankAccounts: ['currentUser', resolveCurrentUserBankAccounts],
      },
    })
    .state('profile.bank.add', {
      url: '/add',
      data: {
        menuTitle: 'Add a Bank Account',
      },
      controller: 'ProfileBankAddCtrl',
      templateUrl: 'views/profile/bank/add.html',
      resolve: {
        institutions: [
          'bankingResolveHelpers',
          function (bankingResolveHelpers) {
            return bankingResolveHelpers.resolveInsitutions();
          },
        ],
      },
    })
    .state('profile.bank.confirm-email', {
      url: '/confirm-email',
      data: {
        menuTitle: 'Confirm your email',
      },
      controller: 'ProfileBankEmailCtrl',
      templateUrl: 'views/profile/bank/confirm-email.html',
    })
    .state('profile.bank.add-success', {
      url: '/add-success',
      data: {
        menuTitle: 'Add a Bank Account',
      },
      controller: 'ProfileBankSuccessCtrl',
      templateUrl: 'views/profile/bank/add-success.html',
      resolve: {
        bankAccounts: ['currentUser', resolveCurrentUserBankAccounts],
      },
    });
}
