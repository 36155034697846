'use strict';

function securedDirective($rootScope) {
  return {
    scope: true,
    restrict: 'EA',
    transclude: true,
    replace: true,

    link: function ($scope, element, attrs, ctrl, $transclude) {
      var requiredPermission = attrs.requiredPermission;
      var resource = attrs.resource;

      var hasAccess = function () {
        var security = $rootScope.security;
        return security && security.can(requiredPermission, resource);
      };

      $transclude(function (clone, scope) {
        if (hasAccess()) {
          if (element.prop('tagName').toUpperCase() === 'SECURED') {
            // if replacing element - replace the whole thing, so it doesn't change HTML structure
            // it's useful if styles depend on exact structure e.g. .menu > li > a
            element.replaceWith(clone);
          } else {
            // if using attribute - don't replace the whole thing
            element.append(clone);
          }
        } else {
          // remove both transcluded element AND original element - we don't want it since it's not allowed
          element.remove();
          $scope.$destroy();

          clone.remove();
          scope.$destroy();
        }
      });
    },
  };
}

angular.module('directive.secured', []).directive('secured', ['$rootScope', securedDirective]);
