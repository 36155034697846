'use strict';

function factory() {
  var CodeErrorHandler = function (code, handlerCallback) {
    this.code = code;
    this.handlerCallback = handlerCallback;
  };

  CodeErrorHandler.getErrorCode = function (error) {
    var code = null;
    if (error && error.data && error.data.code) {
      code = error.data.code;
    }
    return code;
  };

  CodeErrorHandler.prototype.canHandle = function (error) {
    return this.code === CodeErrorHandler.getErrorCode(error);
  };
  CodeErrorHandler.prototype.handle = function (error) {
    this.handlerCallback(error);
  };

  return CodeErrorHandler;
}

angular.module('service.error.CodeErrorHandler', []).factory('CodeErrorHandler', [factory]);
