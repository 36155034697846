'use strict';

// import React from 'react'; // jshint ignore:line
import maReact2Angular from '../maReact2Angular';
import UploadMutualFundTrades from './UploadMutualFundTrades';

angular
  .module('react.component.advise.upload-mutual-fund-trades', [
    'config',
    'csrf',
    'service.user-notifications',
    'model.OrderTradeTicket',
  ])
  .component(
    'reactUploadMutualFundTrades',
    maReact2Angular(
      UploadMutualFundTrades,
      {
        missingTradeFileInfo: '<',
      },
      ['csrfToken', 'userNotifications', 'OrderTradeTicket']
    )
  );
