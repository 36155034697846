'use strict';

angular
  .module('directive.input.currency', [
    'templates',
    'animations',
    'directive.focus',
    'service.adaptive-views',
  ])
  .directive('currency', ['adaptiveViews', currencyMaskDirective])
  .directive('nakedCurrency', ['adaptiveViews', nakedCurrencyDirective]);

var currencyScope = {
  ngModel: '=',
  ngChange: '&',
  ngDisabled: '&',
  ngMin: '=',
  ngMax: '=',
  ngMaxDigit: '=',
  form: '=',
  help: '@',
  focusHelp: '@',
  title: '@',
  label: '@',
  name: '@',
  class: '@',
  placeholder: '@',
  inputClass: '@',
  labelClass: '@',
  helpClass: '@',
  prefix: '@',
  suffix: '@',
  isOptional: '@',
  displayCents: '@',
  textAfterInput: '@',
};

/**
 * This directive supplies a box with help text for entering currency amounts.
 * On mobile platforms it will use a 'tel' keypad where available.  It uses a jQuery mask to automatically
 * insert commas and a dollar sign as the user types.
 */
function currencyMaskDirective() {
  return {
    restrict: 'E',
    scope: currencyScope,
    templateUrl: 'directives/input/number.html',
    controller: ['$scope', 'adaptiveViews', currencyController],
    link: currencyLink,
  };
}

/**
 * This is similar to <currency> however it has no other constructs wrapping it.  No help or label.
 * It otherwise behaves the same.
 */
function nakedCurrencyDirective() {
  return {
    restrict: 'E',
    scope: currencyScope,
    templateUrl: 'directives/input/naked-number.html',
    controller: ['$scope', 'adaptiveViews', currencyController],
    link: currencyLink,
  };
}

function currencyController($scope, adaptiveViews) {
  _setMobilePlatform($scope, adaptiveViews);

  $scope.maxLength = 1 + Math.floor(($scope.ngMaxDigit - 1) / 3) + $scope.ngMaxDigit;

  $scope.model = function (val) {
    if (val === undefined) {
      var amount = $scope.ngModel();
      return $scope.formattedNumber(amount);
    } else {
      $scope.ngModel($scope.rawNumber());
    }
  };

  var localOptions = {};
  if ($scope.displayCents) {
    localOptions = {
      updateOn: 'blur change',
    };
  }
  $scope.modelOptions = _.extend(
    {
      getterSetter: true,
      allowInvalid: true,
    },
    localOptions
  );
}

function currencyLink(scope, elementParent, attrs, ngModelCtrl) {
  var element = elementParent.find('input.currency');
  element.currencyMask('#,##0', {
    allowCents: scope.displayCents,
  });

  if (ngModelCtrl.$parsers) {
    ngModelCtrl.$parsers.unshift(function () {
      return element.currencyCleanVal();
    });
  }

  if (ngModelCtrl.$formatters) {
    ngModelCtrl.$formatters.unshift(function (value) {
      return element.currencyMasked(value);
    });
  }

  scope.rawNumber = function () {
    var retVal = parseFloat(element.currencyCleanVal());
    return retVal;
  };

  scope.formattedNumber = function (value) {
    var retVal = element.currencyMasked('' + value);
    return retVal;
  };

  element.on('$destroy', function () {
    element.currencyUnmask();
  });

  _checkMinMax(scope);
}

/**
 * Sets scope variables for mobile platforms
 * @param {[type]} scope
 * @param {[type]} adaptiveViews
 */
function _setMobilePlatform(scope, adaptiveViews) {
  scope.keyboardType = 'tel';
  scope.isSafari = scope.isMobile && adaptiveViews.isSafari();
  scope.isAndroid = scope.isMobile && !scope.isSafari; // This is a general assumption - If it isn't Safari on Mobile then it must be Android.

  if (scope.isMobile) {
    if (scope.isSafari && scope.displayCents) {
      scope.keyboardType = 'text';
    }
  }
}

/**
 * Min / max checking for currency directives
 * @param  {[type]} scope
 */
function _checkMinMax(scope) {
  // This needs to be re-visited because scope.form is undefined on the home page and it never worked.
  if (scope.form !== undefined && scope.name !== undefined) {
    if (_.isNumber(scope.ngMin)) {
      scope.form[scope.name].$validators.min = function () {
        return (
          (scope.isOptional && (!_.isNumber(scope.ngModel()) || _.isNaN(scope.ngModel()))) ||
          scope.ngModel() >= scope.ngMin
        );
      };
    }

    if (_.isNumber(scope.ngMax)) {
      scope.form[scope.name].$validators.max = function () {
        return (
          (scope.isOptional && (!_.isNumber(scope.ngModel()) || _.isNaN(scope.ngModel()))) ||
          scope.ngModel() <= scope.ngMax
        );
      };
    }
  }
}
