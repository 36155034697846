'use strict';

angular
  .module('directive.percentages-total-to-one-hundred', [])
  .directive('percentagesTotalToOneHundred', [percentagesTotalToOneHundredDirective])
  .directive('percentageGreaterThanZero', [percentageGreaterThanZero])
  .directive('percentageLessThanOneHundred', [percentageLessThanOneHundred]);

function percentagesTotalToOneHundredDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $el, attrs, ctrl) {
      ctrl.$validators.percentagestotaltoonehundred = function () {
        let parent = scope.$parent;
        while (parent !== null) {
          if (Object.getOwnPropertyNames(parent).includes('totalIsOneHundred')) {
            return parent.totalIsOneHundred;
          }
          parent = parent.$parent;
        }
        // If you don't find it, everything is fine.
        return true;
      };
    },
  };
}

function percentageGreaterThanZero() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $el, attrs, ctrl) {
      ctrl.$validators.percentagegreaterthanzero = function (newValue) {
        return parseFloat(newValue) > 0;
      };
    },
  };
}

function percentageLessThanOneHundred() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, $el, attrs, ctrl) {
      ctrl.$validators.percentagelessthanonehundred = function (newValue) {
        return parseFloat(newValue) <= 100;
      };
    },
  };
}
