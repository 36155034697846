'use strict';

/* global moment, s, _ */

angular
  .module('directive.user.date-of-birth-dropdowns', ['ram', 'service.adaptive-views'])
  .directive('dateOfBirthDropdowns', [
    '$timeout',
    'adaptiveViews',
    'ram',
    dateOfBirthDropdownsDirective,
  ])
  .directive('validateDateOfBirthHiddenInput', [validateDateOfBirthHiddenInputDirective]);

var padDateFragment = function (number) {
  return s.pad(number, 2, '0');
};

var createMonths = function () {
  return _.map(moment.months(), function (month, index) {
    return {
      label: month,
      monthNumber: padDateFragment(index + 1), // monthNumber: [1..12]
    };
  });
};

function dateOfBirthDropdownsDirective($timeout, adaptiveViews, ram) {
  function walkGetForm($scope) {
    while ($scope.$parent) {
      if (!_.isUndefined($scope.form)) {
        return $scope.form;
      }
      $scope = $scope.$parent;
    }
  }

  return {
    restrict: 'E',
    scope: {
      minYearsOld: '@',
      person: '=',
      onChange: '&',
      form: '=',
      optional: '=',
    },
    templateUrl: 'directives/user/date-of-birth-dropdowns.html',
    link: function ($scope, element) {
      $scope.form = $scope.form || walkGetForm($scope);
      $scope.isMobile = adaptiveViews.isMobile();

      $scope.months = createMonths();

      $scope.birthDateMonth = new ram.Accessor(null);
      $scope.birthDateDay = new ram.Accessor(null);
      $scope.birthDateYear = new ram.Accessor(null);
      $scope.birthDate = new ram.Accessor(null);

      var initBirthDate = function (person) {
        var date = person && person.birthDate();
        if (date) {
          const momentDate = moment(date);

          $scope.birthDateMonth(padDateFragment(momentDate.month() + 1));
          $scope.birthDateDay(padDateFragment(momentDate.date()));
          $scope.birthDateYear(momentDate.year());
        }
      };

      var createDateString = function () {
        if ($scope.birthDateMonth() && $scope.birthDateDay() && $scope.birthDateYear()) {
          return (
            $scope.birthDateMonth() +
            '-' +
            padDateFragment($scope.birthDateDay()) +
            '-' +
            $scope.birthDateYear()
          );
        }
        return '';
      };

      var isDirty = function () {
        if (!$scope.form) {
          return false;
        }

        var monthModelCtrl = $scope.form['birthDateMonth' + $scope.$id];
        var dayModelCtrl = $scope.form['birthDateDay' + $scope.$id];
        var yearModelCtrl = $scope.form['birthDateYear' + $scope.$id];

        return (
          monthModelCtrl &&
          monthModelCtrl.$dirty &&
          dayModelCtrl &&
          dayModelCtrl.$dirty &&
          yearModelCtrl &&
          yearModelCtrl.$dirty
        );
      };

      var updateBirthDate = function (newValue, oldValue) {
        var dateString = createDateString();
        $scope.birthDate(dateString);

        var dateInput = element.find('input[name=birthDate' + $scope.$id + ']');

        if (newValue !== oldValue) {
          dateInput.trigger('change');
        }

        var modelCtrl = dateInput.data('$ngModelController');
        if (modelCtrl && isDirty()) {
          modelCtrl.$setDirty(true);
        }

        const date = moment(dateString, 'MM-DD-YYYY', true).utc(true); // Passing true will change the time zone [to UTC] without changing the current time.
        if (new Date().getTimezoneOffset() > 0) {
          date.add(12, 'hours'); // Put data for viewing in "Vancouver" time zone at Noon UTC, so it resolves to a GMT-8 JavaScript datetime on the same day.
        }
        if (date.isValid()) {
          if ($scope.onChange) {
            $scope.onChange({
              person: $scope.person,
              date,
            });
          }
        }
      };

      var onDateFragmentChange = function (newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }
        updateBirthDate(newValue, oldValue);
      };

      initBirthDate($scope.person);
      updateBirthDate();

      $scope.$watch('birthDateMonth()', onDateFragmentChange);
      $scope.$watch('birthDateDay()', onDateFragmentChange);
      $scope.$watch('birthDateYear()', onDateFragmentChange);
      $scope.$watch('form["birthDate' + $scope.$id + '"].$invalid', function () {
        if ($scope.form) {
          var dayModelCtrl = $scope.form['birthDateDay' + $scope.$id];
          if (dayModelCtrl) {
            // force validate so that the day input validity stays
            // in sync with the hidden input's validity
            dayModelCtrl.$validate();
          }
        }
      });
    },
  };
}

/**
 * Needed so that one of the visible date of birth inputs gets
 * focused on form submit when invalid (since hidden fields
 * cannot be focused).
 */
function validateDateOfBirthHiddenInputDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function ($scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.validhiddeninput = function (modelValue) {
        if (ngModelCtrl.$isEmpty(modelValue)) {
          return true;
        }

        var hiddenInput = $scope.form['birthDate' + $scope.$id];
        if (hiddenInput && hiddenInput.$invalid) {
          return false;
        } else {
          return true;
        }
      };
    },
  };
}
