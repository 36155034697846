'use strict';

function dropdownDirective() {
  return {
    restrict: 'E',
    scope: {
      options: '=',
    },
    transclude: true,
    templateUrl: 'directives/dropdown.html',
    controllerAs: 'ctrl',

    link: function ($scope, element, attrs) {
      $scope.disabled = $scope.$eval(attrs.disabled) || false;

      attrs.$observe('value', function (selectedValue) {
        $scope.selectedOption = _.find($scope.options, function (option) {
          return option.value + '' === selectedValue + '';
        });
        if (!$scope.selectedOption) {
          $scope.selectedOption = $scope.options[0];
        }
      });

      var optionsElement = element.find('.ma-dropdown-options');

      $scope.dropdownExpanded = false;
      $scope.toggleDropdown = function () {
        if ($scope.disabled || $scope.options.length <= 1) {
          return;
        }

        $scope.dropdownExpanded = !$scope.dropdownExpanded;
        if ($scope.dropdownExpanded) {
          element
            .find('.ma-dropdown-options')
            .show()
            .animate(
              {
                height: 'toggle',
              },
              0
            )
            .animate(
              {
                height: 'toggle',
              },
              300
            );
        } else {
          element.find('.ma-dropdown-options').animate(
            {
              height: 'toggle',
            },
            300,
            function () {
              optionsElement.hide();
            }
          );
        }
      };

      $scope.selectOption = function (option, $event) {
        if (!$scope.disabled && option.callback) {
          $scope.toggleDropdown();
          option.callback($event);
        }
      };
    },
  };
}

angular.module('directive.dropdown', []).directive('dropdown', [dropdownDirective]);
