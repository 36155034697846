'use strict';

angular
  .module('controller.profile', [
    'directive.input',
    'directive.profile-form',
    'controller.profile.personal',
    'controller.profile.bank',
    'controller.profile.finance',
    'controller.profile.legal',
    'controller.profile.risk',
    'controller.profile.account-settings',
    'controller.profile.two-factor-authentication',
    'controller.profile.household',
    'controller.profile.test',
    'multiStepForm',
    'service.feature-toggles',
    'service.init-unsaved-changes-tracking',
    'service.user-notifications',
  ])
  .controller('ProfileCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$state',
    '$http',
    'config',
    'person',
    'brokerageAccounts',
    'insiderRoles',
    'accounts',
    'userNotifications',
    'unsavedChangesTracking',
    'featureToggles',
    profileController,
  ]);

function profileController(
  $rootScope,
  $scope,
  $window,
  $state,
  $http,
  config,
  person,
  brokerageAccounts,
  insiderRoles,
  accounts,
  userNotifications,
  unsavedChangesTracking,
  featureToggles
) {
  $scope.config = config;
  $scope.person = person;

  $scope.brokerageAccounts = brokerageAccounts;
  $scope.insiderRoles = insiderRoles;

  $scope.accounts = accounts;

  $scope.isHouseholdingFeatureEnabled = featureToggles.isEnabled('householding');

  unsavedChangesTracking.init($rootScope, $scope, $window, $state);

  $scope.$on('$stateChangeStart', function () {
    document.activeElement.blur();
  });

  $scope.accountsWithBeneficiaries = $scope.accounts.filter(function (account) {
    return account.type().beneficiary;
  });

  $scope.$on('profileUpdated', function () {
    $http.post('/api/update_records/audit', {
      reason: 'Profile Update',
    });
    userNotifications.showSuccess('Thank you. Changes have been saved.');
  });

  $scope.$on('maritalStatusChangeFailed', function (_, message) {
    userNotifications.showInfo(message);
  });

  function setIfBankViewActivated(toState) {
    // This is needed because "ui-sref-active" will only set the active class
    // if the current state matches or is a child of the state in "ui-sref".
    // In our case, we want to navigate to profile.bank.view by default but
    // also consider the nav item activated if the state starts with
    // "profile.bank".
    $scope.bankViewActivated = toState.name && toState.name.startsWith('profile.bank');
  }

  $scope.$on('$stateChangeStart', function (event, toState) {
    if (/^profile$/.test(toState.name)) {
      event.preventDefault();
      $state.transitionTo(
        toState,
        {},
        {
          reload: true,
          inherit: false,
          notify: false,
        }
      );
    }
  });

  $scope.$on('$stateChangeSuccess', function (event, toState) {
    if (/profile\./.test(toState.name)) {
      setIfBankViewActivated(toState);
    }
  });
}
