'use strict';

angular
  .module('model.TransferRequest', [
    'model.Account',
    'model.BankAccount',
    'model.TransferInstruction',
    'model.Custodian',
    'ram',
  ])
  .factory('TransferRequest', [
    '$http',
    '$q',
    'config',
    'ram',
    'Account',
    'BankAccount',
    'Custodian',
    factory,
  ]);

function factory($http, $q, config, ram, Account, BankAccount, Custodian) {
  var TransferRequest = new ram.Collection('TransferRequest', {
    belongsTo: {
      account: 'Account',
      bankAccount: 'BankAccount',
      transferInstruction: 'TransferInstruction',
    },
    enums: {
      type: config.types.TransferRequest,
      status: config.types.TransferRequestStatus,
    },
    resources: {
      default: new ram.resources.Http(config.schemas.TransferRequest.resourceApiUrl),
      cookie: new ram.resources.Cookie('transfer_requests'),
    },
    schema: config.schemas.TransferRequest,
  });

  TransferRequest.prototype.getFromObject = function () {
    if (this.transferFromType() === 'Account') {
      return Account.find({
        id: this.transferFromId(),
      });
    } else if (this.transferFromType() === 'BankAccount') {
      return BankAccount.find({
        id: this.transferFromId(),
      });
    }
  };

  TransferRequest.prototype.getToObject = function () {
    if (this.transferToType() === 'Account') {
      return Account.find({
        id: this.transferToId(),
      });
    } else if (this.transferToType() === 'BankAccount') {
      return BankAccount.find({
        id: this.transferToId(),
      });
    }
  };

  TransferRequest.prototype.setFrom = function (typeString, accountId) {
    this.transferFromType(typeString);
    this.transferFromId(accountId);
  };

  TransferRequest.prototype.setTo = function (typeString, accountId) {
    this.transferToType(typeString);
    this.transferToId(accountId);
  };

  TransferRequest.prototype.populateTransferFromAndTransferToFields = function () {
    var transferRequest = this;

    return $q
      .all({
        transferFrom: transferRequest.getFromObject(),
        transferTo: transferRequest.getToObject(),
      })
      .then(function (transferFromAndToObject) {
        transferRequest.transferFrom = transferFromAndToObject.transferFrom;
        transferRequest.transferTo = transferFromAndToObject.transferTo;
      });
  };

  TransferRequest.prototype.forceComplete = function () {
    var self = this;
    return $http
      .put('/api/transfer_requests/' + this.id + '/force_complete.json')
      .then(function () {
        return self.reload({
          force: true,
        });
      });
  };

  TransferRequest.prototype.forceFail = function () {
    var self = this;
    return $http.put('/api/transfer_requests/' + this.id + '/force_fail.json').then(function () {
      return self.reload({
        force: true,
      });
    });
  };

  TransferRequest.prototype.custodianIsBbs = function () {
    return this.custodianId() === Custodian.bbsId();
  };

  return TransferRequest;
}
