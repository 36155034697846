'use strict';
const Path = require('path'); // Webpack provides this

angular
  .module('controller.advise.create-external-advisor', [
    'directive.input.phone-number-input',
    'directive.input.full-name',
    'directive.input.email',
    'directive.input.manual-help-block',
    'component.advisor.pick-advisor',
    'model.PhoneNumber',
    'model.Address',
    'service.link-copy-handler',
    'service.user-notifications',
  ])
  .controller('AdviseCreateExternalAdvisorCtrl', [
    '$scope',
    '$http',
    '$timeout',
    '$document',
    'ram',
    'config',
    'linkCopyHandler',
    'userNotifications',
    'csrfToken',
    'PhoneNumber',
    'Address',
    adviseCreateExternalAdvisorCtrl,
  ]);

function adviseCreateExternalAdvisorCtrl(
  $scope,
  $http,
  $timeout,
  $document,
  ram,
  config,
  linkCopyHandler,
  userNotifications,
  csrfToken,
  PhoneNumber,
  Address
) {
  const PHONE_TYPE_BUSINESS = config.types.PhoneNumber.findByName('Business').id;
  const LANDING_PAGE_BASE = 'https://get.modernadvisor.ca/';

  $scope.errorMessages = {
    landingPage: {
      message: 'Landing page is not complete.',
      level: 2,
      check: () => !$scope.landingPage() || $scope.landingPage() !== LANDING_PAGE_BASE,
    },
    name: {
      message: 'You must specify a first and last name.',
      level: 1,
      check: () => !!$scope.firstName() && !!$scope.lastName(),
    },
    organizationComplete: {
      message: 'Both Organization and Organization Known As must be filled in.',
      level: 1,
      check: () =>
        (!$scope.organizationName() && !$scope.organizationShortName() && !$scope.urlBase()) ||
        !!$scope.organizationName(),
    },
    organizationKnownAsComplete: {
      message: 'Organization Known As must be filled in.',
      level: 1,
      check: () =>
        (!$scope.organizationName() && !$scope.organizationShortName() && !$scope.urlBase()) ||
        !!$scope.organizationShortName(),
    },
    organizationComplete2: {
      message: 'You must select an advisor from an existing organization.',
      level: 1,
      check: () =>
        !$scope.addToExisting() ||
        (!!$scope.organizationName() && !!$scope.organizationShortName() && !!$scope.urlBase()),
    },
    url1: {
      message:
        'The Advisor URL Base must have no dashes or special characters and must not be empty.',
      level: 1,
      check: () => _isSafeChars($scope.urlComponent()),
    },
    url2: {
      message:
        'The Organization URL base must have no dashes or special characters and must not be empty.',
      level: 1,
      check: () =>
        !(!!$scope.organizationName() || !!$scope.organizationShortName()) ||
        _isSafeChars($scope.urlBase()),
    },
    email: {
      message: 'You must specify an email.',
      level: 1,
      check: () => !!$scope.email(),
    },
    services: {
      message: 'You must specify services for new advisors.',
      level: 2,
      check: () => !!$scope.addToExisting() || !!$scope.externalAdvisorServices.text,
    },
    notes: {
      message: 'Advisors with notes cannot be automatically generated.',
      level: 2,
      check: () => !$scope.notes.text,
    },
  };

  $scope.errors = {};
  $scope.firstName = new ram.Accessor();
  $scope.lastName = new ram.Accessor();
  $scope.phoneNumber = new PhoneNumber();
  $scope.address = new Address();
  $scope.organizationName = new ram.Accessor();
  $scope.organizationShortName = new ram.Accessor();
  $scope.email = new ram.Accessor();
  $scope.landingPage = new ram.Accessor();
  $scope.serviceLevels = [
    {
      code: new ram.Accessor(),
      fee: new ram.Accessor(),
    },
    {
      code: new ram.Accessor(),
      fee: new ram.Accessor(),
    },
    {
      code: new ram.Accessor(),
      fee: new ram.Accessor(),
    },
    {
      code: new ram.Accessor(),
      fee: new ram.Accessor(),
    },
    {
      code: new ram.Accessor(),
      fee: new ram.Accessor(),
    },
  ];
  $scope.externalAdvisorServices = {}; // Uses an object to get around AngularJS behaviour.
  $scope.coWorkerName = new ram.Accessor();
  $scope.urlBase = new ram.Accessor();
  $scope.urlComponent = new ram.Accessor();
  $scope.feeScheduleLabelPrefix = new ram.Accessor();
  $scope.notes = {}; // Uses an object to get around AngularJS behaviour.
  $scope.imaName = new ram.Accessor();
  $scope.createNew = new ram.Accessor(true);
  $scope.addToExisting = new ram.Accessor(false);
  $scope.advisorToCopy = new ram.Accessor();
  $scope.defaultInstitutionLogo = {
    // Uses an object to get around AngularJS behaviour.
    fileName: null,
  };
  $scope._defaultInstitutionLandingPage = null;
  _resetForm();

  function _fileInput() {
    // For some reason, this isn't being drawn in the same order as before.  The ID is not always there at the start.
    return $document[0].getElementById('select-file');
  }

  /*
   * Create constructs that the scope expects.  Used with our standard controls for phone number, person
   */
  $scope.person = {
    firstName: $scope.firstName,
    middleName: angular.noop,
    lastName: $scope.lastName,
  };
  $scope.onPhoneNumberChange = angular.noop; // ignore this part of the widget

  /*
   * Methods
   */
  function _onDonePressedActivities() {
    _clearErrors();

    $scope.feeScheduleLabelPrefix(
      $scope.organizationShortName() && $scope.organizationShortName().length > 0
        ? $scope.organizationShortName()
        : $scope.urlComponent()
    ); // They are the same.

    if ($scope.organizationName() && !$scope.organizationShortName()) {
      $scope.organizationShortName($scope.organizationName());
    }

    if ($scope.advisorToCopy()) {
      $scope.coWorkerName($scope.advisorToCopy().payload.advisor);
    }
  }

  function _clearErrors() {
    $scope.errors = {};
  }

  // Create and save a file so the back-office can create this advisor later
  $scope.doneCreateFile = function (form) {
    _onDonePressedActivities();
    if (_validateData(1).length !== 0) {
      window.scrollTo(0, 0);
      return;
    }
    _send(form);
  };

  // Invoke the Ruby controller to create this advisor right now.
  $scope.doneCreateNow = function (form) {
    _onDonePressedActivities();
    const errors = [..._validateData(1), ..._validateData(2)];
    if (errors.length !== 0) {
      window.scrollTo(0, 0);
      return;
    }
    _autoCreate(form);
  };

  $scope.resetNotification = function () {
    $scope.advisorSuccess = false;
    $scope.successMessageContents = '';
    userNotifications.clearNotification();
  };

  $scope.resetForm = function (form) {
    if ($scope.createNew()) {
      _resetForm(form);
    } else {
      _resetForm(form);
      _resetFormForAddToExisting(form);
      $scope.resetPickAdvisorCallback?.();
    }
  };

  $scope.resetPickAdvisor = function (fnInstance) {
    $scope.resetPickAdvisorCallback = fnInstance;
  };

  $scope.slugFor = function (code) {
    if (!code || code.length === 0) {
      return '';
    }
    const retVal = [];
    if ($scope.urlBase()) {
      retVal.push($scope.urlBase());
    }
    retVal.push($scope.urlComponent());
    retVal.push(code);
    return retVal.join('-');
  };

  $scope.pickCreateNew = function (selected) {
    if (selected) {
      $scope.createNew(true);
      $scope.addToExisting(false);
      $scope.resetPickAdvisorCallback?.();
      _resetForm();
    } else {
      $scope.createNew(false);
      $scope.addToExisting(true);
      _resetFormForAddToExisting();
    }
  };

  $scope.changeAdvisorToCopy = function (data) {
    $scope.advisorToCopy(data);
    if (data) {
      $scope.organizationName(data.payload.institution);
      $scope.organizationShortName(data.payload.institutionShortName);
      $scope.urlBase(data.payload.urlBase);
      $scope._defaultInstitutionLandingPage = data.payload.institutionLandingPage;
      $scope.landingPage(data.payload.institutionLandingPage);
      $scope.defaultInstitutionLogo = {
        fileName: data.payload.institutionLogo,
      };
    } else {
      $scope.organizationName(null);
      $scope.organizationShortName(null);
      $scope.urlBase(null);
      $scope._defaultInstitutionLandingPage = null;
      $scope.landingPage(LANDING_PAGE_BASE);
      $scope.defaultInstitutionLogo = {
        fileName: null,
      };
    }
  };

  $scope.selectFile = function () {
    $timeout(() => _fileInput()?.click()); // To avoid the AngularJS nightmare.
  };

  $scope.onLinkCopyError = function (e) {
    linkCopyHandler.onLinkCopyError(e);
  };

  $scope.onLinkCopied = function (e) {
    linkCopyHandler.onLinkCopied(e, '<i class="fa fa-check-circle fc-green fs-12px"></i> Copied!');
  };

  /*
   * Watchers
   */
  $scope.$watch('organizationName()', (orgName) => {
    if (orgName && !$scope.organizationShortName()) {
      $scope.organizationShortName(orgName);
    }
    if (orgName && !$scope.urlBase()) {
      $scope.urlBase(_createOrgUrlSlug(orgName));
    }
    if (!orgName && $scope.urlBase()) {
      $scope.urlBase(null);
    }
  });

  $scope.$watch('organizationShortName()', () => {
    if ($scope.logoPreviewExists) {
      $scope.logoName = _getNewLogoName();
    }
  });

  $scope.$watch('[firstName(), lastName()]', (values) => {
    if (values[0] && values[1]) {
      $scope.urlComponent(_createAdvisorUrlSlug($scope.firstName(), $scope.lastName()));
      if ($scope.logoPreviewExists) {
        $scope.logoName = _getNewLogoName();
      }
    }
  });

  $scope.cobrandLogoImageChanged = function () {
    const fileInput = _fileInput();
    $scope.logoPreviewExists = true;

    $scope.passedInLogoName = fileInput.files[0].name;
    $scope.logoName = _getNewLogoName();

    _showPreviewImage(fileInput);
  };

  /*
   * Helpers
   */
  function _createOrgUrlSlug(orgName) {
    const parts = orgName.split(' ');
    return parts[0].substr(0, 10).toLowerCase();
  }

  function _createAdvisorUrlSlug(firstName, lastName) {
    return `${firstName.toLowerCase().substr(0, 1)}${lastName.toLowerCase()}`;
  }

  function _validateData(level) {
    const messages = [];
    for (const key in $scope.errorMessages) {
      const msg = $scope.errorMessages[key];
      if (msg.level === level && !msg.check()) {
        messages.push(msg.message);
        $scope.errors[key] = msg.message;
      }
    }
    return messages;
  }

  function _send(form) {
    var formData, request;

    formData = _generateFormData();
    request = new XMLHttpRequest();
    request.open('POST', '/api/super_advisor/new_external_advisor_details.json');
    request.setRequestHeader('X-CSRF-Token', csrfToken());

    request.onload = function () {
      var result = JSON.parse(request.response);
      if (this.status === 200) {
        userNotifications.showSuccess(`File "${result.name}" saved to S3.`);
        $timeout(() => $scope.resetForm(form), 250);
      } else {
        userNotifications.showError(result.message);
      }
      window.scrollTo(0, 0);
    };
    request.onerror = function () {
      window.scrollTo(0, 0);
      userNotifications.showError('There was a problem saving the data.');
    };

    request.send(formData);
  }

  function _autoCreate(form) {
    var formData, request;

    $scope.advisorSuccess = false;
    $scope.successMessageContents = '';
    formData = _generateFormData();
    request = new XMLHttpRequest();
    request.open('POST', '/api/super_advisor/new_external_advisor_details/auto_create.json');
    request.setRequestHeader('X-CSRF-Token', csrfToken());

    request.onload = function () {
      var result = JSON.parse(request.response);
      if (this.status === 200) {
        $scope.successMessageContents = `${$scope.firstName()} ${$scope.lastName()}\n${
          result.message
        }`;
        $scope.advisorSuccess = true;
        $timeout(() => $scope.resetForm(form), 250);
      } else {
        userNotifications.showError(result.message);
      }
      window.scrollTo(0, 0);
    };
    request.onerror = function () {
      window.scrollTo(0, 0);
      userNotifications.showError('There was a problem creating the advisor.');
    };

    request.send(formData);
  }

  function _serviceLevelJsonMapper(serviceLevel) {
    if (!serviceLevel.code() || serviceLevel.code().trim().length === 0) {
      return null;
    }
    return {
      code: serviceLevel.code(),
      fee: serviceLevel.fee(),
    };
  }

  function _resetForm(form) {
    $scope.firstName('');
    $scope.lastName('');
    $scope.phoneNumber.full('');
    $scope.phoneNumber.extension('');
    $scope.phoneNumber.typeId(PHONE_TYPE_BUSINESS);
    $scope.address.locality('');
    $scope.address.region('');
    $scope.address.postalCode('');
    $scope.address.streetAddress('');
    $scope.address.unit('');
    $scope.address.country('CA');
    $scope.address.sameAsId(null);
    $scope.organizationName('');
    $scope.organizationShortName('');
    $scope.email('');
    $scope.landingPage(LANDING_PAGE_BASE);
    $scope._defaultInstitutionLandingPage = null;
    $scope.defaultInstitutionLogo = {
      fileName: null,
    };
    $scope.advisorToCopy(null);
    $scope.coWorkerName('');
    $scope.urlBase('');
    $scope.urlComponent('');
    $scope.feeScheduleLabelPrefix('');
    $scope.imaName('');
    _resetServiceLevels([
      ['a', '1.00'],
      ['b', '0.75'],
      ['c', '0.50'],
      ['d', '0.25'],
      ['e', '0.00'],
    ]);
    $scope.externalAdvisorServices = {
      text: `Retirement Planning
Financial Goal Planning
Tax Reduction Planning
Insurance Review
Keep track of important dates
`,
    };
    $scope.notes = {
      toggle: false,
      text: '',
    }; // Uses an object to get around AngularJS behaviour.

    $scope.logoName = '';
    $scope.logoPreviewExists = false;
    const fileInput = _fileInput();
    if (fileInput) {
      fileInput.value = '';
      document.getElementById('preview').src = '';
    }

    if (form) {
      form.$setPristine();
      form.$setUntouched();
    }
  }

  function _resetFormForAddToExisting(form) {
    $scope.organizationName('');
    $scope.organizationShortName('');
    $scope.urlBase('');
    $scope.feeScheduleLabelPrefix('');
    $scope.landingPage('');
    _resetServiceLevels([
      ['', ''],
      ['', ''],
      ['', ''],
      ['', ''],
      ['', ''],
    ]);
    $scope.externalAdvisorServices = {
      text: '',
    };
    if (form) {
      form.$setPristine();
      form.$setUntouched();
    }
  }

  function _resetServiceLevels(input) {
    input.forEach(([code, fee], index) => {
      $scope.serviceLevels[index].code(code);
      $scope.serviceLevels[index].fee(fee);
    });
  }

  function _showPreviewImage(obj) {
    var fileReader = new FileReader();
    fileReader.onload = function () {
      document.getElementById('preview').src = fileReader.result;
    };
    fileReader.readAsDataURL(obj.files[0]);
  }

  function _generateFormData() {
    /* jshint camelcase: false */

    const formData = new FormData();
    const landingPage = $scope.landingPage() === LANDING_PAGE_BASE ? '' : $scope.landingPage();
    const fileInput = _fileInput();

    if (fileInput && fileInput.files[0]) {
      formData.append('file', fileInput.files[0]);
    }

    var params = {
      logo_name: _apiLogoName() ?? '', // .present is used in the Ruby code, so '' is a good way to represent null
      ima_name: $scope.imaName(),
      organization_name: $scope.organizationName(),
      organization_short_name: $scope.organizationShortName(),
      url_base: $scope.urlBase(),
      first_name: $scope.firstName(),
      last_name: $scope.lastName(),
      url_component: $scope.urlComponent(),
      phone_number: $scope.phoneNumber.digits(),
      phone_type_id: $scope.phoneNumber.typeId(),
      extension: $scope.phoneNumber.extension(),
      email: $scope.email(),
      co_worker_name: $scope.coWorkerName(),
      fee_schedule_label_prefix: $scope.feeScheduleLabelPrefix(),
      external_advisor_services: $scope.externalAdvisorServices.text,
      notes: $scope.notes.text,
      address: JSON.stringify($scope.address.toJSON()),
      service_levels: JSON.stringify(
        $scope.serviceLevels.map(_serviceLevelJsonMapper).filter(Boolean)
      ), // Filter out nulls
      landing_page: landingPage,
      co_brand_info_url_id: _coBrandUrl(),
      new_cobrand: _createNewCobrand(),
      co_brand_name: _coBrandName(),
    };

    for (var key in params) {
      formData.append(key, params[key]);
    }

    return formData;
  }

  function _createNewCobrand() {
    if ($scope.createNew()) {
      return true;
    } else if ($scope.addToExisting() && _landingPageModified()) {
      return true;
    }
    return false;
  }

  function _apiLogoName() {
    if ($scope.createNew()) {
      return $scope.logoName;
    } else {
      return $scope.defaultInstitutionLogo.fileName;
    }
  }

  function _coBrandUrl() {
    if ($scope.createNew() && $scope.organizationName()) {
      return $scope.urlBase();
    } else if ($scope.createNew()) {
      return $scope.urlComponent();
    } else {
      return `${$scope.urlBase()}-${$scope.urlComponent()}`;
    }
  }

  function _getNewLogoName() {
    const extension = Path.extname($scope.passedInLogoName).slice(1);
    let baseName = $scope.organizationShortName()
      ? $scope.organizationShortName()
      : `${$scope.firstName()} ${$scope.lastName()}`;
    baseName = baseName.replace(/ /g, '-').replace(/'/g, '');
    return `logo-${baseName}.${extension}`.toLowerCase();
  }

  function _coBrandName() {
    if ($scope.addToExisting() && _landingPageModified()) {
      return `${$scope.organizationShortName()} - ${_advisorName()}`;
    }
    return $scope.organizationName() || _advisorName();
  }

  function _advisorName() {
    return `${$scope.firstName()} ${$scope.lastName()}`;
  }

  function _landingPageModified() {
    return $scope._defaultInstitutionLandingPage !== $scope.landingPage();
  }

  function _isSafeChars(s) {
    if (!s || s.length === 0) {
      return false;
    }
    for (let i = 0; i < s.length; i++) {
      const c = s.charAt(i);
      if (!((c >= 'a' && c <= 'z') || (c >= '0' && c <= '9'))) {
        return false;
      }
    }
    return true;
  }
}
