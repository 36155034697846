'use strict';

function addressFactory(ram, config) {
  var Address = new ram.Collection('Address', {
    // belongsTo: {
    //   contact: 'Person'
    // },
    enums: {
      type: config.types.Address,
    },
    schema: config.schemas.Address,
    resources: {
      default: new ram.resources.Http('/api/addresses/:id.json'),
      cookie: new ram.resources.Cookie('addresses'),
    },
  });

  Address.prototype.normalize = function () {
    if (this.postalCode()) {
      this.postalCode(this.postalCode().toUpperCase().replace(' ', '').replace('-', ''));
    }
  };

  /**
   * Return the full address, formatted to a single line.
   * @return {String}
   */
  Address.prototype.full = function () {
    var full = '';
    full += this.unit() ? this.unit() + '-' : '';
    full += this.streetAddress() ? this.streetAddress() + ' ' : '';
    full += this.locality() ? this.locality() + ', ' : '';
    full += this.region() ? this.regionLabel() + ', ' : '';
    full += this.country() ? this.countryLabel() + ', ' : '';
    full += this.postalCode() ? this.postalCode() : '';
    return full;
  };

  Address.prototype.countryLabel = function () {
    return this.enumLabel(this.country(), config.types.Country);
  };

  Address.prototype.regionLabel = function () {
    if (!this.country() || this.country() !== 'CA') {
      return this.region();
    }
    return this.enumLabel(this.region(), config.types.Province);
  };

  Address.prototype.setScopeId = function (id) {
    this.scopeId = id;
    return true;
  };

  Address.prototype.populateFromAddressComplete = function (address) {
    var line1 = address.FormattedLine1;
    if (address.SubBuilding) {
      if (line1.startsWith(address.SubBuilding + '-')) {
        line1 = line1.substr(address.SubBuilding.length + 1);
      }
    }
    this.streetName(address.Street);
    this.streetNumber(address.BuildingNumber);
    this.streetAddress(line1);
    this.unit(address.SubBuilding);
    this.locality(address.City);
    this.region(address.Province);
    this.postalCode(address.PostalCode.replace(' ', ''));
    this.country(address.CountryIso2);
  };

  return Address;
}

angular
  .module('model.Address', ['ram', 'config'])
  .factory('Address', ['ram', 'config', addressFactory]);
