'use strict';

angular
  .module('model.Fund', ['ram'])
  .factory('Fund', ['ram', 'config', 'colors', fundFactory])
  .constant('colors', {
    purple: ['#756BB1', '#9E9AC8', '#BCBDDC', '#DADAEB'],
    green: ['#31A354', '#74C476', '#A1D99B', '#C7E9C0'],
    orange: ['#E6550D', '#FD8D3C', '#FDAE6B', '#FDD0A2'],
    blue: ['#3182BD', '#6BAED6', '#9ECAE1', '#C6DBEF'],
    grey: ['#636363', '#969696', '#BDBDBD', '#D9D9D9'],
  });

/**
 * Factory singleton exposes the Fund constructor
 *
 * @return {Fund}
 */
function fundFactory(ram, config, colors) {
  /**
   * @class Represents a fund that may exist in one or more portfolios.
   *        Can be passed an object with attributes name, symbol, fee to
   *        be applied to the fund.
   *
   * @param {Object} attrs
   */
  var Fund = new ram.Collection('Fund', {
    schema: config.schemas.Fund,
    enums: {
      assetClass: config.types.AssetClass,
      assetType: config.types.FundAssetType,
    },
    resources: {
      default: new ram.resources.Http('/api/funds/:id.json'),
      cookie: new ram.resources.Cookie('funds'),
    },
  });

  Fund.constructor.assetClassIdsByColourCategory = {
    stock: [0, 1, 2, 3, 6, 12, 13],
    fixedIncome: [4, 5, 9, 10, 14],
    cash: [8, 11],
    otherFunds: [7],
  };

  /**
   * Get a html color code.
   */
  Fund.constructor.color = d3.scale.ordinal();
  Fund.constructor.color
    .domain([
      'Canadian Stocks',
      'North American Stocks',
      'US Stocks',
      'Global Stocks',
      'International Stocks',
      'Emerging Market Stocks',
      'Canadian Bonds',
      'Canadian Short-term Bonds',
      'Emerging Market Bonds',
      'Global Fixed Income',
      'Real Estate',
      'Mortgages',
      'Cash',
      'High Interest Savings',
      'Other funds',
    ])
    .range([
      colors.purple[0], // Canadian Stocks
      colors.purple[1], // North American Stocks
      colors.purple[2], // US Stocks
      colors.purple[0], // Global Stocks
      colors.purple[1], // International Stocks
      colors.purple[2], // Emerging Market Stocks

      colors.green[0], // Canadian Bonds
      colors.green[1], // Canadian Short-term Bonds
      colors.green[2], // Emerging Market Bonds
      colors.green[0], // Global Fixed Income
      colors.green[3], // Real Estate
      colors.green[1], // Mortgages

      colors.blue[0], // 'Cash'
      colors.blue[1], // High Interest Savings

      colors.orange[1], // Other Funds
      colors.orange[2],
      colors.orange[3],

      colors.blue[2],
      colors.blue[3],
      colors.grey[0],
      colors.grey[1],
      colors.grey[2],
      colors.grey[3],
    ]);

  Fund.prototype.initialize = function () {
    this.color = Fund.constructor.color(this.assetClass().label);
  };

  Fund.prototype.priceForTarget = function (target) {
    return target < 0 ? this.bid() : this.ask();
  };

  return Fund;
}
