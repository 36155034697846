'use strict';

angular
  .module('controller.interview.contribution-amount', ['ram'])
  .controller('InterviewContributionAmountController', [
    '$scope',
    '$state',
    'ram',
    'storedContributionAmount',
    interviewContributionAmountController,
  ]);

function interviewContributionAmountController($scope, $state, ram, storedContributionAmount) {
  // TODO: refactor this into parent interview controller
  $scope.$parent.nextSection = function () {
    $state.go($state.current.data.next);
  };
  $scope.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;

  $scope.contributionAmount = storedContributionAmount;

  $scope.contributionTypes = {
    dollarAmount: 0,
    percent: 1,
  };

  $scope.contributionType = new ram.Accessor();
  if (!isNullOrUndefined($scope.contributionAmount.dollarAmount())) {
    $scope.contributionType($scope.contributionTypes.dollarAmount);
  } else if (!isNullOrUndefined($scope.contributionAmount.percent())) {
    $scope.contributionType($scope.contributionTypes.percent);
  }

  $scope.$watch('contributionType()', function (newContributionType) {
    if (newContributionType === $scope.contributionTypes.dollarAmount) {
      $scope.contributionAmount.percent(null);
    } else if (newContributionType === $scope.contributionTypes.percent) {
      $scope.contributionAmount.dollarAmount(null);
    }

    $scope.contributionAmount.save();
  });

  function isNullOrUndefined(value) {
    return _.isUndefined(value) || _.isNull(value);
  }
}
