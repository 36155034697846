'use strict';

angular
  .module('controller.modal.advisor.confirm-cancel-transfer-instruction', [])
  .controller('ModalAdvisorConfirmCancelTransferInstructionCtrl', [
    '$scope',
    'transferInstruction',
    controller,
  ]);

function controller($scope, transferInstruction) {
  $scope.transferInstruction = transferInstruction;
  $scope.isRecurringTransferInstruction = !transferInstruction.frequency.is.onlyOnce();
  $scope.upcomingAndInProgressTransferRequests = transferInstruction
    .transferRequests()
    .filter((transferRequest) => {
      if (transferRequest.custodianId() === 2) {
        return transferRequest.status.is.inProgress() && transferRequest.sentAt() != null;
      } else {
        return transferRequest.status.is.inProgress();
      }
    });
}
