'use strict';

angular
  .module('controller.modal.advisor-modify-portfolio-options', [
    'config',
    'model.Account',
    'service.loading-indicator',
    'ram',
  ])
  .controller('ModalAdvisorModifyPortfolioOptionsCtrl', [
    '$http',
    '$scope',
    '$uibModalInstance',
    'Account',
    'config',
    'ram',
    'account',
    'loadingIndicator',
    modalAdvisorModifyPortfolioOptionsCtrl,
  ]);

function modalAdvisorModifyPortfolioOptionsCtrl(
  $http,
  $scope,
  $modalInstance,
  Account,
  config,
  ram,
  account,
  loadingIndicator
) {
  var _clearMessages;
  var _reloadData;
  var _done;

  $scope.newPortfolioOption = new ram.Accessor(account.portfolioOptionId());
  $scope.account = account;
  $scope.informationMessageForUser = '';
  $scope.notes = '';
  $scope.accountPortfolioOptionIds = {};
  _.each(config.types.AccountPortfolioOption, function (option) {
    $scope.accountPortfolioOptionIds[option.name] = option.id;
  });

  $scope.accountPortfolioOptions = _.reject(
    config.types.AccountPortfolioOption,
    function (accountPortfolioOption) {
      // Don't filter with  `|| accountPortfolioOption.private` here.  We want super advisor to have all the options.
      return (
        accountPortfolioOption.name === 'adaptive_portfolio' && !canSelectAdaptivePortfolioOption()
      );
    }
  );

  function canSelectAdaptivePortfolioOption() {
    return $scope.$root.currentUser.canSelectAdaptivePortfolio();
  }

  $scope.save = function () {
    loadingIndicator.show();
    _clearMessages();

    $http
      .post('/api/activity_based_modifications/create_portfolio_option_change', {
        input: {
          accountId: $scope.account.id,
          desiredPortfolioOptionId: $scope.newPortfolioOption().id,
          notes: $scope.notes,
        },
      })
      .then(
        function (response) {
          if (response.data?.repCodeChanged) {
            $scope.infoMessage = `The account's rep code has changed to ${response.data?.currentRepCode}`;
          } else {
            $scope.successMessage = 'Portfolio option updated successfully';
          }
          _reloadData();
        },
        function () {
          loadingIndicator.hide();
          $scope.errorMessage = 'Error saving data';
        }
      );
  };

  _clearMessages = function () {
    $scope.successMessage = null;
    $scope.infoMessage = null;
    $scope.errorMessage = null;
  };

  _reloadData = function () {
    Account.find({
      id: $scope.account.id,
    })
      .then(function (account) {
        account.activities.ready();
      })
      .then(function () {
        _done();
      });
  };

  _done = function () {
    loadingIndicator.hide();
  };
}
