'use strict';

angular
  .module('controller.advise.new-account-applications', [
    'service.modals',
    'service.bulkDataLoader',
    'component.email-client-flow-button',
    'directive.paginationnavigator',
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedAccountApplication',
    'model.ApplicationReadModel',
    'model.User',
    'model.Paginator',
    'model.Account',
    'model.AdvisorCreateNewClientFlow',
    'model.AdvisorCreateNewAccountFlow',
    'directive.secured',
    'service.pagination.filter-service',
    'service.user-notifications',
    'service.new-client-goto-next-state-service',
  ])
  .controller('AdviseNewAccountApplicationsCtrl', [
    '$scope',
    '$rootScope',
    '$state',
    '$http',
    '$q',
    '$window',
    'User',
    'Account',
    'PaginatedAccountApplication',
    'Paginator',
    'AdvisorCreateNewClientFlow',
    'AdvisorCreateNewAccountFlow',
    'ApplicationReadModel',
    'advisorDetails',
    'modals',
    'config',
    'paginationFilterService',
    'securityServiceFactory',
    'userNotifications',
    'newClientGotoNextStateService',
    controller,
  ]);

function controller(
  $scope,
  $rootScope,
  $state,
  $http,
  $q,
  $window,
  User,
  Account,
  PaginatedAccountApplication,
  Paginator,
  AdvisorCreateNewClientFlow,
  AdvisorCreateNewAccountFlow,
  ApplicationReadModel,
  advisorDetails,
  modals,
  config,
  paginationFilterService,
  securityServiceFactory,
  userNotifications,
  newClientGotoNextStateService
) {
  $scope.bulkAccounts = [];

  /** ***************************** Pagination methods */
  var _getDataFromPaginator = function (parameter) {
    return parameter.accounts();
  };

  var initialFilter = []; // No initial filters at this point.  Maybe later.
  $scope.initialActions = function () {
    return [
      {
        type: 'SORT-BY',
        sort: {
          sortBy: 'startedAt',
          order: -1,
        },
      },
      {
        type: 'FLEX-FILTER',
        filterList: initialFilter,
      },
    ];
  };

  var filterData = [];

  // Filter out statuses related to accounts that are open.  They will get omitted from the view.
  paginationFilterService.addEnumFilterData(
    _.reject(config.types.ApplicationReadModelStatus, function (status) {
      return _.contains(['open'], status.name);
    }),
    filterData,
    'Status',
    'status'
  );

  paginationFilterService.addEnumFilterData(config.types.Account, filterData, 'Type', 'type');

  $scope.filterData = filterData;
  $scope.filterModel = paginationFilterService.toFilterModel(filterData, initialFilter);

  $scope.paginator = new Paginator(PaginatedAccountApplication, null, _getDataFromPaginator);

  /** ***************************** end Pagination methods */

  $scope.addNewClient = function () {
    const params = {
      source: 'new-client-button',
      advisorDetails,
      groupUrlIdAndLink: advisorDetails.urlId, // no link here
      groupUrlId: advisorDetails.urlId,
      multiAdvisorLink: null,
    };
    newClientGotoNextStateService.go($state, $scope, params);
  };

  $scope.canApprove = (accountReadModel) => {
    return accountReadModel.accountApprovalRole() === $rootScope.currentUser.roleName();
  };

  $scope.loadStatus = function (application) {
    modals
      .applicationStatusModal(
        application.accountReadModel(),
        application,
        application.advisorCreateNewClientFlow()
      )
      .result.then(function () {
        // Nothing to do once this modal is closed. The modal is purely informational.
      });
  };

  $scope.emailActionCallback = function (clientFlow) {
    $scope.bulkAccounts = $scope.bulkAccounts.map((application) => {
      if (application.advisorCreateNewClientFlow()?.id === clientFlow.id) {
        application.statusId(config.types.ApplicationReadModelStatus.findByName('prefill_sent').id);
        application.sentAt(new Date());
      }
      return application;
    });
  };

  function _relatedAccountFlows(clientFlowId) {
    return AdvisorCreateNewAccountFlow.where(
      {
        advisorCreateNewClientFlowId: clientFlowId,
      },
      {
        force: true,
      }
    );
  }

  function _specifiedAccountFlow(id) {
    return AdvisorCreateNewAccountFlow.find({
      id,
    }).then((flow) => [flow]);
  }

  $scope.deleteAction = function (application) {
    const clientFlowId = application.advisorCreateNewClientFlow()?.id;
    const deleteErrorMessage =
      'Unable to delete the account. Please try again. If the problem persists, please contact us.';
    const modalTitle = 'Delete account prefill?';
    let _accountFlows;
    let modalBody;
    let errorHappened = false;
    let deleteLastPrefill = false;

    const promise = clientFlowId
      ? _relatedAccountFlows(clientFlowId)
      : _specifiedAccountFlow(application.advisorCreateNewAccountFlowId());
    promise
      .then((list) => {
        _accountFlows = list;
        switch (_accountFlows.length) {
          case 0:
            modalBody =
              'This client has no accounts.  Are you sure you want to delete this client?';
            return $scope.$root.genericConfirmationModal(modalTitle, modalBody).result;
          case 1:
            return Account.where(
              {
                userId: application.userId(),
              },
              {
                force: true,
              }
            ).then((accounts) => {
              if (accounts.length === 0) {
                deleteLastPrefill = true;
                return $scope.$root.confirmDeleteLastPrefillForClient().result;
              } else {
                modalBody = 'Are you sure you want to delete this account?';
                return $scope.$root.genericConfirmationModal(modalTitle, modalBody).result;
              }
            });
          default:
            modalBody = 'Are you sure you want to delete this account?';
            return $scope.$root.genericConfirmationModal(modalTitle, modalBody).result;
        }
      })
      .then((modalParam) => {
        if (!clientFlowId) {
          return _handleSimpleDelete(application);
        } else if (deleteLastPrefill) {
          return _handleDeleteLastPrefill(application, modalParam);
        } else if (_accountFlows.length === 0) {
          return _handleZeroAccountFlows(application);
        } else {
          return _handleSimpleDelete(application);
        }
      })
      .then(() => {
        if (errorHappened) {
          return;
        }
        userNotifications.showTransientSuccess('The account has been successfully deleted.');
      });

    function _handleSimpleDelete(application) {
      return AdvisorCreateNewAccountFlow.constructor
        .delete(application.advisorCreateNewAccountFlowId())
        .then(
          () =>
            clientFlowId &&
            application.advisorCreateNewClientFlow().advisorCreateNewAccountFlows.ready()
        )
        .then(() =>
          _filterResults(application.advisorCreateNewAccountFlowId(), _filterByAccountFlow)
        )
        .catch(() => {
          userNotifications.showError(deleteErrorMessage);
          errorHappened = true;
        });
    }

    function _handleDeleteLastPrefill(application, modalParam) {
      if (modalParam === 'Remove client') {
        return application
          .advisorCreateNewClientFlow()
          .delete()
          .then(() => _filterResults(clientFlowId, _filterByClientFlow))
          .catch(() => {
            userNotifications.showError(deleteErrorMessage);
            errorHappened = true;
          });
      } else {
        let _newApplication;
        return application
          .unlink()
          .then((newApplication) => {
            _newApplication = new ApplicationReadModel(newApplication.data);
            return AdvisorCreateNewAccountFlow.constructor.delete(
              application.advisorCreateNewAccountFlowId()
            );
          })
          .then(() => _mapResults(_newApplication))
          .then(() =>
            _newApplication.advisorCreateNewClientFlow.ready({
              force: true,
            })
          )
          .then(() =>
            AdvisorCreateNewAccountFlow.where({
              advisorCreateNewClientFlowId: clientFlowId,
            })
          )
          .catch(() => {
            userNotifications.showError(deleteErrorMessage);
            errorHappened = true;
          });
      }
    }

    function _handleZeroAccountFlows(application) {
      return application
        .advisorCreateNewClientFlow()
        .delete()
        .then(() => _filterResults(clientFlowId, _filterByClientFlow))
        .catch(() => {
          userNotifications.showError(deleteErrorMessage);
          errorHappened = true;
        });
    }
  };

  function _filterResults(id, worker) {
    $scope.bulkAccounts = $scope.bulkAccounts.filter((application) => worker(id, application));
  }

  function _mapResults(newApplication) {
    $scope.bulkAccounts = $scope.bulkAccounts.map((application) => {
      if (application.id === newApplication.id) {
        return newApplication;
      } else {
        return application;
      }
    });
  }

  function _filterByAccountFlow(accountFlowId, application) {
    return application.advisorCreateNewAccountFlowId() !== accountFlowId;
  }

  function _filterByClientFlow(clientFlowId, application) {
    return application.advisorCreateNewClientFlow()?.id !== clientFlowId;
  }
}
