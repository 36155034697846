'use strict';

function controller($http, $scope, $modalInstance, ram, advisor, clientId, loadingIndicator) {
  var DEFAULT_DAYS_CLIENT_UPLOAD_LINK_VALID_FOR = 120;

  $scope.daysValidFor = new ram.Accessor(DEFAULT_DAYS_CLIENT_UPLOAD_LINK_VALID_FOR);
  $scope.informationMessageForUser = '';
  $scope.notes = '';

  $scope.save = function () {
    loadingIndicator.show();
    $scope.informationMessageForUser = '';
    $http
      .post('/api/users/create_mobile_upload_link', {
        userId: advisor.id,
        clientId,
        notes: $scope.notes,
        daysValidFor: $scope.daysValidFor(),
      })
      .then(
        function () {
          $scope.informationMessageForUser = 'Message sent.';
          loadingIndicator.hide();
          $modalInstance.close();
        },
        function () {
          loadingIndicator.hide();
          $scope.informationMessageForUser = 'Error sending message.';
        }
      );
  };
}

angular
  .module('controller.modal.advisor-create-client-mobile-upload-link', [
    'model.User',
    'service.loading-indicator',
    'ram',
  ])
  .controller('ModalAdvisorCreateClientMobileUploadLink', [
    '$http',
    '$scope',
    '$uibModalInstance',
    'ram',
    'advisor',
    'clientId',
    'loadingIndicator',
    controller,
  ]);
