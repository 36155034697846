'use strict';

angular
  .module('controller.dashboard.goal-summary', ['directive.goal-summary'])
  .controller('DashboardGoalSummaryController', [
    '$scope',
    'goals',
    'transferInstructions',
    goalSummaryController,
  ]);

function goalSummaryController($scope, goals, transferInstructions) {
  $scope.goals = _.filter(goals, function (goal) {
    return goal.accountGoals()[0].account().isVisible();
  });
  $scope.transferInstructions = transferInstructions;
}
