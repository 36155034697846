'use strict';

angular
  .module('model.PortfolioManager', [])
  .factory('PortfolioManager', ['ram', 'config', portfolioManagerFactory]);

const MODERN_ADVISOR_PM_ID = 1;
const BCV_PM_ID = 2;
const GUARDIAN_PM_ID = 3;

function portfolioManagerFactory(ram, config) {
  var PortfolioManager = new ram.Collection('PortfolioManager', {
    schema: config.schemas.PortfolioManager,
    resources: {
      default: new ram.resources.Http('/api/portfolio_managers/:id.json'),
      cookie: new ram.resources.Cookie('portfolio_managers'),
    },
  });

  PortfolioManager.constructor.modernAdvisorId = function () {
    return MODERN_ADVISOR_PM_ID;
  };

  PortfolioManager.constructor.bcvId = function () {
    return BCV_PM_ID;
  };

  PortfolioManager.constructor.guardianId = function () {
    return GUARDIAN_PM_ID;
  };

  PortfolioManager.constructor.defaultPortfolioOption = function (portfolioOptions, goal) {
    // High Interest Savings should be selected by default on the portfolio review page
    // in interview if the goal is ‘save for short-term’ or 'save for a rainy day'.
    // This should be the case even if an advisor selected a different portfolio option during client prefill.
    if (
      goal &&
      (goal.label() === 'Save for Short-term' || goal.label() === 'Save for a Rainy Day')
    ) {
      return (
        portfolioOptions.filter((option) => option.name === 'savings_portfolio')[0] ??
        portfolioOptions[0]
      );
    } else {
      return portfolioOptions.filter((option) => option.default)[0] ?? portfolioOptions[0];
    }
  };

  PortfolioManager.prototype.isModernAdvisor = function () {
    return this.name() === 'modern_advisor';
  };

  PortfolioManager.prototype.toObject = function () {
    return {
      id: this.id,
      label: this.label(),
      name: this.name(),
      portfolios: this.portfolios(),
      managementFee: this.managementFee(),
      tooltip: this.tooltip(),
      logo: this.logo(),
    };
  };

  return PortfolioManager;
}
