class BeneficiaryDetailsInputController {
  constructor($scope, Beneficiary) {
    this.$scope = $scope;
    this.Beneficiary = Beneficiary;
  }

  $onInit() {
    this.beneficiaryRelationTypes =
      this.Beneficiary.getBeneficiaryRelationTypes('regularBeneficiary');
    this.$scope.onPersonChange = this.onPersonChange;
    this.labelIndentClass = this.labelIndentClass || 'col-sm-3';
    this.showPercentFlag = this.showPercent === 'true';
    this.showRelationshipFlag = this.showRelationship === 'true';
  }

  triggerDelete(beneficiary) {
    this.deleteAction(beneficiary);
  }

  onPercentChanged(beneficiary) {
    if (beneficiary.percent() !== null && beneficiary.percent() !== '') {
      let value = parseFloat(beneficiary.percent());
      value = Math.round(value * 100) / 100;
      beneficiary.percent(value.toString(10));
    }
    this.onBeneficiaryChange(beneficiary);
  }
}

export default BeneficiaryDetailsInputController;
