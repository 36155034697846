'use strict';

angular
  .module('controller.advise.person', [
    'controller.advise.person.view',
    'controller.advise.person.identity-verification',
  ])
  .controller('AdvisePersonCtrl', [
    '$scope',
    '$http',
    '$stateParams',
    'person',
    'identityVerificationStatus',
    'email',
    advisePersonController,
  ])
  .controller('AdvisePersonPersonalInfoChangeController', [
    '$scope',
    advisePersonPersonalInfoChangeController,
  ]);

function advisePersonController(
  $scope,
  $http,
  $stateParams,
  person,
  identityVerificationStatus,
  email
) {
  $scope.person = person;
  $scope.identityVerificationStatus = identityVerificationStatus;
  $scope.email = email;

  $scope.saveSensitiveInfo = function () {
    var jointApplicantId = $stateParams.jointApplicantId;

    return $http.post('/api/people/' + $scope.person.id + '/advisor_sensitive_info_update', {
      firstName: $scope.person.firstName(),
      middleName: $scope.person.middleName(),
      lastName: $scope.person.lastName(),
      sin: $scope.person.sin(),
      email: $scope.email(),
      jointApplicantId,
      birthDate: $scope.person.birthDate(),
      homeAddress: $scope.person.homeAddress() ? $scope.person.homeAddress().toJSON() : null,
      mailingAddress: $scope.person.mailingAddress()
        ? $scope.person.mailingAddress().toJSON()
        : null,
    });
  };

  $scope.reloadIdentityVerificationStatus = function (personId) {
    return $http
      .get('/api/advisor/people/' + personId + '/identity_verification_status.json')
      .then(function (response) {
        $scope.identityVerificationStatus = response.data;
      });
  };
}

function advisePersonPersonalInfoChangeController($scope) {
  /* jshint validthis: true */
  function reload() {
    var person = $scope.person;
    return person.fetch(true);
  }

  this.save = function () {
    return $scope.saveSensitiveInfo().then(function () {
      return reload();
    });
  };
  this.reset = function () {
    return reload();
  };
}
