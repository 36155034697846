'use strict';

angular
  .module('directive.input.bank-account-verification-method', [
    'directive.document-upload',
    'service.fetch-last-document',
    'service.onboarding-mobile-upload-manager',
  ])
  .directive('bankAccountVerificationMethodInput', [
    'config',
    'fetchLastDocument',
    'onboardingMobileUploadManager',
    bankAccountVerificationMethodInputDirective,
  ]);

function bankAccountVerificationMethodInputDirective(
  config,
  fetchLastDocument,
  onboardingMobileUploadManager
) {
  return {
    restrict: 'E',
    scope: {
      autoSave: '=',
      bankAccount: '=',
      photos: '=',
      user: '=',
      calledFromProfile: '@',
      advisorTemplate: '@',
    },
    controllerAs: '$ctrl',
    templateUrl: function (_, tAttrs) {
      if (tAttrs.advisorTemplate) {
        return 'directives/input/bank-account-verification-method-advisor-template.html';
      }
      return 'directives/input/bank-account-verification-method.html';
    },
    controller: [
      '$scope',
      function ($scope) {
        var $ctrl = this; // jshint ignore:line
        const platform = config.env.platform;

        $scope.isWsi = false;
        if (platform && platform.name === 'WSI') {
          $scope.isWsi = true;
          $scope.$parent.bankAccount.verificationMethod(config.types.VerificationMethod[0]);
        }

        $scope.hideMobileUploadLink = $scope.calledFromProfile === 'true';
        $scope.verificationMethodOptions = config.types.VerificationMethod;
        _stateUpdateCallback();

        var bankStatementOptionId = config.types.VerificationMethod.findByName('bank_statement').id;
        if (
          $scope.advisorTemplate &&
          $scope.bankAccount.verificationMethodId() !== bankStatementOptionId
        ) {
          $scope.bankAccount.save({
            verificationMethodId: bankStatementOptionId,
          });
        }

        $scope.isDocumentRequired = function () {
          return (
            $scope.bankAccount.verificationMethod.is.bankStatement() &&
            $scope.advisorTemplate !== 'true'
          );
        };

        $scope.$root.$on('doMobilePhotoRefresh', function () {
          $scope.user.documents.ready().then(function () {
            var image = fetchLastDocument.fetch($scope.user.documents(), 'bank_statement');
            $scope.photos.bankStatementImage = image;
          });
        });

        $ctrl.sendMobileLink = function () {
          onboardingMobileUploadManager.sendMessage(
            $scope.$root.currentUser.id,
            _stateUpdateCallback
          );
        };

        $ctrl.mobileUploadOperationComplete = function () {
          onboardingMobileUploadManager.done();
        };

        onboardingMobileUploadManager.setCallback(_stateUpdateCallback);

        $scope.onBankAccountChange = function () {
          if ($scope.autoSave) {
            $scope.bankAccount.save();
          }
        };

        function _stateUpdateCallback() {
          $ctrl.emailSentState = onboardingMobileUploadManager.getState();
          $ctrl.imagesConfirmed = onboardingMobileUploadManager.enoughImagesCollected();
        }
      },
    ],
  };
}
