'use strict';

angular
  .module('service.google-analytics-wrapper', [])
  .factory('googleAnalyticsWrapper', ['$window', googleAnalyticsWrapperFactory]);

function googleAnalyticsWrapperFactory($window) {
  function sendSelectedServiceOptionEvent(serviceOption) {
    $window.maga('send', {
      hitType: 'event',
      eventCategory: 'service options',
      eventAction: 'selected ' + serviceOption + ' option',
    });
  }

  function sendSignUpEvent(account) {
    var signUpEvents = {
      trial: {
        label: 'trial',
        value: 20,
      },
      digital: {
        label: 'digital',
        value: 100,
      },
    };
    var signUpEventToSend;

    if (account.type.is.trial()) {
      signUpEventToSend = signUpEvents.trial;
    } else {
      signUpEventToSend = signUpEvents.digital;
    }

    $window.maga('send', {
      hitType: 'event',
      eventCategory: 'user',
      eventAction: 'signup',
      eventLabel: signUpEventToSend.label,
      eventValue: signUpEventToSend.value,
    });
  }

  return {
    sendSelectedServiceOptionEvent,
    sendSignUpEvent,
  };
}
