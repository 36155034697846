'use strict';

angular
  .module('directive.input.promo-code-box', [
    'ram',
    'directive.secured',
    'directive.input.date-input',
    'directive.input.manual-help-block',
    'service.promotion',
  ])
  .directive('promoCodeBox', ['$timeout', promoCodeBoxDirective]);

function promoCodeBoxDirective() {
  return {
    controller: ['$scope', '$timeout', 'ram', 'promotion', promoCodeBoxCtrl],
    restrict: 'E',
    scope: {
      containerClass: '@',
      formGroupClass: '@',
      oneTimeUse: '=',
      reloadUser: '=',
      user: '=',
      authorizeSuperAdvisor: '=',
    },
    templateUrl: 'directives/input/promo-code-box.html',
  };

  function promoCodeBoxCtrl($scope, $timeout, ram, promotion) {
    var now = moment().startOf('day').toDate();

    $scope.promo = promotion;
    $scope.effectiveDate = new ram.Accessor(now);
    $scope.effectiveAsOf = {
      value: 'effective-funded',
    };
    $scope.applyPromo = function () {
      var effectiveDate =
        $scope.effectiveAsOf.value === 'effective-funded' ? undefined : $scope.effectiveDate();
      if ($scope.reloadUser) {
        applyAndReload(effectiveDate);
      } else {
        $scope.promo.apply($scope.user, effectiveDate);
      }
      if (!$scope.oneTimeUse) {
        $scope.promo.code('');
        $timeout(function () {
          $scope.promo.codeMessage('');
        }, 2000);
      }
    };

    function applyAndReload(effectiveDate) {
      $scope.promo.apply($scope.user, effectiveDate).then(function () {
        $scope.user.promotionRedemptions.ready(true);
      });
    }
  }
}
