'use strict';

import BeneficiaryAccountModifyAllCardController from './beneficiary-account-modify-all-card.controller';

angular
  .module('directive.advise.beneficiary-account-modify-all-card', [
    'service.account-service',
    'service.spouse-or-partner',
    'service.beneficiary-service',
    'component.beneficiary-details-input',
    'model.Beneficiary',
    'model.PendingChange',
    'model.Person',
    'model.PersonalRelation',
    'model.SuccessorHolder',
    'service.user-notifications',
    'service.pending-changes-service',
  ])
  .component('beneficiaryAccountModifyAllCard', {
    templateUrl: 'components/advise/beneficiary-account-modify-all-card.html',
    bindings: {
      registerParent: '&',
      advisor: '<',
      beneficiaries: '<',
      successorHolder: '<',
      mutateBeneficiaries: '<',
      mutateSuccessorHolder: '<',
      account: '<',
      client: '<',
    },
    controller: [
      '$scope',
      '$http',
      '$q',
      '$timeout',
      'ram',
      'Beneficiary',
      'SuccessorHolder',
      'PendingChange',
      'Person',
      'PersonalRelation',
      'accountService',
      'spouseOrPartner',
      'beneficiaryService',
      'userNotifications',
      'pendingChangesService',
      BeneficiaryAccountModifyAllCardController,
    ],
    controllerAs: '$ctrl',
  });
