'use strict';

angular
  .module('controller.wsi-interview.conflicts-and-recommendations', [
    'config',
    'ram',
    'service.wsi.risk-calculator',
  ])
  .controller('WsiInterviewConflictsAndRecommendationsController', [
    '$scope',
    '$state',
    'storedAccount',
    'config',
    'ram',
    'wsiRiskCalculator',
    'riskAbility',
    'unconstrainedRecommendedRiskLevel',
    controller,
  ]);

function controller(
  $scope,
  $state,
  account,
  config,
  ram,
  wsiRiskCalculator,
  riskAbility,
  unconstrainedRecommendedRiskLevel
) {
  $scope.account = account;

  $scope.riskToleranceLabel = function () {
    if (riskAbility >= 7) {
      return 'High';
    } else if (riskAbility >= 4) {
      return 'Medium';
    } else {
      return 'Low';
    }
  };

  $scope.isObjectiveTooHigh = function () {
    return wsiRiskCalculator.isObjectiveTooHigh(
      $scope.account.returnObjective(),
      unconstrainedRecommendedRiskLevel
    );
  };

  $scope.isObjectiveTooLow = function () {
    return wsiRiskCalculator.isObjectiveTooLow(
      $scope.account.returnObjective(),
      unconstrainedRecommendedRiskLevel
    );
  };

  $scope.maxAcceptableReturnObjectiveForRiskLevel = function () {
    return wsiRiskCalculator.maxAcceptableReturnObjectiveForRiskLevel(
      unconstrainedRecommendedRiskLevel
    );
  };

  $scope.hasReturnObjectiveConflict = function () {
    return wsiRiskCalculator.hasConflict(
      $scope.account.returnObjective(),
      unconstrainedRecommendedRiskLevel
    );
  };

  $scope.changeReturnObjective = new ram.Accessor();
  $scope.changeReturnObjectiveOptions = [
    {
      label: 'Keep as is',
      value: false,
    },
    {
      label: 'Change',
      value: true,
    },
  ];

  $scope.filteredReturnObjectiveOptions = config.types.AccountReturnObjective.filter(
    (returnObjectiveOption) => {
      return !wsiRiskCalculator.isObjectiveTooHigh(
        returnObjectiveOption,
        unconstrainedRecommendedRiskLevel
      );
    }
  );

  $scope.$parent.nextSection = function () {
    if ($scope.isObjectiveTooHigh()) {
      $scope.account.returnObjective($scope.maxAcceptableReturnObjectiveForRiskLevel());
      $scope.account.save();
    }

    $state.go($state.current.data.next);
  };
  $scope.$parent.lastSection = function () {
    $state.go($state.current.data.last);
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;
}
