'use strict';

angular
  .module('controller.profile.bank.add', [
    'model.BankAccount',
    'model.Document',
    'service.form-helper',
    'service.form-modification-tracker',
    'service.user-notifications',
    'service.account-service',
  ])
  .controller('ProfileBankAddCtrl', [
    '$http',
    '$scope',
    '$state',
    'BankAccount',
    'Document',
    'config',
    'formHelper',
    'formModificationTracker',
    'institutions',
    'userNotifications',
    'accountService',
    profileBankAddController,
  ]);

function profileBankAddController(
  $http,
  $scope,
  $state,
  BankAccount,
  Document,
  config,
  formHelper,
  formModificationTracker,
  institutions,
  userNotifications,
  accountService
) {
  const platform = config.env.platform;

  $scope.isWsi = false;
  if (platform && platform.name === 'WSI') {
    $scope.isWsi = true;
  }

  var bankStatementTypeId = _.findWhere(config.types.Document, {
    name: 'bank_statement',
  }).id;

  $scope.formName = 'formAddBankAccount';
  $scope.isDirty = function () {
    return $scope[$scope.formName].$dirty;
  };
  $scope.reset = function () {
    resetForm();
  };
  formModificationTracker.registerForm($scope.formName, $scope);
  formModificationTracker.setCurrentForm($scope.formName);

  $scope.userHasNonTrialCsiAccount = accountService.hasNonTrialCsiAccount(
    $scope.currentUser.accounts()
  );

  function setupBlankForm() {
    $scope.photos = {
      bankStatementImage: Document.new({
        typeId: bankStatementTypeId,
        userId: $scope.currentUser.id,
      }),
    };
    $scope.bankAccount = BankAccount.new({
      userId: $scope.currentUser.id,
    });
    $scope.uploadedBankStatementIds = [];
  }
  setupBlankForm();

  function resetForm() {
    setupBlankForm();
    $scope[$scope.formName].$setPristine();
  }

  $scope.institutions = institutions;

  $scope.$on('documentUploaded', function (event, documentAttrs) {
    $scope.uploadedBankStatementIds.push(documentAttrs.id);
  });

  function _hasUploadedBankStatement() {
    return (
      $scope.bankAccount.verificationMethod.is.bankStatement() &&
      $scope.photos.bankStatementImage &&
      $scope.photos.bankStatementImage.id
    );
  }

  function isFormDone() {
    return (
      $scope[$scope.formName].$valid &&
      (_hasUploadedBankStatement() || $scope.bankAccount.verificationMethod.is.cheque())
    );
  }

  function doSave() {
    $scope.isSaving = true;

    $scope.bankAccount
      .save()
      .then(function () {
        if ($scope.uploadedBankStatementIds.length > 0) {
          return $http.post('/api/bank_statements/attach_to_bank_account', {
            bankStatementIds: $scope.uploadedBankStatementIds,
            bankAccountId: $scope.bankAccount.id,
          });
        }
      })
      .then(function () {
        $scope.bankAccount.finalize();
      })
      .then(function () {
        resetForm();
      })
      .then(function () {
        if ($scope.currentUser.confirmed() || !$scope.userHasNonTrialCsiAccount) {
          $state.go('profile.bank.add-success');
        } else {
          $state.go('profile.bank.confirm-email');
        }
      })
      .catch(function () {
        userNotifications.showError(
          'Looks like something went wrong while processing your new bank ' +
            'account. Please try again later.'
        );
      })
      .finally(function () {
        $scope.isSaving = false;
      });
  }

  $scope.submitNewBankAccount = function () {
    if (!isFormDone()) {
      formHelper.notDone($scope, $scope[$scope.formName]);
    } else {
      doSave();
    }
  };
}
