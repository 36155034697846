'use strict';

function editableContextDirective(directiveHelper) {
  function Controller(scope) {
    this.scope = scope;
  }

  Controller.prototype.toggleEditingEnabled = function () {
    this.scope.editingEnabled = !this.scope.editingEnabled;

    // Allow parent states to know about editing state
    this.scope.$emit('editingToggled', this.scope.editingEnabled);

    return this.scope.editingEnabled;
  };

  Controller.prototype.getEditingEnabled = function () {
    return this.scope.editingEnabled;
  };

  Controller.prototype.getPreventCancel = function () {
    return this.scope.preventCancel;
  };

  Controller.prototype.getPreventEdit = function () {
    return this.scope.preventEdit;
  };

  return {
    scope: true,
    controller: ['$scope', Controller],
    link: function ($scope, element, attrs) {
      directiveHelper.setBoolAttrToScope('editToggle', $scope, attrs);
      directiveHelper.setBoolAttrToScope('editingEnabled', $scope, attrs);
    },
  };
}

angular
  .module('directive.editableContext', ['service.directive-helper'])
  .directive('editableContext', ['directiveHelper', editableContextDirective]);
