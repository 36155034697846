import template from './filtered-client-documents.html';
import controller from './filtered-client-documents.controller';

const filteredClientDocumentsComponent = {
  template,
  bindings: {
    client: '<',
    attachToOptions: '<',
    clientAccounts: '<',
  },
  controller: [
    '$scope',
    'ram',
    'config',
    'FilteredDocumentHolder',
    'securityServiceFactory',
    controller,
  ],
  controllerAs: '$ctrl',
};

export default filteredClientDocumentsComponent;
