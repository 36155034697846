'use strict';

function radioDirective(optionHelper) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      form: '=',
      name: '@',
      options: '=',
      optionDescriptionClass: '@', // Depens on separate description
      optionDescriptionSeparate: '=',
      optionLabel: '@',
      optionValue: '@',
      optionTitle: '@',
      optionHeadline: '@',
      ngModel: '=',
      ngChange: '&',
      ngDisabled: '&',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      class: '@',
      inline: '=',
      labelClass: '@',
      inputClass: '@',
      helpClass: '@',
      radioElModClass: '@', // BEM radio-element modifier class
      optional: '=',
    },
    templateUrl: 'directives/input/radio.html',
    link: optionHelper.link,
    controller: [
      '$scope',
      function ($scope) {
        $scope.dataPlacement = $scope.$root.isMobile ? 'bottom' : 'right';
      },
    ],
  };
}

angular
  .module('directive.input.radio', [
    'templates',
    'animations',
    'directive.focus',
    'directive.input.option-helper',
  ])
  .directive('radio', ['optionHelper', radioDirective]);
