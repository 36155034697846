'use strict';

function controller($scope, $http, account, ram) {
  $scope.account = account;
  var number = ($scope.number = new ram.Accessor());

  $scope.save = function () {
    $scope.error = null;
    var confirmation = s.sprintf('Set account %d number %s?', account.id, number());
    var valid = $scope.form.$valid;
    if (valid && confirm(confirmation)) {
      account.assignNumber(number()).then(
        function () {
          $scope.$close();
        },
        function () {
          $scope.error = 'An account already exists with this account number.';
        }
      );
    }
  };
}

angular
  .module('controller.account.number', ['ram'])
  .controller('AccountNumberCtrl', ['$scope', '$http', 'account', 'ram', controller]);
