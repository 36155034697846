'use strict';

angular
  .module('controller.advise.add-client.service-level', [
    'ngclipboard',
    'service.new-client-goto-next-state-service',
  ])
  .controller('AddClientServiceLevelCtrl', [
    '$scope',
    '$state',
    'advisorDetails',
    'newClientGotoNextStateService',
    addNewClientCtrl,
  ]);

function addNewClientCtrl($scope, $state, advisorDetails, newClientGotoNextStateService) {
  var options = null;

  if (advisorDetails.serviceLevels.length > 0) {
    options = _.map(advisorDetails.serviceLevels, function (serviceLevel) {
      return {
        descriptionMd: serviceLevel.descriptionMd,
        label: serviceLevel.title + ' - ' + serviceLevel.fees,
        value: serviceLevel.groupUrlIdSuffix,
      };
    });
  }

  _.defaults($scope.referralOption, {
    options,
  });

  if (!$scope.referralOption.selection()) {
    $scope.referralOption.selection($scope.referralOption.options[0].value);
  }

  $scope.continue = function () {
    const params = {
      source: 'service-level',
      advisorDetails,
      groupUrlIdAndLink: $scope.getUrlId(), // no link here
      groupUrlId: $scope.getUrlId(),
      multiAdvisorLink: undefined,
    };
    newClientGotoNextStateService.go($state, $scope, params);
  };
}
