'use strict';

angular
  .module('model.ApplicationReadModel', ['ram'])
  .factory('ApplicationReadModel', ['$http', 'ram', 'config', applicationReadModelFactory]);

function applicationReadModelFactory($http, ram, config) {
  var ApplicationReadModel = new ram.Collection('ApplicationReadModel', {
    bind: ['canEdit', 'canDelete', 'canSend'],
    belongsTo: {
      account: 'Account',
      advisorCreateNewAccountFlow: 'AdvisorCreateNewAccountFlow',
      advisorCreateNewClientFlow: 'AdvisorCreateNewClientFlow',
      accountReadModel: 'AccountReadModel',
      user: 'User',
      primaryAdvisorUser: 'User',
    },
    enums: {
      type: config.types.Account,
      status: config.types.ApplicationReadModelStatus,
      accountStatus: config.types.AccountStatus,
      invitationType: config.types.ApplicationReadModelInvitationType,
    },
    schema: config.schemas.ApplicationReadModel,
    resources: {
      default: new ram.resources.Http('/api/application_read_models/:id.json'),
      cookie: new ram.resources.Cookie('application_read_models'),
    },
  });

  ApplicationReadModel.prototype.canEdit = function () {
    return this.advisorCreateNewClientFlow() && this.advisorCreateNewClientFlow().canEdit();
  };

  ApplicationReadModel.prototype.canDelete = function () {
    const hasClientFlow = !!this.advisorCreateNewClientFlow();
    const hasAccountFlow = !!this.advisorCreateNewAccountFlowId();

    return (
      (hasClientFlow && this.advisorCreateNewClientFlow().canDeleteByAdvisor(this)) ||
      (hasAccountFlow && this.applicationStatus() === 'rejected') ||
      (hasAccountFlow && !hasClientFlow && this.applicationStatus() === 'pending')
    );
  };

  ApplicationReadModel.prototype.canSend = function () {
    return this.advisorCreateNewClientFlow() && this.advisorCreateNewClientFlow().canSendToClient();
  };

  ApplicationReadModel.prototype.unlink = function () {
    return $http.put(`/api/application_read_models/${this.id}/unlink.json`);
  };

  ApplicationReadModel.prototype.statusHighlightLevel = function () {
    const highlightDetails = {};

    if (
      !this.advisorCreateNewClientFlow() ||
      this.advisorCreateNewClientFlow().status() === 'completed'
    ) {
      return highlightDetails;
    }

    const staleDate = moment(this.advisorCreateNewClientFlow().staleDate());
    const currentDate = moment(moment().format('YYYY-MM-DD'));

    highlightDetails.daysUntilStale = staleDate.diff(currentDate, 'days');

    if (highlightDetails.daysUntilStale <= 2) {
      highlightDetails.status = 'danger';
    } else if (highlightDetails.daysUntilStale <= 10) {
      highlightDetails.status = 'warning';
    }

    return highlightDetails;
  };

  return ApplicationReadModel;
}
