'use strict';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const DateRange = forwardRef((props, _ref) => {
  const [startDate, setStartDate] = useState(props.initialStartDate);
  const [endDate, setEndDate] = useState(props.initialEndDate);

  const [startDay, setStartDay] = useState(0);
  const [startMonth, setStartMonth] = useState(0);
  const [startYear, setStartYear] = useState(0);

  const [endDay, setEndDay] = useState(0);
  const [endMonth, setEndMonth] = useState(0);
  const [endYear, setEndYear] = useState(0);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    setStartDate(new Date(startYear, startMonth, startDay));
    setEndDate(new Date(endYear, endMonth, endDay));
  }, [startDay, startMonth, startYear, endDay, endMonth, endYear]);

  useEffect(() => {
    setStartYear(startDate.getFullYear());
    setStartMonth(startDate.getMonth());
    setStartDay(startDate.getDate());

    setEndYear(endDate.getFullYear());
    setEndMonth(endDate.getMonth());
    setEndDay(endDate.getDate());
  }, []);

  useImperativeHandle(_ref, () => ({
    getStartDate: () => {
      return startDate;
    },
    getEndDate: () => {
      return endDate;
    },
  }));

  return (
    <>
      <form name="form">
        <div className="clearfix" />
        <div>
          <h4>Start Date</h4>
          <div className="col-xs-6 m-b-20">
            <label className="field-label m-0-impt">Month</label>
            <select
              value={startMonth}
              onChange={(e) => {
                setStartMonth(e.target.value);
              }}
              className="form-control"
            >
              {months.map((month, index) => {
                return (
                  <option key={index} value={index}>
                    {month}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-xs-3 m-b-20">
            <label className="field-label m-0-impt">Day</label>
            <div className="form-group">
              <input
                type="tel"
                name="startDay"
                form="form"
                className="form-control"
                maxLength="2"
                placeholder="30"
                value={startDay}
                onChange={(e) => setStartDay(e.target.value)}
              />
            </div>
          </div>
          <div className="col-xs-3 m-b-20">
            <label className="field-label m-0-impt">Year</label>
            <div className="form-group">
              <input
                type="tel"
                name="startYear"
                form="form"
                className="form-control"
                maxLength="4"
                placeholder="2023"
                value={startYear}
                onChange={(e) => setStartYear(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <h4>End Date</h4>
          <div className="col-xs-6">
            <label className="field-label m-0-impt">Month</label>
            <select
              value={endMonth}
              onChange={(e) => {
                setEndMonth(e.target.value);
              }}
              className="form-control"
            >
              {months.map((month, index) => {
                return (
                  <option key={index} value={index}>
                    {month}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-xs-3">
            <label className="field-label m-0-impt">Day</label>
            <div className="form-group">
              <input
                type="tel"
                name="day"
                form="form"
                className="form-control"
                onChange={(e) => setEndDay(e.target.value)}
                maxLength="2"
                value={endDay}
                placeholder="30"
              />
            </div>
          </div>
          <div className="col-xs-3">
            <label className="field-label m-0-impt">Year</label>
            <div className="form-group">
              <input
                type="tel"
                name="year"
                form="form"
                className="form-control"
                placeholder="2023"
                value={endYear}
                onChange={(e) => setEndYear(e.target.value)}
                maxLength="4"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
});

export default React.memo(DateRange);
