'use strict';

class HoldingsPieChartLegendController {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {}
}

export default HoldingsPieChartLegendController;
