'use strict';

angular
  .module('controller.advise.trade-reconciliation', [
    'controller.advise.trade-reconciliation.create-report',
    'controller.advise.trade-reconciliation.report',
    'controller.advise.trade-reconciliation.report-creation-details',
    'controller.advise.trade-reconciliation.reports',
  ])
  .controller('AdviseTradeReconciliationCtrl', [adviseTradeReconciliationController]);

function adviseTradeReconciliationController() {}
