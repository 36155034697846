import template from './beneficiary-form-resp.html';
import controller from './beneficiary-form-resp.controller';

const beneficiaryFormRespComponent = {
  template,
  bindings: {
    accountFlow: '=',
    client: '=',
    subforms: '=',
  },
  controller: [
    '$scope',
    '$q',
    'ram',
    'config',
    'AdvisorBeneficiaryForm',
    'RespBeneficiary',
    'Beneficiary',
    'spouseOrPartner',
    'beneficiaryParamsGenerator',
    controller,
  ],
  controllerAs: '$ctrl',
};

export default beneficiaryFormRespComponent;
