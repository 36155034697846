'use strict';

// import React from 'react'; // jshint ignore:line
import maReact2Angular from '../maReact2Angular';
import UploadChangedChargeRecords from './UploadChangedChargeRecords';

angular
  .module('react.component.advise.upload-changed-charge-records', [
    'config',
    'csrf',
    'service.user-notifications',
  ])
  .component(
    'reactUploadChangedChargeRecords',
    maReact2Angular(UploadChangedChargeRecords, {}, ['csrfToken', 'userNotifications'])
  );
