'use strict';

function riskAppetiteFieldNamesFactoryMethod() {
  return {
    fieldNameList: [
      'appetite0',
      'appetite1',
      'appetite2',
      'appetite3',
      'appetite4',
      'appetite5',
      'appetite6',
      'appetite7',
    ],
  };
}

angular
  .module('service.riskAppetiteFieldNames', [])
  .factory('riskAppetiteFieldNames', [riskAppetiteFieldNamesFactoryMethod]);
