'use strict';

function transformStatement(record) {
  record.date = new Date(record.date);
  return record;
}

function statementsService(resource) {
  return resource.create('/api/statements/:id.json', {
    transform: transformStatement,
  });
}

angular
  .module('service.statements', ['service.resource'])
  .factory('statements', ['resource', statementsService]);

// used this to generate test data
//
// var statements = [];
// [1, 2, 3].forEach(function (accountId) {
//   var date = chance.date({
//     year: chance.year({
//       min: 2012,
//       max: 2013
//     })
//   });
//   var month = date.getMonth();
//   var monthly = [];
//   var quarterly = [];
//   var tax = [];
//   while (date < Date.now()) {
//     monthly.push({
//       date: date,
//       'account_id': accountId,
//       type: 'Monthly'
//     });
//     if (monthly.length % 4 === 0) {
//       quarterly.push({
//         date: date,
//         'account_id': accountId,
//         type: 'Quarterly'
//       });
//     }
//     if (monthly.length % 12 === 0) {
//       tax.push({
//         date: date,
//         'account_id': accountId,
//         type: 'Tax'
//       });
//     }
//     month++;
//     date = new Date(date);
//     date.setMonth(month);
//     month = date.getMonth();
//   }
//   statements = statements.concat(monthly, quarterly, tax);
// });
// console.log(JSON.stringify(statements, null, ' '));
